import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { computed, observable } from 'mobx';
import getSources, { getSource } from '../../shared/util/sourceTypes';
import { IExternalApi } from '../external-api-types';

const SOURCE_TYPES = getSources();

export interface ILiveChatConfig {
  genesys?: IGenesysLiveChatConfig;
  zcc?: IZccLiveChatConfig;
}
export interface IGenesysLiveChatConfig {
  messagingWebhookSecret?: string;
}
export interface IZccLiveChatConfig {
  botWebhookSecret?: string;
}

export default class Source {
  created_at: string;
  credentials: {};
  enabled: boolean;
  id: string;
  last_ingestion_count: string;
  last_ingestion_started_at: string;
  last_preview_ingestion_count: string;
  last_preview_ingestion_started_at: string;
  org_id: string;
  rate_limit_options: any;
  reauthenticate: any = null;
  rules: {} = {};
  source: any = {};
  ticket_submission: null = null;
  updated_at: string;
  external_api?: IExternalApi;
  live_chat?: ILiveChatConfig;

  @observable
  type: any = null;
  @observable
  name: string = '';
  @observable
  sourceUrlOrIdentifier: string = '';
  @observable
  region: any = null;

  constructor(initialSource) {
    if (!isEmpty(initialSource)) {
      Object.assign(this, initialSource);

      this.sourceUrlOrIdentifier = initialSource.source.seed_url || initialSource.source.identifier;
      this.region = initialSource.source.region;
    } else {
      this.sourceUrlOrIdentifier = '';
      this.region = '';
    }
  }

  @computed
  get sourceType() {
    if (this.type !== null) {
      return this.type;
    }
    return find(SOURCE_TYPES, st => st.key === this.source.type) || SOURCE_TYPES[0];
  }

  set sourceType(type) {
    this.type = getSource(type);
  }
}
