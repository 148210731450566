import { Button, Form, Input, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import bind from 'bind-decorator';
import cloneDeep from 'lodash/cloneDeep';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { DialogType, UiMajorVersion } from 'src/store/ResolveUI/types';
import { cleanStepForCreate } from '../../services/uiConfigService';
import { OrgStore } from '../../store/orgStore';
import { UiConfiguration } from '../../store/ResolveUI/UiConfiguration';
import { IComponent, IFlowComponent, IFlowScreen, INewStep, IStep } from '../../store/ResolveUI/Workflow';
import { ResolveUiStore } from '../../store/resolveUiStore';
import { typedInject } from '../../store/rootStore';
import { RouterStore } from '../../store/routerStore';
import {
  BUILDER_WORKFLOW_TYPE_DISPLAY,
  BUILDER_WORKFLOW_V5_MULTI_CARD_TYPE,
  BUILDER_WORKFLOW_V5_SINGLE_CARD_TYPE,
  V5_BUILDER_WORKFLOW_TYPES,
  WorkflowStore
} from '../../store/workflowStore';
import { workflowEditRoute } from '../routes';
import { WORKFLOW_PURPOSE } from './constants';
import { StyledModal } from './workflow.styles';
import workflowTemplates from './workflowTemplates';

interface IProps {
  createFromSelectedStepsMode?: boolean;
  onCancel: () => void;
  workflowStore: WorkflowStore;
  resolveUiStore: ResolveUiStore;
  routerStore: RouterStore;
  orgStore: OrgStore;
}

interface IState {
  creating: boolean;
  v5InstanceSelected: boolean;
  selectedInstanceIndex: number;
}

interface IInstanceDisplay {
  configuration: UiConfiguration;
  instanceId: string;
  displayName: string;
}

const CREATE_FROM_SELECTED_STEPS_MODE = -2;
@observer
class CreateWorkflowModal extends Component<IProps, IState> {
  state = { creating: false, v5InstanceSelected: false, selectedInstanceIndex: 0 };
  formRef = React.createRef<FormInstance>();
  selectedInstanceTimeout;

  /** Converts IStep to IFlowScreen. Currently used for creating a Template Workflow from an existing set of steps. */
  stepToFlowScreen(step: IStep | Partial<INewStep>) {
    const convertComponent = (component: IComponent): IFlowComponent => {
      return {
        id: component.id,
        type: component.type!,
        options: {
          ...component.options,
          text: component.text,
          class: component.cssClass ? component.cssClass : undefined,
          components:
            component.childComponents && component.childComponents.length > 0
              ? component.childComponents.map(convertComponent)
              : undefined
        }
      };
    };

    const headerComponents: IFlowComponent[] = [];
    const { prepareNewStepFromExistingStep } = this.props.workflowStore;

    step = prepareNewStepFromExistingStep(cloneDeep(step) as IStep, undefined, false);

    if (step.header) {
      headerComponents.push({
        type: 'header',
        options: {
          text: step.header,
          class: step.headerCssClass ? step.headerCssClass : undefined,
          ...step.headerOptions
        }
      });
    }
    let flowComponents: IFlowComponent[] = [];
    if (step.components) {
      flowComponents = (step.components as IComponent[]).map(convertComponent);
    }
    const components = [...headerComponents, ...flowComponents];
    const screen: IFlowScreen = {
      id: step.id!,
      name: step.name,
      tags: step.tags,
      notes: step.notes,
      displayId: step.displayId,
      components,
      position: step.dashboardState?.position
    };
    return screen;
  }

  @action.bound
  async createWorkflow(values) {
    const { routerStore, workflowStore } = this.props;

    this.setState({ creating: true });

    const { selectedInstanceDisplayIndex, selectedTemplateIndex, workflowName, v5WorkflowType } = values;

    const { instanceId, configuration } = this.getInstanceDisplays()[selectedInstanceDisplayIndex];
    const dialogType = configuration.instances.find(i => i.id === instanceId)?.dialogType || DialogType.Professional;

    let template;
    let templateFlow;

    if (selectedTemplateIndex === CREATE_FROM_SELECTED_STEPS_MODE) {
      const selectedSteps: Array<Partial<INewStep>> = cloneDeep(workflowStore.selectedSteps).map(cleanStepForCreate);
      const screens = selectedSteps.map(selectedStep => this.stepToFlowScreen(selectedStep));
      template = {
        name: 'Selected Steps',
        flow: {
          screens
        }
      };
      templateFlow = template.flow;
    } else {
      template = this.getWorkflowTemplates()[selectedTemplateIndex];
      templateFlow = template ? cloneDeep(template.flow) : undefined;
    }

    // rearrange all prompts to the the top for conversational
    templateFlow && workflowStore.groupPromptsToTopWhenConversational(templateFlow.screens, dialogType);

    const newId = await workflowStore.createWorkflow(instanceId, workflowName, {
      templateFlow,
      workflowType: v5WorkflowType,
      ...(template && { templateName: template.name, purpose: template.purpose, survey: template.survey })
    });

    if (newId) {
      workflowStore.updateSelectedStepId('main');
      workflowStore.setShowWorkflowProperties(true);

      routerStore.history.push(
        workflowEditRoute.stringify({
          ...routerStore.activeRouteParams,
          workflowId: newId
        })
      );
    } else {
      this.setState({ creating: false });
    }
  }

  getConfigurations() {
    const { resolveUiStore } = this.props;
    return resolveUiStore.resolveUiConfigurations.fulfilled ? resolveUiStore.resolveUiConfigurations.value : [];
  }

  getInstanceDisplays(): IInstanceDisplay[] {
    const configurations = this.getConfigurations();
    const creatableConfigurations = configurations.filter(configuration => configuration.include_builder_workflow);

    const instanceDisplays: IInstanceDisplay[] = [];
    creatableConfigurations.forEach(configuration => {
      configuration.instances.forEach(instance => {
        const configurationPrefix = configurations.length > 1 ? `${configuration.name} - ` : '';
        let displayName = configurationPrefix + (instance.name || 'Default');
        displayName = instance.active ? displayName : `${displayName} (inactive)`;
        instanceDisplays.push({ configuration, instanceId: instance.raw.id, displayName });
      });
    });
    return instanceDisplays;
  }

  componentDidMount() {
    // not sure why have to do use setTimeout..0 here but if not the this.formRef.current is not set yet
    this.selectedInstanceTimeout = setTimeout(() => {
      const form = this.formRef.current;
      if (form) {
        const selectedInstanceIndex = (form.getFieldValue('selectedInstanceDisplayIndex') || 0) as number;
        const selectedInstanceDisplay = this.getInstanceDisplays()[selectedInstanceIndex];
        const selectedInstance = this.props.resolveUiStore.getUiInstanceById(selectedInstanceDisplay.instanceId);
        const v5InstanceSelected = selectedInstance && selectedInstance.uiMajorVersion === UiMajorVersion.V5;
        this.setState({ v5InstanceSelected: Boolean(v5InstanceSelected), selectedInstanceIndex });
      }
    }, 0);
  }

  componentWillUnmount() {
    if (this.selectedInstanceTimeout) {
      clearTimeout(this.selectedInstanceTimeout);
    }
  }

  getWorkflowTemplates() {
    const templatesWithoutSurvey = workflowTemplates.filter(template => template.purpose !== WORKFLOW_PURPOSE.SURVEY);
    if (!this.props.orgStore.canAccessSurveyWorkflows) {
      return templatesWithoutSurvey;
    }

    const form = this.formRef.current;
    if (form) {
      const selectedInstanceIndex = (form.getFieldValue('selectedInstanceDisplayIndex') || 0) as number;
      const selectedInstanceDisplay = this.getInstanceDisplays()[selectedInstanceIndex];
      const selectedInstance = this.props.resolveUiStore.getUiInstanceById(selectedInstanceDisplay.instanceId);
      if (
        selectedInstance?.uiMajorVersion === UiMajorVersion.V5 &&
        selectedInstance?.dialogType === DialogType.Conversational
      ) {
        return workflowTemplates;
      }
    }

    return templatesWithoutSurvey;
  }

  @bind
  handleInstanceChange(index: number) {
    this.setState({ selectedInstanceIndex: index });
    const form = this.formRef.current;
    if (form) {
      form.setFieldsValue({
        selectedTemplateIndex: this.props.createFromSelectedStepsMode ? CREATE_FROM_SELECTED_STEPS_MODE : -1
      });
    }
  }

  render() {
    const { createFromSelectedStepsMode, onCancel, orgStore, workflowStore } = this.props;
    const { creating, v5InstanceSelected } = this.state;

    const instanceDisplays = this.getInstanceDisplays();

    return (
      <StyledModal visible={true} title={null} onCancel={onCancel} footer={null}>
        <h2>Create Workflow</h2>

        <Form data-testid="create-form" ref={this.formRef} layout="vertical" onFinish={this.createWorkflow}>
          <Form.Item
            name="workflowName"
            label="Workflow Name"
            initialValue={`Untitled ${new Date().toLocaleString()}`}
            rules={[
              {
                required: true,
                message: 'A workflow name must be provided'
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="selectedInstanceDisplayIndex" label="Instance" initialValue={0}>
            <Select disabled={instanceDisplays.length === 1} onChange={this.handleInstanceChange}>
              {instanceDisplays.map(({ displayName }, index) => (
                <Select.Option data-testid={`create-instance-${index}`} key={index} value={index}>
                  {displayName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {orgStore.canChangeBuilderWorkflowType && v5InstanceSelected && (
            <Form.Item
              name="v5WorkflowType"
              label="Type"
              initialValue={
                orgStore.orgSettings.engage ? BUILDER_WORKFLOW_V5_SINGLE_CARD_TYPE : BUILDER_WORKFLOW_V5_MULTI_CARD_TYPE
              }
            >
              <Select>
                {V5_BUILDER_WORKFLOW_TYPES.map((type, index) => (
                  <Select.Option data-testid={`create-type-${index}`} key={type} value={type}>
                    {BUILDER_WORKFLOW_TYPE_DISPLAY[type]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            name="selectedTemplateIndex"
            label="Template"
            initialValue={createFromSelectedStepsMode ? CREATE_FROM_SELECTED_STEPS_MODE : -1}
          >
            <Select>
              {createFromSelectedStepsMode && (
                <Select.Option key="selected-steps" value={CREATE_FROM_SELECTED_STEPS_MODE}>
                  {`From ${workflowStore.selectedSteps.length} Selected Steps`}
                </Select.Option>
              )}

              <Select.Option key="empty" value={-1}>
                Empty Workflow
              </Select.Option>
              {this.getWorkflowTemplates().map(({ name }, index) => (
                <Select.Option key={index} value={index}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div className="buttons">
            <Button type="default" onClick={onCancel}>
              Cancel
            </Button>
            <Button data-testid="create-button" type="primary" htmlType="submit" loading={creating}>
              Create
            </Button>
          </div>
        </Form>
      </StyledModal>
    );
  }
}

export default typedInject('workflowStore', 'resolveUiStore', 'routerStore', 'orgStore')(CreateWorkflowModal);
