// connector types that can ingest "webpage" type resources
export const KB_CONNECTOR_TYPES = ['webpage', 'weebly', 'zendesk_help_center', 'kustomer', 'salesforce', 'zendesk'];

// connector types that can create tickets or ingest ticket resources
export const TICKET_CONNECTOR_TYPES = [
  'zendesk',
  'salesforce',
  'freshdesk',
  'desk',
  'helpscout',
  'oracle',
  'kustomer',
  'purecloud',
  'email',
  'gorgias',
  'microsoft'
];
