import { Drawer } from 'antd';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import { isStaging } from 'src/shared/util/misc';
import styled from 'styled-components';
import ErrorPlaceHolder from '../../../shared/components/ErrorPlaceHolder';
import Loader from '../../../shared/components/Loader';
import asyncComputed from '../../../shared/util/asyncComputed';
import { OrgStore } from '../../../store/orgStore';
import { typedInject } from '../../../store/rootStore';
import { WorkflowStore } from '../../../store/workflowStore';
import DownloadReports from '../../Analytics/components/DownloadReports';
import BreakdownChart from './BreakdownChart';
import DateRangeDropDown from './DateRangeDropDown';
import { IButtonBreakdown } from './IButtonBreakdown';
import S from './layoutStyles';

const ChartContainer = styled(BreakdownChart)`
  width: 100%;
  height: auto;
  overflow: hidden;
`;

const LayoutWrapper = styled.div`
  columns: 2 420px;
  column-gap: 1rem;
  padding: 32px 0;
`;

interface IProps {
  workflowStore: WorkflowStore;
  orgStore: OrgStore;
}

const DATE_ONLY_FORMAT = 'YYYY-MM-DD';

@observer
class ButtonBreakdownLayout extends Component<IProps> {
  defaultStartDay: Moment = moment().subtract(7, 'days');
  defaultEndDay: Moment = moment();

  @observable
  buttonBreakdown = asyncComputed(() => this.fetchData());

  @observable
  selectedStartDay = this.defaultStartDay;

  @observable
  selectedEndDay = this.defaultEndDay;

  async fetchData() {
    const { data } = await this.props.workflowStore.eventService.getWorkflowButtonBreakdownData({
      org_id: this.props.orgStore.selectedOrgId,
      start_at: this.selectedStartDay.format(DATE_ONLY_FORMAT),
      end_at: this.selectedEndDay.format(DATE_ONLY_FORMAT),
      workflow_id: this.props.workflowStore.getWorkflowToEditLegacyId || this.props.workflowStore.workflowIdToEdit,
      environment: isStaging ? 'dev' : 'prod'
    });
    return data;
  }

  @action
  onClose = () => {
    this.props.workflowStore.isBreakdownOpen = false;
  };

  renderCharts = data => {
    return (
      <>
        {data.length === 0 && <div>No data available</div>}
        <Layout screens={data} />
      </>
    );
  };

  @action
  handleCustomDateRangeSelection = dates => {
    this.selectedStartDay = dates[0];
    this.selectedEndDay = dates[1];
  };

  disabledDate(current) {
    return current > moment().endOf('day') || this.buttonBreakdown.pending;
  }

  getData = () => (this.buttonBreakdown.fulfilled ? this.buttonBreakdown.value : []);

  render() {
    return (
      <Drawer
        bodyStyle={{ backgroundColor: '#f6f6f6' }}
        width={256 * 4}
        title="Button Breakdown Report"
        keyboard={true}
        closable={true}
        mask={true}
        maskClosable={true}
        onClose={this.onClose}
        visible={this.props.workflowStore.isBreakdownOpen}
      >
        <S.Container>
          <div className="header">
            <DownloadReports
              data={{ screens: this.getData(), title: this.props.workflowStore.workflowToEdit.name }}
              isButtonBreakdownReport={true}
            />
            <DateRangeDropDown
              handleCustomDateRangeSelection={this.handleCustomDateRangeSelection}
              selectedStartDay={this.selectedStartDay}
              selectedEndDay={this.selectedEndDay}
            />
          </div>
          {this.buttonBreakdown.case({
            pending: () => <Loader thingToWaitFor="report" />,
            rejected: err => <ErrorPlaceHolder error={err} />,
            fulfilled: data => {
              return <S.ChartsLayout>{this.renderCharts(sortEveryNth(data, 2))}</S.ChartsLayout>;
            }
          })}
        </S.Container>
      </Drawer>
    );
  }
}

function Layout({ screens }: { screens: IButtonBreakdown[] }) {
  return (
    <LayoutWrapper>
      {screens.map((screen, index) => (
        <ChartContainer key={screen.step_number} screen={screen} />
      ))}
    </LayoutWrapper>
  );
}

const sortEveryNth = (arr: IButtonBreakdown[], n) => {
  const newArr: IButtonBreakdown[] = [];

  let base = 0;

  while (base < n) {
    for (let i = base; i < arr.length; i += n) {
      newArr.push(arr[i]);
    }
    base += 1;
  }
  return newArr;
};

export default typedInject('workflowStore', 'orgStore')(ButtonBreakdownLayout);
