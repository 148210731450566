import { allUsingApi } from './util';

const getWorkflowButtonBreakdownData = (api, params) => {
  return api.get('/v1/events/workflow-button', ...params);
};

const getQuestionsForSolution = (api, reportParams) => {
  return api.get(`/v1/events/questions-for-solution`, ...reportParams);
};

const getLookerReportUrl = (api, reportParams) => {
  return api.get(`/v1/looker/report-url`, ...reportParams);
};

const getArticlePerformanceDownload = (api, reportParams) => {
  return api.get(`/v1/events/article-performance-download`, ...reportParams);
};

const getWorkflowQuerySources = (api, reportParams) => {
  return api.get(`/v1/events/workflow-source-query`, ...reportParams);
};

const getWorkflowQueryActions = (api, reportParams) => {
  return api.get(`/v1/events/workflow-screen-query`, ...reportParams);
};

export const eventsService = api => {
  return allUsingApi(api, {
    getWorkflowButtonBreakdownData,
    getLookerReportUrl,
    getQuestionsForSolution,
    getWorkflowQuerySources,
    getWorkflowQueryActions,
    getArticlePerformanceDownload
  });
};

export default eventsService;
