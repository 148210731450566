import flat from 'flat';
import { cloneDeep, get, isEmpty, merge, mergeWith, set } from 'lodash';
import { action, computed, observable } from 'mobx';
import { makeBoolean } from 'src/shared/util/format';
import { recordErrors } from 'src/shared/util/recordErrors';
import { GenericSupportOption } from '../models/GenericSupportOption';
import SupportOptionSchedule, { DEFAULT_SCHEDULE, IHours, ISchedule } from '../models/SupportOptionSchedule';
import { BaseUiInstance } from './BaseUiInstance';
import { customMergeSuggestions, SUPPORT_OPTION_TYPES } from './Util';

function addZero(value) {
  if (value.length < 2 && parseInt(value, 10) < 10) {
    return '0' + value;
  }
  return value;
}

interface ILocaleTextStrings {
  [subKey: string]: undefined | string | string[] | ILocaleTextStrings;
}

interface ISunshineInstanceOptions {
  text?: ILocaleTextStrings;

  botDisplayName?: string; // display name to use for bot messages in the Solvvy flow
  botAvatarUrl?: string; // avatar URL to use for bot messages in the Solvvy flow
}

export class SunshineUiInstance extends BaseUiInstance {
  static createRaw(instance: SunshineUiInstance) {
    const myRaw = instance.raw;
    const baseInstanceRaw = BaseUiInstance.createRaw(instance);

    const newRaw = mergeWith(
      {},
      myRaw,
      baseInstanceRaw,
      {
        socialChannelOptions: {
          botDisplayName: instance.botDisplayName,
          botAvatarUrl: instance.botAvatarUrl,
          text: {}
        },
        feedOptions: {}
      },
      customMergeSuggestions
    );

    // apply new text overrides on top of existing raw text
    const textInstance = cloneDeep(myRaw.text || {});
    const newText = merge({}, textInstance, this.getRawTextFromOverrides(instance.textOverrides));
    Object.assign(newRaw.socialChannelOptions, { text: newText });

    if (instance.enableSupportOptionOrgSetting) {
      const channelOptions = this.getRawSupportOptions(instance, myRaw.feedOptions?.channelOptions).supportOptions;
      const channelOptionSchedules = this.getRawSupportOptionSchedules(instance).supportOptionSchedules;
      Object.assign(newRaw, { text: newText });
      Object.assign(newRaw.feedOptions, { channelOptions, channelOptionSchedules });
    } else {
      Object.assign(newRaw, { text: newText });
      Object.assign(newRaw.feedOptions, { channelOptions: null, channelOptionSchedules: null });
    }

    return newRaw;
  }

  /*
    Filter out the closed days and retrieve the hours in 24hr format to be saved in database. Every time a new schedule is always saved to database
  */
  static getFormattedSchedule(supportOptionSchedule) {
    const formatStartDate = startHourArr => `${addZero(startHourArr[0])}:${startHourArr[1]}`;
    const formatHours = (hours: IHours[]) =>
      hours.map(hour => {
        const startHourArr = hour.start.split(':');
        return {
          enabled: formatStartDate(startHourArr),
          disabled: hour.end
        };
      });

    const filteredDisabledScheduleDays: ISchedule[] = supportOptionSchedule.filter(
      (schedule: ISchedule) => !schedule.closed
    );
    const newSchedule: Partial<ISchedule | any[]> = [];

    for (const schedule of filteredDisabledScheduleDays) {
      if (schedule.hours.length > 0) {
        newSchedule.push({
          dayOfWeek: schedule.dayOfWeek,
          hours: formatHours(schedule.hours)
        });
      }
    }
    return newSchedule;
  }

  /*
    If no existing support option is present then the schedule will only be saved if the user has selected to save to support option schedule.
  */
  static getRawSupportOptionSchedules(instance: SunshineUiInstance) {
    const supportOptionSchedules: SupportOptionSchedule[] = [];

    (instance.supportOptions || []).forEach((supportOption: any) => {
      const schedule = this.getFormattedSchedule(supportOption.schedule.schedule);

      if (!isEmpty(schedule)) {
        supportOptionSchedules.push({
          schedule,
          timeZone: supportOption.schedule.timeZone,
          scheduledPluginIds: supportOption.schedule.scheduledPluginIds
        });
      }
    });

    return {
      supportOptionSchedules
    };
  }

  static getRawSupportOptions(instance: SunshineUiInstance, existingRawSupportOptions?: any[] | null) {
    const supportOptions: any[] = cloneDeep(existingRawSupportOptions || []);

    (instance.supportOptions || []).forEach((supportOption: GenericSupportOption) => {
      // find corresponding raw support option
      const index = supportOptions.findIndex(so => so.id === supportOption.id);

      const dashboardOptions: any = {};

      set(dashboardOptions, 'alwaysOffline', supportOption.alwaysOffline);
      set(dashboardOptions, 'workflowsOnly', supportOption.workflowsOnly);
      set(dashboardOptions, 'description', supportOption.description);

      supportOptions[index] = merge({}, supportOptions[index], dashboardOptions);
    });

    return { supportOptions };
  }

  @computed
  get enableSupportOptionOrgSetting() {
    return makeBoolean(get(this._orgSettings, 'enable_support_option_texts', true));
  }

  @action
  static getRawTextFromOverrides(textOverrides) {
    const text: ILocaleTextStrings = {};

    for (const [key, value] of Array.from(textOverrides.entries()) as any) {
      set(text, key, value);
    }
    return text;
  }

  @observable
  socialChannel: string | null = null;

  @observable
  supportOptions: GenericSupportOption[];

  @observable
  botDisplayName?: string; // display name to use for bot messages in the Solvvy flow

  @observable
  botAvatarUrl?: string; // avatar URL to use for bot messages in the Solvvy flow

  @observable
  hasSupportOptions: boolean = false;

  @observable
  textOverrides = new Map<string, undefined | string | string[]>();

  constructor(public raw: any, private _orgTimeZone: string, private _orgSettings: any, public configuration) {
    super(raw, configuration);
    this.applyFromRaw(raw);
  }

  retrieveSupportOptionDetails(rawSupportOptions, rawSupportOptionSchedules) {
    this.supportOptions = [];

    rawSupportOptions.forEach((rawSupportOption: any) => {
      const { id } = rawSupportOption;

      // Bot currently supports only generic support options
      const option = new GenericSupportOption(rawSupportOption, null);
      option.title = rawSupportOption.title;
      option.description = rawSupportOption.description;

      if (option.type === SUPPORT_OPTION_TYPES.BOT_AGENT_AVAILABLITY) {
        if (!option.title) {
          option.title = 'After Hours Message';
        }
        if (!option.description) {
          option.description =
            'No agents are available at this time. Please reach out to us during our designated hours.';
        }
      }

      const supportOptionSchedule = rawSupportOptionSchedules.find(schedule =>
        schedule.scheduledPluginIds.includes(id)
      );

      if (!supportOptionSchedule) {
        option.schedule.schedule = DEFAULT_SCHEDULE;
        option.schedule.timeZone = this._orgTimeZone;
      } else {
        option.schedule.schedule = this.retrieveFormattedSupportHourSchedule(supportOptionSchedule);
        option.schedule.timeZone = supportOptionSchedule.timeZone;
      }
      option.schedule.scheduledPluginIds = [id];

      this.supportOptions.push(option);
    });
  }

  retrieveFormattedSupportHourSchedule(supportOptionSchedule: { schedule: any[] }) {
    return DEFAULT_SCHEDULE.map(defaultDaySchedule => {
      const currentDaySchedule = supportOptionSchedule.schedule.find(
        scheduleInfo => scheduleInfo.dayOfWeek === defaultDaySchedule.dayOfWeek
      );
      if (!isEmpty(currentDaySchedule)) {
        currentDaySchedule!.hours = currentDaySchedule.hours.map(hour => ({ start: hour.enabled, end: hour.disabled }));
        currentDaySchedule!.closed = false;
        currentDaySchedule.step = defaultDaySchedule.step;
        currentDaySchedule.id = defaultDaySchedule.id;
        return currentDaySchedule;
      } else {
        return defaultDaySchedule;
      }
    });
  }

  @action
  applyFromRaw(raw) {
    try {
      super.applyFromRaw(raw);

      this.socialChannel = raw.socialChannel;

      if (raw.socialChannelOptions) {
        const sunshineChannelOptions = raw.socialChannelOptions as ISunshineInstanceOptions;

        this.botDisplayName = sunshineChannelOptions.botDisplayName;
        this.botAvatarUrl = sunshineChannelOptions.botAvatarUrl;

        this.setTextOverridesFromRawText(sunshineChannelOptions.text);
      }

      if (
        (!isEmpty(raw.supportOptions) || !isEmpty(raw.feedOptions?.channelOptions)) &&
        this.enableSupportOptionOrgSetting
      ) {
        // support options
        const rawSupportOptions = raw.feedOptions?.channelOptions || [];
        const rawSupportOptionSchedules = raw.feedOptions?.channelOptionSchedules || [];

        this.hasSupportOptions = true;
        this.retrieveSupportOptionDetails(rawSupportOptions, rawSupportOptionSchedules);
      }
    } catch (e) {
      recordErrors(e);
    }
  }

  setTextOverridesFromRawText(rawText?: ILocaleTextStrings | null) {
    const flattened = flat(rawText || {});

    for (const [key, value] of Object.entries(flattened)) {
      this.textOverrides.set(key, value as any);
    }
  }
}
