import { DownloadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { Parser } from 'json2csv';
import startCase from 'lodash/startCase';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { WorkflowStore } from 'src/store/workflowStore';
import { IButtonBreakdown } from '../../../routes/Workflows/ButonBreakdown/IButtonBreakdown';
import { downloadCsvFile } from '../../../shared/util/csvUtil';
import { NotificationManager } from '../../../shared/util/NotificationManager';
import { recordErrors } from '../../../shared/util/recordErrors';
import { AnalyticsStore } from '../../../store/analyticsStore/';
import { AuthStore } from '../../../store/authStore';
import { OrgStore } from '../../../store/orgStore';
import { typedInject } from '../../../store/rootStore';
import { StyledDownloadIcon, StyledMenuGroup, StyledMenuItem, StyledReportAccr } from '../AnalyticsPage.styles';

interface IProps {
  analyticsStore: AnalyticsStore;
  authStore: AuthStore;
  workflowStore: WorkflowStore;
  orgStore: OrgStore;
  data?: any;
  isButtonBreakdownReport?: boolean;
  isStepAnalyticsQueriesDownload?: boolean;
}

@observer
class DownloadReports extends Component<IProps, {}> {
  notificationManager = new NotificationManager();

  onClickCsvDownload = () => {
    const { isArticlePerformanceReportSelected } = this.props.analyticsStore;

    if (isArticlePerformanceReportSelected) {
      return this.props.analyticsStore.downloadArticlePerformance();
    }

    if (this.props.isButtonBreakdownReport) {
      return this.requestButtonBreakdownDownload();
    }

    if (this.props.isStepAnalyticsQueriesDownload) {
      const { isQueryActions } = this.props.data;
      return this.props.workflowStore.requestStepAnalyticsQueriesDownload(isQueryActions);
    }

    return;
  };

  async requestButtonBreakdownDownload() {
    try {
      this.notificationManager.info({
        title: 'CSV request submitted',
        message: `Your request to download CSV report is ongoing. Once CSV is generated it will be downloaded.`,
        config: {
          duration: 10
        }
      });

      const data = this.props.data as { screens: IButtonBreakdown[]; title: string };

      const reportData = data.screens
        .map(row => {
          return row.buttons.map(btn => {
            return {
              workflow_name: data.title,
              step_name: row.step_name,
              step_number: row.step_number,
              button: btn.text,
              user_actions: btn.user_actions
            };
          });
        })
        .reduce((acc, val) => acc.concat(val), []);

      if (!reportData?.length) {
        return this.notificationManager.warn({ message: 'No items found for the request', config: { duration: 10 } });
      }

      const parser = new Parser();
      const csv = parser.parse(reportData);
      downloadCsvFile(csv, `Button_breakdown_report_${this.props.orgStore.currentOrgName}`);
      this.notificationManager.success({
        title: 'CSV download succeeded',
        message: `Your request to download CSV report succeeded`
      });
    } catch (e) {
      this.notificationManager.error({
        title: 'Oops something went wrong',
        message: `Download request failed. Please try after some time.`
      });
      recordErrors(e);
    }
  }

  render() {
    const { canAccessDataDownload } = this.props.orgStore;
    const { isStepAnalyticsQueriesDownload } = this.props;

    let userQueryDownload = <></>;

    // `articlePerformanceUpdateObserver` is used to update the menu item contents on date filter change in article performance report
    if (!canAccessDataDownload && this.props.analyticsStore.articlePerformanceUpdateObserver) {
      const {
        currentRangeType,
        canDownloadUserQueries,
        userQueryDownloadText,
        userQueryDownloadLink
      } = this.props.analyticsStore;

      userQueryDownload = (
        <StyledMenuGroup
          title={
            <span>
              User Queries for <b>{startCase(currentRangeType)}</b>
            </span>
          }
        >
          <StyledMenuItem key="export" disabled={!canDownloadUserQueries}>
            <a id="query_export" href={userQueryDownloadLink}>
              {userQueryDownloadText}
            </a>
          </StyledMenuItem>
        </StyledMenuGroup>
      );
    }

    const menu = (
      <Menu>
        <StyledMenuItem key="csv">
          <div onClick={this.onClickCsvDownload}>Download as CSV</div>
        </StyledMenuItem>
        {userQueryDownload}
      </Menu>
    );

    return (
      <StyledReportAccr>
        <Dropdown overlay={menu} overlayStyle={{ zIndex: 9999 }} placement="bottomRight">
          {isStepAnalyticsQueriesDownload ? (
            <StyledDownloadIcon />
          ) : (
            <Button
              type="default"
              icon={<DownloadOutlined />}
              size="large"
              data-testid="download-button"
              className="reports_download"
            >
              Download Report
            </Button>
          )}
        </Dropdown>
      </StyledReportAccr>
    );
  }
}

export default typedInject('analyticsStore', 'authStore', 'workflowStore', 'orgStore')(DownloadReports);
