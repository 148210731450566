export enum ChannelOptionPluginTypes {
  Ticket = 'TicketChannelOption',
  Link = 'LinkChannelOption',
  Phone = 'PhoneChannelOption',
  V5KustomerChatIntegration = 'V5KustomerChatIntegration',
  V5ZendeskChatIntegration = 'V5ZendeskChatIntegration',
  IntercomChat = 'V5IntercomChatIntegration',
  KustomerChat = 'V5KustomerChatIntegration',
  CustomKustomerChat = 'CustomV5KustomerChatIntegration',
  LimitlessChat = 'V5LimitlessChatIntegration',
  LivePersonChat = 'V5LivePersonChatIntegration',
  SalesforceChat = 'V5SalesforceEmbeddedServiceChatIntegration',
  WebViewNativeBridge = 'WebviewNativeBridgeChannelOption'
}
