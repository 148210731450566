import { toNumber } from 'lodash';
import { observable } from 'mobx';

export default class Question {
  @observable
  solutionId?: string;

  @observable
  question: string;

  @observable
  instantResolutions?: number;

  @observable
  supportContacted?: number;

  @observable
  dropoffs?: number;

  @observable
  views?: number;

  @observable
  assistedSupport?: string;

  constructor({
    question,
    solution_id: solutionId,
    instant_resolutions: instantResolutions,
    support_contacted: supportContacted,
    dropoffs: dropoffs,
    views: views,
    assisted_support: assistedSupport
  }: {
    question: string;
    solution_id: string;
    instant_resolutions?: string;
    support_contacted?: string;
    dropoffs?: string;
    views?: number;
    assisted_support: string;
  }) {
    this.question = question;
    this.solutionId = solutionId;
    this.instantResolutions = toNumber(instantResolutions);
    this.supportContacted = toNumber(supportContacted);
    this.dropoffs = toNumber(dropoffs);
    this.views = views;
    this.assistedSupport = assistedSupport;
  }
}
