import moment from 'moment';
import { Api } from './api';
import { allUsingApi } from './util';

const search = async (api, options) => {
  const { data } = await api.post('/v1/solutions/search', options);
  return data;
};

const getTopLaunchUrls = async (api, options) => {
  const { data } = await api.get('/v1/data-analytics/top-launch-urls', options);
  return data;
};

const getSessionQuestions = async (api, options) => {
  options.start_date = moment(options.start_date).format('YYYY-MM-DD');
  options.end_date = moment(options.end_date).format('YYYY-MM-DD');
  const { data } = await api.get('/v1/events/session-questions', options);
  return data;
};
const getSessionQuestionsCount = async (api, options) => {
  options.start_date = moment(options.start_date).format('YYYY-MM-DD');
  options.end_date = moment(options.end_date).format('YYYY-MM-DD');
  const { data } = await api.get('/v1/events/session-questions-count', options);
  return data;
};

const getMLDataQueries = async (api, options) => {
  const { data } = await api.get('/v1/queries', options);
  return data;
};

const getSessionAnnotations = async (
  api: Api,
  options: { org_id: number; start_date: string; end_date: string }
): Promise<{ data: any[] }> => api.get(`/v1/events/session-annotations-export`, options);

const createSessionAnnotation = async (api, options) => {
  const { data } = await api.post('/v1/session-annotations', options);
  return data;
};

const editSessionAnnotation = async (api, options) => {
  const { data } = await api.put(`/v1/session-annotations/${options.id}`, options);
  return data;
};

const deleteSessionAnnotation = async (api, options) => {
  const { data } = await api.del(`/v1/session-annotations/${options.id}`);
  return data;
};

const getAnnotationLabels = async (api, options) => {
  const { data } = await api.get('/v1/session-annotations/list-labels', options);
  return data;
};

const getConversionQuestions = async (api, options) => {
  const { data } = await api.get('/v1/data-analytics/conversion-questions', options);
  return data;
};

const getConversionQuestionsCount = async (api, options) => {
  const { data } = await api.get('/v1/data-analytics/conversion-questions-count', options);
  return data;
};

export const exploreService = api => {
  return allUsingApi(api, {
    search,
    getTopLaunchUrls,
    getSessionQuestions,
    getSessionQuestionsCount,
    getMLDataQueries,
    getSessionAnnotations,
    createSessionAnnotation,
    editSessionAnnotation,
    deleteSessionAnnotation,
    getAnnotationLabels,
    getConversionQuestions,
    getConversionQuestionsCount
  });
};

export type IExploreService = ReturnType<typeof exploreService>;
export default exploreService;
