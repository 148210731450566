import { MobXProviderContext } from 'mobx-react';
import { useContext } from 'react';
import { RootStore } from 'src/store/rootStore';

export function useRootStore() {
  const context = useContext<RootStore>(MobXProviderContext);
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider');
  }

  return context;
}
