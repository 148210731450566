import { RangeType } from 'src/shared/util/DateRangeTypes';

export const DATE_ONLY_FORMAT = 'YYYY-MM-DD';

export const DAYS_AGO_TO_RANGE = {
  1: RangeType.TODAY,
  7: RangeType.PAST_WEEK,
  14: RangeType.PAST_2_WEEKS,
  30: RangeType.PAST_MONTH,
  90: RangeType.PAST_3_MONTHS
};
