import { DialogType } from 'src/store/ResolveUI/types';
import { sanitizeHTMLForUiPreview } from './misc';

export const TINYMCE_KEY = process.env.REACT_APP_TINYMCE_KEY;

export const MEDIA_PROMPT_ALLOWED_KEYS = [
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Backspace',
  'Control',
  'Delete',
  'Escape',
  'Meta',
  'Shift',
  'Tab'
];
export const SINGLE_IFRAME_IMAGE_PATTERN = /^(<(p|div)>)?(<img[^<>]*\/>|<iframe.*?><\/iframe>)(<\/(p|div)>)?$/;
export const IMAGE_IFRAME_TAG_PATTERN = /<img.*?\/>|<iframe.*?<\/iframe>/g;

export function getPlainTextFromHtml(html: string) {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = sanitizeHTMLForUiPreview(html);
  return tempElement.textContent || tempElement.innerText || '';
}

export const TOOLBAR_OPTIONS = {
  [DialogType.Conversational]: 'bold italic link unlink emoticons code',
  [DialogType.Professional]: 'bold italic bullist numlist link unlink removeformat code',
  [DialogType.ConversationalLegacy]: 'bold italic bullist numlist link unlink removeformat code'
};

export function getToolbarOptions(dialogType?: DialogType) {
  if (!dialogType) {
    return TOOLBAR_OPTIONS.professional;
  }
  return TOOLBAR_OPTIONS[dialogType] || TOOLBAR_OPTIONS.professional;
}
