import moment, { Moment } from 'moment';
import { IReport } from 'src/routes/Analytics/AnalyticsReportIdTypes';

export const sortReports = (reports: IReport[], order: string[]) => {
  const orderedReports: IReport[] = [];
  order.forEach(id => {
    const report = reports.find(item => item.reportId === id);
    if (report) {
      orderedReports.push(report);
    }
  });
  return orderedReports;
};

export const convertMomentRangeToLookerRange = (startDate: Moment, endDate: Moment) => {
  // Looker accepts range in the format "YYYY/MM/DD to YYYY/MM/DD"
  // also clone the dates so we don't modify the original
  // also add 1 day to the end date so we get the full range since looker expects the range to be exclusive
  return `${startDate.format('YYYY/MM/DD')} to ${endDate
    .clone()
    .add(1, 'days')
    .format('YYYY/MM/DD')}`;
};

export const parseStartAndEndDateFromLookerRange = (lookerRange): [string, string] => {
  // yesterday, today, x day, this year to second, this week, this month, this quarter, this year, YYYY/MM/DD to YYYY/MM/DD, last week, last month, last quarter, last year
  let startDate;
  let endDate;

  const lastRangeTest = new RegExp(/last/);
  if (lastRangeTest.test(lookerRange)) {
    const range = lookerRange.split(' ')[1];
    switch (range) {
      case 'week':
        startDate = moment()
          .subtract(1, 'weeks')
          .startOf('week')
          .format('YYYY-MM-DD');
        endDate = moment()
          .subtract(1, 'weeks')
          .endOf('week')
          .format('YYYY-MM-DD');
        break;
      case 'month':
        startDate = moment()
          .subtract(1, 'months')
          .startOf('month')
          .format('YYYY-MM-DD');
        endDate = moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD');
        break;
      case 'quarter':
        startDate = moment()
          .subtract(1, 'quarters')
          .startOf('quarter')
          .format('YYYY-MM-DD');
        endDate = moment()
          .subtract(1, 'quarters')
          .endOf('quarter')
          .format('YYYY-MM-DD');
        break;
      case 'year':
        startDate = moment()
          .subtract(1, 'years')
          .startOf('year')
          .format('YYYY-MM-DD');
        endDate = moment()
          .subtract(1, 'years')
          .endOf('year')
          .format('YYYY-MM-DD');
        break;
    }
    return [startDate, endDate];
  }

  const thisRangeTest = new RegExp(/this/);
  if (thisRangeTest.test(lookerRange)) {
    const range = lookerRange.split('this ')[1];
    switch (range) {
      case 'week':
        startDate = moment()
          .startOf('week')
          .format('YYYY-MM-DD');
        endDate = moment()
          .endOf('week')
          .format('YYYY-MM-DD');
        break;
      case 'month':
        startDate = moment()
          .startOf('month')
          .format('YYYY-MM-DD');
        endDate = moment()
          .endOf('month')
          .format('YYYY-MM-DD');
        break;
      case 'quarter':
        startDate = moment()
          .startOf('quarter')
          .format('YYYY-MM-DD');
        endDate = moment()
          .endOf('quarter')
          .format('YYYY-MM-DD');
        break;
      case 'year':
        startDate = moment()
          .startOf('year')
          .format('YYYY-MM-DD');
        endDate = moment()
          .endOf('year')
          .format('YYYY-MM-DD');
        break;
      case 'year to second':
        startDate = moment()
          .startOf('year')
          .format('YYYY-MM-DD');
        endDate = moment().format('YYYY-MM-DD');
        break;
    }
    return [startDate, endDate];
  }

  const customDateTest = new RegExp(/\d{4}(\/)\d{2}(\/)\d{2} to \d{4}(\/)\d{2}(\/)\d{2}/);
  if (customDateTest.test(lookerRange)) {
    const dates = lookerRange.split(' to ');
    startDate = moment(dates[0]).format('YYYY-MM-DD');
    endDate = moment(dates[1])
      .subtract(1, 'days')
      .format('YYYY-MM-DD');
    // subtracting 1 day from endDate because looker seems to add 1 day to the end date.
    return [startDate, endDate];
  }

  const xDaysTest = new RegExp(/\d* day/);
  if (xDaysTest.test(lookerRange)) {
    const lastDays = parseInt(lookerRange.split(' day')[0], 10);
    startDate = moment()
      .subtract(lastDays - 1, 'days')
      .format('YYYY-MM-DD');
    endDate = moment().format('YYYY-MM-DD');
    return [startDate, endDate];
  }

  switch (lookerRange) {
    case 'yesterday':
      startDate = moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
      endDate = startDate;
      break;
    case 'today':
      startDate = moment().format('YYYY-MM-DD');
      endDate = startDate;
      break;
  }

  return [startDate, endDate];
};
