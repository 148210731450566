import { Options, Parser } from 'json2csv';

export function downloadCsvFile(csv, fileTitle) {
  const exportedFilename = fileTitle + '.csv' || 'export.csv';

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function parseAndDownloadCsv<T>(data, fileTitle, parserOptions?: Options<T>) {
  const parser = new Parser(parserOptions);
  const csv = parser.parse(data);
  downloadCsvFile(csv, fileTitle);
}
