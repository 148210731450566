export enum InstanceTab {
  GREETING = 'greeting',
  ANNOUNCEMENT = 'announcement',
  WELCOME_PANEL = 'welcome-panel',
  SUPPORT_OPTIONS = 'support-options',
  COLORS = 'colors',
  LOOK_AND_FEEL = 'look-and-feel',
  LAUNCHER = 'launcher',
  INTERFACE_TEXT = 'interface-text',
  SOLUTIONS_SETTINGS = 'solutions-settings',
  SURVEY_SETTINGS = 'survey-settings',
  EXCLUSIONS = 'exclusions',
  CUSTOM_CSS = 'custom-css',
  BOT_SETUP = 'bot-setup'
}
