import { toNumber } from 'lodash';
import { observable } from 'mobx';
import SolutionBySupportVolume from './SolutionBySupportVolume';

export default class ArticlePerformance {
  @observable
  title: string;

  @observable
  url: string;

  @observable
  views: number;

  @observable
  supportRequests: number;

  @observable
  ssrPercent: number;

  @observable
  dropoffs: number;

  @observable
  instantResolutions?: number;

  @observable
  startDate?: string;

  @observable
  endDate?: string;

  @observable
  assistedSupport?: string;

  @observable
  solutions?: SolutionBySupportVolume[];

  constructor({
    title,
    solution_url: url,
    views,
    support_requests: supportRequests,
    dropoffs,
    instant_resolutions: instantResolutions,
    ssr_percent: ssrPercent,
    start_date: startDate,
    end_date: endDate,
    assisted_supports: assistedSupport
  }) {
    this.title = title;
    this.url = url;
    this.views = toNumber(views);
    this.dropoffs = toNumber(dropoffs);
    this.instantResolutions = toNumber(instantResolutions);
    this.ssrPercent = toNumber(ssrPercent);
    this.supportRequests = toNumber(supportRequests);
    this.startDate = startDate;
    this.endDate = endDate;
    this.assistedSupport = assistedSupport;
  }
}
