import { debounce, isEmpty, isNil } from 'lodash';
import { action, observable, reaction, runInAction } from 'mobx';
import moment from 'moment';
import { feedbackService, recommendationService, searchService } from 'src/services';
import asyncComputed from 'src/shared/util/asyncComputed';
import { PromiseState } from 'src/shared/util/PromiseStates';
import { recordErrors } from 'src/shared/util/recordErrors';
import {
  ALL_TIKA_USERS,
  ICoachStats,
  IQuestion,
  IRecommendation,
  ISearchedQuestion,
  IVote,
  IVotedQuery,
  JUDGEMENT_FILTER,
  Judgment,
  SolutionRelevance,
  TARA_MODE
} from './coachStoreV2.utils';
import Solution from './models/Solution';
import { OrgStore } from './orgStore';

export class CoachStoreV2 {
  refreshHistoryTab = false;
  refreshStatisticsTab = false;

  recommendationOffset: number = 0;

  debouncedQuestionsSearch = debounce<(searchText: string) => void>(this._searchQuestions.bind(this), 1000);

  @observable
  questionsSearchStatus: PromiseState = PromiseState.pending;

  @observable
  searchedQuestions: ISearchedQuestion[] = [];

  @observable
  selectedQuestionFromSearch: IQuestion | undefined;

  @observable
  searchSolutionsStatus: PromiseState = PromiseState.pending;

  @observable
  searchedSolutions: Solution[] = [];

  @observable
  selectedSolution: Solution | undefined;

  @observable
  selectedJudgment: Judgment | undefined;

  asyncRecommendation = asyncComputed<IRecommendation | undefined>(async () => {
    try {
      return await this._getRecommendation(this.recommendationOffset, 0);
    } catch (err) {
      recordErrors(err);
      throw err;
    }
  });

  debouncedSearchForSolution = debounce((searchText: string, questionId: string) => {
    this._searchSolutions(searchText, questionId);
  }, 600);

  votedQuestionOffset: number = 0;

  @observable
  votedSolution: Solution | undefined;

  @observable
  votedQuestionsCount: number;

  @observable
  votedJudgment: Judgment | undefined;

  /**
   * @example
   * 'undefined' - voted solution is not changed
   * 'Solution' - voted solution is updated with a different solution
   * 'null' - voted solution is removed
   */
  @observable
  updatedSolution: undefined | Solution | null;

  /**
   * @example
   * 'undefined' - voted judgment is not changed
   * 'Judgment' - voted judgment is updated with a different judgment
   * 'null' - voted judgment is removed
   */
  @observable
  updatedJudgment: undefined | Judgment | null;

  @observable
  solutionTagsForQuery: Record<string, string[]> = {};

  filter: {
    start: moment.Moment;
    end: moment.Moment;
    users: string[];
    judgments: Judgment[];
  } = {
    start: moment().subtract(7, 'days'),
    end: moment(),
    users: ['my_feedback'],
    judgments: []
  };

  asyncVotedQuery = asyncComputed<IVotedQuery | undefined>(async () => {
    try {
      return await this._fetchVotedQuery();
    } catch (err) {
      recordErrors(err);
      throw err;
    }
  });

  asyncCoachStats = asyncComputed<ICoachStats>(async () => {
    const res = await recommendationService.getCoachStats(
      this._orgStore.selectedOrgId,
      this._orgStore.currentUserContext.id
    );
    return res.data;
  });

  /** Used to compare and cancel old values during debounce search */
  private _questionSearchText: string = '';

  /** Used to compare and cancel old values during debounce search */
  private _solutionsSearchText: string = '';

  constructor(private _orgStore: OrgStore) {
    reaction(
      () => this._orgStore.selectedOrgId,
      () => {
        runInAction(() => {
          this.recommendationOffset = 0;
          this.questionsSearchStatus = PromiseState.pending;
          this.searchedQuestions = [];
          this.selectedQuestionFromSearch = undefined;
          this.searchSolutionsStatus = PromiseState.pending;
          this.searchedSolutions = [];
          this.selectedSolution = undefined;
          this.selectedJudgment = undefined;
          this.votedQuestionOffset = 0;
          this.filter = {
            start: moment().subtract(7, 'days'),
            end: moment(),
            users: ['my_feedback'],
            judgments: []
          };
          this.votedQuestionsCount = 0;
          this.resetHistoryTab();
          this.asyncRecommendation.refresh();
          this.asyncCoachStats.refresh();
        });
      }
    );
  }

  @action
  resetHistoryTab() {
    this.votedSolution = undefined;
    this.votedJudgment = undefined;
    this.updatedSolution = undefined;
    this.updatedJudgment = undefined;
    this.asyncVotedQuery.refresh();
  }

  @action
  removeSelectedSolution() {
    this.selectedSolution = undefined;
    this.selectedJudgment = undefined;
  }

  async getSolutionsForQuestion(
    questionId: string,
    question: string,
    searchParams: { limit?: number; intelligent?: boolean } = {}
  ): Promise<Solution[]> {
    const { selectedOrgId, currentUserContext } = this._orgStore;

    const solutionTags = await this._getSolutionTagsForQuery(questionId);
    const { data: solutionResp } = await searchService.search(
      selectedOrgId,
      question,
      solutionTags,
      ...Object.values(searchParams)
    );

    // Reformat the solution data into an array.
    return solutionResp.reduce<Solution[]>((result, respData) => {
      respData.solutions.map(value => {
        result.push(
          new Solution(
            questionId,
            value.id,
            value.content,
            value.metadata,
            SolutionRelevance.NOT_RELEVANT,
            value.resource.type,
            currentUserContext.isActualGlobalUser ? 'solvvy_feedback' : 'expert_feedback',
            'direct',
            currentUserContext.id.toString(),
            false
          )
        );
      });
      return result;
    }, []);
  }

  async navigateRecommendation(offset: 1 | -1 | 0, skipRecommendationId?: string) {
    if (skipRecommendationId) {
      await this.markRecommendationAsUnViewed(skipRecommendationId);
    }
    runInAction(() => (this.searchedSolutions = []));
    this.recommendationOffset += offset;
    this.asyncRecommendation.refresh();
  }

  markRecommendationAsUnViewed(id: string) {
    return recommendationService.updateRecommendationStatus({
      id,
      status: 'unviewed',
      org_id: this._orgStore.selectedOrgId
    });
  }

  async updateRelevantSolution(questionId: string, solution: Solution) {
    await feedbackService.saveUserFeedback(
      this._orgStore.selectedOrgId,
      this._orgStore.currentUserContext.id.toString(),
      questionId,
      solution.id,
      solution.solution_resource_type,
      SolutionRelevance.RELEVANT,
      solution.source
    );
  }

  saveVote(params: { recommendationId?: string; query_id?: string; org_id?: number; analysis_id?: string }) {
    const { currentUserContext, selectedOrgId } = this._orgStore;
    const user_id = currentUserContext.id.toString();
    const status = this.selectedJudgment;

    // For recommended questions from the training set, update the judgment status
    if (params.recommendationId) {
      return recommendationService.updateRecommendationStatus({
        id: params.recommendationId,
        org_id: selectedOrgId,
        audited: false,
        audited_by: null,
        user_id,
        tara_mode: TARA_MODE,
        status
      });
    }

    // For searched questions, insert it into recommendations along with the judgment status
    if (params.query_id && params.org_id) {
      return recommendationService.insertAsViewed({
        org_id: params.org_id,
        query_id: params.query_id,
        analysis_id: params.analysis_id,
        audited: false,
        user_id,
        tara_mode: TARA_MODE,
        status
      });
    }
  }

  async getVote(questionId: string): Promise<IVote | undefined> {
    const { data: votes } = await feedbackService.getAllVotedQueries(
      questionId,
      this._orgStore.currentUserContext.isActualGlobalUser ? 'solvvy_feedback' : 'expert_feedback',
      this._orgStore.selectedOrgId
    );
    return votes[0];
  }

  async fetchVotedSolution(queryId: string, vote: IVote) {
    const { data } = await searchService.getSolutionsInfo([vote.solution_id], this._orgStore.selectedOrgId);
    runInAction(() => {
      this.votedSolution = new Solution(
        queryId,
        vote.solution_id,
        data[0].content,
        data[0].metadata,
        vote.relevance.toString(),
        vote.solution_resource_type,
        vote.source,
        vote.source_type,
        vote.source_id,
        vote.was_positive
      );
    });
  }

  saveAuditedFeedback(solution: Solution, relevance: SolutionRelevance, queryId: string) {
    // Save the solutions to ml-data.votes. We link the solution ids to the question id (in queries).
    return feedbackService.saveAuditedFeedback({
      id: this._orgStore.selectedOrgId,
      userId: this._orgStore.currentUserContext.id.toString(),
      queryId,
      solutionId: solution.id,
      solutionType: solution.solution_resource_type,
      source_type: solution.source_type || 'direct',
      relevance,
      sourceFeedback: solution.source,
      source_id: solution.source_id
    });
  }

  saveUpdatedJudgment(recommendationId: string) {
    // Update status and audited flag and audited by
    return recommendationService.updateRecommendationStatus({
      audited: true,
      id: recommendationId,
      status: this.updatedJudgment,
      org_id: this._orgStore.selectedOrgId,
      audited_by: this._orgStore.currentUserContext.id
    });
  }

  @action
  navigateVotedQuestion(offset: 1 | -1) {
    this.votedQuestionOffset += offset;
    this.resetHistoryTab();
  }

  setHasNewVotes() {
    this.refreshHistoryTab = true;
    this.refreshStatisticsTab = true;
  }

  /** Recursively get training set if no query found. */
  private async _getRecommendation(offset: number, maxRecursiveCall: number) {
    const { data } = await recommendationService.getRecommendation(
      this._orgStore.selectedOrgId,
      this._orgStore.currentUserContext.id.toString(),
      offset
    );

    if (!isEmpty(data) && isNil(data[0]?.query) && maxRecursiveCall < 10) {
      return this._getRecommendation(offset + 1, maxRecursiveCall + 1);
    }

    const recommendation = data[0];
    recommendation && this._markRecommendationAsViewed(recommendation.id);
    return recommendation;
  }

  private _markRecommendationAsViewed(id: string) {
    // a specific use case where for now we allow question to be marked as viewed only for global user. This check would be removed once we expose to everyone
    if (this._orgStore.currentUserContext.isGlobalUser && id) {
      recommendationService
        .updateRecommendationStatus({
          id,
          status: 'viewed',
          user_id: this._orgStore.currentUserContext.id.toString(),
          org_id: this._orgStore.selectedOrgId
        })
        .catch(err => recordErrors(err));
    }
  }

  private async _getSolutionTagsForQuery(questionId: string): Promise<string[]> {
    if (!this.solutionTagsForQuery[questionId]) {
      const { data } = await searchService.getQueryInfo(questionId, this._orgStore.selectedOrgId);
      runInAction(() => (this.solutionTagsForQuery[questionId] = data[0]?.solution_tags || []));
    }

    return this.solutionTagsForQuery[questionId];
  }

  private async _searchQuestions(searchText: string) {
    this._questionSearchText = searchText;

    runInAction(() => {
      this.questionsSearchStatus = PromiseState.pending;
      this.searchedQuestions = [];
      this.selectedSolution = undefined;
    });

    if (!searchText) {
      return;
    }

    try {
      const { data: resp } = await searchService.searchQueries(this._orgStore.selectedOrgId, searchText, 5);
      if (searchText !== this._questionSearchText) {
        return;
      }
      runInAction(() => {
        this.searchedQuestions = resp;
        this.questionsSearchStatus = PromiseState.fulfilled;
      });
    } catch (e) {
      recordErrors(e);
      if (searchText !== this._questionSearchText) {
        return;
      }
      runInAction(() => (this.questionsSearchStatus = PromiseState.rejected));
    }
  }

  private async _searchSolutions(searchText: string, questionId: string) {
    this._solutionsSearchText = searchText;

    runInAction(() => {
      this.searchSolutionsStatus = PromiseState.pending;
      this.searchedSolutions = [];
    });

    if (!searchText) {
      return;
    }

    try {
      // Use Elasticsearch (intelligent: false) for Search All Solutions if org setting enabled
      const intelligent = !this._orgStore.orgSettings.coach_search_all_solutions_elasticsearch;

      const searchedSolutions = await this.getSolutionsForQuestion(questionId, searchText, { limit: 10, intelligent });
      if (searchText !== this._solutionsSearchText) {
        return;
      }

      runInAction(() => {
        this.searchedSolutions = searchedSolutions;
        this.searchSolutionsStatus = PromiseState.fulfilled;
      });
    } catch (e) {
      recordErrors(e);
      runInAction(() => (this.searchSolutionsStatus = PromiseState.rejected));
    }
  }

  private async _fetchVotedQuery(): Promise<IVotedQuery | undefined> {
    let users = [this._orgStore.currentUserContext.id.toString()];
    if (this.filter.users.length === 2) {
      // this will happen when a single user from tika  users is selected (2 level selection in UI)
      users = this.filter.users.slice(1);
    } else if (this.filter.users[0] === 'all_tika_users') {
      users = ALL_TIKA_USERS.map(user => user.value);
    }

    const result = await recommendationService.getVotedQueries(
      this._orgStore.selectedOrgId,
      users,
      this.filter.judgments.length === 0 ? Object.keys(JUDGEMENT_FILTER) : this.filter.judgments,
      this.filter.start.format('YYYY-MM-DD'),
      this.filter.end.format('YYYY-MM-DD'),
      this.votedQuestionOffset,
      TARA_MODE
    );

    const {
      data: [recommendation],
      headers: { 'x-total': totalQuestionsAvailable }
    } = result;

    runInAction(() => {
      this.votedQuestionsCount = Number(totalQuestionsAvailable);
      this.votedJudgment = recommendation?.status;
    });

    if (!recommendation) {
      return;
    }

    const { data: queries } = await searchService.getQueryInfo(recommendation.query_id, this._orgStore.selectedOrgId);
    if (queries.length) {
      queries[0].recommendationId = recommendation.id;
      queries[0].voteUpdatedAt = recommendation.updated_at;
    }
    return queries[0];
  }
}
