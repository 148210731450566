import { Table } from 'antd';
import styled from 'styled-components';

export const StyledTableContainer = styled.div`
  margin-top: 46px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  height: 280px;
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 600px;

  p {
    margin-top: 24px;
    width: 80%;
    text-align: center;
  }

  h3 {
    margin-bottom: 0px;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledTable = styled(Table)`
  .ant-table-content {
    table {
      border: 0px !important;
    }
  }
`;

export const VolumeHeader = styled.h4`
  margin-top: 20px;
  font-size: 14px;
`;

export const OverviewContainer = styled.div`
  height: 88vh;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`;

export const AlertContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: -40px;
  justify-content: center;
`;

export const ScrollContainer = styled.div`
  width: 100%;
  overflow: scroll;
`;

export const SankeyErrorContainer = styled.div`
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  margin-top: 15% !important;
`;

export const QueriesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 115px;
`;

export const InfiniteScrollContainer = styled.div`
  overflow-y: auto;
  height: 250px;
`;

export const SankeyContainer = styled.div`
  overflow: scroll;
  width: 100%;
  .sankey-tooltip {
    position: absolute;
    visibility: hidden;
    background-color: black;
    color: white;
    border-radius: 5px;
    padding: 10px;
    opacity: 0.8;
    min-width: 60px;
    pointer-events: none;
  }

  .sankey-tooltip :after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 7px;
    border-style: solid;
    border-color: black transparent transparent transparent;
    pointer-events: none;
  }
`;

export const StepsContainer = styled.div`
  .ant-steps-navigation .ant-steps-item::after {
    border: 1px solid white !important;
  }
  .ant-steps-item {
    text-align: left !important;
    padding-left: 25px !important;
  }
`;

export const PieContainer = styled.div`
  .recharts-legend-item {
    margin-right: 20px !important;
  }
`;
