import { allUsingApi } from './util';

const saveUserFeedback = (api, id, userId, queryId, solutionId, solutionType, relevance, sourceFeedback) => {
  return api.post('/v1/votes', {
    org_id: id,
    source_id: userId,
    query_id: queryId,
    solution_id: solutionId,
    solution_resource_type: solutionType,
    source: sourceFeedback,
    source_type: 'direct',
    relevance,
    was_positive: true
  });
};

const saveAuditedFeedback = (
  api,
  { id, userId, queryId, solutionId, solutionType, source_type, relevance, sourceFeedback, source_id }
) => {
  return api.post('/v1/votes', {
    org_id: id,
    source_id,
    query_id: queryId,
    solution_id: solutionId,
    solution_resource_type: solutionType,
    source: sourceFeedback,
    source_type,
    relevance,
    was_positive: relevance !== '0',
    audited: true,
    audited_by: userId
  });
};

const getVotedQueries = (api, id, audited, was_positive, offset, source, source_id) => {
  return api.get('/v1/votes', {
    org_id: id,
    limit: '1',
    audited,
    was_positive,
    offset,
    source
  });
};

const getVotedQueriesBySourceIds = (api, orgId, audited, wasPositive, source, sourceIds, offset) => {
  return api.get('/v1/votes/by-source-ids', {
    orgId,
    audited,
    wasPositive,
    source,
    sourceIds,
    offset
  });
};

const getAllVotedQueries = (api, query_id, source, org_id) => {
  return api.get('/v1/votes', {
    query_id,
    source,
    ...(org_id && { org_id })
  });
};

const getAllVotedQueriesOld = (
  api,
  query_id: string,
  limit: number,
  audited: boolean,
  was_positive: boolean,
  source: string
) => {
  return api.get('/v1/votes', {
    query_id,
    limit,
    audited,
    was_positive,
    source
  });
};

export const feedbackService = api => {
  return allUsingApi(api, {
    saveUserFeedback,
    saveAuditedFeedback,
    getVotedQueries,
    getVotedQueriesBySourceIds,
    getAllVotedQueries,
    getAllVotedQueriesOld
  });
};

export default feedbackService;
