import { allUsingApi } from './util';

const getAllResources = async (api, options) => {
  const { data } = await api.get('/v1/resources', options);
  return data;
};

const getActiveWebpages = async (api, options) => {
  const { data } = await api.get(`/v1/resources`, options);
  return data;
};

const getActiveSolutions = async (api, orgId) => {
  const { data } = await api.get(`/v2/solutions?org_id=${orgId}&type=snippet`);
  return data;
};

const getActiveSolutionCount = async (api, options) => {
  const { headers } = await api.get(`/v2/solutions`, options);
  return headers['x-total'];
};

const getSolutionsPerArticle = async (api, options) => {
  const { data } = await api.get(`/v2/solutions/solutions-per-article`, options);
  return data;
};

const getSolutionsPerArticleCount = async (api, options) => {
  const { data } = await api.get(`/v2/solutions/solutions-per-article-count`, options);
  return data;
};

export const resourcesService = api => {
  return allUsingApi(api, {
    getAllResources,
    getActiveWebpages,
    getActiveSolutions,
    getActiveSolutionCount,
    getSolutionsPerArticle,
    getSolutionsPerArticleCount
  });
};

export default resourcesService;
