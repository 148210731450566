type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export interface ISuggestion {
  id: string;
  lockVersion: number;
  suggestionListVersionId?: string;

  name: string;
  urlRegex?: string;
  question?: string;
  workflowId?: string;
  text: string;
  enabled: boolean;
  position?: number;

  updatedAt: string;
  updatedByUser?: {
    name: string;
    email: string;
  };

  placeholder?: boolean;
}
export type INewSuggestion = Optional<ISuggestion, 'id' | 'lockVersion'>;

export enum SuggestionType {
  WORKFLOW = 'workflow',
  QUERY = 'query'
}

export interface ISuggestionList {
  id: string;
  lockVersion: number;
  orgGroupId: number;
  uiInstanceId: string;
  publishedVersion: ISuggestionListVersion;
  workingVersion: ISuggestionListVersion;
}
export type INewSuggestionList = Omit<
  Optional<ISuggestionList, 'id' | 'lockVersion'>,
  'publishedVersion' | 'workingVersion'
> & {
  publishedVersion?: INewSuggestionListVersion;
  workingVersion: INewSuggestionListVersion;
};

export interface ISuggestionListVersion {
  id: string;
  lockVersion: number;
  suggestions: ISuggestion[];
  overallUpdatedAt: string;
  overallUpdatedByUserId?: string;
  overallUpdatedByUser?: {
    name: string;
    email: string;
  };
  publishedAt?: string;
  publishedComments?: string;
  publishedVersion?: number;
}
export type INewSuggestionListVersion = Omit<
  Optional<ISuggestionListVersion, 'id' | 'lockVersion' | 'overallUpdatedAt'>,
  'suggestions'
> & {
  suggestions: INewSuggestion[];
};

export interface IRevertSuggestionListVersion {
  id: string;
  updatedByUser?: {
    name: string;
    email: string;
  };
  publishedAt?: string;
  publishedComments?: string;
  publishedVersion?: number;
}
