import get from 'lodash/get';
import { observable } from 'mobx';
import { UiMajorVersion } from '../ResolveUI/types';
import { GenericSupportOption } from './GenericSupportOption';
import PhoneNumber from './PhoneNumber';

export default class PhoneSupportOption extends GenericSupportOption {
  @observable
  phoneNumbers: PhoneNumber[];

  constructor(rawSupportOption: any, uiMajorVersion: UiMajorVersion) {
    super(rawSupportOption, uiMajorVersion);

    this.phoneNumbers = (get(rawSupportOption, 'options.phoneNumbers') || []).map(
      phoneObject => new PhoneNumber(phoneObject)
    );
  }
}
