import { DatePicker } from 'antd';
import moment from 'moment';
import React from 'react';
import S from './layoutStyles';

const { RangePicker } = DatePicker;

export default function DateRangeDropDown({
  selectedStartDay,
  selectedEndDay,
  handleCustomDateRangeSelection,
  hideLabel = false
}) {
  const disabledDate = current => {
    return current > moment().endOf('day');
  };

  return (
    <S.DateDropDown>
      {!hideLabel && <span className="label">Date Range:</span>}

      <RangePicker
        allowClear={false}
        disabledDate={disabledDate}
        defaultValue={[moment().subtract(7, 'days'), moment()]}
        format="MM/DD/YYYY"
        onChange={handleCustomDateRangeSelection}
        ranges={{
          Today: [moment(), moment()],
          'Last 7 Days': [moment().add(-6, 'days'), moment()],
          'Last 14 Days': [moment().add(-13, 'days'), moment()],
          'Last 30 Days': [moment().add(-29, 'days'), moment()],
          'Last 90 Days': [moment().add(-89, 'days'), moment()],
          'Year to Date': [moment().startOf('year'), moment()]
        }}
        className="date_range_dropdown"
        value={[selectedStartDay, selectedEndDay]}
        getPopupContainer={(target: any) => target.parentNode}
      />
    </S.DateDropDown>
  );
}
