import { createBrowserHistory } from 'history';
import omit from 'lodash/omit';
import { action, computed, observable, reaction } from 'mobx';
import qs from 'qs';
import UrlPattern from 'url-pattern';
import { intentCreateRoute, loginPageRoute, orgPageRoute, Routes } from '../routes/routes';
import MobxHistory from '../shared/util/MobxHistory';
import { OrgStore } from './orgStore';

export type ORG_PAGE =
  | 'addons'
  | 'combinedAnalytics'
  | 'analytics'
  | 'recrawlHub'
  | 'settings'
  | 'intents'
  | 'interfaceSetup'
  | 'profileSettings'
  | 'userManagement'
  | 'coachBeta'
  | 'coach'
  | 'coachv2'
  | 'voteAudit'
  | 'voteAuditBeta'
  | 'dataDownload'
  | 'categories'
  | 'explore'
  | 'workflows'
  | 'suggestions'
  | 'social-integrations';

const REDIRECT_URL_AFTER_LOGIN = 'REDIRECT_URL_AFTER_LOGIN';
const REDIRECT_URL_TTL = 24 * 60 * 60 * 1000;
const PATH_NAMES_NOT_TO_BE_PRESERVED = ['/login', '/sso-callback'];
export class RouterStore {
  redirectReaction = reaction(
    () => this.activeRoutes.length,
    () => {
      if (this.activeRoutes.length === 0) {
        // The route doesn't exist, so we redirect to the 404
        this.history.push('/404');
      } else if (this.history.location.pathname === '/') {
        setTimeout(() => {
          // the function would only be defined in the next tick, so doing a setTimeout.
          if (this.preserveCurrentUrl) {
            this.preserveCurrentUrl();
          }
        });

        const searchParam = new URL(window.location.href).search;
        this.history.push({
          pathname: loginPageRoute.stringify({ orgPage: 'login' }),
          search: searchParam
        });
      }
    },
    { fireImmediately: true }
  );

  redirectUnauthorizedReaction = reaction(
    () => [
      this.activeOrgPage,
      this._orgStore && this._orgStore.fetchedOrgSettingsOfSelectedOrg,
      this._orgStore && this._orgStore.loadingUser
    ],
    () => {
      if (this.redirectIfNotAuthorized) {
        this.redirectIfNotAuthorized();
      }
    },
    { fireImmediately: true }
  );

  @observable
  _orgStore: OrgStore;

  @observable
  isPreviewUrlModalOpen = false;

  constructor(public history: any = new MobxHistory(createBrowserHistory())) {}

  redirectIfNotAuthorized = () => {
    if (
      this._orgStore &&
      this._orgStore.fetchedOrgSettingsOfSelectedOrg &&
      !this._orgStore.loadingUser &&
      !this._orgStore.canAccessPage(this.activeOrgPage)
    ) {
      // if user has access to multiple orgs, then send to home in case the current page just isn't accessible to the newly selected org
      if (this._orgStore.associatedOrgs.length > 1) {
        this.history.push(
          orgPageRoute.stringify({
            ...this.activeRouteParams,
            orgId: this.activeOrgId,
            orgPage: 'analytics'
          })
        );
      } else {
        this.history.push('/404');
      }
    }
  };

  preserveCurrentUrl = () => {
    // preserve the url, only if the current page is not login or if there are no query params,
    // since we do not want to redirect to login.

    if (!PATH_NAMES_NOT_TO_BE_PRESERVED.includes(this.history.location.pathname) || !this.getRedirectUrl()) {
      return this.setRedirectUrl();
    }
    return false;
  };

  setRedirectUrl = () => {
    const now = new Date();
    const item = {
      location: this.history.location,
      expiry: now.getTime() + REDIRECT_URL_TTL
    };
    localStorage.setItem(REDIRECT_URL_AFTER_LOGIN, JSON.stringify(item));
    return true;
  };

  getRedirectUrl = () => {
    const itemJson = localStorage.getItem(REDIRECT_URL_AFTER_LOGIN);
    if (!itemJson) {
      return null;
    }
    const item = JSON.parse(itemJson);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(REDIRECT_URL_AFTER_LOGIN);
      return null;
    }
    return item.location;
  };

  clearRedirectUrl = () => {
    localStorage.removeItem(REDIRECT_URL_AFTER_LOGIN);
  };

  redirectFromPreservedUrl = () => {
    const pathToRedirect = this.getRedirectUrl();
    if (pathToRedirect && pathToRedirect.pathname) {
      if (this.history.location.pathname === '/global') {
        // doing a href redirect so that it triggers necessary APIs as part of fetchUserContext, and navigate straight to the route intended
        // TODO: remove the email if anything is there (sso emailid)
        window.location.href = `${pathToRedirect.pathname}${pathToRedirect.search}`;
      } else {
        this.history.push(pathToRedirect);
      }
      this.clearRedirectUrl();
    }
  };

  @action
  togglePreviewUrlModal = ({ isOpen }) => {
    this.isPreviewUrlModalOpen = isOpen;
  };

  @computed
  get activeOrgPage(): ORG_PAGE {
    return this.activeRouteParams.orgPage;
  }

  @computed
  get activeRouteParams() {
    const paramsOfActiveRoutes = this.activeRoutes.map(route => route.match(this.history.location.pathname));
    return omit(Object.assign({}, ...paramsOfActiveRoutes), ['_']);
  }

  @computed
  get activeQueryParams() {
    return qs.parse(this.history.location.search.slice(1));
  }

  @computed
  get passwordTokenQueryParam() {
    return this.activeQueryParams.passwordToken;
  }

  @computed
  get activeRoutes() {
    return Routes.filter(route => !!route.match(this.history.location.pathname));
  }

  @computed
  get activeRoute(): UrlPattern | undefined {
    const routes = this.activeRoutes;
    return routes && routes.length > 0 ? routes[0] : undefined;
  }

  @computed
  get activeOrgId() {
    return Number(this.activeRouteParams.orgId);
  }

  @computed
  get onboardingStatus(): string {
    return this.activeRouteParams.status;
  }

  @computed
  get activeResolveUiConfigurationName(): string {
    return this.activeRouteParams.resolveUiConfigurationName;
  }

  @computed
  get activeResolveUiInstanceIndex(): string {
    return this.activeRouteParams.instanceIndex;
  }

  @computed
  get activePluginId(): string {
    return this.activeRouteParams.pluginId;
  }

  @computed
  get workflowId(): string {
    return this.activeRouteParams.workflowId;
  }

  @computed
  get uiInstanceId(): string {
    return this.activeRouteParams.uiInstanceId;
  }

  @computed
  get suggestionId(): string {
    return this.activeRouteParams.suggestionId;
  }

  @computed
  get activeIntentId(): string {
    return this.activeRouteParams.intentId;
  }

  @computed
  get isIntentCreateRoute() {
    return (intentCreateRoute as UrlPattern).match(this.history.location.pathname);
  }

  @computed
  get tabName(): string {
    return this.activeRouteParams.tabName;
  }

  @computed
  get shouldShowPluginSettingsPage(): boolean {
    return Boolean(this.activePluginId);
  }

  @computed
  get activeReportId(): string {
    return this.activeRouteParams.reportId;
  }

  @computed
  get activeReportFilters() {
    return this.activeQueryParams.reportFilters;
  }

  @computed
  get activeInstanceTabId(): string {
    return this.activeRouteParams.instanceTabId;
  }
}

export default new RouterStore();
