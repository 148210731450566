import { action, observable } from 'mobx';

import * as OnboardingSteps from '../shared/util/onboarding_steps';

export default class OnboardingStore {
  onboardingSteps = [
    {
      title: OnboardingSteps.KNOWLEDGE_BASES.title,
      content: 'knowledge-base'
    },
    {
      title: OnboardingSteps.SERVICE_PROVIDER.title,
      content: 'service-provider'
    }
  ];

  @observable
  currentStep = 0;

  @action
  updateCurrentStep(step) {
    this.currentStep = step.id;
  }
}
