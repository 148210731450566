import { InstanceTab } from 'src/routes/InterfaceSetup/constants';
import { TEXT_OVERRIDE_TYPES } from './constants';

export interface IConfigText {
  fullKey: string;
  key: string;
  defaultText: unknown;
  overrideText: any;
}

export interface IConfigurationPath {
  path: string;
  exclude?: string[];
  treatValueAsNull?: string | string[];
}

export type GroupTextType = typeof TEXT_OVERRIDE_TYPES[keyof typeof TEXT_OVERRIDE_TYPES];
export interface ITextGroup {
  name: string;
  configTexts: IConfigText[];
  type: GroupTextType;
  pluginId?: string;
}

export enum UiMajorVersion {
  V4 = 'v4',
  V5 = 'v5'
}

export interface IWidgetIconUrl {
  custom: string | null;
  template: string | null;
}

export enum DialogType {
  Conversational = 'conversational',
  Professional = 'professional',
  ConversationalLegacy = 'conversational-legacy'
}

export enum SolutionsSnippetLength {
  Full = 'full',
  ScreenOptimized = 'screen_optimized',
  Minimized = 'minimized'
}

export enum SolutionsToPresent {
  Minimum = 'minimum',
  Maximum = 'maximum',
  MinimumWithRelatedArticles = 'minimum_with_related_articles'
}

export enum ContactSupportPriority {
  AfterSolutions = 'after_solutions',
  LastSolution = 'last_solution',
  FirstSolution = 'first_solution'
}

export enum SurveyTriggers {
  Solutions = 'solutions',
  Workflows = 'workflows'
}

// this is not an exhaustive list of browsers, only the ones needed
// for minor UI workarounds are listed
export enum DeviceBrowser {
  Safari = 'safari',
  Firefox = 'firefox'
}

export enum AvatarType {
  None = 'none',
  Custom = 'custom'
}

export enum WidgetPosition {
  BottomRight = 'bottom-right',
  BottomLeft = 'bottom-left',
  FullScreen = 'full-screen'
}

export interface IPane {
  id: string;
  menuItem: string;
  uiVersions?: string[];
}

export interface IWarnings {
  tab: InstanceTab;
  type?: string;
}
