import { observable } from 'mobx';
import ModalInstanceColors from 'store/models/ModalInstanceColors';
import { IWidgetIconUrl } from '../ResolveUI/types';
const chatIcon =
  '<svg width="40" height="40"><g transform="translate(5 4)" fill="#000" fill-rule="evenodd"><path d="M7.16 24.018a.825.825 0 00-.521-.188H2.753a2.76 2.76 0 01-1.946-.803A2.733 2.733 0 010 21.091V2.739A2.745 2.745 0 012.753 0h25.494c.73 0 1.43.288 1.946.801.517.515.807 1.211.807 1.938v18.352c0 .725-.29 1.423-.807 1.936a2.76 2.76 0 01-1.946.803h-9.364a.794.794 0 00-.795.785c-.024 2.22-.086 8.385-.086 8.385L7.16 24.018zm.794-2.069a.975.975 0 00-.626-.227H3.393c-.365 0-.714-.144-.973-.401a1.368 1.368 0 01-.404-.969V3.302a1.372 1.372 0 011.377-1.37h24.08c.364 0 .714.144.972.4.26.258.404.606.404.97v17.05a1.379 1.379 0 01-1.376 1.37h-10.29a.951.951 0 00-.673.28.938.938 0 00-.274.672l.061 6.269-8.343-6.994z"></path><rect x="5" y="7" width="20" height="2" rx="1"></rect><rect x="5" y="13" width="14" height="2" rx="1"></rect></g></svg>';

export default class LauncherConfiguration {
  @observable
  backgroundClosed: string;
  @observable
  backgroundClosedHover: string;
  @observable
  backgroundOpen: string;
  @observable
  iconColor: string;
  @observable
  borderRadius: string;
  @observable
  icon: string;
  @observable
  iconSvg: IWidgetIconUrl;
  @observable
  position: string;
  @observable
  textLabel: string;
  @observable
  size: number;
  @observable
  mobileSize: number;

  constructor(colors: ModalInstanceColors) {
    this.backgroundClosed = colors.primary;
    this.backgroundOpen = colors.secondary;
    this.iconColor = '#FFFFFF';
    this.borderRadius = '6px';
    this.icon = chatIcon;
  }
}
