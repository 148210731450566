const UrlPattern = require('url-pattern');

/* Route Definitions */

// home page (root)
const _homeRoute = '/';
export const homePageRoute = new UrlPattern(_homeRoute);

// login page
const _loginRoute = '/login';
export const loginPageRoute = new UrlPattern(_loginRoute + '(/*)');

// combined analytics page flow
const _combinedOrgRoute = '/org/combinedAnalytics';
export const combinedOrgPageRoute = new UrlPattern(_combinedOrgRoute);

// main page flow
const _orgRoute = '/org/:orgId';
const _orgPageRoute = _orgRoute + '/:orgPage';
export const orgPageRoute = new UrlPattern(_orgPageRoute + '(/*)');

// 404 page flow
const _errorRoute = '/404';
export const errorPageRoute = new UrlPattern(_errorRoute);

// global user page flow
const _globalUserRoute = '/global';
export const globalUserPageRoute = new UrlPattern(_globalUserRoute);

// two-factor auth flow
const _twoFactorAuthRoute = '/two-factor';
export const twoFactorAuthPageRoute = new UrlPattern(_twoFactorAuthRoute);

const _ssoCallbackRoute = '/sso-callback';
export const ssoCallbackRoute = new UrlPattern(_ssoCallbackRoute);

// faq page flow
const _faqPageRoute = '/faq';
export const faqPageRoute = new UrlPattern(_faqPageRoute);

// onboarding flow
const _onboardingPattern = _orgRoute + '/onboarding';
export const onboardingPageRoute = new UrlPattern(_onboardingPattern + '(/*)');

// password reset flow
const _passwordReset = _orgRoute + '/passwordReset';
export const passwordResetRoute = new UrlPattern(_passwordReset + '(/*)');

// forgot password reset flow
const _forgotPassword = '/reset';
export const forgotPasswordRoute = new UrlPattern(_forgotPassword + '(/*)');

const _onboardingServiceProvidersPattern = _onboardingPattern + '/service_provider';
const _serviceProviderStatusPattern = '/service_provider/:status';
export const onboardingServiceProviderRoute = new UrlPattern(_onboardingServiceProvidersPattern);
export const onboardingStatusRoute = new UrlPattern(_serviceProviderStatusPattern);

const _uiConfigurationEditPatternOptions = {
  segmentValueCharset: 'a-zA-Z0-9-_~ %.'
};

const _resolveUiConfigurationEditPattern = _orgRoute + '/interfaceSetup/:resolveUiConfigurationName';
export const resolveUiConfigurationEditRoute = new UrlPattern(
  _resolveUiConfigurationEditPattern,
  _uiConfigurationEditPatternOptions
);
export const resolveUiInstanceEditRoute = new UrlPattern(
  _resolveUiConfigurationEditPattern + '/instance/:instanceIndex/:instanceTabId',
  _uiConfigurationEditPatternOptions
);

export const workflowEditRoute = new UrlPattern(_orgRoute + '/workflows/:workflowId');

const _suggestionsListEditPattern = _orgRoute + '/suggestions/:uiInstanceId';
export const instanceSuggestionsRoute = new UrlPattern(_suggestionsListEditPattern);
export const suggestionEditRoute = new UrlPattern(_suggestionsListEditPattern + '/:suggestionId');

export const settingsTabRoute = new UrlPattern(_orgRoute + '/settings/:tabName');

const _pluginTypePattern = _orgRoute + '/addons/:pluginId';

export const pluginSettingsRoute = new UrlPattern(
  _pluginTypePattern + '/:resolveUiConfigurationName/:instanceIndex',
  _uiConfigurationEditPatternOptions
);

export const exploreRoute = new UrlPattern(_orgRoute + '/explore');
export const analyticsRoute = new UrlPattern(_orgRoute + '/analytics/:reportId');

export const intentEditRoute = new UrlPattern(_orgRoute + '/intents/:intentId');
export const intentCreateRoute = new UrlPattern(_orgRoute + '/intents/create/:intentId');

/* End Route Definitions */

export const Routes = [
  combinedOrgPageRoute,
  orgPageRoute,
  loginPageRoute,
  onboardingPageRoute,
  globalUserPageRoute,
  ssoCallbackRoute,
  twoFactorAuthPageRoute,
  errorPageRoute,
  homePageRoute,
  passwordResetRoute,
  forgotPasswordRoute,
  onboardingServiceProviderRoute,
  resolveUiInstanceEditRoute,
  resolveUiConfigurationEditRoute,
  workflowEditRoute,
  onboardingStatusRoute,
  faqPageRoute,
  instanceSuggestionsRoute,
  suggestionEditRoute,
  settingsTabRoute,
  pluginSettingsRoute,
  analyticsRoute,
  intentEditRoute,
  intentCreateRoute
];
