import * as FullStory from '@fullstory/browser';
import * as mobx from 'mobx';
import { Provider } from 'mobx-react';
import { default as React } from 'react';
import * as ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import * as reactHotKeys from 'react-hotkeys';
import './global.less';
import AppLayout from './layouts/AppLayout';
// jointJS custom element definitions
import './routes/Workflows/jointjs/WorkflowStep';
import * as serviceWorker from './serviceWorker';
import loadDefaultFont from './shared/util/fontLoader';
import { isLocalhost } from './shared/util/misc';
import './shared/util/sentry';
import './shared/util/solvvyLogRocket';
import { defaultStores } from './store/rootStore';

// see https://www.npmjs.com/package/@fullstory/browser
// The FullStory orgId can be found in FullStory Settings > FullStory Setup
// at https://app.fullstory.com/ui/A1P0T/settings/general
if (!isLocalhost) {
  FullStory.init({ orgId: 'A1P0T', devMode: process.env.REACT_APP_ENABLE_FULLSTORY !== 'true' });
}

const stores = defaultStores;
mobx.configure({ enforceActions: 'observed' });

reactHotKeys.configure({ ignoreTags: [] }); // allow shortcuts to work even if input and textarea focused

// load default fonts
loadDefaultFont();

ReactDOM.render(
  <Provider {...stores}>
    <Helmet>
      <title>Solvvy Dashboard</title>
    </Helmet>
    <AppLayout />
  </Provider>,
  document.getElementById('solvvy-app') as HTMLElement
);

// by default the service worker is not registered
serviceWorker.unregister();
