import { OrgPermission } from '@solvvy/util/lib/authorization';
import { Button } from 'antd';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { OrgStore } from 'src/store/orgStore';
import { IStep } from '../../store/ResolveUI/Workflow';
import { typedInject } from '../../store/rootStore';
import { WorkflowStore } from '../../store/workflowStore';
import { STEP_DISPLAY_TYPE } from './constants';
import CreateWorkflowModal from './CreateWorkflowModal';
import { StyledCardActionsPopoverContent } from './workflow.styles';

interface IProps {
  step: IStep;
  onActionClicked?: () => void;
  workflowStore: WorkflowStore;
  orgStore: OrgStore;
}

@observer
class StepActions extends Component<IProps, { showCreateWorkflowModal: boolean }> {
  state = {
    showCreateWorkflowModal: false
  };
  render() {
    const { workflowStore, onActionClicked, step } = this.props;
    const canUpdate = this.props.orgStore.hasPermission(OrgPermission.workflowUpdate);
    const fullyEditable = workflowStore.workflowToEditIsFullyEditable;

    let actionClickedCallback = onActionClicked;
    if (!actionClickedCallback) {
      actionClickedCallback = () => undefined;
    }

    if (isNil(step)) {
      return null;
    }

    const { selectedSteps, isSurveyWorkflow, deleteSteps, duplicateStep, toggleTagsModalVisibility } = workflowStore;
    if (!selectedSteps || selectedSteps.length === 0) {
      return null;
    }

    const { onStartAction, onStartOrContinueAction } = workflowStore.workflowToEdit.workingVersion.options || {};
    const anyStartAction = onStartAction || onStartOrContinueAction;

    const isOverlayStep = workflowStore.isOverlayStep(step);
    const isOverlayStepPresentInSelectedSteps =
      isSurveyWorkflow &&
      selectedSteps.findIndex(selectedStep => selectedStep.displayType === STEP_DISPLAY_TYPE.OVERLAY) !== -1;

    return (
      <StyledCardActionsPopoverContent>
        {selectedSteps.length > 1 ? (
          <>
            {canUpdate && fullyEditable && (
              <Button
                type="link"
                disabled={isOverlayStepPresentInSelectedSteps}
                onClick={async () => {
                  for (const selectedStep of selectedSteps) {
                    await duplicateStep(selectedStep);
                  }
                  actionClickedCallback!();
                }}
              >
                Duplicate Selected Steps
              </Button>
            )}

            <Button
              type="link"
              disabled={isOverlayStepPresentInSelectedSteps}
              onClick={() => {
                workflowStore.copySteps();
                actionClickedCallback!();
              }}
            >
              Copy Selected Steps
            </Button>
            {canUpdate && fullyEditable && (
              <Button
                type="link"
                onClick={() => {
                  toggleTagsModalVisibility();
                  actionClickedCallback!();
                }}
              >
                Add Tags to All Selected Steps
              </Button>
            )}
            {canUpdate && (
              <Button
                type="link"
                disabled={isOverlayStepPresentInSelectedSteps}
                onClick={() => {
                  this.setState({ showCreateWorkflowModal: true });
                  actionClickedCallback!();
                }}
              >
                Save Selected Steps as a New Workflow
              </Button>
            )}
            {canUpdate && fullyEditable && (
              <Button
                type="link"
                danger={true}
                onClick={() => {
                  deleteSteps(selectedSteps);
                  actionClickedCallback!();
                }}
              >
                Delete Selected Steps
              </Button>
            )}

            {this.state.showCreateWorkflowModal && (
              <CreateWorkflowModal
                onCancel={() => this.setState({ showCreateWorkflowModal: false })}
                createFromSelectedStepsMode={true}
              />
            )}
          </>
        ) : (
          <>
            {canUpdate && fullyEditable && (
              <>
                <Button
                  type="link"
                  className="duplicate"
                  disabled={isOverlayStep}
                  onClick={() => {
                    workflowStore.duplicateStep(workflowStore.selectedStep);
                    actionClickedCallback!();
                  }}
                >
                  Duplicate
                </Button>
                <Button
                  type="link"
                  disabled={Boolean(anyStartAction) || isSurveyWorkflow}
                  className="start"
                  onClick={() => {
                    workflowStore.setAsStartStep();
                    actionClickedCallback!();
                  }}
                >
                  Set as Start Step
                </Button>
              </>
            )}

            <Button
              type="link"
              className="copy"
              disabled={isOverlayStep}
              onClick={() => {
                workflowStore.copySteps();
                actionClickedCallback!();
              }}
            >
              Copy
            </Button>
            {canUpdate && fullyEditable && (
              <>
                <Button
                  type="link"
                  className="copy"
                  disabled={isOverlayStep}
                  onClick={() => {
                    workflowStore.pasteSteps(workflowStore.selectedStepId);
                    actionClickedCallback!();
                  }}
                >
                  Paste and Replace
                </Button>
                <Button
                  type="link"
                  className="delete"
                  onClick={() => {
                    workflowStore.deleteStep();
                    actionClickedCallback!();
                  }}
                >
                  Delete
                </Button>
              </>
            )}
          </>
        )}
      </StyledCardActionsPopoverContent>
    );
  }
}

export default typedInject('orgStore', 'workflowStore')(StepActions);
