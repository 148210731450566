import V4_UI_TEXT_DE from '@solvvy/locales/de/v4-ui/v4-ui';
import V4_UI_TEXT_EN from '@solvvy/locales/en/v4-ui/v4-ui';
import V4_UI_TEXT_ES from '@solvvy/locales/es/v4-ui/v4-ui';
import V4_UI_TEXT_FR from '@solvvy/locales/fr/v4-ui/v4-ui';
import V4_UI_TEXT_IT from '@solvvy/locales/it/v4-ui/v4-ui';
import V4_UI_TEXT_JA from '@solvvy/locales/ja/v4-ui/v4-ui';
import V4_UI_TEXT_NL from '@solvvy/locales/nl/v4-ui/v4-ui';
import V4_UI_TEXT_PT from '@solvvy/locales/pt/v4-ui/v4-ui';
import V4_UI_TEXT_ZH from '@solvvy/locales/zh/v4-ui/v4-ui';
import { get } from 'lodash';
import { computed } from 'mobx';
import { recordErrors } from '../../shared/util/recordErrors';
import { OrgStore } from '../orgStore';

const langTextsMap = {
  en: V4_UI_TEXT_EN,
  es: V4_UI_TEXT_ES,
  de: V4_UI_TEXT_DE,
  fr: V4_UI_TEXT_FR,
  it: V4_UI_TEXT_IT,
  ja: V4_UI_TEXT_JA,
  nl: V4_UI_TEXT_NL,
  pt: V4_UI_TEXT_PT,
  zh: V4_UI_TEXT_ZH
};

const DEFAULT_LANG_KEYS = {
  editQuestion: 'topBar.text.backToQuestionForm',
  hide: 'topBar.text.close',
  goBack: 'ticketForm.text.back',
  poweredBySolvvy: 'text.poweredBySolvvy',
  questionValue: 'solutionsView.text.sampleQuestion',
  solutionTitle: 'solutionsView.text.sampleSolutionTitle',
  solutionContent: 'solutionsView.text.sampleSolutionContent',
  articleLink: 'solutionsView.text.viewFullArticle',
  solvedQuestionText: 'solutionsView.text.solvedQuestion',
  yesButtonText: 'solutionsView.text.solved'
};

export class MultiLangDefaultText {
  orgDefaultLang = 'en';

  constructor(private _orgStore: OrgStore) {}

  @computed
  get orgLanguageText(): any {
    try {
      const defaultLangText = {};
      const orgLanguage = get(this._orgStore.orgSettings, 'languages') || this.orgDefaultLang;
      const localeStrings = langTextsMap[orgLanguage.split(',')[0]];
      for (const key in DEFAULT_LANG_KEYS) {
        if (DEFAULT_LANG_KEYS.hasOwnProperty(key)) {
          defaultLangText[key] = get(localeStrings, DEFAULT_LANG_KEYS[key], '');
        }
      }
      return defaultLangText;
    } catch (e) {
      recordErrors(e);
      // TODO: return something more meaningful.
      return DEFAULT_LANG_KEYS;
    }
  }
}
