import find from 'lodash/find';

import { recommendationService } from './';
import { allUsingApi } from './util';

// Function: search
//
// Arguments:
//  - id: The org id to search from.
//  - subject: The subject of the query. We default to using the query subject.
//  - content: The content of the query. We default to using the query description.
//  - limit: The maximum number of solutions to return.
// Returns: a promise that resolves to a list of solution objects.
const search = (
  api,
  id: string,
  content: string,
  solution_tags: string[],
  limit: number = 3,
  intelligent: boolean = true
): Promise<{
  data: Array<{
    solutions: Array<{
      id: string;
      content: string;
      metadata: { title: string; url: string; subtitle: string };
      resource: { type: string };
      status;
    }>;
  }>;
}> => {
  return api.post('/v1/solutions/search', {
    org_id: id,
    query: content,
    group_by_webpage: true,
    limit,
    solution_tags,
    create_query: false,
    // disable the new api-search confident filtering logic which is on by default now
    confident_filter: false,
    // api-search intelligent param defaults to true, so only need to pass param if false
    ...(intelligent ? {} : { intelligent: false })
  });
};

// Function: searchQueries
//
// Returns a list of questions using elasticSearch.
// Arguments:
//  - id: The org id to search from.
//  - content: The search term.
//  - limit: The maximum number of questions to return.
// Returns: a promise that resolves to a list of question objects.
const searchQueries = async (api, id, content, limit = 3) => {
  const result = await api.get('/v1/queries/search', {
    org_id: id,
    query: content,
    min_result_length: 10,
    // has_vote: false,
    limit
  });

  if (result.data && result.data.length > 0) {
    const queryIds = result.data.map(searchResult => searchResult.id);
    const { data: existingQueryResult } = await recommendationService.checkIfQueriesExists(queryIds, id);
    if (existingQueryResult.length > 0) {
      const existingQueryIds = existingQueryResult.map(queryResult => queryResult.query_id);
      for (const r in result.data) {
        if (result.data.hasOwnProperty(r)) {
          const searchResultQueryId = result.data[r].id;
          if (existingQueryIds.includes(searchResultQueryId)) {
            const existingQueryInfo = find(
              existingQueryResult,
              queryResult => queryResult.query_id === searchResultQueryId
            );
            result.data[r].answered = true;
            result.data[r].analysis_id = existingQueryInfo ? existingQueryInfo.analysis_id : '';
          } else {
            result.data[r].answered = false;
          }
        }
      }
    }
  }

  return result;
};

const getQueryInfo = (api, query_id: string, org_id?: string) => {
  return api.get('/v1/queries', {
    id: query_id,
    org_id
  });
};

const getSolutionsInfo = (api, solutionsIds, org_id) => {
  return api.get('/v2/solutions', {
    ids: solutionsIds,
    ...(org_id && { org_id })
  });
};

export const searchService = api => {
  return allUsingApi(api, {
    search,
    searchQueries,
    getQueryInfo,
    getSolutionsInfo
  });
};

export default searchService;
