import { isArray, isString } from 'lodash';
import createTheme from 'styled-components-theme';
import { Colors } from './Colors';

const colorPicker: typeof Colors = createTheme(...Object.keys(Colors));

/** function to pick a color from the array based on the text passed. Currently used to pick a color for Tags. */
const TAG_COLORS = ['magenta', 'volcano', 'gold', 'green', 'cyan', 'blue', 'geekblue', 'purple'];
export const pickColorForText = (text, colors = TAG_COLORS) => {
  if (!isString(text) || !isArray(colors)) {
    return;
  }
  let sum = 0;
  for (const t of text) {
    sum += t.charCodeAt(0);
  }
  return colors[sum % colors.length];
};
export default colorPicker;
