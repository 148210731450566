import { DialogType } from 'src/store/ResolveUI/types';

export const WORKFLOW_PURPOSE = {
  SURVEY: 'survey'
} as const;

export const STEP_DISPLAY_TYPE = {
  OVERLAY: 'overlay',
  RATING: 'rating',
  CAROUSEL: 'carousel'
} as const;

export const OVERLAY_COMPONENT_LABEL = {
  Button: 'Form Button',
  Paragraph: 'Title',
  Textarea: 'Form Input Label'
} as const;

export const ADD_COMPONENT_BUTTON_LABEL = {
  paragraph: {
    [DialogType.Conversational]: 'Add Prompt',
    [DialogType.Professional]: 'Add Paragraph',
    [DialogType.ConversationalLegacy]: 'Add Paragraph'
  },
  button: {
    [DialogType.Conversational]: 'Add Cue',
    [DialogType.Professional]: 'Add Button',
    [DialogType.ConversationalLegacy]: 'Add Button'
  },
  media: {
    [DialogType.Conversational]: 'Add Media'
  }
} as const;

export const COMPONENT_LABEL = {
  paragraph: {
    [DialogType.Conversational]: 'Prompt',
    [DialogType.Professional]: 'Paragraph Content',
    [DialogType.ConversationalLegacy]: 'Paragraph Content'
  },
  button: {
    [DialogType.Conversational]: 'Cue Text',
    [DialogType.Professional]: 'Button Text',
    [DialogType.ConversationalLegacy]: 'Button Text'
  }
} as const;

export const PANEL_TITLE = {
  paragraph: {
    [DialogType.Conversational]: 'Bot Dialog',
    [DialogType.Professional]: 'Paragraph',
    [DialogType.ConversationalLegacy]: 'Paragraph'
  },
  button: {
    [DialogType.Conversational]: 'Cue',
    [DialogType.Professional]: 'Button',
    [DialogType.ConversationalLegacy]: 'Button'
  }
} as const;
