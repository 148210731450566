import format from 'date-fns/format';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import reject from 'lodash/reject';
import { action, computed, observable, toJS } from 'mobx';
import { asyncAction } from 'mobx-utils';

import { feedbackService, recommendationService, searchService } from '../services';
import asyncComputed from '../shared/util/asyncComputed';
import { NotificationManager } from '../shared/util/NotificationManager';
import { PromiseState } from '../shared/util/PromiseStates';
import { recordErrors } from '../shared/util/recordErrors';
import Solution from './models/Solution';
import { OrgStore } from './orgStore';

export interface ICoachStats {
  total_addressed: string;
  total_not_self_serviceable: string;
  user_addressed: string;
  user_not_self_serviceable: string;
  total_remaining: string; // recommendations with status that is not addressed|not_deflectable|junk
}

interface IQueryInfo {
  id?: string;
  description?: any;
  created_at?: string;
}

interface IQuerySolutionData {
  trainingQueryData: {
    id: string;
    analysis_id: string;
    queryInfo: IQueryInfo;
    solutions: Solution[];
    offset: any;
    queryMetadata: {
      solution_tags: string[] | null;
    };
  };
}

export class CoachStore {
  @observable
  solutionTab: number = 0; // This observable determines the open tab.
  @observable
  loadingMoreSolutions: PromiseState | null; // Determines if search solutions are being loaded.
  @observable
  moreSolutions: Solution[] = []; // Array of solutions loaded from a search.
  @observable
  searchMoreValue = ''; // The solution search query.
  @observable
  offset = 0; // The index of the current question.
  @observable
  notSelfServiceable = false; // Determine if this is a question that can be answered automatically.
  @observable
  notRealCustomerQuery = false; // Determine if this is not a real customer question.
  @observable
  isKnowledgeGap = false; // Determine if the solution is not considered to be in the knowledge base.
  @observable
  loadingSearchedQueries: PromiseState | null; // Determines if queries are being loaded.
  @observable
  searchedQueries = []; // Searched questions.
  @observable
  searchQueryValue = ''; // The search query.
  @observable
  selectedQuery: any = {}; // The selected query.
  @observable
  saving: boolean = false; // Determine if we are currently saving.
  @observable
  showExampleSelected: boolean = false; // This observable determines if we should show an example suggested solution for the tour.

  shallowMoreSolutions: Solution[] = [];
  @observable
  updatedSolutions: Solution[] = []; // The updated set of solutions. Checking to see if this attribute is still necessary.
  // Function: queryWithSolutionData
  //
  // Loads solution data using the Tutor API.
  // Returns an object containing the suggested solutions, the question, the offset, and additional information.
  // In order to show previous queries, we need to obtain all suggestions (viewed included), then filter.
  // To retain order consistency, we assume all data originates ordered. Otherwise, we shall use the date.
  queryWithSolutionData = asyncComputed<IQuerySolutionData>(async () => {
    let queryData: any;
    let analysis_id: string;
    let currentOffset = 0;
    if (isEmpty(this.selectedQuery)) {
      // We are getting a question from the training set.
      const {
        result: {
          data: [trainingData]
        },
        offset
      } = await this.getSuggestionsById(this.offset, 0); // Get the current question in the training set.

      queryData = trainingData;
      analysis_id = queryData && queryData.analysis_id;
      currentOffset = offset;
    } else {
      analysis_id = this.selectedQuery.analysis_id;
      // We are getting a searched question by id.
      queryData = {
        // Currently "faking" a response from the API.
        query: {
          subject: this.selectedQuery.searchable,
          description: this.selectedQuery.searchable,
          id: this.selectedQuery.id,
          created_at: ''
        }
      };
    }
    return this.constructQuerySolutionData(queryData, currentOffset, analysis_id);
  });

  coachStats = asyncComputed<ICoachStats>(async () => {
    if (!isNaN(this._orgStore.selectedOrgId)) {
      const { data: stats } = await recommendationService.getCoachStats(
        this._orgStore.selectedOrgId,
        this._orgStore.currentUserContext.id
      );
      return stats;
    }
  });

  constructor(private _orgStore: OrgStore, private _notificationManager: NotificationManager) {}

  /*Recursively get training set if no query found. */
  async getSuggestionsById(offset: number, maxRecursiveCall: number) {
    if (isEmpty(this._orgStore.currentUserContext)) {
      await this._orgStore.fetchUserContext();
    }

    if (!isNaN(this._orgStore.selectedOrgId)) {
      const result = await recommendationService.getRecommendation(
        this._orgStore.selectedOrgId,
        this._orgStore.currentUserContext.id.toString(),
        offset
      );
      if (!isEmpty(result.data) && isNil(result.data[0].query) && maxRecursiveCall < 10) {
        return this.getSuggestionsById(offset + 1, maxRecursiveCall + 1);
      }
      return {
        result,
        offset
      };
    }
  }

  // Retrieves solutions for a given query and repackages them into the query.
  // Arguments:
  //  - queryData: The query object to search for solutions for.
  // Returns: An object containing the necessary query information and the suggested solutions.
  async constructQuerySolutionData(
    queryData: { id: any; query: any; created_at: string },
    offset: number,
    analysis_id: string
  ) {
    // Get question data
    if (isEmpty(queryData) || isEmpty(queryData.query)) {
      return {
        trainingQueryData: {
          id: '',
          analysis_id: '',
          queryInfo: {
            id: ''
          },
          solutions: [],
          offset: 0,
          count: 0,
          queryMetadata: {
            solution_tags: []
          }
        }
      };
    }

    let queryMetadata = { solution_tags: null };

    try {
      const { data } = await searchService.getQueryInfo(queryData.query.id, this._orgStore.selectedOrgId);
      queryMetadata = data[0];
    } catch (e) {
      // capture this but dont report back to the client.
      recordErrors(e);
    }

    const solutions = await this.fetchSolutionsData(queryData, queryMetadata.solution_tags);
    await this.markQuestionAsViewed(queryData.id);

    return {
      // Set the question data.
      trainingQueryData: {
        id: queryData.id,
        queryInfo: queryData.query,
        analysis_id,
        solutions,
        offset,
        queryMetadata
      }
    };
  }

  async markQuestionAsViewed(id) {
    // a specific use case where for now we allow question to be marked as viewed only for global user. This check would be removed once we expose to everyone
    if (this.isGlobalUser && id) {
      await recommendationService.updateRecommendationStatus({
        id,
        status: 'viewed',
        user_id: this._orgStore.currentUserContext.id.toString(),
        org_id: this._orgStore.selectedOrgId
      });
    }
  }

  async markQuestionAsUnviewed() {
    // a specific use case where for now we allow question to be marked as viewed only for global user. This check would be removed once we expose to everyone
    if (this.isGlobalUser && this.recommendationId) {
      await recommendationService.updateRecommendationStatus({
        id: this.recommendationId,
        status: 'unviewed',
        user_id: this._orgStore.currentUserContext.id.toString(),
        org_id: this._orgStore.selectedOrgId
      });
    }
  }

  async fetchSolutionsData(queryData: { query: { subject: any; description: any; id: string } }, solution_tags) {
    let solutions: Solution[] = [];
    if (!isEmpty(queryData.query)) {
      let solutionResp;
      try {
        const { data } = await searchService.search(
          // Use search API to get initial suggested solutions
          this._orgStore.selectedOrgId,
          queryData.query.description,
          solution_tags
        );
        solutionResp = data;
      } catch (e) {
        recordErrors(e);
      }
      // Reformat the solution data into an array.
      solutions = solutionResp.reduce(
        (result: Solution[], respData: { solutions: { map: (arg0: (value: any) => void) => void } }) => {
          respData.solutions.map(
            (value: {
              id: string;
              content: string;
              metadata: { title: string; url: string; subtitle: string };
              resource: { type: string };
            }) => {
              result.push(
                new Solution(
                  queryData.query.id,
                  value.id,
                  value.content,
                  value.metadata,
                  '0',
                  value.resource.type,
                  this._orgStore.currentUserContext.isGlobalUser ? 'solvvy_feedback' : 'expert_feedback',
                  'direct',
                  this._orgStore.currentUserContext.id.toString(),
                  false
                )
              );
            }
          );
          return result;
        },
        []
      );
    }

    return solutions;
  }

  // This action opens the suggested solutions tab.
  @action
  openSuggestedSolutions = () => {
    this.solutionTab = 0;
  };

  // This action opens the search solutions tab.
  @action
  openSearchSolutions = () => {
    this.solutionTab = 1;
  };

  // Show an example card. Used for the Coach Tour.
  @action
  showExampleSelectedSolution = () => {
    this.showExampleSelected = true;
  };

  // Hide the example card. Used for the Coach Tour.
  @action
  hideExampleSelectedSolution = () => {
    this.showExampleSelected = false;
  };

  // Function: searchMoreSolution
  //
  // Loads searched solutions using the search service and the Tutor API.
  // This function is void. It loads the data into the coachStore state.
  @asyncAction
  *searchMoreSolution(content: string) {
    this.loadingMoreSolutions = PromiseState.pending;
    this.moreSolutions = [];
    try {
      if (isEmpty(content)) {
        // Check to make sure there's a search query.
        this.loadingMoreSolutions = null;
      } else {
        let intelligent = true;

        // Use Elasticsearch (intelligent: false) for Search All Solutions if org setting enabled
        if (this._orgStore.orgSettings.coach_search_all_solutions_elasticsearch) {
          intelligent = false;
        }

        const { data: resp } = yield searchService.search(
          this._orgStore.selectedOrgId,
          content,
          this.solutionTags,
          100,
          intelligent
        ); // Send the API request.
        this.moreSolutions = resp.reduce(
          (result: Solution[], solution: { solutions: { map: (arg0: (value: any) => void) => void } }) => {
            // Map the results to a flat array.
            solution.solutions.map(
              (value: {
                id: string;
                content: string;
                metadata: { title: string; url: string; subtitle: string };
                resource: { type: string };
              }) => {
                result.push(
                  new Solution(
                    this.queryData.id!,
                    value.id,
                    value.content,
                    value.metadata,
                    '0',
                    value.resource.type,
                    this._orgStore.currentUserContext.isGlobalUser ? 'solvvy_feedback' : 'expert_feedback',
                    'direct',
                    this._orgStore.currentUserContext.id.toString(),
                    false
                  )
                );
              }
            );
            return result;
          },
          []
        );
        this.shallowMoreSolutions = toJS(this.moreSolutions);
        this.loadingMoreSolutions = PromiseState.fulfilled;
      }
    } catch (e) {
      this.loadingMoreSolutions = PromiseState.rejected; // We hit an error.
      recordErrors(e);
    }
  }

  // Function: searchQueries
  //
  // Loads searched questions using the search service
  // This function is void. It loads the data into the coachStore state.
  @asyncAction
  *searchQueries(content: string) {
    this.loadingSearchedQueries = PromiseState.pending;
    this.searchedQueries = [];
    try {
      if (isEmpty(content)) {
        // Check to make sure there's a question query.
        this.loadingSearchedQueries = null;
      } else {
        const { data: resp } = yield searchService.searchQueries(this._orgStore.selectedOrgId, content, 15); // Send the API request.
        this.searchedQueries = resp; // Set the searched queries.
        this.loadingSearchedQueries = PromiseState.fulfilled;
      }
    } catch (e) {
      this.loadingSearchedQueries = PromiseState.rejected;
      recordErrors(e);
    }
  }

  // Function: setSelectedQuery
  //
  // Set the currently selected query.
  // Arguments:
  //  - query: The query object that has been selected.
  // Returns: void.
  @action
  setSelectedQuery(query: any, analysisId: string) {
    this.selectedQuery = query;
    this.selectedQuery.analysis_id = analysisId;
  }

  // Function: saveSolutionAndUpdateQuery
  //
  // Saves data for a question. This includes the selected solutions and the appropriate status.
  @asyncAction
  *saveSolutionAndUpdateQuery() {
    try {
      this.saving = true;
      // Save the solutions to ml-data.votes. We link the solution ids to the question id (in queries).
      for (const solution of this.allSelectedSolutions) {
        yield feedbackService.saveUserFeedback(
          this._orgStore.selectedOrgId,
          this._orgStore.currentUserContext.id.toString(),
          this.queryData.id,
          solution.id,
          solution.solution_resource_type,
          solution.relevance,
          solution.source
        );
      }
      // Update/Save the questions in api_tutor (recommendations).
      // Valid statuses = skipped|hidden|unviewed|addressed|demo|demo_undeflected|junk|not_deflectable|not_in_kb|not_relevant_answer|relevant_answer|verified_answer
      let status = 'addressed';
      if (isEmpty(this.allSelectedSolutions) && this.notSelfServiceable) {
        status = 'not_deflectable';
      } else if (this.notRealCustomerQuery) {
        status = 'junk';
      }
      if (this.recommendationId === undefined && !isEmpty(this.selectedQuery)) {
        yield recommendationService.insertAsViewed({
          org_id: this.selectedQuery.org_id,
          query_id: this.selectedQuery.id,
          analysis_id: this.analysisId,
          status,
          user_id: this._orgStore.currentUserContext.id
        }); // If the question is not in recommendations, insert it.
      } else {
        yield recommendationService.updateRecommendationStatus({
          id: this.recommendationId,
          status,
          user_id: this._orgStore.currentUserContext.id.toString(),
          org_id: this._orgStore.selectedOrgId
        }); // If the question is from the training set, update it.
      }
      this._notificationManager.success({
        title: 'Your feedback saved!!!',
        message: `On to the next question!`
      });
      this.resetData();
      this.queryWithSolutionData.refresh(); // Go to the next question in the list.
      this.coachStats.refresh();
    } catch (e) {
      this._notificationManager.error({
        title: 'Oops something went wrong',
        message: `Try after sometime or skip to next question.`
      });
      recordErrors(e);
      return e;
    }
  }

  @action
  increaseOffset(currentOffsetValue = this.offset) {
    // Get the next question.
    this.resetData();
    this.offset = currentOffsetValue + 1;
  }

  @action
  decreaseOffset(currentOffsetValue = this.offset) {
    // Get the previous question.
    this.resetData();
    this.offset = currentOffsetValue - 1;
  }

  @action
  resetData() {
    // Reset the entire coachStore state.
    this.updatedSolutions = [];
    this.moreSolutions = [];
    this.searchMoreValue = '';
    this.shallowMoreSolutions = [];
    this.notSelfServiceable = false;
    this.notRealCustomerQuery = false;
    this.isKnowledgeGap = false;
    this.searchedQueries = [];
    this.searchQueryValue = '';
    this.selectedQuery = {};
    this.saving = false;
    this.solutionTab = 0;
  }

  @action
  resetSavedData() {
    // Reset the shallow persistent state (array values).
    this.solutionData.map(data => (data.relevance = '0'));
    this.moreSolutions.map(data => (data.relevance = '0'));
    this.updatedSolutions = [];
  }

  @action
  updateVotedRelevance(solution: Solution, relevanceValue: string) {
    // Rate the relevance of a given solution.
    // This function operates only on suggested solutions.
    if (findIndex(this.solutionData, { id: solution.id, relevance: relevanceValue }) === -1) {
      solution.relevance = relevanceValue;
    } else {
      solution.relevance = '0';
    }
  }

  @action
  updateMoreSolutionRelevance(solution: Solution, relevanceValue: string) {
    // Rate the relevance of a given solution.
    // Note that this function is different from updateVotedRelevance() because it operates on search solutions.
    this.updateRelevance(solution, relevanceValue, this.moreSolutions, this.shallowMoreSolutions);
  }

  @action
  updateRelevance(
    solution: Solution,
    relevanceValue: string,
    solutionsArray: Solution[],
    shallowSolutionsArray: Solution[]
  ) {
    let updatedRelevance = relevanceValue;
    if (findIndex(solutionsArray, { id: solution.id, relevance: relevanceValue }) === -1) {
      solution.relevance = relevanceValue;
    } else {
      solution.relevance = '0';
      updatedRelevance = '0';
    }

    if (findIndex(shallowSolutionsArray, { id: solution.id, relevance: updatedRelevance }) === -1) {
      if (findIndex(this.updatedSolutions, { id: solution.id }) === -1) {
        this.updatedSolutions.push(solution);
      }
    } else {
      this.updatedSolutions = reject(this.updatedSolutions, { id: solution.id });
    }
  }

  @action
  resetLoadingState() {
    this.loadingSearchedQueries = null;
    this.loadingMoreSolutions = null;
  }

  @computed
  get orgName() {
    // Return the org name
    return this._orgStore.userOrg.name;
  }

  @computed
  get queryData() {
    if (this.queryWithSolutionData.fulfilled) {
      return this.queryWithSolutionData.value.trainingQueryData.queryInfo;
    } else {
      return {};
    }
  }

  @computed
  get queryDate(): string {
    if (
      this.queryWithSolutionData.fulfilled &&
      this.queryWithSolutionData.value.trainingQueryData.queryInfo.created_at
    ) {
      return format(new Date(this.queryWithSolutionData.value.trainingQueryData.queryInfo.created_at), 'MMM Do YY');
    } else {
      return '';
    }
  }

  @computed
  get analysisId() {
    if (this.queryWithSolutionData.fulfilled) {
      return this.queryWithSolutionData.value.trainingQueryData.analysis_id;
    } else {
      return '';
    }
  }

  @computed
  get recommendationId() {
    if (this.queryWithSolutionData.fulfilled) {
      return this.queryWithSolutionData.value.trainingQueryData.id;
    } else {
      return '';
    }
  }

  @computed
  get solutionData() {
    if (this.queryWithSolutionData.fulfilled) {
      return this.queryWithSolutionData.value.trainingQueryData.solutions;
    } else {
      return [];
    }
  }

  @computed
  get isGlobalUser() {
    return this._orgStore.currentUserContext.isGlobalUser;
  }

  @computed
  get hasSelectedSolutionsData() {
    return this.allSelectedSolutions.length > 0;
  }

  @computed
  get numberOfSelectedSolutions() {
    return this.solutionData.filter(solution => solution.relevance !== '0').length + this.updatedSolutions.length;
  }

  @computed
  get allSelectedSolutions() {
    // Gets all of the selected solutions in a merged list.
    return [...this.solutionData.filter(solution => solution.relevance !== '0'), ...toJS(this.updatedSolutions)];
  }

  @computed
  get solutionTags() {
    if (this.queryWithSolutionData.fulfilled) {
      const { solution_tags } = this.queryWithSolutionData.value.trainingQueryData.queryMetadata;
      return solution_tags ? solution_tags : [];
    } else {
      return [];
    }
  }
}
