import { observable } from 'mobx';

export interface ISurveyTriggers {
  solutions: boolean;
  workflows: boolean;
}

export default class SurveySettings {
  static createRaw(surveySettings: SurveySettings) {
    return {
      show: surveySettings.show,
      defaultWorkflowId: surveySettings.defaultWorkflowId,
      triggers: surveySettings.triggers
    };
  }

  @observable
  show: boolean;

  @observable
  defaultWorkflowId: string | undefined;

  @observable
  triggers: ISurveyTriggers;

  constructor({ show, defaultWorkflowId, triggers }: SurveySettings) {
    this.show = show;
    this.defaultWorkflowId = defaultWorkflowId;
    this.triggers = triggers;
  }
}
