import axios from 'axios';
import gql from 'graphql-tag';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import moment from 'moment-timezone';
import qs from 'qs';
import { AccessDeniedError } from '../shared/util/AccessDeniedError';
import { GlobalUserError } from '../shared/util/GlobalUserError';
import { recordErrors } from '../shared/util/recordErrors';
import { SessionExpiredError } from '../shared/util/SessionExpiredError';
import authStore, { ABSOLUTE_SESSION_TIMEOUT_HEADER, IDLE_SESSION_TIMEOUT_HEADER } from '../store/authStore';
import { IOrgGroupSSODetails, IOrgMembership } from '../store/models/User';
import { Api } from './api';
import envService from './envService';
import { allUsingApi } from './util';

const ORG_FRAGMENT = `
  {
    id
    name
    org_group_id
    settings {
      is_active
    }
  }
`;

const ORG_MEMBERSHIP_FRAGMENT = `
  {
    org ${ORG_FRAGMENT}
    roles
    receive_voc_email
  }
`;

const USER_FRAGMENT = `
  {
    id
    email
    first_name
    last_name
    created_at
    messages_last_read_at
    last_login_at
    force_change_password
    is_two_factor_authentication_enabled
    global_membership {
      global_role
    }
    org_memberships ${ORG_MEMBERSHIP_FRAGMENT}
    authorizationInfo
  }
`;

const UserInfoQuery = gql`
  query {
    Viewer ${USER_FRAGMENT}
  }
`;

const ORG_SETTINGS_FRAGMENT = `
  {
    timezone
    cost_per_ticket
    average_order_value
    enable_reauth_connector
    enable_ssr_dropOff_calc
    enable_insights_app
    default_export_fields
    default_dashboard_queries_csv_export_limit
    enable_coach_app,
    expose_api_key_console,
    default_mode_reports,
    enable_support_option_texts,
    languages,
    sauron_cron_schedule,
    enable_ticketqueries_explore,
    org_display_name
    carting_url
    conversion_url
    launch_url
    enable_workflow_builder
    enable_survey_workflows
    engage
    enable_analytics_api
    enable_url_suggestions
    enable_wfb_spatial_editor
    explore_default_previous_days
    enable_tag_filtering_reports
    enable_org_roles
    enable_social_channel_integration
    intent_prediction_rules
    enable_data_source_setup
    enable_addon_marketplace
    display_url_with_article_title
    enable_intents_layout
    coach_search_all_solutions_elasticsearch
    enable_launcher_widget_controls
    enable_workflow_insights
  }
  `;

const AllOrgsQuery = gql`
  query {
    allOrgs(orderBy: name_ASC) {
      edges {
        node {
          id
          name
          settings {
            is_active
            org_display_name
          }
        }
      }
    }
  }
`;

const OrgInfoQuery = gql`
  query($id: Int!){
    Org(id: $id) {
      id
      name
      org_group {
        orgs {
          id
          name,
          settings {
            is_active
            shadow_for_org
            org_display_name
          }
        }
        api_clients{
          name
          as_api_key
        }
      }
      org_group_id
      settings ${ORG_SETTINGS_FRAGMENT}
    }
  }
`;

const updatePasswordMutation = gql`
  mutation($currentPassword: String!, $newPassword: String!) {
    updateViewerPassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      id
    }
  }
`;

const updateUserLastMessagesReadMutation = gql`
  mutation($messages_last_read_at: Date!) {
    updateCurrentViewerInfo(messages_last_read_at: $messages_last_read_at) {
      id
    }
  }
`;

const updateUserLastLoginInfoMutation = gql`
  mutation($last_login_at: Date!) {
    updateCurrentViewerInfo(last_login_at: $last_login_at) {
      id
    }
  }
`;

const removeUserMutation = gql`
  mutation($id: Int!) {
    deleteUser(id: $id) {
      first_name
      last_name
    }
  }
`;

const assignOrgGroupRoleToUserMutation = gql`
  mutation($user_id: Int!, $org_group_role: OrgGroupRole!, $org_group_id: Int!) {
    assignOrgGroupRoleToUser(user_id: $user_id, org_group_id: $org_group_id, org_group_role: $org_group_role) {
      user ${USER_FRAGMENT}
    }
  }
`;

const enableVoCOrgsForCurrentViewerMutation = gql`
  mutation($org_ids: [Int!]) {
    enableVoCOrgsForCurrentViewer(org_ids: $org_ids) {
      receive_voc_email
      org {
        id
        name
      }
    }
  }
`;

const updateTwoFactorAuthMutation = gql`
  mutation($two_factor_auth: Boolean!) {
    updateViewerTwoFactorAuth(is_two_factor_authentication_enabled: $two_factor_auth) {
      is_two_factor_authentication_enabled
      qrCode
    }
  }
`;

const updateOrgSettingMutation = gql`
  mutation($org_id: Int!, $name: String!, $value: String!) {
    updateOrgSetting(org_id: $org_id, name: $name, value: $value) {
      org_id
      name
      value
    }
  }
`;

const login = async (
  api: Api,
  email: string,
  password: string,
  forZendesk?: boolean,
  zendeskReturnToUrl?: string
): Promise<string> => {
  const { data, headers } = await axios({
    method: 'post',
    url: `${envService.getBaseUrl()}/v1/auth/login`,
    headers: { 'Content-Type': 'application/json;charset=UTF-8' } as any,
    data: JSON.stringify({
      email,
      password,
      forZendesk,
      zendeskReturnToUrl
    })
  });

  authStore.setSessionTimeouts(
    Number(headers[IDLE_SESSION_TIMEOUT_HEADER]),
    Number(headers[ABSOLUTE_SESSION_TIMEOUT_HEADER])
  );

  return data;
};

const loginWithGoogleIdToken = async (
  api: Api,
  idToken: string,
  forZendesk?: boolean,
  zendeskReturnToUrl?: string
): Promise<string> => {
  const { data, headers } = await axios({
    method: 'post',
    url: `${envService.getBaseUrl()}/v1/auth/login-with-google`,
    headers: { 'Content-Type': 'application/json;charset=UTF-8' } as any,
    data: JSON.stringify({
      id_token: idToken,
      forZendesk,
      zendeskReturnToUrl
    })
  });

  authStore.setSessionTimeouts(
    Number(headers[IDLE_SESSION_TIMEOUT_HEADER]),
    Number(headers[ABSOLUTE_SESSION_TIMEOUT_HEADER])
  );

  return data;
};

const redirectToSSOLogin = async (
  api: Api,
  emailId: string,
  queryParams,
  workOSConnectionId?: string
): Promise<IOrgGroupSSODetails | string> => {
  const { data } = await axios({
    method: 'get',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' } as any,
    url: `${envService.getBaseUrl()}/v1/auth/sso?emailId=${emailId}${
      workOSConnectionId ? `&connectionId=${workOSConnectionId}` : ''
    }${queryParams ? `&${qs.stringify(queryParams)}` : ''}`
  });
  // If org group has multiple SSO connections, return list of connection
  // to select from instead of redirecting to auth url
  if (!data.authorizationURL) {
    return data as IOrgGroupSSODetails;
  }
  window.location.href = data.authorizationURL;
  return data.authorizationURL;
};

const getZendeskRedirectUrlAndPerformRedirect = async (api: Api, queryParams): Promise<string> => {
  const { data } = await axios({
    method: 'get',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' } as any,
    url: `${envService.getBaseUrl()}/v1/auth/zendesk-redirect?token=${authStore.accessToken}${
      queryParams ? `&${qs.stringify(queryParams)}` : ''
    }`
  });
  window.location.href = data.zendesk_redirect_url;
  return data.zendesk_redirect_url;
};

const logOutUser = api => {
  return api.post(`/v1/auth/logout`);
};

const fetchOrgInfoWithSettings = (api, id) => {
  return api.gqlQuery({
    query: OrgInfoQuery,
    variables: {
      id
    }
  });
};

const changeViewerPassword = (api, currentPassword, newPassword) => {
  return api
    .gqlMutate({
      mutation: updatePasswordMutation,
      variables: {
        currentPassword,
        newPassword
      }
    })
    .catch(e => {
      recordErrors(e);
      if (e && e.graphQLErrors && e.graphQLErrors.length > 0) {
        throw new Error(e.graphQLErrors[0].message);
      } else {
        throw e;
      }
    });
};

const getUsersForOrg = (api, org_group_id) => {
  return api.gqlQuery({
    query: gql`
      query{
        OrgGroup(id: ${org_group_id}) {
          memberships {
            role: org_group_role
            user ${USER_FRAGMENT}
          }
        }
      }
    `
  });
};

const getAllOrgs = api => {
  return api.gqlQuery({
    query: AllOrgsQuery
  });
};

const createUser = async (api, updateUserInput, org_id, formattedEmail) => {
  const { data } = await api.gqlMutate({
    mutation: gql`
      mutation CreateUser($org_id: Int!, $first_name: String!, $last_name: String!, $email: Email!,
              $password: String!, $send_welcome_email: Boolean!) {
        createUser(
          org_id: $org_id,
          first_name: $first_name,
          last_name: $last_name,
          email: $email,
          password: $password,
          send_welcome_email: $send_welcome_email
        ) ${USER_FRAGMENT}
      }
    `,
    variables: {
      org_id,
      email: formattedEmail,
      send_welcome_email: true,
      ...pick(updateUserInput, ['first_name', 'last_name', 'password'])
    }
  });
  return data.createUser;
};

const createOrgUser = async (api, org_id: number, user_id: number, receive_voc_email: boolean, roles: string[]) => {
  const { data } = await api.gqlMutate({
    mutation: gql`
      mutation CreateOrgUser($org_id: Int!, $user_id: Int!, $receive_voc_email: Boolean!, $roles: [OrgGroupRole]!) {
        createOrgUser(
          org_id: $org_id,
          user_id: $user_id,
          receive_voc_email: $receive_voc_email,
          roles: $roles
        ) ${ORG_MEMBERSHIP_FRAGMENT}
      }
    `,
    variables: {
      org_id,
      user_id,
      receive_voc_email,
      roles
    }
  });
  return data.createOrgUser;
};

const updateUserProfileVoCOrgs = (api, org_ids) => {
  return api.gqlMutate({
    mutation: enableVoCOrgsForCurrentViewerMutation,
    variables: {
      org_ids
    }
  });
};

const updateUserLastMessagesRead = (api, messages_last_read_at) => {
  return api.gqlMutate({
    mutation: updateUserLastMessagesReadMutation,
    variables: {
      messages_last_read_at
    }
  });
};

const updateUserLastLogin = (api, last_login_at) => {
  return api.gqlMutate({
    mutation: updateUserLastLoginInfoMutation,
    variables: {
      last_login_at
    }
  });
};

const deleteUser = (api, id) => {
  return api.gqlMutate({
    mutation: removeUserMutation,
    variables: {
      id
    }
  });
};

const forgotPasswordChange = (api, newPassword, resetCode) => {
  return axios({
    method: 'put',
    url: `${envService.getBaseUrl()}/v1/user/reset-password/${resetCode}`,
    headers: { 'Content-Type': 'application/json;charset=UTF-8' } as any,
    data: JSON.stringify({
      newPassword
    })
  }).then(response => response.data);
};

const verifyPasswordResetCode = (api, resetCode) => {
  return axios({
    method: 'get',
    url: `${envService.getBaseUrl()}/v1/user/reset-password/${resetCode}`,
    headers: { 'Content-Type': 'application/json;charset=UTF-8' } as any
  }).then(response => response.data);
};

const sendForgotPasswordEmail = (api, email) => {
  return axios({
    method: 'post',
    url: `${envService.getBaseUrl()}/v1/user/reset-password`,
    headers: { 'Content-Type': 'application/json;charset=UTF-8' } as any,
    data: JSON.stringify({
      email
    })
  }).then(response => response.data);
};

const verifyAuthToken = (api, email, password, token, forZendesk?: boolean, zendeskReturnToUrl?: string) => {
  return axios({
    method: 'post',
    url: `${envService.getBaseUrl()}/v1/auth/login`,
    headers: { 'Content-Type': 'application/json;charset=UTF-8' } as any,
    data: JSON.stringify({
      email,
      password,
      token,
      forZendesk,
      zendeskReturnToUrl
    })
  }).then(response => response.data);
};

const assignOrgGroupRoleToUser = (api, id, org_group_role, org_group_id) => {
  return api.gqlMutate({
    mutation: assignOrgGroupRoleToUserMutation,
    variables: {
      user_id: id,
      org_group_role,
      org_group_id
    }
  });
};

const updateTwoFactorAuth = (api, two_factor_auth) => {
  return api.gqlMutate({
    mutation: updateTwoFactorAuthMutation,
    variables: {
      two_factor_auth
    }
  });
};

const getUserInfo = async (api, org_id?) => {
  /*  in the new api a currentUserContext can be member of multiple orgs and we don't return the org membership information anymore directly as part of the login response.
          instead we have to make a graphql follow up request to determine which orgs a currentUserContext belongs to. For dashboard 1.0 we'll just pick the first org and treat the currentUserContext
          as if he's only a member of that single org. this code is dirty and should be done a bit more elegantly in dashboard 2.0.
          */

  const {
    data: { Viewer }
  } = await api.gqlQuery({ query: UserInfoQuery, fetchPolicy: 'network-only' }).catch(e => {
    if ((e.response && e.response.status === 401) || (e.networkError && e.networkError.statusCode === 401)) {
      throw new SessionExpiredError('Your session has expired, please login again.');
    } else {
      throw e;
    }
  });

  let targetOrgId: number | undefined;
  const orgIdQueryParam = org_id || new URL(window.location.href).searchParams.get('org_id');
  if (orgIdQueryParam) {
    targetOrgId = Number(orgIdQueryParam);
  }
  if (Viewer.global_membership) {
    if (Viewer.global_membership.global_role !== 'ROOT') {
      throw new Error(
        `User logged in with global role: ${Viewer.global_membership.global_role} which is not yet supported`
      );
    }

    if (!targetOrgId || !Number.isInteger(targetOrgId)) {
      throw new GlobalUserError(
        `You attempted to login as a global currentUserContext which has access to multiple orgs, but a target org_id is missing or invalid. Please specify a target org_id via a url parameter like so ?org_id=<some_org_id>`
      );
    }
  } else if (Viewer.org_memberships.length > 0) {
    const activeOrgs = Viewer.org_memberships.filter(
      (orgMembership: IOrgMembership) => orgMembership.org.settings.is_active && !isEmpty(orgMembership.roles)
    );

    if (activeOrgs.length === 0) {
      throw new AccessDeniedError(
        `You do not have permissions to access any org. Please contact Solvvy for more info!`
      );
    }
    if (!targetOrgId) {
      targetOrgId = activeOrgs[0].org.id;
    }
  }

  return {
    id: Viewer.id,
    force_change_password: Viewer.force_change_password,
    first_name: Viewer.first_name,
    last_name: Viewer.last_name,
    email: Viewer.email,
    global_membership: Viewer.global_membership,
    messages_last_read_at: Viewer.messages_last_read_at && Viewer.messages_last_read_at,
    last_login_at: Viewer.last_login_at && moment(Viewer.last_login_at).format('YYYY-MM-DD'),
    is_two_factor_authentication_enabled: Viewer.is_two_factor_authentication_enabled,
    receive_voc_email: Viewer.receive_voc_email,
    org_id: targetOrgId,
    org_memberships: Viewer.org_memberships,
    authorizationInfo: Viewer.authorizationInfo
  };
};

const updateOrgSettingValue = (api, org_id, name, value) => {
  return api.gqlMutate({ mutation: updateOrgSettingMutation, variables: { org_id, name, value } });
};

const getOrgSetting = async (api, orgId, name, bypassCache?) => {
  const { data } = await api.gqlQuery({
    query: gql`
      query($orgId: Int!) {
        Org(id: $orgId) {
          id
          settings { ${name} }
        }
      }
    `,
    variables: { orgId },
    ...(bypassCache ? { fetchPolicy: 'network-only' } : {})
  });
  return data.Org.settings[name];
};

const allUsersForOrgGroup = async (api, orgGroupId: number) => {
  const { data } = await api.gqlQuery({
    query: gql`
      query AllUsersForGroup($orgGroupId: Int!) {
        allUsers(where: { org_group_id: $orgGroupId }) {
          edges {
            node ${USER_FRAGMENT}
          }
        }
      }
    `,
    variables: { orgGroupId },
    fetchPolicy: 'network-only'
  });
  return data.allUsers.edges.map(edge => edge.node);
};

const updateUser = async (api, id, { first_name, last_name, email }) => {
  const { data } = await api.gqlMutate({
    mutation: gql`
    mutation UpdateUser($id: Int!, $first_name: String, $last_name: String, $email: Email) {
      updateUser(id: $id, first_name: $first_name, last_name: $last_name, email: $email)
      ${USER_FRAGMENT}
    }
  `,
    variables: { id, first_name, last_name, email }
  });
  return data.updateUser;
};

const updateOrgUser = async (
  api,
  user_id: number,
  org_id: number,
  { receive_voc_email, roles }: { receive_voc_email?: boolean; roles?: string[] } = {}
) => {
  const { data } = await api.gqlMutate({
    mutation: gql`
    mutation UpdateOrgUser($user_id: Int!, $org_id: Int!, $receive_voc_email: Boolean, $roles: [OrgGroupRole]) {
      updateOrgUser(user_id: $user_id, org_id: $org_id, receive_voc_email: $receive_voc_email, roles: $roles)
      ${ORG_MEMBERSHIP_FRAGMENT}
    }
  `,
    variables: { user_id, org_id, receive_voc_email, roles }
  });
  return data.updateOrgUser;
};

export const userService = (api: Api) => {
  return allUsingApi(api, {
    login,
    loginWithGoogleIdToken,
    redirectToSSOLogin,
    getZendeskRedirectUrlAndPerformRedirect,
    getUserInfo,
    logOutUser,
    fetchOrgInfoWithSettings,
    changeViewerPassword,
    getUsersForOrg,
    createUser,
    createOrgUser,
    updateUserProfileVoCOrgs,
    updateUserLastMessagesRead,
    updateUserLastLogin,
    deleteUser,
    verifyPasswordResetCode,
    forgotPasswordChange,
    sendForgotPasswordEmail,
    assignOrgGroupRoleToUser,
    updateOrgSettingValue,
    getAllOrgs,
    verifyAuthToken,
    updateTwoFactorAuth,
    getOrgSetting,
    allUsersForOrgGroup,
    updateUser,
    updateOrgUser
  });
};

export default userService;

type PromiseType<P extends Promise<any>> = P extends Promise<infer T> ? T : never;

type UserInfoPromise = ReturnType<typeof getUserInfo>;
export type UserInfo = PromiseType<UserInfoPromise>;
