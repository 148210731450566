import { observable } from 'mobx';

export interface IHours {
  start: string;
  end: string;
}

export interface ISchedule {
  dayOfWeek: string;
  hours: IHours[];
  step: number;
  closed: boolean;
  id: number;
}

export enum HoursUpdateType {
  CHANGE = 'change',
  ADD = 'add',
  REMOVE = 'remove'
}

export const DEFAULT_SCHEDULE = [
  {
    dayOfWeek: 'sunday',
    hours: [
      {
        start: '09:00',
        end: '17:00'
      }
    ],
    step: 15,
    closed: true,
    id: 1
  },
  {
    dayOfWeek: 'monday',
    hours: [
      {
        start: '09:00',
        end: '17:00'
      }
    ],
    step: 15,
    closed: true,
    id: 2
  },
  {
    dayOfWeek: 'tuesday',
    hours: [
      {
        start: '09:00',
        end: '17:00'
      }
    ],
    step: 15,
    closed: true,
    id: 3
  },
  {
    dayOfWeek: 'wednesday',
    hours: [
      {
        start: '09:00',
        end: '17:00'
      }
    ],
    step: 15,
    closed: true,
    id: 4
  },
  {
    dayOfWeek: 'thursday',
    hours: [
      {
        start: '09:00',
        end: '17:00'
      }
    ],
    closed: true,
    step: 15,
    id: 5
  },
  {
    dayOfWeek: 'friday',
    hours: [
      {
        start: '09:00',
        end: '17:00'
      }
    ],
    closed: true,
    step: 15,
    id: 6
  },
  {
    dayOfWeek: 'saturday',
    hours: [
      {
        start: '09:00',
        end: '17:00'
      }
    ],
    closed: true,
    step: 15,
    id: 7
  }
];

export default class SupportOptionSchedule {
  @observable
  schedule: ISchedule[] = [];
  @observable
  timeZone: string;
  @observable
  scheduledPluginIds: string[];
}
