import bind from 'bind-decorator';
import get from 'lodash/get';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import { asyncAction } from 'mobx-utils';
import moment, { Moment } from 'moment-timezone';
import { exportQueriesService } from '../services';
import asyncComputed from '../shared/util/asyncComputed';
import { NotificationManager } from '../shared/util/NotificationManager';
import { AuthStore } from './authStore';
import { OrgStore } from './orgStore';
import { RouterStore } from './routerStore';

const REFRESH_INTERVAL_SECONDS = 10; // how frequently to refresh the exports (to see status change)
const DEFAULT_TIMEZONE = 'America/Los_Angeles';

export enum Status {
  PENDING = 'pending',
  PROCESSING = 'processing',
  COMPLETE = 'complete'
}

export interface IExportQueriesRequest {
  id: string;
  org_id: number;
  status: Status;
  user_id?: number;
  user_email?: string;
  user_full_name?: string;
  internal: boolean;
  name: string;
  percent_complete?: number;
  size?: number;
  uncompressed_size?: number;
  uncompressedSize?: number;
  start_at: string;
  end_at: string;
  created_at: string;
  updated_at: string;
}

export class ExportQueriesStore {
  @observable
  showNewExportModel: boolean = false;

  @observable
  submittingNewExport?: boolean;

  periodicRefreshing?: boolean = false;

  exportRequests = asyncComputed(this.loadExportRequests);
  cachedExportRequests: IExportQueriesRequest[];

  refreshInterval?: any;

  defaultDateRange = [moment().add(-1, 'months'), moment().endOf('day')];

  constructor(
    private orgStore: OrgStore,
    private authStore: AuthStore,
    private routerStore: RouterStore,
    private notificationManager: NotificationManager
  ) {
    const startRefresh = () =>
      setInterval(() => runInAction(() => this.periodicRefresh()), REFRESH_INTERVAL_SECONDS * 1000);
    reaction(
      () => this.shouldPeriodicRefresh,
      () => {
        if (this.shouldPeriodicRefresh && !this.refreshInterval) {
          this.refreshInterval = startRefresh();
        }
        if (!this.shouldPeriodicRefresh && this.refreshInterval) {
          clearInterval(this.refreshInterval);
          this.refreshInterval = undefined;
        }
      }
    );
    if (this.shouldPeriodicRefresh && !this.refreshInterval) {
      this.refreshInterval = startRefresh();
    }
  }

  @computed
  get timezone() {
    return this.orgStore.orgSettings.timezone || DEFAULT_TIMEZONE;
  }

  getDownloadUrl(exportRequestId: string) {
    const { selectedOrgId } = this.orgStore;
    const token = this.authStore.accessToken;
    return exportQueriesService.getDownloadUrl(selectedOrgId, exportRequestId, token);
  }

  @action.bound
  toggleNewExportModel() {
    this.showNewExportModel = !this.showNewExportModel;
  }

  @bind
  @asyncAction
  *submit(startDate: Moment, endDate: Moment) {
    this.submittingNewExport = true;

    try {
      // convert to timezone neutral format without any time component
      const startDateOnly = startDate.format('YYYY-MM-DD');
      const endDateOnly = endDate.format('YYYY-MM-DD');

      yield exportQueriesService.postNewExport({
        org_id: this.orgStore.selectedOrgId,
        // start_at is tart of day in org's timezone
        start_at: moment.tz(startDateOnly, 'YYYY-MM-DD', this.timezone).toISOString(),
        // end_at is start of +1 day after selected end (so selected end date is inclusive)
        end_at: moment
          .tz(endDateOnly, 'YYYY-MM-DD', this.timezone)
          .add(1, 'day')
          .toISOString()
      });

      this.submittingNewExport = false;
      this.notificationManager.success({
        title: 'Data Download Request Submitted',
        message: `Thank you for submitting a data download request. You will receive an email when the data is ready for download.`,
        config: {
          placement: 'topLeft',
          duration: 10,
          style: {
            width: 500
          }
        }
      });
      this.exportRequests.refresh();
      return true;
    } catch (e) {
      this.submittingNewExport = false;
      const serverError = get(e, 'response.data.message');
      this.notificationManager.error({
        title: 'Oops something went wrong',
        message: `${e}${serverError ? '\n' + serverError : ''}`
      });
      return false;
    }
  }

  @bind
  private async loadExportRequests(): Promise<IExportQueriesRequest[]> {
    if (!isNaN(this.orgStore.selectedOrgId)) {
      try {
        const { data } = await exportQueriesService.loadExportQueries(
          this.orgStore.selectedOrgId,
          this.periodicRefreshing
        );
        this.cachedExportRequests = data;
        return data;
      } finally {
        this.periodicRefreshing = false;
      }
    }
    return [];
  }

  @computed
  get shouldPeriodicRefresh() {
    return this.routerStore.activeOrgPage === 'dataDownload' && !this.showNewExportModel;
  }

  @action.bound
  private periodicRefresh() {
    this.periodicRefreshing = true;
    this.exportRequests.refresh();
  }
}
