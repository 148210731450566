import { Form, Modal, Select } from 'antd';
import bind from 'bind-decorator';
import capitalize from 'lodash/capitalize';
import { observer } from 'mobx-react';
import { Component, default as React } from 'react';
import { OrgStore } from 'src/store/orgStore';
import { typedInject } from '../../store/rootStore';
import { LANGUAGES, WorkflowStore } from '../../store/workflowStore';

interface IProps {
  visible: boolean;
  onOk: (instanceId?: string, translateToLanguage?: string) => void;
  onCancel: () => void;
  workflowStore: WorkflowStore;
  orgStore: OrgStore;
}

interface IState {
  selectedInstanceId?: string;
  translateToLanguage?: string;
}

@observer
class DuplicateWorkflowModal extends Component<IProps, IState> {
  state: IState = {};

  @bind
  async handleChangeInstance(selectedInstanceId) {
    this.setState({ selectedInstanceId });
  }

  @bind
  async handleChangeLanguage(translateToLanguage) {
    this.setState({ translateToLanguage });
  }

  @bind
  onOk() {
    const { selectedInstanceId, translateToLanguage } = this.state;
    return this.props.onOk(selectedInstanceId, translateToLanguage);
  }

  @bind
  onCancel() {
    this.setState({ selectedInstanceId: undefined });
    this.props.onCancel();
  }

  render() {
    const { visible, workflowStore, orgStore } = this.props;

    const instanceDisplays = workflowStore.getInstanceDisplays(true);

    const firstInstance = instanceDisplays.length > 0 ? instanceDisplays[0].instance : undefined;

    return (
      <Modal
        visible={visible}
        onOk={this.onOk}
        onCancel={this.onCancel}
        title="Duplicate Workflow"
        confirmLoading={Boolean(workflowStore.savingWorkflow)}
      >
        <Form layout="vertical">
          <Form.Item label="Target instance for the new workflow">
            <Select
              style={{ width: '100%' }}
              placeholder="Select instance..."
              defaultValue={firstInstance?.raw.id}
              onChange={this.handleChangeInstance}
            >
              {instanceDisplays.map(({ instanceId, displayName, dialogType }) => (
                <Select.Option key={instanceId} value={instanceId}>
                  {`${displayName}${dialogType ? ' (' + capitalize(dialogType) + ')' : ''}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {orgStore.currentUserContext.isGlobalUser && (
            <Form.Item label="Optionally translate content (from English only)">
              <Select placeholder="Select target langauge..." onChange={this.handleChangeLanguage}>
                {LANGUAGES.map(({ code, label }) => (
                  <Select.Option key={code} value={code}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form>
      </Modal>
    );
  }
}

export default typedInject('workflowStore', 'orgStore')(DuplicateWorkflowModal);
