import { JSONSchema4 } from 'json-schema';
import { recordErrors } from '../../shared/util/recordErrors';
import { IDataSource, IDataSourceAction } from '../data-source-types';
import { IExternalApi } from '../external-api-types';
import Source from '../models/Source';
import { IExternalApiActionOptions } from './Workflow';

function setTemplateExpressions(routeName: string, rootSchema: JSONSchema4, propertyIdPrefix: string = ''): void {
  rootSchema.templateExpression = `{{api.${routeName}.body${propertyIdPrefix}}}`;

  if (rootSchema.properties) {
    for (const [propertyId, schema] of Object.entries(rootSchema.properties || {})) {
      const propertyPath = `${propertyIdPrefix}.${propertyId}`;
      setTemplateExpressions(routeName, schema, propertyPath);
    }
  }

  for (const [propertyId, schema] of Object.entries(rootSchema.properties || {})) {
    const propertyPath = `${propertyIdPrefix}.${propertyId}`;
    schema.templateExpression = `{{api.${routeName}.body${propertyPath}}}`;
    if (schema.properties) {
      setTemplateExpressions(routeName, schema, propertyPath);
    }
  }
}

export class ExternalApiAutoGeneratedDataSource implements IDataSource {
  id: string;
  name: string;
  variablesJsonSchema: JSONSchema4 = { properties: {} };
  variablesMock: object = {};
  actions: IDataSourceAction[] = [];

  constructor(connector: Source) {
    this.id = connector.id;
    this.name = connector.name;

    const externalApi: IExternalApi = connector.external_api!;

    for (const route of externalApi.routes) {
      if (route.response_json_schema) {
        const properties = this.variablesJsonSchema.properties!;
        try {
          properties[route.name] = JSON.parse(route.response_json_schema!) as JSONSchema4;
        } catch (e) {
          recordErrors(e);
        }

        // add templateExpressions
        if (properties[route.name]) {
          setTemplateExpressions(route.name, properties[route.name]);
        }
      }
      if (route.response_mock) {
        try {
          this.variablesMock[route.name] = JSON.parse(route.response_mock) as object;
        } catch (e) {
          recordErrors(e);
        }
      }

      const stringInputs = {};
      for (const input of route.input || []) {
        stringInputs[input.name] = {
          type: 'string',
          title: input.name,
          targetPath: `input.${input.name}`
        };
      }

      this.actions.push({
        id: route.id || route.name,
        name: route.name,
        action: {
          type: 'external_api',
          options: {
            orgId: Number(connector.org_id),
            connectorId: connector.id,
            name: route.name,
            input: {}
          } as IExternalApiActionOptions
        },
        inputsJsonSchema: {
          required: ['onSuccessAction'],
          properties: {
            onSuccessAction: {
              type: 'action' as any,
              title: 'On Success',
              targetPath: 'onSuccessAction'
            },
            onErrorAction: {
              type: 'action' as any,
              title: 'On Error',
              targetPath: 'onErrorAction'
            },
            ...stringInputs
          }
        }
      });
    }
  }
}
