import CHANNEL_OPTION_PLUGINS from '@solvvy/locales/en/plugins';
import V4_UI_OPTION_DEFAULTS from '@solvvy/locales/en/v4-ui/v4-ui';
import { omit } from 'lodash';
import get from 'lodash/get';
import merge from 'lodash/merge';
import set from 'lodash/set';
import startCase from 'lodash/startCase';
import { observable } from 'mobx';
import { ChannelOptionPluginTypes } from '../../routes/InterfaceSetup/configOptions/ChannelOptionPluginTypes';
import { UiMajorVersion } from '../ResolveUI/types';
import { inferIconFromType, inferTypeFromPluginName, SUPPORT_OPTION_TYPES } from '../ResolveUI/Util';
import SupportOptionSchedule, { DEFAULT_SCHEDULE } from './SupportOptionSchedule';

// the new default is to use '.launchCard' as the path to the title, description and launch button
// but some plugins use '.card' for now
const V5_PLUGINS_WITH_CARD_PATH = [ChannelOptionPluginTypes.WebViewNativeBridge, ChannelOptionPluginTypes.Link];

export class GenericSupportOption {
  @observable
  id: string;

  @observable
  type: string;

  @observable
  normalizedPluginName: string;

  @observable
  icon: string;

  @observable
  button: string;

  @observable
  launchButton: string;

  @observable
  title: string;

  @observable
  displayName: string;

  @observable
  description: string;

  @observable
  v5CardOptionsPath?: string;

  @observable
  schedule: SupportOptionSchedule = {
    schedule: DEFAULT_SCHEDULE,
    timeZone: '',
    scheduledPluginIds: []
  };

  @observable
  alwaysOffline?: boolean;

  @observable
  workflowsOnly?: boolean;

  @observable
  extraTextOverrides: any = {};

  // single feed chat
  @observable
  handoffToAgentBotMessage: string;
  @observable
  agentEndedBotMessage: string;
  @observable
  userEndedBotMessage: string;
  @observable
  useAgentAvatarIfAvailable?: boolean;

  uiMajorVersion: UiMajorVersion | null;

  constructor(rawSupportOption: any, uiMajorVersion: UiMajorVersion | null) {
    this.uiMajorVersion = uiMajorVersion;
    if (uiMajorVersion === UiMajorVersion.V4) {
      this.createV4SupportOption(rawSupportOption);
    }

    if (uiMajorVersion === UiMajorVersion.V5) {
      this.createV5ChannelOption(rawSupportOption);
    }

    if (uiMajorVersion === null) {
      this.createBotChannelOption(rawSupportOption);
    }
  }

  getRawText() {
    if (this.uiMajorVersion === UiMajorVersion.V4) {
      return {
        title: this.title || '',
        description: this.description
      };
    }

    if (this.uiMajorVersion === UiMajorVersion.V5) {
      const common = { ...this.extraTextOverrides, button: this.button };

      if (this.type === SUPPORT_OPTION_TYPES.SINGLE_FEED_CHAT) {
        return {
          ...common,
          handoffToAgentBotMessage: this.handoffToAgentBotMessage,
          agentEndedBotMessage: this.agentEndedBotMessage,
          userEndedBotMessage: this.userEndedBotMessage
        };
      }

      // special cases
      switch (this.normalizedPluginName) {
        case ChannelOptionPluginTypes.Ticket:
          return {
            ...common,
            title: this.title,
            description: this.description
          };
        case ChannelOptionPluginTypes.Phone:
          return {
            ...common,
            title: this.title
          };
        default:
          return {
            ...common,
            [this.v5CardOptionsPath!]: {
              title: this.title,
              instructions: this.description,
              button: this.launchButton
            }
          };
      }
    }

    return {};
  }

  setTextOverrideByRawPath(textPath: string, value: string) {
    const overrides: any = this.getRawText();
    set(overrides, textPath, value);
    this.setV5RawTextOverrides(overrides);
  }

  createV4SupportOption(rawSupportOption: any) {
    const { id, type: pluginName, options } = rawSupportOption;
    const { type, ui } = options || {};

    this.id = id;
    this.normalizedPluginName = pluginName.replace(/(^(.*?(?:Custom)))*(.*?(?:Extend)(s)*(ed)*)*/i, '');
    this.type = type ? type : inferTypeFromPluginName(this.normalizedPluginName);
    this.icon = get(ui, 'icon') || inferIconFromType(this.type);

    const defaultText = V4_UI_OPTION_DEFAULTS.supportOptions.text.supportOptions[this.type] || {
      title: 'Title',
      description: 'Description'
    };
    const overrideText = get(ui, 'text') || {};
    this.title = overrideText.title || defaultText.title;
    this.description = overrideText.description || defaultText.description;
    this.displayName = this.title && this.title !== ' ' ? this.title : startCase(this.type);
  }

  createV5ChannelOption(rawSupportOption: any) {
    const { id, type: pluginName, options } = rawSupportOption;
    this.id = id;
    this.type = inferTypeFromPluginName(pluginName);

    // lookup default text from locales (custom plugins should follow convention of a Custom or Extends prefix)
    this.normalizedPluginName = pluginName.replace(/(^(.*?(?:Custom)))*(.*?(?:Extend)(s)*(ed)*)*/, '');

    this.alwaysOffline = get(options, 'alwaysOffline', false);
    this.workflowsOnly = get(options, 'workflowsOnly', false);

    if (this.type === SUPPORT_OPTION_TYPES.SINGLE_FEED_CHAT) {
      this.useAgentAvatarIfAvailable = get(options, 'useAgentAvatarIfAvailable', false);
    }

    const overrides = get(options, 'text') || {};
    this.setV5RawTextOverrides(overrides);
  }

  createBotChannelOption(rawSupportOption: any) {
    const { id, type: pluginName } = rawSupportOption;
    this.id = id;
    this.type = inferTypeFromPluginName(pluginName);

    // Bot channel doesn't support text overrides so we'll just leave this blank for now
  }

  setV5RawTextOverrides(overrides: any) {
    const globalDefaults = {
      button: 'Unknown',
      title: 'Title',
      description: 'Description'
    };
    const pluginDefaults = this.fetchPluginDefaults();

    // common across all
    this.button = overrides.button || pluginDefaults.button || globalDefaults.button;

    // special cases
    switch (this.type) {
      case SUPPORT_OPTION_TYPES.TICKET:
        this.title = overrides.title || pluginDefaults.title || globalDefaults.title;
        this.description = overrides.description || pluginDefaults.description || globalDefaults.description;
        this.extraTextOverrides = omit(overrides, ['button', 'title', 'description']);
        break;
      case SUPPORT_OPTION_TYPES.PHONE:
        this.title = overrides.title || pluginDefaults.title || globalDefaults.title;
        this.extraTextOverrides = omit(overrides, ['button', 'title']);
        break;
      case SUPPORT_OPTION_TYPES.SINGLE_FEED_CHAT:
        this.handoffToAgentBotMessage = overrides.handoffToAgentBotMessage || pluginDefaults.handoffToAgentBotMessage;
        this.agentEndedBotMessage = overrides.agentEndedBotMessage || pluginDefaults.agentEndedBotMessage;
        this.userEndedBotMessage = overrides.userEndedBotMessage || pluginDefaults.userEndedBotMessage;
        this.extraTextOverrides = omit(overrides, [
          'button',
          'handoffToAgentBotMessage',
          'agentEndedBotMessage',
          'userEndedBotMessage'
        ]);
        break;
      default:
        // the rest of the options are inside a sub-object either 'card' or 'launchCard' (unfortunately not consistent)
        this.v5CardOptionsPath = V5_PLUGINS_WITH_CARD_PATH.map(t => t.toString()).includes(this.normalizedPluginName)
          ? 'card'
          : 'launchCard';

        const getText = (option, customGlobalDefault?) =>
          get(overrides, `${this.v5CardOptionsPath}.${option}`) ||
          get(pluginDefaults, `${this.v5CardOptionsPath}.${option}`) ||
          globalDefaults[customGlobalDefault || option];

        this.title = getText('title');
        this.description = getText('instructions', 'description');
        this.launchButton = getText('button', 'launchButton');
        this.extraTextOverrides = omit(overrides, [
          'button',
          `${this.v5CardOptionsPath}.title`,
          `${this.v5CardOptionsPath}.instructions`,
          `${this.v5CardOptionsPath}.button`
        ]);
    }
  }

  protected fetchPluginDefaults() {
    // some channel options have a base class with text that is used (e.g. chat)
    let baseDefaults = { text: {} };
    if (this.type === SUPPORT_OPTION_TYPES.CHAT) {
      baseDefaults = CHANNEL_OPTION_PLUGINS.V5ChatIntegration;
    } else if (this.type === SUPPORT_OPTION_TYPES.SINGLE_FEED_CHAT) {
      // for all single feed chat channel, the path "SunshineSingleFeedChatChannel" is considered to have the base locale data
      baseDefaults = CHANNEL_OPTION_PLUGINS.SunshineSingleFeedChatChannel;
    }
    const defaultOptions = CHANNEL_OPTION_PLUGINS[this.normalizedPluginName];

    return defaultOptions ? merge({}, baseDefaults.text, defaultOptions.text) : baseDefaults.text;
  }
}
