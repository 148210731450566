import { Card, Collapse, Drawer, Modal, Row, Table } from 'antd';
import styled from 'styled-components';
import colorPicker from '../../shared/util/ColorPicker';
import { INSERT_VARIABLE_TOOLTIP } from './ParagraphEdit';

export const WorkflowStyles = styled.div`
  height: 100%;

  .ant-breadcrumb-link .ant-btn-link {
    padding: 0;
  }
  .ant-breadcrumb .ant-btn-link {
    color: rgba(0, 0, 0, 0.45);
  }
  .ant-breadcrumb .ant-btn-link:hover {
    color: ${colorPicker.solvvyMediumPurple_2};
  }
  .ant-breadcrumb > span:last-child .ant-btn-link {
    color: ${colorPicker.solvvyBlack};
  }

  .workflow_list_header {
    margin-bottom: 15px;
  }

  .actions {
    text-align: center;
  }

  .joint-type-solvvy-workflowstep img,
  .screen_content img {
    display: block !important;
    max-width: 100% !important;
    max-height: 250px !important;
  }
  .jointjs-paragraph-ruler-div img {
    max-width: 100% !important;
    max-height: 250px !important;
  }

  .joint-type-solvvy-workflowstep {
    .highlight1,
    .highlight2 {
      visibility: hidden;
    }

    &.highlight {
      .highlight1,
      .highlight2 {
        visibility: visible;
      }
    }

    .button-port:hover {
      stroke: rgba(218, 236, 255, 0.85);
      r: 15;
      stroke-width: 6;
      cursor: crosshair;
    }
  }
`;

export const StyledEditWorkflowHeader = styled.div`
  .go_back {
    color: ${colorPicker.solvvyTeal};
    padding-left: 0;
    margin-bottom: 10px;
    font-size: 14px;

    .anticon svg path:first-child {
      fill: ${colorPicker.white} !important;
    }
  }

  .workflow_name {
    display: inline;
  }

  .auto_save_notice {
    display: inline;
    color: ${colorPicker.grey_10};
    font-size: 14px;
    margin-left: 25px;

    .anticon {
      margin-right: 5px;
    }
  }

  .save-publish-buttons {
    text-align: right;
    margin-bottom: 10px;

    button {
      margin-left: 10px;
    }
  }
`;

export const StyledModal = styled(Modal)`
  .buttons {
    text-align: right;

    button {
      margin-left: 10px;
    }
  }

  h2 {
    color: ${colorPicker.solvvyBlack};
    margin-bottom: 1em;
  }

  .ant-form-item-label > label {
    font-weight: 700;
    font-size: 16px;
    color: ${colorPicker.solvvyBlack};
  }
`;

export const WorkflowEditAreaRow = styled(Row)`
  .jointjs-container {
    width: 100%;
    height: calc(100vh - 285px); /* full viewport height minus all the headers and status bar */
    overflow: auto;

    span.mention {
      background-color: #e0e8f4;
      color: #000000;
      padding: 2px 4px;
      margin: 0 2px;
      border-radius: 2px;
    }
  }
`;

export const WorkflowStatusArea = styled(Row)`
  display: flex;
  width: 100%;
  padding: 15px;
  min-height: 63px;
  border: 1px solid ${colorPicker.grey_11};
  background-color: ${colorPicker.grey_14};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  .ant-switch {
    margin: 0 10px;
  }
  .status_container {
    float: right;
  }
  .add_card_button_wrapper {
    display: inline;
    margin-right: 20px;
  }
  .toggle_button.active {
    margin-left: 5px;
    margin-right: 0;
    display: inline;
    width: 31px;
    height: 31px;
  }
  .toggle_button.inactive {
    margin-left: 12px;
    margin-right: 7px;
    font-size: 18px;
  }
  .show_issues_button {
    margin-left: 10px;
    font-size: 18px;
    color: ${colorPicker.solvvyRed};
  }

  .ant-input-group-wrapper.current-zoom {
    width: 150px;
    margin-right: 10px;

    &:hover {
      cursor: pointer;
    }

    &:hover .ant-input-group-addon {
      border-color: ${colorPicker.solvvyBlue};
      border-right-color: ${colorPicker.grey_17};
    }

    &:hover .ant-input-affix-wrapper {
      border-color: ${colorPicker.solvvyBlue};
      border-left-color: ${colorPicker.grey_17};
    }
    .ant-input-affix-wrapper-focused {
      box-shadow: none;
      border-color: ${colorPicker.grey_17};
    }

    .ant-input-affix-wrapper {
      background-color: ${colorPicker.grey_16};
      &:focus {
        border-color: ${colorPicker.grey_17};
      }
    }

    input {
      cursor: pointer;
      background-color: ${colorPicker.grey_16};
    }
  }
`;

export const WorkflowCardArea = styled.div`
  position: relative;
  width: 100%;
  background-color: ${colorPicker.white};
  border: 2px solid ${colorPicker.grey_11};
  border-top: none;
  margin: 0 !important;
  overflow: hidden;

  .ant-card-hoverable:hover {
    border-color: ${colorPicker.solvvyBlue} !important;
    box-shadow: 0 0 0 8px ${colorPicker.solvvyLightBlue} !important;
  }
  .card_active {
    border-color: ${colorPicker.solvvyBlue} !important;
    box-shadow: 0 0 0 8px ${colorPicker.solvvyLightBlue} !important;
  }
  .workflow_settings {
    position: absolute;
    top: 15px;
    left: 15px;
    background-color: ${colorPicker.grey_15};
    border: none;
    color: white;
    z-index: 1;

    &.drawer_open {
      left: 255px;
    }
    .icon {
      font-size: 20px;
    }

    &:focus {
      background-color: ${colorPicker.grey_15};
      border: none;
      color: white;
    }

    &:hover,
    &.active {
      background-color: ${colorPicker.solvvyBlue};
      color: white;
      box-shadow: 0 0 0 10px ${colorPicker.solvvyLightBlue};
    }
  }
  .grid_cards {
    position: relative;
    display: flex !important;
    justify-content: flex-start !important;
    flex-wrap: wrap !important;
    align-items: flex-start !important;
    max-height: calc(100vh - 265px); /* view height minus height of content above card area */
    overflow-y: auto;
    padding: 50px 30px 20px 50px;
  }
`;

export const StyledCard = styled(Card)`
  width: 250px !important;
  min-height: 250px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  border: 2px solid ${colorPicker.grey_2} !important;
  box-shadow: 0 1px 3px rgb(93, 93, 94);
  .ant-card-head {
    color: ${colorPicker.grey_15};
    background-color: ${colorPicker.grey_14};
    font-size: 15px;
    font-weight: bold;
    border-radius: 8px 8px 0 0;
    .ant-card-head-title {
      white-space: normal;
    }
  }
  .ant-card-body {
    border-radius: 0 0 8px 8px;
    overflow-x: auto;
  }
  .screen_content {
    line-height: 1.3;

    ol,
    ul {
      padding-inline-start: 25px;
    }
  }
`;

export const StyledCardLayout = styled.div`
  position: relative;
  margin: 20px;

  .mini_card {
    height: 33px;
    width: 30px;
    position: absolute;
    text-align: center;
    padding-top: 5px;
    background-color: ${colorPicker.grey_15};
    color: white;
    z-index: 10;
  }
  .card_step {
    top: 10px;
    left: -30px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: none;
  }
  .card_entry {
    top: 47px;
    left: -30px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: none;
    background-color: black;
  }
  .card_exit {
    bottom: 10px;
    right: -30px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: none;
    background-color: black;
  }

  .repeating-group-child-components {
    padding: 10px;
    border: 1px dashed ${colorPicker.grey_2};
  }
`;
export const StyledCardParagraph = styled.div`
  margin: 5px;
`;

export const StyledCardButton = styled.div`
  border: 1px solid ${props => (props.actionAvailable === true ? colorPicker.solvvyBlack : colorPicker.solvvyBlue)};
  padding: 5px;
  margin: 8px 0;
  min-height: 33px;
  text-align: center;
  color: ${props => (props.actionAvailable === true ? colorPicker.solvvyBlack : colorPicker.solvvyBlue)};
  position: relative;
  .card_step_connector {
    position: absolute;
    right: -11px;
    top: 10%;
    border: 1px solid ${colorPicker.solvvyBlue};
    background-color: ${colorPicker.solvvyBlue};
    color: ${colorPicker.white};
    border-radius: 50%;
    padding: 3px 7px;
    font-size: 13px;
    min-width: 29px;
  }
  .card_step_exit_connector {
    position: absolute;
    right: -9px;
    top: 10%;
    .anticon {
      font-size: 26px;
    }
  }
  &.error {
    border: 1px solid ${colorPicker.solvvyRed};
    color: ${colorPicker.solvvyRed};
    .anticon-warning {
      float: left;
      margin-top: 3px;
    }
  }
`;

export const StyledStepSearchDrawer = styled(Drawer)`
  &.ant-drawer-open .workflow_settings {
    left: 255px;
  }

  .workflow_settings {
    left: 15px;
  }
  .ant-drawer-content-wrapper {
    z-index: 1000;

    .ant-drawer-header {
      background-color: ${colorPicker.grey_14};
    }
    .ant-drawer-close {
      color: ${colorPicker.solvvyTeal};
    }
    .ant-drawer-body {
      padding: 0;
    }
  }
  .ant-drawer-mask {
    left: 0 !important;
  }

  &.ant-drawer-open .step_search_header {
    position: fixed;
  }

  .step_search_header {
    background-color: white;
    z-index: 2;
    box-shadow: -1px 2px 8px -5px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: -1px 2px 8px -5px rgb(0 0 0 / 15%);
    -moz-box-shadow: -1px 2px 8px -5px rgba(0, 0, 0, 0.15);
  }
  .steps_container {
    margin-top: 94px;
    position: relative;
  }
  .step_list_header {
    display: flex;
    padding: 4px 8px;
    border-top: 1px solid ${colorPicker.grey_1};
    color: ${colorPicker.grey_5};

    .card_actions {
      margin-left: auto;

      .ellipsis_action_button {
        color: ${colorPicker.solvvyBlue};

        &[disabled] {
          color: ${colorPicker.grey_5};
        }
      }
    }
  }

  .search_input {
    margin: 10px 4px;
  }

  .step_container {
    padding: 4px 8px;
    display: flex;
    justify-content: space-between;
    transition: all 0.2s;

    .step_name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .step_action {
      margin-left: 20px;
      cursor: pointer;
      visibility: hidden;
      color: ${colorPicker.grey_2};
    }

    &.selected .step_action {
      visibility: visible;
      color: ${colorPicker.solvvyBlue};
    }

    &:hover {
      background-color: #fafafa;
    }

    &:hover .step_action {
      visibility: visible;
    }

    -webkit-user-select: none; /* Safari */
    -webkit-touch-callout: none; /* iOS Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -ms-user-select: none; /* Internet Explorer/Edge */
    -moz-user-select: none; /* Old versions of Firefox */
    user-select: none; /* Non-prefixed version*/
    cursor: default;

    &.selected {
      background-color: ${colorPicker.solvvyLightBlue};
    }
  }
`;

export const StyledPropertiesDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    z-index: 1000;
    width: 500px !important;
    .ant-drawer-header {
      background-color: ${colorPicker.grey_14};
    }
    .ant-drawer-close {
      color: ${colorPicker.solvvyTeal};
    }
  }
  .ant-drawer-mask {
    left: 0 !important;
  }

  .ant-drawer-body {
    display: flex;
    height: calc(100vh - 55px); /* view height - width of drawyer header */
    flex-direction: column;
    padding: 0;
  }

  .workflow_intents {
    .ant-select-selector {
      border: none !important;
    }
  }

  .edit-intents-button {
    float: right;
    margin-top: -32px;
  }

  .properties {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }

  .properties-last-container {
    z-index: 1;
    box-shadow: 0px -4px 4px 0px rgb(0 0 0 / 12%);
    background-color: #fafafa;
  }

  .errors {
    border-radius: 0;
    .have_errors {
      color: ${colorPicker.solvvyRed};
    }
    .anticon {
      margin-right: 5px;
    }
    button {
      padding: 0;
      padding-right: 5px;
    }
    .ant-alert .anticon {
      font-size: 24px;
      color: ${colorPicker.solvvyRed};
      vertical-align: middle;
    }
    .ant-alert {
      margin-bottom: 10px;
    }
    .ant-alert:last-child {
      margin-bottom: 0;
    }
    .workflow_error {
    }
  }
`;

export const StyleWorkflowDetails = styled.div`
  padding: 12px 40px;

  .primary-details > div {
    margin: 10px 0;
  }
  hr {
    margin: 20px 0;
    opacity: 0.4;
  }
  hr:last-child {
    display: none;
  }

  .ant-form-item-label {
    line-height: inherit;
    & > label {
      color: inherit;
    }
  }
  label,
  .label {
    font-weight: 700;
    font-size: 16px;
    display: block;
    margin-bottom: 5px;

    .extra {
      font-weight: normal;
      margin-left: 5px;
    }
  }
  .ant-form-item-label {
    padding-bottom: 5px;
  }
  .ant-form-item-label > label {
    margin-bottom: 0;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const StyledWorkFlowProperties = styled.div`
  height: 100%;
  hr {
    margin: 20px 0;
    opacity: 0.4;
  }
  hr:last-child {
    display: none;
  }
  .key-properties {
    padding: 24px;
  }

  .ant-collapse-content {
    overflow: visible;
  }

  .ant-collapse,
  .ant-collapse > .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-content,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }
  .ant-collapse-header {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
  }
  .ant-collapse-header .extra {
    text-transform: none;
    margin-left: 5px;
  }
  .workflow-settings .ant-collapse-item:last-child.ant-collapse-item-active {
    border-bottom: 0;
  }

  .ant-form-item-label {
    line-height: inherit;
    & > label {
      color: inherit;
    }
  }
  label,
  .label {
    font-weight: 700;
    font-size: 16px;
    display: block;
    margin-bottom: 5px;

    .extra {
      font-weight: normal;
      margin-left: 5px;
    }
  }
  .label-with-time-delay-picker {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 5px;
  }
  .ant-form-item-label > label {
    margin-bottom: 0;
  }
  label.ant-checkbox-wrapper {
    font-weight: normal;
  }
  .decision_label {
    font-weight: 700;
    font-size: 16px;
    padding-bottom: 20px;
  }
  .small_label {
    font-size: 15px;
  }

  .ant-form-item-control {
    padding-bottom: 10px;
  }
  .ant-form-item-control.has-error {
    padding-bottom: 5px;
  }

  .workflow_text,
  .workflow_suggestions {
    padding-bottom: 20px;
  }

  .workflow_button_text {
    padding-bottom: 20px;
  }

  .button_action_view {
    display: flex;
    justify-content: flex-start;
    vertical-align: middle;
    margin-top: 30px;
    color: ${colorPicker.grey_2};
    .anticon {
      padding: 4px 5px 0 0;
    }
  }

  .button_action_select {
    width: 100%;
  }

  .ant-select {
    width: 100%;
    margin-bottom: 10px;
  }

  .action .anticon {
    margin-left: 10px;
    margin-top: 35px;
  }

  .step_select {
    display: block;
    width: 100%;
  }

  .repeating-group-child-components {
    border: 1px dashed ${colorPicker.grey_2};
    padding: 10px;
    margin-bottom: 10px;

    .child-component + .child-component {
      margin-top: 10px !important;
    }

    .child-component {
      position: relative;

      .child-component-actions {
        position: absolute;
        right: 0;
        top: -7px;
        z-index: 1;
      }
    }
  }

  .child-components-actions {
    float: right;
  }

  .card_actions {
    float: right;
  }

  .ellipsis_action_button {
    font-size: 18px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .workflow_suggestions {
    // margin-top: 20px;
    // margin-bottom: 20px;

    .anticon-edit {
      margin-left: 10px;
    }

    .suggestion {
      margin-top: 5px;

      button {
        padding-left: 0;
      }
    }

    .inactive {
      color: ${colorPicker.grey_2};
      margin-left: 10px;

      .anticon-warning {
        color: ${colorPicker.solvvyOrange};
        padding-left: 2px;
        padding-right: 2px;
      }
    }
  }

  .url-action-options,
  .exit-action-options,
  .condition-action-options {
    margin-bottom: 10px;
  }

  .info.anticon {
    color: ${colorPicker.solvvyTeal};
    margin-left: 5px;
  }

  .selected-channel-options {
    margin-left: 25px;
    .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }

  .ant-card.condition {
    margin-bottom: 10px;
    border-color: ${colorPicker.solvvyMediumPurple};

    .ant-card-head {
      padding: 2px 15px;
      min-height: auto;
      font-size: 16px;
      text-transform: uppercase;
      background-color: ${colorPicker.solvvyLightPurple_2};
      border-bottom-color: ${colorPicker.solvvyMediumPurple};
    }

    .ant-card-head-title,
    .ant-card-head-title a,
    .ant-card-extra,
    .ant-card-extra .anticon {
      padding: 0;
      color: ${colorPicker.solvvyMediumPurple};
    }

    .ant-card-body {
      padding: 12px;
      background-color: ${colorPicker.grey_16};
      border-top: 1px solid ${colorPicker.solvvyMediumPurple};
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .ant-form-item-control {
      padding-bottom: 0;
    }
  }

  .action-input {
    margin-left: 10px;
  }

  .multiple-steps-menu-container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    padding: 20px 84px;
    text-align: center;

    .multiple-step-title {
      font-size: 18px;
      margin-bottom: 10px;
    }

    button {
      margin: 6px 0;
    }
  }

  .image-prompt-container {
    height: 100px;
    margin: auto;
  }

  .overlay-step-components-alert {
    margin-bottom: 16px;

    &.ant-alert {
      font-size: 14px;
    }
  }

  .overlay-step-components-divider {
    &.ant-divider {
      color: #d6d6d6;
    }

    &.ant-divider-horizontal {
      width: auto;
      margin-left: -16px;
      margin-right: -16px;
    }
  }
`;

export const InstanceDetailsRow = styled(Row)`
  padding: 15px;
  border: 1px solid ${colorPicker.grey_11};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top: none;
  .anticon-check {
    color: ${colorPicker.solvvyTeal};
    padding-left: 10px;
  }
  .instance_version {
    padding-left: 10px !important;
  }
`;

export const StyledNotice = styled.div`
  font-size: 16px;
  color: ${colorPicker.solvvyBlue};
  .anticon {
    padding-right: 5px;
  }
  margin-bottom: 10px;
`;

export const StyledCardActionsPopoverContent = styled.div`
  button {
    display: block !important;
    color: ${colorPicker.grey_2};
    padding-left: 0;
    width: 100%;
    text-align: left;
  }
  button.delete {
    color: ${colorPicker.solvvyRed};
  }
`;

export const StyledPreviewPanel = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 10px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 10;
  border: 1px solid ${colorPicker.grey_11};
  transition: right 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);

  &.fade-enter {
    animation-duration: 0.2s;
  }
  &.fade-leave {
    animation-duration: 0.2s;
  }

  &.panel-opened {
    right: 500px;
  }

  .preview-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    min-height: 45px;
    background-color: ${colorPicker.grey_14};
    border-bottom: 1px solid ${colorPicker.grey_11};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .anticon {
      font-size: 26px;
      color: ${colorPicker.solvvyTeal};
    }
    .anticon + .anticon {
      margin-left: 10px;
    }
  }

  .preview-content {
    background-color: white;
    padding: 10px;
    box-shadow: inset 0 8px 8px -8px rgba(0, 0, 0, 0.3);

    &.conversational {
      height: calc(75vh + 68px);

      .mock-solvvy-v5-ui.mobile {
        height: calc(100% - 68px) !important;
      }
    }

    .workflow-exited {
      h2 {
        margin-bottom: 20px !important;
      }
    }

    &.analytics {
      height: 420px;
      width: 500px;
    }
  }
`;

export const StyledWorkflowsTable = styled(Table)`
  height: calc(100% - 90px);

  .ant-table-content {
    // turn off sorted column background highlight
    .ant-table-tbody > tr > td.ant-table-column-sort {
      background: ${colorPicker.white};
    }

    // row item selection and hover state.
    .ant-table-tbody > tr.ant-table-row-selected > td {
      background: #e6f7ff !important;
      border-color: #00000008;
    }

    .ant-table-tbody > tr.ant-table-row:not(.ant-table-row-selected):hover > td {
      background: #fafafa !important;
    }
  }

  .wf_name_container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  .wf_name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .details_link {
    color: ${colorPicker.grey_6};
  }

  .wf_add_tag_button {
    opacity: 0;
  }
  .wf_add_tag_button.active {
    opacity: 1;
    animation-duration: 0.2s;
  }
  .ant-tag {
    white-space: normal;
  }
`;

export const PrintStyles = styled.div`
  height: 100%;
  @media print {
    // disable certain elements
    .workflow_status_area {
      display: none;
    }
    .workflow_edit_header {
      .save-publish-buttons {
        display: none;
      }
      .auto_save_notice {
        display: none;
      }
    }

    // reduce font size
    .ant-card-head-title,
    .step_content_paragraph,
    .ant-card,
    .action_button {
      font-size: 80%;
    }

    // reduce padding
    .ant-card-head {
      padding: 0 16px;
    }
    .ant-card-body {
      padding: 16px;
    }
    .card_layout {
      margin: 0;
    }

    .workflow_step_area {
      border: none;
      padding: 0 !important;
      margin-top: 20px !important;
      overflow-y: visible !important;
      max-height: none !important;
      display: table !important;
    }

    .card_wrapper {
      margin: 0 10px 20px 30px;
      vertical-align: top;
      display: inline-block !important;
      page-break-inside: avoid !important;
    }

    .card {
      box-shadow: none;
      // width: 270px !important;
      width: 200px !important;

      &.ant-card-hoverable:hover {
        border-color: ${colorPicker.grey_2} !important;
        box-shadow: none !important;
      }
    }

    .action_button {
      min-height: 25px;
    }

    .mini_card {
      border: 1px solid ${colorPicker.grey_15};
      border-right: none;
      &.card_entry,
      &.card_exit {
        display: none;
      }
    }

    .jointjs-container {
      width: 100%;
      height: 100%;
    }
  }
`;

export const StyledPanel = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  z-index: 10;

  &.panel-opened {
    right: 500px;
  }
`;

export const StyledAnalyticsPanel = styled.iframe`
  height: 400px;
  width: 454px;
  margin-top: 24px;
  border: none;
`;

export const StyledRevertWorkflowModal = styled(Modal)`
  .warning-message {
    color: ${colorPicker.solvvyRed};
    float: left;

    .anticon {
      margin-right: 5px;
    }
  }
`;

export const StyledNewStepOption = styled.div`
  color: ${colorPicker.solvvyLightPurple_5} !important;
  border-bottom: 1px solid ${colorPicker.grey_18};
  padding: 5px 0;
  cursor: pointer !important;
`;

export const StyledAddComponentButtons = styled.div`
  padding: 8px 16px 16px;

  button {
    margin-top: 8px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

export const WYSIWYGEditorContainer = styled.div`
  .editor-wrapper {
    border: 1px solid ${colorPicker.grey_9};
    border-radius: 4px;
    transition: all 0.3s;
    margin: 0px 2px;
  }
  .editor-wrapper:hover {
    border-color: #40a9ff;
  }
  .editor-focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);

    .tox .tox-editor-header {
      visibility: visible;
      height: unset;
    }
  }
  .image-prompt {
    .tox .tox-editor-header {
      visibility: visible;
      height: unset;
    }
  }
  .tox-tinymce {
    border: 0;
    border-radius: inherit;
  }
  .tox .tox-statusbar {
    border-color: ${colorPicker.grey_9};
  }
  .tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
    border-color: transparent;
  }
  .tox .tox-tbtn svg {
    fill: ${colorPicker.grey_13} !important;
  }
  .tox .tox-tbtn--disabled svg {
    fill: ${colorPicker.grey_7} !important;
  }
  .tox .tox-toolbar__primary {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg height='39px' viewBox='0 0 40 39px' width='40' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0' y='38px' width='100' height='1' fill='%23E7E7E3'/%3E%3C/svg%3E");
    border-top-color: ${colorPicker.grey_9} !important;
  }
  .tox .tox-editor-header {
    visibility: hidden;
    height: 0px;
  }

  button[title='${INSERT_VARIABLE_TOOLTIP}'] .tox-tbtn__select-chevron {
    display: none;
  }
`;

export const StyledInstanceNameColumn = styled.span`
  & > span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: keep-all;
  }
`;

export const StyledIntentCollectionModal = styled(Modal)`
  .intent-search {
    max-width: 354px;
    float: right;
  }

  .root-breadcrumb {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-breadcrumb {
    .ant-breadcrumb-link {
      color: rgba(0, 0, 0, 0.85) !important;
    }
    font-size: 14px !important;
  }

  .ant-modal-title {
    padding: 0 4px !important;
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.85) !important;
  }

  .ant-modal-footer {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .ant-btn {
    border-radius: 2px;
  }

  .clear-intent-container {
    float: left;
    display: flex;
    align-items: center;
    line-height: 2em;

    .clear-intents-button {
      margin-left: 10px;
      line-height: 21px;
    }
  }

  .back-button {
    margin-right: 15px;
    font-size: 14px;
    line-height: 24px;
  }

  .empty-container {
    min-height: 50vh;
  }

  .search-empty-element {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
  }

  .ant-collapse {
    background: transparent;
    margin: 15px 10px;
    border: none;

    .ant-collapse-item {
      border: 1px solid #f0f0f0;
      border-radius: 4px;
      margin-bottom: 15px;
      overflow: hidden;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .ant-collapse-content {
      border-top: none;

      .ant-collapse-content-box {
        padding-top: 5px;
      }
    }

    .ant-collapse-header {
      padding-bottom: 0 !important;
    }
  }
`;

export const StyledCollapse = styled(Collapse)`
  height: 50vh;
  overflow-y: scroll;
`;

export const StyledIntentCollectionGridItem = styled(Card)`
  margin-bottom: 15px !important;
  cursor: pointer;
  height: fit-content;

  .highlight {
    background-color: ${colorPicker.solvvyLightBlue};
    padding: 0;
  }

  .ant-card-body {
    padding: 10px 20px !important;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-card-head {
    font-size: 14px !important;
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 20px;
  }

  .ant-card-head-title {
    padding: 10px 0;
  }

  .ant-card-extra {
    padding: 10px 0;
  }

  .intent-name {
    color: rgba(0, 0, 0, 0.85);
  }
  .intent-category-name {
    color: rgba(0, 0, 0, 0.85);
    text-transform: uppercase;
    margin-left: 10px;
    font-size: 12px;
  }
  .intent-description {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
  }

  &.intent-collection {
    color: rgba(0, 0, 0, 0.85);
    .caret {
      position: absolute;
      top: 22px;
      right: 12px;
    }
  }
`;

export const StyledIntentCollectionHeader = styled.div`
  .intent-collection-name {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.85);
  }
  .highlight {
    background-color: ${colorPicker.solvvyLightBlue};
    padding: 0;
  }
  .intent-collection-description {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
  }
  .selected-intent-count {
    border-radius: 50%;
    background-color: ${colorPicker.solvvyLightBlue};
    color: ${colorPicker.solvvyBlue};
    font-size: 0.7rem;
    width: 18px;
    height: 18px;
    text-align: center;
    display: inline-block;
    line-height: 20px;
    margin-left: 5px;
  }
`;
