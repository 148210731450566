import { DownOutlined, LeftCircleTwoTone, LoadingOutlined, PieChartTwoTone } from '@ant-design/icons';
import { OrgPermission } from '@solvvy/util/lib/authorization';
import { Button, Col, Dropdown, Menu, Modal, Row, Tooltip } from 'antd';
import bind from 'bind-decorator';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { Component, default as React } from 'react';
import { Helmet } from 'react-helmet';
import UnpublishedChangesModal from '../../shared/components/UnpublishedChangesModal';
import { Colors } from '../../shared/util/Colors';
import { MOD_KEY } from '../../shared/util/keymap';
import { OrgStore } from '../../store/orgStore';
import { IWorkflow } from '../../store/ResolveUI/Workflow';
import { ResolveUiStore } from '../../store/resolveUiStore';
import { typedInject } from '../../store/rootStore';
import { RouterStore } from '../../store/routerStore';
import { WorkflowStore } from '../../store/workflowStore';
import { orgPageRoute } from '../routes';
import {
  showConfirmDeleteModal,
  showConfirmImportModal,
  showConfirmSwitchToConversationalInstanceModal
} from './actionConfirmModals';
import DuplicateWorkflowModal from './DuplicateWorkflowModal';
import PublishWorkflowModal from './PublishWorkflowModal';
import RevertWorkflowModal from './RevertWorkflowModal';
import { StyledEditWorkflowHeader } from './workflow.styles';
import OverviewAnalytics from './WorkflowReporting/OverviewAnalytics';

interface IProps {
  workflow: IWorkflow;
  spatialEditor: boolean;
  optimizeLayoutVertical: () => void;
  optimizeLayoutHorizontal: () => void;
  onImport: () => void;
  print: () => void;
  routerStore: RouterStore;
  orgStore: OrgStore;
  workflowStore: WorkflowStore;
  resolveUiStore: ResolveUiStore;
}

interface IState {
  showUnpublishedChangesModal: boolean;
  showInstanceSelectModal: boolean;
  showRevertWorkflowModal?: boolean;
  showPublishWorkflowModal?: boolean;
  publishedComments?: string;
}

@observer
class WorkflowEditHeader extends Component<IProps, IState> {
  origWorkflow: IWorkflow;
  state: IState = {
    showUnpublishedChangesModal: false,
    showInstanceSelectModal: false,
    publishedComments: ''
  };

  @bind
  goBack() {
    const { routerStore } = this.props;

    routerStore.history.push(
      orgPageRoute.stringify({
        ...routerStore.activeRouteParams,
        orgPage: 'workflows'
      })
    );
  }

  @bind
  async handleDuplicateWorkflow() {
    const instances = this.props.workflowStore.getInstanceDisplays(true);
    if (instances.length > 1) {
      // more than one instance available. show instance select modal.
      this.setState({ showInstanceSelectModal: true });
    } else {
      this.confirmDuplicateWorkflow();
    }
  }

  @bind
  hideInstanceSelectModal() {
    this.setState({ showInstanceSelectModal: false });
  }

  @bind
  confirmDuplicateWorkflow(selectedInstanceId?: string, translateToLanguage?: string) {
    const { workflowStore } = this.props;

    const instanceDisplays = this.props.workflowStore.getInstanceDisplays(true);
    const firstInstance = instanceDisplays.length > 0 ? instanceDisplays[0].instance : undefined;
    const instanceId = selectedInstanceId || firstInstance?.raw.id;

    const currentInstance = workflowStore.currentWorkflowInstance;
    const newInstance = workflowStore.getInstanceById(instanceId);

    if (workflowStore.isSwitchingToConversationalInstance(currentInstance, newInstance)) {
      showConfirmSwitchToConversationalInstanceModal(() => this.handleConfirmDuplicateWorkflow(instanceId));
    } else {
      this.handleConfirmDuplicateWorkflow(instanceId, translateToLanguage);
    }
  }

  @bind
  async handleConfirmDuplicateWorkflow(instanceId: string, translateToLanguage?: string) {
    const { workflowStore, resolveUiStore } = this.props;

    const newId = await workflowStore.duplicateWorkflow(workflowStore.workflowToEdit, instanceId!, translateToLanguage);
    const orgId = resolveUiStore.getOrgIdByUiInstanceId(instanceId);

    if (newId && orgId) {
      workflowStore.navigateToWorkflowInAnyOrg(newId, orgId);
    }
  }

  @bind
  printWorkflow() {
    window.print();
  }

  @bind
  onClickRevertUnpublished() {
    this.setState({ showRevertWorkflowModal: true });
  }

  @bind
  onClickViewUnpublishedChanges() {
    this.setState({ showUnpublishedChangesModal: true });
  }

  @bind
  onClickDelete() {
    const { workflowStore } = this.props;
    showConfirmDeleteModal(async () => {
      await workflowStore.deleteWorkflow(workflowStore.workflowToEdit);
      this.goBack();
    }, this.props.workflowStore.getWorkflowDependencies());
  }

  @bind
  onClickImport() {
    const { workflowStore, workflow } = this.props;

    showConfirmImportModal(async () => {
      await workflowStore.importWorkflowFromClipboard(workflow);
      if (this.props.onImport) {
        this.props.onImport();
      }
    });
  }

  @action.bound
  onReviewIssues() {
    this.props.workflowStore.showIssues();
  }

  @bind
  showPublishIssuesModal() {
    const numIssues = this.props.workflowStore.getWorkflowErrors().length;
    Modal.confirm({
      title: 'Can Not Publish',
      content: (
        <div>
          <strong>
            {numIssues} {numIssues === 1 ? 'issue was' : 'issues were'} detected.
          </strong>{' '}
          All issues must be resolved before a workflow can be published.
        </div>
      ),
      okText: 'Review Issues',
      cancelText: 'Cancel',
      className: 'confirm-modal-no-icon',
      icon: '',
      width: 600,
      onOk: this.onReviewIssues
    });
  }

  @bind
  onClickPublish() {
    const haveErrors = this.props.workflowStore.getWorkflowErrors().length > 0;
    if (haveErrors) {
      this.showPublishIssuesModal();
    } else {
      this.setState({ showPublishWorkflowModal: true });
    }
  }

  renderActionsMenu(hasUnpublishedChanges: boolean) {
    const { workflowStore, workflow } = this.props;
    const { hasPermission } = this.props.orgStore;
    const { isGlobalUser } = this.props.orgStore.currentUserContext;
    const fullyEditable = workflowStore.workflowToEditIsFullyEditable;
    const { canUndo, canRedo } = workflowStore;
    const { showInstanceSelectModal, showRevertWorkflowModal } = this.state;
    const canUpdateWorkflow = hasPermission(OrgPermission.workflowUpdate);
    const canDeleteWorkflow = hasPermission(OrgPermission.workflowDelete);

    return (
      <Menu data-testid="workflow-actions-menu">
        {canUpdateWorkflow && (
          <Menu.Item onClick={() => workflowStore.exportWorkflowToClipboard(workflow)}>Copy to Clipboard</Menu.Item>
        )}
        {canUpdateWorkflow && <Menu.Item onClick={this.onClickImport}>Paste and Replace</Menu.Item>}
        {canUpdateWorkflow && fullyEditable && (
          <Menu.Item onClick={() => workflowStore.pasteSteps()}>Paste Step(s)</Menu.Item>
        )}
        {canUpdateWorkflow && (isGlobalUser || fullyEditable) && <Menu.Divider />}

        {canUpdateWorkflow && (
          <Menu.Item disabled={!canUndo} onClick={() => workflowStore.undoLastSave()}>
            Undo <span className="menu-shortcut">{`${MOD_KEY}Z`}</span>
          </Menu.Item>
        )}
        {canUpdateWorkflow && (
          <Menu.Item disabled={!canRedo} onClick={() => workflowStore.redoLastUndo()}>
            Redo <span className="menu-shortcut">{`⇧${MOD_KEY}Z`}</span>
          </Menu.Item>
        )}
        {canUpdateWorkflow && <Menu.Divider />}

        {canUpdateWorkflow && this.props.spatialEditor && (
          <Menu.Item onClick={this.props.optimizeLayoutVertical}>Optimize Layout (vertical)</Menu.Item>
        )}
        {canUpdateWorkflow && this.props.spatialEditor && (
          <Menu.Item onClick={this.props.optimizeLayoutHorizontal}>Optimize Layout (horizontal)</Menu.Item>
        )}
        {canUpdateWorkflow && this.props.spatialEditor && <Menu.Divider />}

        {canUpdateWorkflow && <Menu.Item onClick={this.onClickRevertUnpublished}>Version Control</Menu.Item>}
        {showRevertWorkflowModal && (
          <RevertWorkflowModal
            workflow={workflow}
            onCancel={() => this.setState({ showRevertWorkflowModal: false })}
            onSuccess={() => this.setState({ showRevertWorkflowModal: false })}
          />
        )}

        {isGlobalUser && (
          <Menu.Item disabled={!hasUnpublishedChanges} onClick={this.onClickViewUnpublishedChanges}>
            Unpublished Changes
          </Menu.Item>
        )}
        {hasPermission(OrgPermission.workflowCreate) && fullyEditable && (
          <Menu.Item onClick={this.handleDuplicateWorkflow}>Duplicate</Menu.Item>
        )}

        <DuplicateWorkflowModal
          visible={showInstanceSelectModal}
          onOk={this.confirmDuplicateWorkflow}
          onCancel={this.hideInstanceSelectModal}
        />

        {canUpdateWorkflow && <Menu.Divider />}

        <Menu.Item onClick={this.props.print}>Print</Menu.Item>

        {canDeleteWorkflow && fullyEditable && <Menu.Divider />}
        {canDeleteWorkflow && fullyEditable && <Menu.Item onClick={this.onClickDelete}>Delete</Menu.Item>}
      </Menu>
    );
  }

  @action
  toggleButtonBreakdown = () => {
    this.props.workflowStore.isBreakdownOpen = true;
  };

  render() {
    const { workflowStore, workflow, orgStore } = this.props;
    const { showPublishWorkflowModal } = this.state;
    const { hasPermission, canAccessWfReporting, currentUserContext } = orgStore;
    const unpublishedChanges = workflowStore.getWorkflowUnpublishedChanges(workflow);
    const hasUnpublishedChanges = unpublishedChanges.filter(change => change.changed).length > 0;
    return (
      <>
        {workflowStore.isOverviewAnalyticsModal && (currentUserContext.isGlobalUser || canAccessWfReporting) && (
          <OverviewAnalytics />
        )}
        <StyledEditWorkflowHeader className="workflow_edit_header" data-testid="workflow-edit-header">
          <Helmet>
            <title>{workflow.name || 'Untitled'} | Workflows | Solvvy Dashboard</title>
          </Helmet>
          <Button type="link" onClick={() => this.goBack()} className="go_back">
            <LeftCircleTwoTone twoToneColor={Colors.solvvyTeal} />
            All Workflows
          </Button>

          <Row justify="space-between" align="middle" gutter={12}>
            <Col xs={12} md={8}>
              <h1 className="workflow_name">{workflow.name || 'Untitled'}</h1>
              <div className="auto_save_notice">
                {workflowStore.saving && (
                  <span>
                    <LoadingOutlined /> Auto Saving
                  </span>
                )}
                {!workflowStore.saving && workflowStore.lastSavedAt && (
                  <span>Last Auto Saved: {workflowStore.lastSavedAt}</span>
                )}
              </div>
            </Col>
            <Col xs={12} md={4} className="save-publish-buttons">
              {currentUserContext.isGlobalUser || canAccessWfReporting ? (
                <Tooltip title={currentUserContext.isGlobalUser && !canAccessWfReporting ? 'Global Users only' : null}>
                  <Button onClick={workflowStore.toggleOverviewAnalyticsModal}>
                    <PieChartTwoTone twoToneColor={Colors.solvvyTeal} />
                    Workflow Insights (BETA){currentUserContext.isGlobalUser && !canAccessWfReporting ? '*' : null}
                  </Button>
                </Tooltip>
              ) : null}
              <Dropdown overlay={this.renderActionsMenu(hasUnpublishedChanges)}>
                <Button data-testid="workflow-actions-button">
                  Actions <DownOutlined />
                </Button>
              </Dropdown>

              {hasPermission(OrgPermission.workflowUpdate) && (
                <>
                  {hasUnpublishedChanges ? (
                    <Button htmlType="submit" type="primary" onClick={this.onClickPublish}>
                      Publish
                    </Button>
                  ) : (
                    <Tooltip title="No changes to publish">
                      <Button disabled={!hasUnpublishedChanges}>Publish</Button>
                    </Tooltip>
                  )}

                  {showPublishWorkflowModal && (
                    <PublishWorkflowModal
                      workflow={workflow}
                      onCancel={() => this.setState({ showPublishWorkflowModal: false })}
                      onSuccess={() => this.setState({ showPublishWorkflowModal: false })}
                    />
                  )}
                </>
              )}
            </Col>
          </Row>

          <UnpublishedChangesModal
            visible={this.state.showUnpublishedChangesModal}
            onClose={() => this.setState({ showUnpublishedChangesModal: false })}
            unpublishedChanges={unpublishedChanges}
          />
        </StyledEditWorkflowHeader>
      </>
    );
  }
}

export default typedInject('routerStore', 'orgStore', 'workflowStore', 'resolveUiStore')(WorkflowEditHeader);
