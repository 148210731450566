import { computed, observable } from 'mobx';
import asyncComputed from '../shared/util/asyncComputed';

export interface IColumns {
  resource_id: string;
  title: string;
  count: number;
  created_at: string;
  url: string;
}

export class RecrawlHubStore {
  @observable
  recrawlDataOffset: number = 0;

  @observable
  recrawlDataLimit: number = 10;

  @observable
  searchText: string;

  @computed
  get tableData(): IColumns[] {
    if (this.activeSolutionsPerArticle.fulfilled) {
      return this.activeSolutionsPerArticle.value;
    }
    return [];
  }

  @computed
  get shouldShowArticleUrl(): boolean {
    return this.orgStore.shouldShowArticleUrlWithTitle;
  }

  activeSolutionCount = asyncComputed(async () => {
    const orgId = this.orgStore.selectedOrgId;
    const params = {
      org_id: orgId,
      type: 'snippet',
      is_active: true,
      is_current: true,
      is_canonical: true,
      limit: 1
    };
    return this.resourcesService.getActiveSolutionCount(params);
  });

  activeWebpages = asyncComputed(async () => {
    const orgId = this.orgStore.selectedOrgId;
    const params = {
      org_id: orgId,
      type: 'webpage',
      fields: 'id,type,raw.title,raw.url',
      is_active: true,
      is_current: true,
      limit: 5000
    };
    return this.resourcesService.getActiveWebpages(params);
  });

  activeSolutionsPerArticle = asyncComputed(async () => {
    const orgId = this.orgStore.selectedOrgId;
    const params = {
      org_id: orgId,
      limit: this.recrawlDataLimit,
      offset: this.recrawlDataOffset,
      ...(this.searchText ? { searchText: this.searchText } : {})
    };
    return this.resourcesService.getSolutionsPerArticle(params);
  });

  activeSolutionsPerArticleCount = asyncComputed(async () => {
    const orgId = this.orgStore.selectedOrgId;
    const params = {
      org_id: orgId,
      ...(this.searchText ? { searchText: this.searchText } : {})
    };
    return this.resourcesService.getSolutionsPerArticleCount(params);
  });

  constructor(private orgStore: any, private resourcesService: any) {}
}
