import DOMPurify from 'dompurify';
import sanitizeHtml, { IOptions } from 'sanitize-html';
import { DeviceBrowser } from 'src/store/ResolveUI/types';
import Cookies from 'universal-cookie';
import { recordErrors } from './recordErrors';

const cookies = new Cookies();

export const isLocalhost = window.location.href.includes('localhost');

export const isStaging =
  window.location.href.includes('dev') ||
  window.location.href.includes('dashboard-review') ||
  window.location.href.includes('.staging') ||
  window.location.href.includes('localhost');
export const stagingReviewBranch = isStaging ? cookies.get('branch') : undefined;

export const removeHtmlFromUrl = (url: string): string => {
  const regex = /\.(html)(.*)/;
  const replacedUrl = url.replace(regex, '');
  return replacedUrl;
};

export const sanitizeHTML = (html = '', options?: IOptions, lessStrictMode = false): string => {
  const sanitizeOptions: any = {
    parser: {
      decodeEntities: false
    },
    ...(options || {})
  };
  // currently required for Workflows Paragraph Component
  if (lessStrictMode) {
    sanitizeOptions.allowedTags = sanitizeHtml.defaults.allowedTags.concat(['img', 'iframe']);
    sanitizeOptions.allowedAttributes = {
      img: ['src', 'alt', 'style', 'class'],
      iframe: ['src', 'width', 'height', 'frameborder']
    };
  }
  try {
    const sanitizedMessage = sanitizeHtml(html, sanitizeOptions);
    return sanitizedMessage;
  } catch (e) {
    recordErrors(e);
    return html;
  }
};

export function sanitizeHTMLForUiPreview(html: string) {
  return sanitizeHTML(html, { allowedAttributes: { '*': ['class'] } });
}

export function sanitizeSvg(svg: string, { throwOnError = true }: { throwOnError?: boolean } = {}) {
  try {
    return DOMPurify.sanitize(svg, { USE_PROFILES: { svg: true, svgFilters: true } });
  } catch (e) {
    recordErrors(e);
    if (throwOnError) {
      throw e;
    }
    return '';
  }
}

export function calculateDeviceBrowser() {
  const isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
  const isFirefox = !!navigator.userAgent.match(/firefox/i);

  if (isSafari) {
    return DeviceBrowser.Safari;
  } else if (isFirefox) {
    return DeviceBrowser.Firefox;
  } else {
    return null;
  }
}

export function isValidUrl(url: string) {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}
