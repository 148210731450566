import { sanitizeHTMLForUiPreview } from 'src/shared/util/misc';
import { ConversationalUiInstance } from './ConversationalUiInstance';
import { SunshineUiInstance } from './SunshineUiInstance';
import { DialogType, UiMajorVersion } from './types';
import { UiInstance } from './UiInstance';

export const createInstance = (
  instance: any,
  orgTimeZone,
  orgSettings,
  configuration
): UiInstance | ConversationalUiInstance | SunshineUiInstance => {
  const dialogType = instance.dialogType;
  const uiMajorVersion = instance.uiMajorVersion;

  if (instance.socialChannel) {
    return new SunshineUiInstance(instance, orgTimeZone, orgSettings, configuration);
  }

  // V4 supports only Professional Dialog type
  if (uiMajorVersion === UiMajorVersion.V4) {
    return new UiInstance(instance, orgTimeZone, orgSettings, configuration);
  }

  if (uiMajorVersion === UiMajorVersion.V5) {
    switch (dialogType) {
      case DialogType.Conversational:
        return new ConversationalUiInstance(instance, orgTimeZone, orgSettings, configuration);
      case DialogType.Professional:
      default:
        return new UiInstance(instance, orgTimeZone, orgSettings, configuration);
    }
  }

  return new UiInstance(instance, orgTimeZone, orgSettings, configuration);
};

export const unescape = (html: string) => {
  const divElement = document.createElement('div');
  divElement.innerHTML = sanitizeHTMLForUiPreview(html);
  return divElement.textContent || '';
};
