import { Editor } from '@tinymce/tinymce-react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { useRootStore } from 'src/hooks/useRootStore';
import { DataSourcesStore } from 'src/store/dataSourcesStore';
import { DialogType } from 'src/store/ResolveUI/types';
import { DEFAULT_EMPTY_MEDIA_PROMPT_TEXT } from 'src/store/workflowStore';
import { IMAGE_IFRAME_TAG_PATTERN, MEDIA_PROMPT_ALLOWED_KEYS, TINYMCE_KEY } from '../../shared/util/wysiwygUtil';
import { STEP_DISPLAY_TYPE } from './constants';
import { WYSIWYGEditorContainer } from './workflow.styles';

interface IProps {
  id: string;
  html: string;
  disabled: boolean;
  isMediaPrompt?: boolean;
  stepDisplayType?: string;
  onChange: (newHtml: string) => void;
}

export const INSERT_VARIABLE_TOOLTIP = 'Insert a variable';
const INSERT_VARIABLE_BUTTON_ID = 'INSERT_VARIABLE_BUTTON_ID';
const INSERT_VARIABLE_ICON_ID = 'INSERT_VARIABLE_ICON_ID';
/** Single curly braces icon */
const INSERT_VARIABLE_ICON_SVG =
  '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.91905 13.9395C5.74231 13.6094 5.14227 12.8577 5.11893 11.6843V10.6192C5.11893 9.86919 4.79725 9.49416 4.15387 9.49416V8.6041C4.79725 8.6041 5.11893 8.22741 5.11893 7.47403V6.41396C5.12893 5.81392 5.28394 5.32889 5.58396 4.95886C5.88732 4.58884 6.33235 4.32049 6.91905 4.15381L7.15907 4.85385C6.58236 5.04053 6.28401 5.55057 6.26401 6.38396V7.46403C6.26401 8.21408 5.97566 8.74078 5.39895 9.04413C5.97566 9.35082 6.26401 9.88252 6.26401 10.6392V11.7043C6.28401 12.5377 6.58236 13.0477 7.15907 13.2344L6.91905 13.9395Z" fill="#676765"/><path d="M10.836 13.2344C11.4227 13.0444 11.7227 12.5244 11.736 11.6743V10.6342C11.736 9.86419 12.0394 9.33582 12.6461 9.04913C12.0394 8.76245 11.736 8.22908 11.736 7.44903V6.41396C11.7227 5.5639 11.4227 5.04387 10.836 4.85385L11.076 4.15381C11.6827 4.32382 12.1344 4.6005 12.4311 4.98386C12.7278 5.36389 12.8761 5.86225 12.8761 6.47896V7.47903C12.8761 8.22908 13.1995 8.6041 13.8462 8.6041V9.49416C13.1995 9.49416 12.8761 9.86919 12.8761 10.6192V11.6043C12.8761 12.2277 12.7261 12.7294 12.4261 13.1094C12.1294 13.4928 11.6794 13.7694 11.076 13.9395L10.836 13.2344Z" fill="#676765"/><path d="M1 1.38462H17V-1.38462H1V1.38462ZM16.6154 1V17H19.3846V1H16.6154ZM17 16.6154H1V19.3846H17V16.6154ZM1.38462 17V1H-1.38462V17H1.38462ZM1 16.6154C1.21242 16.6154 1.38462 16.7876 1.38462 17H-1.38462C-1.38462 18.317 -0.316985 19.3846 1 19.3846V16.6154ZM16.6154 17C16.6154 16.7876 16.7876 16.6154 17 16.6154V19.3846C18.317 19.3846 19.3846 18.317 19.3846 17H16.6154ZM17 1.38462C16.7876 1.38462 16.6154 1.21242 16.6154 1H19.3846C19.3846 -0.316988 18.317 -1.38462 17 -1.38462V1.38462ZM1 -1.38462C-0.316988 -1.38462 -1.38462 -0.316985 -1.38462 1H1.38462C1.38462 1.21242 1.21242 1.38462 1 1.38462V-1.38462Z" fill="#676765"/></svg>';

/** Data source name in variable selection menu is styled using this */
const ITALIC_STYLE_FOR_DISABLED_MENU_ITEM = (
  <style
    dangerouslySetInnerHTML={{
      __html:
        '.tox-tiered-menu .tox-collection__item--state-disabled .tox-collection__item-label { font-style: italic; }'
    }}
  />
);

function ParagraphEdit({ html, disabled, isMediaPrompt, stepDisplayType, onChange }: IProps) {
  const { workflowStore, dataSourcesStore } = useRootStore();

  /**
   * The Editor requires the content to be in HTML in order to work correctly.
   * If the content is not in HTML, the cursor will be moved to the start on focus.
   * To avoid this behaviour, a copy of the content is passed as value to the Editor
   * which will then be updated `onEditorChange`.
   */
  const [value, setValue] = useState(html);
  const [isActive, setIsActive] = useState(false);
  const editorRef = useRef<Editor>(null);
  const isCarousel = stepDisplayType === STEP_DISPLAY_TYPE.CAROUSEL;

  /**
   * If the html prop value changes, update the state.
   * This is to update the editor content when the value is updated elsewhere.
   */
  useEffect(() => {
    if (!isActive) {
      setValue(html);
    }
  }, [html]);

  function handleEditorChange(newHtml: string) {
    let updateState = true;

    /**
     * Add the image/iframe to the editor if the media prompt is empty
     * else replace the image/iframe present in the editor with the new image/iframe.
     */
    if (isMediaPrompt && new RegExp(IMAGE_IFRAME_TAG_PATTERN).test(value)) {
      const existingMedia = value.match(IMAGE_IFRAME_TAG_PATTERN) || [];
      let newMedia = newHtml.match(IMAGE_IFRAME_TAG_PATTERN) || [];

      // Exclude the images/iframes that are already present in the editor. `addedMedia` will have the new images/iframes.
      // if `addedMedia` is empty, then probably the image/iframe that is already present in the editor is added again.
      const addedMedia = newMedia.filter(media => !existingMedia.includes(media));
      if (addedMedia.length > 0) {
        newMedia = [...addedMedia];
      } else if (existingMedia.length > 0 && newMedia.length > 0) {
        newMedia = [existingMedia[0]];
      }

      if (newMedia.length) {
        newHtml = newMedia[newMedia.length - 1];

        /**
         * Wrap the img/iframe tag with a p tag and update the state
         * so that the editor places the cursor at the correct position.
         */
        setValue(`<p>${newHtml}</p>`);
        updateState = false;
      }
    }

    // replace the mention tags in html with our templateExpression {{xxxx}}
    newHtml = newHtml.replace(new RegExp('<span[^/>].*?mention-id="({{[^}]+}})".*?>.*?</span>', 'g'), '$1');

    if (updateState) {
      setValue(newHtml);
    }

    /**
     * If plain text without HTML is passed as a value to the Editor, the Editor wraps the text with `p`
     * and fires the `onEditorChange` callback. This is the default behaviour of TinyMCE. To prevent this,
     * the `handleChange` is invoked only when the Editor is in focused state.
     */
    if (isActive) {
      onChange(isMediaPrompt && newHtml === '' ? DEFAULT_EMPTY_MEDIA_PROMPT_TEXT : newHtml);
    }
  }

  async function uploadImage(file: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async e => {
        if (e && e.target && e.target.result) {
          const contentBase64 = (e.target.result || '').toString().split(',')[1] || '';
          const url = await workflowStore.uploadImage(file.type, contentBase64);
          resolve(url);
        }
      };
      reader.onerror = e => reject(e);
      reader.onabort = e => reject(e);
    });
  }

  function getToolbarOptions() {
    const defaultOptions = 'bold italic bullist numlist outdent indent link unlink';
    switch (workflowStore.currentWorkflowInstanceDialogType) {
      case DialogType.Conversational: {
        if (isMediaPrompt) {
          return isCarousel ? 'image' : 'image media';
        }
        return `${defaultOptions} emoticons ${INSERT_VARIABLE_BUTTON_ID}`;
      }
      case DialogType.Professional:
      default:
        return `${defaultOptions} image media ${INSERT_VARIABLE_BUTTON_ID}`;
    }
  }

  return (
    <WYSIWYGEditorContainer>
      {ITALIC_STYLE_FOR_DISABLED_MENU_ITEM}
      <div className={classnames('editor-wrapper', { 'editor-focused': isActive, 'image-prompt': isMediaPrompt })}>
        <Editor
          ref={editorRef}
          apiKey={TINYMCE_KEY}
          scriptLoading={{ async: true }}
          init={{
            branding: false,
            menubar: false,
            plugins: 'lists link code paste autoresize emoticons solvvyimage solvvymentions media',
            toolbar: getToolbarOptions(),
            content_style: `body { font-family: 'Roboto', sans-serif; font-size: 14px;${
              isMediaPrompt ? ' caret-color: transparent;' : ''
            } } img, iframe { max-width: 100%; } img { max-height: 250px; }
            ${isCarousel ? 'img { width: 240px; height: 135px; }' : ''}
            span.mention { background-color: #E0E8F4; color: #000000; padding: 2px 4px; margin: 0 2px; border-radius: 2px; }`,
            paste_as_text: true,
            default_link_target: '_blank',
            max_height: 320,
            toolbar_location: 'bottom',
            statusbar: false,
            media_alt_source: false,
            media_poster: false,
            images_file_types: 'jpeg,jpg,png,gif',
            object_resizing: false,
            ...(isMediaPrompt && {
              placeholder: `Click the icon below to add an image${isCarousel ? '' : ' or embed a video'}`,
              media_dimensions: false
            }),
            external_plugins: {
              /**
               * `solvvyimage` is an external/custom plugin that extends the tinymce `image` plugin.
               * The `image` plugin calculates the dimensions of the image automatically whereas
               * `solvvyimage` plugin sets the dimensions of the image to `auto` by default which
               * can then be changed to a fixed value if required. This can also be controlled
               * based on the property `image_auto_dimensions` (defaults to `false`). If set to
               * `true`, then the dimensions of the image will be calculated automatically.
               */
              solvvyimage: '/tinymce/plugins/solvvyimage/plugin.min.js',
              solvvymentions: '/tinymce/plugins/solvvymentions/plugin.min.js'
            },
            images_upload_handler: (blobInfo, success, failure) => {
              uploadImage(blobInfo.blob())
                .then((url: string) => success(url))
                .catch(() => failure('An error occurred. Please try again.', { remove: true }));
            },
            media_url_resolver: (data, resolve) => {
              let url: string = data.url;
              const videoUrlObj = parseUrlForVideo(url);
              url = videoUrlObj.type !== 'unknown' ? videoUrlObj.embedUrl : url;
              const embedHtml = `<iframe src="${url}" width="auto" height="auto" frameborder="0" style="border: 0;"></iframe>`;
              resolve({ html: embedHtml });
            },
            setup: editor => {
              editor.on('ExecCommand', event => {
                const command = event.command;
                if (command === 'mceMedia') {
                  // Workaround to change the media dialog title
                  const titleElement = document.querySelector<HTMLDivElement>('div.tox-dialog__title');
                  if (titleElement && titleElement.textContent === 'Insert/Edit Media') {
                    titleElement.innerText = 'Embed Link';
                  }

                  // Workaround to hide the Embed tab in the media dialog https://stackoverflow.com/a/64437556
                  document.querySelectorAll<HTMLDivElement>('div[role="tablist"] .tox-tab').forEach(tab => {
                    if (tab.textContent === 'Embed') {
                      tab.style.display = 'none';
                    }
                  });
                }
              });

              editor.ui.registry.addIcon(INSERT_VARIABLE_ICON_ID, INSERT_VARIABLE_ICON_SVG);
              editor.ui.registry.addMenuButton(INSERT_VARIABLE_BUTTON_ID, {
                icon: INSERT_VARIABLE_ICON_ID,
                tooltip: INSERT_VARIABLE_TOOLTIP,
                type: 'menubutton',
                fetch: async callback => {
                  const dataSources = dataSourcesStore.dataSources.fulfilled
                    ? dataSourcesStore.dataSources.value
                    : await dataSourcesStore.loadDataSources();

                  type MenuButtonItems = Exclude<Parameters<typeof callback>[0], string>;

                  const variables = dataSources.reduce((allVariables, dataSource) => {
                    if (!dataSource.variablesJsonSchema) {
                      return allVariables;
                    }

                    // Data source name is styled using `ITALIC_STYLE_FOR_DISABLED_MENU_ITEM`
                    allVariables.push({ type: 'menuitem', text: dataSource.name, disabled: true });

                    Object.values(dataSource.variablesJsonSchema?.properties as any).map((variable: any) => {
                      allVariables.push({
                        type: 'menuitem',
                        text: variable.title,
                        onAction: () => {
                          editor.insertContent(
                            `<span class="mention" data-mention-id="${variable.templateExpression}">${variable.title}</span>`
                          );
                        }
                      });
                    });

                    return allVariables;
                  }, [] as MenuButtonItems);

                  callback(variables);
                }
              });
            }
          }}
          disabled={disabled}
          value={DataSourcesStore.replaceTemplateExpressionsToVariableNames(
            value,
            workflowStore._dataSourcesStore.dataSources
          )}
          onEditorChange={handleEditorChange}
          onFocus={() => {
            setIsActive(true);

            // Scroll the currently selected element into view once the toolbar is visible
            setTimeout(() => {
              editorRef.current?.editor?.selection?.getNode()?.scrollIntoView({
                block: 'nearest'
              });
            });
          }}
          onBlur={() => {
            setIsActive(false);
          }}
          onKeyDown={event => {
            // Prevent typing text in the editor if it is a media prompt
            if (isMediaPrompt) {
              if (MEDIA_PROMPT_ALLOWED_KEYS.includes(event.key)) {
                return;
              }
              event.preventDefault();
            }
          }}
        />
      </div>
    </WYSIWYGEditorContainer>
  );
}

function parseUrlForVideo(url: string) {
  // reference from https://gist.github.com/kostasx/c89c4d6e8ba2921cb5dc4fc508bc1a8c

  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
  //   - http://youtu.be/My2FRPA3Gf8
  //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551
  // - DailyMotion
  //   - http://www.dailymotion.com/video/x6ga7eg

  url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com)|dailymotion.com)\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
  );
  let type = 'unknown';
  let embedUrl = '';
  const id = RegExp.$6;

  if (RegExp.$3.indexOf('youtu') > -1) {
    type = 'youtube';
    embedUrl = `//youtube.com/embed/${id}`;
  } else if (RegExp.$3.indexOf('vimeo') > -1) {
    type = 'vimeo';
    embedUrl = `//player.vimeo.com/video/${id}`;
  } else if (RegExp.$3.indexOf('dailymotion') > -1) {
    type = 'dailymotion';
    embedUrl = `//www.dailymotion.com/embed/video/${id}`;
  }

  return {
    type,
    id,
    embedUrl
  };
}

export default observer(ParagraphEdit);
