import { IFlow, ISurveyOptions } from '../../store/ResolveUI/Workflow';

interface IWorkflowTemplate {
  name: string;
  purpose?: string;
  survey?: ISurveyOptions;
  flow: IFlow;
}

const TEMPLATES: IWorkflowTemplate[] = [
  {
    name: 'Sample Account Management',
    flow: {
      screens: [
        {
          id: '4460f02d-db56-4f27-a1e0-bf42a1bfcc3a',
          name: '  ',
          displayId: '1',
          components: [
            {
              type: 'header',
              options: {
                text: 'How can we help you access your account?'
              }
            },
            {
              id: '017e82d9-3cc2-435d-93ea-4e24cbcde644',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '3fb6d430-7d06-40cd-a665-e25853203286'
                  }
                },
                text: 'I lost access to email/phone number'
              }
            },
            {
              id: '06216f3c-fe59-461a-a404-f7cb48a4c22b',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '539eddad-d4e4-4a80-9c8d-e67b092087df'
                  }
                },
                text: 'I forgot sign in credentials'
              }
            }
          ],
          position: {
            x: 1120,
            y: 1040,
            z: 248
          }
        },
        {
          id: '3751b9bb-1c81-404a-8f23-6257fab8971a',
          name: ' ',
          displayId: '3',
          components: [
            {
              type: 'header',
              options: {
                text: "You're in luck! Our support team can update your email & phone number. "
              }
            },
            {
              id: '49712c2f-3afb-4f89-ac15-da617efbb243',
              type: 'button',
              options: {
                action: {
                  type: 'ticket-form',
                  options: {
                    assistedSupport: true
                  }
                },
                text: 'Contact Support'
              }
            }
          ],
          position: {
            x: 1790,
            y: 730,
            z: 257
          }
        },
        {
          id: '3fb6d430-7d06-40cd-a665-e25853203286',
          name: 'Experience 1',
          displayId: '4',
          components: [
            {
              type: 'header',
              options: {
                text: "Let's see how we can help. What type of account do you have?"
              }
            },
            {
              id: 'a3ac82a2-4555-45d7-81d4-19609d31a56e',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '3751b9bb-1c81-404a-8f23-6257fab8971a'
                  }
                },
                text: 'Premium account (subscription)'
              }
            },
            {
              id: '69a62ad7-b2c1-431e-a04d-c19b3ad6507c',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'b2ce111b-0aa4-4d7e-a4d6-bf21057d040d'
                  }
                },
                text: 'Basic account (not subscribed)'
              }
            }
          ],
          position: {
            x: 1450,
            y: 1020,
            z: 247
          }
        },
        {
          id: 'b2ce111b-0aa4-4d7e-a4d6-bf21057d040d',
          name: ' ',
          displayId: '5',
          components: [
            {
              type: 'header',
              options: {
                text: 'Unfortunately, our hands are tied, but there is still hope! '
              }
            },
            {
              id: 'd1233883-7569-48b8-934e-032aaea4f705',
              type: 'paragraph',
              options: {
                text:
                  '<p>We recommend you reach out to the provider for the original credential (phone provider/email provider) to see if they can help you restore access to send and receive emails or phone numbers.&nbsp;</p>'
              }
            },
            {
              id: '3b4f5eed-63d9-4079-b1ed-be9c31daf42f',
              type: 'button',
              options: {
                action: {
                  type: 'exit',
                  options: {}
                },
                text: "Thanks, I'll give that a try."
              }
            }
          ],
          position: {
            x: 2080,
            y: 730,
            z: 241
          }
        },
        {
          id: '539eddad-d4e4-4a80-9c8d-e67b092087df',
          name: 'Experience 2',
          displayId: '6',
          components: [
            {
              type: 'header',
              options: {
                text: 'Luckily, we have a tool that can help you out. '
              }
            },
            {
              id: '298a4dd3-6b7d-4e35-8d73-bff1f184987d',
              type: 'paragraph',
              options: {
                text:
                  "<p>Our login page has a 'forgot username/password' tool. Follow the directions there and we will get you all taken care of in no time!&nbsp;</p>"
              }
            },
            {
              id: '3f55a923-fd64-4160-bf9c-e1b3f5e3ab24',
              type: 'button',
              options: {
                action: {
                  type: 'url',
                  options: {
                    url: 'https://dashboard.solvvy.com/login',
                    exit: true
                  }
                },
                text: 'Take me to the login page'
              }
            }
          ],
          position: {
            x: 1440,
            y: 1400,
            z: 252
          }
        },
        {
          id: '4517a960-b295-404b-98f9-8a239d37c549',
          name: '(Delete before publishing)',
          displayId: '7',
          components: [
            {
              type: 'header',
              options: {
                text: 'Getting Started'
              }
            },
            {
              id: 'd0c8315a-9df5-4994-90ce-0ed9a2679948',
              type: 'paragraph',
              options: {
                text:
                  "<p>You've just opened a generic template. Please review and edit your user flow prior to enabling.</p>\n<p><strong>In this Account Management example you will find:</strong></p>\n<ul>\n<li>User identifying their persona + problem</li>\n<li>Agent assist support options</li>\n<li>Deep links to tools you already built to help</li>\n</ul>\n<p><strong>Looking to personalize your workflow? </strong>Ask your CSM about data sources and starter actions!</p>"
              }
            }
          ],
          position: {
            x: 410,
            y: 840,
            z: 255
          }
        },
        {
          id: '56c04a7d-b662-4e08-9c29-dffbe265df93',
          name: '(Delete before publishing)',
          displayId: '8',
          components: [
            {
              type: 'header',
              options: {
                text: 'Personalizing Workflows'
              }
            },
            {
              id: 'b154c0f7-938d-4633-bf23-7503ed690b50',
              type: 'paragraph',
              options: {
                text:
                  '<p><strong>Why should we personalize?</strong></p>\n<p>You already know so much about your end users. By sharing that data with Solvvy, you can supercharge answers so your users get the answers they need FASTER.</p>\n<p><strong>Personalization features in workflows:</strong></p>\n<ul>\n<li><strong>Data Sources</strong> - Configure Solvvy to read global variables or API responses (exclusions apply)</li>\n<li><strong>Conditional logic</strong> - Create a journey tailored for the user based on previous selections or data sources</li>\n<li><strong>Workflow starter actions</strong> - Configure Solvvy to take an action prior to showing your user their experience.&nbsp;</li>\n</ul>'
              }
            }
          ],
          position: {
            x: 710,
            y: 840,
            z: 254
          }
        }
      ],
      text: {
        confirmationText: 'Confirm'
      },
      startScreenId: '4460f02d-db56-4f27-a1e0-bf42a1bfcc3a'
    }
  },
  {
    name: 'Sample Educational (how to guides)',
    flow: {
      screens: [
        {
          id: '3e2ecf5d-5bb5-4c93-a0eb-90a3a025c4f1',
          name: ' ',
          displayId: '1',
          components: [
            {
              type: 'header',
              options: {
                text: 'I am so excited to tell you all about Solvvy workflows! '
              }
            },
            {
              id: 'e2fdbe4d-3171-4b9a-a9e9-eb1db6a3a107',
              type: 'paragraph',
              options: {
                text: '<p>Learning something new can be overwhelming, how detailed would you like me to be?</p>'
              }
            },
            {
              id: '0e2507ee-417b-4d1f-a69f-4c8ac61de462',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '13538dd8-7f8b-47bd-abda-1f7a0f608541'
                  }
                },
                text: 'Just an overview for now'
              }
            },
            {
              id: 'fbdb5d3e-b03e-4b26-8f40-d5312b125242',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '5c710153-01a4-434b-9f56-16b9da973189'
                  }
                },
                text: 'I have a specific topic in mind'
              }
            }
          ],
          position: {
            x: 580,
            y: 1220,
            z: 37
          }
        },
        {
          id: 'a56d0c8a-0458-41aa-ad91-0da31eeaf7b0',
          name: '(Delete before publishing)',
          displayId: '10',
          components: [
            {
              type: 'header',
              options: {
                text: 'Personalizing Workflows'
              }
            },
            {
              id: '0d967fb7-5d90-486c-8800-d6fae4592d77',
              type: 'paragraph',
              options: {
                text:
                  '<p><strong>Why should we personalize?</strong></p>\n<p>You already know so much about your end users. By sharing that data with Solvvy, you can supercharge answers so your users get the answers they need FASTER.</p>\n<p><strong>Personalization features in workflows:</strong></p>\n<p><strong>Data Sources </strong>- Configure Solvvy to read global variables or API responses (exclusions apply)<br /><strong>Conditional logic</strong> - Create a journey tailored for the user based on previous selections or data sources<br /><strong>Workflow starter actions</strong> - Configure Solvvy to take an action prior to showing your user their experience.&nbsp;</p>'
              }
            }
          ],
          position: {
            x: 30,
            y: 1200,
            z: 222
          }
        },
        {
          id: '6bc1bec1-1649-4bb3-925b-2bd62ff9f984',
          name: '(Delete before publishing)',
          displayId: '11',
          components: [
            {
              type: 'header',
              options: {
                text: 'Getting Started'
              }
            },
            {
              id: 'ffa1635b-6e21-4fe3-a264-98575773f0dd',
              type: 'paragraph',
              options: {
                text:
                  "<p>You've just opened a generic template. Please review and edit your user flow prior to enabling.</p>\n<p><strong>In this Educational (how to guide) example you will find:</strong></p>\n<ul>\n<li>User identifying desired experience</li>\n<li>Engaging images</li>\n<li>Multiple uses for the same content</li>\n<li>Self serve only with no support options</li>\n</ul>\n<p><strong>Looking to personalize your workflow? </strong>Ask your CSM about data sources and starter actions!</p>"
              }
            }
          ],
          position: {
            x: -280,
            y: 1200,
            z: 221
          }
        },
        {
          id: '13538dd8-7f8b-47bd-abda-1f7a0f608541',
          name: 'Experience 1',
          displayId: '2',
          components: [
            {
              type: 'header',
              options: {
                text: "Awesome! Let's keep this short and sweet."
              }
            },
            {
              id: '3d733a46-2425-4bbb-b258-c200c3641b5b',
              type: 'paragraph',
              options: {
                text:
                  '<p>Solvvy Workflows automate your organization&rsquo;s challenging - and expensive - customer tasks, saving time for support teams and enabling customers to self-serve.</p>\n<p><img src="https://solvvy.com/wp-content/uploads/2020/11/solvvy-chat-options-mockup-transparent-final.svg" alt="" width="auto" height="auto" /></p>'
              }
            },
            {
              id: 'a28edd9e-1e25-4894-9bd6-4ba8997b4585',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'd279370d-3237-4571-8ce3-13bbf92b3297'
                  }
                },
                text: 'Next'
              }
            }
          ],
          position: {
            x: 940,
            y: 1020,
            z: 237
          }
        },
        {
          id: 'eb9769c4-344c-4851-967b-af5806fbe7ff',
          name: ' ',
          displayId: '3',
          components: [
            {
              type: 'header',
              options: {
                text: 'Taking workflows to the next level!'
              }
            },
            {
              id: 'e9a701e8-a834-4567-b129-f11f5dce80ce',
              type: 'paragraph',
              options: {
                text:
                  '<p>Expand your self-service capabilities by connecting self-service Workflow automations with API&rsquo;s or backend systems, allowing customers to resolve issues or complete requests on their own without needing to contact support.</p>\n<p><img src="https://solvvy.com/wp-content/uploads/2021/11/damaged-item-conversational.svg" alt="" width="auto" height="auto" /></p>'
              }
            },
            {
              id: 'a67ee4c3-56ea-4d01-baf3-10b7440230c7',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '64bde99c-676a-456c-8348-1b5ce5a4576f'
                  }
                },
                text: 'Next'
              }
            }
          ],
          position: {
            x: 1590,
            y: 1020,
            z: 253
          }
        },
        {
          id: 'd279370d-3237-4571-8ce3-13bbf92b3297',
          name: ' ',
          displayId: '4',
          components: [
            {
              type: 'header',
              options: {
                text: 'For situations where human support is necessary.'
              }
            },
            {
              id: '5c2c4ef0-9523-4f3a-9c4b-f3f2677fddc9',
              type: 'paragraph',
              options: {
                text:
                  '<p>Workflows reduce handle times by automating information collection and streamlining conversations between customers and your team.</p>\n<p>&nbsp;<img src="https://solvvy.com/wp-content/uploads/2021/11/damaged-item-conversational-1.svg" alt="" width="auto" height="auto" /></p>'
              }
            },
            {
              id: '4be09627-ff37-4815-a8f6-c5c4317a8deb',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'eb9769c4-344c-4851-967b-af5806fbe7ff'
                  }
                },
                text: 'Next'
              }
            }
          ],
          position: {
            x: 1270,
            y: 1020,
            z: 240
          }
        },
        {
          id: '5c710153-01a4-434b-9f56-16b9da973189',
          name: 'Experience 2',
          displayId: '5',
          components: [
            {
              type: 'header',
              options: {
                text: 'Great! What would you like me to dive into? '
              }
            },
            {
              id: '4a7b06c3-134e-4e70-89fd-c0cb17df4c8e',
              type: 'paragraph',
              options: {
                text: ''
              }
            },
            {
              id: 'ad6c52b7-a114-4fe6-addd-020d4d977ae1',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'e613444a-e772-48ed-a1ae-161542f638e6'
                  }
                },
                text: 'Agent Assist'
              }
            },
            {
              id: 'ecac1391-afe4-46b8-b5e2-2310297385f0',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '2fb2e58a-40c8-4f8f-a2ad-3c2b967dee87'
                  }
                },
                text: 'Personalization via integrations'
              }
            },
            {
              id: '9a420f00-33bb-42c3-8ca3-cc87b789e48e',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '2f6d040d-8e7a-48e0-8ac1-06bf17b84c80'
                  }
                },
                text: 'Inspiration'
              }
            }
          ],
          position: {
            x: 890,
            y: 1660,
            z: 171
          }
        },
        {
          id: '64bde99c-676a-456c-8348-1b5ce5a4576f',
          name: ' ',
          displayId: '6',
          components: [
            {
              type: 'header',
              options: {
                text: "Here are some of the cool ways our client's are using Workflows:"
              }
            },
            {
              id: '67312e90-c284-4bbf-9d9b-b9920c2b10f7',
              type: 'paragraph',
              options: {
                text:
                  '<ol>\n<li>Order tracking</li>\n<li>Troubleshooting</li>\n<li>Education</li>\n<li>Churn prevention</li>\n<li>Upsells &amp; refunds</li>\n</ol>\n<p>Are you feeling inspired to make your own workflow?</p>'
              }
            },
            {
              id: '0897f557-665e-4ca3-b93e-e7c92c266a80',
              type: 'button',
              options: {
                action: {
                  type: 'exit',
                  options: {}
                },
                text: 'Yes, I feel ready to build my own'
              }
            },
            {
              id: '52c7d55d-0647-467c-8200-db9bfa27dc59',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '5c710153-01a4-434b-9f56-16b9da973189'
                  }
                },
                text: 'I would love to learn even more about workflows'
              }
            }
          ],
          position: {
            x: 1940,
            y: 1020,
            z: 214
          }
        },
        {
          id: 'e613444a-e772-48ed-a1ae-161542f638e6',
          name: ' ',
          displayId: '7',
          components: [
            {
              type: 'header',
              options: {
                text: 'For situations where human support is necessary.'
              }
            },
            {
              id: '41ed5409-3fc6-4b0d-8527-f636dac295ac',
              type: 'paragraph',
              options: {
                text:
                  '<p>Workflows reduce handle times by automating information collection and streamlining conversations between customers and your team.</p>\n<p>&nbsp;<img src="https://solvvy.com/wp-content/uploads/2021/11/damaged-item-conversational-1.svg" alt="" width="auto" height="auto" /></p>'
              }
            },
            {
              id: '1a071391-ac8f-4c38-b135-3d371a61cd83',
              type: 'button',
              options: {
                action: {
                  type: 'exit',
                  options: {}
                },
                text: 'Thanks! That is all I needed'
              }
            }
          ],
          position: {
            x: 1750,
            y: 1720,
            z: 249
          }
        },
        {
          id: '2fb2e58a-40c8-4f8f-a2ad-3c2b967dee87',
          name: ' ',
          displayId: '8',
          components: [
            {
              type: 'header',
              options: {
                text: 'Taking workflows to the next level!'
              }
            },
            {
              id: 'c2328c7b-665f-42d2-827c-46ac1099abbc',
              type: 'paragraph',
              options: {
                text:
                  '<p>Expand your self-service capabilities by connecting self-service Workflow automations with API&rsquo;s or backend systems, allowing customers to resolve issues or complete requests on their own without needing to contact support.</p>\n<p><img src="https://solvvy.com/wp-content/uploads/2021/11/damaged-item-conversational.svg" alt="" width="auto" height="auto" /></p>'
              }
            },
            {
              id: 'd48433d7-6eb8-43c5-bab7-0f71a13cde25',
              type: 'button',
              options: {
                action: {
                  type: 'exit',
                  options: {}
                },
                text: 'Thanks! That is all I needed'
              }
            }
          ],
          position: {
            x: 1390,
            y: 1880,
            z: 251
          }
        },
        {
          id: '2f6d040d-8e7a-48e0-8ac1-06bf17b84c80',
          name: ' ',
          displayId: '9',
          components: [
            {
              type: 'header',
              options: {
                text: "Here are some of the cool ways our client's are using Workflows:"
              }
            },
            {
              id: '10784f57-e53e-4fd5-84f8-a4ee5397a4d8',
              type: 'paragraph',
              options: {
                text:
                  '<ol>\n<li>Order tracking</li>\n<li>Troubleshooting</li>\n<li>Education</li>\n<li>Churn prevention</li>\n<li>Upsells &amp; refunds</li>\n</ol>\n<p>Are you feeling inspired to make your own workflow?</p>'
              }
            },
            {
              id: 'fde86500-d1e2-486b-be31-e61d658140e9',
              type: 'button',
              options: {
                action: {
                  type: 'exit',
                  options: {}
                },
                text: 'Yes, I feel ready to build my own'
              }
            }
          ],
          position: {
            x: 1080,
            y: 2060,
            z: 256
          }
        }
      ],
      startScreenId: '3e2ecf5d-5bb5-4c93-a0eb-90a3a025c4f1'
    }
  },
  {
    name: 'Sample Subscription Management',
    flow: {
      screens: [
        {
          id: 'f8300d3f-bafc-45a7-bd48-069c557cef5c',
          name: 'Step 1',
          displayId: '1',
          components: [
            {
              type: 'header',
              options: {
                text: 'Subscription Management - User Info '
              }
            },
            {
              id: 'a479cdbc-2340-4cc6-a306-8bf4da278202',
              type: 'paragraph',
              options: {
                text:
                  '<p>We will be happy to help you with your subscription. </p>\n<p></p>\n<p>Can you tell us a little bit about yourself?</p>\n'
              }
            },
            {
              id: '99f5de32-64cc-40c5-99f5-9e9735ce9743',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '656841aa-2d95-47b3-b97a-0d2714b7ca14'
                  }
                },
                text: 'Student'
              }
            },
            {
              id: 'e934c4af-ad58-4f56-923d-b6f4bc5a0ba9',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '656841aa-2d95-47b3-b97a-0d2714b7ca14'
                  }
                },
                text: 'Teacher'
              }
            },
            {
              id: '4922c2f7-9964-4aa6-846b-0fea89121ea7',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '656841aa-2d95-47b3-b97a-0d2714b7ca14'
                  }
                },
                text: 'Group (multiple accounts)'
              }
            }
          ],
          position: {
            x: 980,
            y: 1040,
            z: 360
          }
        },
        {
          id: '656841aa-2d95-47b3-b97a-0d2714b7ca14',
          name: 'Step 2',
          displayId: '2',
          components: [
            {
              type: 'header',
              options: {
                text: 'Subscription Management'
              }
            },
            {
              id: '123979dd-1144-4d19-8540-6357e7603162',
              type: 'paragraph',
              options: {
                text: '<p>What would you like to do?</p>\n'
              }
            },
            {
              id: 'b19f71d0-2b8d-4751-9ec0-9c601d15fb27',
              type: 'button',
              options: {
                action: {
                  type: 'url',
                  options: {
                    url: 'https://solvvy.com/plusexample',
                    exit: false
                  }
                },
                text: 'Upgrade to Plus Account'
              }
            },
            {
              id: 'eece3287-9182-4543-ab8d-415adef998aa',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'b923c419-01aa-4407-9114-97fedc5f8f88'
                  }
                },
                text: "I don't want my subscription anymore"
              }
            }
          ],
          position: {
            x: 1300,
            y: 760,
            z: 166
          }
        },
        {
          id: 'b923c419-01aa-4407-9114-97fedc5f8f88',
          name: 'Step 3',
          displayId: '3',
          components: [
            {
              type: 'header',
              options: {
                text: 'App Access'
              }
            },
            {
              id: 'fb2aeab4-b633-440f-b584-777d7e8b86d1',
              type: 'paragraph',
              options: {
                text: '<p>How are you accessing our platform?</p>\n'
              }
            },
            {
              id: 'c379fed4-1700-46e6-a4c9-84592749eca4',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '84fc2ef8-028d-4e5c-bc70-3b9b4963f647'
                  }
                },
                text: 'Web'
              }
            },
            {
              id: '50e0192e-0a29-4cc7-ad4e-c3f80be97ec3',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '4bdd70b4-ee91-4c44-b491-394ff84099a6'
                  }
                },
                text: 'Android App'
              }
            },
            {
              id: '6fbec85e-039c-4eb8-8bd7-cadd1cfb565b',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '59c85848-9d46-4d2a-b186-4c7a70eaaab5'
                  }
                },
                text: 'iOS App'
              }
            }
          ],
          position: {
            x: 1660,
            y: 1060,
            z: 309
          }
        },
        {
          id: '84fc2ef8-028d-4e5c-bc70-3b9b4963f647',
          name: 'Step 4a',
          displayId: '4',
          components: [
            {
              type: 'header',
              options: {
                text: 'Subscription Management via Web'
              }
            },
            {
              id: '83b9a69d-c562-4d4b-98ea-ab86c9e2d5ea',
              type: 'paragraph',
              options: {
                text:
                  '<p>Would you consider staying if we offered you 3 free months of Plus after your subscription is due to expire?</p>'
              }
            },
            {
              id: '9477f49a-93a2-4da6-939c-c7355bfd7b72',
              type: 'button',
              options: {
                action: {
                  type: 'ticket-form',
                  options: {}
                },
                text: 'Yes, I would love 3 free months'
              }
            },
            {
              id: '0338bb8f-a049-4296-912a-abe19e99e1cb',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'baeafbc3-7518-4854-8744-c1e225464756'
                  }
                },
                text: 'No, Please cancel my subscription'
              }
            }
          ],
          position: {
            x: 2020,
            y: 520,
            z: 311
          }
        },
        {
          id: '59c85848-9d46-4d2a-b186-4c7a70eaaab5',
          name: 'Step 4c',
          displayId: '5',
          components: [
            {
              type: 'header',
              options: {
                text: 'Subscription Management via iOS App'
              }
            },
            {
              id: '6cb4bf0d-8dcc-4de6-b167-f26b5b1aaaf3',
              type: 'paragraph',
              options: {
                text:
                  "<p>We want to make sure you're getting all you need from our product.</p>\n<p><strong>If you paid Apple through the App Store, you'll need to request a refund from Apple. </strong>Since they accepted your payment, they will handle your refund request.</p>"
              }
            },
            {
              id: 'd8a5209c-774a-4ae2-88f7-e8261b2c76fe',
              type: 'button',
              options: {
                action: {
                  type: 'url',
                  options: {
                    url: 'https://support.apple.com/en-us/HT202039'
                  }
                },
                text: 'Show me Apple help article'
              }
            }
          ],
          position: {
            x: 1860,
            y: 1420,
            z: 355
          }
        },
        {
          id: '4bdd70b4-ee91-4c44-b491-394ff84099a6',
          name: 'Step 4b',
          displayId: '6',
          components: [
            {
              type: 'header',
              options: {
                text: 'Subscription Management via Android'
              }
            },
            {
              id: 'cfa71ed4-743d-4b84-ab02-02f1d5819ad7',
              type: 'paragraph',
              options: {
                text:
                  '<p>Would you consider staying if we offered you 3 free months of Plus after your subscription is due to expire?</p>'
              }
            },
            {
              id: 'f24d54b1-6944-4d89-99ed-b5d57318606c',
              type: 'button',
              options: {
                action: {
                  type: 'ticket-form',
                  options: {
                    assistedSupport: true
                  }
                },
                text: 'Yes, I would love 3 free months'
              }
            },
            {
              id: '3d88378c-890b-447e-b37e-6dc3014c5b38',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'baeafbc3-7518-4854-8744-c1e225464756'
                  }
                },
                text: 'No, Please cancel my subscription'
              }
            }
          ],
          position: {
            x: 2340,
            y: 980,
            z: 352
          }
        },
        {
          id: '7b2a5bf9-1978-4473-bfc3-1d1763fbc4f4',
          name: '(Delete before publishing)',
          displayId: '7',
          components: [
            {
              type: 'header',
              options: {
                text: 'Getting Started'
              }
            },
            {
              id: 'd8fa4423-cc36-4ace-891c-62306580413f',
              type: 'paragraph',
              options: {
                text:
                  "<p>You've just opened a generic template. Please review and edit your user flow prior to enabling.</p>\n<p><strong>In this Subscription Management example you will find:</strong></p>\n<ul>\n<li>User identifying their persona + environment</li>\n<li>Agent assist support options</li>\n<li>upsell opportunity</li>\n<li>retention call to action</li>\n</ul>\n<p><strong>Looking to personalize your workflow? </strong>Ask your CSM about data sources and starter actions!</p>"
              }
            }
          ],
          position: {
            x: 290,
            y: 910,
            z: 367
          }
        },
        {
          id: 'baeafbc3-7518-4854-8744-c1e225464756',
          name: 'Step 5',
          displayId: '8',
          components: [
            {
              type: 'header',
              options: {
                text: "Let's get you over to support"
              }
            },
            {
              id: '32be6321-ce1c-40bb-b642-5445db66bf1f',
              type: 'paragraph',
              options: {
                text:
                  "<p>In general, all purchases and subscriptions are nonrefundable, but we'll review your request and may be able to issue a refund depending on the details of your purchase.</p>"
              }
            },
            {
              id: 'da5aed10-a5c5-4ead-b61c-2479711526f5',
              type: 'button',
              options: {
                action: {
                  type: 'ticket-form',
                  options: {
                    assistedSupport: true
                  }
                },
                text: 'Please cancel my subscription'
              }
            }
          ],
          position: {
            x: 2740,
            y: 900,
            z: 357
          }
        },
        {
          id: 'faab868c-c863-4ef4-970a-94623d79ec17',
          name: '(Delete before publishing)',
          displayId: '9',
          components: [
            {
              type: 'header',
              options: {
                text: 'Personalizing Workflows'
              }
            },
            {
              id: '387e7cd5-4736-4f01-82f3-bf52c47382b4',
              type: 'paragraph',
              options: {
                text:
                  '<p><strong>Why should we personalize?</strong></p>\n<p>You already know so much about your end users. By sharing that data with Solvvy, you can supercharge answers so your users get the answers they need FASTER.</p>\n<p><strong>Personalization features in workflows:</strong></p>\n<ul>\n<li><strong>Data Sources</strong> - Configure Solvvy to read global variables or API responses (exclusions apply)</li>\n<li><strong>Conditional logic</strong> - Create a journey tailored for the user based on previous selections or data sources</li>\n<li><strong>Workflow starter actions</strong> - Configure Solvvy to take an action prior to showing your user their experience.&nbsp;</li>\n</ul>'
              }
            }
          ],
          position: {
            x: 590,
            y: 910,
            z: 366
          }
        }
      ],
      startScreenId: 'f8300d3f-bafc-45a7-bd48-069c557cef5c'
    }
  },
  {
    name: 'Sample Troubleshooting Workflow',
    flow: {
      screens: [
        {
          id: '7c4ff3a1-0325-47f3-ba8c-08027675ad05',
          name: ' ',
          displayId: '1',
          components: [
            {
              type: 'header',
              options: {
                text: 'Troubleshooting'
              }
            },
            {
              id: 'f48af451-cb04-495e-8dfe-190f7ad128db',
              type: 'paragraph',
              options: {
                text: '<p>What type of issue are you having with the Product Console?</p>'
              }
            },
            {
              id: '79a18acd-f7c8-49d5-a86b-73c2d0c1b86a',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '4194646c-9aac-44c0-9f68-073963582e4b'
                  }
                },
                text: 'Software Issue'
              }
            },
            {
              id: 'c31753d9-27a6-46b3-b27c-bf4bb7ce9de1',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '091738c7-d146-403e-8f19-a93be9cb5a82'
                  }
                },
                text: 'Power Issue'
              }
            },
            {
              id: '3731b52a-e29c-4bf6-a93b-239253e5926f',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'b21c4be7-1c2d-4d44-9325-67ad906bc930'
                  }
                },
                text: 'Something Else'
              }
            }
          ],
          position: {
            x: 1000,
            y: 1090,
            z: 72
          }
        },
        {
          id: '4194646c-9aac-44c0-9f68-073963582e4b',
          name: 'Experience 1',
          displayId: '2',
          components: [
            {
              type: 'header',
              options: {
                text: 'Software Issue'
              }
            },
            {
              id: '8e5b9774-46ac-43df-8486-995c331e7cec',
              type: 'paragraph',
              options: {
                text:
                  '<p>If your Product Console is not loading, try one of the below steps:</p>\n<ol>\n<li>Make sure your device is connected to power.  </li>\n<li>Make sure your device has the latest software. <br /></li>\n</ol>\n'
              }
            },
            {
              id: 'fba3be1f-e3ea-4452-9c73-acb51dc21cdc',
              type: 'button',
              options: {
                action: {
                  type: 'exit',
                  options: {
                    message: 'Great. Let us know if there is anything else you need. '
                  }
                },
                text: 'It worked'
              }
            },
            {
              id: '19f25bb3-a520-4d6c-8001-52dcae2a04ed',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '7251236b-6ddb-4209-8131-68f9549f4cc5'
                  }
                },
                text: "No, let's try something else"
              }
            }
          ],
          position: {
            x: 1190,
            y: 660,
            z: 73
          }
        },
        {
          id: '7251236b-6ddb-4209-8131-68f9549f4cc5',
          name: ' ',
          displayId: '3',
          components: [
            {
              type: 'header',
              options: {
                text: 'Update Date/Time'
              }
            },
            {
              id: '5a766156-3b7e-4a68-b8d0-849659e4d507',
              type: 'paragraph',
              options: {
                text:
                  '<p>Make sure your device&rsquo;s date and time are accurate. If they are wrong, please update them via the Settings menu.</p>'
              }
            },
            {
              id: '4647ea01-8f9f-47c0-a60d-d8b05854906f',
              type: 'button',
              options: {
                action: {
                  type: 'exit',
                  options: {
                    message: 'Awesome. Let us know if there is anything else you need. '
                  }
                },
                text: 'Great! That worked'
              }
            },
            {
              id: 'ee643585-367d-4339-9449-bcfbf796dce5',
              type: 'button',
              options: {
                action: {
                  type: 'ticket-form',
                  options: {
                    assistedSupport: true
                  }
                },
                text: 'No luck, I need support'
              }
            }
          ],
          position: {
            x: 1560,
            y: 680,
            z: 76
          }
        },
        {
          id: '091738c7-d146-403e-8f19-a93be9cb5a82',
          name: 'Experience 2',
          displayId: '4',
          components: [
            {
              type: 'header',
              options: {
                text: 'Power Issue'
              }
            },
            {
              id: '0679eece-1e32-42b7-aafe-4c98e7bfe3f5',
              type: 'paragraph',
              options: {
                text:
                  '<p>Your device should automatically receive power when it is plugged into a grounded AC outlet.</p>\n<ol>\n<li>Make sure the console is firmly plugged into the USB hardware hub and the power adaptor is plugged firmly into a power outlet.</li>\n<li>Plug the power adaptor into a different outlet.</li>\n</ol>\n<p>Did that work?</p>\n'
              }
            },
            {
              id: '2a5de153-a820-4d53-ad4d-7c04178fae72',
              type: 'button',
              options: {
                action: {
                  type: 'exit',
                  options: {
                    message: 'Great. Let us know if there is anything else you need. '
                  }
                },
                text: 'Yes'
              }
            },
            {
              id: '0558e3e1-abf4-4d78-a102-5ded938a8fb9',
              type: 'button',
              options: {
                action: {
                  type: 'ticket-form',
                  options: {
                    assistedSupport: true
                  }
                },
                text: 'No'
              }
            }
          ],
          position: {
            x: 1490,
            y: 1100,
            z: 78
          }
        },
        {
          id: 'b21c4be7-1c2d-4d44-9325-67ad906bc930',
          name: 'Experience 3',
          displayId: '5',
          components: [
            {
              type: 'header',
              options: {
                text: "What's on your mind?"
              }
            },
            {
              id: 'eb03febe-576d-4de0-8369-e5bc43d1aff8',
              type: 'button',
              options: {
                action: {
                  type: 'url',
                  options: {
                    url: 'https://solvvy.com/help/article/6180'
                  }
                },
                text: "I'de like to search the Help Center"
              }
            },
            {
              id: 'ef774797-ea91-48db-a2c2-4e11b948f5df',
              type: 'button',
              options: {
                action: {
                  type: 'exit',
                  options: {}
                },
                text: 'I regained access to my account'
              }
            }
          ],
          position: {
            x: 1110,
            y: 1490,
            z: 80
          }
        },
        {
          id: '3375aa9c-9bd4-47b7-949f-34de0a79fdae',
          name: '(Delete before publishing)',
          displayId: '6',
          components: [
            {
              type: 'header',
              options: {
                text: 'Personalizing Workflows'
              }
            },
            {
              id: 'd7d830a0-0e58-471b-b173-957951b4b6cf',
              type: 'paragraph',
              options: {
                text:
                  '<p><strong>Why should we personalize?</strong></p>\n<p>You already know so much about your end users. By sharing that data with Solvvy, you can supercharge answers so your users get the answers they need FASTER.</p>\n<p><strong>Personalization features in workflows:</strong></p>\n<ul>\n<li><strong>Data Sources</strong> - Configure Solvvy to read global variables or API responses (exclusions apply)</li>\n<li><strong>Conditional logic</strong> - Create a journey tailored for the user based on previous selections or data sources</li>\n<li><strong>Workflow starter actions</strong> - Configure Solvvy to take an action prior to showing your user their experience.&nbsp;&nbsp;</li>\n</ul>'
              }
            }
          ],
          position: {
            x: 590,
            y: 950,
            z: 69
          }
        },
        {
          id: '028a4685-d64e-4c10-84c3-948d068d4a9a',
          name: '(Delete before publishing)',
          displayId: '7',
          components: [
            {
              type: 'header',
              options: {
                text: 'Getting Started'
              }
            },
            {
              id: '8b9512b4-6bdf-471f-a6c7-aeeda19afd24',
              type: 'paragraph',
              options: {
                text:
                  "<p>You've just opened a generic template. Please review and edit your user flow prior to enabling.</p>\n<p>In this <strong>Troubleshooting </strong>example you will find:</p>\n<ul>\n<li>User identifying their problem</li>\n<li>Deep link to Knowledge base&nbsp;</li>\n<li>Workflow to workflow routing</li>\n<li>Agent assisted support option</li>\n</ul>\n<p><br /><strong>Looking to personalize your workflow?</strong> Ask your CSM about data sources and starter actions!</p>"
              }
            }
          ],
          position: {
            x: 290,
            y: 950,
            z: 71
          }
        }
      ],
      startScreenId: '7c4ff3a1-0325-47f3-ba8c-08027675ad05'
    }
  },
  {
    name: 'Sample Product Quiz (with Personalization)',
    flow: {
      screens: [
        {
          id: '46e00d8c-f122-4095-9e10-01587353b5f1',
          name: '   ',
          displayId: '1',
          components: [
            {
              type: 'header',
              options: {
                text: 'Need help choosing the right Product? Let us help!'
              }
            },
            {
              id: 'f48af451-cb04-495e-8dfe-190f7ad128db',
              type: 'paragraph',
              options: {
                text: '<p>Do you already have a few of our Products?</p>\n'
              }
            },
            {
              id: '79a18acd-f7c8-49d5-a86b-73c2d0c1b86a',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'f936fac0-bedf-48af-bf1b-23f51d813e23'
                  }
                },
                text: 'Yes'
              }
            },
            {
              id: 'c31753d9-27a6-46b3-b27c-bf4bb7ce9de1',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'd9b60aab-3049-42a5-a8cd-70942a475352'
                  }
                },
                text: 'No'
              }
            }
          ],
          position: {
            x: 210,
            y: 50,
            z: 721
          }
        },
        {
          id: '84aabb3a-a94f-471f-9cb0-eed32657b626',
          name: 'Experience 3',
          displayId: '10',
          components: [
            {
              type: 'header',
              options: {
                text: 'You have Product Plus!'
              }
            },
            {
              id: '70298b24-6b9e-4970-a8ff-33df0bf79952',
              type: 'paragraph',
              options: {
                text:
                  '<p><strong>Good news! You are already protected with Product Plus. </strong></p>\n<p></p>\n<p>This means you get all of the benefits below:</p>\n<ul>\n<li>Extended benefit 1</li>\n<li>Extended benefit 2</li>\n<li>Extended benefit 3</li>\n</ul>\n'
              }
            },
            {
              id: '4aa08d58-4a9a-4077-9599-fb6f01e9e0d3',
              type: 'button',
              options: {
                action: {
                  type: 'exit',
                  options: {}
                },
                text: "That's a relief!"
              }
            }
          ],
          position: {
            x: 200,
            y: 520,
            z: 746
          }
        },
        {
          id: 'bba6055b-77e7-452f-a37e-7588c57a68dc',
          name: 'Experience 2',
          displayId: '11',
          components: [
            {
              type: 'header',
              options: {
                text: 'You have Product 1'
              }
            },
            {
              id: '9667f176-8c64-40b4-ba16-e06ce6f6d5d4',
              type: 'paragraph',
              options: {
                text:
                  '<p>Good news! You have Product 1.&nbsp;</p>\n<p></p>\n<p>This means you get all of the benefits below:</p>\n<ul>\n<li>Benefit 1</li>\n<li>Benefit 2</li>\n<li>Benefit 3</li>\n</ul>\n<p>Get <strong>Product Plus </strong>for an additional $7 a month for:&nbsp;</p>\n<ul>\n<li>Extended benefit 1</li>\n<li>Extended benefit 2</li>\n</ul>\n<p></p>\n<p></p>\n'
              }
            },
            {
              id: 'a72bc007-7b89-4e61-bec7-175e0efa9ce2',
              type: 'button',
              options: {
                action: {
                  type: 'url',
                  options: {
                    url: 'https://ring.com/account/app/plans',
                    exit: true
                  }
                },
                text: 'Get Product 2 Now'
              }
            }
          ],
          position: {
            x: -170,
            y: 510,
            z: 744
          }
        },
        {
          id: '4a43099d-572a-4af8-9740-2d1e77a5b51a',
          name: 'Experience 1',
          displayId: '12',
          components: [
            {
              type: 'header',
              options: {
                text: "Let's get you more protection!"
              }
            },
            {
              id: '75f5cec0-c190-41df-a1d2-a8bf5561cec7',
              type: 'paragraph',
              options: {
                text:
                  '<p>It looks like you have our free plan.&nbsp;</p>\n<p></p>\n<p>We have 2 plans you can choose from:</p>\n<p></p>\n<p><strong>Product Basic:</strong></p>\n<ul>\n<li>Benefit 1</li>\n<li>Benefit 2</li>\n<li>Benefit 3</li>\n</ul>\n<p><strong>Product Plus: </strong></p>\n<ul>\n<li>All benefits included with Product Basic</li>\n<li>Extended benefit 1</li>\n<li>Extended benefit 2</li>\n</ul>\n<p></p>\n<p></p>\n<p></p>\n<p></p>\n'
              }
            },
            {
              id: '3fa0ca4b-b619-405f-b945-0e4fbe35723e',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '46e00d8c-f122-4095-9e10-01587353b5f1'
                  }
                },
                text: 'Help me choose which product is best for me'
              }
            }
          ],
          position: {
            x: -270,
            y: -150,
            z: 713
          }
        },
        {
          id: 'a01ba9af-af1b-4a31-b1d0-e78a57f62209',
          name: '(Delete before publishing)',
          displayId: '13',
          components: [
            {
              type: 'header',
              options: {
                text: 'Workflow Personalization'
              }
            },
            {
              id: '53d027d6-0f62-4068-9a86-38b56e1ec791',
              type: 'paragraph',
              options: {
                text:
                  '<p><strong>Why should we personalize?</strong></p>\n<p>You already know so much about your end users. By sharing that data with Solvvy, you can supercharge answers so your users get the answers they need FASTER.</p>\n<p><strong>Personalization features in workflows:</strong></p>\n<ul>\n<li><strong>Data Sources </strong>- Configure Solvvy to read global variables or API responses (exclusions apply)</li>\n<li><strong>Conditional logic</strong> - Create a journey tailored for the user based on previous selections or data sources</li>\n<li><strong>Workflow starter actions</strong> - Configure Solvvy to take an action prior to showing your user their experience.&nbsp;</li>\n</ul>'
              }
            }
          ],
          position: {
            x: -1010,
            y: -20,
            z: 752
          }
        },
        {
          id: '85f25329-d479-41ec-9ce3-6e7af4d80536',
          name: '(Delete before publishing)',
          displayId: '14',
          components: [
            {
              type: 'header',
              options: {
                text: 'Get started'
              }
            },
            {
              id: '4cde58fb-daa5-42c3-ad28-4fc1a89fb136',
              type: 'paragraph',
              options: {
                text:
                  "<p>You've just opened a generic template. Please review and edit your user flow prior to enabling.</p>\n<p><strong>In this Product Quiz example you will find:</strong></p>\n<ul>\n<li>Upsell opportunity</li>\n<li>Data sources (plan type)</li>\n<li>Conditional logic (pink connecting line)</li>\n<li>Deep link to product page</li>\n</ul>\n<p><strong>Looking to personalize your workflow?</strong> Ask your CSM about data sources and starter actions!</p>"
              }
            }
          ],
          position: {
            x: -1320,
            y: -20,
            z: 753
          }
        },
        {
          id: 'd9b60aab-3049-42a5-a8cd-70942a475352',
          name: '   ',
          displayId: '3',
          components: [
            {
              type: 'header',
              options: {
                text: 'Is customization important to you?'
              }
            },
            {
              id: '8e5b9774-46ac-43df-8486-995c331e7cec',
              type: 'paragraph',
              options: {
                text: '<p></p>\n'
              }
            },
            {
              id: '4647ea01-8f9f-47c0-a60d-d8b05854906f',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'f936fac0-bedf-48af-bf1b-23f51d813e23'
                  }
                },
                text: 'Yes'
              }
            },
            {
              id: 'ee643585-367d-4339-9449-bcfbf796dce5',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '1deb16ec-951c-473b-aa55-14bd2e3875f7'
                  }
                },
                text: 'No'
              }
            }
          ],
          position: {
            x: 660,
            y: 120,
            z: 725
          }
        },
        {
          id: '1deb16ec-951c-473b-aa55-14bd2e3875f7',
          name: '   ',
          displayId: '4',
          components: [
            {
              type: 'header',
              options: {
                text: 'Are you interested in accessing your product via mobile?'
              }
            },
            {
              id: '5fba21ec-ee05-4add-8a11-9580ddf0eb50',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'f936fac0-bedf-48af-bf1b-23f51d813e23'
                  }
                },
                text: 'Yes'
              }
            },
            {
              id: 'a71558b1-1e71-44f3-8774-5c0daab7083b',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '6901bee6-eeac-4a6f-be83-502c4445e8e1'
                  }
                },
                text: 'No'
              }
            }
          ],
          position: {
            x: 1070,
            y: 130,
            z: 729
          }
        },
        {
          id: 'f936fac0-bedf-48af-bf1b-23f51d813e23',
          name: '   ',
          displayId: '7',
          components: [
            {
              type: 'header',
              options: {
                text: 'We recommend:'
              }
            },
            {
              id: '53525d35-55f6-49c4-96dc-9199549a87f6',
              type: 'paragraph',
              options: {
                text:
                  '<p><strong>Product Plus</strong></p>\n<p>This means you get all the benefits below</p>\n<ul>\n<li>Benefit 1</li>\n<li>Benefit 2</li>\n<li>Benefit 3</li>\n<li>Extended benefit 1</li>\n<li>Extended benefit 2</li>\n<li>Extended benefit 3</li>\n</ul>'
              }
            },
            {
              id: '7f205f90-722e-4e97-8985-27bb55366cd0',
              type: 'paragraph',
              options: {
                text: '<p><strong>Click below to sign into your account and subscribe.</strong></p>\n'
              }
            },
            {
              id: '3bc13745-e831-41fa-a647-60e0f9ca2d64',
              type: 'button',
              options: {
                action: {
                  type: 'url',
                  options: {
                    url: 'https://solvvy.com/productexample',
                    exit: true,
                    exitMessage: '',
                    openInSameWindow: true
                  }
                },
                text: 'Buy Now'
              }
            }
          ],
          position: {
            x: 730,
            y: -350,
            z: 754
          }
        },
        {
          id: '6901bee6-eeac-4a6f-be83-502c4445e8e1',
          name: '   ',
          displayId: '8',
          components: [
            {
              type: 'header',
              options: {
                text: 'We recommend:'
              }
            },
            {
              id: 'f42b1682-aef4-4b76-aba5-ce053cc70f04',
              type: 'paragraph',
              options: {
                text:
                  '<p><strong>Product Basic</strong><br>This means you get all of the benefits below:</p>\n<ul>\n<li>Benefit 1</li>\n<li>Benefit 2</li>\n<li>Benefit 3</li>\n</ul>\n<p>Get <strong>Product Plus </strong>for an additional $7 a month for:&nbsp;</p>\n<ul>\n<li>Extended benefit 1</li>\n<li>Extended benefit 2</li>\n</ul>\n'
              }
            },
            {
              id: '09f9a838-3660-44cd-b646-09f0aa209eb9',
              type: 'paragraph',
              options: {
                text: '<p><strong>Click below to sign into your account and subscribe.</strong></p>\n'
              }
            },
            {
              id: 'd862ebf4-c4f9-4909-80bd-832cd198ac22',
              type: 'button',
              options: {
                action: {
                  type: 'url',
                  options: {
                    url: 'https://solvvy.com/product example',
                    exit: true
                  }
                },
                text: 'Buy Now'
              }
            }
          ],
          position: {
            x: 1490,
            y: -60,
            z: 750
          }
        },
        {
          id: 'b03ee929-4edf-47e7-b5c9-528cc0d63755',
          name: '   ',
          displayId: '9',
          components: [
            {
              type: 'header',
              options: {
                text: 'Product Upsell'
              }
            },
            {
              id: 'ca4d2254-e6cb-4913-894f-f06c83ec5156',
              type: 'paragraph',
              options: {
                text:
                  '<p>We offer a product that provides extra features for your devices. There are 2 products to choose from</p>\n<ul>\n<li>Product Basic</li>\n<li>Product Plus</li>\n</ul>\n<p>Need help with deciding which plan is right for you?&nbsp;</p>'
              }
            },
            {
              id: '3ee4d64d-7bf9-4890-a485-9775add13582',
              type: 'button',
              options: {
                action: {
                  type: 'condition',
                  options: {
                    conditions: [
                      {
                        action: {
                          type: 'screen',
                          options: {
                            screenId: '84aabb3a-a94f-471f-9cb0-eed32657b626'
                          }
                        },
                        condition: {
                          eq: [
                            {
                              expression: '{{api.getAccount.body.ring_plan}}',
                              dataSourceId: 'aa7a0a4f-f58d-46a5-aa8d-225eaffb913b',
                              dataSourcePropertyId: 'ring_plan'
                            },
                            {
                              expression: 'Plus'
                            }
                          ]
                        }
                      },
                      {
                        action: {
                          type: 'screen',
                          options: {
                            screenId: 'bba6055b-77e7-452f-a37e-7588c57a68dc'
                          }
                        },
                        condition: {
                          eq: [
                            {
                              expression: '{{api.getAccount.body.ring_plan}}',
                              dataSourceId: 'aa7a0a4f-f58d-46a5-aa8d-225eaffb913b',
                              dataSourcePropertyId: 'ring_plan'
                            },
                            {
                              expression: 'Basic'
                            }
                          ]
                        }
                      },
                      {
                        action: {
                          type: 'screen',
                          options: {
                            screenId: '4a43099d-572a-4af8-9740-2d1e77a5b51a'
                          }
                        },
                        condition: {
                          eq: [
                            {
                              expression: '{{api.getAccount.body.ring_plan}}',
                              dataSourceId: 'aa7a0a4f-f58d-46a5-aa8d-225eaffb913b',
                              dataSourcePropertyId: 'ring_plan'
                            },
                            {
                              expression: 'Free'
                            }
                          ]
                        }
                      }
                    ],
                    defaultAction: {
                      type: 'screen',
                      options: {
                        screenId: '4a43099d-572a-4af8-9740-2d1e77a5b51a'
                      }
                    }
                  }
                },
                text: 'Check my Product Type'
              }
            }
          ],
          position: {
            x: -640,
            y: 120,
            z: 743
          }
        }
      ],
      text: {
        confirmationText: 'Learn About Subscription Plans'
      },
      startScreenId: 'b03ee929-4edf-47e7-b5c9-528cc0d63755'
    }
  },
  {
    name: 'CSAT Survey (Binary Rating)',
    purpose: 'survey',
    survey: { type: 'binary' },
    flow: {
      screens: [
        {
          id: 'f96f8d20-5685-45b7-b353-2fba53d4cd4b',
          name: 'CSAT Survey - Easy/Hard',
          displayType: 'rating',
          displayId: '2',
          components: [
            {
              id: '52b0679c-5795-4b64-8626-ad984f06ad4f',
              type: 'paragraph',
              options: {
                text: '<p>How easy was it to find this answer?</p>'
              }
            },
            {
              id: '5b3b3a9a-0ba9-4192-95b2-b0a1b627754d',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '78be3c1f-7080-452a-9faf-97f2121ebaca'
                  }
                },
                text: '😊  Easy',
                rating: 'easy'
              }
            },
            {
              id: 'd56e7436-b05e-492f-b93b-81c68af605e4',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '78be3c1f-7080-452a-9faf-97f2121ebaca'
                  }
                },
                text: '🙁  Hard',
                rating: 'hard'
              }
            }
          ],
          position: {
            x: 1400,
            y: 990,
            z: 29
          }
        },
        {
          id: '78be3c1f-7080-452a-9faf-97f2121ebaca',
          name: 'Survey Comments',
          displayId: '3',
          components: [
            {
              id: 'acea0a81-c8bf-447a-9c74-f5940d111fa0',
              type: 'paragraph',
              options: {
                text: '<p>Thanks for letting us know! Would you care to leave a comment on how we might improve?</p>'
              }
            },
            {
              id: '994614a2-8042-4cb6-8f01-194d39831d64',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'a88252b1-c0f4-4be6-b874-edf8832dcffe'
                  }
                },
                text: 'Yes, give feedback'
              }
            },
            {
              id: '79d9b8f8-b7fb-422a-9d19-7bf55e6dcf56',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '714fca4f-d896-472c-b613-5d3a3c76eec6'
                  }
                },
                text: 'No, thanks'
              }
            }
          ],
          position: {
            x: 1400,
            y: 1350,
            z: 5
          }
        },
        {
          id: '714fca4f-d896-472c-b613-5d3a3c76eec6',
          name: 'Survey Submitted Confirmation',
          displayId: '4',
          components: [
            {
              id: '14ad4546-c2ad-4870-b180-539bcb9498fe',
              type: 'paragraph',
              options: {
                text: '<p>Thanks for your feedback! Please let me know if there is anything else I can help with.</p>'
              }
            }
          ],
          position: {
            x: 1400,
            y: 1790,
            z: 144
          }
        },
        {
          id: 'a88252b1-c0f4-4be6-b874-edf8832dcffe',
          name: 'Comments Form (Component)',
          displayType: 'overlay',
          displayId: '5',
          components: [
            {
              id: '7e59e353-e4f1-4b6f-8c54-744b1cb951b3',
              type: 'paragraph',
              options: {
                text: '<p>We value your feedback</p>'
              }
            },
            {
              id: 'd84cbb0d-e3d8-45d6-9a54-7ecda8729f86',
              type: 'textarea',
              options: {
                title: 'How can we improve?'
              }
            },
            {
              id: '52571535-8f9b-4a2e-aaa4-3fd1b3c7de10',
              type: 'button',
              options: {
                text: 'Submit',
                purpose: 'submit',
                action: {
                  type: 'screen',
                  options: {
                    screenId: '714fca4f-d896-472c-b613-5d3a3c76eec6'
                  }
                }
              }
            },
            {
              id: '2cd86607-ff4c-44bc-8fe7-40dd18e2878b',
              type: 'button',
              options: {
                text: 'Cancel',
                purpose: 'cancel',
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'ff3c233b-8a27-44a4-b41c-b8892e6cc076'
                  }
                }
              }
            }
          ],
          position: {
            x: 1790,
            y: 1470,
            z: 7
          }
        },
        {
          id: 'ff3c233b-8a27-44a4-b41c-b8892e6cc076',
          name: 'Cancel Comments',
          displayId: '6',
          components: [
            {
              id: 'c2ecd017-70a9-4970-9a35-fdeb8cbf9f23',
              type: 'paragraph',
              options: {
                text: '<p>Please let me know if there is anything else I can help with.</p>'
              }
            }
          ],
          position: {
            x: 1790,
            y: 1790,
            z: 142
          }
        }
      ],
      startScreenId: 'f96f8d20-5685-45b7-b353-2fba53d4cd4b'
    }
  },
  {
    name: 'CSAT Survey (Star Rating)',
    purpose: 'survey',
    survey: { type: 'star' },
    flow: {
      screens: [
        {
          id: '5ca353c1-1d5e-49e6-ba87-9f6739f3dc96',
          name: 'CSAT Survey - Star Rating',
          displayType: 'rating',
          displayId: '1',
          components: [
            {
              id: '520ccbd2-10a5-4f86-aa58-e82b793e2a92',
              type: 'paragraph',
              options: {
                text: '<p>Please rate your experience</p>'
              }
            },
            {
              id: 'c86e3556-2bd0-4fee-bcd3-d655fe65ac5e',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'ddbf1eee-9bf3-49b0-8acc-d05ef2ee635c'
                  }
                },
                text: '⭐  ⭐  ⭐  ⭐  ⭐',
                rating: 5
              }
            },
            {
              id: 'ba1930d5-43e2-430c-bec1-22813569e759',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'f48239f8-4752-4d23-a176-455b4663b7f4'
                  }
                },
                text: '⭐⭐⭐⭐',
                rating: 4
              }
            },
            {
              id: 'bf5edfd2-15ed-4789-9d80-ebc4e7b6b9fc',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'f48239f8-4752-4d23-a176-455b4663b7f4'
                  }
                },
                text: '⭐⭐⭐',
                rating: 3
              }
            },
            {
              id: '68d276f6-24cb-4608-9031-5ec7ded699ff',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'e76d0ab8-68b1-42c9-b153-9b33b82a5bcf'
                  }
                },
                text: '⭐⭐',
                rating: 2
              }
            },
            {
              id: 'ed6b3a48-62d0-4067-8ff2-ebc2921ec74f',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'e76d0ab8-68b1-42c9-b153-9b33b82a5bcf'
                  }
                },
                text: '⭐',
                rating: 1
              }
            }
          ],
          position: {
            x: 1030,
            y: 1040,
            z: 13
          }
        },
        {
          id: 'ddbf1eee-9bf3-49b0-8acc-d05ef2ee635c',
          name: '5-Star Survey Comments',
          displayId: '2',
          components: [
            {
              id: '130927c9-2338-4e9c-a7d9-98dd58ad58c4',
              type: 'paragraph',
              options: {
                text:
                  '<p>🤩 Thanks for the 5-star rating! Would you care to leave a comment on anything we might improve?</p>'
              }
            },
            {
              id: '913ab959-c8c7-46bb-b8bf-b4b84d15ed33',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '81f83574-fc45-4455-86ad-c25420c2304f'
                  }
                },
                text: 'Yes, give feedback'
              }
            },
            {
              id: '2d635c70-6c6a-47a3-ba8e-62467bed6722',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'a0b3c6ab-544b-4643-a4f7-47db251df896'
                  }
                },
                text: 'No, thanks'
              }
            }
          ],
          position: {
            x: 1030,
            y: 1430,
            z: 54
          }
        },
        {
          id: 'f48239f8-4752-4d23-a176-455b4663b7f4',
          name: '3-4 Star Survey Comments',
          displayId: '3',
          components: [
            {
              id: '72823fb4-58d9-44c2-945f-02ba3dded3e8',
              type: 'paragraph',
              options: {
                text:
                  '<p>Thanks for the rating. Definitely room for improvement. Would you care to leave a comment on how we might improve?</p>'
              }
            },
            {
              id: '5cbea098-bc7b-4ec4-b499-4f42f1237716',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '81f83574-fc45-4455-86ad-c25420c2304f'
                  }
                },
                text: 'Yes, give feedback'
              }
            },
            {
              id: '228e1949-3f3b-4ea5-a4b7-9f6358b8c16e',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'a0b3c6ab-544b-4643-a4f7-47db251df896'
                  }
                },
                text: 'No, thanks'
              }
            }
          ],
          position: {
            x: 1390,
            y: 1430,
            z: 78
          }
        },
        {
          id: 'e76d0ab8-68b1-42c9-b153-9b33b82a5bcf',
          name: '1-2 Star Survey Comments',
          displayId: '4',
          components: [
            {
              id: '64ab28e8-9b74-44ec-9d5f-79859eb9573e',
              type: 'paragraph',
              options: {
                text:
                  '<p>Sorry you had a less than stellar experience. Thanks for letting us know. We would love to get your feedback on how we might improve?</p>'
              }
            },
            {
              id: '77812949-8519-4797-8f80-26a47aacb1b4',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: '81f83574-fc45-4455-86ad-c25420c2304f'
                  }
                },
                text: 'Yes, give feedback'
              }
            },
            {
              id: '2df7de12-dc26-4ab9-996b-2d0ef3640900',
              type: 'button',
              options: {
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'a0b3c6ab-544b-4643-a4f7-47db251df896'
                  }
                },
                text: 'No, thanks'
              }
            }
          ],
          position: {
            x: 1740,
            y: 1430,
            z: 180
          }
        },
        {
          id: '81f83574-fc45-4455-86ad-c25420c2304f',
          name: 'Comments Form (Component)',
          displayType: 'overlay',
          displayId: '5',
          components: [
            {
              id: 'e9eff230-4cf2-4b1c-8be7-b2c6d862d8f9',
              type: 'paragraph',
              options: {
                text: '<p>We value your feedback</p>'
              }
            },
            {
              id: 'fdb62c3e-0575-4824-ac39-233f3777a1b0',
              type: 'textarea',
              options: {
                title: 'How can we improve?'
              }
            },
            {
              id: 'bf1ecb26-4600-4df5-a5fe-7638c374ccd0',
              type: 'button',
              options: {
                text: 'Submit',
                purpose: 'submit',
                action: {
                  type: 'screen',
                  options: {
                    screenId: '3c1ff714-9212-478e-b0b7-3efc4dc462e4'
                  }
                }
              }
            },
            {
              id: '02307b86-7c09-4e44-8fdf-6a594dc25803',
              type: 'button',
              options: {
                text: 'Cancel',
                purpose: 'cancel',
                action: {
                  type: 'screen',
                  options: {
                    screenId: 'a0b3c6ab-544b-4643-a4f7-47db251df896'
                  }
                }
              }
            }
          ],
          position: {
            x: 1580,
            y: 1820,
            z: 412
          }
        },
        {
          id: 'a0b3c6ab-544b-4643-a4f7-47db251df896',
          name: 'Cancel Comments',
          displayId: '6',
          components: [
            {
              id: 'c4ad9044-142c-4fc4-b894-a59c467ea7c2',
              type: 'paragraph',
              options: {
                text: '<p>Please let me know if there is anything else I can help with.</p>'
              }
            }
          ],
          position: {
            x: 1350,
            y: 2180,
            z: 420
          }
        },
        {
          id: '3c1ff714-9212-478e-b0b7-3efc4dc462e4',
          name: 'Survey Submitted Confirmation - 5 star',
          displayId: '7',
          components: [
            {
              id: 'ce1533b0-0799-438b-897b-c02c9e5e05a9',
              type: 'paragraph',
              options: {
                text: '<p>Thanks for your feedback! Please let me know if there is anything else I can help with.</p>'
              }
            }
          ],
          position: {
            x: 1810,
            y: 2180,
            z: 418
          }
        }
      ],
      startScreenId: '5ca353c1-1d5e-49e6-ba87-9f6739f3dc96'
    }
  }
];

export default TEMPLATES;
