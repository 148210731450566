import format from 'date-fns/format';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import reject from 'lodash/reject';
import { action, computed, observable, toJS } from 'mobx';
import { asyncAction } from 'mobx-utils';
import moment, { Moment } from 'moment';

import { feedbackService, recommendationService, searchService } from '../services';
import asyncComputed from '../shared/util/asyncComputed';
import { RangeType } from '../shared/util/DateRangeTypes';
import { NotificationManager } from '../shared/util/NotificationManager';
import { PromiseState } from '../shared/util/PromiseStates';
import { recordErrors } from '../shared/util/recordErrors';
import Solution from './models/Solution';
import { OrgStore } from './orgStore';

interface IVotedQuerySolutionData {
  votedQueryDataSet: {
    id: string;
    queryInfo: any;
    suggestedSolutions: Solution[];
    votedSolutions: Solution[];
    offset: number;
    createdAt?: string;
    updatedAt?: string;
    queryMetadata: {
      solution_tags: string[] | null;
    };
    totalQuestionsAvailable: number;
  };
}

export const DATE_ONLY_FORMAT = 'YYYY-MM-DD';

const RANGE_TO_DAYS_AGO = {
  [RangeType.TODAY]: 0,
  [RangeType.PAST_WEEK]: 7,
  [RangeType.PAST_2_WEEKS]: 14,
  [RangeType.PAST_MONTH]: 30,
  [RangeType.PAST_3_MONTHS]: 90
};

const ALL_TIKA_USERS = [
  { value: '1978', label: 'Tika User 01: 1978' },
  { value: '1979', label: 'Tika User 02: 1979' },
  { value: '1980', label: 'Tika User 03: 1980' },
  { value: '1981', label: 'Tika User 04: 1981' },
  { value: '1982', label: 'Tika User 05: 1982' },
  { value: '2007', label: 'Tika User 06: 2007' },
  { value: '2008', label: 'Tika User 07: 2008' },
  { value: '2009', label: 'Tika User 08: 2009' },
  { value: '2010', label: 'Tika User 09: 2010' },
  { value: '2011', label: 'Tika User 10: 2011' },
  { value: '2012', label: 'Tika User 11: 2012' },
  { value: '2013', label: 'Tika User 12: 2013' },
  { value: '2014', label: 'Tika Manager 01: 2014' },
  { value: '2015', label: 'Tika Manager 02: 2015' },
  { value: '2776', label: 'Tika Manager 03: 2776' },
  { value: '2777', label: 'Tika Manager 04: 2777' },
  { value: '2778', label: 'Tika Manager 05: 2778' },
  { value: '2779', label: 'Tika Manager 06: 2779' },
  { value: '2780', label: 'Tika Manager 07: 2780' }
];

const BI_USERS = [
  { value: '1957', label: 'Brad: 1957' },
  { value: '1224', label: 'Chris: 1224' },
  { value: '2000', label: 'Jacob: 2000' },
  { value: '2005', label: 'Kaye: 2005' },
  { value: '1877', label: 'Tom: 1877' },
  { value: '2335', label: 'Tony: 2335' }
];

export const DEFAULT_VOTE_AUDIT_USERS = [
  {
    value: 'all_tika_users',
    label: 'All Tika Users',
    children: ALL_TIKA_USERS
  },
  {
    value: 'bi_users',
    label: 'BI Users',
    children: BI_USERS
  },
  { value: 'solvvy_feedback', label: 'Solvvy Feedback' },
  { value: 'expert_feedback', label: 'Expert Feedback' }
];

const emptyVotedQueryDataSet = {
  votedQueryDataSet: {
    id: '',
    queryInfo: {
      id: '',
      status: ''
    },
    suggestedSolutions: [],
    votedSolutions: [],
    offset: 0,
    count: 0,
    queryMetadata: {
      solution_tags: []
    },
    createdAt: '',
    totalQuestionsAvailable: 0
  }
};

export default class VoteAuditBetaStore {
  @observable
  selectedCustomStartDay?: Moment = moment().subtract(7, 'days');
  @observable
  selectedCustomEndDay?: Moment = moment();
  @observable
  selectedDateRangeType: RangeType = RangeType.PAST_WEEK;
  @observable
  updatedDateRangeType: RangeType = RangeType.PAST_WEEK;
  @observable
  selectedUserValue: string[] = ['all_tika_users'];
  @observable
  solutionTab: number = 0; // This observable determines the open tab.
  @observable
  offset = 0; // The index of the current question.
  @observable
  skipDirection: string = 'next';
  @observable
  moreSolutions: Solution[] = []; // Array of solutions loaded from a search.
  @observable
  searchMoreValue = ''; // The solution search query.
  @observable
  saving: boolean = false; // Determine if we are currently saving.
  @observable
  loadingMoreSolutions: PromiseState = PromiseState.fulfilled; // Determines if search solutions are being loaded.
  @observable
  updatedSolutions: Solution[] = []; // The updated set of solutions. Checking to see if this attribute is still necessary.
  @observable
  recommendationStatusFilter = {
    addressed: 'Absolutely Perfect Answer',
    junk: 'Junk',
    innately_non_deflectable: 'Innately Non-Deflectable',
    constructive_feedback: 'Constructive Feedback',
    content_gap: 'Content Gap',
    support_recommended: 'Support Recommended',
    answer_loop: 'Answer Loop',
    accuracy_issue: 'Accuracy Issue'
  };
  @observable
  selectedRecommendationStatus = ['addressed'];
  @observable
  recommendationStatus: any = null;
  @observable
  taraMode: boolean = false;

  // keep solution  to restore saved solution
  savedSolution?: Solution = undefined;

  shallowMoreSolutions: Solution[] = [];

  unauditedQueriesWithStatus = asyncComputed<IVotedQuerySolutionData>(async () => {
    try {
      const result = await recommendationService.getVotedQueries(
        this._orgStore.selectedOrgId,
        this.selectedUserIds,
        this.taraMode ? this.selectedRecommendationStatus : ['addressed', 'accuracy_issue'],
        this.formattedCustomStartDay,
        this.formattedCustomEndDay,
        this.offset,
        this.taraMode
      );
      const {
        data: [queryData],
        headers: { 'x-total': totalQuestions }
      } = result;
      return this.constructQuerySolutionData({ ...queryData, totalQuestionsAvailable: totalQuestions });
    } catch (e) {
      recordErrors(e);
      return emptyVotedQueryDataSet;
    }
  });

  constructor(private _orgStore: OrgStore, public _notificationManager: NotificationManager) {}

  // Retrieves solutions for a given query and repackages them into the query.
  async constructQuerySolutionData(queryData) {
    try {
      if (isEmpty(queryData)) {
        return emptyVotedQueryDataSet;
      }

      // get query metadata
      const {
        data: [queryMetadata]
      } = await searchService.getQueryInfo(queryData.query_id, this._orgStore.selectedOrgId);

      // get all voted queries
      const { data: selectedSolutionList } = await feedbackService.getAllVotedQueries(
        queryData.query_id,
        this.sourceType
      );

      // retrieve voted solution info
      const votedSolutions = await this.fetchVotedSolutionsData(queryData.query_id, selectedSolutionList);
      this.savedSolution = votedSolutions[0];
      // retrieve suggestion solution info and exclude voted solutions
      const suggestedSolutions = await this.fetchSolutionsData(queryMetadata, votedSolutions);

      return {
        votedQueryDataSet: {
          id: queryData.id,
          queryInfo: {
            id: queryData.query_id,
            ...queryData
          },
          suggestedSolutions,
          votedSolutions,
          offset: this.offset,
          queryMetadata,
          createdAt: queryData.created_at,
          updatedAt: queryData.updated_at,
          totalQuestionsAvailable: queryData.totalQuestionsAvailable
        }
      };
    } catch (e) {
      return emptyVotedQueryDataSet;
    }
  }

  async fetchSolutionsData(queryData, votedSolutions) {
    let solutions: Solution[];
    const { data: solutionResp } = await searchService.search(
      // Use search API to get initial suggested solutions
      this._orgStore.selectedOrgId,
      queryData.content,
      this.solutionTags
    );
    // Reformat the solution data into an array.
    solutions = solutionResp.reduce<Solution[]>((result, respData) => {
      respData.solutions.map(value => {
        const votedSolution = votedSolutions.find(solution => solution.id === value.id);
        result.push(
          new Solution(
            queryData.query_id,
            value.id,
            value.content,
            value.metadata,
            votedSolution ? votedSolution.relevance : '0',
            value.resource.type,
            this._orgStore.currentUserContext.isGlobalUser ? 'solvvy_feedback' : 'expert_feedback',
            'direct',
            this._orgStore.currentUserContext.id.toString(),
            false
          )
        );
      });
      return result;
    }, []);

    return solutions;
  }

  async fetchVotedSolutionsData(queryId, selectedSolutionList) {
    let votedSolutions: Solution[] = [];
    if (!isEmpty(selectedSolutionList)) {
      const selectedSolutionsIds = selectedSolutionList.map(solution => solution.solution_id);
      const { data: filteredSolutions } = await searchService.getSolutionsInfo(selectedSolutionsIds);

      votedSolutions = filteredSolutions.map(data => {
        const selectedData = selectedSolutionList.filter(value => value.solution_id === data.id)[0];
        return new Solution(
          queryId,
          data.id,
          data.content,
          data.metadata,
          selectedData.relevance,
          selectedData.solution_resource_type,
          selectedData.source,
          selectedData.source_type,
          selectedData.source_id,
          selectedData.was_positive
        );
      });
    }
    return votedSolutions;
  }

  @action
  openSuggestedSolutions = () => {
    this.solutionTab = 0;
  };

  @action
  openSearchSolutions = () => {
    this.solutionTab = 1;
  };

  @action
  setSelectedUserValue = value => {
    this.selectedUserValue = value;
  };

  @asyncAction
  *searchMoreSolution(content: string) {
    this.loadingMoreSolutions = PromiseState.pending;
    this.moreSolutions = [];
    try {
      if (isEmpty(content)) {
        this.loadingMoreSolutions = PromiseState.fulfilled;
      } else {
        const { data: moreSolutions } = yield searchService.search(
          this._orgStore.selectedOrgId,
          content,
          this.solutionTags,
          10
        );

        this.moreSolutions = moreSolutions.reduce((result, solution) => {
          solution.solutions.map(value => {
            result.push(
              new Solution(
                this.queryData.id,
                value.id,
                value.content,
                value.metadata,
                '0',
                value.resource.type,
                this._orgStore.currentUserContext.isGlobalUser ? 'solvvy_feedback' : 'expert_feedback',
                'direct',
                this._orgStore.currentUserContext.id.toString(),
                false
              )
            );
          });
          return result;
        }, []);
        this.shallowMoreSolutions = toJS(this.moreSolutions);
        this.loadingMoreSolutions = PromiseState.fulfilled;
      }
    } catch (e) {
      this.loadingMoreSolutions = PromiseState.rejected; // We hit an error.
      recordErrors(e);
    }
  }

  @asyncAction
  *saveAuditedFeedback() {
    try {
      this.saving = true;
      // Save the solutions to ml-data.votes. We link the solution ids to the question id (in queries).
      for (const solution of this.allSelectedSolutions) {
        yield feedbackService.saveAuditedFeedback({
          id: this._orgStore.selectedOrgId,
          userId: this._orgStore.currentUserContext.id.toString(),
          queryId: this.queryData.id,
          solutionId: solution.id,
          solutionType: solution.solution_resource_type,
          source_type: solution.source_type || 'direct',
          relevance: solution.relevance,
          sourceFeedback: solution.source,
          source_id: solution.source_id
        });
      }

      // Update status and audited flag and audited by
      yield recommendationService.updateRecommendationStatus({
        id: this.recommendationId,
        status: this.updatedRecommendationStatus,
        org_id: this._orgStore.selectedOrgId,
        audited: true,
        audited_by: this._orgStore.currentUserContext.id
      });

      this._notificationManager.success({
        title: 'Your feedback saved!!!',
        message: `On to the next question!`
      });
      this.resetData();
      this.unauditedQueriesWithStatus.refresh(); // Go to the next question in the list.
    } catch (e) {
      this._notificationManager.error({
        title: 'Oops something went wrong',
        message: `Try after sometime or skip to next question.`
      });
      recordErrors(e);
      return e;
    }
  }

  @action
  increaseOffset = (currentOffsetValue = this.offset) => {
    this.resetData();
    this.offset = currentOffsetValue + 1;
  };

  @action
  decreaseOffset = (currentOffsetValue = this.offset) => {
    this.resetData();
    this.offset = currentOffsetValue - 1;
  };

  @action
  updateSelectedRecommendationStatus(selectedInput: string[]) {
    // reset offset when change in filter
    this.offset = 0;
    this.selectedRecommendationStatus = [];

    // empty filter should have all status
    if (selectedInput.length === 0) {
      this.selectedRecommendationStatus = Object.keys(this.recommendationStatusFilter);
      return;
    }

    // add all selected recommendations
    selectedInput.forEach(recommendation => {
      const recommendationKey = Object.keys(this.recommendationStatusFilter).find(
        key => this.recommendationStatusFilter[key] === recommendation
      );

      if (recommendationKey) {
        this.selectedRecommendationStatus.push(recommendationKey);
      }
    });
  }

  @action
  resetData() {
    this.updatedSolutions = [];
    this.moreSolutions = [];
    this.searchMoreValue = '';
    this.shallowMoreSolutions = [];
    this.saving = false;
    this.solutionTab = 0;
    this.recommendationStatus = null;
  }

  @action
  setCustomDateRange(startMoment: Moment, endMoment: Moment) {
    this.selectedCustomStartDay = startMoment;
    this.selectedCustomEndDay = endMoment;
    this.selectedDateRangeType = RangeType.CUSTOM;
  }

  @action
  setStandardDateRange(rangeType: RangeType) {
    this.selectedDateRangeType = rangeType;
    this.updatedDateRangeType = rangeType;
  }

  @action
  disabledDate(current) {
    return current > moment().endOf('day');
  }

  @action
  updateVotedRelevance(solution: Solution, relevanceValue: string, isReset: boolean = false) {
    if (findIndex(this.votedSolutionData, { id: solution.id }) !== -1) {
      const suggestedSolution = find(this.suggestedSolutionsData, data => data.id === solution.id);
      suggestedSolution ? (suggestedSolution.relevance = relevanceValue) : '';
      const votedSolution = find(this.votedSolutionData, data => data.id === solution.id);
      votedSolution ? (votedSolution.relevance = relevanceValue) : '';
      solution.relevance = relevanceValue;
    } else if (findIndex(this.suggestedSolutionsData, { id: solution.id }) !== -1) {
      const selectedSolution = find(this.suggestedSolutionsData, data => data.id === solution.id);
      selectedSolution ? (selectedSolution.relevance = relevanceValue) : '';
      solution.relevance = relevanceValue;
    } else {
      // relevance for searched solution needs to be updated differently if performing reset action
      isReset
        ? this.updateRelevanceForResetSolution(solution, relevanceValue)
        : this.updateMoreSolutionRelevance(solution, relevanceValue);
    }
  }

  @action
  updateMoreSolutionRelevance(solution: Solution, relevanceValue: string) {
    this.updateRelevance(solution, relevanceValue, this.moreSolutions, this.shallowMoreSolutions);
  }

  @action
  updateRecommendationStatus(status: string) {
    this.recommendationStatus = status;
  }

  @action.bound
  clearRecommendationStatus() {
    this.recommendationStatus = null;
  }

  @action
  updateRelevanceForResetSolution(solution: Solution, relevanceValue: string) {
    solution.relevance = relevanceValue;
    this.updatedSolutions.push(solution);
  }

  @action
  updateRelevance(
    solution: Solution,
    relevanceValue: string,
    solutionsArray: Solution[],
    shallowSolutionsArray: Solution[]
  ) {
    let updatedRelevance = relevanceValue;
    if (findIndex(solutionsArray, { id: solution.id, relevance: relevanceValue }) === -1) {
      solution.relevance = relevanceValue;
    } else {
      solution.relevance = '0';
      updatedRelevance = '0';
    }

    if (findIndex(shallowSolutionsArray, { id: solution.id, relevance: updatedRelevance }) === -1) {
      if (findIndex(this.updatedSolutions, { id: solution.id }) === -1) {
        this.updatedSolutions.push(solution);
      }
    } else {
      this.updatedSolutions = reject(this.updatedSolutions, { id: solution.id });
    }
  }

  @computed
  get totalQuestionsAvailable() {
    if (this.unauditedQueriesWithStatus.fulfilled) {
      return this.unauditedQueriesWithStatus.value.votedQueryDataSet.totalQuestionsAvailable;
    } else {
      return 0;
    }
  }

  @action.bound
  toggleVoteMode() {
    this.taraMode = !this.taraMode;
  }

  @computed
  get orgName() {
    return this._orgStore.userOrg.name;
  }

  @computed
  get recommendationId() {
    if (this.unauditedQueriesWithStatus.fulfilled) {
      return this.unauditedQueriesWithStatus.value.votedQueryDataSet.id;
    } else {
      return '';
    }
  }

  @computed
  get queryData() {
    if (this.unauditedQueriesWithStatus.fulfilled) {
      return this.unauditedQueriesWithStatus.value.votedQueryDataSet.queryInfo;
    } else {
      return {};
    }
  }

  @computed
  get queryDate() {
    if (
      this.unauditedQueriesWithStatus.fulfilled &&
      this.unauditedQueriesWithStatus.value.votedQueryDataSet.queryInfo.created_at
    ) {
      return format(
        new Date(this.unauditedQueriesWithStatus.value.votedQueryDataSet.queryInfo.created_at),
        'MMM Do YY'
      );
    } else {
      return '';
    }
  }

  @computed
  get voteCreatedDate() {
    if (
      this.unauditedQueriesWithStatus.fulfilled &&
      this.unauditedQueriesWithStatus.value.votedQueryDataSet.updatedAt
    ) {
      return format(new Date(this.unauditedQueriesWithStatus.value.votedQueryDataSet.updatedAt), 'MMM Do YY');
    } else {
      return '';
    }
  }

  @computed
  get votedSolutionData() {
    if (this.unauditedQueriesWithStatus.fulfilled) {
      return this.unauditedQueriesWithStatus.value.votedQueryDataSet.votedSolutions;
    } else {
      return [];
    }
  }

  @computed
  get suggestedSolutionsData() {
    if (this.unauditedQueriesWithStatus.fulfilled) {
      return this.unauditedQueriesWithStatus.value.votedQueryDataSet.suggestedSolutions;
    } else {
      return [];
    }
  }

  @computed
  get hasSelectedSolutionsData() {
    return this.allSelectedSolutions.length > 0;
  }

  @computed
  get hasAllRelevantSelectedSolutionsData() {
    return this.allRelevantSelectedSolutions.length > 0;
  }

  @computed
  get numberOfSuggestedSolutions() {
    return this.suggestedSolutionsData.filter(solution => solution.relevance === '0').length;
  }

  @computed
  get allSelectedSolutions() {
    // Gets all of the selected solutions in a merged list.
    return [
      ...this.votedSolutionData,
      ...this.suggestedSolutionsData.filter(solution => {
        const votedSolution = this.votedSolutionData.find(data => data.id === solution.id);
        return solution.relevance !== '0' && !votedSolution;
      }),
      ...toJS(this.updatedSolutions)
    ];
  }

  @computed
  get allRelevantSelectedSolutions() {
    return [
      ...this.votedSolutionData.filter(solution => solution.relevance !== '0'),
      ...this.suggestedSolutionsData.filter(solution => {
        const votedSolution = this.votedSolutionData.find(data => data.id === solution.id);
        return solution.relevance !== '0' && !votedSolution;
      }),
      ...toJS(this.updatedSolutions)
    ];
  }

  @computed
  get isRecommendationStatusOtherThanAddressed() {
    if (this.unauditedQueriesWithStatus.fulfilled && !this.recommendationStatus) {
      return ['junk', 'innately_non_deflectable', 'constructive_feedback', 'content_gap'].includes(
        this.unauditedQueriesWithStatus.value.votedQueryDataSet.queryInfo.status
      );
    }
    return ['junk', 'innately_non_deflectable', 'constructive_feedback', 'content_gap'].includes(
      this.recommendationStatus
    );
  }

  @computed
  get isRecommendationStatusIsPartOfNoPerfectAnswer() {
    if (this.unauditedQueriesWithStatus.fulfilled && !this.recommendationStatus) {
      return ['innately_non_deflectable', 'constructive_feedback', 'content_gap'].includes(
        this.unauditedQueriesWithStatus.value.votedQueryDataSet.queryInfo.status
      );
    }
    return ['innately_non_deflectable', 'constructive_feedback', 'content_gap'].includes(this.recommendationStatus);
  }

  @computed
  get sourceType(): string {
    if (this.selectedUserValue[0] === 'expert_feedback') {
      return 'expert_feedback';
    } else {
      return 'solvvy_feedback';
    }
  }

  @computed
  get selectedUserIds(): string[] {
    if (this.selectedUserValue.length === 2) {
      return this.selectedUserValue.slice(1);
    } else if (this.selectedUserValue[0] === 'all_tika_users') {
      return ALL_TIKA_USERS.map(user => user.value);
    } else if (this.selectedUserValue[0] === 'bi_users') {
      return BI_USERS.map(user => user.value);
    } else {
      return [this._orgStore.currentUserContext.id.toString()];
    }
  }

  @computed
  get updatedRecommendationStatus() {
    if (isNil(this.recommendationStatus) && this.unauditedQueriesWithStatus.fulfilled) {
      return this.unauditedQueriesWithStatus.value.votedQueryDataSet.queryInfo.status;
    }
    return this.recommendationStatus;
  }

  @computed
  get solutionTags() {
    if (this.unauditedQueriesWithStatus.fulfilled) {
      const { solution_tags } = this.unauditedQueriesWithStatus.value.votedQueryDataSet.queryMetadata;
      return solution_tags ? solution_tags : [];
    } else {
      return [];
    }
  }

  @computed
  get effectiveStartMoment(): Moment {
    if (this.selectedDateRangeType === RangeType.CUSTOM && this.selectedCustomStartDay) {
      return this.selectedCustomStartDay;
    } else if (this.selectedDateRangeType === RangeType.CURRENT_YEAR) {
      return moment().startOf('year');
    } else {
      const daysAgo = RANGE_TO_DAYS_AGO[this.selectedDateRangeType];
      return moment()
        .clone()
        .subtract(daysAgo, 'days')
        .startOf('day');
    }
  }

  @computed
  get effectiveEndMoment(): Moment {
    return this.selectedDateRangeType === RangeType.CUSTOM && this.selectedCustomEndDay
      ? moment(this.selectedCustomEndDay)
      : moment().endOf('day');
  }

  @computed
  get formattedCustomStartDay(): string {
    return this.effectiveStartMoment.format(DATE_ONLY_FORMAT);
  }

  @computed
  get formattedCustomEndDay(): string {
    return this.effectiveEndMoment.format(DATE_ONLY_FORMAT);
  }
}
