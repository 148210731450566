export const ALLOWED_WELCOME_TEXTS = [
  'questionForm.greeting',
  'questionForm.instructions',
  'questionForm.placeholder',
  'greetingCard.greeting'
];
export const RECOMMENDED_LENGTH = 250;

export const RECOMMENDED_WARNING_TEXT = `Warning! For the best appearance, keep your content under ${RECOMMENDED_LENGTH} characters.`;

export const GREETING_PROMPT_DELAY_OPTIONS = [0, 1000, 2000, 3000];

export const GREETING_PROMPT_LIMIT = 3;

export const MAX_PROMPT_INFO = `There is a (${GREETING_PROMPT_LIMIT}) prompt limit for greetings.`;
