import styled from 'styled-components';

const S: { ChartsLayout?: any; Container?: any; DateDropDown?: any } = {};

S.Container = styled.div`
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

S.ChartsLayout = styled.div`
  .row {
    width: 100%;
    height: fit-content;
  }
`;

S.DateDropDown = styled.div`
  padding: 8px 12px;
  .label {
    font-weight: 600;
    padding-right: 5px;
  }

  .date_range_dropdown {
    max-width: 280px;
    margin-right: 12px;
    height: 40px;

    .ant-calendar-picker-input.ant-input {
      height: 100%;
    }

    .ant-calendar-header .ant-calendar-prev-month-btn::before,
    .ant-calendar-header .ant-calendar-prev-year-btn::before,
    .ant-calendar-header .ant-calendar-prev-year-btn::after {
      transform: rotate(-45deg) scale(1.2);
    }

    .ant-calendar-header .ant-calendar-next-month-btn::before,
    .ant-calendar-header .ant-calendar-next-year-btn::before,
    .ant-calendar-header .ant-calendar-next-year-btn::after {
      transform: rotate(135deg) scale(1.2);
    }
  }
`;

export default S;
