import * as Sentry from '@sentry/browser';

const LogRocket = require('logrocket');
const only = require('sanitize-object/exclude');

export const recordErrors = err => {
  if (err) {
    // Sanitize error object to exclude PII data before sending to LogRocket and Sentry
    const sanitizeError = only('config', 'request', 'response');
    const sanitizedError = sanitizeError(err);

    LogRocket.captureException(sanitizedError);
    Sentry.captureException(sanitizedError);
  }
};
