import { observable } from 'mobx';

export default class AvatarOptions {
  static createRaw(avatar: AvatarOptions): AvatarOptions {
    return {
      show: avatar.show,
      botUrl: avatar.botUrl,
      templateBotUrl: avatar.templateBotUrl,
      agentUrl: avatar.agentUrl,
      templateAgentUrl: avatar.templateAgentUrl
    };
  }

  @observable
  botUrl: string;

  @observable
  templateBotUrl: string | null;

  @observable
  agentUrl: string;

  @observable
  templateAgentUrl: string | null;

  @observable
  show: boolean;

  constructor({ botUrl, templateBotUrl, agentUrl, templateAgentUrl, show }: AvatarOptions) {
    this.botUrl = botUrl;
    this.show = show;
    this.templateBotUrl = templateBotUrl;
    this.agentUrl = agentUrl;
    this.templateAgentUrl = templateAgentUrl;
  }
}
