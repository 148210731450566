import { allUsingApi } from './util';

const facebookPages = (api, id: string, accessToken: string) => {
  return api.post('/v1/livechat/facebook/pages', {
    org_id: id,
    user_access_token: accessToken
  });
};

const sunshineIntegration = async (api, { userName, pageName, pageToken, sunshineId }) => {
  return await api.post(`/v1/livechat/sunshine/integration/messenger/${sunshineId}`, {
    facebook_user_name: userName,
    facebook_page_name: pageName,
    page_access_token: pageToken
  });
};

export const liveChatService = api => {
  return allUsingApi(api, {
    facebookPages,
    sunshineIntegration
  });
};

export default liveChatService;
