import { inject } from 'mobx-react';
import {
  connectorService,
  dataAnalyticsService,
  eventsService,
  exploreService,
  intentService,
  liveChatService,
  pluginService,
  resolveUiConfigurationService,
  resourcesService,
  uiConfigService
} from '../services';
import notificationManager from '../shared/util/NotificationManager';
import { TypedInject } from '../shared/util/utility';
import { AnalyticsStore } from './analyticsStore/';
import { CombinedAnalyticsStore } from './analyticsStore/combinedAnalyticsStore';
import authStore from './authStore';
import { CoachBetaStore } from './coachBetaStore';
import { CoachStore } from './coachStore';
import { CoachStoreV2 } from './coachStoreV2';
import { ConnectorStore } from './connectorStore';
import { DataSourcesStore } from './dataSourcesStore';
import ExploreStore from './exploreStore';
import { ExportQueriesStore } from './exportQueriesStore';
import FeaturesStore from './featuresStore';
import { InsightsStore } from './insightsStore';
import { IntentStore } from './intentStore';
import OnboardingStore from './onboardingStore';
import { OrgStore } from './orgStore';
import { PluginStore } from './pluginStore';
import { RecrawlHubStore } from './recrawlHubStore';
import { MultiLangDefaultText } from './ResolveUI/MultiLangDefaultText';
import { ResolveUiStore } from './resolveUiStore';
import routerStore from './routerStore';
import { SocialIntegrationStore } from './socialIntegrationStore';
import { SuggestionsStore } from './suggestionsStore';
import { UserManagementStore } from './userManagementStore';
import VoteAuditBetaStore from './voteAuditBetaStore';
import VoteAuditStore from './voteAuditStore';
import { WorkflowStore } from './workflowStore';

const featuresStore = new FeaturesStore();
const onboardingStore = new OnboardingStore();
const connectorStore = new ConnectorStore(onboardingStore, routerStore, notificationManager, connectorService);
const orgStore = new OrgStore(
  authStore,
  routerStore,
  connectorStore,
  onboardingStore,
  featuresStore,
  notificationManager
);
const userManagementStore = new UserManagementStore(orgStore, notificationManager);
const coachBetaStore = new CoachBetaStore(orgStore, notificationManager);
const coachStore = new CoachStore(orgStore, notificationManager);
const intentStore = new IntentStore(orgStore, routerStore, intentService);
const voteAuditStore = new VoteAuditStore(orgStore, notificationManager);
const voteAuditBetaStore = new VoteAuditBetaStore(orgStore, notificationManager);
const resolveUiStore = new ResolveUiStore(routerStore, orgStore, notificationManager, resolveUiConfigurationService);
const pluginStore = new PluginStore(
  routerStore,
  orgStore,
  notificationManager,
  resolveUiConfigurationService,
  pluginService,
  resolveUiStore
);
const dataSourcesStore = new DataSourcesStore(orgStore, connectorStore, resolveUiStore, uiConfigService);
const workflowStore = new WorkflowStore(
  routerStore,
  orgStore,
  resolveUiStore,
  connectorStore,
  intentService,
  notificationManager,
  resolveUiConfigurationService,
  connectorService,
  uiConfigService,
  eventsService,
  dataAnalyticsService
);
const analyticsStore = new AnalyticsStore(orgStore, authStore, workflowStore, routerStore, notificationManager);

// must assign here instead of passing in to constructor due to circular dependency
workflowStore._analyticsStore = analyticsStore;
workflowStore._dataSourcesStore = dataSourcesStore;
resolveUiStore._workflowStore = workflowStore;

const suggestionsStore = new SuggestionsStore(
  orgStore,
  resolveUiStore,
  workflowStore,
  routerStore,
  notificationManager,
  exploreService,
  uiConfigService
);
workflowStore._suggestionsStore = suggestionsStore;
resolveUiStore._suggestionsStore = suggestionsStore;

const exportQueriesStore = new ExportQueriesStore(orgStore, authStore, routerStore, notificationManager);
const exploreStore = new ExploreStore(orgStore, routerStore, notificationManager, exploreService, intentService);
const multiLangDefaultText = new MultiLangDefaultText(orgStore);
const insightsStore = new InsightsStore(orgStore);
const recrawlHubStore = new RecrawlHubStore(orgStore, resourcesService);
const socialIntegrationStore = new SocialIntegrationStore(
  orgStore,
  liveChatService,
  connectorService,
  notificationManager
);

const combinedAnalyticsStore = new CombinedAnalyticsStore(orgStore);
const coachStoreV2 = new CoachStoreV2(orgStore);

// must assign here instead of passing in to constructor due to circular dependency
authStore.orgStore = orgStore;

export const defaultStores = {
  authStore,
  orgStore,
  userManagementStore,
  routerStore,
  featuresStore,
  connectorStore,
  analyticsStore,
  combinedAnalyticsStore,
  coachStore,
  coachStoreV2,
  coachBetaStore,
  intentStore,
  voteAuditStore,
  voteAuditBetaStore,
  onboardingStore,
  pluginStore,
  resolveUiStore,
  workflowStore,
  dataSourcesStore,
  exportQueriesStore,
  multiLangDefaultText,
  insightsStore,
  exploreStore,
  suggestionsStore,
  recrawlHubStore,
  socialIntegrationStore
};

export type RootStore = typeof defaultStores;

export const typedInject = inject as TypedInject<RootStore>;
