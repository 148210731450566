import { observable } from 'mobx';
export default class ModalInstanceColors {
  @observable
  background: string = '#333333';
  @observable
  primary: string = '#999999';
  @observable
  secondary: string = '#EB144C';
  @observable
  confirmation: string = '#999999';
  @observable
  link: string = '#999999';
  @observable
  button: string = '#0081F9';
}
