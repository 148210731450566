import { Checkbox, Form, Modal } from 'antd';
import * as jsondiffpatch from 'jsondiffpatch';
import 'jsondiffpatch/dist/formatters-styles/html.css';
import { observer } from 'mobx-react';
import { Component, default as React } from 'react';
import styled from 'styled-components';
import { IUnpublishedChanges } from '../../store/ResolveUI/UiConfiguration';

interface IProps {
  unpublishedChanges: IUnpublishedChanges[];
  visible: boolean;
  onClose: () => void;
}

export const StyledUnpublishedChangesModal = styled(Modal)`
  width: 80vw !important;
  .ant-modal-body {
    max-height: calc(100vh - 300px);
    overflow-y: auto;
  }
  form {
    text-align: left;
  }
`;

@observer
export default class UnpublishedChangesModal extends Component<IProps, { unchanged: boolean }> {
  state = {
    unchanged: false
  };

  toggleUnchanged = () => {
    this.setState({ unchanged: !this.state.unchanged });
  };

  render() {
    const { unchanged } = this.state;
    const { unpublishedChanges, onClose } = this.props;

    const left = {} as any;
    const right = {} as any;

    unpublishedChanges.forEach(change => {
      left[change.path.path] = change.published;
      right[change.path.path] = change.preview;
    });

    let delta: jsondiffpatch.Delta | undefined;
    try {
      delta = jsondiffpatch.diff(left, right);
    } catch (e) {
      // re-compute diff without using google's diff-match-patch which has a bug dealing with certain unicode chars
      // See https://github.com/google/diff-match-patch/issues/59
      const diffWithoutTextDiff = jsondiffpatch.create({
        textDiff: {
          minLength: 9007199254740991 // specify max int value to basically disable using the text diff
        }
      });
      try {
        delta = diffWithoutTextDiff.diff(left, right);
      } catch (err) {
        // ignore further errors
      }
    }
    const deltaHtml = delta
      ? jsondiffpatch.formatters.html.format(delta as any, unchanged ? left : undefined)
      : 'error computing diff';

    return (
      <StyledUnpublishedChangesModal
        title="Unpublished Changes"
        visible={this.props.visible}
        onCancel={onClose}
        footer={
          <Form layout="inline">
            <Form.Item label="Show Unchanged" htmlFor="show-unchanged">
              <Checkbox id="show-unchanged" checked={unchanged} onChange={this.toggleUnchanged} />
            </Form.Item>
          </Form>
        }
      >
        <div dangerouslySetInnerHTML={{ __html: deltaHtml }} style={{ height: '100%', overflowY: 'auto' }} />
      </StyledUnpublishedChangesModal>
    );
  }
}
