import { observable } from 'mobx';

export type SolutionSource = 'solvvy_feedback' | 'expert_feedback';

export default class Solution {
  @observable
  content: string;
  @observable
  title: string;
  @observable
  url: string;
  @observable
  subtitle: string;
  @observable
  query_id: string;
  @observable
  id: string;
  @observable
  relevance: string;
  @observable
  solution_resource_type: string;
  @observable
  source: SolutionSource;
  @observable
  source_type: string;
  @observable
  source_id: string;
  @observable
  was_positive: boolean;

  constructor(
    query_id: any,
    id: string,
    content: string,
    metadata: { title: string; url: string; subtitle: string },
    relevance: string,
    solution_resource_type: string,
    source: SolutionSource,
    source_type: string,
    source_id: string,
    was_positive: boolean
  ) {
    this.query_id = query_id;
    this.id = id;
    this.content = content;
    this.title = metadata.title;
    this.url = metadata.url;
    this.subtitle = metadata.subtitle === metadata.title ? '' : metadata.subtitle;
    this.relevance = relevance;
    this.solution_resource_type = solution_resource_type;
    this.source = source;
    this.source_type = source_type;
    this.source_id = source_id;
    this.was_positive = was_positive;
  }
}
