import bind from 'bind-decorator';
import flat from 'flat';
import { cloneDeep, get, isEmpty, isNil, isObject, merge, mergeWith, omitBy, set } from 'lodash';
import { action, computed, observable } from 'mobx';
import { ChannelOptionPluginTypes } from 'src/routes/InterfaceSetup/configOptions/ChannelOptionPluginTypes';
import { GREETING_PROMPT_LIMIT } from 'src/routes/InterfaceSetup/configOptions/GreetingTab/constants';
import { InstanceTab } from 'src/routes/InterfaceSetup/constants';
import { recordErrors } from 'src/shared/util/recordErrors';
import AnnouncementConfiguration from 'src/store/models/AnnouncementConfiguration';
import { GenericSupportOption } from 'src/store/models/GenericSupportOption';
import { GreetingPrompt } from 'src/store/models/GreetingPrompt';
import LinkSupportOption from 'src/store/models/LinkSupportOption';
import PhoneNumber from 'src/store/models/PhoneNumber';
import PhoneSupportOption from 'src/store/models/PhoneSupportOption';
import SupportOptionSchedule, { DEFAULT_SCHEDULE, IHours, ISchedule } from 'src/store/models/SupportOptionSchedule';
import AvatarOptions from '../models/AvatarOptions';
import SolutionsSettings from '../models/SolutionsSettings';
import SurveySettings from '../models/SurveySettings';
import { BaseSolvvyUiInstance } from './BaseSolvvyUiInstance';
import { customMergeSuggestions, inferTypeFromPluginName, SUPPORT_OPTION_TYPES } from './Util';

function addZero(value) {
  if (value.length < 2 && parseInt(value, 10) < 10) {
    return '0' + value;
  }
  return value;
}

export class ConversationalUiInstance extends BaseSolvvyUiInstance {
  static createRaw(instance: ConversationalUiInstance) {
    const myRaw = instance.raw;

    const newRaw = mergeWith(
      {},
      myRaw,
      BaseSolvvyUiInstance.createRaw(instance),
      {
        announcement: instance.enableAnnouncement
          ? {
              title: instance.announcementConfiguration.title,
              message: instance.announcementConfiguration.message,
              url: instance.announcementConfiguration.url,
              linkText: instance.announcementConfiguration.linkText,
              autoShow: instance.announcementConfiguration.autoShow,
              styleLinkAsButton: instance.announcementConfiguration.styleLinkAsButton,
              countClickAsInstantResolution: instance.announcementConfiguration.countClickAsInstantResolution,
              imageUrl: instance.announcementConfiguration.imageUrl,
              showImage: instance.announcementConfiguration.imageUrl && instance.announcementConfiguration.showImage
            }
          : null
      },
      customMergeSuggestions
    );

    if (!newRaw.text) {
      newRaw.text = {};
    }

    if (!newRaw.feedOptions) {
      newRaw.feedOptions = { text: {} };
    }

    // get the text object from the raw v5 instance
    const textInstance = cloneDeep(myRaw.text || {});
    const feedText = cloneDeep(myRaw.feedOptions.text);

    // get the new Text from the text overrides for v5 since it has different strings in the configuration
    const newText = merge({}, textInstance, this.getRawTextFromOverrides(instance.textOverrides));
    const newFeedText = merge({}, feedText, this.getRawFeedTextFromOverrides(instance.resolveFeedTextOverrides));

    if (instance.enableSupportOptionOrgSetting) {
      const channelOptions = this.getRawSupportOptions(instance, myRaw.feedOptions.channelOptions).supportOptions;
      const channelOptionSchedules = this.getRawSupportOptionSchedules(instance).supportOptionSchedules;
      Object.assign(newRaw, { text: newText });
      Object.assign(newRaw.feedOptions, { channelOptions, channelOptionSchedules, text: newFeedText });
    } else {
      Object.assign(newRaw, { text: newText });
      Object.assign(newRaw.feedOptions, { channelOptions: null, channelOptionSchedules: null, text: newFeedText });
    }

    const newGreetings = GreetingPrompt.createRaw(myRaw.feedOptions.greetings, instance.greetingPrompts);
    Object.assign(newRaw.feedOptions, {
      ...(!isNil(newGreetings) && { greetings: newGreetings })
    });

    const newSolutionsSettings = SolutionsSettings.createRaw(instance.solutionsSettings);
    if (!isEmpty(omitBy(newSolutionsSettings, isNil))) {
      set(newRaw, 'solutionsSettings', { ...newSolutionsSettings });
    }

    const newSurveySettings = SurveySettings.createRaw(instance.surveySettings);
    set(newRaw, 'surveySettings', { ...newSurveySettings });

    set(newRaw, 'avatar', AvatarOptions.createRaw(instance.avatar));

    return newRaw;
  }

  static convertTextOverrideKeyToRawUiPath(textOverrideKey: string): string {
    const regexMatch = textOverrideKey.match(/(\w+)\.(.*)/);
    if (regexMatch) {
      const groupKey = regexMatch[1];
      const stringKey = regexMatch[2];
      const prefix = groupKey === 'root' ? '' : `${groupKey}.`;
      return prefix + `text.${stringKey}`;
    }
    return textOverrideKey;
  }

  static convertTextOverrideToRawTextPathV5(textOverrideKey: string): string {
    return textOverrideKey;
  }

  static getRawUiFromTextOverrides(textOverrides) {
    const ui = {};

    for (const [key, value] of Array.from(textOverrides.entries()) as any) {
      const path = this.convertTextOverrideKeyToRawUiPath(key);
      set(ui, path, value);
    }
    return ui;
  }

  @action
  static getRawTextFromOverrides(textOverrides) {
    const text = {};

    for (const [key, value] of Array.from(textOverrides.entries()) as any) {
      const path = this.convertTextOverrideToRawTextPathV5(key);
      set(text, path, value);
    }
    return text;
  }

  @action
  static getRawFeedTextFromOverrides(feedTextOverrides) {
    const feedText = {};
    for (const [key, value] of Array.from(feedTextOverrides.entries()) as any) {
      set(feedText, key, value);
    }
    return feedText;
  }

  static removeTextFromUi(rawUi) {
    if (rawUi) {
      delete rawUi.text;
      for (const value of Object.entries(rawUi)) {
        if (isObject(value)) {
          delete (value as any).text;
        }
      }
    }
  }

  static getRawSupportOptions(instance: ConversationalUiInstance, existingRawSupportOptions?: any[] | null) {
    const supportOptions: any[] = cloneDeep(existingRawSupportOptions || []);

    (instance.supportOptions || []).forEach(
      (supportOption: GenericSupportOption & PhoneSupportOption & LinkSupportOption) => {
        // find corresponding raw support option
        const index = supportOptions.findIndex(so => so.id === supportOption.id);

        const text = supportOption.getRawText();

        const dashboardOptions: any = {};

        if (supportOption.phoneNumbers) {
          set(
            dashboardOptions,
            'phoneNumbers',
            supportOption.phoneNumbers.map((phoneNumber: PhoneNumber) => ({
              number: phoneNumber.number,
              title: phoneNumber.title
            }))
          );
        }

        set(dashboardOptions, 'alwaysOffline', supportOption.alwaysOffline);
        set(dashboardOptions, 'workflowsOnly', supportOption.workflowsOnly);
        set(dashboardOptions, 'useAgentAvatarIfAvailable', supportOption.useAgentAvatarIfAvailable);

        if (supportOption.normalizedPluginName === ChannelOptionPluginTypes.Link) {
          set(dashboardOptions, 'link', supportOption.url);
        }

        const supportOptionTextPath = 'text';
        set(dashboardOptions, supportOptionTextPath, text);
        supportOptions[index].options = merge({}, supportOptions[index].options, dashboardOptions);
        // phoneNumbers is an array so needs to be set
        if (
          ConversationalUiInstance.isPhoneType(supportOptions[index].type) &&
          supportOptions[index].options.phoneNumbers
        ) {
          set(supportOptions[index].options, 'phoneNumbers', dashboardOptions.phoneNumbers);
        }
      }
    );

    return { supportOptions };
  }

  static isPhoneType(type: string) {
    return type === ChannelOptionPluginTypes.Phone || type === 'V4PhoneSupportOption';
  }

  /*
    If no existing support option is present then the schedule will only be saved if the user has selected to save to support option schedule.
  */
  static getRawSupportOptionSchedules(instance: ConversationalUiInstance) {
    const supportOptionSchedules: SupportOptionSchedule[] = [];

    (instance.supportOptions || []).forEach((supportOption: any) => {
      const schedule = this.getFormattedSchedule(supportOption.schedule.schedule);

      if (!isEmpty(schedule)) {
        supportOptionSchedules.push({
          schedule,
          timeZone: supportOption.schedule.timeZone,
          scheduledPluginIds: supportOption.schedule.scheduledPluginIds
        });
      }
    });

    return {
      supportOptionSchedules
    };
  }

  /*
    Filter out the closed days and retrieve the hours in 24hr format to be saved in database. Every time a new schedule is always saved to database
  */
  static getFormattedSchedule(supportOptionSchedule) {
    const formatStartDate = startHourArr => `${addZero(startHourArr[0])}:${startHourArr[1]}`;
    const formatHours = (hours: IHours[]) =>
      hours.map(hour => {
        const startHourArr = hour.start.split(':');
        return {
          enabled: formatStartDate(startHourArr),
          disabled: hour.end
        };
      });

    const filteredDisabledScheduleDays: ISchedule[] = supportOptionSchedule.filter(
      (schedule: ISchedule) => !schedule.closed
    );
    const newSchedule: Partial<ISchedule | any[]> = [];

    for (const schedule of filteredDisabledScheduleDays) {
      if (schedule.hours.length > 0) {
        newSchedule.push({
          dayOfWeek: schedule.dayOfWeek,
          hours: formatHours(schedule.hours)
        });
      }
    }
    return newSchedule;
  }

  @observable
  connectorIdForTicketCreation?: string;

  @observable
  enableAnnouncement: boolean;

  @observable
  announcementConfiguration: AnnouncementConfiguration;

  @observable
  avatar: AvatarOptions;

  @observable
  solutionsSettings: SolutionsSettings;

  @observable
  surveySettings: SurveySettings;

  @observable
  hasCustomCss: boolean = false;

  @observable
  supportOptions: GenericSupportOption[];

  @observable
  textOverrides = new Map();

  @observable
  resolveFeedTextOverrides = new Map();

  @observable
  greetingPrompts: GreetingPrompt[] = [];

  constructor(public raw: any, private _orgTimeZone: string, _orgSettings: any, public configuration) {
    super(raw, _orgSettings, configuration);
    this.applyFromRaw(raw);
  }

  @action
  applyFromRaw(raw) {
    try {
      super.applyFromRaw(raw);

      // announcements
      this.retrieveAnnouncementDetails(raw);

      // solutions settings
      this.retrieveSolutionsSettings(raw);

      // survey settings
      this.retrieveSurveySettings(raw);

      // avatar
      this.retrieveAvatar(raw);

      // check supportOptions or feedOptions since v5 has support options inside feedOptions
      if (isNil(raw.feedOptions) || isEmpty(raw.feedOptions)) {
        raw.feedOptions = {};
      }
      if (
        (!isEmpty(raw.supportOptions) || !isEmpty(raw.feedOptions.channelOptions)) &&
        this.enableSupportOptionOrgSetting
      ) {
        // support options
        const rawSupportOptions = raw.feedOptions.channelOptions || [];
        const rawSupportOptionSchedules = raw.feedOptions.channelOptionSchedules || [];

        this.hasSupportOptions = true;
        this.retrieveSupportOptionDetails(rawSupportOptions, rawSupportOptionSchedules);
      }

      this.setV5TextOverridesFromRaw(raw);
      this.setResolveFeedTextOverridesFromRaw(raw);

      this.greetingPrompts = this.applyGreetingPromptsFromRaw(raw.feedOptions.greetings);
    } catch (e) {
      recordErrors(e);
    }
  }

  retrieveAnnouncementDetails(raw) {
    this.enableAnnouncement = !isEmpty(raw.announcement);
    const announcementConfiguration = raw.announcement;
    this.announcementConfiguration = new AnnouncementConfiguration();
    this.announcementConfiguration.title = get(
      announcementConfiguration,
      'title',
      this.announcementConfiguration.title
    );
    this.announcementConfiguration.message = get(
      announcementConfiguration,
      'message',
      this.announcementConfiguration.message
    );
    this.announcementConfiguration.url = get(announcementConfiguration, 'url', this.announcementConfiguration.url);
    this.announcementConfiguration.linkText = get(
      announcementConfiguration,
      'linkText',
      this.announcementConfiguration.linkText
    );
    this.announcementConfiguration.styleLinkAsButton = get(
      announcementConfiguration,
      'styleLinkAsButton',
      this.announcementConfiguration.styleLinkAsButton
    );
    this.announcementConfiguration.countClickAsInstantResolution = get(
      announcementConfiguration,
      'countClickAsInstantResolution',
      this.announcementConfiguration.countClickAsInstantResolution
    );
    this.announcementConfiguration.imageUrl = get(
      announcementConfiguration,
      'imageUrl',
      this.announcementConfiguration.imageUrl
    );
    this.announcementConfiguration.showImage =
      this.announcementConfiguration.imageUrl &&
      get(announcementConfiguration, 'showImage', this.announcementConfiguration.showImage);
  }

  retrieveSupportOptionDetails(rawSupportOptions, rawSupportOptionSchedules) {
    this.supportOptions = [];

    rawSupportOptions.forEach((rawSupportOption: any) => {
      const { id, type: pluginName } = rawSupportOption;

      const type = inferTypeFromPluginName(pluginName);
      let option;
      switch (type) {
        case SUPPORT_OPTION_TYPES.PHONE:
        case SUPPORT_OPTION_TYPES.SMS:
          option = new PhoneSupportOption(rawSupportOption, this.uiMajorVersion);
          break;
        case SUPPORT_OPTION_TYPES.LINK:
          option = new LinkSupportOption(rawSupportOption, this.uiMajorVersion);
          break;
        default:
          option = new GenericSupportOption(rawSupportOption, this.uiMajorVersion);
          break;
      }

      const supportOptionSchedule = rawSupportOptionSchedules.find(schedule =>
        schedule.scheduledPluginIds.includes(id)
      );

      if (!supportOptionSchedule) {
        option.schedule.schedule = DEFAULT_SCHEDULE;
        option.schedule.timeZone = this._orgTimeZone;
      } else {
        option.schedule.schedule = this.retrieveFormattedSupportHourSchedule(supportOptionSchedule);
        option.schedule.timeZone = supportOptionSchedule.timeZone;
      }
      option.schedule.scheduledPluginIds = [id];

      this.supportOptions.push(option);
    });
  }

  retrieveSolutionsSettings(raw) {
    const solutionsSettings = raw.solutionsSettings;
    const { snippetLength, displayLimit, showRelatedArticles, contactSupportPriority } = (solutionsSettings ||
      {}) as SolutionsSettings;
    this.solutionsSettings = new SolutionsSettings({
      snippetLength,
      displayLimit,
      showRelatedArticles,
      contactSupportPriority
    });
  }

  retrieveSurveySettings(raw) {
    const surveySettings = raw.surveySettings;
    const { show, defaultWorkflowId, triggers } = (surveySettings || {}) as SurveySettings;
    this.surveySettings = new SurveySettings({ show, defaultWorkflowId, triggers });
  }

  retrieveAvatar(raw) {
    const avatar = raw.avatar;
    const { botUrl, templateBotUrl, agentUrl, templateAgentUrl, show } = (avatar || {}) as AvatarOptions;
    this.avatar = new AvatarOptions({ botUrl, templateBotUrl, agentUrl, templateAgentUrl, show });
  }

  applyGreetingPromptsFromRaw(instanceRawPrompts?: GreetingPrompt[]) {
    return instanceRawPrompts && instanceRawPrompts.length > 0
      ? instanceRawPrompts.map(rawPrompt => new GreetingPrompt(rawPrompt))
      : GreetingPrompt.defaultGreetings;
  }

  get warningTabs(): InstanceTab[] {
    const isCustomAvatar = this.avatar.show && isNil(this.avatar.templateBotUrl);

    const warnings: InstanceTab[] = [];

    this.greetingPrompts.some(greetingPrompt => isEmpty(greetingPrompt.prompt) || greetingPrompt.prompt === ' ') &&
      warnings.push(InstanceTab.GREETING);

    this.announcementConfiguration.showImage &&
      isEmpty(this.announcementConfiguration.imageUrl) &&
      warnings.push(InstanceTab.WELCOME_PANEL);

    isCustomAvatar && isEmpty(this.avatar.botUrl) && warnings.push(InstanceTab.LOOK_AND_FEEL);

    this.surveySettings.show &&
      isEmpty(this.surveySettings.defaultWorkflowId) &&
      warnings.push(InstanceTab.SURVEY_SETTINGS);

    return warnings;
  }

  get supportOptionErrorCount() {
    if (isNil(this.supportOptions)) {
      return 0;
    }

    let buttonTextErrorCount = 0;
    let phoneNumbersErrorCount = 0;
    let launchButtonErrorCount = 0;
    let linkUrlErrorCount = 0;

    this.supportOptions.forEach((supportOption: GenericSupportOption & PhoneSupportOption & LinkSupportOption) => {
      const isButtonTextError = supportOption.button.length === 1 && supportOption.button === ' ';
      const isLaunchButtonTextError = supportOption.launchButton?.length === 1 && supportOption.launchButton === ' ';
      const isPhoneError =
        supportOption.type === SUPPORT_OPTION_TYPES.PHONE &&
        (supportOption.phoneNumbers?.length === 0 || isEmpty(supportOption.phoneNumbers[0].number));
      const isLinkUrlError = supportOption.type === SUPPORT_OPTION_TYPES.LINK && isEmpty(supportOption.url);

      buttonTextErrorCount += isButtonTextError ? 1 : 0;
      phoneNumbersErrorCount += isPhoneError ? 1 : 0;
      launchButtonErrorCount += isLaunchButtonTextError ? 1 : 0;
      linkUrlErrorCount += isLinkUrlError ? 1 : 0;
    });

    return buttonTextErrorCount + phoneNumbersErrorCount + launchButtonErrorCount + linkUrlErrorCount;
  }

  setTextOverridesFromRaw(raw: { ui: any }) {
    const flattened = flat(raw.ui || {});

    for (const [key, value] of Object.entries(flattened)) {
      const matchedText = key.match(/(^|(\w+)\.)text\.(.+)/);
      if (matchedText) {
        const groupKey = matchedText[2] || 'root';
        const stringKey = matchedText[3];
        const fullKey = `${groupKey}.${stringKey}`.replace('.text.', '.');
        this.textOverrides.set(fullKey, value);
      }
    }
  }

  setV5TextOverridesFromRaw(raw: { text: any }) {
    const flattened = flat(raw.text || {});

    for (const [key, value] of Object.entries(flattened)) {
      this.textOverrides.set(key, value);
    }
  }

  setResolveFeedTextOverridesFromRaw(raw: { feedOptions: any }) {
    const flattened = flat(raw.feedOptions.text || {});
    for (const [key, value] of Object.entries(flattened)) {
      this.resolveFeedTextOverrides.set(key, value);
    }
  }

  retrieveFormattedSupportHourSchedule(supportOptionSchedule: { schedule: any[] }) {
    return DEFAULT_SCHEDULE.map(defaultDaySchedule => {
      const currentDaySchedule = supportOptionSchedule.schedule.find(
        scheduleInfo => scheduleInfo.dayOfWeek === defaultDaySchedule.dayOfWeek
      );
      if (!isEmpty(currentDaySchedule)) {
        currentDaySchedule!.hours = currentDaySchedule.hours.map(hour => ({ start: hour.enabled, end: hour.disabled }));
        currentDaySchedule!.closed = false;
        currentDaySchedule.step = defaultDaySchedule.step;
        currentDaySchedule.id = defaultDaySchedule.id;
        return currentDaySchedule;
      } else {
        return defaultDaySchedule;
      }
    });
  }

  @bind
  @action
  addGreetingPrompt() {
    try {
      if (this.greetingPrompts.length === GREETING_PROMPT_LIMIT) {
        throw new Error(`You can only have ${GREETING_PROMPT_LIMIT} greeting prompts.`);
      }

      const index = this.greetingPrompts.length;
      const prompt = GreetingPrompt.defaultPrompts[index] || ' ';
      this.greetingPrompts = [...this.greetingPrompts, new GreetingPrompt({ prompt })];
    } catch (error) {
      recordErrors(error);
    }
  }

  @bind
  @action
  modifyGreetingPrompt(index: number, updatedPrompt: Partial<GreetingPrompt>) {
    this.greetingPrompts[index] = {
      ...this.greetingPrompts[index],
      ...updatedPrompt
    };
  }

  @bind
  @action
  removeGreetingPrompt(index: number) {
    this.greetingPrompts = this.greetingPrompts.filter((_, i) => i !== index);
  }

  @bind
  @action
  updateColor(color: string, path: string) {
    set(this.colors, path, color);
  }

  @computed
  get showAvatar() {
    return Boolean(this.avatar && this.avatar.show);
  }
}
