import gql from 'graphql-tag';
import { pick } from 'lodash';
import moment from 'moment';
import { Api } from './api';

export interface IIntent {
  id: string;
  name: string;
  clientEditable: boolean;
  details?: string;
  draft?: boolean;
  createdBy?: string;
  orgPredictionRules?: IPredictionRule[];
  activeLearningQueries?: IActiveLearningQuery[];
  examples?: IExample[];
  countActiveLearningQueries?: number;
  countLabelledQueries?: number;
  lastLabelledQuery?: string;
  matchedQueries?: number;
  matchedQueriesPercent?: number;
}

export interface IExample {
  content: string;
  createdAt: string;
  id: string;
  indicator: boolean;
  updatedAt: string;
  user: string;
}

export interface IPredictionRule {
  id: string;
  condition: 'MATCH' | 'MISS';
  createdBy: string;
  details: string;
  name: string;
  regexes: string[];
  score: '1' | '0';
  texts: string[];
  updatedBy?: string;
  __typename?: 'orgPredictionRules';
}

export interface IIntentCollection {
  id: string;
  name: string;
  details?: string;
  intents: IIntent[];
}

export interface ICreatePredictionRuleInput {
  condition: 'MATCH' | 'MISS';
  createdBy: string;
  details: string;
  name: string;
  regexes: string[];
  score: string;
  texts: string[];
}

export interface IActiveLearningQuery {
  id: string;
  content: string;
  orgId: number;
}

export interface ICreateReviewJudgementInput {
  categoryId: string;
  indicator: boolean;
}

export interface ILabellingQuery {
  judgement: ICreateReviewJudgementInput;
  queryId: string;
  user: string;
}

export interface IStatistics {
  intent_id: string;
  intent_label: string;
  total: number;
  volume_percent: number;
}

export const predictionRuleUIDataKeys: Array<keyof IPredictionRule> = [
  'id',
  'condition',
  'name',
  'regexes',
  'score',
  'texts',
  '__typename' // this is added automatically by the graphql layer
];

export class IntentService {
  constructor(private _api: Api) {}

  async getIntentCollections(orgId: number): Promise<IIntentCollection[]> {
    const response = await this._api.gqlQuery({
      query: gql`
        query getIntentCollections($orgId: Int!) {
          intentCollectionsProxy(orgId: $orgId) {
            id
            name
            details
            intents {
              id
              name
              details
              clientEditable
              draft
              createdBy
              createdAt
            }
          }
        }
      `,
      variables: { orgId },
      fetchPolicy: 'network-only'
    });

    return response.data.intentCollectionsProxy;
  }

  async getIntentExtras(intentId: string, orgId: number): Promise<any> {
    const response = await this._api.gqlQuery({
      query: gql`
        query getIntentExtras($orgId: Int!, $intentId: String!) {
          getIntentExtrasProxy(orgId: $orgId, intentId: $intentId) {
            id
            name
            examples {
              content
              createdAt
              id
              indicator
              intent {
                id
                name
              }
              orgId
              updatedAt
              user
            }
            activeLearningQueries {
              id
              content
              orgId
            }
            orgPredictionRules {
              ${predictionRuleUIDataKeys.join('\n')}
            }
            countActiveLearningQueries
          }
        }
      `,
      variables: { orgId, intentId },
      fetchPolicy: 'network-only'
    });

    return response.data.getIntentExtrasProxy;
  }

  async getOrgPredictionRules(orgId: number): Promise<any> {
    const response = await this._api.gqlQuery({
      query: gql`
        query getOrgPredictionRules($orgId: Int!) {
          getOrgPredictionRulesProxy(orgId: $orgId) {
            id
            orgPredictionRules {
              ${predictionRuleUIDataKeys.join('\n')}
            }
          }
        }
      `,
      variables: { orgId },
      fetchPolicy: 'network-only'
    });

    return response.data.getOrgPredictionRulesProxy;
  }

  async getIntentLabelledStats(intentId: string, orgId: number): Promise<any> {
    const response = await this._api.gqlQuery({
      query: gql`
        query getIntentLabelledStats($intentId: String!, $orgId: Int!) {
          getIntentLabelledStatsProxy(intentId: $intentId, orgId: $orgId) {
            id
            name
            countLabelledQueries
            lastLabelledQuery
          }
        }
      `,
      variables: { orgId, intentId },
      fetchPolicy: 'network-only'
    });

    return response.data.getIntentLabelledStatsProxy;
  }

  async createIntent(orgId: number, createdBy: string, name: string, intentCollectionId: string, description: string) {
    const response = await this._api.gqlMutate({
      mutation: gql`
        mutation createIntent(
          $clientEditable: Boolean!
          $createdBy: String!
          $details: String!
          $intentCollectionId: ID
          $name: String!
          $source: IntentSourceType!
          $orgId: Int!
        ) {
          createIntentProxy(
            clientEditable: $clientEditable
            createdBy: $createdBy
            details: $details
            intentCollectionId: $intentCollectionId
            name: $name
            source: $source
            orgId: $orgId
          ) {
            id
            name
            details
          }
        }
      `,
      variables: {
        orgId,
        clientEditable: true,
        createdBy,
        details: description,
        intentCollectionId,
        name,
        source: 'DATA'
      }
    });

    return response.data.createIntentProxy;
  }

  async updateIntent(intent: IIntent, orgId: number) {
    const response = await this._api.gqlMutate({
      mutation: gql`
        mutation updateIntent($id: ID!, $details: String, $name: String, $orgId: Int!) {
          updateIntentProxy(id: $id, details: $details, name: $name, orgId: $orgId) {
            id
            name
            details
          }
        }
      `,
      variables: { id: intent.id, name: intent.name, details: intent.details || '', orgId }
    });

    return response.data.intentProxy;
  }

  async setExamples(variables: {
    user: string;
    orgId: number;
    intentId: string;
    exampleInputs: Array<{ content: string; indicator: boolean }>;
  }) {
    const response = await this._api.gqlMutate({
      mutation: gql`
        mutation setExamples($user: String!, $orgId: Int!, $intentId: ID!, $exampleInputs: [ExampleInput!]!) {
          setExamplesProxy(user: $user, orgId: $orgId, intentId: $intentId, exampleInputs: $exampleInputs) {
            id
          }
        }
      `,
      variables
    });

    return response.data.setExamplesProxy;
  }

  async createRules(orgId: number, categoryId: string, newRules: IPredictionRule[]) {
    const variableDefinitions: string[] = [];
    const ruleVariables: Record<string, ICreatePredictionRuleInput> = {};
    let createRulesGql = '';
    const ruleIdMap: Record<string, string> = {};

    newRules.forEach((rule, index) => {
      const ruleVarName = `rule_${index}`;
      const aliasName = `create_${ruleVarName}`;

      variableDefinitions.push(`$${ruleVarName}: CreatePredictionRuleInput!`);
      ruleVariables[ruleVarName] = pick(rule, [
        'condition',
        'createdBy',
        'details',
        'name',
        'regexes',
        'score',
        'texts'
      ]);

      createRulesGql += `
        ${aliasName}: createPredictionRuleProxy(orgId: $orgId, categoryId: $categoryId, predictionRule: $${ruleVarName}) {
          id
        }
      `;

      ruleIdMap[rule.id] = aliasName;
    });

    const { data } = await this._api.gqlMutate({
      mutation: gql`
        mutation createPredictionRuleProxy(
          $categoryId: ID!
          $orgId: Int!
          ${variableDefinitions.join(', ')}
        ) {
          ${createRulesGql}
        }
      `,
      variables: {
        categoryId,
        orgId,
        ...ruleVariables
      }
    });

    Object.entries(ruleIdMap).forEach(([ruleId, aliasName]) => {
      ruleIdMap[ruleId] = data[aliasName].id;
    });

    return ruleIdMap;
  }

  updateRules(orgId: number, updatedBy: string, updatedRules: IPredictionRule[]) {
    let updateRulesGql = '';
    const variableDefinitions: string[] = [];
    const ruleVarMap: Record<string, any> = {};

    updatedRules.forEach((rule, index) => {
      const ruleAttrVarName = `ruleAttr_${index}`;
      const ruleIdVarName = `ruleId_${index}`;
      const alias = `update_${ruleAttrVarName}`;

      variableDefinitions.push(`$${ruleIdVarName}: ID!, $${ruleAttrVarName}: UpdatePredictionRuleInput!`);
      ruleVarMap[ruleIdVarName] = rule.id;
      ruleVarMap[ruleAttrVarName] = pick(rule, ['condition', 'name', 'regexes', 'score', 'texts']);
      ruleVarMap[ruleAttrVarName].updatedBy = updatedBy;
      ruleVarMap.orgId = orgId;

      updateRulesGql += `
        ${alias}: updatePredictionRuleProxy( orgId: $orgId, predictionRuleId: $${ruleIdVarName}, predictionRuleAttributes: $${ruleAttrVarName}) {
          id
        }
      `;
    });

    return this._api.gqlMutate({
      mutation: gql`
        mutation updatePredictionRuleProxy(
          $orgId: Int!
          ${variableDefinitions.join(', ')}
        ) {
          ${updateRulesGql}
        }
      `,
      variables: ruleVarMap
    });
  }

  async moveIntent(orgId: number, initialRuleIds: string[], newRuleIds: string[]) {
    let moveIntentGql = '';
    const variableDefinitions: string[] = [];
    const ruleVarMap: Record<string, any> = {};

    let initialFirstRule = initialRuleIds[0];
    const newFirstRule = newRuleIds[0];

    if (initialFirstRule !== newFirstRule) {
      // From the existing rule, the first rule which is not deleted will be on the top
      const firstNonDeletedRule = initialRuleIds.find(ruleId => newRuleIds.includes(ruleId));

      if (!firstNonDeletedRule) {
        // All the old rules are deleted. Even though the new rules would be moved, they will be created in shown order
        return;
      }

      initialFirstRule = firstNonDeletedRule;
      variableDefinitions.push(`$predictionRuleId: ID!, $between: BetweenPredictionRulesInput!`);
      ruleVarMap.predictionRuleId = newFirstRule;
      ruleVarMap.between = { before: firstNonDeletedRule };

      moveIntentGql += `
      setFirst: movePredictionRuleProxy(orgId: $orgId, predictionRuleId: $predictionRuleId, between: $between) {
        id
      }
    `;
    }

    let previousRule = initialFirstRule;
    newRuleIds.forEach((ruleId, index) => {
      if (index === 0 && previousRule === ruleId) {
        return;
      }

      const ruleIdVarName = `ruleId_${index}`;
      const betweenVarName = `between_${index}`;
      const alias = `move_${betweenVarName}`;

      variableDefinitions.push(`$${ruleIdVarName}: ID!, $${betweenVarName}: BetweenPredictionRulesInput!`);
      ruleVarMap[ruleIdVarName] = ruleId;
      ruleVarMap[betweenVarName] = { after: previousRule };
      previousRule = ruleId;

      moveIntentGql += `
        ${alias}: movePredictionRuleProxy(orgId: $orgId, predictionRuleId: $${ruleIdVarName}, between: $${betweenVarName}) {
          id
        }
      `;
    });

    ruleVarMap.orgId = orgId;

    try {
      await this._api.gqlMutate({
        mutation: gql`
        mutation movePredictionRuleProxy(
          $orgId: Int!
          ${variableDefinitions.join(', ')}
        ) {
          ${moveIntentGql}
        }
      `,
        variables: ruleVarMap
      });
    } catch (err) {
      // tslint:disable-next-line: no-console
      console.log(err);
    }
  }

  async deleteRules(orgId: number, deletedRuleIds: string[]) {
    let deleteRulesGql = '';
    const variableDefinitions: string[] = [];
    const ruleVarMap: Record<string, string | number> = {};

    deletedRuleIds.forEach((ruleId, index) => {
      const ruleVarName = `ruleId_${index}`;
      variableDefinitions.push(`$${ruleVarName}: ID!`);
      ruleVarMap[ruleVarName] = ruleId;

      deleteRulesGql += `
        delete_${ruleVarName}: deletePredictionRuleProxy( orgId: $orgId, predictionRuleId: $${ruleVarName}) {
          status
        }
      `;
    });

    ruleVarMap.orgId = orgId;
    return this._api.gqlMutate({
      mutation: gql`
        mutation deletePredictionRuleProxy(
          $orgId: Int!
          ${variableDefinitions.join(', ')}
        ) {
          ${deleteRulesGql}
        }
      `,
      variables: ruleVarMap
    });
  }

  async labelActiveLearningQueries(query: ILabellingQuery, orgId: number) {
    const response = await this._api.gqlMutate({
      mutation: gql`
        mutation labelActiveLearningQuery(
          $judgement: CreateReviewJudgementInput!
          $queryId: ID!
          $user: String!
          $orgId: Int!
        ) {
          labelActiveLearningQueryProxy(judgement: $judgement, queryId: $queryId, user: $user, orgId: $orgId) {
            id
          }
        }
      `,
      variables: { judgement: query.judgement, queryId: query.queryId, user: query.user, orgId }
    });

    return response.data.labelActiveLearningQueryProxy;
  }

  async getIntentStatistics(options): Promise<IStatistics[]> {
    options.start_date = moment(options.start_date).format('YYYY-MM-DD');
    options.end_date = moment(options.end_date).format('YYYY-MM-DD');
    const { data } = await this._api.get('/v1/events/intent-details', options);
    return data;
  }

  async notifyTrainingCompleted(options: {
    userId: number;
    orgId: number;
    intentId: string;
    intentName: string;
  }): Promise<any> {
    const response = await this._api.gqlMutate({
      mutation: gql`
        mutation notifyOnIntentTrainingCompletion(
          $intentId: String!
          $intentName: String!
          $userId: Int!
          $orgId: Int!
        ) {
          notifyOnIntentTrainingCompletion(
            intent_id: $intentId
            intent_name: $intentName
            user_id: $userId
            org_id: $orgId
          ) {
            user_id
          }
        }
      `,
      variables: options
    });

    return response.data.notifyOnIntentTrainingCompletion;
  }

  async getIntents(orgId: number): Promise<IIntentCollection[]> {
    const response = await this._api.gqlQuery({
      query: gql`
        query getIntents($orgId: Int!) {
          intentsProxy(orgId: $orgId) {
            id
            intents {
              id
              name
            }
          }
        }
      `,
      variables: { orgId },
      fetchPolicy: 'network-only'
    });

    return response.data.intentsProxy;
  }
}

const intentService = api => new IntentService(api);

export default intentService;
