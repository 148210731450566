import gql from 'graphql-tag';
import { allUsingApi } from './util';

const getAllOrgMessages = api => {
  return api.gqlQuery({
    query: gql`
      query {
        allOrgMessages {
          edges {
            node {
              id
              created_at
              subject
              is_active
              message_body
            }
          }
        }
      }
    `
  });
};

const getAllNewOrgMessages = (api, created_at = null) => {
  return api.gqlQuery({
    query: gql`
      query {
        allNewOrgMessages(created_at: ${created_at}) {
          id
          created_at
          subject
          is_active
          message_body
        }
      }
    `
  });
};

export const messagesService = api => {
  return allUsingApi(api, {
    getAllOrgMessages,
    getAllNewOrgMessages
  });
};

export default messagesService;
