import V5_UI_OPTION_DEFAULTS from '@solvvy/locales/en/v5-ui/v5-ui/';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';

export class GreetingPrompt {
  static toRaw({ prompt, delay }: GreetingPrompt) {
    return {
      prompt,
      ...(!isNil(delay) ? { delay } : {})
    };
  }

  static createRaw(rawGreetings, instanceGreetings: GreetingPrompt[]) {
    return isEqual(
      instanceGreetings,
      GreetingPrompt.defaultGreetings.map(greeting => GreetingPrompt.toRaw(greeting))
    )
      ? undefined
      : instanceGreetings.map(greeting => GreetingPrompt.toRaw(greeting));
  }

  static get defaultGreetings() {
    return GreetingPrompt.defaultPrompts.map(prompt => new GreetingPrompt({ prompt }));
  }

  static get defaultPrompts(): string[] {
    return V5_UI_OPTION_DEFAULTS.greetingCard.prompts || [];
  }

  prompt: string;
  delay?: number;

  constructor({ prompt, delay }: GreetingPrompt) {
    this.prompt = prompt;
    if (!isNil(delay)) {
      this.delay = delay;
    }
  }
}
