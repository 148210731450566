import { EXTEND_SESSION_HEADER } from '../store/authStore';
import envService from './envService';
import { allUsingApi } from './util';

const loadExportQueries = (api, orgId, periodicRefreshing) => {
  const headers = {};

  // if doing a periodic a refresh, disable extending the session
  if (periodicRefreshing) {
    headers[EXTEND_SESSION_HEADER] = 'false';
  }

  return api.get(
    '/v1/export-queries-requests',
    {
      org_id: orgId
    },
    {
      headers
    }
  );
};

const postNewExport = (api, newExport) => {
  return api.post(`/v1/export-queries-requests/`, newExport);
};

const getDownloadUrl = (orgId, exportRequestId, token) => {
  const baseUrl = envService.getBaseUrl();
  return `${baseUrl}/v1/export-queries-requests/${exportRequestId}/download?org_id=${orgId}&token=${token}`;
};

export const exportQueriesService = api => {
  return {
    ...allUsingApi(api, { loadExportQueries, postNewExport }),
    getDownloadUrl
  };
};

export default exportQueriesService;
