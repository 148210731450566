import bind from 'bind-decorator';
import omit from 'lodash/omit';
import { action, computed, observable } from 'mobx';
import moment, { Moment } from 'moment-timezone';

import asyncComputed from '../shared/util/asyncComputed';
import { RangeType } from '../shared/util/DateRangeTypes';
import { getNodesFromConnection } from '../shared/util/format';
import { insightsService } from './../services/index';
import { OrgStore } from './orgStore';

const RANGE_TO_DAYS_AGO = {
  [RangeType.PAST_WEEK]: 14,
  [RangeType.PAST_MONTH]: 30
};
export const DATE_ONLY_FORMAT = 'YYYY-MM-DD';
const CHART_COLORS = ['#724784', '#EF4771', '#66E1DA', '#00A194', '#FED178'];

export class InsightsStore {
  @observable
  selectedCustomStartDay?: Moment;
  @observable
  selectedCustomEndDay?: Moment;
  @observable
  selectedDateRangeType: RangeType = RangeType.PAST_MONTH;
  @observable
  updatedSelectedDateRangeType = false;
  @observable
  displayCategoryDetails = false;
  @observable
  selectedCategoryId = '';
  @observable
  selectedCategoryBlock;
  @observable
  aggregationInterval = 'WEEKLY';
  @observable
  filterOutCategoriesForGraph: any = new Set();

  categoriesWithMostQueries = asyncComputed(async () => {
    const {
      data: { categoryQueryStatistics }
    } = await insightsService.queryCategoriesWithMostQueries({
      org_id: this._orgStore.selectedOrgId,
      sort_by: 'VOLUME',
      start_time: this.formattedCustomStartDay,
      end_time: this.formattedCustomEndDay
    });
    return getNodesFromConnection(categoryQueryStatistics);
  });

  categoriesWithHighestSSR = asyncComputed(async () => {
    const {
      data: { categoryQueryStatistics }
    } = await insightsService.queryCategoriesWithMostQueries({
      org_id: this._orgStore.selectedOrgId,
      sort_by: 'SSR',
      start_time: this.formattedCustomStartDay,
      end_time: this.formattedCustomEndDay
    });
    return getNodesFromConnection(categoryQueryStatistics);
  });

  categoriesWithLowestSSR = asyncComputed(async () => {
    const {
      data: { categoryQueryStatistics }
    } = await insightsService.queryCategoriesWithMostQueries({
      org_id: this._orgStore.selectedOrgId,
      sort_by: 'SSR',
      sort_order: 'ASC',
      start_time: this.formattedCustomStartDay,
      end_time: this.formattedCustomEndDay
    });
    return getNodesFromConnection(categoryQueryStatistics);
  });

  biggestMovingCategories = asyncComputed(async () => {
    const {
      data: { biggestCategoryMovements }
    } = await insightsService.topMovingCategories({
      org_id: this._orgStore.selectedOrgId,
      start_time: this.formattedLast14StartDay,
      end_time: this.formattedCustomEndDay
    });
    return getNodesFromConnection(biggestCategoryMovements);
  });

  retrieveTotalQueries = asyncComputed(async () => {
    const {
      data: { queryStatistics }
    } = await insightsService.retrieveTotalQueries({
      org_id: this._orgStore.selectedOrgId,
      start_time: this.formattedCustomStartDay,
      end_time: this.formattedCustomEndDay
    });
    return queryStatistics.total;
  });

  retrieveSingleCategoryDetails = asyncComputed(async () => {
    const {
      data: { node: category }
    } = await insightsService.retrieveSingleCategoryDetails({
      org_id: this._orgStore.selectedOrgId,
      id: this.selectedCategoryId,
      start_time: this.startDateBasedOnCategory,
      end_time: this.formattedCustomEndDay
    });

    return category;
  });

  lineChartData = asyncComputed(async () => {
    const lineChartData: any = new Map();
    const timelineResp = await Promise.all(
      this.categoryWithMostQueryIds.map(
        async (id: string) =>
          await insightsService.retrieveCategoryTimeline({
            org_id: this._orgStore.selectedOrgId,
            id,
            start_time: this.formattedCustomStartDay,
            end_time: this.formattedCustomEndDay,
            aggregation_interval: this.aggregationInterval
          })
      )
    );
    timelineResp.map(
      ({
        data: {
          node: { name, queryStatisticsTrend }
        }
      }) => {
        queryStatisticsTrend.map(summary => {
          const categoryDate = moment(summary.period.start).format('MMM-DD');
          if (!lineChartData.has(categoryDate)) {
            lineChartData.set(categoryDate, {
              date: categoryDate,
              [name]: summary.statistics.count
            });
          } else {
            lineChartData.get(categoryDate)[name] = summary.statistics.count;
          }
          return lineChartData;
        });
      }
    );
    return [...lineChartData.values()];
  });

  constructor(private _orgStore: OrgStore) {}

  @computed
  get effectiveStartMoment(): Moment {
    const daysAgo = RANGE_TO_DAYS_AGO[this.selectedDateRangeType];
    return this.endOfTodayInOrgTimezone
      .clone()
      .subtract(daysAgo, 'days')
      .startOf('day');
  }

  @computed
  get effectiveEndMoment(): Moment {
    return this.endOfTodayInOrgTimezone;
  }

  @computed
  get endOfTodayInOrgTimezone() {
    return moment.tz(this.effectiveTimezone).endOf('day');
  }

  @computed
  get effectiveTimezone() {
    return this._orgStore.orgSettings.timezone;
  }

  @computed
  get formattedCustomStartDay(): string {
    return this.effectiveStartMoment.format(DATE_ONLY_FORMAT);
  }

  @computed
  get startDateBasedOnCategory(): string {
    if (this.selectedCategoryBlock === 'biggestMovers') {
      return this.formattedLast14StartDay;
    }
    return this.formattedCustomStartDay;
  }

  @computed
  get formattedLast14StartDay(): string {
    return this.endOfTodayInOrgTimezone
      .clone()
      .subtract(14, 'days')
      .startOf('day')
      .format(DATE_ONLY_FORMAT);
  }

  @computed
  get formattedCustomEndDay(): string {
    return this.effectiveEndMoment.format(DATE_ONLY_FORMAT);
  }

  @computed
  get categoryWithMostQueryIds() {
    if (this.categoriesWithMostQueries.fulfilled) {
      return this.categoriesWithMostQueries.value.map(categoryInfo => categoryInfo.category.id);
    }
    return [];
  }

  @computed
  get categoryBarData() {
    const data: any = [];
    if (this.categoriesWithMostQueries.fulfilled) {
      this.categoriesWithMostQueries.value.map(categoryInfo => {
        data.push({ name: categoryInfo.category.name, value: categoryInfo.count });
      });
      return data;
    }
    return data;
  }

  @computed
  get chartColors() {
    return CHART_COLORS;
  }

  @computed
  get selectedCategoryDetail() {
    if (this.selectedCategoryBlock === 'ByQuery' && this.categoriesWithMostQueries.fulfilled) {
      return this.categoriesWithMostQueries.value.filter(
        categoryInfo => categoryInfo.category.id === this.selectedCategoryId
      )[0];
    } else if (this.selectedCategoryBlock === 'highestSSR' && this.categoriesWithHighestSSR.fulfilled) {
      return this.categoriesWithHighestSSR.value.filter(
        categoryInfo => categoryInfo.category.id === this.selectedCategoryId
      )[0];
    } else if (this.selectedCategoryBlock === 'lowestSSR' && this.categoriesWithLowestSSR.fulfilled) {
      return this.categoriesWithLowestSSR.value.filter(
        categoryInfo => categoryInfo.category.id === this.selectedCategoryId
      )[0];
    } else if (this.selectedCategoryBlock === 'biggestMovers' && this.biggestMovingCategories.fulfilled) {
      return this.biggestMovingCategories.value.filter(
        categoryInfo => categoryInfo.category.id === this.selectedCategoryId
      )[0];
    }
    return null;
  }

  @computed
  get selectedCategoryName() {
    if (!this.selectedCategoryDetail) {
      return '--';
    }
    return this.selectedCategoryDetail.category.name;
  }

  @computed
  get selectedCategoryQueriesTotal() {
    if (!this.selectedCategoryDetail) {
      return '--';
    }
    if (this.selectedCategoryBlock === 'biggestMovers') {
      return this.selectedCategoryDetail.category.queryStatistics.count
        ? this.selectedCategoryDetail.category.queryStatistics.count
        : '--';
    }
    return this.selectedCategoryDetail.count ? this.selectedCategoryDetail.count : '--';
  }

  @computed
  get selectedCategoryQueriesSupportContacted() {
    if (!this.selectedCategoryDetail) {
      return '--';
    }
    if (this.selectedCategoryBlock === 'biggestMovers') {
      return this.selectedCategoryDetail.category.queryStatistics.supportContactedCount
        ? this.selectedCategoryDetail.category.queryStatistics.supportContactedCount
        : '--';
    }
    return this.selectedCategoryDetail.supportContactedCount ? this.selectedCategoryDetail.supportContactedCount : '--';
  }

  @computed
  get selectedCategoryQueriesInstanceResolution() {
    if (!this.selectedCategoryDetail) {
      return '--';
    }
    if (this.selectedCategoryBlock === 'biggestMovers') {
      return this.selectedCategoryDetail.category.queryStatistics.ssr
        ? this.selectedCategoryDetail.category.queryStatistics.ssr
        : '--';
    }
    return this.selectedCategoryDetail.ssr ? this.selectedCategoryDetail.ssr : '--';
  }

  @computed
  get totalLaunchUrlMatches() {
    let matches = 0;
    if (this.selectedCategoryId !== '' && this.retrieveSingleCategoryDetails.fulfilled) {
      const topLaunchUrlNode = getNodesFromConnection(this.retrieveSingleCategoryDetails.value.topLaunchURLs);
      topLaunchUrlNode.map(launchUrls => (matches = matches + launchUrls.count));
    }
    return matches;
  }

  @computed
  get filteredLineChartData() {
    if (this.lineChartData.fulfilled) {
      return this.lineChartData.value.map(data => omit(data, [...this.filterOutCategoriesForGraph]));
    }
    return [];
  }

  @bind
  @action
  updateCategoriesForGraph(categoryName) {
    if (this.filterOutCategoriesForGraph.has(categoryName)) {
      this.filterOutCategoriesForGraph.delete(categoryName);
    } else {
      this.filterOutCategoriesForGraph.add(categoryName);
    }
  }

  @action
  setStandardDateRange(rangeType: RangeType) {
    this.selectedDateRangeType = rangeType;
    this.aggregationInterval = rangeType === RangeType.PAST_WEEK ? 'DAILY' : 'WEEKLY';
    this.updatedSelectedDateRangeType = true;
  }

  @bind
  @action
  toggleCategoryDetails() {
    this.displayCategoryDetails = !this.displayCategoryDetails;
  }

  @bind
  @action
  updateSelectedCategoryId(id, categoryBlock) {
    this.selectedCategoryBlock = categoryBlock;
    this.selectedCategoryId = id;
    this.toggleCategoryDetails();
  }
}
