import { DownloadOutlined } from '@ant-design/icons';
import { Menu, Row, Table } from 'antd';
import styled from 'styled-components';
import colorPicker from '../../shared/util/ColorPicker';

const MenuItemGroup = Menu.ItemGroup;

export const StyledRow = styled(Row)`
  padding-bottom: ${props => (props.isLookerReport ? 0 : '10px')};
  padding-top: 10px;

  h1 {
    margin-bottom: 0;
  }
  .ant-tabs {
    width: 100%;
    .ant-tabs-bar {
      border: none;
      margin: 0;
      .ant-tabs-ink-bar {
        bottom: 5px;
      }
    }
  }
`;

export const StyledEngageFilterRow = styled(Row)`
  padding: 8px 12px;
  .device-icon {
    border: 1px solid;
    padding: 7px;
  }
  .desktop {
    background: ${props => (props.selectedDeviceType === 'desktop' ? colorPicker.solvvyTeal : colorPicker.white)};
    color: ${props => (props.selectedDeviceType === 'desktop' ? colorPicker.white : colorPicker.solvvyTeal)};
  }
  .mobile {
    background: ${props => (props.selectedDeviceType === 'mobile' ? colorPicker.solvvyTeal : colorPicker.white)};
    color: ${props => (props.selectedDeviceType === 'mobile' ? colorPicker.white : colorPicker.solvvyTeal)};
  }
`;

export const StyledReportInfo = styled.div`
  font-size: 16px;
  color: ${colorPicker.solvvyBlue};
  margin-left: 50px;
  .anticon {
    padding-right: 5px;
  }
`;

export const StyledFrame = styled(Row)`
  .analytics_frame {
    height: 72vh;
    min-height: 500px;
    border: none;
    position: absolute;
    width: 100%;
    left: 0;
  }
`;

export const StyledReportAccr = styled.div`
  display: flex;
  justify-content: space-between;
  .reports_download {
    @media (max-width: 992px) {
      display: none !important;
    }
  }
`;

export const StyledDownloadIcon = styled(DownloadOutlined)`
  height: 25px;
  width: 25px;
  color: white;
  border-radius: 50%;
  padding-top: 3px;
  background: #1890ff;
`;

export const StyledDateDropDown = styled.div`
  padding: 8px 12px;
  .label {
    font-weight: 600;
    padding-right: 5px;
  }

  .date_range_dropdown {
    max-width: 280px;
    margin-right: 12px;
    height: 40px;

    .ant-calendar-picker-input.ant-input {
      height: 100%;
    }

    .ant-calendar-header .ant-calendar-prev-month-btn::before,
    .ant-calendar-header .ant-calendar-prev-year-btn::before,
    .ant-calendar-header .ant-calendar-prev-year-btn::after {
      transform: rotate(-45deg) scale(1.2);
    }

    .ant-calendar-header .ant-calendar-next-month-btn::before,
    .ant-calendar-header .ant-calendar-next-year-btn::before,
    .ant-calendar-header .ant-calendar-next-year-btn::after {
      transform: rotate(135deg) scale(1.2);
    }
  }
`;

export const StyledMenuItem = styled(Menu.Item)`
  a {
    cursor: ${props => (props.disabled === true ? 'not-allowed' : 'pointer')} !important;
    color: ${props => (props.disabled === true ? colorPicker.grey_2 : colorPicker.solvvyBlack)} !important;
  }
`;

export const StyledMenuGroup = styled(MenuItemGroup)`
  ul {
    list-style-type: none;
    padding-left: 10px;
  }
`;

export const QueriesContainer = styled.div`
  .launch-urls-select {
    min-width: 300px;
  }

  .ant-select-selection {
    background: 'white';
    color: #00a99b;
    border: 1px solid #00a99b;
  }

  .fetch-limit-select {
    min-width: 85px;
  }

  .filtered-status {
    text-align: center;
    margin: 10px 0;
  }

  .queries {
    margin-top: 10px;
    overflow-y: auto;
  }

  .query {
    cursor: pointer;
    background-color: #fff;
    padding: 10px;

    .ant-list-item-meta-title {
      font-size: 16px;
      font-weight: normal;
    }

    .query-status {
      margin-left: 10px;
      float: right;
    }

    .launch-url {
      clear: both;
      font-size: 85%;
      margin-top: 10px;
    }

    .ant-list-item-meta-description {
      color: rgba(0, 0, 0, 0.7);
    }
  }

  .show-more {
    margin-top: 20px;
  }
`;

export const StyledArticlePerformanceTable = styled(Table)`
  width: 100%;

  .ant-table-cell img {
    max-height: 150px;
    width: auto;
  }

  .ant-table-column-sorters {
    padding: 8px;
  }

  .solution_content_title {
    color: ${colorPicker.SolvvyDarkPurple_3};
    font-weight: 500;
  }

  .article_url {
    font-size: 14px;
    margin-right: 3px;
  }

  .article_url:hover {
    text-decoration: underline;
  }
`;

export const StyledArticlePerformanceTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  align-items: center;

  .title {
    font-size: 1.2rem;
  }
`;
