import gql from 'graphql-tag';
import { clone, cloneDeep, omit, pick } from 'lodash';
import { runInAction } from 'mobx';
import { IDataSource } from '../store/data-source-types';
import {
  INewSuggestion,
  INewSuggestionList,
  IRevertSuggestionListVersion,
  ISuggestion,
  ISuggestionList,
  ISuggestionListVersion
} from '../store/ResolveUI/Suggestion';
import {
  IComponent,
  IFlow,
  INewComponent,
  INewStep,
  INewWorkflow,
  INewWorkflowVersion,
  IPluginWorkflow,
  IRevertWorkflowVersion,
  IStep,
  IUpdateComponent,
  IUpdateWorkflowVersion,
  IWorkflow,
  IWorkflowVersion
} from '../store/ResolveUI/Workflow';
import { Api } from './api';

const CHILD_COMPONENT_FIELDS_FRAGMENT = gql`
  fragment ChildComponentFields on Component {
    workflowVersionId
    stepId
    id
    lockVersion
    parentComponentId
    type
    text
    options
    position
    cssClass
    dashboardState
  }
`;

const COMPONENT_FIELDS_FRAGMENT = gql`
  ${CHILD_COMPONENT_FIELDS_FRAGMENT}

  fragment ComponentFields on Component {
    # support 3 levels deep of child components
    ...ChildComponentFields
    childComponents {
      ...ChildComponentFields
      childComponents {
        ...ChildComponentFields
        childComponents {
          ...ChildComponentFields
        }
      }
    }
  }
`;

const STEP_FIELDS_FRAGMENT = gql`
  ${COMPONENT_FIELDS_FRAGMENT}

  fragment StepFields on Step {
    workflowVersionId
    id
    lockVersion
    displayType
    displayId
    header
    name
    tags
    notes
    headerCssClass
    headerOptions
    options
    dashboardState

    components {
      ...ComponentFields
    }

    # components: componentsFlattened {
    #   ...ChildComponentFields
    # }
  }
`;

const WORKFLOW_VERSION_FIELDS_FRAGMENT = gql`
  ${STEP_FIELDS_FRAGMENT}

  fragment WorkflowVersionFields on WorkflowVersion {
    id
    lockVersion
    uiInstanceIds
    intents
    skipIntentConfirmation
    confirmationText
    text
    options
    extraUrlParams
    captureNameAsTag
    captureJourney
    captureTrigger
    captureGreeting
    startStepId
    dashboardState
    overallUpdatedAt
    overallUpdatedByUserId
    overallUpdatedByUser {
      name
      email
    }
    updatedByUserId
    updatedByUser {
      name
      email
    }
    publishedAt
    publishedComments
    publishedVersion
    steps {
      ...StepFields
    }
  }
`;

const WORKFLOW_FIELDS_FRAGMENT = gql`
  ${WORKFLOW_VERSION_FIELDS_FRAGMENT}

  fragment WorkflowFields on Workflow {
    id
    lockVersion
    legacyId
    costSaved
    name
    type
    purpose
    survey
    enabled
    lastEnabledAt
    updatedAt
    updatedByUserId
    updatedByUser {
      name
      email
    }
    dashboard
    workingVersion {
      ...WorkflowVersionFields
    }
    publishedVersion {
      ...WorkflowVersionFields
    }
    tags
    notes
  }
`;

const DATA_SOURCE_FIELDS_FRAGMENT = gql`
  fragment DataSourceFields on DataSource {
    id
    lockVersion
    uiInstanceIds
    name
    variablesJsonSchema
    variablesMock
    actions {
      id
      lockVersion
      name
      action
      inputsJsonSchema
    }
    updatedAt
  }
`;

const SUGGESTION_FIELDS_FRAGMENT = gql`
  fragment SuggestionFields on Suggestion {
    id
    lockVersion
    suggestionListVersionId

    name
    urlRegex
    question
    workflowId
    text
    enabled
    position

    updatedAt
    updatedByUserId
    updatedByUser {
      name
      email
    }
  }
`;

const SUGGESTION_LIST_VERSION_FIELDS_FRAGMENT = gql`
  ${SUGGESTION_FIELDS_FRAGMENT}

  fragment SuggestionListVersionFields on SuggestionListVersion {
    id
    lockVersion
    suggestionListId

    overallUpdatedAt
    overallUpdatedByUserId
    overallUpdatedByUser {
      name
      email
    }

    publishedAt
    publishedComments
    publishedVersion

    suggestions {
      ...SuggestionFields
    }
  }
`;

const SUGGESTION_LIST_FIELDS_FRAGMENT = gql`
  ${SUGGESTION_LIST_VERSION_FIELDS_FRAGMENT}

  fragment SuggestionListFields on SuggestionList {
    id
    lockVersion
    orgGroupId
    uiInstanceId
    workingVersion {
      ...SuggestionListVersionFields
    }
    publishedVersion {
      ...SuggestionListVersionFields
    }
  }
`;

function cleanComponentForCreate(component: INewComponent) {
  const cleanedComponent = omit(component, ['workflowVersionId', 'stepId', 'lockVersion', 'position', '__typename']);
  if (cleanedComponent.childComponents) {
    cleanedComponent.childComponents = cleanedComponent.childComponents.map(cleanComponentForCreate) as INewComponent[];
  }

  return cleanedComponent;
}

function cleanComponentForCreateOrUpdate(component: IUpdateComponent) {
  const cleanedComponent = omit(component, ['stepId', '__typename']);
  if (cleanedComponent.childComponents) {
    cleanedComponent.childComponents = cleanedComponent.childComponents.map(
      cleanComponentForCreateOrUpdate
    ) as IUpdateComponent[];
  }

  return cleanedComponent;
}

export function cleanStepForCreate(uncleanedStep: INewStep) {
  const step = omit(uncleanedStep, ['workflowVersionId', 'lockVersion', '__typename']);
  step.components = uncleanedStep.components.map(cleanComponentForCreate) as any;
  return step;
}

function cleanWorkflowVersionForCreate(uncleanedWorkflowVersion: INewWorkflowVersion) {
  const workflowVersion = omit(uncleanedWorkflowVersion, [
    'id',
    'lockVersion',
    '__typename',
    'overallUpdatedAt',
    'numSteps',
    'updatedByUserId',
    'updatedByUser',
    'overallUpdatedByUser',
    'overallUpdatedByUserId',
    'publishedComments',
    'publishedVersion',
    'publishedAt'
  ]);
  workflowVersion.steps = uncleanedWorkflowVersion.steps.map(cleanStepForCreate) as any;
  return workflowVersion;
}

function cleanWorkflowForCreate(uncleanedWorkflow: INewWorkflow) {
  const workflow = omit(uncleanedWorkflow, [
    'lockVersion',
    'updatedAt',
    'updatedByUser',
    'updatedByUserId',
    'lastEnabledAt',
    '__typename'
  ]);
  workflow.workingVersion = cleanWorkflowVersionForCreate(uncleanedWorkflow.workingVersion) as any;
  if (uncleanedWorkflow.publishedVersion) {
    workflow.publishedVersion = cleanWorkflowVersionForCreate(uncleanedWorkflow.publishedVersion) as any;
  }
  return workflow;
}

function cleanDataSourceForUpdate(dataSource: IDataSource) {
  return {
    ...omit(dataSource, ['__typename']),
    actions: dataSource.actions ? dataSource.actions.map(action => omit(action, ['__typename'])) : undefined
  };
}

function cleanDataSourceForCreate(dataSource: IDataSource) {
  return {
    ...omit(dataSource, ['__typename', 'lockVersion']),
    actions: dataSource.actions
      ? dataSource.actions.map(action => omit(action, ['__typename', 'lockVersion']))
      : undefined
  };
}

function cleanSuggestionForUpdate(suggestion: ISuggestion): ISuggestion {
  return omit(suggestion, ['updatedAt', 'updatedByUser', 'updatedByUserId', '__typename']) as ISuggestion;
}

function cleanSuggestionForCreate(suggestion: INewSuggestion): ISuggestion {
  return omit(suggestion, [
    'lockVersion',
    'suggestionListVersionId',
    'updatedAt',
    'updatedByUser',
    'updatedByUserId',
    '__typename'
  ]) as ISuggestion;
}

interface IVersionedResource {
  id?: string;
  workflowVersionId?: string;
  suggestionListVersionId?: string;
  lockVersion?: number;
}

export class UiConfigService {
  private pendingApi: Promise<any> = Promise.resolve();

  // store latest seen lockVersion for arbitrary resources (workflow, workflow version, step, component)
  private lockVersions: { [resourceId: string]: number } = {};

  constructor(private api: Api) {}

  // Helper function to enforce 1 call to API at a time to help reduce optimistic lock errors when the user makes
  // several quick actions back to back on the same resource.
  //
  // This is to be used by update or delete API calls that require lockVersions
  async queueApi(work: () => Promise<any>): Promise<any> {
    // see https://stackoverflow.com/a/53540586
    const run = async workToRun => {
      try {
        await this.pendingApi;
      } finally {
        // run work on next tick to allow handler of previous pending promise a chance to update lockVersion on resource
        await new Promise(resolve => setTimeout(resolve, 0));
        return runInAction(workToRun); // tslint:disable-line
      }
    };

    this.pendingApi = run(work);
    return this.pendingApi;
  }

  createResourceKey(resource: IVersionedResource): string {
    if (resource.workflowVersionId) {
      return `${resource.workflowVersionId}:${resource.id}`;
    } else if (resource.suggestionListVersionId) {
      return `${resource.suggestionListVersionId}:${resource.id}`;
    }

    return resource.id!;
  }

  getLockVersion(resource: IVersionedResource): number {
    return this.lockVersions[this.createResourceKey(resource)];
  }

  setLockVersion(resource: IVersionedResource): void {
    this.lockVersions[this.createResourceKey(resource)] = resource.lockVersion!;
  }

  extractLockVersions(obj: IVersionedResource | IVersionedResource[]): void {
    const resources = Array.isArray(obj) ? obj : [obj];
    for (const resource of resources || []) {
      if (resource && resource.id && resource.lockVersion) {
        this.setLockVersion(resource);
      }
      if (resource && typeof resource === 'object') {
        this.extractLockVersions(Object.values(resource));
      }
    }
  }

  updateLockVersions(obj: IVersionedResource | IVersionedResource[]) {
    const resources = Array.isArray(obj) ? obj : [obj];
    for (const resource of resources || []) {
      if (resource && resource.id && this.getLockVersion(resource)) {
        resource.lockVersion = this.getLockVersion(resource);
      }
      if (resource && typeof resource === 'object') {
        this.updateLockVersions(Object.values(resource));
      }
    }
  }

  async getWorkflows(orgGroupId: number, publishedOrgId: number): Promise<IWorkflow[]> {
    const response = await this.api.gqlQuery({
      query: gql`
        ${WORKFLOW_VERSION_FIELDS_FRAGMENT}

        query AllWorkflowsForOrgGroup($orgGroupId: Int!, $publishedOrgId: Int!) {
          allWorkflows(where: { orgGroupId: $orgGroupId, publishedOrgId: $publishedOrgId }, orderBy: updated_at_DESC) {
            edges {
              node {
                id
                lockVersion
                legacyId
                costSaved
                name
                type
                purpose
                survey
                enabled
                lastEnabledAt
                dashboard
                updatedAt
                updatedByUserId
                updatedByUser {
                  name
                  email
                }
                workingVersion {
                  ...WorkflowVersionFields
                  numSteps
                }
                publishedVersion {
                  ...WorkflowVersionFields
                }
                tags
                notes
              }
            }
          }
        }
      `,
      variables: { orgGroupId, publishedOrgId },
      fetchPolicy: 'network-only'
    });

    const workflows = response.data.allWorkflows.edges.map(edge => {
      const workflow = edge.node as IWorkflow;
      this.setWorkflowDefaults(workflow);
      return workflow;
    });

    this.extractLockVersions(workflows);

    return workflows;
  }

  async getWorkflow(workflowId: string): Promise<IWorkflow | undefined> {
    const response = await this.api.gqlQuery({
      query: gql`
        ${WORKFLOW_FIELDS_FRAGMENT}

        query GetWorkflow($workflowId: ID!) {
          Workflow(id: $workflowId) {
            ...WorkflowFields
          }
        }
      `,
      variables: {
        workflowId
      },
      fetchPolicy: 'network-only'
    });

    const workflow = response.data.Workflow;
    if (workflow) {
      this.setWorkflowDefaults(workflow);
      this.extractLockVersions(workflow);
    }

    return workflow ? workflow : undefined;
  }

  setWorkflowVersionDefaults(workflowVersion: IWorkflowVersion) {
    if (!workflowVersion) {
      return;
    }

    if (workflowVersion.intents) {
      workflowVersion.intents.sort();
    }
    workflowVersion.uiInstanceIds = workflowVersion.uiInstanceIds || [];

    workflowVersion.dashboardState = workflowVersion.dashboardState || {};
    workflowVersion.options = workflowVersion.options || {};
    for (const step of workflowVersion.steps) {
      step.dashboardState = step.dashboardState || {};
      const setComponentDefaults = (components: IComponent[]): void => {
        for (const component of components) {
          component.dashboardState = component.dashboardState || {};
          component.options = component.options || {};
          if (component.childComponents) {
            setComponentDefaults(component.childComponents);
          }
        }
      };
      setComponentDefaults(step.components);
    }
  }

  setWorkflowDefaults(workflow: IWorkflow): void {
    this.setWorkflowVersionDefaults(workflow.publishedVersion);
    this.setWorkflowVersionDefaults(workflow.workingVersion);
  }

  async createWorkflow(workflow: INewWorkflow, initialPublishedComments?: string): Promise<IWorkflow> {
    const response = await this.api.gqlMutate({
      mutation: gql`
        ${WORKFLOW_FIELDS_FRAGMENT}

        mutation CreateWorkflow($workflow: CreateWorkflowInput!, $initialPublishedComments: String) {
          createWorkflow(workflow: $workflow, initialPublishedComments: $initialPublishedComments) {
            ...WorkflowFields
          }
        }
      `,
      variables: { workflow: cleanWorkflowForCreate(workflow), initialPublishedComments }
    });
    const createdWorkflow = response.data.createWorkflow;
    this.extractLockVersions(createdWorkflow);
    return createdWorkflow;
  }

  async updateWorkflow(workflow: IWorkflow): Promise<IWorkflow> {
    const response = await this.queueApi(() => {
      this.updateLockVersions(workflow);
      return this.api.gqlMutate({
        mutation: gql`
          ${WORKFLOW_FIELDS_FRAGMENT}

          mutation UpdateWorkflow($workflow: UpdateWorkflowInput!) {
            updateWorkflow(workflow: $workflow) {
              ...WorkflowFields
            }
          }
        `,
        variables: {
          workflow: omit(workflow, [
            'workingVersion',
            'lastEnabledAt',
            'updatedByUserId',
            'updatedByUser',
            'updatedAt',
            'publishedVersion',
            '__typename'
          ])
        }
      });
    });
    const updatedWorkflow = response.data.updateWorkflow;
    this.extractLockVersions(updatedWorkflow);
    return updatedWorkflow;
  }

  async deleteWorkflow(workflowId: string): Promise<void> {
    await this.queueApi(() => {
      const lockVersion = this.getLockVersion({ id: workflowId });
      return this.api.gqlMutate({
        mutation: gql`
          mutation DeleteWorkflow($workflowId: ID!, $lockVersion: Int!) {
            deleteWorkflow(id: $workflowId, lockVersion: $lockVersion) {
              id
            }
          }
        `,
        variables: { workflowId, lockVersion }
      });
    });
  }

  async importIntoExistingWorkflow(workingVersionId: string, flow: IFlow, uiInstanceIds: string[]): Promise<IWorkflow> {
    const response = await this.queueApi(() => {
      const lockVersion = this.getLockVersion({ id: workingVersionId });
      return this.api.gqlMutate({
        mutation: gql`
          ${WORKFLOW_FIELDS_FRAGMENT}

          mutation ImportIntoExistingWorkflow($workingVersionId: ID!, $lockVersion: Int!, $workflow: JSONObject!) {
            importIntoExistingWorkflow(
              workingVersionId: $workingVersionId
              lockVersion: $lockVersion
              workflow: $workflow
            ) {
              ...WorkflowFields
            }
          }
        `,
        variables: { workingVersionId, lockVersion, workflow: { options: { flow, uiInstanceIds } } }
      });
    });
    const updatedWorkflow = response.data.importIntoExistingWorkflow;
    this.extractLockVersions(updatedWorkflow);
    return updatedWorkflow;
  }

  async importNewWorkflow(
    orgGroupId: number,
    workingWorkflow: IPluginWorkflow,
    initialPublishedComments?: string,
    translateToLanguage?: string
  ): Promise<IWorkflow> {
    const response = await this.api.gqlMutate({
      mutation: gql`
        ${WORKFLOW_FIELDS_FRAGMENT}

        mutation ImportNewWorkflow(
          $orgGroupId: Int!
          $workingWorkflow: JSONObject!
          $initialPublishedComments: String
          $translateToLanguage: String
        ) {
          importNewWorkflow(
            orgGroupId: $orgGroupId
            workingWorkflow: $workingWorkflow
            initialPublishedComments: $initialPublishedComments
            translateToLanguage: $translateToLanguage
          ) {
            ...WorkflowFields
          }
        }
      `,
      variables: { orgGroupId, workingWorkflow, initialPublishedComments, translateToLanguage }
    });
    const createdWorkflow = response.data.importNewWorkflow;
    this.extractLockVersions(createdWorkflow);
    return createdWorkflow;
  }

  async bulkDuplicateWorkflows(
    orgGroupId: number,
    srcWorkflowVersionIds: string[],
    targetUiInstanceId: string,
    newWorkflowType: string,
    initialPublishedComments?: string,
    translateToLanguage?: string
  ): Promise<string[]> {
    const response = await this.api.gqlMutate({
      mutation: gql`
        mutation DuplicateWorkflows(
          $orgGroupId: Int!
          $srcWorkflowVersionIds: [ID!]!
          $targetUiInstanceId: ID!
          $newWorkflowType: String!
          $initialPublishedComments: String
          $translateToLanguage: String
        ) {
          duplicateWorkflows(
            orgGroupId: $orgGroupId
            srcWorkflowVersionIds: $srcWorkflowVersionIds
            targetUiInstanceId: $targetUiInstanceId
            newWorkflowType: $newWorkflowType
            initialPublishedComments: $initialPublishedComments
            translateToLanguage: $translateToLanguage
          ) {
            newWorkflowIds
          }
        }
      `,
      variables: {
        orgGroupId,
        srcWorkflowVersionIds,
        targetUiInstanceId,
        newWorkflowType,
        initialPublishedComments,
        translateToLanguage
      }
    });

    return response.data.duplicateWorkflows.newWorkflowIds;
  }

  async publishWorkflow(workflowId: string, workingVersionId: string, comments?: string): Promise<IWorkflow> {
    const response = await this.queueApi(() => {
      const workflowLockVersion = this.getLockVersion({ id: workflowId });
      const workingLockVersion = this.getLockVersion({ id: workingVersionId });
      return this.api.gqlMutate({
        mutation: gql`
          ${WORKFLOW_FIELDS_FRAGMENT}

          mutation PublishWorkflow(
            $workflowId: ID!
            $workflowLockVersion: Int!
            $workingLockVersion: Int!
            $comments: String
          ) {
            publishWorkflow(
              workflowId: $workflowId
              workflowLockVersion: $workflowLockVersion
              workingLockVersion: $workingLockVersion
              comments: $comments
            ) {
              ...WorkflowFields
            }
          }
        `,
        variables: {
          workflowId,
          workflowLockVersion,
          workingLockVersion,
          comments
        }
      });
    });
    const updatedWorkflow = response.data.publishWorkflow;
    this.extractLockVersions(updatedWorkflow);
    return updatedWorkflow;
  }

  async revertWorkflowWorkingVersion(
    workingVersionId: string,
    srcWorkflowVersionId: string
  ): Promise<IWorkflowVersion> {
    const response = await this.queueApi(() => {
      const lockVersion = this.getLockVersion({ id: workingVersionId });
      return this.api.gqlMutate({
        mutation: gql`
          ${WORKFLOW_VERSION_FIELDS_FRAGMENT}

          mutation RevertWorkflowWorkingVersion(
            $workingVersionId: ID!
            $lockVersion: Int!
            $srcWorkflowVersionId: ID!
          ) {
            revertWorkflowWorkingVersion(
              workingVersionId: $workingVersionId
              lockVersion: $lockVersion
              srcWorkflowVersionId: $srcWorkflowVersionId
            ) {
              ...WorkflowVersionFields
            }
          }
        `,
        variables: { workingVersionId, lockVersion, srcWorkflowVersionId }
      });
    });
    const updatedWorkflowVersion = response.data.revertWorkflowWorkingVersion;
    this.extractLockVersions(updatedWorkflowVersion);
    return updatedWorkflowVersion;
  }

  async updateWorkflowVersion(
    rawWorkflowVersion: IUpdateWorkflowVersion,
    {
      updateStepsAndComponents,
      deleteExistingStepsAndComponents
    }: {
      updateStepsAndComponents?: boolean;
      deleteExistingStepsAndComponents?: boolean;
    } = {}
  ): Promise<IWorkflowVersion> {
    const response = await this.queueApi(() => {
      const workflowVersion = cloneDeep(rawWorkflowVersion);
      if (updateStepsAndComponents && workflowVersion.steps) {
        workflowVersion.steps = workflowVersion.steps.map(s => omit(s, ['__typename']) as IStep);
        for (const step of workflowVersion.steps) {
          if (step.components) {
            step.components = step.components.map(c => cleanComponentForCreateOrUpdate(c)) as IUpdateComponent[];
          }
        }
      } else {
        delete workflowVersion.steps;
      }
      this.updateLockVersions(workflowVersion);
      return this.api.gqlMutate({
        mutation: gql`
          ${WORKFLOW_VERSION_FIELDS_FRAGMENT}

          mutation UpdateWorkflowVersion(
            $workflowVersion: UpdateWorkflowVersionInput!
            $deleteExistingStepsAndComponents: Boolean
          ) {
            updateWorkflowVersion(
              workflowVersion: $workflowVersion
              deleteExistingStepsAndComponents: $deleteExistingStepsAndComponents
            ) {
              ...WorkflowVersionFields
            }
          }
        `,
        variables: {
          workflowVersion: omit(workflowVersion, [
            'startStep',
            '__typename',
            'overallUpdatedAt',
            'overallUpdatedByUserId',
            'overallUpdatedByUser',
            'updatedByUserId',
            'updatedByUser',
            'publishedVersion'
          ]),
          deleteExistingStepsAndComponents
        }
      });
    });

    const updatedWorkflowVersion = response.data.updateWorkflowVersion;
    this.extractLockVersions(updatedWorkflowVersion);
    return updatedWorkflowVersion;
  }

  /**
   * Updates the workflow layout related attributes:
   *  - workflowVersion.dashboardState.canvasTranslate
   *  - steps[].dashboardState.position
   * @param workflowVersion
   */
  async updateWorkflowVersionLayout(workflowVersion: IWorkflowVersion): Promise<IWorkflowVersion> {
    const response = await this.queueApi(() => {
      this.updateLockVersions(workflowVersion);

      let stepVariableDefinitions = '';
      const stepVariables = {};
      let updateSteps = '';
      workflowVersion.steps.forEach((step, index) => {
        const stepVarName = `step${index}`;
        updateSteps += `
          updateStep${index}: updateStep(step: $${stepVarName}) {
            id
            lockVersion
          }
        `;
        stepVariableDefinitions += `, $${stepVarName}: UpdateStepInput!`;
        stepVariables[stepVarName] = pick(step, ['workflowVersionId', 'id', 'dashboardState', 'lockVersion']);
      });

      return this.api.gqlMutate({
        mutation: gql`
        ${WORKFLOW_VERSION_FIELDS_FRAGMENT}

        mutation UpdateWorkflowVersion($workflowVersion: UpdateWorkflowVersionInput! ${stepVariableDefinitions}) {
          ${updateSteps}
          updateWorkflowVersion(workflowVersion: $workflowVersion) {
            ...WorkflowVersionFields
          }
        }
      `,
        variables: {
          workflowVersion: pick(workflowVersion, ['id', 'dashboardState', 'lockVersion']),
          ...stepVariables
        }
      });
    });
    const updatedWorkflowVersion = response.data.updateWorkflowVersion;
    this.extractLockVersions(updatedWorkflowVersion);
    return updatedWorkflowVersion;
  }

  async createStep(workflowVersionId: string, step: INewStep): Promise<IStep> {
    const response = await this.queueApi(() => {
      return this.api.gqlMutate({
        mutation: gql`
          ${STEP_FIELDS_FRAGMENT}

          mutation CreateStep($workflowVersionId: ID!, $step: CreateStepInput!) {
            createStep(workflowVersionId: $workflowVersionId, step: $step) {
              ...StepFields
            }
          }
        `,
        variables: { workflowVersionId, step: cleanStepForCreate(step) }
      });
    });
    const createdStep = response.data.createStep;
    this.extractLockVersions(createdStep);
    return createdStep;
  }

  async updateStep(rawStep: IStep, updateComponents: boolean = false): Promise<IStep> {
    const response = await this.queueApi(() => {
      this.updateLockVersions(rawStep);
      const step: Partial<IStep> = clone(rawStep);
      if (!updateComponents) {
        delete step.components;
      }

      if (step.components) {
        step.components = step.components.map(c => omit(c, ['stepId', '__typename']) as IComponent);
      }
      return this.api.gqlMutate({
        mutation: gql`
          ${STEP_FIELDS_FRAGMENT}

          mutation UpdateStep($step: UpdateStepInput!) {
            updateStep(step: $step) {
              ...StepFields
            }
          }
        `,
        variables: { step: omit(step, ['__typename']) }
      });
    });
    const updatedStep = response.data.updateStep;
    this.extractLockVersions(updatedStep);
    return updatedStep;
  }

  async updateStepComponentPositions(
    stepComponentsOrRepeatingGroupChildComponents: IComponent[],
    step: IStep
  ): Promise<Array<Partial<IComponent>>> {
    const response = await this.queueApi(() => {
      this.updateLockVersions(stepComponentsOrRepeatingGroupChildComponents);
      const componentVariableDefinitions: string[] = [];
      const componentVariables = {};
      let updateComponents = '';
      stepComponentsOrRepeatingGroupChildComponents.forEach((component, index) => {
        const componentVarName = `component${index}`;
        updateComponents += `
          updateComponent${index}: updateComponent(component: $${componentVarName}) {
            id
            lockVersion
            workflowVersionId
          }
        `;
        componentVariableDefinitions.push(`$${componentVarName}: UpdateComponentInput!`);
        componentVariables[componentVarName] = pick(component, ['workflowVersionId', 'id', 'position', 'lockVersion']);
      });

      return this.api.gqlMutate({
        mutation: gql`
        mutation UpdateComponents(${componentVariableDefinitions.join(', ')}) {
          ${updateComponents}
        }
      `,
        variables: { workflowVersionId: step.workflowVersionId, stepId: step.id, ...componentVariables }
      });
    });

    const updatedComponents: Array<Partial<IComponent>> = Object.values(response.data);
    this.extractLockVersions(updatedComponents);
    return updatedComponents;
  }

  async deleteStep(workflowVersionId: string, stepId: string): Promise<void> {
    await this.queueApi(() => {
      const lockVersion = this.getLockVersion({ id: stepId, workflowVersionId });
      return this.api.gqlMutate({
        mutation: gql`
          mutation DeleteStep($workflowVersionId: ID!, $stepId: ID!, $lockVersion: Int!) {
            deleteStep(workflowVersionId: $workflowVersionId, stepId: $stepId, lockVersion: $lockVersion) {
              id
            }
          }
        `,
        variables: { workflowVersionId, stepId, lockVersion }
      });
    });
  }

  async deleteSteps(workflowVersionId: string, stepIds: string[]): Promise<void> {
    await this.queueApi(() => {
      let deleteStepsGql = '';
      const stepVariableDefinitions: string[] = [];
      const stepVariables = {};
      stepIds.forEach((stepId, index) => {
        const lockVersion = this.getLockVersion({ id: stepId, workflowVersionId });
        const stepVarName = `step_id_${index}`;
        const lockVersionVarName = `step_lock_version_${index}`;
        stepVariableDefinitions.push(`$${stepVarName} : ID!, $${lockVersionVarName}: Int!`);

        deleteStepsGql += `
          deleteStep${index}: deleteStep(workflowVersionId: $workflowVersionId, stepId: $${stepVarName}, lockVersion: $${lockVersionVarName}) {
            id
          }
        `;
        stepVariables[stepVarName] = stepId;
        stepVariables[lockVersionVarName] = lockVersion;
      });

      return this.api.gqlMutate({
        mutation: gql`
          mutation DeleteSteps($workflowVersionId: ID!, ${stepVariableDefinitions.join(', ')}) {
            ${deleteStepsGql}
          }
        `,
        variables: { workflowVersionId, ...stepVariables }
      });
    });
  }

  async createComponent(workflowVersionId: string, stepId: string, component: INewComponent): Promise<IComponent> {
    const response = await this.api.gqlMutate({
      mutation: gql`
        ${COMPONENT_FIELDS_FRAGMENT}

        mutation CreateComponent($workflowVersionId: ID!, $stepId: ID!, $component: CreateComponentInput!) {
          createComponent(workflowVersionId: $workflowVersionId, stepId: $stepId, component: $component) {
            ...ComponentFields
          }
        }
      `,
      variables: {
        workflowVersionId,
        stepId,
        component: cleanComponentForCreate(component)
      }
    });
    const createdComponent = response.data.createComponent;
    this.extractLockVersions(createdComponent);
    return createdComponent;
  }

  async updateComponent(component: IComponent): Promise<IComponent> {
    const response = await this.queueApi(() => {
      this.updateLockVersions(component);
      return this.api.gqlMutate({
        mutation: gql`
          ${COMPONENT_FIELDS_FRAGMENT}

          mutation UpdateComponent($component: UpdateComponentInput!) {
            updateComponent(component: $component) {
              ...ComponentFields
            }
          }
        `,
        variables: {
          component: omit(component, ['childComponents', 'stepId', 'parentComponentId', 'position', '__typename'])
        }
      });
    });
    const updatedComponent = response.data.updateComponent;
    this.extractLockVersions(updatedComponent);
    return updatedComponent;
  }

  async deleteComponent(workflowVersionId: string, componentId: string): Promise<void> {
    await this.queueApi(() => {
      const lockVersion = this.getLockVersion({ id: componentId, workflowVersionId });
      return this.api.gqlMutate({
        mutation: gql`
          mutation DeleteComponent($workflowVersionId: ID!, $componentId: ID!, $lockVersion: Int!) {
            deleteComponent(
              workflowVersionId: $workflowVersionId
              componentId: $componentId
              lockVersion: $lockVersion
            ) {
              id
            }
          }
        `,
        variables: { workflowVersionId, componentId, lockVersion }
      });
    });
  }

  async getWorkflowVersionsForRevert(workflowId: string, limit: number = 25): Promise<IRevertWorkflowVersion[]> {
    const response = await this.api.gqlQuery({
      query: gql`
        query getWorkflowVersions($workflowId: ID!, $limit: Int!) {
          Workflow(id: $workflowId) {
            id
            versions(limit: $limit) {
              id
              publishedAt
              publishedComments
              publishedVersion
              updatedByUserId
              updatedByUser {
                name
                email
              }
            }
          }
        }
      `,
      variables: { workflowId, limit },
      fetchPolicy: 'network-only'
    });
    return response.data.Workflow.versions;
  }

  async getDataSources(orgGroupId: number): Promise<IDataSource[]> {
    const response = await this.api.gqlQuery({
      query: gql`
        ${DATA_SOURCE_FIELDS_FRAGMENT}

        query GetAllDataSources($orgGroupId: Int!) {
          allDataSources(where: { orgGroupId: $orgGroupId }, orderBy: updated_at_DESC) {
            edges {
              node {
                ...DataSourceFields
              }
            }
          }
        }
      `,
      variables: { orgGroupId },
      fetchPolicy: 'network-only'
    });

    const dataSources = response.data.allDataSources.edges.map(({ node }) => node) as IDataSource[];

    this.extractLockVersions(dataSources);

    return dataSources;
  }

  async createDataSource(dataSource: IDataSource): Promise<IDataSource> {
    const response = await this.api.gqlMutate({
      mutation: gql`
        ${DATA_SOURCE_FIELDS_FRAGMENT}

        mutation CreateDataSource($dataSource: CreateDataSourceInput!) {
          createDataSource(dataSource: $dataSource) {
            ...DataSourceFields
          }
        }
      `,
      variables: { dataSource: cleanDataSourceForCreate(dataSource) }
    });
    const createdDataSource = response.data.createDataSource;
    this.extractLockVersions(createdDataSource);
    return createdDataSource;
  }

  async updateDataSource(dataSource: IDataSource): Promise<IDataSource> {
    const response = await this.queueApi(() => {
      this.updateLockVersions(dataSource);
      return this.api.gqlMutate({
        mutation: gql`
          ${DATA_SOURCE_FIELDS_FRAGMENT}

          mutation UpdateDataSource($dataSource: UpdateDataSourceInput!) {
            updateDataSource(dataSource: $dataSource) {
              ...DataSourceFields
            }
          }
        `,
        variables: {
          dataSource: cleanDataSourceForUpdate(dataSource)
        }
      });
    });
    const updatedComponent = response.data.updateComponent;
    this.extractLockVersions(updatedComponent);
    return updatedComponent;
  }

  async deleteDataSource(dataSourceId: string): Promise<void> {
    await this.queueApi(() => {
      const lockVersion = this.getLockVersion({ id: dataSourceId });
      return this.api.gqlMutate({
        mutation: gql`
          mutation DeleteDataSource($dataSourceId: ID!, $lockVersion: Int!) {
            deleteDataSource(id: $dataSourceId, lockVersion: $lockVersion) {
              id
            }
          }
        `,
        variables: { dataSourceId, lockVersion }
      });
    });
  }

  async getSuggestionLists(orgId: number): Promise<ISuggestionList[]> {
    const response = await this.api.gqlQuery({
      query: gql`
        ${SUGGESTION_LIST_FIELDS_FRAGMENT}

        query AllSuggestionListsForOrg($orgId: Int!) {
          allSuggestionLists(where: { orgId: $orgId }, orderBy: updated_at_DESC) {
            edges {
              node {
                ...SuggestionListFields
              }
            }
          }
        }
      `,
      variables: { orgId },
      fetchPolicy: 'network-only'
    });

    const suggestionLists = response.data.allSuggestionLists.edges.map(edge => {
      const suggestionList = edge.node as ISuggestionList;
      return suggestionList;
    });

    this.extractLockVersions(suggestionLists);

    return suggestionLists;
  }

  async getSuggestionList(uiInstanceId: string): Promise<ISuggestionList | undefined> {
    const response = await this.api.gqlQuery({
      query: gql`
        ${SUGGESTION_LIST_FIELDS_FRAGMENT}

        query GetSuggestionList($uiInstanceId: ID!) {
          SuggestionList(uiInstanceId: $uiInstanceId) {
            ...SuggestionListFields
          }
        }
      `,
      variables: {
        uiInstanceId
      },
      fetchPolicy: 'network-only'
    });

    const suggestionList = response.data.SuggestionList;
    if (suggestionList) {
      this.extractLockVersions(suggestionList);
    }

    return suggestionList ? suggestionList : undefined;
  }

  async createSuggestionList(
    orgGroupId: number,
    uiInstanceId: string,
    suggestion?: INewSuggestion
  ): Promise<ISuggestionList> {
    const suggestionList: INewSuggestionList = {
      orgGroupId,
      uiInstanceId,
      workingVersion: {
        suggestions: suggestion ? [suggestion] : []
      }
    };

    const response = await this.queueApi(() => {
      return this.api.gqlMutate({
        mutation: gql`
          ${SUGGESTION_LIST_FIELDS_FRAGMENT}

          mutation CreateSuggestionList($suggestionList: CreateSuggestionListInput!) {
            createSuggestionList(suggestionList: $suggestionList) {
              ...SuggestionListFields
            }
          }
        `,
        variables: { suggestionList }
      });
    });
    const createdSuggestionList = response.data.createSuggestionList;
    this.extractLockVersions(createdSuggestionList);
    return createdSuggestionList;
  }

  async createSuggestion(suggestionListVersionId: string, suggestion: INewSuggestion): Promise<ISuggestion> {
    const response = await this.queueApi(() => {
      return this.api.gqlMutate({
        mutation: gql`
          ${SUGGESTION_FIELDS_FRAGMENT}

          mutation CreateSuggestion($suggestionListVersionId: ID!, $suggestion: CreateSuggestionInput!) {
            createSuggestion(suggestionListVersionId: $suggestionListVersionId, suggestion: $suggestion) {
              ...SuggestionFields
            }
          }
        `,
        variables: {
          suggestion: cleanSuggestionForCreate(suggestion),
          suggestionListVersionId
        }
      });
    });
    const createdSuggestion = response.data.createSuggestion;
    this.extractLockVersions(createdSuggestion);
    return createdSuggestion;
  }

  async updateSuggestion(suggestion: ISuggestion): Promise<ISuggestion> {
    const response = await this.queueApi(() => {
      this.updateLockVersions(suggestion);
      return this.api.gqlMutate({
        mutation: gql`
          ${SUGGESTION_FIELDS_FRAGMENT}

          mutation UpdateSuggestion($suggestion: UpdateSuggestionInput!) {
            updateSuggestion(suggestion: $suggestion) {
              ...SuggestionFields
            }
          }
        `,
        variables: {
          suggestion: cleanSuggestionForUpdate(suggestion)
        }
      });
    });
    const updatedSuggestion = response.data.updateSuggestion;
    this.extractLockVersions(updatedSuggestion);
    return updatedSuggestion;
  }

  async batchUpdateSuggestions(
    suggestionListVersionId: string,
    suggestions: ISuggestion[]
  ): Promise<Array<Partial<ISuggestion>>> {
    const response = await this.queueApi(() => {
      this.updateLockVersions(suggestions);
      const suggestionVariableDefinitions: string[] = [];
      const suggestionVariables = {};
      let updateSuggestions = '';
      suggestions.forEach((suggestion, index) => {
        const suggestionVarName = `suggestion${index}`;
        updateSuggestions += `
          updateSuggestion${index}: updateSuggestion(suggestion: $${suggestionVarName}) {
            id
            lockVersion
            suggestionListVersionId
          }
        `;
        suggestionVariableDefinitions.push(`$${suggestionVarName}: UpdateSuggestionInput!`);
        suggestionVariables[suggestionVarName] = cleanSuggestionForUpdate(suggestion);
      });

      return this.api.gqlMutate({
        mutation: gql`
        mutation UpdateSuggestions(${suggestionVariableDefinitions.join(', ')}) {
          ${updateSuggestions}
        }
      `,
        variables: { suggestionListVersionId, ...suggestionVariables }
      });
    });

    const updatedSuggestions: Array<Partial<ISuggestion>> = Object.values(response.data);
    this.extractLockVersions(updatedSuggestions);
    return updatedSuggestions;
  }

  async deleteSuggestion(suggestionListVersionId: string, suggestionId: string): Promise<void> {
    await this.queueApi(() => {
      const lockVersion = this.getLockVersion({ id: suggestionId, suggestionListVersionId });
      return this.api.gqlMutate({
        mutation: gql`
          mutation DeleteSuggestion($suggestionListVersionId: ID!, $suggestionId: ID!, $lockVersion: Int!) {
            deleteSuggestion(
              suggestionListVersionId: $suggestionListVersionId
              suggestionId: $suggestionId
              lockVersion: $lockVersion
            ) {
              id
            }
          }
        `,
        variables: { suggestionListVersionId, suggestionId, lockVersion }
      });
    });
  }

  async updateSuggestionListVersion(
    rawSuggestionListVersion: ISuggestionListVersion,
    {
      deleteExistingSuggestions
    }: {
      deleteExistingSuggestions?: boolean;
    } = {}
  ): Promise<void> {
    const response = await this.queueApi(() => {
      const suggestionListVersion = cloneDeep(rawSuggestionListVersion);
      this.updateLockVersions(suggestionListVersion);
      suggestionListVersion.suggestions = suggestionListVersion.suggestions.map(cleanSuggestionForUpdate);
      return this.api.gqlMutate({
        mutation: gql`
          ${SUGGESTION_LIST_VERSION_FIELDS_FRAGMENT}

          mutation UpdateSuggestionListVersion(
            $suggestionListVersion: UpdateSuggestionListVersionInput!
            $deleteExistingSuggestions: Boolean
          ) {
            updateSuggestionListVersion(
              suggestionListVersion: $suggestionListVersion
              deleteExistingSuggestions: $deleteExistingSuggestions
            ) {
              ...SuggestionListVersionFields
            }
          }
        `,
        variables: {
          suggestionListVersion: omit(suggestionListVersion, [
            '__typename',
            'overallUpdatedAt',
            'overallUpdatedByUserId',
            'overallUpdatedByUser',
            'updatedByUserId',
            'updatedByUser',
            'suggestionListId',
            'publishedVersion'
          ]),
          deleteExistingSuggestions
        }
      });
    });

    const updatedSuggestionListVersion = response.data.updateSuggestionListVersion;
    this.extractLockVersions(updatedSuggestionListVersion);
    return updatedSuggestionListVersion;
  }

  async publishSuggestionList(
    suggestionListId: string,
    suggestionListVersionId: string,
    comments?: string
  ): Promise<ISuggestionList> {
    const response = await this.queueApi(() => {
      const suggestionListLockVersion = this.getLockVersion({ id: suggestionListId });
      const workingLockVersion = this.getLockVersion({ id: suggestionListVersionId });
      return this.api.gqlMutate({
        mutation: gql`
          ${SUGGESTION_LIST_FIELDS_FRAGMENT}

          mutation PublishSuggestionList(
            $suggestionListId: ID!
            $suggestionListLockVersion: Int!
            $workingLockVersion: Int!
            $comments: String
          ) {
            publishSuggestionList(
              suggestionListId: $suggestionListId
              suggestionListLockVersion: $suggestionListLockVersion
              workingLockVersion: $workingLockVersion
              comments: $comments
            ) {
              ...SuggestionListFields
            }
          }
        `,
        variables: {
          suggestionListId,
          suggestionListLockVersion,
          workingLockVersion,
          comments
        }
      });
    });
    const updatedSuggestionList = response.data.publishSuggestionList;
    this.extractLockVersions(updatedSuggestionList);
    return updatedSuggestionList;
  }

  async revertSuggestionListWorkingVersion(
    suggestionListVersionId: string,
    srcSuggestionListVersionId: string
  ): Promise<ISuggestionListVersion> {
    const response = await this.queueApi(() => {
      const lockVersion = this.getLockVersion({ id: suggestionListVersionId });
      return this.api.gqlMutate({
        mutation: gql`
          ${SUGGESTION_LIST_VERSION_FIELDS_FRAGMENT}

          mutation RevertSuggestionListWorkingVersion(
            $workingVersionId: ID!
            $lockVersion: Int!
            $srcSuggestionListVersionId: ID!
          ) {
            revertSuggestionListWorkingVersion(
              workingVersionId: $workingVersionId
              lockVersion: $lockVersion
              srcSuggestionListVersionId: $srcSuggestionListVersionId
            ) {
              ...SuggestionListVersionFields
            }
          }
        `,
        variables: { workingVersionId: suggestionListVersionId, lockVersion, srcSuggestionListVersionId }
      });
    });
    const updatedSuggestionListVersion = response.data.revertSuggestionListWorkingVersion;
    this.extractLockVersions(updatedSuggestionListVersion);
    return updatedSuggestionListVersion;
  }

  async getSuggestionListVersionsForRevert(
    suggestionListId: string,
    limit: number = 25
  ): Promise<IRevertSuggestionListVersion[]> {
    const response = await this.api.gqlQuery({
      query: gql`
        query getSuggestionListVersions($suggestionListId: ID!, $limit: Int!) {
          SuggestionList(id: $suggestionListId) {
            id
            versions(limit: $limit) {
              id
              publishedAt
              publishedComments
              publishedVersion
              updatedByUserId
              updatedByUser {
                name
                email
              }
            }
          }
        }
      `,
      variables: { suggestionListId, limit },
      fetchPolicy: 'network-only'
    });
    return response.data.SuggestionList.versions;
  }
}

export const uiConfigService = api => {
  return new UiConfigService(api);
};

export default uiConfigService;
