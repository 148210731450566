import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import { IWorkflow } from 'src/store/ResolveUI/Workflow';

export const showConfirmDeleteModal = (onOk, dependentWorkflows?: IWorkflow[] | undefined) => {
  if (dependentWorkflows && dependentWorkflows.length > 0) {
    let content = '';
    if (dependentWorkflows.length === 1) {
      content = `The workflow named "${dependentWorkflows[0].name}" links to this workflow. You cannot delete it until you delete that link.`;
    } else {
      content = `There are ${dependentWorkflows.length} workflows that link to this workflow. You cannot delete it until you delete those links.`;
    }
    Modal.warning({
      title: `Can't Delete Workflow`,
      content,
      okText: 'OK',
      className: 'confirm-modal-no-icon',
      icon: <ExclamationCircleOutlined />,
      width: 600
    });
  } else {
    Modal.confirm({
      title: 'Delete Workflow',
      content: 'Deleting this workflow cannot be undone or reverted.',
      okText: 'Delete',
      cancelText: 'Cancel',
      className: 'confirm-modal-no-icon',
      icon: '',
      width: 600,
      onOk
    });
  }
};

export const showConfirmImportModal = onOk => {
  Modal.confirm({
    title: 'Paste and Replace',
    content: 'Pasting a workflow will replace the workflow (but will not automatically publish).',
    okText: 'Replace',
    cancelText: 'Cancel',
    className: 'confirm-modal-no-icon',
    icon: '',
    width: 600,
    onOk
    // onCancel: () => this.setState({ showConfirmImportModal: false })
  });
};

export const showConfirmToggleEnableModal = (disabled, unpublishedChanges, onOk, dependentWorkflows?: IWorkflow[]) => {
  let title;
  let content;

  if (!disabled && dependentWorkflows && dependentWorkflows.length > 0) {
    if (dependentWorkflows.length === 1) {
      content = `The workflow named "${dependentWorkflows[0].name}" links to this workflow. You cannot disable it until you delete that link.`;
    } else {
      content = `There are ${dependentWorkflows.length} workflows that link to this workflow. You cannot disable it until you delete those links.`;
    }
    Modal.warning({
      title: `Can't Disable Workflow`,
      content,
      okText: 'OK',
      className: 'confirm-modal-no-icon',
      icon: <ExclamationCircleOutlined />,
      width: 600
    });
  } else {
    if (!disabled) {
      title = 'Disable Workflow';
      content = 'Disabling this workflow will make it immediately no longer available';
    } else if (unpublishedChanges) {
      title = 'Enable Workflow with Unpublished Changes';
      content =
        'You can enable this workflow, but any unpublished changes will not be visible on the enabled live instance';
    } else {
      title = 'Enable Workflow';
      content = 'Enabling this workflow will make it immediately available to use';
    }

    Modal.confirm({
      title,
      content,
      okText: disabled ? 'Enable' : 'Disable',
      cancelText: 'Cancel',
      className: 'confirm-modal-no-icon',
      icon: '',
      width: 600,
      onOk
    });
  }
};

export const showConfirmSwitchToConversationalInstanceModal = onOk => {
  Modal.confirm({
    content: (
      <>
        <h3>Heads up! You're switching to a Conversational interface.</h3>
        <ul>
          <li>Any 'Header Text' in this workflow will be removed</li>
          <li>'Buttons' between paragraphs will be moved to after the paragraph</li>
        </ul>
      </>
    ),
    okText: 'Continue',
    cancelText: 'Cancel',
    className: 'confirm-modal-no-icon',
    icon: '',
    width: 600,
    onOk
  });
};
