import { Modal } from 'antd';
import { action, observable, reaction } from 'mobx';
import React from 'react';
import Cookies from 'universal-cookie';
import CountdownMessage from '../shared/components/CountdownMessage';
import { OrgStore } from './orgStore';

const SESSION_EXPIRATION_WARNING_SECONDS = 60; // how many seconds before the session expires to warn the user

export const IDLE_SESSION_TIMEOUT_HEADER = 'x-solvvy-session-idle-timeout';
export const ABSOLUTE_SESSION_TIMEOUT_HEADER = 'x-solvvy-session-absolute-timeout';
export const EXTEND_SESSION_HEADER = 'x-solvvy-extend-session';

const cookies = new Cookies();

export class AuthStore {
  @observable
  accessToken = cookies.get('solvvyACCTK');
  @observable
  tokenMaxAge = 0;

  orgStore: OrgStore;
  idleSessionExpirationWarningTimeout;
  idleSessionExpirationTimeout;
  absoluteSessionExpirationWarningTimeout;
  absoluteSessionExpirationTimeout;
  idleSessionExpirationWarningModal;
  absoluteSessionExpirationWarningModal;

  constructor() {
    reaction(
      () => this.accessToken,
      token => {
        if (token) {
          cookies.set('solvvyACCTK', token, {
            path: '/',
            maxAge: this.tokenMaxAge,
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            secure: process.env.REACT_APP_COOKIE_SECURE_FLAG === 'true',
            sameSite: 'lax'
          });
        } else {
          cookies.remove('solvvyACCTK', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
        }
      }
    );
  }

  @action
  setAccessToken(token: string, maxAge: number) {
    this.accessToken = token;
    this.tokenMaxAge = maxAge;
  }

  @action
  removeToken() {
    this.accessToken = '';
    cookies.remove('solvvyACCTK', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
  }

  setupIdleExpirationWarning(idleTimeoutInSeconds) {
    const timeoutSeconds = Math.max(0, idleTimeoutInSeconds - SESSION_EXPIRATION_WARNING_SECONDS);

    // set a timeout for the idle/inactivity timeout warning
    this.idleSessionExpirationWarningTimeout = setTimeout(() => {
      const countDownStart = Math.min(SESSION_EXPIRATION_WARNING_SECONDS, idleTimeoutInSeconds);

      // show idle timeout warning confirmation modal if not already being shown
      if (!this.idleSessionExpirationWarningModal && !this.absoluteSessionExpirationWarningModal) {
        this.idleSessionExpirationWarningModal = Modal.confirm({
          title: 'Session Expiration Warning',
          content: (
            <CountdownMessage
              startSeconds={countDownStart}
              message={value =>
                `For security reasons, your session will automatically expire in ${value} seconds unless you choose to stay logged in.`
              }
            />
          ),
          okText: 'Stay Logged In',
          cancelText: 'Log Out',
          className: 'confirm-modal-no-icon',
          width: 600,
          onOk: () => {
            this.orgStore.fetchUserInfo();
            this.idleSessionExpirationWarningModal = null;
          },
          onCancel: () => {
            this.orgStore.logout();
            this.idleSessionExpirationWarningModal = null;
          }
        });
      }

      // set timeout for actual expiration
      this.idleSessionExpirationTimeout = setTimeout(async () => {
        await this.orgStore.logout();
        this.orgStore.setLoginError('Your session has expired, please login again.');
      }, countDownStart * 1000);
    }, timeoutSeconds * 1000);
  }

  setupAbsoluteExpirationWarning(absoluteTimeoutInSeconds) {
    const timeoutSeconds = Math.max(0, absoluteTimeoutInSeconds - SESSION_EXPIRATION_WARNING_SECONDS);

    // set a timeout for the idle/inactivity timeout warning
    this.absoluteSessionExpirationWarningTimeout = setTimeout(() => {
      const countDownStart = Math.min(SESSION_EXPIRATION_WARNING_SECONDS, absoluteTimeoutInSeconds);

      // close modal from idle warning if it exists
      if (this.idleSessionExpirationWarningModal) {
        this.idleSessionExpirationWarningModal.destroy();
        this.idleSessionExpirationWarningModal = null;
      }

      // show absolute timeout warning confirmation modal if not already being shown
      if (!this.absoluteSessionExpirationWarningModal) {
        this.absoluteSessionExpirationWarningModal = Modal.confirm({
          title: 'Session Expiration Warning',
          content: (
            <CountdownMessage
              startSeconds={countDownStart}
              message={value => `For security reasons, your session will automatically expire in ${value} seconds.`}
            />
          ),
          okText: 'OK',
          cancelText: 'Log Out',
          className: 'confirm-modal-no-icon',
          width: 600,
          onOk: () => {
            this.absoluteSessionExpirationWarningModal = null;
          },
          onCancel: () => {
            this.orgStore.logout();
            this.absoluteSessionExpirationWarningModal = null;
          }
        });
      }

      // set timeout for actual expiration
      this.absoluteSessionExpirationTimeout = setTimeout(async () => {
        await this.orgStore.logout();
        this.orgStore.setLoginError('Your session has expired, please login again.');
      }, countDownStart * 1000);
    }, timeoutSeconds * 1000);
  }

  @action.bound
  setSessionTimeouts(idleTimeoutInSeconds?: number, absoluteTimeoutInSeconds?: number) {
    if (!idleTimeoutInSeconds || !absoluteTimeoutInSeconds) {
      return;
    }

    this.cancelSessionExpirationTimeouts(true);

    // setup the idle session expiration warning (unless absolute timeout is sooner)
    if (idleTimeoutInSeconds < absoluteTimeoutInSeconds) {
      this.setupIdleExpirationWarning(idleTimeoutInSeconds);
    }

    // setup the absolute timeout warning
    this.setupAbsoluteExpirationWarning(absoluteTimeoutInSeconds);
  }

  @action.bound
  cancelSessionExpirationTimeouts(dontCloseWarningModals: boolean = false) {
    // clear existing timeouts and modal
    if (this.idleSessionExpirationWarningTimeout) {
      clearTimeout(this.idleSessionExpirationWarningTimeout);
      this.idleSessionExpirationWarningTimeout = null;
    }
    if (this.idleSessionExpirationTimeout) {
      clearTimeout(this.idleSessionExpirationTimeout);
      this.idleSessionExpirationTimeout = null;
    }
    if (this.absoluteSessionExpirationWarningTimeout) {
      clearTimeout(this.absoluteSessionExpirationWarningTimeout);
      this.absoluteSessionExpirationWarningTimeout = null;
    }
    if (this.absoluteSessionExpirationTimeout) {
      clearTimeout(this.absoluteSessionExpirationTimeout);
      this.absoluteSessionExpirationTimeout = null;
    }
    if (!dontCloseWarningModals) {
      if (this.idleSessionExpirationWarningModal) {
        this.idleSessionExpirationWarningModal.destroy();
        this.idleSessionExpirationWarningModal = null;
      }
      if (this.absoluteSessionExpirationWarningModal) {
        this.absoluteSessionExpirationWarningModal.destroy();
        this.absoluteSessionExpirationWarningModal = null;
      }
    }
  }
}

export default new AuthStore();
