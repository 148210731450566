import { JSONSchema4 } from 'json-schema';
import { IDataSource } from '../data-source-types';
import { ITicketFormConfig } from './Workflow';

interface IDropdownsPreQuestionFormDataPlugin {
  type: 'DropdownsPreQuestionForm';
  options: {
    fieldIdWhitelist: string[];
  };
}
export const DROPDOWNS_PRE_QUESTION_FORM_DATA_SOURCE_ID = 'DropdownsPreQuestionFormDataSource';
export class DropdownsPreQuestionFormDataSource implements IDataSource {
  id: string = DROPDOWNS_PRE_QUESTION_FORM_DATA_SOURCE_ID;
  name: string = 'Pre-Question Form';
  variablesJsonSchema: JSONSchema4 = { properties: {} };

  constructor(plugin: IDropdownsPreQuestionFormDataPlugin, ticketFormConfig: ITicketFormConfig) {
    for (const form of ticketFormConfig.forms) {
      for (const [fieldId, property] of Object.entries(form.properties)) {
        if (plugin.options.fieldIdWhitelist.includes(fieldId) && this.variablesJsonSchema.properties) {
          this.variablesJsonSchema.properties[fieldId] = {
            ...(property as any),
            templateExpression: `{{userData.${fieldId}}}`
          };
        }
      }
    }
  }
}
