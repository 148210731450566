import { Alert, Button, Modal } from 'antd';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import useWindowSize from 'src/hooks/useWindowSize';
import ErrorPlaceHolder from 'src/shared/components/ErrorPlaceHolder';
import Loader from 'src/shared/components/Loader';
import { Colors } from 'src/shared/util/Colors';
import { typedInject } from '../../../store/rootStore';
import { WorkflowStore } from '../../../store/workflowStore';
import { CircularSankey } from './CircularSankey';
import StepAnalytics from './StepAnalytics';

import { InfoCircleOutlined } from '@ant-design/icons';
import DateRangeDropDown from '../ButonBreakdown/DateRangeDropDown';
import { INodeData } from './IWorkflowReporting';
import {
  AlertContainer,
  FilterContainer,
  OverviewContainer,
  SankeyErrorContainer,
  ScrollContainer
} from './WorkflowReporting.styles';

interface IProps {
  workflowStore: WorkflowStore;
}

const OverviewAnalytics = observer(({ workflowStore }: IProps) => {
  const {
    workflowToEdit: workflow,
    toggleOverviewAnalyticsModal,
    toggleStepAnalyticsModal,
    isOverviewAnalyticsModal,
    handleCustomDateRangeSelection,
    selectedStartDay,
    selectedEndDay,
    setActiveStepNumber,
    isStepAnalyticsModal,
    workflowButtonBreakdown,
    dataSankey,
    isValidSankey
  } = workflowStore;
  const [step, setStep] = useState<INodeData | null>(null);
  const windowSize = useWindowSize();

  const { height, width } = windowSize;

  const onNodeClicked = (data: INodeData) => {
    if (!data.id) {
      return;
    }
    toggleStepAnalyticsModal();
    setStep(data);
    setActiveStepNumber(data.stepNumber);
  };

  const onStepChange = (stepNumber: string | number) => {
    const stepString = stepNumber.toString();
    const curr = dataSankey.nodes.find(currStep => currStep.stepNumber === stepString);
    if (curr) {
      toggleStepAnalyticsModal();
      setStep(curr);
      setActiveStepNumber(stepString);
    }
  };

  const renderErrorMessage = () => {
    return (
      <SankeyErrorContainer>
        <InfoCircleOutlined style={{ fontSize: '60px', color: '#1890FF' }} />
        <br />
        <h3>This workflow hasn’t been used yet</h3>
        <p>Check back soon to see an overview of how your users are interacting with this workflow.</p>
        <Button
          type="primary"
          style={{
            background: Colors.wfReportingBlue
          }}
          onClick={workflowStore.goBack}
        >
          Back to Workflow Builder
        </Button>
      </SankeyErrorContainer>
    );
  };

  return (
    <>
      <Modal
        title={`Workflow Insights for '${workflow.name || 'Untitled Workflow'}'`}
        centered={true}
        visible={isOverviewAnalyticsModal}
        onCancel={toggleOverviewAnalyticsModal}
        footer={null}
        width={'98%'}
        style={{
          top: 5
        }}
      >
        <OverviewContainer>
          <FilterContainer>
            <DateRangeDropDown
              handleCustomDateRangeSelection={handleCustomDateRangeSelection}
              selectedStartDay={selectedStartDay}
              selectedEndDay={selectedEndDay}
              hideLabel={true}
            />
          </FilterContainer>
          <AlertContainer>
            <Alert
              message="View shown is from last published version within this date range."
              type="info"
              showIcon={true}
              closable={true}
            />
          </AlertContainer>
          <ScrollContainer>
            {workflowButtonBreakdown.pending && <Loader thingToWaitFor="Sankey" />}
            {workflowButtonBreakdown.rejected && (
              <ErrorPlaceHolder errorMsg="Oops something went wrong!!!. Try again later." />
            )}
            {workflowButtonBreakdown.fulfilled ? (
              <>
                {isValidSankey ? (
                  <CircularSankey data={dataSankey} width={width} height={height} onClick={onNodeClicked} />
                ) : (
                  renderErrorMessage()
                )}
              </>
            ) : null}
          </ScrollContainer>
        </OverviewContainer>
      </Modal>
      {isStepAnalyticsModal && <StepAnalytics stepData={step} onStepChange={onStepChange} />}
    </>
  );
});

export default typedInject('workflowStore')(OverviewAnalytics);
