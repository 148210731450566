import get from 'lodash/get';
import moment from 'moment-timezone';
import querystring from 'querystring';

import envService from '../../services/envService';

export const DEFAULT_ORG_SETTINGS = {
  timezone: 'America/Los_Angeles'
};

const ticketDownloadLink = params => {
  return `${envService.getBaseUrl()}/v1/pixels/export-queries?${querystring.stringify(params)}`;
};

const TicketDownloadDefaultFields = 'id,created_at,description,deflection';
export const ticketDownloadLinkCreator = (authStore, orgStore) => {
  return (startDate, endDate) => {
    const timezone = get(orgStore.orgSettings, 'timezone', DEFAULT_ORG_SETTINGS.timezone);
    const defaultFields = get(orgStore.orgSettings, 'default_export_fields', TicketDownloadDefaultFields);

    const params = {
      format: 'csv',
      org_id: orgStore.selectedOrgId,
      token: get(authStore, 'accessToken'),
      fields: defaultFields,
      start_at: moment(startDate)
        .tz(timezone)
        .startOf('day')
        .toISOString(),
      end_at: moment(endDate)
        .tz(timezone)
        .endOf('day')
        .toISOString()
    };

    return ticketDownloadLink(params);
  };
};
