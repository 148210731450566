import { OrgPermission } from '@solvvy/util/lib/authorization';
import { ReaderRole } from '@solvvy/util/lib/authorization/RoleModel';
import { cloneDeep, isEqual, pick } from 'lodash';
import startCase from 'lodash/startCase';
import { action, computed, observable, reaction, toJS as mobxToJs } from 'mobx';
import { asyncAction } from 'mobx-utils';
import { userService } from '../services';
import asyncComputed from '../shared/util/asyncComputed';
import { formatEmail } from '../shared/util/format';
import { NotificationManager } from '../shared/util/NotificationManager';
import { recordErrors } from '../shared/util/recordErrors';
import { IUserData, User } from './models/User';
import { OrgStore } from './orgStore';

const securePasswordGenerator = require('secure-random-password');

export class UserManagementStore {
  @computed
  get loggedInUser() {
    return this._orgStore.currentUserContext;
  }

  @observable
  createNewUser = false;

  @observable
  showAddOrEditUserModal = false;

  @observable
  userToEdit?: User;

  @observable
  savingUser = false;

  @observable
  users = asyncComputed<User[]>(async () => {
    const users = this._orgStore.currentOrgGroupId
      ? await userService.allUsersForOrgGroup(this._orgStore.currentOrgGroupId)
      : [];
    return observable(users.map(user => new User(user, this.isLoggedInUser(user))));
  });

  constructor(private _orgStore: OrgStore, private _notificationManager: NotificationManager) {
    reaction(
      () => this._orgStore.selectedOrgId,
      orgId => {
        this.users.refresh();
      }
    );
  }

  isLoggedInUser(user) {
    return user.id === this._orgStore.currentUserContext.id;
  }

  @asyncAction
  *createUser({ first_name, last_name, email, org_memberships }: IUserData) {
    try {
      this.savingUser = true;
      const securePassword = securePasswordGenerator.randomPassword({
        length: 10,
        characters: [
          { characters: securePasswordGenerator.upper, exactly: 1 },
          { characters: securePasswordGenerator.symbols, exactly: 1 },
          securePasswordGenerator.lower,
          securePasswordGenerator.digits
        ]
      });
      const createdUserData = yield userService.createUser(
        { first_name, last_name, password: securePassword },
        this._orgStore.selectedOrgId,
        formatEmail(email)
      );

      const createdOrgMemberships = yield Promise.all(
        org_memberships.map(({ org_id, receive_voc_email, roles }) =>
          userService.createOrgUser(org_id, createdUserData.id, receive_voc_email, roles)
        )
      );
      createdUserData.org_memberships = createdOrgMemberships;
      if (this.users.fulfilled) {
        this.users.value.push(new User(createdUserData));
      }

      this.closeAddOrEditUserModal();
      this._notificationManager.success({
        title: 'User Created',
        message: `User ${first_name} ${last_name} was successfully created `
      });
    } catch (e) {
      this._notificationManager.error({
        title: 'Create User',
        message: 'An unexpected error occurred. Please try again later.'
      });
      recordErrors(e);
    } finally {
      this.savingUser = false;
    }
  }

  @asyncAction
  *updateUser(user: User, newUserData: IUserData) {
    try {
      this.savingUser = true;

      const updatableUserFields = ['first_name', 'last_name', 'email'];
      const oldUser = pick(this.userToEdit, updatableUserFields);
      const newUser = pick(newUserData, updatableUserFields);
      if (!isEqual(oldUser, newUser)) {
        const updatedUser = yield userService.updateUser(user.id, newUser);
        user.updateCache(updatedUser);
      }

      const normalizeMemberships = rawOrgMemberships => {
        const updatableOrgMembershipFields = ['org_id', 'receive_voc_email'];
        if (!this.isLoggedInUser(user)) {
          // current user won't have permissions to update their own role, so exclude from request so they can still update VoC flags
          updatableOrgMembershipFields.push('roles');
        }

        const memberships = mobxToJs(rawOrgMemberships).map(membership => ({
          org_id: membership.org ? membership.org.id : membership.org_id,
          ...pick(membership, updatableOrgMembershipFields)
        }));
        memberships.sort((a, b) => a.org_id - b.org_id);
        return memberships;
      };
      const oldMemberships = normalizeMemberships(user.org_memberships);
      const newMemberships = normalizeMemberships(newUserData.org_memberships);
      if (!isEqual(oldMemberships, newMemberships)) {
        const updatedOrgMemberships = yield Promise.all(
          newMemberships
            // only send updates for orgs current user has permissions for
            .filter(({ org_id }) => this._orgStore.hasPermissionForOrg(org_id, OrgPermission.usersUpdate))
            .map(orgMembership => userService.updateOrgUser(user.id, orgMembership!.org_id, orgMembership))
        );
        for (const updatedOrgMembership of updatedOrgMemberships) {
          const existingMembership = user.org_memberships.find(({ org }) => org.id === updatedOrgMembership.org.id);
          if (existingMembership) {
            Object.assign(existingMembership, updatedOrgMembership);
          }
        }
      }

      // update current user context if user being edited is same as logged in user
      if (user.id === this._orgStore.currentUserContext.id) {
        this._orgStore.currentUserContext.updateCache(user);
      }

      const updateMessage = `User ${user.first_name} ${user.last_name} was successfully updated `;
      this.closeAddOrEditUserModal();
      this._notificationManager.success({
        title: 'User Updated',
        message: updateMessage
      });
    } catch (e) {
      this.savingUser = false;
      this._notificationManager.error({
        title: 'Update User',
        message: 'An unexpected error occurred. Please try again later.'
      });
      recordErrors(e);
    } finally {
      this.savingUser = false;
    }
  }

  canDeleteUser(user: User): boolean {
    return (
      !user.currentUser &&
      user.org_memberships.every(({ org }) => {
        if (!org.settings.is_active) {
          return true;
        }
        return this._orgStore.hasPermissionForOrg(org.id, OrgPermission.usersDelete);
      })
    );
  }

  @asyncAction
  *deleteUser(user: User) {
    try {
      if (this.users.fulfilled) {
        const userIndex = this.users.value.findIndex(u => u.id === user.id);
        if (userIndex > -1) {
          this.users.value.splice(userIndex, 1);
        }
      }
      yield user.destroy();
      this.closeAddOrEditUserModal();
      this._notificationManager.warn({
        title: 'Deleted User',
        message: `User ${user.full_name} is successfully removed.`
      });
    } catch (e) {
      this._notificationManager.error({
        title: 'Deleted User',
        message: 'An unexpected error occurred. Please try again later.'
      });
      recordErrors(e);
    }
  }

  @asyncAction
  *updateViewerVoCOrgs(orgs, user: User) {
    let message;
    if (orgs.length > 0) {
      message = `User ${user.full_name} will receive the VoC email for ${orgs.map(o => o.name).join(', ')}`;
    } else {
      message = `User ${user.full_name} will no longer receive the VoC email`;
    }
    try {
      user.updatingUser = true;
      const orgIds = orgs.map(org => org.id);
      yield user.updateUserVoCOrgs(orgIds);

      this._notificationManager.success({
        title: `User updated`,
        message
      });
      user.updatingUser = false;
      for (const orgMembership of user.org_memberships) {
        orgMembership.receive_voc_email = orgIds.includes(orgMembership.org.id);
      }

      // update user object in the user management list
      if (this.users.fulfilled) {
        const userInList = this.users.value.find(u => u.id === user.id);
        if (userInList) {
          userInList.org_memberships = cloneDeep(user.org_memberships);
        }
      }
    } catch (e) {
      user.updatingUser = false;
      this._notificationManager.error({
        title: `User not updated`,
        message: `We encountered some issues please try again after some time.`
      });
      recordErrors(e);
    }
  }
  @asyncAction
  *assignOrgRole(user: User, orgRole: string) {
    try {
      user.updatingUser = true;
      yield user.assignOrgGroupRole(user.org_group_id, orgRole.toUpperCase());
      this._notificationManager.success({
        title: 'Assigned Group Role',
        message: `User ${user.full_name} has now been assigned to the ${startCase(orgRole)} Role`
      });
      user.updatingUser = false;
      this.users.refresh();
    } catch (e) {
      user.updatingUser = false;
      this._notificationManager.error({
        title: 'Assigned Group Role',
        message: `We encountered some issues please try again after some time.`
      });
      recordErrors(e);
    }
  }

  @action
  showEditUserModal(user: User) {
    this.userToEdit = user;
    this.showAddOrEditUserModal = true;
  }

  @action
  showAddUserModal() {
    this.showAddOrEditUserModal = true;
    this.userToEdit = undefined;
  }

  @action
  closeAddOrEditUserModal() {
    this.showAddOrEditUserModal = false;
    this.userToEdit = undefined;
  }

  userToFormValues(user?: User): any {
    const { selectedOrgId } = this._orgStore;

    if (user) {
      const orgRoles = user.org_memberships.reduce(
        (acc, { org, roles }) => ({ ...acc, [org.id.toString()]: mobxToJs(roles) }),
        {}
      );

      const values = {
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        vocOrgs: user.vocOrgIds,
        orgRoles
      };
      return values;
    } else {
      const values = {
        vocOrgs: [selectedOrgId],
        orgRoles: { [selectedOrgId.toString()]: [ReaderRole.name] }
      };
      return values;
    }
  }
}
