import { allUsingApi, excludePaths } from './util';

const loadResolveUiConfiguration = async (api, groupId) => {
  const { data } = await api.get('/v1/resolve-ui-configurations/dashboard', { org_group_id: groupId });
  return data;
};

const updateById = async (api, id, newConfig) => {
  const { data } = await api.put(`/v1/resolve-ui-configurations/${id}`, excludePaths(newConfig));
  return data;
};

const postNewConfig = (api, newConfig) => {
  return api.post(
    `/v1/resolve-ui-configurations/`,
    excludePaths(newConfig, ['id', 'created_at', 'updated_at', 'lock_version'])
  );
};

const uploadArtifact = async (api, id, artifact) => {
  const { data } = await api.post(`/v1/resolve-ui-configurations/${id}/artifacts`, artifact);
  return data;
};

export const resolveUiConfigurationService = api => {
  return allUsingApi(api, { loadResolveUiConfiguration, updateById, postNewConfig, uploadArtifact });
};

export default resolveUiConfigurationService;
