import includes from 'lodash/includes';
import { observer } from 'mobx-react';
import React, { lazy, Suspense } from 'react';
import { ThemeProvider } from 'styled-components';

import {
  combinedOrgPageRoute,
  errorPageRoute,
  forgotPasswordRoute,
  globalUserPageRoute,
  homePageRoute,
  loginPageRoute,
  onboardingPageRoute,
  onboardingStatusRoute,
  orgPageRoute,
  passwordResetRoute,
  ssoCallbackRoute,
  twoFactorAuthPageRoute
} from '../routes/routes';
import Loader from '../shared/components/Loader';
import { Colors } from '../shared/util/Colors';
import { typedInject } from '../store/rootStore';
import { RouterStore } from '../store/routerStore';

const LoginLayout = lazy(() => import('./Login/LoginLayout'));
const SSOLayout = lazy(() => import('./Login/SSOLayout'));
const GlobalUserLayout = lazy(() => import('./GlobalUser/GlobalUserLayout'));
const Verify2FAAuthLayout = lazy(() => import('./2FAAuth/Verify2FAAuth'));
const MainLayout = lazy(() => import('./Main/MainLayout'));
const NotFoundLayout = lazy(() => import('./NotFound/NotFoundLayout'));
const OnboardingLayout = lazy(() => import('./Onboarding/OnboardingLayout'));
const ServiceProviderAuthResult = lazy(() => import('./ServiceProviderAuthResult/ServiceProviderAuthResult'));
const PasswordResetLayout = lazy(() => import('./Password/PasswordResetLayout'));
const ForgotPasswordLayout = lazy(() => import('./Password/ForgotPasswordLayout'));
const GroupLevelAnalyticsLayout = lazy(() => import('./GroupLevelReport/GroupLevelAnalytics'));

function renderActivePage(routerStore: RouterStore) {
  // Render the appropriate view based upon our current route
  if (includes(routerStore.activeRoutes, loginPageRoute) || includes(routerStore.activeRoutes, homePageRoute)) {
    return <LoginLayout />;
  } else if (includes(routerStore.activeRoutes, ssoCallbackRoute)) {
    return <SSOLayout />;
  } else if (includes(routerStore.activeRoutes, onboardingStatusRoute)) {
    return <ServiceProviderAuthResult />;
  } else if (includes(routerStore.activeRoutes, onboardingPageRoute)) {
    return <OnboardingLayout />;
  } else if (includes(routerStore.activeRoutes, passwordResetRoute)) {
    return <PasswordResetLayout />;
  } else if (includes(routerStore.activeRoutes, forgotPasswordRoute)) {
    return <ForgotPasswordLayout />;
  } else if (includes(routerStore.activeRoutes, globalUserPageRoute)) {
    return <GlobalUserLayout />;
  } else if (includes(routerStore.activeRoutes, twoFactorAuthPageRoute)) {
    return <Verify2FAAuthLayout />;
  } else if (includes(routerStore.activeRoutes, orgPageRoute)) {
    return <MainLayout />;
  } else if (includes(routerStore.activeRoutes, combinedOrgPageRoute)) {
    return <GroupLevelAnalyticsLayout />;
  } else if (includes(routerStore.activeRoutes, errorPageRoute)) {
    return <NotFoundLayout />;
  } else {
    return <LoginLayout />;
  }
}

export default typedInject('routerStore')(
  observer(({ routerStore }: { routerStore: RouterStore }) => (
    <ThemeProvider theme={Colors}>
      <Suspense fallback={<Loader thingToWaitFor="" />}>{renderActivePage(routerStore)}</Suspense>
    </ThemeProvider>
  ))
);
