import format from 'date-fns/format';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import reject from 'lodash/reject';
import { action, computed, observable, toJS } from 'mobx';
import { asyncAction } from 'mobx-utils';

import { feedbackService, searchService } from '../services';
import asyncComputed from '../shared/util/asyncComputed';
import { NotificationManager } from '../shared/util/NotificationManager';
import { PromiseState } from '../shared/util/PromiseStates';
import { recordErrors } from '../shared/util/recordErrors';
import Solution from './models/Solution';
import { OrgStore } from './orgStore';

interface IVotedQuerySolutionData {
  votedQueryDataSet: {
    queryInfo: any;
    votedSolutions: Solution[];
    suggestedSolutions: Solution[];
    offset: number;
    totalRemainingQuestions: number;
    createdAt?: string;
    updatedAt?: string;
  };
}

const InvalidQueryData = {
  query_id: '',
  content: 'The current query is no longer available. Skip to next question.'
};

const ALL_TIKA_USERS = [
  { value: '1978', label: 'Tika User 01: 1978' },
  { value: '1979', label: 'Tika User 02: 1979' },
  { value: '1980', label: 'Tika User 03: 1980' },
  { value: '1981', label: 'Tika User 04: 1981' },
  { value: '1982', label: 'Tika User 05: 1982' },
  { value: '2007', label: 'Tika User 06: 2007' },
  { value: '2008', label: 'Tika User 07: 2008' },
  { value: '2009', label: 'Tika User 08: 2009' },
  { value: '2010', label: 'Tika User 09: 2010' },
  { value: '2011', label: 'Tika User 10: 2011' },
  { value: '2012', label: 'Tika User 11: 2012' },
  { value: '2013', label: 'Tika User 12: 2013' },
  { value: '2014', label: 'Tika Manager 01: 2014' },
  { value: '2015', label: 'Tika Manager 02: 2015' },
  { value: '2776', label: 'Tika Manager 03: 2776' },
  { value: '2777', label: 'Tika Manager 04: 2777' },
  { value: '2778', label: 'Tika Manager 05: 2778' },
  { value: '2779', label: 'Tika Manager 06: 2779' },
  { value: '2780', label: 'Tika Manager 07: 2780' }
];

const BI_USERS = [
  { value: '1957', label: 'Brad: 1957' },
  { value: '1224', label: 'Chris: 1224' },
  { value: '2000', label: 'Jacob: 2000' },
  { value: '2005', label: 'Kaye: 2005' },
  { value: '1877', label: 'Tom: 1877' },
  { value: '2335', label: 'Tony: 2335' }
];

export default class VoteAuditStore {
  options = [
    {
      value: 'all_tika_users',
      label: 'All Tika Users',
      children: ALL_TIKA_USERS
    },
    {
      value: 'bi_users',
      label: 'BI Users',
      children: BI_USERS
    },
    { value: 'solvvy_feedback', label: 'Solvvy Feedback' },
    { value: 'expert_feedback', label: 'Expert Feedback' }
  ];

  @observable
  selectedUserValue: string[] = ['all_tika_users'];
  @observable
  solutionTab: number = 0; // This observable determines the open tab.
  @observable
  offset = 0; // The index of the current question.
  @observable
  skipDirection: string = 'next';
  @observable
  moreSolutions: Solution[] = []; // Array of solutions loaded from a search.
  @observable
  searchMoreValue = ''; // The solution search query.
  @observable
  saving: boolean = false; // Determine if we are currently saving.
  @observable
  loadingMoreSolutions: PromiseState = PromiseState.fulfilled; // Determines if search solutions are being loaded.
  @observable
  updatedSolutions: Solution[] = []; // The updated set of solutions. Checking to see if this attribute is still necessary.
  @computed
  get sourceType(): string {
    if (this.selectedUserValue[0] === 'expert_feedback') {
      return 'expert_feedback';
    } else {
      return 'solvvy_feedback';
    }
  }
  @computed
  get selectedUserIds(): string[] {
    if (this.selectedUserValue.length === 2) {
      return this.selectedUserValue.slice(1);
    } else if (this.selectedUserValue[0] === 'all_tika_users') {
      return ALL_TIKA_USERS.map(user => user.value);
    } else if (this.selectedUserValue[0] === 'bi_users') {
      return BI_USERS.map(user => user.value);
    } else {
      return [];
    }
  }

  shallowMoreSolutions: Solution[] = [];

  votedQueries = asyncComputed<IVotedQuerySolutionData>(async () => {
    let result;
    const orgId = this._orgStore.selectedOrFirstOrgId;
    if (this.selectedUserIds.length) {
      result = await feedbackService.getVotedQueriesBySourceIds(
        orgId,
        false,
        true,
        this.sourceType,
        this.selectedUserIds,
        this.offset
      );
    } else {
      result = await feedbackService.getVotedQueries(orgId, false, true, this.offset, this.sourceType);
    }

    const {
      data: [votedQueryData],
      headers: { 'x-total': totalQuestions }
    } = result;

    if (isEmpty(votedQueryData)) {
      return {
        votedQueryDataSet: {
          queryInfo: {},
          votedSolutions: [],
          suggestedSolutions: [],
          offset: 0,
          totalRemainingQuestions: 0
        }
      };
    }
    return this.loadQuerySolutionMetadata(
      votedQueryData.query_id,
      votedQueryData.created_at,
      votedQueryData.updated_at,
      totalQuestions,
      this.offset
    );
  });

  constructor(private _orgStore: OrgStore, public _notificationManager: NotificationManager) {}

  async loadQuerySolutionMetadata(query_id, created_at, updated_at, remainingQuestions, offset) {
    let votedQueryInfo;
    let votedSolutions: Solution[] = [];
    let suggestedSolutions: Solution[] = [];
    const [{ data: queryMetadata }, { data: selectedSolutionList }] = await Promise.all([
      searchService.getQueryInfo(query_id, this._orgStore.selectedOrgId),
      feedbackService.getAllVotedQueriesOld(query_id, 15, false, true, this.sourceType)
    ]);

    if (!isEmpty(queryMetadata)) {
      votedQueryInfo = queryMetadata[0];
      votedSolutions = await this.fetchVotedSolutionsData(query_id, selectedSolutionList);
      suggestedSolutions = await this.fetchSolutionsData(queryMetadata[0], votedSolutions);
    } else {
      InvalidQueryData.query_id = query_id;
      votedQueryInfo = InvalidQueryData;
    }

    return {
      // Set the question data.
      votedQueryDataSet: {
        queryInfo: votedQueryInfo,
        votedSolutions,
        suggestedSolutions,
        offset,
        totalRemainingQuestions: remainingQuestions,
        createdAt: created_at,
        updatedAt: updated_at
      }
    };
  }

  async fetchSolutionsData(queryData, votedSolutions) {
    let solutions: Solution[];
    const { data: solutionResp } = await searchService.search(
      // Use search API to get initial suggested solutions
      this._orgStore.selectedOrgId,
      queryData.content
    );
    // Reformat the solution data into an array.
    solutions = solutionResp.reduce<Solution[]>((result, respData) => {
      respData.solutions.map(value => {
        const votedSolution = votedSolutions.find(solution => solution.id === value.id);
        result.push(
          new Solution(
            queryData.query_id,
            value.id,
            value.content,
            value.metadata,
            votedSolution ? votedSolution.relevance : '0',
            value.resource.type,
            this._orgStore.currentUserContext.isGlobalUser ? 'solvvy_feedback' : 'expert_feedback',
            'direct',
            this._orgStore.currentUserContext.id.toString(),
            false
          )
        );
      });
      return result;
    }, []);

    return solutions;
  }

  async fetchVotedSolutionsData(queryId, selectedSolutionList) {
    let votedSolutions: Solution[] = [];
    if (!isEmpty(selectedSolutionList)) {
      const selectedSolutionsIds = selectedSolutionList.map(solution => solution.solution_id);
      const { data: filteredSolutions } = await searchService.getSolutionsInfo(selectedSolutionsIds);

      votedSolutions = filteredSolutions.map(data => {
        const selectedData = selectedSolutionList.filter(value => value.solution_id === data.id)[0];
        return new Solution(
          queryId,
          data.id,
          data.content,
          data.metadata,
          selectedData.relevance,
          selectedData.solution_resource_type,
          selectedData.source,
          selectedData.source_type,
          selectedData.source_id,
          selectedData.was_positive
        );
      });
    }
    return votedSolutions;
  }

  @action
  openSuggestedSolutions = () => {
    this.solutionTab = 0;
  };

  @action
  openSearchSolutions = () => {
    this.solutionTab = 1;
  };

  @action
  setSelectedUserValue = value => {
    this.selectedUserValue = value;
  };

  @asyncAction
  *searchMoreSolution(content: string) {
    this.loadingMoreSolutions = PromiseState.pending;
    this.moreSolutions = [];
    try {
      if (isEmpty(content)) {
        this.loadingMoreSolutions = PromiseState.fulfilled;
      } else {
        const { data: moreSolutions } = yield searchService.search(this._orgStore.selectedOrgId, content, [], 15);

        this.moreSolutions = moreSolutions.reduce((result, solution) => {
          solution.solutions.map(value => {
            result.push(
              new Solution(
                this.queryData.id,
                value.id,
                value.content,
                value.metadata,
                '0',
                value.resource.type,
                this._orgStore.currentUserContext.isGlobalUser ? 'solvvy_feedback' : 'expert_feedback',
                'direct',
                this._orgStore.currentUserContext.id.toString(),
                false
              )
            );
          });
          return result;
        }, []);
        this.shallowMoreSolutions = toJS(this.moreSolutions);
        this.loadingMoreSolutions = PromiseState.fulfilled;
      }
    } catch (e) {
      this.loadingMoreSolutions = PromiseState.rejected; // We hit an error.
      recordErrors(e);
    }
  }

  @asyncAction
  *saveAuditedFeedback() {
    try {
      this.saving = true;
      // Save the solutions to ml-data.votes. We link the solution ids to the question id (in queries).
      for (const solution of this.allSelectedSolutions) {
        yield feedbackService.saveAuditedFeedback({
          id: this._orgStore.selectedOrgId,
          userId: this._orgStore.currentUserContext.id.toString(),
          queryId: this.queryData.id,
          solutionId: solution.id,
          solutionType: solution.solution_resource_type,
          source_type: solution.source_type || 'direct',
          relevance: solution.relevance,
          sourceFeedback: solution.source,
          source_id: solution.source_id
        });
      }

      this._notificationManager.success({
        title: 'Your feedback saved!!!',
        message: `On to the next question!`
      });
      this.resetData();
      this.votedQueries.refresh(); // Go to the next question in the list.
    } catch (e) {
      this._notificationManager.error({
        title: 'Oops something went wrong',
        message: `Try after sometime or skip to next question.`
      });
      recordErrors(e);
      return e;
    }
  }

  @action
  increaseOffset = (currentOffsetValue = this.offset) => {
    this.resetData();
    this.offset = currentOffsetValue + 1;
  };

  @action
  decreaseOffset = (currentOffsetValue = this.offset) => {
    this.resetData();
    this.offset = currentOffsetValue - 1;
  };

  @action
  resetData() {
    this.updatedSolutions = [];
    this.moreSolutions = [];
    this.searchMoreValue = '';
    this.shallowMoreSolutions = [];
    this.saving = false;
    this.solutionTab = 0;
  }

  @action
  showSolutionLimitNotification() {
    this._notificationManager.info({
      title: 'Unable to cast your vote!',
      message: `Only 2 Perfect Answers are allowed.`
    });
  }

  @action
  updateVotedRelevance(solution: Solution, relevanceValue: string) {
    if (findIndex(this.votedSolutionData, { id: solution.id }) !== -1) {
      // Todo: Need to make this more generic.
      const suggestedSolution = find(this.suggestedSolutionsData, data => data.id === solution.id);
      suggestedSolution ? (suggestedSolution.relevance = relevanceValue) : '';
      const votedSolution = find(this.votedSolutionData, data => data.id === solution.id);
      votedSolution ? (votedSolution.relevance = relevanceValue) : '';
      solution.relevance = relevanceValue;
    } else if (findIndex(this.suggestedSolutionsData, { id: solution.id }) !== -1) {
      const selectedSolution = find(this.suggestedSolutionsData, data => data.id === solution.id);
      selectedSolution ? (selectedSolution.relevance = relevanceValue) : '';
      solution.relevance = relevanceValue;
    } else {
      this.updateMoreSolutionRelevance(solution, relevanceValue);
    }
  }

  @action
  updateMoreSolutionRelevance(solution: Solution, relevanceValue: string) {
    this.updateRelevance(solution, relevanceValue, this.moreSolutions, this.shallowMoreSolutions);
  }

  @action
  updateRelevance(
    solution: Solution,
    relevanceValue: string,
    solutionsArray: Solution[],
    shallowSolutionsArray: Solution[]
  ) {
    let updatedRelevance = relevanceValue;
    if (findIndex(solutionsArray, { id: solution.id, relevance: relevanceValue }) === -1) {
      solution.relevance = relevanceValue;
    } else {
      solution.relevance = '0';
      updatedRelevance = '0';
    }

    if (findIndex(shallowSolutionsArray, { id: solution.id, relevance: updatedRelevance }) === -1) {
      if (findIndex(this.updatedSolutions, { id: solution.id }) === -1) {
        this.updatedSolutions.push(solution);
      }
    } else {
      this.updatedSolutions = reject(this.updatedSolutions, { id: solution.id });
    }
  }

  @computed
  get totalQuestionsAvailable() {
    if (this.votedQueries.fulfilled) {
      return this.votedQueries.value.votedQueryDataSet.totalRemainingQuestions;
    } else {
      return 0;
    }
  }

  @computed
  get orgName() {
    return this._orgStore.userOrg.name;
  }

  @computed
  get queryData() {
    if (this.votedQueries.fulfilled) {
      return this.votedQueries.value.votedQueryDataSet.queryInfo;
    } else {
      return {};
    }
  }

  @computed
  get queryDate() {
    if (this.votedQueries.fulfilled && this.votedQueries.value.votedQueryDataSet.queryInfo.created_at) {
      return format(new Date(this.votedQueries.value.votedQueryDataSet.queryInfo.created_at), 'MMM Do YY');
    } else {
      return '';
    }
  }

  @computed
  get voteCreatedDate() {
    if (this.votedQueries.fulfilled && this.votedQueries.value.votedQueryDataSet.updatedAt) {
      return format(new Date(this.votedQueries.value.votedQueryDataSet.updatedAt), 'MMM Do YY');
    } else {
      return '';
    }
  }

  @computed
  get votedSolutionData() {
    if (this.votedQueries.fulfilled) {
      return this.votedQueries.value.votedQueryDataSet.votedSolutions;
    } else {
      return [];
    }
  }

  @computed
  get suggestedSolutionsData() {
    if (this.votedQueries.fulfilled) {
      return this.votedQueries.value.votedQueryDataSet.suggestedSolutions;
    } else {
      return [];
    }
  }

  @computed
  get hasSelectedSolutionsData() {
    return this.allSelectedSolutions.length > 0;
  }

  @computed
  get numberOfSuggestedSolutions() {
    return this.suggestedSolutionsData.filter(solution => solution.relevance === '0').length;
  }

  @computed
  get allSelectedSolutions() {
    // Gets all of the selected solutions in a merged list.
    return [
      ...this.votedSolutionData,
      ...this.suggestedSolutionsData.filter(solution => {
        const votedSolution = this.votedSolutionData.find(data => data.id === solution.id);
        return solution.relevance !== '0' && !votedSolution;
      }),
      ...toJS(this.updatedSolutions)
    ];
  }

  @computed
  get allRelevantSelectedSolutions() {
    return [
      ...this.votedSolutionData.filter(solution => solution.relevance !== '0'),
      ...this.suggestedSolutionsData.filter(solution => {
        const votedSolution = this.votedSolutionData.find(data => data.id === solution.id);
        return solution.relevance !== '0' && !votedSolution;
      }),
      ...toJS(this.updatedSolutions)
    ];
  }
}
