import * as joint from 'jointjs/dist/joint';
import { isNil } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { IAsyncComputed } from 'src/shared/util/asyncComputed';
import { SINGLE_IFRAME_IMAGE_PATTERN } from 'src/shared/util/wysiwygUtil';
import { IDataSource } from 'src/store/data-source-types';
import { DataSourcesStore } from 'src/store/dataSourcesStore';
import { DEFAULT_DELAY_IN_MS } from 'src/store/ResolveUI/constants';
import { DialogType } from 'src/store/ResolveUI/types';
import { Colors } from '../../../shared/util/Colors';
import {
  IAction,
  IBaseComponent,
  IBaseStep,
  IComponentOptions,
  IScreenActionOptions,
  ISupportActionOptions,
  IWorkflow,
  IWorkflowError
} from '../../../store/ResolveUI/Workflow';
import { WorkflowStore } from '../../../store/workflowStore';
import { STEP_DISPLAY_TYPE, WORKFLOW_PURPOSE } from '../constants';
import { JOINTJS_CONTAINER_CLASS } from '../WorkflowEdit';

const placeholderCommentsBox = `<div style="height: 75px; margin-top: 4px; border: 1px solid #EAEAEA; border-radius: 4px; box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.13); background-color: #FFFFFF;"></div>`;

const exitIconSvg = className =>
  `<svg class="${className}" viewBox="64 64 896 896"><path d="M888.3 757.4h-53.8c-4.2 0-7.7 3.5-7.7 7.7v61.8H197.1V197.1h629.8v61.8c0 4.2 3.5 7.7 7.7 7.7h53.8c4.2 0 7.7-3.4 7.7-7.7V158.7c0-17-13.7-30.7-30.7-30.7H158.7c-17 0-30.7 13.7-30.7 30.7v706.6c0 17 13.7 30.7 30.7 30.7h706.6c17 0 30.7-13.7 30.7-30.7V765.1c0-4.3-3.5-7.7-7.7-7.7zm18.6-251.7L765 393.7c-5.3-4.2-13-.4-13 6.3v76H438c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 000-12.6z"></path></svg>`;
const rightIconSvg = className =>
  `<svg class="${className}" viewBox="64 64 896 896"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg>`;
const linkIconSvg = className =>
  `<svg class="${className}" viewBox="64 64 896 896"><path d="M574 665.4a8.03 8.03 0 00-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 00-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 000 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 000 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 00-11.3 0L372.3 598.7a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z" /></svg>`;
const brokenLinkIconSvg = className =>
  `<svg class="${className}" viewBox="64 64 896 896"><path d="M832.6 191.4c-84.6-84.6-221.5-84.6-306 0l-96.9 96.9 51 51 96.9-96.9c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204l-96.9 96.9 51.1 51.1 96.9-96.9c84.4-84.6 84.4-221.5-.1-306.1zM446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l96.9-96.9-51.1-51.1-96.9 96.9c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l96.9-96.9-51-51-96.8 97zM260.3 209.4a8.03 8.03 0 00-11.3 0L209.4 249a8.03 8.03 0 000 11.3l554.4 554.4c3.1 3.1 8.2 3.1 11.3 0l39.6-39.6c3.1-3.1 3.1-8.2 0-11.3L260.3 209.4z"></path></svg>`;
// const questionIconSvg = className =>
//   `<svg class="${className}" viewBox="64 64 896 896"><path d="M764 280.9c-14-30.6-33.9-58.1-59.3-81.6C653.1 151.4 584.6 125 512 125s-141.1 26.4-192.7 74.2c-25.4 23.6-45.3 51-59.3 81.7-14.6 32-22 65.9-22 100.9v27c0 6.2 5 11.2 11.2 11.2h54c6.2 0 11.2-5 11.2-11.2v-27c0-99.5 88.6-180.4 197.6-180.4s197.6 80.9 197.6 180.4c0 40.8-14.5 79.2-42 111.2-27.2 31.7-65.6 54.4-108.1 64-24.3 5.5-46.2 19.2-61.7 38.8a110.85 110.85 0 00-23.9 68.6v31.4c0 6.2 5 11.2 11.2 11.2h54c6.2 0 11.2-5 11.2-11.2v-31.4c0-15.7 10.9-29.5 26-32.9 58.4-13.2 111.4-44.7 149.3-88.7 19.1-22.3 34-47.1 44.3-74 10.7-27.9 16.1-57.2 16.1-87 0-35-7.4-69-22-100.9zM512 787c-30.9 0-56 25.1-56 56s25.1 56 56 56 56-25.1 56-56-25.1-56-56-56z" /></svg>`;
const userIconSvg = className =>
  `<svg class="${className}" viewBox="64 64 896 896"><path d="M858.5 763.6a374 374 0 00-80.6-119.5 375.63 375.63 0 00-119.5-80.6c-.4-.2-.8-.3-1.2-.5C719.5 518 760 444.7 760 362c0-137-111-248-248-248S264 225 264 362c0 82.7 40.5 156 102.8 201.1-.4.2-.8.3-1.2.5-44.8 18.9-85 46-119.5 80.6a375.63 375.63 0 00-80.6 119.5A371.7 371.7 0 00136 901.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8 2-77.2 33-149.5 87.8-204.3 56.7-56.7 132-87.9 212.2-87.9s155.5 31.2 212.2 87.9C779 752.7 810 825 812 902.2c.1 4.4 3.6 7.8 8 7.8h60a8 8 0 008-8.2c-1-47.8-10.9-94.3-29.5-138.2zM512 534c-45.9 0-89.1-17.9-121.6-50.4S340 407.9 340 362c0-45.9 17.9-89.1 50.4-121.6S466.1 190 512 190s89.1 17.9 121.6 50.4S684 316.1 684 362c0 45.9-17.9 89.1-50.4 121.6S557.9 534 512 534z" /></svg>`;
// const warningIconSvg = className =>
//   `<svg class="${className}" viewBox="64 64 896 896"><path d="M464 720a48 48 0 1096 0 48 48 0 10-96 0zm16-304v184c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V416c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8zm475.7 440l-416-720c-6.2-10.7-16.9-16-27.7-16s-21.6 5.3-27.7 16l-416 720C56 877.4 71.4 904 96 904h832c24.6 0 40-26.6 27.7-48zm-783.5-27.9L512 239.9l339.8 588.2H172.2z" /></svg>`;
const cloudIconSvg = className =>
  `<svg class="${className}" viewBox="64 64 896 896"><path d="M811.4 418.7C765.6 297.9 648.9 212 512.2 212S258.8 297.8 213 418.6C127.3 441.1 64 519.1 64 612c0 110.5 89.5 200 199.9 200h496.2C870.5 812 960 722.5 960 612c0-92.7-63.1-170.7-148.6-193.3zm36.3 281a123.07 123.07 0 01-87.6 36.3H263.9c-33.1 0-64.2-12.9-87.6-36.3A123.3 123.3 0 01140 612c0-28 9.1-54.3 26.2-76.3a125.7 125.7 0 0166.1-43.7l37.9-9.9 13.9-36.6c8.6-22.8 20.6-44.1 35.7-63.4a245.6 245.6 0 0152.4-49.9c41.1-28.9 89.5-44.2 140-44.2s98.9 15.3 140 44.2c19.9 14 37.5 30.8 52.4 49.9 15.1 19.3 27.1 40.7 35.7 63.4l13.8 36.5 37.8 10c54.3 14.5 92.1 63.8 92.1 120 0 33.1-12.9 64.3-36.3 87.7z"></path></svg>`;
const branchesIconSvg = className =>
  `<svg class="${className}" viewBox="64 64 896 896"><path d="M740 161c-61.8 0-112 50.2-112 112 0 50.1 33.1 92.6 78.5 106.9v95.9L320 602.4V318.1c44.2-15 76-56.9 76-106.1 0-61.8-50.2-112-112-112s-112 50.2-112 112c0 49.2 31.8 91 76 106.1V706c-44.2 15-76 56.9-76 106.1 0 61.8 50.2 112 112 112s112-50.2 112-112c0-49.2-31.8-91-76-106.1v-27.8l423.5-138.7a50.52 50.52 0 0034.9-48.2V378.2c42.9-15.8 73.6-57 73.6-105.2 0-61.8-50.2-112-112-112zm-504 51a48.01 48.01 0 0196 0 48.01 48.01 0 01-96 0zm96 600a48.01 48.01 0 01-96 0 48.01 48.01 0 0196 0zm408-491a48.01 48.01 0 010-96 48.01 48.01 0 010 96z"></path></svg>`;
// const plusIconSvg = className =>
//   `<svg class="${className}" viewBox="64 64 896 896"><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path><path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path></svg>`;
const codeIconSvg = className =>
  `<svg class="${className}" viewBox="64 64 896 896"><path d="M516 673c0 4.4 3.4 8 7.5 8h185c4.1 0 7.5-3.6 7.5-8v-48c0-4.4-3.4-8-7.5-8h-185c-4.1 0-7.5 3.6-7.5 8v48zm-194.9 6.1l192-161c3.8-3.2 3.8-9.1 0-12.3l-192-160.9A7.95 7.95 0 00308 351v62.7c0 2.4 1 4.6 2.9 6.1L420.7 512l-109.8 92.2a8.1 8.1 0 00-2.9 6.1V673c0 6.8 7.9 10.5 13.1 6.1zM880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-40 728H184V184h656v656z"></path></svg>`;
const workflowIconSvg = className =>
  `<svg class="${className}" viewBox="64 64 896 896" ><path d="M888 680h-54V540H546v-92h238c8.8 0 16-7.2 16-16V168c0-8.8-7.2-16-16-16H240c-8.8 0-16 7.2-16 16v264c0 8.8 7.2 16 16 16h238v92H190v140h-54c-4.4 0-8 3.6-8 8v176c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8V688c0-4.4-3.6-8-8-8h-54v-72h220v72h-54c-4.4 0-8 3.6-8 8v176c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8V688c0-4.4-3.6-8-8-8h-54v-72h220v72h-54c-4.4 0-8 3.6-8 8v176c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8V688c0-4.4-3.6-8-8-8zM256 805.3c0 1.5-1.2 2.7-2.7 2.7h-58.7c-1.5 0-2.7-1.2-2.7-2.7v-58.7c0-1.5 1.2-2.7 2.7-2.7h58.7c1.5 0 2.7 1.2 2.7 2.7v58.7zm288 0c0 1.5-1.2 2.7-2.7 2.7h-58.7c-1.5 0-2.7-1.2-2.7-2.7v-58.7c0-1.5 1.2-2.7 2.7-2.7h58.7c1.5 0 2.7 1.2 2.7 2.7v58.7zM288 384V216h448v168H288zm544 421.3c0 1.5-1.2 2.7-2.7 2.7h-58.7c-1.5 0-2.7-1.2-2.7-2.7v-58.7c0-1.5 1.2-2.7 2.7-2.7h58.7c1.5 0 2.7 1.2 2.7 2.7v58.7zM360 300a40 40 0 1080 0 40 40 0 10-80 0z"></path></svg>`;
const timeDelayIconSvg = ({ className, x, y }) =>
  `<svg x="${x}" y="${y}" class="${className}" viewBox="64 64 896 896"><defs><style /></defs><path d="M945 412H689c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h256c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM811 548H689c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h122c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM477.3 322.5H434c-6.2 0-11.2 5-11.2 11.2v248c0 3.6 1.7 6.9 4.6 9l148.9 108.6c5 3.6 12 2.6 15.6-2.4l25.7-35.1v-.1c3.6-5 2.5-12-2.5-15.6l-126.7-91.6V333.7c.1-6.2-5-11.2-11.1-11.2z" /><path d="M804.8 673.9H747c-5.6 0-10.9 2.9-13.9 7.7a321 321 0 01-44.5 55.7 317.17 317.17 0 01-101.3 68.3c-39.3 16.6-81 25-124 25-43.1 0-84.8-8.4-124-25-37.9-16-72-39-101.3-68.3s-52.3-63.4-68.3-101.3c-16.6-39.2-25-80.9-25-124 0-43.1 8.4-84.7 25-124 16-37.9 39-72 68.3-101.3 29.3-29.3 63.4-52.3 101.3-68.3 39.2-16.6 81-25 124-25 43.1 0 84.8 8.4 124 25 37.9 16 72 39 101.3 68.3a321 321 0 0144.5 55.7c3 4.8 8.3 7.7 13.9 7.7h57.8c6.9 0 11.3-7.2 8.2-13.3-65.2-129.7-197.4-214-345-215.7-216.1-2.7-395.6 174.2-396 390.1C71.6 727.5 246.9 903 463.2 903c149.5 0 283.9-84.6 349.8-215.8a9.18 9.18 0 00-8.2-13.3z" /></svg>`;

// extend joint.shapes namespace
declare module 'jointjs' {
  namespace shapes {
    namespace solvvy {
      class WorkflowStep extends joint.dia.Element {
        static createFromStep(
          step: IBaseStep,
          errors: IWorkflowError[],
          workflow: IWorkflow,
          options?: {
            fullyEditable?: boolean;
            isEntryStep?: boolean;
            isConditionalEntryStep?: boolean;
            dialogType?: DialogType;
          },
          dataSources?: IAsyncComputed<IDataSource[]>
        ): WorkflowStep;
      }
      const STEP_WIDTH: number;
    }
  }
}

const STEP_WIDTH = 250;
const STEP_BORDER_RADIUS = 8;
const STEP_BORDER_WIDTH = 2;
const STEP_PADDING = 16;
const HEADER_FONT_SIZE = 15;
const HEADER_LINE_HEIGHT = HEADER_FONT_SIZE * 1.2;
const HEADER_PADDING = 5;
const BODY_FONT_SIZE = 14;
const PADDING_BETWEEN_COMPONENTS = 10;
const BUTTON_PADDING = 9;
const REPEATING_GROUP_PADDING = 15;

const STEP_TAB_SIZE = 33;
const STEP_TAB_BORDER_RADIUS = 4;
const ENTRY_STEP_TAB_TOP_OFFSET = 50;
const ENTRY_STEP_TAB_ICON_SIZE = 16;
const EXIT_STEP_BOTTOM_OFFSET = 10;

const ACTION_BUTTON_ICON_SIZE = 14;

const XML_SERIALIZER = new XMLSerializer();

function addComponent(
  type: string,
  text: string | undefined,
  childComponents: IBaseComponent[] | undefined,
  options: IComponentOptions,
  componentIndexes: number[],
  commonAttrs: joint.attributes.SVGAttributes,
  maxWidth: number,
  padding: number,
  errors: IWorkflowError[],
  workflow: IWorkflow,
  markup: string[],
  attrs: joint.dia.Cell.Selectors,
  ports: any[],
  redrawStep?: () => void,
  dialogType?: DialogType,
  position?: number,
  dataSources?: IAsyncComputed<IDataSource[]>,
  stepDisplayType?: string,
  isRepeatingGroupChildComponent?: boolean
): number {
  const selector = `component-${componentIndexes.join('-')}`;

  if (type === 'paragraph' || type === 'textarea') {
    const isConversational = dialogType === DialogType.Conversational;
    const isMediaPrompt = isConversational && new RegExp(SINGLE_IFRAME_IMAGE_PATTERN).test(text || '');
    const isSurveyWorkflow = isConversational && workflow.purpose === WORKFLOW_PURPOSE.SURVEY;
    const isOverlayStep = isSurveyWorkflow && stepDisplayType === STEP_DISPLAY_TYPE.OVERLAY;
    const isCarouselStep = isConversational && stepDisplayType === STEP_DISPLAY_TYPE.CAROUSEL;
    const isOverlayStepTextarea = isOverlayStep && type === 'textarea';
    const showTimeDelayIcon = isConversational && !isOverlayStep && !(isCarouselStep && isRepeatingGroupChildComponent);

    // convert the HTML to XHTML so it can be properly inserted into SVG <foreignObject> tag without XML parsing errors
    const xhtmlDiv = document.createElement('div');

    text = DataSourcesStore.replaceTemplateExpressionsToVariableNames(text, dataSources);

    if (isOverlayStepTextarea) {
      xhtmlDiv.innerHTML = `${options.title || ''}${placeholderCommentsBox}`;
    } else {
      xhtmlDiv.innerHTML = text || '';
    }
    const xhtml = XML_SERIALIZER.serializeToString(xhtmlDiv);

    // create temporary div with HTML content just to get height
    const rulerDiv = document.createElement('div');
    rulerDiv.classList.add('jointjs-paragraph-ruler-div');
    rulerDiv.innerHTML = xhtml;
    rulerDiv.style.visibility = 'hidden';
    rulerDiv.style.fontSize = isOverlayStep && position === 1 ? '18px' : '14px';
    rulerDiv.style.width = `${maxWidth - padding * 2}px`;

    if (isConversational && !isMediaPrompt && !isOverlayStep) {
      rulerDiv.style.paddingTop = '24px';
      rulerDiv.style.padding = '12px 8px';
    }

    const container = document.querySelector(`.${JOINTJS_CONTAINER_CLASS}`);
    if (container) {
      container.appendChild(rulerDiv);
    } else {
      throw new Error('jointJS container not found');
    }
    const componentHeight = rulerDiv.offsetHeight;

    // wait for images to load and then trigger redraw of step since the height needs to change
    let imagesLoadedCount = 0;
    const images = rulerDiv.querySelectorAll('img');
    if (images.length > 0 && redrawStep) {
      const imageLoaded = () => {
        imagesLoadedCount += 1;
        // when all images loaded, remove ruler div and redraw step
        if (imagesLoadedCount >= images.length) {
          rulerDiv.remove();
          redrawStep();
        }
      };
      images.forEach(img => {
        if (img.complete) {
          imageLoaded();
        } else {
          img.addEventListener('load', imageLoaded, false);
        }
      });
    } else {
      rulerDiv.remove();
    }

    const style: React.CSSProperties =
      isConversational && !isOverlayStep
        ? {
            backgroundColor: Colors.grey_19,
            color: Colors.grey_13,
            borderRadius: '18px',
            padding: isMediaPrompt ? '0px' : '12px 8px'
          }
        : {};

    if (isOverlayStep && position === 1) {
      style.fontSize = '18px';
    }

    markup.push(
      `<foreignObject class="${selector}"><div xmlns="http://www.w3.org/1999/xhtml">${xhtml}</div></foreignObject>`
    );

    showTimeDelayIcon && addPromptDelayIcon({ markup, commonAttrs, attrs, maxWidth, options, position });

    attrs[`.${selector}`] = {
      ...commonAttrs,
      x: commonAttrs.x + padding,
      // refWidth: -padding * 2,
      height: componentHeight,
      width: maxWidth - 2 * padding,
      style
    };
    const imgStyles = {
      ...(isMediaPrompt
        ? {
            height: componentHeight,
            minWidth: '100%',
            minHeight: '100%'
          }
        : {
            width: '85%',
            margin: 'auto'
          })
    };

    attrs[`.${selector} img[src]`] = {
      style: imgStyles
    };

    if (isMediaPrompt) {
      attrs[`.${selector} iframe[src]`] = {
        width: '100%',
        height: componentHeight
      };
    }

    return componentHeight;
  } else if (type === 'button') {
    // const componentErrors = WorkflowStore.getComponentErrors(errors, componentIndexes[componentIndexes.length - 1]);
    const hasStepIdError = errors.length > 0 && errors.some(e => e.field === 'screenId');
    const hasOtherError = errors.length > 0 && !errors.some(e => e.field === 'screenId');

    const wrappedText = joint.util.breakText(text || '', { width: maxWidth - padding * 2 });

    const buttonBorderWidth = 1;
    const buttonHeight = BUTTON_PADDING * 2 + BODY_FONT_SIZE * wrappedText.split('\n').length;
    let color = Colors.solvvyBlue;
    if ((options.action?.options as ISupportActionOptions)?.assistedSupport) {
      color = Colors.sunsetOrange;
    } else if (hasStepIdError) {
      color = Colors.grey_10;
    } else if (hasOtherError) {
      color = Colors.solvvyRed;
    } else if (options.action && options.action.type === 'condition') {
      color = Colors.solvvyMediumPurple;
    }

    const buttonSelector = selector + '-button';
    markup.push(`<rect class="${buttonSelector}" />`);
    attrs[`.${buttonSelector}`] = {
      ...commonAttrs,
      x: commonAttrs.x + padding,
      // refWidth: -padding * 2,
      width: maxWidth - 2 * padding,
      height: buttonHeight,
      fill: 'transparent',
      stroke: color,
      strokeWidth: buttonBorderWidth
    };

    // add warning icon if error
    // if (hasError) {
    //   const iconSelector = selector + '-warning-icon';
    //   markup.push(warningIconSvg(iconSelector));
    //   attrs[`.${iconSelector}`] = {
    //     x: commonAttrs.x + ACTION_BUTTON_ICON_SIZE / 2,
    //     y: commonAttrs.y + buttonHeight / 2 - ACTION_BUTTON_ICON_SIZE / 2,
    //     width: ACTION_BUTTON_ICON_SIZE,
    //     height: ACTION_BUTTON_ICON_SIZE,
    //     fill: Colors.solvvyRed,
    //     stroke: Colors.solvvyRed
    //   };
    // }

    const textSelector = selector + '-text';
    markup.push(`<text class="${textSelector}" />`);
    attrs[`.${textSelector}`] = {
      ...commonAttrs,
      x: commonAttrs.x + maxWidth / 2,
      y: commonAttrs.y + BODY_FONT_SIZE + BUTTON_PADDING - 2,
      height: buttonHeight,
      textAnchor: 'middle',
      fill: color,
      text: wrappedText
    };

    const addActionIcon = (iconSvgFn, iconBackgroundClassName?: string) => {
      const iconBackgroundSelector = selector + '-icon-background';
      markup.push(`<circle class="${iconBackgroundSelector} ${iconBackgroundClassName}" />`);
      attrs[`.${iconBackgroundSelector}`] = {
        cx: commonAttrs.x + maxWidth - padding,
        cy: commonAttrs.y + buttonHeight / 2,
        r: 12,
        stroke: color,
        fill: color
      };

      const iconSelector = selector + '-icon';
      markup.push(iconSvgFn(iconSelector));
      attrs[`.${iconSelector}`] = {
        x: commonAttrs.x + maxWidth - padding - ACTION_BUTTON_ICON_SIZE / 2,
        y: commonAttrs.y + buttonHeight / 2 - ACTION_BUTTON_ICON_SIZE / 2,
        width: ACTION_BUTTON_ICON_SIZE,
        height: ACTION_BUTTON_ICON_SIZE,
        fill: 'white',
        stroke: 'white'
      };
    };

    const buttonAction = options.action || ({} as IAction);
    const { type: actionType } = buttonAction;
    if (actionType === 'screen' || actionType === 'condition') {
      const actionOptions = buttonAction.options as IScreenActionOptions;
      if (hasStepIdError) {
        addActionIcon(rightIconSvg, 'unlinked-step-action');
      } else {
        const portBackgroundSelector = selector + '-port-background';
        markup.push(`<circle class="${portBackgroundSelector}" />`);
        attrs[`.${portBackgroundSelector}`] = {
          cx: commonAttrs.x + maxWidth - padding,
          cy: commonAttrs.y + buttonHeight / 2,
          r: 12,
          strokeWidth: 1,
          stroke: color,
          fill: color,
          ...(actionOptions && actionOptions.screenId ? { fill: color } : {})
        };

        if (actionType === 'condition') {
          addActionIcon(branchesIconSvg);
        } else {
          const targetStep = workflow.workingVersion.steps.find(s => s.id === actionOptions?.screenId);
          const portTextSelector = selector + '-port-text';
          const portLabelFontSize =
            actionOptions && actionOptions.screenId && actionOptions.screenId.length <= 2 ? 14 : 12;
          markup.push(`<text class="${portTextSelector}" />`);
          attrs[`.${portTextSelector}`] = {
            x: commonAttrs.x + maxWidth - padding,
            y: commonAttrs.y + buttonHeight / 2 + portLabelFontSize / 2 - 2,
            text: targetStep?.displayId || '',
            textAnchor: 'middle',
            fontSize: portLabelFontSize,
            fill: color,
            ...(actionOptions?.screenId ? { fill: 'white' } : {})
          };
        }
      }

      ports.push({
        id: componentIndexes.join('-'),
        markup: `<circle class="button-port${hasStepIdError ? ' unlinked' : ''}" />`,
        attrs: {
          circle: {
            magnet: false,
            r: 12,
            strokeWidth: 0,
            fill: 'transparent'
          }
        },
        position: 'absolute',
        args: {
          x: commonAttrs.x + maxWidth - padding,
          y: commonAttrs.y + buttonHeight / 2
        }
      });
    } else if (actionType === 'url') {
      const hasInvalidUrl = errors.some(e => e.field === 'url');
      if (hasInvalidUrl) {
        addActionIcon(brokenLinkIconSvg);
      } else {
        addActionIcon(linkIconSvg);
      }
    } else if (actionType === 'workflow') {
      addActionIcon(workflowIconSvg);
    } else if (actionType === 'exit') {
      addActionIcon(exitIconSvg);
    } else if (actionType === 'ticket-form') {
      addActionIcon(userIconSvg);
    } else if (actionType === 'external_api') {
      addActionIcon(cloudIconSvg);
    } else if (actionType === 'javascript') {
      addActionIcon(codeIconSvg);
    }

    return buttonHeight;
  } else if (type === 'header') {
    const wrappedText = joint.util.breakText(text || '', { width: maxWidth - padding * 2 });

    const headerHeight =
      HEADER_PADDING * 2 +
      wrappedText.split('\n').length * HEADER_LINE_HEIGHT -
      // line height doesn't seem to apply to first line??
      (HEADER_LINE_HEIGHT - HEADER_FONT_SIZE);

    markup.push(`<text class="${selector}" />`);
    attrs[`.${selector}`] = {
      ...commonAttrs,
      x: commonAttrs.x + padding,
      y: commonAttrs.y + HEADER_FONT_SIZE + HEADER_PADDING - 2,
      height: headerHeight,
      fontSize: HEADER_FONT_SIZE,
      fill: Colors.grey_15,
      fontWeight: 'bold',
      lineHeight: HEADER_LINE_HEIGHT,
      text: wrappedText
    };
    return headerHeight;
  } else if (type === 'repeatingGroup') {
    let componentHeight = REPEATING_GROUP_PADDING;

    const rootErrors = WorkflowStore.getRootComponentErrors(errors);
    const hasRootErrors = rootErrors.length > 0;

    (childComponents || []).forEach((childComponent, childIndex) => {
      const preComponentPadding = childIndex > 0 ? PADDING_BETWEEN_COMPONENTS : 0;
      const childCommonAttrs = {
        ...cloneDeep(commonAttrs),
        x: commonAttrs.x + padding,
        y: commonAttrs.y + preComponentPadding + componentHeight
      };
      const childErrors = WorkflowStore.getComponentErrors(errors, childIndex);
      const childHeight = addComponent(
        childComponent.type,
        childComponent.text,
        childComponent.childComponents,
        childComponent.options || {},
        componentIndexes.concat(childIndex),
        childCommonAttrs,
        maxWidth - 2 * padding,
        REPEATING_GROUP_PADDING,
        childErrors,
        workflow,
        markup,
        attrs,
        ports,
        redrawStep,
        dialogType,
        childComponent.position,
        dataSources,
        stepDisplayType,
        true
      );
      componentHeight += preComponentPadding + childHeight;
    });
    componentHeight += REPEATING_GROUP_PADDING;

    markup.push(`<rect class="${selector}" />`);
    attrs[`.${selector}`] = {
      ...commonAttrs,
      x: commonAttrs.x + padding,
      // refWidth: -padding * 2,
      width: maxWidth - 2 * padding,
      height: componentHeight,
      fill: 'transparent',
      stroke: hasRootErrors ? Colors.solvvyRed : Colors.grey_2,
      strokeWidth: 1,
      strokeDasharray: '4,2'
    };

    return componentHeight;
  } else {
    throw new Error(`unsupported component type ${type}`);
  }
}

const addPromptDelayIcon = ({ markup, commonAttrs, attrs, maxWidth, options, position }) => {
  const BACKGROUND_WIDTH = 44;
  const BACKGROUND_HEIGHT = 20;
  const BACKGROUND_POS_Y = commonAttrs.y - BACKGROUND_HEIGHT / 2;
  const BACKGROUND_POS_X = commonAttrs.x + maxWidth / 2 - BACKGROUND_WIDTH / 2;
  const DELAY_TEXT_POS_X = BACKGROUND_POS_X + BACKGROUND_WIDTH / 2 + 2;
  const DELAY_ICON_POS_X = BACKGROUND_POS_X + BACKGROUND_WIDTH / 2 - ACTION_BUTTON_ICON_SIZE - 2;

  attrs['.timeDelayIcon'] = {
    width: ACTION_BUTTON_ICON_SIZE,
    height: ACTION_BUTTON_ICON_SIZE,
    visibility: 'visible',
    fill: Colors.white
  };
  attrs['.timeDelayBackground'] = {
    width: BACKGROUND_WIDTH,
    height: BACKGROUND_HEIGHT,
    rx: 10,
    ry: 10,
    strokeWidth: 0,
    fill: Colors.grey_15
  };
  attrs['.timeDelayText'] = {
    fontSize: '12px',
    width: ACTION_BUTTON_ICON_SIZE,
    height: ACTION_BUTTON_ICON_SIZE,
    color: 'white'
  };

  const delay = (isNil(options.delay) ? DEFAULT_DELAY_IN_MS : options.delay) / 1000;

  markup.push(
    `<rect x="${BACKGROUND_POS_X}" y="${BACKGROUND_POS_Y}" class="timeDelayBackground" />`,
    `<foreignObject x="${DELAY_TEXT_POS_X}" y="${BACKGROUND_POS_Y}" class="timeDelayText"><div xmlns="http://www.w3.org/1999/xhtml">${delay}s</div></foreignObject>`,
    timeDelayIconSvg({ className: 'timeDelayIcon', y: BACKGROUND_POS_Y + 2, x: DELAY_ICON_POS_X })
  );
};

interface IBuildStep {
  step: IBaseStep;
  errors: IWorkflowError[];
  workflow: IWorkflow;
  isEntryStep?: boolean;
  isConditionalEntryStep?: boolean;
  redrawStep?: () => void;
  dialogType?: DialogType;
  dataSources?: IAsyncComputed<IDataSource[]>;
}

function buildStep({
  step,
  errors,
  workflow,
  isEntryStep,
  isConditionalEntryStep,
  redrawStep,
  dialogType,
  dataSources
}: IBuildStep) {
  const isDialogTypeConversational = dialogType === DialogType.Conversational;

  const wrappedHeaderText = joint.util.breakText(step.header || '', {
    width: STEP_WIDTH - STEP_PADDING * 2
  });
  const headerHeight = isDialogTypeConversational
    ? 0
    : STEP_PADDING * 2 +
      wrappedHeaderText.split('\n').length * HEADER_LINE_HEIGHT -
      // line height doesn't seem to apply to first line??
      (HEADER_LINE_HEIGHT - HEADER_FONT_SIZE);

  // the order here defines the stacking (equivalent of z-index)
  let markup = [
    '<rect class="highlight1" />',
    '<rect class="entryStepTabBackground" />',
    exitIconSvg('entryStepTabIcon'),
    '<rect class="exitStepTabBackground" />',
    exitIconSvg('exitStepTabIcon'),
    '<text class="stepName" />',
    '<rect class="body" />',
    '<rect class="header" />',
    '<text class="headerText" />',
    '<rect class="headerHideRoundedBottomBorder" />',
    '<rect class="headerBottomBorder" />',
    '<rect class="highlight2" />'
  ];

  const headerAttrs = {
    '.header': {
      height: headerHeight
    },
    '.headerText': {
      text: wrappedHeaderText
    },
    '.headerHideRoundedBottomBorder': {
      y: headerHeight + STEP_BORDER_WIDTH / 2 - STEP_BORDER_RADIUS,
      x: STEP_BORDER_WIDTH / 2,
      refWidth: -STEP_BORDER_WIDTH,
      height: STEP_BORDER_RADIUS,
      fill: Colors.grey_14
    },
    '.headerBottomBorder': {
      y: headerHeight + STEP_BORDER_WIDTH / 2,
      x: STEP_BORDER_WIDTH / 2,
      // y: 50,
      refWidth: -STEP_BORDER_WIDTH,
      height: 1,
      fill: Colors.grey_9
    }
  };

  const attrs = {
    '.stepName': {
      height: HEADER_LINE_HEIGHT,
      text: joint.util.breakText(
        step.name || `Step ${step.displayId}`,
        { width: STEP_WIDTH - STEP_PADDING },
        undefined,
        { ellipsis: true, maxLineCount: 1 }
      )
    },
    ...(isDialogTypeConversational ? {} : headerAttrs)
  };

  const ports: any[] = [];
  let currentHeight = headerHeight + STEP_BORDER_WIDTH / 2 + 1 + STEP_PADDING;
  let bodyIndex = -1;
  step.components.forEach((component, componentIndex) => {
    const { type, text, childComponents, options, position } = component;
    bodyIndex += 1;
    // const selector = `component-${componentIndex}`;

    const preComponentPadding = bodyIndex > 0 ? PADDING_BETWEEN_COMPONENTS : 0;

    const componentStartY = currentHeight + preComponentPadding;
    const commonAttrs = {
      x: 0,
      y: componentStartY,
      fontSize: BODY_FONT_SIZE
    };

    const componentErrors = WorkflowStore.getComponentErrors(errors, componentIndex);
    const componentHeight = addComponent(
      type,
      text,
      childComponents,
      options || {},
      [componentIndex],
      commonAttrs,
      STEP_WIDTH,
      STEP_PADDING,
      componentErrors,
      workflow,
      markup,
      attrs,
      ports,
      // redraw step fn (will be called after any images have loaded)
      redrawStep,
      dialogType,
      position,
      dataSources,
      step.displayType
    );

    currentHeight += preComponentPadding + componentHeight;
  });

  const stepHeight = Math.max(STEP_WIDTH, currentHeight + STEP_PADDING);

  // make entry tab visible or remove
  if (isEntryStep) {
    attrs['.entryStepTabBackground'] = { visibility: 'visible' };
    attrs['.entryStepTabIcon'] = { visibility: 'visible' };
  } else if (isConditionalEntryStep) {
    attrs['.entryStepTabBackground'] = { visibility: 'visible', fill: Colors.solvvyMediumPurple };
    attrs['.entryStepTabIcon'] = { visibility: 'visible' };
  } else {
    // remove markup for entry tab
    markup = markup.filter(
      svg => svg.indexOf('entryStepTabBackground') === -1 && svg.indexOf('entryStepTabIcon') === -1
    );
  }

  // make exit tab visible or remove
  const isExitStep = step.components.some(component => {
    const actionType = get(component, 'options.action.type');
    const actionOptionsExit = get(component, 'options.action.options.exit');
    return actionType === 'exit' || actionType === 'ticket-form' || (actionType === 'url' && actionOptionsExit);
  });
  if (isExitStep) {
    attrs['.exitStepTabBackground'] = {
      visibility: 'visible',
      y: stepHeight - EXIT_STEP_BOTTOM_OFFSET - STEP_TAB_SIZE
    };
    attrs['.exitStepTabIcon'] = {
      visibility: 'visible',
      y: stepHeight - EXIT_STEP_BOTTOM_OFFSET - STEP_TAB_SIZE + STEP_TAB_SIZE / 2 - ENTRY_STEP_TAB_ICON_SIZE / 2
    };
  } else {
    // remove markup for exit tab
    markup = markup.filter(svg => svg.indexOf('exitStepTabBackground') === -1 && svg.indexOf('exitStepTabIcon') === -1);
  }

  return { markup, attrs, stepHeight, ports };
}

const WorkflowStep = joint.dia.Element.define(
  'solvvy.WorkflowStep',
  {
    attrs: {
      '.body': {
        refWidth: '100%',
        refHeight: '100%',
        fill: 'white',
        stroke: Colors.grey_2,
        strokeWidth: STEP_BORDER_WIDTH,
        rx: STEP_BORDER_RADIUS,
        ry: STEP_BORDER_RADIUS,
        filter: { name: 'dropShadow', args: { dx: 0, dy: 1, blur: 3, color: '#5D5D5E' } }
      },
      '.highlight1': {
        visibility: 'hidden',
        refWidth: 8,
        refHeight: 8,
        refX: -4,
        refY: -4,
        fill: 'transparent',
        stroke: 'rgba(218, 236, 255, 0.85)',
        strokeWidth: 8,
        rx: STEP_BORDER_RADIUS,
        ry: STEP_BORDER_RADIUS
      },
      '.entryStepTabBackground': {
        visibility: 'hidden',
        x: -STEP_TAB_SIZE + STEP_TAB_BORDER_RADIUS - STEP_BORDER_WIDTH,
        y: ENTRY_STEP_TAB_TOP_OFFSET,
        width: STEP_TAB_SIZE,
        height: STEP_TAB_SIZE,
        rx: STEP_TAB_BORDER_RADIUS,
        ry: STEP_TAB_BORDER_RADIUS,
        strokeWidth: 0,
        fill: 'black'
      },
      '.entryStepTabIcon': {
        visibility: 'hidden',
        x: -STEP_TAB_SIZE / 2 - ENTRY_STEP_TAB_ICON_SIZE / 2 + STEP_TAB_BORDER_RADIUS - STEP_BORDER_WIDTH,
        y: ENTRY_STEP_TAB_TOP_OFFSET + STEP_TAB_SIZE / 2 - ENTRY_STEP_TAB_ICON_SIZE / 2,
        width: ENTRY_STEP_TAB_ICON_SIZE,
        height: ENTRY_STEP_TAB_ICON_SIZE,
        fill: 'white',
        stroke: 'white'
      },
      '.exitStepTabBackground': {
        visibility: 'hidden',
        x: STEP_WIDTH + STEP_BORDER_WIDTH - STEP_TAB_BORDER_RADIUS,
        width: STEP_TAB_SIZE,
        height: STEP_TAB_SIZE,
        rx: STEP_TAB_BORDER_RADIUS,
        ry: STEP_TAB_BORDER_RADIUS,
        strokeWidth: 0,
        fill: 'black'
      },
      '.exitStepTabIcon': {
        visibility: 'hidden',
        x: STEP_WIDTH + STEP_BORDER_WIDTH - STEP_TAB_BORDER_RADIUS + STEP_TAB_SIZE / 2 - ENTRY_STEP_TAB_ICON_SIZE / 2,
        width: ENTRY_STEP_TAB_ICON_SIZE,
        height: ENTRY_STEP_TAB_ICON_SIZE,
        fill: 'white',
        stroke: 'white'
      },
      '.highlight2': {
        visibility: 'hidden',
        refWidth: '100%',
        refHeight: '100%',
        fill: 'transparent',
        stroke: Colors.solvvyBlue,
        strokeWidth: STEP_BORDER_WIDTH,
        rx: STEP_BORDER_RADIUS,
        ry: STEP_BORDER_RADIUS
      },
      '.stepName': {
        x: STEP_BORDER_RADIUS,
        y: -HEADER_FONT_SIZE,
        fontSize: HEADER_FONT_SIZE + 2,
        fontWeight: 'bold',
        fill: Colors.grey_15,
        lineHeight: HEADER_LINE_HEIGHT,
        refWidth: '100%'
      },
      '.header': {
        refWidth: '100%',
        fill: Colors.grey_14,
        stroke: Colors.grey_2,
        strokeWidth: STEP_BORDER_WIDTH,
        rx: STEP_BORDER_RADIUS,
        ry: STEP_BORDER_RADIUS
      },
      '.headerText': {
        x: STEP_PADDING,
        y: STEP_PADDING + HEADER_FONT_SIZE,
        fill: Colors.grey_15,
        fontSize: HEADER_FONT_SIZE,
        fontWeight: 'bold',
        lineHeight: HEADER_LINE_HEIGHT
      }
    }
  },
  {},
  {
    createFromStep(
      step: IBaseStep,
      errors: IWorkflowError[],
      workflow: IWorkflow,
      {
        fullyEditable,
        isEntryStep,
        isConditionalEntryStep,
        dialogType
      }: {
        fullyEditable?: boolean;
        isEntryStep?: boolean;
        isConditionalEntryStep?: boolean;
        dialogType?: DialogType;
      } = {},
      dataSources?: IAsyncComputed<IDataSource[]>
    ) {
      let stepJointJsModel;

      const redrawStep = () => {
        if (!stepJointJsModel) {
          return;
        }

        const updatedStepData = buildStep({
          step,
          errors,
          workflow,
          isEntryStep,
          isConditionalEntryStep,
          dialogType,
          dataSources
        });
        stepJointJsModel.attr(updatedStepData.attrs);
        stepJointJsModel.size(STEP_WIDTH, updatedStepData.stepHeight);
        for (const port of updatedStepData.ports) {
          stepJointJsModel.portProp(port.id, 'args', port.args);
        }
      };

      const { markup, attrs, stepHeight, ports } = buildStep({
        step,
        errors,
        workflow,
        isEntryStep,
        isConditionalEntryStep,
        redrawStep,
        dialogType,
        dataSources
      });

      stepJointJsModel = new this({ markup: markup.join(''), attrs });
      stepJointJsModel.size(STEP_WIDTH, stepHeight);
      if (fullyEditable) {
        ports.forEach(port => stepJointJsModel.addPort(port));
      }
      stepJointJsModel.prop('stepId', step.id);

      return stepJointJsModel;
    }
  }
);

Object.assign(joint.shapes, {
  solvvy: {
    WorkflowStep,
    STEP_WIDTH
  }
});
