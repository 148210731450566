import { message } from 'antd';
import * as d3 from 'd3';
import { sankeyCircular, sankeyRight } from 'd3-sankey-circular';
import { observer } from 'mobx-react';
import React from 'react';
import { IDataSankey } from './IWorkflowReporting';
import { appendArrows, calculateDimensions, highlightNodes, truncateString } from './utils';
import { SankeyContainer } from './WorkflowReporting.styles';

import CopySVG from '../../../shared/images/copy.svg';

interface IProps {
  data: IDataSankey;
  width: number | undefined;
  height: number | undefined;
  onClick: (data) => void;
}

export const CircularSankey = observer((props: IProps) => {
  const renderTooltip = () => {
    return d3
      .select('#chart')
      .append('div')
      .attr('class', 'sankey-tooltip');
  };

  const renderChart = () => {
    const margin = { top: 30, right: 30, bottom: 20, left: 30 };
    const { height, width } = props;
    const tooltip = renderTooltip();

    const linkTooltip = data => {
      return tooltip.html(`
      <div style="height: 100px">
      <p style="margin: 0px; font-weight:bold">User Pathway</p>
      <p style="margin: 0px">${truncateString(data.source.id)} -> ${truncateString(data.target.id)}</p>
      <p style="margin: 0px">${data.value < 1 ? 'No ' : ''}Volume: ${data.value < 1 ? '0' : Math.floor(data.value)}</p>
      </div>
      `);
    };

    const nodeTooltip = data => {
      let volume = 0;
      if (data.sourceLinks.length) {
        data.sourceLinks?.forEach(item => {
          volume += item.value;
        });
        volume = volume + (data.exitValue ? data.exitValue : 0);
      } else {
        volume = data.value;
      }
      return tooltip.html(`
      <div style="height: 80px">
      <p style="margin: 0px; font-weight:bold">${truncateString(data.id)}</p>
      <p style="margin: 0px">Volume: ${volume ? (volume < 1 ? '0' : Math.floor(volume)) : 0}</p>
      <p style="margin: 0px">Exits: ${data.exitValue ? Math.floor(data.exitValue) : '0'} </p>
      </div>
      `);
    };

    const linkMouseover = (e, data) => {
      linkTooltip(data);
      tooltip.style('visibility', 'visible');
    };

    const nodeMouseover = (e, data) => {
      nodeTooltip(data);
      tooltip.style('visibility', 'visible');
    };

    const nodeMousemove = (e, data) => {
      nodeTooltip(data);
      tooltip.style('top', e.pageY - 120 + 'px').style('left', e.pageX - 100 + 'px');
    };

    const linkMousemove = (e, data) => {
      linkTooltip(data);
      tooltip.style('top', e.pageY - 140 + 'px').style('left', e.pageX - 100 + 'px');
    };

    const nodeMouseenter = (e, data) => {
      const name = data.name;
      node.selectAll('.node, .node-exit, .node-label').style('opacity', dRect => {
        return highlightNodes(dRect, name);
      });

      d3.selectAll('.link')
        .style('opacity', l => {
          return l.source.name === name || l.target.name === name ? 1 : 0.25;
        })
        .style('stroke-opacity', l => {
          return l.source.name === name || l.target.name === name ? 0.5 : 0.25;
        });
    };

    const nodeMouseleave = () => {
      d3.selectAll('.node, .node-exit, .node-label').style('opacity', 1);
      d3.selectAll('.link')
        .style('opacity', 1)
        .style('stroke-opacity', 0.25);
    };

    const initialSankey = sankeyCircular()
      .nodeWidth(20)
      .nodePadding(50)
      .nodePaddingRatio(1)
      .nodeId(d => {
        return d.name;
      })
      .nodeAlign(sankeyRight)
      .iterations(32)
      .circularLinkGap(2);

    const initialSankeyData = initialSankey(props.data);
    const initialSankeyNodes = initialSankeyData.nodes;

    const depth = d3.extent(initialSankeyNodes, d => {
      if (d.partOfCycle) {
        margin.bottom = 100;
      }
      return d.depth;
    });

    const { nodeWidth, nodePadding, nodePaddingRatio, screenWidth, screenHeight } = calculateDimensions(
      depth,
      initialSankeyData,
      height,
      width
    );

    const sankey = sankeyCircular()
      .nodeWidth(nodeWidth)
      .nodePadding(nodePadding)
      .nodePaddingRatio(nodePaddingRatio)
      .size([screenWidth, screenHeight])
      .nodeId(d => {
        return d.name;
      })
      .nodeAlign(sankeyRight)
      .iterations(32)
      .circularLinkGap(3);

    const sankeyData = sankey(props.data);
    const sankeyNodes = sankeyData.nodes;
    const sankeyLinks = sankeyData.links;

    const svg = d3
      .select('#chart')
      .append('svg')
      .attr('width', screenWidth + margin.left + margin.right)
      .attr('height', screenHeight + margin.top + margin.bottom);

    const g = svg.append('g').attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const linkG = g
      .append('g')
      .attr('class', 'links')
      .attr('fill', 'none')
      .attr('stroke-opacity', 0.25)
      .selectAll('path');

    const link = linkG.data(sankeyLinks).enter();

    link
      .filter(d => d.path)
      .append('path')
      .attr('class', 'link')
      .style('transition', '0.5s opacity')
      .attr('d', linkName => {
        return linkName.path;
      })
      .style('stroke-width', d => {
        return Math.max(1, d.width) < 3 ? 3 : Math.max(1, d.width);
      })
      .style('stroke', d => {
        return d.value < 1 ? '#D9D9D9' : '#8944CD';
      })
      .on(
        'mouseenter',
        // tslint:disable-next-line: only-arrow-functions
        function(this, d, e) {
          svg.selectAll('.node-exit, .node').style('opacity', p => {
            if (p.id === e.source.id) {
              return 1;
            }
            if (p.id === e.target.id) {
              return 1;
            }
            return 0.3;
          });

          svg.selectAll('.link').style('opacity', 0.25);
          d3.select(this)
            .attr('stroke-opacity', 0.5)
            .style('opacity', 1);
        }
      )
      .on('mouseover', linkMouseover)
      .on('mousemove', linkMousemove)
      .on('mouseout', () => {
        tooltip.style('visibility', 'hidden');
      })
      .on('mouseleave', d => {
        d3.selectAll('.node, .node-exit, .node-label').style('opacity', 1);
        d3.selectAll('.link')
          .style('opacity', 1)
          .attr('stroke-opacity', 0.25);
      })
      .sort((a, b) => {
        return b.dy - a.dy;
      });

    linkG
      .data(sankeyLinks)
      .enter()
      .append('g')
      .attr('class', 'g-arrow')
      .call(appendArrows, 20, 300, 4);

    const nodeG = g
      .append('g')
      .attr('class', 'nodes')
      .attr('font-family', 'sans-serif')
      .attr('font-size', 15)
      .selectAll('g');

    const node = nodeG
      .data(sankeyNodes)
      .enter()
      .append('g');

    node
      .append('rect')
      .attr('x', d => {
        return d.x0;
      })
      .attr('y', d => {
        return d.y0;
      })
      .attr('height', d => {
        return d.y1 - d.y0 > 5 ? d.y1 - d.y0 : 5;
      })
      .attr('width', d => {
        return d.x1 - d.x0;
      })
      .attr('class', 'node')
      .style('transition', '0.5s opacity')
      .style('fill', d => {
        if (d.hasExit ? d.value + d.exitValue < 1 : d.value < 1) {
          return '#D9D9D9';
        }
        if (d.isLastScreen) {
          return '#EF4771';
        }
        return '#724784';
      })
      .on('mouseenter', nodeMouseenter)
      .on('mouseleave', nodeMouseleave)
      .on('mouseover', nodeMouseover)
      .on('mousemove', nodeMousemove)
      .on('mouseout', () => {
        tooltip.style('visibility', 'hidden');
        d3.selectAll('.link')
          .style('opacity', 1)
          .style('stroke-opacity', 0.25);
      })
      .on('click', (e, data) => {
        if (data.isLastScreen) {
          return;
        }
        props.onClick(data);
      });

    node
      .append('rect')
      .attr('class', 'node-exit')
      .style('transition', '0.5s opacity')
      .attr('x', d => {
        return d.x0;
      })
      .attr('y', d => {
        return d.y1;
      })
      .attr('width', d => {
        return d.x1 - d.x0;
      })
      .attr('height', '16')
      .attr('fill', d => {
        return d.hasExit ? '#1F1F1F' : 'none';
      })
      .on('mouseover', nodeMouseover)
      .on('mousemove', nodeMousemove)
      .on('mouseout', () => {
        tooltip.style('visibility', 'hidden');
      });

    node
      .append('text')
      .attr('font-family', 'sans-serif')
      .attr('text-anchor', 'middle')
      .attr('font-size', 12)
      .attr('x', d => {
        return (d.x0 + d.x1) / 2;
      })
      .attr('y', d => {
        return d.y1 + 13;
      })
      .style('fill', d => {
        return d.hasExit ? '#fff' : 'none';
      })
      .text(d => {
        return `${d.exitValue} ${d.exitValue === 1 ? 'Exit' : 'Exits'}`;
      })
      .on('mouseover', nodeMouseover)
      .on('mousemove', nodeMousemove)
      .on('mouseout', () => {
        tooltip.style('visibility', 'hidden');
      });

    node
      .append('text')
      .attr('class', 'node-label')
      .style('cursor', 'pointer')
      .attr('x', d => {
        return (d.x0 + d.x1) / 2;
      })
      .attr('y', d => {
        return d.y0 - 12;
      })
      .attr('dy', '0.35em')
      .attr('text-anchor', 'middle')
      .text(d => {
        if (d.isUrl) {
          return truncateString(d.id, 13);
        }
        return truncateString(d.id);
      })
      .on('mouseenter', d => {
        return d.name;
      })
      .on('mouseover', d => {
        return d.name;
      })
      .on('click', (e, data) => {
        if (data.isUrl) {
          navigator.clipboard.writeText(data.name);
          message.success('Link successfully copied to clipboard', 2);
        } else {
          props.onClick(data);
        }
      });

    node
      .append('svg:image')
      .attr('xlink:href', d => {
        if (d.isUrl) {
          return CopySVG;
        }
        return null;
      })
      .style('cursor', d => {
        if (d.isUrl) {
          return 'pointer';
        }
        return '';
      })
      .attr('width', 12)
      .attr('height', 12)
      .attr('x', d => {
        return (d.x0 + d.x1) / 2 + 50;
      })
      .attr('y', d => {
        return d.y0 - 18;
      })
      .attr('dy', '0.35em')
      .on('click', (e, data) => {
        if (data.isUrl) {
          navigator.clipboard.writeText(data.name);
          message.success('Link successfully copied to clipboard', 2);
        }
      });
  };

  React.useEffect(() => {
    renderChart();
  }, []);

  return (
    <>
      <SankeyContainer id="chart" />
    </>
  );
});
