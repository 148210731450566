import { defaultDataIdFromObject, InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError as apolloLinkOnError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import axios from 'axios';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import querystring from 'querystring';
import {
  ABSOLUTE_SESSION_TIMEOUT_HEADER,
  EXTEND_SESSION_HEADER,
  IDLE_SESSION_TIMEOUT_HEADER
} from '../store/authStore';
import { defaultStores } from '../store/rootStore';
import envService from './envService';

const request = (accessToken: any, method: string, path: string, params: any = {}, options: any = {}) => {
  let url: string = `${envService.getBaseUrl()}${path}`;
  const headers = { Authorization: `Bearer ${accessToken}`, 'Cache-Control': 'no-cache' };
  const axiosOpts: any = { method, url, headers };

  if (includes(['post', 'put', 'PATCH'], method)) {
    headers['Content-Type'] = 'application/json;charset=UTF-8';
    const body = options.body || params;
    axiosOpts.data = JSON.stringify(body);
  } else if (method === 'get') {
    if (!isEmpty(params)) {
      url = `${url}?${querystring.stringify(params)}`;
      axiosOpts.url = url;
    }
  }

  if (options.headers) {
    merge(headers, options.headers);
  }

  return axios(axiosOpts);
};

export const createApi = () => {
  let onLogout;

  const makeRequest = async (method: string, path: string, params: any = null, options?: any) => {
    try {
      const response = await request(defaultStores.authStore.accessToken, method, path, params, options);

      if (options && options.headers && options.headers[EXTEND_SESSION_HEADER] !== 'false') {
        // check if the API response has response headers indicating when the session will expire
        const { headers } = response;
        defaultStores.authStore.setSessionTimeouts(
          Number(headers[IDLE_SESSION_TIMEOUT_HEADER]),
          Number(headers[ABSOLUTE_SESSION_TIMEOUT_HEADER])
        );
      }

      return response;
    } catch (error) {
      // if response is unauthorized (session may have expired) or if the request was made but no response was received
      // force logout on 401 error
      // don't logout on 403 error as this is annoying when there is a bug in server permissions (in that case its better for some small part of the dashboard to not function)
      if (error.response && error.response.status === 401) {
        onLogout();
      }
      throw error;
    }
  };

  const httpLink = createHttpLink({
    uri: envService.getGraphQlUrl()
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: defaultStores.authStore.accessToken ? `Bearer ${defaultStores.authStore.accessToken}` : ''
      }
    };
  });

  const cache = new InMemoryCache({
    dataIdFromObject: (object: any) => {
      switch (object.__typename) {
        case 'QuerySummary':
          return `${object.__typename}:${object.total}`;
        case 'Step':
          return `${object.__typename}:${object.workflowVersionId}:${object.id}`;
        case 'Component':
          return `${object.__typename}:${object.workflowVersionId}:${object.id}`;
        default:
          return defaultDataIdFromObject(object); // fall back to default handling
      }
    }
  });

  const sessionTimeoutLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(result => {
      const context = operation.getContext();
      if (context.headers && context.headers[EXTEND_SESSION_HEADER] !== 'false') {
        const { headers } = context.response;
        defaultStores.authStore.setSessionTimeouts(
          Number(headers.get(IDLE_SESSION_TIMEOUT_HEADER)),
          Number(headers.get(ABSOLUTE_SESSION_TIMEOUT_HEADER))
        );
      }
      return result;
    });
  });

  const sessionExpiredLink = apolloLinkOnError(({ networkError, graphQLErrors, operation }) => {
    // raw "response" set by apollo-link-http
    const { response } = operation.getContext();
    if (response && (response.status === 401 || response.status === 403)) {
      onLogout();
    }
  });

  const gqlClient = new ApolloClient({
    link: ApolloLink.from([authLink, sessionTimeoutLink, sessionExpiredLink, httpLink]),
    cache
  });

  return {
    gqlQuery: gqlClient.query.bind(gqlClient),
    gqlMutate: gqlClient.mutate.bind(gqlClient),
    get(path: string, params = {}, options?) {
      return makeRequest('get', path, params, options);
    },
    post(path: string, params = {}, options?) {
      return makeRequest('post', path, params, options);
    },
    put(path: string, params = {}, options?) {
      return makeRequest('put', path, params, options);
    },
    del(path: string) {
      return makeRequest('delete', path);
    },
    setOnLogout(fn: () => void) {
      onLogout = fn;
    }
  };
};

export type Api = ReturnType<typeof createApi>;

export default createApi;
