import { notification } from 'antd';
import { ArgsProps, NotificationApi } from 'antd/lib/notification';

interface INotification {
  title?: string;
  message: string;
  config?: INotificationConfig;
}

type INotificationConfig = Omit<ArgsProps, 'message' | 'description'>;

export class NotificationManager {
  notification: NotificationApi;
  constructor() {
    notification.config({
      placement: 'bottomLeft',
      bottom: 50,
      duration: 5
    });
  }

  success({ title, message, config }: INotification) {
    this.openNotificationWithIcon('success', title, message, config);
  }

  info({ title, message, config }: INotification) {
    this.openNotificationWithIcon('info', title, message, config);
  }

  warn({ title, message, config }: INotification) {
    this.openNotificationWithIcon('warning', title, message, config);
  }

  error({ title, message, config }: INotification) {
    this.openNotificationWithIcon('error', title, message, config);
  }

  close(key: string) {
    notification.close(key);
  }

  openNotificationWithIcon = (type, title, description, restOfConfig?: INotificationConfig) => {
    notification[type]({
      message: title,
      description,
      ...restOfConfig
    });
  };
}

export default new NotificationManager();
