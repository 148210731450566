import * as Sentry from '@sentry/browser';
import setupLogRocketReact from 'logrocket-react';
import { isStaging } from '../util/misc';

const SolvvyLogRocket = require('logrocket');

// NODE_ENV gives the type of build (production | development)
if (process.env.NODE_ENV === 'production') {
  const logRocketProjectId = isStaging ? 'xbsk2p/solvvy-staging' : 'xbsk2p/solvvy-prod';
  SolvvyLogRocket.init(logRocketProjectId, {
    shouldCaptureIP: false,
    network: {
      requestSanitizer: request => {
        request.body = null;
        return request;
      },
      responseSanitizer: response => {
        response.body = null;
        return response;
      }
    }
  });
  setupLogRocketReact(SolvvyLogRocket);
  SolvvyLogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra('sessionURL', sessionURL);
    });
  });
}
