import find from 'lodash/find';
import get from 'lodash/get';
import ExternalApiLogo from '../images/cloud.svg';
import EmailLogo from '../images/email_logo.svg';
import FreshdeskLogo from '../images/freshdesk.jpg';
import GladlyLogo from '../images/gladlyLogo.png';
import GorgiasLogo from '../images/gorgias_logo.png';
import HelpScoutLogo from '../images/helpscout.png';
import IntercomLogo from '../images/intercomLogo.png';
import KustomerLogo from '../images/kustomer.jpg';
import MicrosoftLogo from '../images/microsoft_logo.png';
import OracleLogo from '../images/oracle.png';
import PureCloudLogo from '../images/purecloud.jpg';
import SalesforceLogo from '../images/Salesforce.png';
import SunshineLogo from '../images/sunshine.png';
import WebLogo from '../images/www.jpg';
import ZendeskLogo from '../images/zendesk.png';
import ZendeskGuideLogo from '../images/zendesk_guide.png';
import ZoomContactCenterLogo from '../images/zoom-logo.png';

const sourceTypes = [
  {
    name: 'Email',
    logo: EmailLogo,
    key: 'email',
    rate_limit_options: {
      window_size_seconds: null,
      max_requests_in_window: null
    },
    email: true,
    domain: false,
    base_url: false,
    need_authentication: false
  },
  {
    name: 'Web',
    logo: WebLogo,
    key: 'webpage',
    rate_limit_options: {
      window_size_seconds: null,
      max_requests_in_window: null
    },
    domain: false,
    base_url: true,
    need_authentication: false
  },
  {
    name: 'Zendesk',
    logo: ZendeskLogo,
    key: 'zendesk',
    rate_limit_options: {
      window_size_seconds: 60,
      max_requests_in_window: 200
    },
    domain: 'zendesk.com',
    need_authentication: true
  },
  {
    name: 'Freshdesk',
    logo: FreshdeskLogo,
    key: 'freshdesk',
    domain: 'freshdesk.com',
    rate_limit_options: {
      window_size_seconds: 60,
      max_requests_in_window: 200
    },
    api_key: true,
    instructions: 'In Freshdesk, Profile Settings> Your API Key> Copy and paste it here',
    need_authetication: false
  },
  {
    name: 'Zendesk Guide',
    logo: ZendeskGuideLogo,
    key: 'zendesk_help_center',
    rate_limit_options: {
      window_size_seconds: 60,
      max_requests_in_window: 200
    },
    domain: 'zendesk.com',
    need_authentication: false,
    disable_onboarding: true
  },
  {
    name: 'Salesforce',
    logo: SalesforceLogo,
    key: 'salesforce',
    rate_limit_options: {
      window_size_seconds: 24 * 60 * 60,
      max_requests_in_window: 1000
    },
    domain: 'salesforce.com',
    need_authentication: true
  },
  {
    name: 'Help Scout',
    logo: HelpScoutLogo,
    key: 'helpscout',
    rate_limit_options: {
      window_size_seconds: 60,
      max_requests_in_window: 200
    },
    instructions:
      'After clicking "Save and Continue" below you will be redirected to Help Scout to authorize Solvvy and then will be returned here.',
    need_authentication: true
  },
  {
    name: 'Oracle Service Cloud',
    logo: OracleLogo,
    key: 'oracle',
    rate_limit_options: {
      window_size_seconds: 60,
      max_requests_in_window: 200
    },
    username_and_password: true,
    instructions:
      'You may want to create a new Oracle Service Cloud user just for Solvvy so that you can control the permissions easier',
    domain: 'custhelp.com',
    need_authentication: false
  },
  {
    name: 'Kustomer',
    logo: KustomerLogo,
    key: 'kustomer',
    rate_limit_options: {
      window_size_seconds: 60,
      max_requests_in_window: 200
    },
    api_key: true,
    instructions:
      'In Kustomer, go to Settings > Platform Settings > API Keys. Click "New API Key", use any name and select the "org.user" role. Copy & paste the API Key Token here.',
    domain: 'kustomerapp.com',
    need_authentication: false
  },
  {
    name: 'Sunshine',
    logo: SunshineLogo,
    key: 'sunshine',
    rate_limit_options: {
      window_size_seconds: 60,
      max_requests_in_window: 200
    },
    need_authentication: true
  },
  {
    name: 'Intercom',
    logo: IntercomLogo,
    key: 'intercom',
    rate_limit_options: {
      window_size_seconds: 60,
      max_requests_in_window: 200
    },
    need_authentication: true,
    ticket_submission: {
      enabled: true,
      extract_ticket_form_enabled: true,
      allowAttachments: false,
      enable_subject: true,
      enable_name: true
    }
  },
  {
    name: 'Genesys',
    logo: PureCloudLogo,
    key: 'purecloud',
    rate_limit_options: {
      window_size_seconds: 60,
      max_requests_in_window: 200
    },
    username_and_password: true,
    regions: [
      { name: 'US East (N. Virginia) - mypurecloud.com', value: 'mypurecloud.com' },
      { name: 'US West (Oregon) - usw2.pure.cloud', value: 'usw2.pure.cloud' },
      { name: 'Canada (Canada Central) - cac1.pure.cloud', value: 'cac1.pure.cloud' },
      { name: 'EU (Frankfurt) - mypurecloud.de', value: 'mypurecloud.de' },
      { name: 'EU (Ireland) - mypurecloud.ie', value: 'mypurecloud.ie' },
      { name: 'EU (London) - euw2.pure.cloud', value: 'euw2.pure.cloud' },
      { name: 'Asia Pacific (Mumbai) - aps1.pure.cloud', value: 'aps1.pure.cloud' },
      { name: 'Asia Pacific (Seoul) - apne2.pure.cloud', value: 'apne2.pure.cloud' },
      { name: 'Asia Pacific (Sydney) - mypurecloud.com.au', value: 'mypurecloud.com.au' },
      { name: 'Asia Pacific (Tokyo) - mypurecloud.jp', value: 'mypurecloud.jp' }
    ],
    text: {
      regionPrompt: 'Select PureCloud Region...',
      usernameLabel: 'Client ID',
      passwordLabel: 'Client Secret'
    },
    instructions:
      'In PureCloud, go to Admin > Integrations > OAuth. Click "Add Client" and use any app name and description and select "Client Credentials" grant type. Choose appropriate role and then copy & paste client ID and secret here.',
    need_authentication: false
  },
  {
    name: 'Gorgias',
    logo: GorgiasLogo,
    key: 'gorgias',
    username_and_password: true,
    domain: 'gorgias.com/api/',
    text: {
      usernameLabel: 'Email address',
      passwordLabel: 'Password (API Key)'
    },
    domain_cleanup_regexp: new RegExp(/https|http|:|\/|\.gorgias\.com|api/gi),
    instructions:
      'In Gorgias, go to Settings > REST API. In the above fields, enter your subdomain (ex. "test" from https://test.gorgias.com/api/, or paste the entire Base API URL text and we\'ll clean it up for you), username, and password.'
  },
  {
    name: 'Microsoft',
    logo: MicrosoftLogo,
    key: 'microsoft',
    domain: 'crm.dynamics.com',
    application_id: true,
    username_and_password: true,
    text: {
      usernameLabel: 'Application (client) ID',
      passwordLabel: 'Application (client) Secret',
      applicationIdLabel: 'Directory (tenant) ID'
    },
    instructions:
      'In Azure, create a new App Registration. Assign this App with permissions to your Microsoft Dynamics CRM. Create a Client secret for your Solvvy App registration. Add the Secret, the application (client) ID and directory (tenant) ID (from the App Registration "Overview" page), and your Dynamics CRM subdomain (YOUR_SUBDOMAIN.crm.dynamics.com).'
  },
  {
    name: 'Gladly',
    logo: GladlyLogo,
    key: 'gladly',
    domain: 'gladly.com',
    rate_limit_options: {
      window_size_seconds: 60,
      max_requests_in_window: 200
    },
    need_authentication: false,
    ticket_submission: {
      enabled: true,
      extract_ticket_form_enabled: true,
      allowAttachments: false,
      enable_subject: true,
      enable_name: true
    },
    username_and_api_key: true,
    instructions: `In Gladly, login as admin and go to 'more settings' and click 'API Tokens' under 'Integration'. Create new Token for your Solvvy App. Copy the token and paste in 'New API Key'. User name is email address used to login in Gladly`
  },
  {
    name: 'Custom API',
    logo: ExternalApiLogo,
    key: 'external_api',
    disable_onboarding: true
  },
  {
    name: 'Zoom Contact Center',
    logo: ZoomContactCenterLogo,
    key: 'zcc',
    disable_onboarding: true
  }
];

export const getSources = () => {
  return sourceTypes;
};

export const getSource = name => {
  return find(sourceTypes, s => s.key === name);
};

export const getLogo = name => {
  return get(getSource(name), 'logo');
};

export const needsAuthentication = connector => {
  const type = getSource(connector.source.type);
  const credentials = connector.source.credentials || ({} as any);
  // this connector doesn't need authentication if it refers to another connector
  const haveCredentialsConnectorReference = credentials.connector_id;
  return type && type.need_authentication && !haveCredentialsConnectorReference;
};

export const isAuthenticated = source => {
  if (needsAuthentication(source)) {
    return source.has_authenticated_credentials && !source.reauthenticate;
  } else {
    return true;
  }
};

export default getSources;
