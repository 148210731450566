import { Alert, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import { recordErrors } from '../util/recordErrors';

const StyledRow = styled(Row)`
  width: 100%;
`;

export default observer(
  ({
    errorMsg = 'We had a problem loading this page. Please try again later.',
    className,
    error
  }: {
    onRetry?: any;
    errorMsg?: string;
    className?: string;
    error?: Error;
  }) => {
    recordErrors(error);
    return (
      <StyledRow className={className}>
        <Alert type="error" message={errorMsg} />
      </StyledRow>
    );
  }
);
