import format from 'date-fns/format';

export default class OrgMessage {
  constructor(
    public id: number,
    public subject: string,
    public is_active: boolean,
    public message_body: string,
    public created_at: Date
  ) {}

  get created_at_short() {
    return format(this.created_at, 'MMM DD YYYY');
  }
}
