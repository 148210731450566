import {
  ICookieValueTemplateExpression,
  ICustomTemplateExpression,
  IDomElementPropertyTemplateExpression,
  IExternalApiResponseTemplateExpression,
  IGlobalJavaScriptVariableTemplateExpression,
  ILocalStorageJsonTemplateExpression,
  ILocalStorageStringTemplateExpression,
  IQueryParamTemplateExpression,
  IRepeatingGroupItemTemplateExpression,
  ISessionStorageJsonTemplateExpression,
  ISessionStorageStringTemplateExpression,
  ITemplateExpression,
  IUserDataTemplateExpression
} from './data-source-types';
import Source from './models/Source';

const escapeDots = (value: string) => value.replace(/\./g, '\\.');
const unescapeDots = (value: string) => value.replace(/\\\./g, '.');

export function encodeTemplateExpression(templateExpression: ITemplateExpression): string {
  const { type } = templateExpression;

  const encoders = {
    api: (te: IExternalApiResponseTemplateExpression) =>
      `{{${type}.${te.routeName}.body${te.responseObjectPath ? '.' : ''}${te.responseObjectPath}}}`,
    repeatingGroupItem: (te: IRepeatingGroupItemTemplateExpression) =>
      `{{${type}${te.itemObjectPath ? '.' : ''}${te.itemObjectPath || ''}}}`,
    domId: (te: IDomElementPropertyTemplateExpression) => `{{${type}.${te.elementId}.${te.property}}}`,
    window: (te: IGlobalJavaScriptVariableTemplateExpression) => `{{${type}.${te.objectPath}}}`,
    cookie: (te: ICookieValueTemplateExpression) => `{{${type}.${te.cookieName}}}`,
    localStorage: (te: ILocalStorageStringTemplateExpression) => `{{${type}.${te.itemKey}}}`,
    localStorageJson: (te: ILocalStorageJsonTemplateExpression) =>
      `{{${type}.${escapeDots(te.itemKey)}${te.objectPath ? '.' : ''}${te.objectPath || ''}}}`,
    sessionStorage: (te: ISessionStorageStringTemplateExpression) => `{{${type}}}`,
    sessionStorageJson: (te: ISessionStorageJsonTemplateExpression) =>
      `{{${type}.${escapeDots(te.itemKey)}${te.objectPath ? '.' : ''}${te.objectPath || ''}}}`,
    queryParam: (te: IQueryParamTemplateExpression) => `{{${type}.${te.param}}}`,
    userData: (te: IUserDataTemplateExpression) =>
      `{{${type}.${escapeDots(te.userDataId)}${te.objectPath ? '.' : ''}${te.objectPath || ''}}}`,
    custom: (te: ICustomTemplateExpression) => te.templateExpression
  };

  return encoders[type](templateExpression);
}

export function decodeTemplateExpression(
  templateExpression?: string,
  externalApiConnectors?: Source[]
): ITemplateExpression {
  let type = 'custom';
  let value = '';

  const typeMatch = templateExpression?.match(new RegExp(`^{{(\\w+)\.?(.*)}}$`));
  if (typeMatch) {
    type = typeMatch[1];
    value = typeMatch[2];
  }

  // regex that matches a key and value separated by a dot. The key can contain a dot if its escaped (e.g. my\.key.value)
  const dottedKeyValueRegexp = /^(([^.\\]|\\.)+)\.(.+)$/;

  switch (type) {
    case 'api':
      const apiMatch = value.match(dottedKeyValueRegexp);
      if (apiMatch) {
        const routeName = apiMatch[1];
        let responseObjectPath = apiMatch[3];

        const bodyMatch = responseObjectPath.match(/^body\.?(.*)$/);
        if (bodyMatch) {
          responseObjectPath = bodyMatch[1];
        } else {
          // if not accesing something on the body, then treat as custom expression
          return { type: 'custom', templateExpression } as ICustomTemplateExpression;
        }

        let connectorId: string | undefined;
        if (externalApiConnectors) {
          for (const connector of externalApiConnectors) {
            for (const route of connector.external_api?.routes || []) {
              if (route.name === routeName) {
                connectorId = connector.id;
                break;
              }
            }
            if (connectorId) {
              break;
            }
          }
        }

        return { type, routeName, responseObjectPath, connectorId } as IExternalApiResponseTemplateExpression;
      }
      break;
    case 'repeatingGroupItem':
      return { type, itemObjectPath: value } as IRepeatingGroupItemTemplateExpression;
    case 'domId':
      const [elementId, ...property] = value.split('.');
      return { type, elementId, property: property.join('.') } as IDomElementPropertyTemplateExpression;
    case 'window':
      return { type, objectPath: value } as IGlobalJavaScriptVariableTemplateExpression;
    case 'cookie':
      return { type, cookieName: value } as ICookieValueTemplateExpression;
    case 'localStorage':
      return { type, itemKey: value } as ILocalStorageStringTemplateExpression;
    case 'localStorageJson':
      const localStorageJsonMatch = value.match(dottedKeyValueRegexp);
      if (localStorageJsonMatch) {
        const itemKey = unescapeDots(localStorageJsonMatch[1]);
        const objectPath = localStorageJsonMatch[3];
        return { type, itemKey, objectPath } as ILocalStorageJsonTemplateExpression;
      } else {
        return { type, itemKey: unescapeDots(value) } as ILocalStorageJsonTemplateExpression;
      }
      break;
    case 'sessionStorage':
      return { type, itemKey: value } as ISessionStorageStringTemplateExpression;
    case 'sessionStorageJson':
      const sessionStorageJsonMatch = value.match(dottedKeyValueRegexp);
      if (sessionStorageJsonMatch) {
        const itemKey = unescapeDots(sessionStorageJsonMatch[1]);
        const objectPath = sessionStorageJsonMatch[3];
        return { type, itemKey, objectPath } as ISessionStorageJsonTemplateExpression;
      } else {
        return { type, itemKey: unescapeDots(value) } as ILocalStorageJsonTemplateExpression;
      }
      break;
    case 'queryParam':
      return { type, param: value } as IQueryParamTemplateExpression;
    case 'userData':
      const userDataJsonMatch = value.match(dottedKeyValueRegexp);
      if (userDataJsonMatch) {
        const userDataId = unescapeDots(userDataJsonMatch[1]);
        const objectPath = userDataJsonMatch[3];
        return { type, userDataId, objectPath } as IUserDataTemplateExpression;
      } else {
        return { type, userDataId: unescapeDots(value) } as IUserDataTemplateExpression;
      }
      break;
  }

  return { type: 'custom', templateExpression } as ICustomTemplateExpression;
}
