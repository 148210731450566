import { LDFlagSet, LDFlagValue } from 'ldclient-js';
import { action, observable } from 'mobx';
import FeatureFlagService from 'src/services/features';

export const FLAGS = {
  enable_unified_coach: 'enable_unified_coach',
  enable_new_intent_button: 'enable_new_intent_button'
} as const;

/**
 * Initialization of launchdarkly client happens in `FeatureFlagService`. Updating the user attributes dynamically happens here.
 * The flags in the service does not dynamically change. Flags here will change dynamically.
 * If the flag dynamically needs to be updated/changed use this store and add the flag above. Else add it in the `FeatureFlagService`.
 */
export default class FeaturesStore {
  @observable
  flags: LDFlagSet = {};

  init() {
    FeatureFlagService.ldClient.on('ready', this.initializeFlags.bind(this));
    FeatureFlagService.ldClient.on('change', this.initializeFlags.bind(this));
  }

  @action
  initializeFlags() {
    this.flags = FeatureFlagService.ldClient.allFlags();
  }

  @action
  updateCustomAttributes(attrs: { [key: string]: LDFlagValue }) {
    const user = FeatureFlagService.ldClient?.getUser();
    if (user) {
      Object.assign(user.custom!, attrs);
      FeatureFlagService.ldClient.identify(user);
    }
  }
}
