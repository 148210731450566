import { message, Modal } from 'antd';
import { JSONSchema4 } from 'json-schema';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import { action, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { UiConfigService } from '../services/uiConfigService';
import asyncComputed from '../shared/util/asyncComputed';
import { recordErrors } from '../shared/util/recordErrors';
import { ConnectorStore } from './connectorStore';
import {
  DataSourceActionFormActionTypes,
  IDataSource,
  IDataSourceAction,
  IDataSourceActionFormValues,
  IDataSourceFormValues,
  IDataSourceVariableFormValues,
  IExternalApiActionOptionsFormValues,
  IExternalApiActionOptionsInputFormValues
} from './data-source-types';
import Source from './models/Source';
import { OrgStore } from './orgStore';
import { ExternalApiAutoGeneratedDataSource } from './ResolveUI/ExternalApiAutoGeneratedDataSource';
import { IAction, IExternalApiActionOptions, IJavascriptActionOptions } from './ResolveUI/Workflow';
import { ResolveUiStore } from './resolveUiStore';
import { decodeTemplateExpression, encodeTemplateExpression } from './templateExpressionUtils';

export const SUPPORTED_ACTION_TYPES = ['set-variable', 'authentication', 'external_api', 'javascript'];

export class DataSourcesStore {
  static replaceTemplateExpressionsToVariableNames(htmlToReplace: string | undefined, dataSources) {
    if (!htmlToReplace) {
      return htmlToReplace;
    }

    return htmlToReplace.replace(new RegExp('{{([^}]+)}}', 'g'), (match, expression) => {
      const variable = this.getVariableByTemplateExpression(`{{${expression}}}`, dataSources);

      if (variable) {
        return `<span class="mention" data-mention-id="${variable.templateExpression}">${variable.title}</span>`;
      }
      return match;
    });
  }

  static getVariableByTemplateExpression(templateExpression: any, dataSources) {
    if (dataSources.fulfilled) {
      const variables = dataSources.value.reduce((allVariables, dataSource) => {
        if (!dataSource.variablesJsonSchema) {
          return allVariables;
        }
        Object.values(dataSource.variablesJsonSchema?.properties as any).map((variable: any) => {
          allVariables.push(variable);
        });

        return allVariables;
      }, [] as any);

      return variables.find(variable => {
        return variable.templateExpression === templateExpression;
      });
    }
  }

  @observable
  dataSources = asyncComputed(() => this.loadDataSources());

  @observable
  openEditModal = false;

  @observable
  isCreate = false;

  @observable
  selectedDataSource?: IDataSource;

  showingErrorModal: boolean = false;

  // used to disable messages by tests since they are interferring
  disableMessages: boolean = false;

  constructor(
    private orgStore: OrgStore,
    private connectorStore: ConnectorStore,
    private resolveUiStore: ResolveUiStore,
    private uiConfigService: UiConfigService
  ) {}

  async loadDataSources(orgGroupId: number = this.orgStore.currentOrgGroupId): Promise<IDataSource[]> {
    if (!orgGroupId) {
      return [];
    }

    const instanceIdsForSelectedOrg = this.resolveUiStore.getUiInstanceIds();

    const dataSources = await this.uiConfigService.getDataSources(orgGroupId);

    return dataSources.filter(
      dataSource =>
        dataSource.uiInstanceIds?.length === 0 ||
        dataSource.uiInstanceIds?.some(instanceId => instanceIdsForSelectedOrg.includes(instanceId))
    );
  }

  @action
  toggleEditModal() {
    this.openEditModal = !this.openEditModal;
  }

  @action
  showCreateModal(srcDataSource?: IDataSource) {
    this.openEditModal = true;
    this.isCreate = true;
    if (srcDataSource) {
      this.selectedDataSource = cloneDeep(srcDataSource);
      this.selectedDataSource.name = `Copy of ${srcDataSource.name}`;
      for (const actionObj of this.selectedDataSource.actions || []) {
        actionObj.id = uuidv4();
      }
      this.selectedDataSource.id = undefined as any;
    } else {
      this.selectedDataSource = undefined;
    }
  }

  @action
  showEditModal(selectedDataSource: IDataSource) {
    this.openEditModal = true;
    this.isCreate = false;
    this.selectedDataSource = selectedDataSource;
  }

  @action
  closeModal() {
    this.openEditModal = false;
    this.isCreate = false;
    this.selectedDataSource = undefined;
  }

  async createDataSource(dataSource: IDataSource): Promise<IDataSource | undefined> {
    try {
      dataSource.orgGroupId = this.orgStore.currentOrgGroupId;
      const createdDataSource = await this.uiConfigService.createDataSource(dataSource);
      this.dataSources.refresh();
      if (!this.disableMessages) {
        message.success('The data source has been created.');
      }
      return createdDataSource;
    } catch (e) {
      this.handleServerError(
        e,
        'data source',
        'There was an unexpected error creating the data source. Please try again later.'
      );
      return undefined;
    }
  }

  async updateDataSource(dataSource: IDataSource): Promise<IDataSource | undefined> {
    try {
      const updatedDataSource = await this.uiConfigService.updateDataSource(dataSource);
      this.dataSources.refresh();
      if (!this.disableMessages) {
        message.success('The data source has been updated.');
      }
      return updatedDataSource;
    } catch (e) {
      this.handleServerError(
        e,
        'data source',
        'There was an unexpected error updating the data source. Please try again later.'
      );
      return undefined;
    }
  }

  async deleteDataSource(dataSourceId: string) {
    try {
      await this.uiConfigService.deleteDataSource(dataSourceId);
      this.closeModal();
      this.dataSources.refresh();
      if (!this.disableMessages) {
        message.success('The data source has been deleted.');
      }
    } catch (e) {
      this.handleServerError(
        e,
        'data source',
        'There was an unexpected error deleting the data source. Please try again later.'
      );
    }
  }

  @action
  async copyDataSource(dataSource: any) {
    try {
      window.navigator.clipboard.writeText(JSON.stringify(dataSource));
      message.success('The data source has been copied to clipboard successfully');
    } catch (e) {
      this.handleServerError(e, 'data source', 'Unable to copy to clipboard');
    }
  }

  @action
  async pasteAndCreateDataSource(dataSource: IDataSource) {
    try {
      const dataSourceString = await window.navigator.clipboard.readText();
      const newDataSource = JSON.parse(dataSourceString);
      newDataSource.uiInstanceIds = dataSource.uiInstanceIds;
      newDataSource.actions = newDataSource.actions.map((ds: any) => {
        delete ds.action.options.orgId;
        delete ds.action.options.connectorId;
        return ds;
      });
      this.showCreateModal(newDataSource);
      if (!this.disableMessages) {
        message.success('"Datasource pasted from Clipboard. Review the data source, and click Create.');
      }
    } catch (e) {
      this.handleServerError(
        e,
        'data source',
        'Import Error: The data on the clipboard is not a valid data source JSON definition'
      );
    }
  }

  handleServerError(err: Error, objectName: string, defaultMessageText: string) {
    recordErrors(err);
    let messageText = defaultMessageText;
    if (err.message.match(/Record .*in DB has a newer version than expected/)) {
      messageText = `Someone else has already modified this ${objectName}. Please refresh the page, reapply your changes and then save again.`;
      if (!this.showingErrorModal) {
        this.showingErrorModal = true;
        Modal.error({
          title: 'Oops something went wrong',
          content: messageText,
          okText: 'Refresh',
          keyboard: false,
          width: 600,
          onOk: () => {
            window.location.reload();
          }
        });
      }
    } else {
      message.error(defaultMessageText);
    }
  }

  convertActionFormValuesToDataSourceAction(actionValue: IDataSourceActionFormValues): IDataSourceAction {
    let actionObj: IAction = cloneDeep(actionValue.action!) || { options: {} };
    if (actionValue.actionType === 'custom') {
      try {
        actionObj = JSON.parse(actionValue.actionJson!);
      } catch (e) {
        recordErrors(e);
      }
    } else {
      actionObj.type = actionValue.actionType;
    }

    let inputsJsonSchema: JSONSchema4 | undefined;
    if (actionObj.type === 'javascript') {
      const options = actionObj.options as IJavascriptActionOptions;
      const newArgs: object = {};
      if (options.arguments) {
        inputsJsonSchema = { properties: {} };

        for (const arg of options.arguments) {
          if (arg.type === 'custom') {
            newArgs[arg.name] = arg.value;
          } else if (arg.type === 'string-input') {
            inputsJsonSchema.properties![arg.name] = {
              type: 'string',
              title: arg.name,
              targetPath: `arguments.${arg.name}`
            };
          } else if (arg.type === 'action-input') {
            inputsJsonSchema.properties![arg.name] = {
              type: 'action' as any,
              title: arg.name,
              targetPath: `arguments.${arg.name}`
            };
          }
          if (arg.required) {
            inputsJsonSchema.required = (inputsJsonSchema.required as string[]) || [];
            inputsJsonSchema.required.push(arg.name);
          }
        }

        if (Object.keys(inputsJsonSchema.properties || {}).length === 0) {
          inputsJsonSchema = undefined;
        }
      }

      options.arguments = newArgs;
    } else if (actionObj.type === 'external_api') {
      const options = actionObj.options as IExternalApiActionOptionsFormValues;
      const connector = this.connectorStore.externalApiConnectors.find(({ id }) => id === options.connectorId);
      if (connector) {
        options.orgId = Number(connector.org_id);
      }

      inputsJsonSchema = {
        required: ['onSuccessAction'],
        properties: {
          onSuccessAction: {
            type: 'action' as any,
            title: 'On Success',
            targetPath: 'onSuccessAction'
          },
          onErrorAction: {
            type: 'action' as any,
            title: 'On Error',
            targetPath: 'onErrorAction'
          }
        }
      };
      const actionObjInput: any = {};

      for (const [inputName, input] of Object.entries(options.inputs || {})) {
        if (inputsJsonSchema?.properties && !inputsJsonSchema.properties[inputName]) {
          if (input.type === 'custom') {
            actionObjInput[inputName] = input.value;
          } else {
            inputsJsonSchema.properties[inputName] = {
              type: 'string',
              title: inputName,
              targetPath: `input.${inputName}`
            };
            if (input.required) {
              (inputsJsonSchema.required as string[]).push(inputName);
            }
          }
        }
      }

      actionObj.options = {
        ...omit(actionObj.options, ['inputs']),
        input: actionObjInput
      };
    } else if (actionObj.type === 'authentication') {
      inputsJsonSchema = {
        required: ['onSuccessAction'],
        properties: {
          onSuccessAction: {
            type: 'action' as any,
            title: 'On Success',
            targetPath: 'onSuccessAction'
          },
          onErrorAction: {
            type: 'action' as any,
            title: 'On Error',
            targetPath: 'onErrorAction'
          }
        }
      };
    } else if (actionObj.type === 'set-variable') {
      inputsJsonSchema = {
        required: ['nextAction'],
        properties: {
          nextAction: {
            type: 'action' as any,
            title: 'Next Action',
            targetPath: 'nextAction'
          }
        }
      };
    }

    return {
      ...omit(actionValue, ['actionType', 'actionJson']),
      action: actionObj,
      inputsJsonSchema
      // inputsJsonSchema: actionValue.inputsJsonSchema ? JSON.parse(actionValue.inputsJsonSchema) : undefined
    };
  }

  convertFormValuesToDataSource(values: IDataSourceFormValues): IDataSource {
    return {
      ...omit(values, ['uiInstanceId', 'variables']),
      uiInstanceIds: values.uiInstanceId ? [values.uiInstanceId] : undefined,
      // variablesJsonSchema: values.variablesJsonSchema ? JSON.parse(values.variablesJsonSchema) : undefined,
      variablesJsonSchema: this.convertVariableFormValuesToJsonSchema(values.variables),
      // variablesMock: values.variablesMock ? JSON.parse(values.variablesMock) : undefined,
      variablesMock: this.convertVariableFormValuesToMock(values.variables),
      actions: values.actions?.map(actionValue => this.convertActionFormValuesToDataSourceAction(actionValue)),
      ...(this.selectedDataSource && { id: this.selectedDataSource.id })
    };
  }

  convertVariableFormValuesToJsonSchema(variables?: IDataSourceVariableFormValues[]): JSONSchema4 | undefined {
    if (!variables) {
      return undefined;
    }

    const properties = {};

    for (const variable of variables) {
      let type = variable.type;
      if (type === 'dropdown') {
        type = 'string';
      }
      properties[variable.id] = {
        ...variable,
        type,
        templateExpression: encodeTemplateExpression(variable.templateExpression)
      };
    }

    return {
      properties
    };
  }

  convertVariableFormValuesToMock(variables?: IDataSourceVariableFormValues[]): object | undefined {
    if (!variables) {
      return undefined;
    }

    const mock: object = {};
    for (const variable of variables) {
      let mockValue = variable.mock;
      if (!mockValue && variable.type === 'dropdown' && variable.oneOf && variable.oneOf.length > 0) {
        mockValue = variable.oneOf[0].const;
      }
      if (mockValue && variable.type === 'array') {
        try {
          mockValue = JSON.parse(mockValue.toString());
        } catch (e) {
          recordErrors(e);
        }
      }
      mock[variable.id] = mockValue;
    }

    return mock;
  }

  convertDataSourceActionToActionFormValues(dsAction: IDataSourceAction): IDataSourceActionFormValues {
    let actionJson;
    let actionObj;
    let actionType: DataSourceActionFormActionTypes;
    if (SUPPORTED_ACTION_TYPES.includes(dsAction.action.type)) {
      actionObj = cloneDeep(dsAction.action);
      actionType = dsAction.action.type as DataSourceActionFormActionTypes;
    } else {
      actionJson = JSON.stringify(actionObj, null, 2);
      actionType = 'custom';
    }

    if (actionType === 'javascript') {
      const options = actionObj.options as IJavascriptActionOptions;
      const newArgs: object[] = [];
      if (options.arguments) {
        for (const [name, value] of Object.entries(options.arguments)) {
          newArgs.push({ type: 'custom', name, value });
        }
      }

      for (const [inputName, schema] of Object.entries(dsAction.inputsJsonSchema?.properties || {})) {
        const type = (schema.type as any) === 'action' ? 'action-input' : 'string-input';
        newArgs.push({
          type,
          name: inputName,
          required: ((dsAction.inputsJsonSchema?.required as string[]) || []).includes(inputName)
        });
      }

      options.arguments = newArgs;
    } else if (actionType === 'external_api') {
      const options = actionObj.options as IExternalApiActionOptions;

      const inputs = {};
      for (const [inputName, value] of Object.entries(options.input || {})) {
        inputs[inputName] = {
          type: 'custom',
          value
        };
      }

      const builtInOptions = ['onSuccessAction', 'onErrorAction'];
      for (const inputName of Object.keys(dsAction.inputsJsonSchema?.properties || {})) {
        if (!builtInOptions.includes(inputName)) {
          inputs[inputName] = {
            type: 'string-input',
            required: ((dsAction.inputsJsonSchema?.required as string[]) || []).includes(inputName)
          } as IExternalApiActionOptionsInputFormValues;
        }
      }

      actionObj.options = {
        ...omit(options, ['input']),
        inputs
      } as IExternalApiActionOptionsFormValues;
    }

    return {
      ...dsAction,
      actionType,
      action: actionObj,
      actionJson
      // inputsJsonSchema: dsAction.inputsJsonSchema ? JSON.stringify(dsAction.inputsJsonSchema, null, 2) : undefined
    };
  }

  convertDataSourceToFormValues(dataSource: IDataSource): IDataSourceFormValues {
    return {
      ...dataSource,
      uiInstanceId: dataSource.uiInstanceIds ? dataSource.uiInstanceIds[0] : undefined,
      // variablesJsonSchema: variablesJsonSchema ? JSON.stringify(variablesJsonSchema, null, 2) : undefined,
      variables: this.convertDataSourceToVariableFormValues(dataSource),
      // variablesMock: dataSource.variablesMock ? JSON.stringify(dataSource.variablesMock, null, 2) : undefined,
      actions: dataSource.actions?.map(dsAction => this.convertDataSourceActionToActionFormValues(dsAction))
    };
  }

  convertDataSourceToVariableFormValues(dataSource: IDataSource): IDataSourceVariableFormValues[] | undefined {
    const { variablesJsonSchema, variablesMock } = dataSource;

    if (!variablesJsonSchema || !variablesJsonSchema.properties) {
      return undefined;
    }

    return Object.entries(variablesJsonSchema.properties).map(([id, schema]) => {
      let mock = variablesMock ? variablesMock[id] : undefined;
      let type = (schema.type as string) || 'string';
      if (schema.oneOf) {
        type = 'dropdown';
      }
      if (mock && type === 'array') {
        mock = JSON.stringify(mock, null, 2);
      }
      return {
        ...schema,
        id,
        type,
        mock,
        templateExpression: decodeTemplateExpression(
          schema.templateExpression,
          this.connectorStore.externalApiConnectors
        )
      } as IDataSourceVariableFormValues;
    });
  }

  getAutoGeneratedExternalApiDataSources() {
    return this.connectorStore.externalApiConnectors
      .filter(
        connector =>
          connector.external_api?.auto_generate_data_source && (connector.external_api?.routes || []).length > 0
      )
      .map(connector => new ExternalApiAutoGeneratedDataSource(new Source(connector)));
  }
}
