import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import truncate from 'lodash/truncate';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { OrgStore } from 'src/store/orgStore';
import { IRevertWorkflowVersion, IWorkflow } from '../../store/ResolveUI/Workflow';
import { typedInject } from '../../store/rootStore';
import { WorkflowStore } from '../../store/workflowStore';
import { getMaskedDisplayName } from './Util';
import { StyledRevertWorkflowModal } from './workflow.styles';

interface IProps {
  workflow: IWorkflow;
  onCancel: () => void;
  onSuccess?: () => void;
  workflowStore: WorkflowStore;
  orgStore: OrgStore;
}

interface IState {
  reverting?: boolean;
  selectedWorkflowVersionId?: string;
  versions?: IRevertWorkflowVersion[];
}

@observer
class RevertWorkflowModal extends Component<IProps, IState> {
  state: IState = {};

  async componentDidMount() {
    const versions = await this.props.workflowStore.loadWorkflowVersionsForRevert(this.props.workflow);
    this.setState({ versions });
  }

  @action.bound
  async revertWorkflow() {
    const { workflowStore, workflow, onSuccess } = this.props;
    this.setState({ reverting: true });
    await workflowStore.revertUnpublishedWorkflow(workflow, this.state.selectedWorkflowVersionId);
    if (onSuccess) {
      onSuccess();
    }
  }

  render() {
    const { onCancel, orgStore, workflowStore, workflow } = this.props;
    const { versions, reverting, selectedWorkflowVersionId } = this.state;

    const getUpdatedByUserName = updatedByUser => {
      return getMaskedDisplayName(updatedByUser, orgStore.currentUserContext.isGlobalUser);
    };

    return (
      <StyledRevertWorkflowModal
        visible={true}
        title={null}
        onCancel={onCancel}
        width={1000}
        footer={
          <div>
            <div className="warning-message">
              <InfoCircleOutlined className="info" />
              Reverting a workflow will replace any unpublished changes.
            </div>

            <div className="buttons">
              <Button type="default" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="default" danger={true} htmlType="submit" loading={reverting} onClick={this.revertWorkflow}>
                Revert
              </Button>
            </div>
          </div>
        }
      >
        <h3>Revert Workflow: {workflow.name}</h3>

        <Table
          dataSource={versions}
          rowKey={({ id }) => id}
          pagination={false}
          size="middle"
          locale={{ emptyText: 'No versions to revert to' }}
          loading={workflowStore.loadingWorkflowVersionsForRevert}
          scroll={{ y: 'calc(100vh - 350px)' }}
          rowSelection={{
            type: 'radio',
            columnWidth: '35px',
            selectedRowKeys: selectedWorkflowVersionId
              ? [selectedWorkflowVersionId]
              : versions && versions.length > 0
              ? [versions[0].id]
              : undefined,
            onSelect: ({ id }) => this.setState({ selectedWorkflowVersionId: id })
          }}
        >
          <Column
            title="Version"
            key="version"
            align="center"
            width="100px"
            sorter={(a: IRevertWorkflowVersion, b: IRevertWorkflowVersion) =>
              Number(a.publishedVersion) - Number(b.publishedVersion)
            }
            render={(workflowVersion: IRevertWorkflowVersion) => workflowVersion.publishedVersion}
          />
          <Column
            title="Date Published"
            key="publishedAt"
            width="200px"
            sorter={(a: IRevertWorkflowVersion, b: IRevertWorkflowVersion) =>
              (a.publishedAt || '').localeCompare(b.publishedAt || '')
            }
            render={(workflowVersion: IRevertWorkflowVersion) =>
              workflowVersion.publishedAt ? new Date(workflowVersion.publishedAt).toLocaleString() : ''
            }
          />
          <Column
            title="Published By"
            key="publishedBy"
            width="200px"
            sorter={(a: IRevertWorkflowVersion, b: IRevertWorkflowVersion) =>
              getUpdatedByUserName(a.updatedByUser).localeCompare(getUpdatedByUserName(b.updatedByUser))
            }
            render={(workflowVersion: IRevertWorkflowVersion) => getUpdatedByUserName(workflowVersion.updatedByUser)}
          />
          <Column
            title="Notes"
            key="notes"
            sorter={(a: IRevertWorkflowVersion, b: IRevertWorkflowVersion) =>
              (a.publishedComments || '').toString().localeCompare((b.publishedComments || '').toString())
            }
            render={(workflowVersion: IRevertWorkflowVersion) =>
              truncate(workflowVersion.publishedComments || '', { length: 500 })
            }
          />
        </Table>
      </StyledRevertWorkflowModal>
    );
  }
}

export default typedInject('workflowStore', 'orgStore')(RevertWorkflowModal);
