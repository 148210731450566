import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styled from 'styled-components';

const StyledSpinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
`;

export default observer(
  ({
    thingToWaitFor,
    size = 'small',
    onlySpinner = false,
    style = {}
  }: {
    thingToWaitFor?: string;
    size?: 'small' | 'default' | 'large';
    onlySpinner?: boolean;
    style?: any;
  }) => (
    <StyledSpinner data-testid="loader" style={style}>
      <Spin size={size} style={onlySpinner ? {} : { marginTop: '5em' }}>
        {!onlySpinner && `Loading ${thingToWaitFor}, please wait...`}
      </Spin>
    </StyledSpinner>
  )
);
