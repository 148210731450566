import { assign, set } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import { asyncAction } from 'mobx-utils';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { onboardingPageRoute, orgPageRoute } from '../routes/routes';
import { ConnectorService } from '../services/connectorService';
import asyncComputed from '../shared/util/asyncComputed';
import { KB_CONNECTOR_TYPES, TICKET_CONNECTOR_TYPES } from '../shared/util/connectorTypes';
import { NotificationManager } from '../shared/util/NotificationManager';
import * as OnboardingSteps from '../shared/util/onboarding_steps';
import { PromiseState } from '../shared/util/PromiseStates';
import { recordErrors } from '../shared/util/recordErrors';
import { getSource, isAuthenticated, needsAuthentication } from '../shared/util/sourceTypes';
import { IAuthentication, IExternalApi, IRoute, IRouteInput } from './external-api-types';
import Source from './models/Source';
import OnboardingStore from './onboardingStore';
import { RouterStore } from './routerStore';

const REFRESH_INTERVAL_SECONDS = 10; // how frequently to refresh

export class ConnectorStore {
  static connectorToEditFormValues(connector: Source): any {
    return {
      sourceTypeKey: connector.source.type,
      name: connector.name,
      region: connector.region,
      sourceUrlOrIdentifier: connector.sourceUrlOrIdentifier,
      external_api: connector.external_api
        ? {
            ...connector.external_api,
            authentication: connector.external_api.authentication
              ? {
                  type: connector.external_api.authentication.type,
                  options: {
                    ...(connector.external_api.authentication.type === 'header'
                      ? {
                          headerName: Object.keys(connector.external_api.authentication.options)[0] || ''
                        }
                      : {})
                  }
                }
              : undefined
          }
        : undefined,
      live_chat: connector.live_chat
    };
  }

  static formValuesToRawConnector(formValues: any, selectedConnector: Source): any {
    const {
      name,
      sourceTypeKey,
      sourceUrlOrIdentifier,
      apiKey,
      username,
      password,
      region,
      sunshineWebhookSecret,
      applicationId,
      external_api,
      live_chat
    } = formValues;
    const origConnector: any = omit(selectedConnector, ['region', 'sourceUrlOrIdentifier', 'sourceType', 'type']) || {};
    const sourceType: any = getSource(sourceTypeKey);

    const newData: any = {
      id: selectedConnector && selectedConnector.id,
      name,
      source: {
        ...origConnector.source,
        type: sourceType.key,
        identifier: sourceType.domain ? sourceUrlOrIdentifier : name,
        region: region ? region : undefined,
        seed_url: sourceType.base_url ? sourceUrlOrIdentifier : null
      },
      ...(sunshineWebhookSecret && {
        sunshine: {
          ...origConnector.sunshine,
          webhook_secret: sunshineWebhookSecret
        }
      }),
      rate_limit_options: sourceType.rate_limit_options,
      credentials: origConnector.credentials,
      live_chat
    };

    if (sourceType.api_key && apiKey) {
      newData.credentials = { api_key: apiKey };
    }

    if (sourceType.username_and_password && username && password) {
      newData.credentials = { username, password };
    }

    if (sourceType.username_and_api_key && username && password) {
      newData.credentials = { username, access_token: password };
    }

    if (external_api) {
      this.processExternalApiFormValues(external_api, origConnector, newData);
    }

    if (sourceType.application_id && applicationId) {
      newData.credentials = newData.credentials || {};
      newData.credentials.application_id = applicationId;
    }

    const connectorData = assign({}, origConnector, newData);

    // generate a zcc bot webhook secret if it doesn't exist
    if (connectorData.source.type === 'zcc' && !connectorData.live_chat?.zcc?.botWebhookSecret) {
      set(connectorData, 'live_chat.zcc.botWebhookSecret', uuidv4());
    }

    return connectorData;
  }

  static processExternalApiFormValues(external_api: any, origConnector: any, newData: any): void {
    if (external_api) {
      external_api.routes = external_api.routes || [];
      if (external_api.authentication?.type === 'bearer') {
        const { options } = external_api.authentication;
        const { token } = options || {};
        external_api.authentication.type = 'header';
        if (token) {
          newData.credentials = { token };
        }
        external_api.authentication.options = {
          ...origConnector.external_api?.authentication?.options,
          Authorization: `Bearer {{{connector.credentials.token}}}`
        };
      } else if (external_api.authentication?.type === 'http_basic') {
        const { options } = external_api.authentication;
        const { username, password } = options || {};
        if (username && password) {
          newData.credentials = { username, password };
        }
        external_api.authentication.options = {
          ...origConnector.external_api?.authentication?.options,
          username: '{{{connector.credentials.username}}}',
          password: '{{{connector.credentials.password}}}'
        };
      } else if (external_api.authentication?.type === 'header') {
        const { options } = external_api.authentication;
        const { headerName, headerValue } = options || {};
        if (headerValue) {
          newData.credentials = { [headerName]: headerValue };
        }
        external_api.authentication.options = {
          ...origConnector.external_api?.authentication?.options,
          [headerName]: `{{{connector.credentials.${headerName}}}}`
        };
      } else if (!external_api.authentication?.type) {
        external_api.authentication = undefined;
      } else {
        external_api.authentication = origConnector.external_api?.authentication;
      }

      for (const route of external_api.routes || []) {
        route.options = route.options || {};
        if (route.options.query_params) {
          const items = route.options.query_params || [];
          route.options.query_params = {};
          for (const item of items) {
            route.options.query_params[item.name] = item.value;
          }
        }
        if (route.options.headers) {
          const items = route.options.headers || [];
          route.options.headers = {};
          for (const item of items) {
            route.options.headers[item.name] = item.value;
          }
        }
        if (route.options.request_body) {
          try {
            route.options.request_body = JSON.parse(route.options.request_body);
          } catch (e) {}
        }
        if (route.input) {
          const items: IRouteInput[] = route.input || [];
          route.input = {};
          for (const item of items) {
            if (item.origObjectValue) {
              route.input[item.name] = {
                ...item.origObjectValue,
                targetPath: `${item.targetOption}${item.optionParam ? '.' : ''}${item.optionParam}`
              };
            } else {
              route.input[item.name] = `${item.targetOption}${item.optionParam ? '.' : ''}${item.optionParam}`;
            }
          }
        }
        if (route.validation_checks) {
          const items = route.validation_checks || [];
          route.validation_checks = {};
          for (const item of items) {
            route.validation_checks[item.name] = item.value;
          }
        }
        if (route.response_mock) {
          try {
            route.response_mock = JSON.parse(route.response_mock);
          } catch (e) {}
        }
        if (route.response_json_schema) {
          try {
            route.response_json_schema = JSON.parse(route.response_json_schema);
          } catch (e) {}
        }
      }

      newData.external_api = external_api;
    }
  }

  static convertExternalApiFromDb(dbExternalApi: any): IExternalApi {
    const authentication: IAuthentication = cloneDeep(dbExternalApi.authentication);
    if (
      authentication?.type === 'header' &&
      (authentication?.options as any)?.Authorization?.indexOf('Bearer ') === 0
    ) {
      authentication.type = 'bearer';
    }
    const routes: IRoute[] = (dbExternalApi.routes || []).map((dbRoute: any) => {
      const route: IRoute = cloneDeep(dbRoute);
      route.id = route.id || uuidv4();
      route.options = route.options || {};
      route.options.query_params = Object.entries(dbRoute.options?.query_params || {}).map(([name, value]: any) => ({
        name,
        value
      }));
      route.options.headers = Object.entries(dbRoute.options?.headers || {}).map(([name, value]: any) => ({
        name,
        value
      }));
      if (dbRoute.options?.request_body) {
        route.options.request_body = JSON.stringify(dbRoute.options.request_body, null, 2);
      }
      if (dbRoute.input) {
        route.input = Object.entries(dbRoute.input || {}).map(([name, value]) => {
          let targetPath: string = value as string;
          let origObjectValue: any;
          if (typeof value === 'object' && (value as any).targetPath) {
            targetPath = (value as any).targetPath;
            origObjectValue = value;
          }
          const [targetOption, ...optionParamParts] = targetPath.split('.');
          return {
            name,
            targetOption,
            optionParam: optionParamParts.join('.'),
            origObjectValue
          };
        });
      }
      route.validation_checks = Object.entries(dbRoute.validation_checks || {}).map(([name, value]: any) => ({
        name,
        value
      }));
      if (dbRoute.response_mock) {
        route.response_mock = JSON.stringify(dbRoute.response_mock, null, 2);
      }
      if (dbRoute.response_json_schema) {
        route.response_json_schema = JSON.stringify(dbRoute.response_json_schema, null, 2);
      }

      return route;
    });

    return {
      ...dbExternalApi,
      authentication,
      routes
    };
  }

  @observable
  connectors: any[] = [];
  @observable
  loadingConnectors: PromiseState = PromiseState.pending;
  @observable
  userAssignedOrgId: any;
  @observable
  orgTimeZone: string = 'America/Los_Angeles';
  @observable
  openEditModal = false;
  @observable
  isCreate = false;
  @observable
  selectedConnector: Source = new Source({});
  @observable
  periodicRefreshing?: boolean = false;

  refreshInterval?: any;

  getAnyPendingRecrawlJob = asyncComputed(async () => {
    const allJobsPerOrgStatus = await this._connectorService.getRecrawlJobStatus(
      {
        org_id: this.selectedOrgId,
        status: ['init', 'pending', 'started', 'requested_for_approval'],
        jobNames: [
          'reingest_webpages_if_safe',
          'reingest_webpages_promote_preview',
          'reingest_webpages_if_safe_no_promote'
        ]
      },
      this.periodicRefreshing
    );
    return allJobsPerOrgStatus;
  });

  isKbRecrawlInProgressAsyncComputed = asyncComputed(() =>
    this._connectorService.isKbRecrawlInProgress(this.selectedOrgId, this.periodicRefreshing)
  );

  constructor(
    private _onboardingStore: OnboardingStore,
    private _routerStore: RouterStore,
    private _notificationManager: NotificationManager,
    private _connectorService: ConnectorService
  ) {
    const startRefresh = () =>
      setInterval(() => runInAction(() => this.periodicRefresh()), REFRESH_INTERVAL_SECONDS * 1000);
    reaction(
      () => this.shouldPeriodicRefresh,
      () => {
        if (this.shouldPeriodicRefresh && !this.refreshInterval) {
          this.refreshInterval = startRefresh();
        }
        if (!this.shouldPeriodicRefresh && this.refreshInterval) {
          clearInterval(this.refreshInterval);
          this.refreshInterval = undefined;
        }
      }
    );
    if (this.shouldPeriodicRefresh && !this.refreshInterval) {
      this.refreshInterval = startRefresh();
    }
  }

  @action
  updateUserAssignedOrgId(orgId) {
    this.userAssignedOrgId = orgId;
  }

  @action
  updateOrgTimeZone(timeZone) {
    this.orgTimeZone = timeZone;
  }

  @action
  editSourceConnector(connector: {}) {
    this.isCreate = false;
    this.selectedConnector = new Source(connector);
    this.toggleEditModal();
  }

  @action
  createSourceConnector(srcConnector?: any) {
    this.isCreate = true;
    if (srcConnector) {
      this.selectedConnector = new Source({
        ...omit(cloneDeep(srcConnector), ['created_at', 'updated_at']),
        name: `Copy of ${srcConnector.name}`,
        id: undefined
      });
      this.selectedConnector.credentials = {};
      for (const route of this.selectedConnector.external_api?.routes || []) {
        route.id = uuidv4();
      }
    } else {
      this.selectedConnector = new Source({ source: { type: 'external_api' } });
    }
    this.toggleEditModal();
  }

  @action
  async importAndCreateSourceConnector() {
    try {
      const newConnectorSource = await window.navigator.clipboard.readText();
      const srcConnector = JSON.parse(newConnectorSource);
      this.createSourceConnector(srcConnector);
      this._notificationManager.success({
        message: 'Connector settings pasted from Clipboard. Review the settings, and click Create Connector'
      });
    } catch (e) {
      this._notificationManager.error({
        message: 'Import Error: The data on the clipboard is not a valid connection JSON definition'
      });
    }
  }

  @action
  async copyConnectorToClipboard(connector?: any) {
    try {
      await window.navigator.clipboard.writeText(JSON.stringify(connector));
      this._notificationManager.success({
        message: 'Connector copied successfully'
      });
    } catch (e) {
      this._notificationManager.error({
        message: 'Unable to copy to clipboard'
      });
    }
  }

  @action
  toggleEditModal() {
    this.openEditModal = !this.openEditModal;
  }

  @asyncAction
  *createServiceProviderAndContinueOnboarding(connector) {
    try {
      const { data } = yield this.createConnector(connector);
      if (needsAuthentication(data)) {
        this.authenticateConnector(data);
      } else {
        yield this.retrieveConnectors();
        this.goToOrgMain();
      }
    } catch (e) {
      recordErrors(e);
      throw Error('Could not save service provider');
    }
  }

  @asyncAction
  *requestForRecrawlNow() {
    try {
      yield this._connectorService.requestForRecrawl({
        org_id: this.selectedOrgId,
        job_name: 'reingest_webpages_if_safe'
      });
      this.isKbRecrawlInProgressAsyncComputed.refresh();
      this.getAnyPendingRecrawlJob.refresh();
      this._notificationManager.success({
        title: 'You request for recrawl is submitted',
        message: `Once your recrawl job succeeds we will notify you via email`
      });
    } catch (e) {
      recordErrors(e);
      this._notificationManager.error({
        title: 'Oops something went wrong',
        message: 'Could not request a recrawl'
      });
    }
  }

  @asyncAction
  *createConnectorsAndContinueOnboarding(connectors) {
    try {
      yield Promise.all(connectors.map(connector => this.createConnector(connector)));
      yield this.retrieveConnectors();
      if (this.hasTicketConnectors) {
        this.goToOrgMain();
      } else {
        this.goToServiceProviderOnboardingRoute();
      }
    } catch (e) {
      recordErrors(e);
      throw Error('Could not create connector');
    }
  }

  @asyncAction
  *retrieveConnectors(orgId = this.selectedOrgId) {
    this.connectors = [];
    this.loadingConnectors = PromiseState.pending;
    try {
      const connectors = yield this._connectorService.fetchConnectors(orgId);
      for (const connector of connectors) {
        if (connector.external_api) {
          connector.external_api = ConnectorStore.convertExternalApiFromDb(connector.external_api);
        }
      }
      this.connectors = connectors;
      this.loadingConnectors = PromiseState.fulfilled;
    } catch (e) {
      recordErrors(e);
      this.loadingConnectors = PromiseState.rejected;
    }
  }

  @asyncAction
  *createConnectorsAndRedirect(connector?) {
    this.loadingConnectors = PromiseState.pending;
    try {
      const { data } = yield this._connectorService.createConnector(this.userAssignedOrgId, connector);
      this.redirectAfterConnectorSave(data);
    } catch (e) {
      recordErrors(e);
      this.loadingConnectors = PromiseState.rejected;
    }
  }

  @asyncAction
  *updateConnector(source: Source) {
    this.loadingConnectors = PromiseState.pending;
    try {
      yield this._connectorService.updateConnector(source);
      this.loadingConnectors = PromiseState.fulfilled;
      this.redirectAfterConnectorSave(source);
      this._notificationManager.success({
        title: 'Connection Saved',
        message: `The connection "${source.name}" has been saved`
      });
    } catch (e) {
      this.loadingConnectors = PromiseState.rejected;
      this._notificationManager.error({
        title: 'Connection Save Error',
        message: 'There was an unexpected error while saving the connection. Please try again later.'
      });
      recordErrors(e);
    }
  }

  @asyncAction
  *removeConnector(id) {
    this.loadingConnectors = PromiseState.pending;
    try {
      yield this._connectorService.deleteConnector(id);
      this.loadingConnectors = PromiseState.fulfilled;
      this.retrieveConnectors();
      this._notificationManager.success({
        title: 'Connection Deleted',
        message: `The connection was deleted (id: ${id})`
      });
      this._routerStore.history.push(`/org/${this.userAssignedOrgId}/settings/connections`);
    } catch (e) {
      this.loadingConnectors = PromiseState.rejected;
      this._notificationManager.error({
        title: 'Connection Deletion Error',
        message: 'There was an unexpected error while saving the connection. Please try again later.'
      });
      recordErrors(e);
    }
  }

  @asyncAction
  *createConnector(connector: any) {
    this.loadingConnectors = PromiseState.pending;
    try {
      const createConnectorResponse = yield this._connectorService.createConnector(this.userAssignedOrgId, connector);
      yield this.retrieveConnectors();
      this.loadingConnectors = PromiseState.fulfilled;
      return createConnectorResponse;
    } catch (e) {
      this.loadingConnectors = PromiseState.rejected;
      recordErrors(e);
    }
  }

  @action.bound
  redirectAfterConnectorSave = connector => {
    if (needsAuthentication(connector) && !isAuthenticated(connector)) {
      this.authenticateConnector(connector);
    } else {
      this.retrieveConnectors();
      this._routerStore.history.push(`/org/${this.userAssignedOrgId}/settings/connections`);
    }
  };

  @action.bound
  authenticateConnector = async connector => {
    this.loadingConnectors = PromiseState.pending;
    try {
      const response = await this._connectorService.getAuthUrl(connector.id);
      window.open(response.data.url, '_self');
    } catch (e) {
      this._notificationManager.error({
        title: 'Re-Authenticate Error',
        message:
          'There was an unexpected error while attempting to re-authenticate the connection. Please try again later.'
      });
      runInAction(() => (this.loadingConnectors = PromiseState.fulfilled));
    }
  };

  @action.bound
  periodicRefresh() {
    this.periodicRefreshing = true;
    this.getAnyPendingRecrawlJob.refresh();
    this.isKbRecrawlInProgressAsyncComputed.refresh();
  }

  getConnectorById(connectorId): any | undefined {
    return this.connectors.find((connector: any) => connector.id === connectorId);
  }

  @computed
  get selectedOrgId() {
    return this._routerStore.activeOrgId;
  }

  @computed
  get ticketEnabledConnectors() {
    if (this.connectors.length > 0) {
      return this.connectors.filter(
        (connector: any) => connector.ticket_submission && connector.ticket_submission.enabled
      );
    }
    return [];
  }

  @computed
  get hasTicketEnabledConnectors() {
    return this.ticketEnabledConnectors.length > 0;
  }

  @computed
  get knowledgeBaseConnectors() {
    return this.connectors.filter((connector: any) => KB_CONNECTOR_TYPES.includes(connector.source.type));
  }

  @computed
  get hasKnowledgeBaseConnectors() {
    return this.knowledgeBaseConnectors.length > 0;
  }

  @computed
  get ticketConnectors() {
    return this.connectors.filter((connector: any) => TICKET_CONNECTOR_TYPES.includes(connector.source.type));
  }

  @computed
  get externalApiConnectors(): Source[] {
    return this.connectors.filter((connector: any) => connector.source.type === 'external_api');
  }

  @computed
  get hasTicketConnectors() {
    return this.ticketConnectors.length > 0;
  }

  @computed
  get isZendeskConnector() {
    if (this.connectors.length > 0) {
      return this.connectors.find((connector: any) => connector.source.type.toUpperCase() === 'ZENDESK');
    }
    return false;
  }

  @computed
  get needAuthentication() {
    let authenticate = false;
    if (this.selectedConnector.id !== null) {
      if (needsAuthentication(this.selectedConnector)) {
        authenticate = !isAuthenticated(this.selectedConnector);
      }
    } else {
      authenticate = true;
    }
    return authenticate;
  }

  @computed
  get isConnectorsLoaded() {
    return this.loadingConnectors !== PromiseState.rejected;
  }

  @computed
  get isKbRecrawlInProgress() {
    if (this.isKbRecrawlInProgressAsyncComputed.fulfilled) {
      return this.isKbRecrawlInProgressAsyncComputed.value;
    }
    return true;
  }

  @computed
  get recrawlJobWithRequestForApprovalStatus() {
    if (this.getAnyPendingRecrawlJob.fulfilled) {
      return (
        this.getAnyPendingRecrawlJob.value.length > 0 &&
        this.getAnyPendingRecrawlJob.value[0].status === 'requested_for_approval'
      );
    }
    return false;
  }

  @computed
  get shouldPeriodicRefresh() {
    return this._routerStore.activeOrgPage === 'recrawlHub' && this.isKbRecrawlInProgress;
  }

  @computed
  get lastRecrawlInfo(): any {
    const filteredConnectors: any = this.connectors.filter(
      (connector: any) =>
        connector.enabled &&
        connector.rules &&
        !connector.rules.disable_kb_ingest &&
        KB_CONNECTOR_TYPES.indexOf(connector.source.type) > -1
    );

    if (filteredConnectors.length > 0) {
      const lastCrawledDate = moment(filteredConnectors[0].last_ingestion_started_at);
      return lastCrawledDate.isValid() ? moment.tz(lastCrawledDate, this.orgTimeZone).format('llll') : 'unknown';
    }
    return 'loading...';
  }

  goToServiceProviderOnboardingRoute() {
    this._onboardingStore.updateCurrentStep(OnboardingSteps.SERVICE_PROVIDER);
    this._routerStore.history.push(
      onboardingPageRoute.stringify({
        ...this._routerStore.activeRouteParams,
        orgId: this.userAssignedOrgId,
        orgPage: 'onboarding'
      })
    );
  }

  goToOrgMain() {
    this._routerStore.history.push(
      orgPageRoute.stringify({
        ...this._routerStore.activeRouteParams,
        orgPage: 'analytics'
      })
    );
  }
}
