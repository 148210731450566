import { OrgPermission } from '@solvvy/util/lib/authorization/RoleModel';
import { Input, Select, Tag } from 'antd';
import { observer } from 'mobx-react';
import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import { pickColorForText } from '../../shared/util/ColorPicker';
import { OrgStore } from '../../store/orgStore';
import { IStep } from '../../store/ResolveUI/Workflow';
import { typedInject } from '../../store/rootStore';
import { WorkflowStore } from '../../store/workflowStore';

// if styles grows, move to separate file.
export const StyledNotesTags = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IProps {
  onChange: (e: any) => void;
  tagsStandaloneMode?: boolean;
  tagsExternal?: string[]; // only passed in tagsStandaloneMode
  step?: IStep;
  workflowStore: WorkflowStore;
  orgStore: OrgStore;
}

@observer
class NotesAndTags extends Component<IProps, {}> {
  notesTagsContainerRef: any = createRef();
  tagSelectRef: any = createRef();

  componentDidMount() {
    if (this.props.workflowStore.focusTagEditor && this.notesTagsContainerRef.current && this.tagSelectRef.current) {
      setTimeout(() => {
        this.notesTagsContainerRef.current.scrollIntoView(true);
        this.tagSelectRef?.current?.focus();
        this.props.workflowStore.setFocusToTagEditorWorkflowProperties(false);
      });
    }
  }

  tagRender(props) {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={pickColorForText(value)}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  }

  handleTagChange = value => {
    this.props.onChange({ name: 'tags', value: value.map(v => v.trim()) });
  };

  render() {
    const { workflowStore, onChange, orgStore, tagsExternal, tagsStandaloneMode, step } = this.props;
    const { allExistingWorkflowTags, workflowToEdit } = workflowStore;
    const { hasPermission } = orgStore;

    if (!workflowToEdit) {
      return null;
    }

    let tags;
    let notes;
    if (tagsStandaloneMode) {
      tags = tagsExternal;
    } else if (workflowStore.selectedStepId !== 'main') {
      // step tags and notes
      tags = step?.tags || [];
      notes = step?.notes;
    } else if (workflowStore.selectedStepId === 'main') {
      // workflow tags and notes
      tags = workflowToEdit.tags || [];
      notes = workflowToEdit.notes;
    }

    return (
      <StyledNotesTags ref={this.notesTagsContainerRef}>
        {!tagsStandaloneMode && <label>Tags</label>}
        <Select
          mode="tags"
          autoFocus={tagsStandaloneMode}
          placeholder="Select or Create new Tags"
          ref={this.tagSelectRef}
          defaultValue={tags}
          value={tags}
          tagRender={this.tagRender}
          disabled={!hasPermission(OrgPermission.workflowUpdate)}
          onChange={value => this.handleTagChange(value)}
          tokenSeparators={[',']}
        >
          {allExistingWorkflowTags.map(tag => (
            <Select.Option key={tag} value={tag}>
              {tag}
            </Select.Option>
          ))}
        </Select>
        {!tagsStandaloneMode && (
          <>
            <label>Notes</label>
            <Input.TextArea
              id={'notes-field'}
              name="notes"
              placeholder="Enter a Note"
              rows={3}
              value={notes}
              disabled={!hasPermission(OrgPermission.workflowUpdate)}
              onChange={e => onChange(e.target)}
            />
          </>
        )}
      </StyledNotesTags>
    );
  }
}

export default typedInject('workflowStore', 'orgStore')(NotesAndTags);
