import { IPluginManifest } from 'src/store/pluginStore';
import { Api } from './api';

export class PluginService {
  constructor(private _api: Api) {}

  async getPluginsManifest(plugins: string[], selectedOrgId: number): Promise<Record<string, IPluginManifest>> {
    if (plugins.length === 0) {
      return {};
    }
    const { data } = await this._api.get('/v1/plugins/manifest', { plugins, org_id: selectedOrgId });
    return data;
  }
}

const pluginService = api => new PluginService(api);

export default pluginService;
