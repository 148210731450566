import isNil from 'lodash/isNil';
import numeral from 'numeral';

export const formatInteger = value => {
  return numeral(value).format('1,000');
};

export const formatPercentage = value => {
  return numeral(value).format('0.0%');
};

export const getFormattedPercentValue = (numerator: string, denominator: string) => {
  if (denominator === '0') {
    return formatPercentage(0);
  }
  return formatPercentage(parseInt(numerator, 10) / parseInt(denominator, 10));
};

export const formatWholeDollars = value => {
  return numeral(value).format('$1,000');
};

export const formatDollarsAndCents = value => {
  return numeral(value).format('$1,000.00');
};

// NOTE: At some point, this.props.orgStore.orgSettings became actual booleans, or the code was switched to strings at an earlier point.
export const makeBoolean = value => {
  if (isNil(value)) {
    return false;
  }
  if (typeof value === 'boolean') {
    return value;
  } else {
    // Convert to string before getting Lower case as there are some errors reporting toLowerCase is not a function
    // This error happens only if value is other than string.
    return value.toString().toLowerCase() === 'true';
  }
};

export const formatEmail = email => {
  return email.toLowerCase().trim();
};

export const getNodesFromConnection = (connection: { edges: any[] }) => connection.edges.map(edge => edge.node);

export const isNumeric = x => (typeof x === 'number' || typeof x === 'string') && !isNaN(Number(x));
