import { OrgPermission } from '@solvvy/util/lib/authorization';
import isEmpty from 'lodash/isEmpty';
import { computed, observable, reaction } from 'mobx';

import { CombinedOrgOverviewReport } from 'src/routes/Analytics/AnalyticsReportIdTypes';
import { eventsService } from 'src/services';
import asyncComputed from 'src/shared/util/asyncComputed';
import { OrgStore } from '../orgStore';

export class CombinedAnalyticsStore {
  @observable
  filteredSelectedOrgInfo: any = [];

  @computed
  get analyticsOrgs() {
    const isCombinedAnalyticsPseudoOrg = org => org.id === 0;
    return this._orgStore.associatedOrgs.filter(
      org =>
        isCombinedAnalyticsPseudoOrg(org) || this._orgStore.hasPermissionForOrg(org.id, OrgPermission.analyticsRead)
    );
  }

  @computed
  get sortedCombinedOrgInfo() {
    return this.analyticsOrgs.sort((a, b) => (a.id > b.id ? 1 : -1));
  }

  @computed
  get lengthOfFilteredSelectedOrgInfo() {
    if (isEmpty(this.filteredSelectedOrgInfo)) {
      return '--';
    }
    return this.filteredSelectedOrgInfo.filter(org => org.selectedForOverViewReport).map(org => org.id).length;
  }

  getGroupAnalyticsReportUrl = asyncComputed(async () => {
    if (!isEmpty(this.filteredSelectedOrgInfo)) {
      const associatedOrgs = this.filteredSelectedOrgInfo
        .filter(org => org.selectedForOverViewReport)
        .map(org => org.id);
      if (!isEmpty(associatedOrgs)) {
        const { data } = await eventsService.getLookerReportUrl({
          org_group_id: this._orgStore.currentOrgGroupId,
          report_id: CombinedOrgOverviewReport.modeId,
          user_id: this._orgStore.currentUserContext.id,
          orgs: associatedOrgs.toString()
        });
        return data;
      }
    }
    return [];
  });

  constructor(private _orgStore: OrgStore) {
    reaction(
      () => this.analyticsOrgs,
      () => {
        this.filteredSelectedOrgInfo = this.sortedCombinedOrgInfo.filter(org => org.id !== 0);
      }
    );
  }
}
