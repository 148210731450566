import flat from 'flat';
import { mergeWith } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import merge from 'lodash/merge';
import set from 'lodash/set';
import { action, computed, observable } from 'mobx';
import { ChannelOptionPluginTypes } from '../../routes/InterfaceSetup/configOptions/ChannelOptionPluginTypes';
import { recordErrors } from '../../shared/util/recordErrors';
import AnnouncementConfiguration from '../models/AnnouncementConfiguration';
import { GenericSupportOption } from '../models/GenericSupportOption';
import LinkSupportOption from '../models/LinkSupportOption';
import PhoneNumber from '../models/PhoneNumber';
import PhoneSupportOption from '../models/PhoneSupportOption';
import SupportOptionSchedule, { DEFAULT_SCHEDULE, IHours, ISchedule } from '../models/SupportOptionSchedule';
import { BaseSolvvyUiInstance } from './BaseSolvvyUiInstance';
import { IConfigurationPath, ITextGroup, UiMajorVersion } from './types';
import { customMergeSuggestions, getTextOverridePaths, inferTypeFromPluginName, SUPPORT_OPTION_TYPES } from './Util';

function addZero(value) {
  if (value.length < 2 && parseInt(value, 10) < 10) {
    return '0' + value;
  }
  return value;
}

export class UiInstance extends BaseSolvvyUiInstance {
  static createRaw(instance: UiInstance) {
    const myRaw = instance.raw;

    const newRaw = mergeWith(
      {},
      myRaw,
      BaseSolvvyUiInstance.createRaw(instance),
      instance.uiMajorVersion === UiMajorVersion.V4 || instance.uiMajorVersion === UiMajorVersion.V5
        ? {
            announcement: instance.enableAnnouncement
              ? {
                  title: instance.announcementConfiguration.title,
                  message: instance.announcementConfiguration.message,
                  url: instance.announcementConfiguration.url,
                  linkText: instance.announcementConfiguration.linkText,
                  autoShow: instance.announcementConfiguration.autoShow,
                  styleLinkAsButton: instance.announcementConfiguration.styleLinkAsButton,
                  countClickAsInstantResolution: instance.announcementConfiguration.countClickAsInstantResolution
                }
              : null
          }
        : {},
      customMergeSuggestions
    );

    if (instance.uiMajorVersion === UiMajorVersion.V4) {
      // since all text will be in textOverrides, need to remove old text from raw
      const uiWithoutText = cloneDeep(myRaw.ui || {});

      // removes text from ui property for a v4 instance
      this.removeTextFromUi(uiWithoutText);

      // the updated ui object with changes for a v4 instance
      const newUi = merge({}, uiWithoutText, this.getRawUiFromTextOverrides(instance.textOverrides));
      if (instance.enableSupportOptionOrgSetting) {
        Object.assign(
          newRaw,
          { ui: newUi },
          this.getRawSupportOptions(instance, myRaw.supportOptions),
          this.getRawSupportOptionSchedules(instance)
        );
      } else {
        Object.assign(newRaw, { ui: newUi }, { supportOptions: null }, { supportOptionSchedules: null });
      }
    }

    if (instance.uiMajorVersion === UiMajorVersion.V5) {
      if (!newRaw.text) {
        newRaw.text = {};
      }

      if (!newRaw.feedOptions) {
        newRaw.feedOptions = { text: {} };
      }

      // get the text object from the raw v5 instance
      const textInstance = cloneDeep(myRaw.text || {});
      const feedText = cloneDeep(myRaw.feedOptions.text);

      // get the new Text from the text overrides for v5 since it has different strings in the configuration
      const newText = merge({}, textInstance, this.getRawTextFromOverrides(instance.textOverrides));
      const newFeedText = merge({}, feedText, this.getRawFeedTextFromOverrides(instance.resolveFeedTextOverrides));

      if (instance.enableSupportOptionOrgSetting) {
        const channelOptions = this.getRawSupportOptions(instance, myRaw.feedOptions.channelOptions).supportOptions;
        const channelOptionSchedules = this.getRawSupportOptionSchedules(instance).supportOptionSchedules;
        Object.assign(newRaw, { text: newText });
        Object.assign(newRaw.feedOptions, { channelOptions, channelOptionSchedules, text: newFeedText });
      } else {
        Object.assign(newRaw, { text: newText });
        Object.assign(newRaw.feedOptions, { channelOptions: null, channelOptionSchedules: null, text: newFeedText });
      }
    }
    return newRaw;
  }

  static getV4TextOverridePaths(
    uiInstance: UiInstance,
    textOverrideGroups: ITextGroup[],
    prefix: string = ''
  ): IConfigurationPath[] {
    // get all the potential text override keys for the specified groups
    const potentialTextOverridePaths = getTextOverridePaths(textOverrideGroups);

    // get paths for actual text overrides for this instance
    const paths = potentialTextOverridePaths
      .filter(pathObj => uiInstance.textOverrides.get(pathObj.path))
      .map(pathObj => ({ ...pathObj, path: prefix + UiInstance.convertTextOverrideKeyToRawUiPath(pathObj.path) }));

    return paths;
  }

  static convertTextOverrideKeyToRawUiPath(textOverrideKey: string): string {
    const regexMatch = textOverrideKey.match(/(\w+)\.(.*)/);
    if (regexMatch) {
      const groupKey = regexMatch[1];
      const stringKey = regexMatch[2];
      const prefix = groupKey === 'root' ? '' : `${groupKey}.`;
      return prefix + `text.${stringKey}`;
    }
    return textOverrideKey;
  }

  static convertTextOverrideToRawTextPathV5(textOverrideKey: string): string {
    return textOverrideKey;
  }

  static getRawUiFromTextOverrides(textOverrides) {
    const ui = {};

    for (const [key, value] of Array.from(textOverrides.entries()) as any) {
      const path = this.convertTextOverrideKeyToRawUiPath(key);
      set(ui, path, value);
    }
    return ui;
  }

  @action
  static getRawTextFromOverrides(textOverrides) {
    const text = {};

    for (const [key, value] of Array.from(textOverrides.entries()) as any) {
      const path = this.convertTextOverrideToRawTextPathV5(key);
      set(text, path, value);
    }
    return text;
  }

  @action
  static getRawFeedTextFromOverrides(feedTextOverrides) {
    const feedText = {};
    for (const [key, value] of Array.from(feedTextOverrides.entries()) as any) {
      set(feedText, key, value);
    }
    return feedText;
  }

  static removeTextFromUi(rawUi) {
    if (rawUi) {
      delete rawUi.text;
      for (const value of Object.entries(rawUi)) {
        if (isObject(value)) {
          delete (value as any).text;
        }
      }
    }
  }

  static getRawSupportOptions(instance: UiInstance, existingRawSupportOptions?: any[] | null) {
    const supportOptions: any[] = cloneDeep(existingRawSupportOptions || []);

    (instance.supportOptions || []).forEach(
      (supportOption: GenericSupportOption & PhoneSupportOption & LinkSupportOption) => {
        // find corresponding raw support option
        const index = supportOptions.findIndex(so => so.id === supportOption.id);

        const text = supportOption.getRawText();

        const dashboardOptions: any = {};

        if (supportOption.phoneNumbers) {
          set(
            dashboardOptions,
            'phoneNumbers',
            supportOption.phoneNumbers.map((phoneNumber: PhoneNumber) => ({
              number: phoneNumber.number,
              title: phoneNumber.title
            }))
          );
        }

        set(dashboardOptions, 'alwaysOffline', supportOption.alwaysOffline);
        set(dashboardOptions, 'workflowsOnly', supportOption.workflowsOnly);

        if (supportOption.normalizedPluginName === ChannelOptionPluginTypes.Link) {
          set(dashboardOptions, 'link', supportOption.url);
        }

        const supportOptionTextPath = this.getChannelOptionTextPath(instance);
        set(dashboardOptions, supportOptionTextPath, text);
        supportOptions[index].options = merge({}, supportOptions[index].options, dashboardOptions);
        // phoneNumbers is an array so needs to be set
        if (UiInstance.isPhoneType(supportOptions[index].type) && supportOptions[index].options.phoneNumbers) {
          set(supportOptions[index].options, 'phoneNumbers', dashboardOptions.phoneNumbers);
        }
      }
    );

    return { supportOptions };
  }

  static isPhoneType(type: string) {
    return type === ChannelOptionPluginTypes.Phone || type === 'V4PhoneSupportOption';
  }

  static getChannelOptionTextPath(instance: UiInstance) {
    let supportOptionTextPath = '';
    if (instance.uiMajorVersion === UiMajorVersion.V4) {
      supportOptionTextPath = 'ui.text';
    }

    if (instance.uiMajorVersion === UiMajorVersion.V5) {
      supportOptionTextPath = 'text';
    }
    return supportOptionTextPath;
  }

  /*
    If no existing support option is present then the schedule will only be saved if the user has selected to save to support option schedule.
  */
  static getRawSupportOptionSchedules(instance: UiInstance) {
    const supportOptionSchedules: SupportOptionSchedule[] = [];

    (instance.supportOptions || []).forEach((supportOption: any) => {
      const schedule = this.getFormattedSchedule(supportOption.schedule.schedule);

      if (!isEmpty(schedule)) {
        supportOptionSchedules.push({
          schedule,
          timeZone: supportOption.schedule.timeZone,
          scheduledPluginIds: supportOption.schedule.scheduledPluginIds
        });
      }
    });

    return {
      supportOptionSchedules
    };
  }

  /*
    Filter out the closed days and retrieve the hours in 24hr format to be saved in database. Every time a new schedule is always saved to database
  */
  static getFormattedSchedule(supportOptionSchedule) {
    const formatStartDate = startHourArr => `${addZero(startHourArr[0])}:${startHourArr[1]}`;
    const formatHours = (hours: IHours[]) =>
      hours.map(hour => {
        const startHourArr = hour.start.split(':');
        return {
          enabled: formatStartDate(startHourArr),
          disabled: hour.end
        };
      });

    const filteredDisabledScheduleDays: ISchedule[] = supportOptionSchedule.filter(
      (schedule: ISchedule) => !schedule.closed
    );
    const newSchedule: Partial<ISchedule | any[]> = [];

    for (const schedule of filteredDisabledScheduleDays) {
      if (schedule.hours.length > 0) {
        newSchedule.push({
          dayOfWeek: schedule.dayOfWeek,
          hours: formatHours(schedule.hours)
        });
      }
    }
    return newSchedule;
  }

  @observable
  enableAnnouncement: boolean;

  @observable
  announcementConfiguration: AnnouncementConfiguration;

  @observable
  supportOptions: GenericSupportOption[];

  @observable
  textOverrides = new Map();

  @observable
  resolveFeedTextOverrides = new Map();

  constructor(public raw: any, private _orgTimeZone: string, _orgSettings: any, public configuration) {
    super(raw, _orgSettings, configuration);
    this.applyFromRaw(raw);
  }

  @action
  applyFromRaw(raw) {
    try {
      super.applyFromRaw(raw);

      // announcements
      this.retrieveAnnouncementDetails(raw);

      // check supportOptions or feedOptions since v5 has support options inside feedOptions
      if (isNil(raw.feedOptions) || isEmpty(raw.feedOptions)) {
        raw.feedOptions = {};
      }
      if (
        (!isEmpty(raw.supportOptions) || !isEmpty(raw.feedOptions.channelOptions)) &&
        this.enableSupportOptionOrgSetting
      ) {
        // support options
        const rawSupportOptions =
          (this.uiMajorVersion === UiMajorVersion.V5 ? raw.feedOptions.channelOptions : raw.supportOptions) || [];
        const rawSupportOptionSchedules =
          (this.uiMajorVersion === UiMajorVersion.V5
            ? raw.feedOptions.channelOptionSchedules
            : raw.supportOptionSchedules) || [];
        this.hasSupportOptions = true;
        this.retrieveSupportOptionDetails(rawSupportOptions, rawSupportOptionSchedules);
      }

      if (this.uiMajorVersion === UiMajorVersion.V4) {
        this.setTextOverridesFromRaw(raw);
      }
      if (this.uiMajorVersion === UiMajorVersion.V5) {
        this.setV5TextOverridesFromRaw(raw);
        this.setResolveFeedTextOverridesFromRaw(raw);
      }
    } catch (e) {
      recordErrors(e);
    }
  }

  retrieveAnnouncementDetails(raw) {
    this.enableAnnouncement = !isEmpty(raw.announcement);
    const announcementConfiguration = raw.announcement;
    this.announcementConfiguration = new AnnouncementConfiguration();
    this.announcementConfiguration.title = get(
      announcementConfiguration,
      'title',
      this.announcementConfiguration.title
    );
    this.announcementConfiguration.message = get(
      announcementConfiguration,
      'message',
      this.announcementConfiguration.message
    );
    this.announcementConfiguration.url = get(announcementConfiguration, 'url', this.announcementConfiguration.url);
    this.announcementConfiguration.linkText = get(
      announcementConfiguration,
      'linkText',
      this.announcementConfiguration.linkText
    );
    this.announcementConfiguration.autoShow = get(
      announcementConfiguration,
      'autoShow',
      this.announcementConfiguration.autoShow
    );
    this.announcementConfiguration.styleLinkAsButton = get(
      announcementConfiguration,
      'styleLinkAsButton',
      this.announcementConfiguration.styleLinkAsButton
    );
    this.announcementConfiguration.countClickAsInstantResolution = get(
      announcementConfiguration,
      'countClickAsInstantResolution',
      this.announcementConfiguration.countClickAsInstantResolution
    );
  }

  retrieveSupportOptionDetails(rawSupportOptions, rawSupportOptionSchedules) {
    this.supportOptions = [];

    rawSupportOptions.forEach((rawSupportOption: any) => {
      const { id, type: pluginName } = rawSupportOption;

      const type = inferTypeFromPluginName(pluginName);
      let option;
      switch (type) {
        case SUPPORT_OPTION_TYPES.PHONE:
        case SUPPORT_OPTION_TYPES.SMS:
          option = new PhoneSupportOption(rawSupportOption, this.uiMajorVersion);
          break;
        case SUPPORT_OPTION_TYPES.LINK:
          option = new LinkSupportOption(rawSupportOption, this.uiMajorVersion);
          break;
        default:
          option = new GenericSupportOption(rawSupportOption, this.uiMajorVersion);
          break;
      }

      const supportOptionSchedule = rawSupportOptionSchedules.find(schedule =>
        schedule.scheduledPluginIds.includes(id)
      );

      if (!supportOptionSchedule) {
        option.schedule.schedule = DEFAULT_SCHEDULE;
        option.schedule.timeZone = this._orgTimeZone;
      } else {
        option.schedule.schedule = this.retrieveFormattedSupportHourSchedule(supportOptionSchedule);
        option.schedule.timeZone = supportOptionSchedule.timeZone;
      }
      option.schedule.scheduledPluginIds = [id];

      this.supportOptions.push(option);
    });
  }

  get supportOptionErrorCount() {
    if (isNil(this.supportOptions)) {
      return 0;
    }

    if (this.currentInstanceIsV4Version) {
      return 0;
    }

    let buttonTextErrorCount = 0;
    let phoneNumbersErrorCount = 0;
    let launchButtonErrorCount = 0;
    let linkUrlErrorCount = 0;

    this.supportOptions.forEach((supportOption: GenericSupportOption & PhoneSupportOption & LinkSupportOption) => {
      const isButtonTextError = supportOption.button.length === 1 && supportOption.button === ' ';
      const isLaunchButtonTextError = supportOption.launchButton?.length === 1 && supportOption.launchButton === ' ';
      const isPhoneError =
        supportOption.type === SUPPORT_OPTION_TYPES.PHONE &&
        (supportOption.phoneNumbers?.length === 0 || isEmpty(supportOption.phoneNumbers[0].number));
      const isLinkUrlError = supportOption.type === SUPPORT_OPTION_TYPES.LINK && isEmpty(supportOption.url);

      buttonTextErrorCount += isButtonTextError ? 1 : 0;
      phoneNumbersErrorCount += isPhoneError ? 1 : 0;
      launchButtonErrorCount += isLaunchButtonTextError ? 1 : 0;
      linkUrlErrorCount += isLinkUrlError ? 1 : 0;
    });

    return buttonTextErrorCount + phoneNumbersErrorCount + launchButtonErrorCount + linkUrlErrorCount;
  }

  setTextOverridesFromRaw(raw: { ui: any }) {
    const flattened = flat(raw.ui || {});

    for (const [key, value] of Object.entries(flattened)) {
      const matchedText = key.match(/(^|(\w+)\.)text\.(.+)/);
      if (matchedText) {
        const groupKey = matchedText[2] || 'root';
        const stringKey = matchedText[3];
        const fullKey = `${groupKey}.${stringKey}`.replace('.text.', '.');
        this.textOverrides.set(fullKey, value);
      }
    }
  }

  setV5TextOverridesFromRaw(raw: { text: any }) {
    const flattened = flat(raw.text || {});

    for (const [key, value] of Object.entries(flattened)) {
      this.textOverrides.set(key, value);
    }
  }

  setResolveFeedTextOverridesFromRaw(raw: { feedOptions: any }) {
    const flattened = flat(raw.feedOptions.text || {});
    for (const [key, value] of Object.entries(flattened)) {
      this.resolveFeedTextOverrides.set(key, value);
    }
  }

  retrieveFormattedSupportHourSchedule(supportOptionSchedule: { schedule: any[] }) {
    return DEFAULT_SCHEDULE.map(defaultDaySchedule => {
      const currentDaySchedule = supportOptionSchedule.schedule.find(
        scheduleInfo => scheduleInfo.dayOfWeek === defaultDaySchedule.dayOfWeek
      );
      if (!isEmpty(currentDaySchedule)) {
        currentDaySchedule!.hours = currentDaySchedule.hours.map(hour => ({ start: hour.enabled, end: hour.disabled }));
        currentDaySchedule!.closed = false;
        currentDaySchedule.step = defaultDaySchedule.step;
        currentDaySchedule.id = defaultDaySchedule.id;
        return currentDaySchedule;
      } else {
        return defaultDaySchedule;
      }
    });
  }

  @computed
  get displayName() {
    if (this.name) {
      return this.name.charAt(0).toUpperCase() + this.name.slice(1);
    }

    return 'Default';
  }
}
