import { AuthorizationInfo } from '@solvvy/util/lib/authorization';
import { OrgGroupRole, OrgGroupRoles } from '@solvvy/util/lib/authorization/RoleModel';
import capitalize from 'lodash/capitalize';
import { action, computed, observable } from 'mobx';
import moment from 'moment-timezone';
import { userService } from '../../services';

const REMEMBER_GLOBAL_USER_VIEW_ROLES_LOCAL_STORAGE_KEY = 'globalUserViewRoles';
export const GLOBAL_USER_ROLE = 'GLOBAL_USER';

export type UserData = Pick<
  User,
  'id' | 'first_name' | 'last_name' | 'email' | 'created_at' | 'global_membership' | 'org_memberships'
>;

export interface ISSOConnection {
  organization_id: string;
  connection_id: string;
  connection_name: string;
  connection_type: string;
}
export interface IOrgGroupSSODetails {
  id: number;
  name: string;
  workos_details: ISSOConnection[];
}

export interface IOrgMembership {
  org_id?: number;
  org: {
    id: number;
    name: string;
    org_group_id: number;
    settings: {
      is_active: boolean;
    };
  };
  roles: string[];
  receive_voc_email: boolean;
}

export interface IUserData {
  first_name: string;
  last_name: string;
  email: string;
  org_memberships: IOrgMembership[];
}

export class User {
  @computed
  get org_membership(): IOrgMembership | undefined {
    return this.org_memberships.length > 0 ? this.org_memberships[0] : undefined;
  }

  @computed
  get org_group_id(): number {
    return (this.org_membership && this.org_membership.org.org_group_id) || this.currentUser.org_group_id;
  }

  @computed
  get full_name() {
    return `${capitalize(this.first_name)} ${capitalize(this.last_name)}`;
  }

  @computed
  get isGlobalUser() {
    if (!this.globalUserViewRoleNames) {
      return this.isActualGlobalUser;
    }
    return this.globalUserViewRoleNames.includes(GLOBAL_USER_ROLE) ? this.isActualGlobalUser : false;
  }

  /**
   * even if a global admin uses the profile settings to switch their "view role" to something else,
   * this will still return true unlike the isGlobalUser above which would return false
   */
  @computed
  get isActualGlobalUser() {
    return this.global_membership ? this.global_membership.global_role === 'ROOT' : false;
  }

  @computed
  get lastLoginAt() {
    return moment(this.last_login_at || new Date()).format('MMMM D, YYYY');
  }

  @computed get vocOrgIds() {
    return this.org_memberships.filter(({ receive_voc_email }) => receive_voc_email).map(({ org }) => org.id);
  }

  @observable
  id: number;
  @observable
  first_name: string;
  @observable
  last_name: string;
  @observable
  email: string;
  @observable
  created_at;
  @observable
  currentUser;
  @observable
  global_membership?: {
    global_role;
  };
  @observable
  org_memberships: IOrgMembership[];
  @observable
  receive_voc_email: boolean;
  @observable
  messages_last_read_at: Date;
  @observable
  last_login_at: Date;
  @observable
  updatingUser: boolean;
  @observable
  globalUserViewRoleNames?: string[];
  @observable
  is_two_factor_authentication_enabled: boolean;

  authorizationInfo: AuthorizationInfo;

  constructor(data: UserData, loggedInUser: boolean = false) {
    Object.assign(this, data);
    this.currentUser = loggedInUser;
    const lastGlobalUserViewRoles = localStorage.getItem(REMEMBER_GLOBAL_USER_VIEW_ROLES_LOCAL_STORAGE_KEY);
    if (lastGlobalUserViewRoles) {
      this.globalUserViewRoleNames = lastGlobalUserViewRoles.split(',');
    } else {
      this.globalUserViewRoleNames = [GLOBAL_USER_ROLE];
    }
  }

  @action
  updateGlobalUserViewRoleNames(roles?: string[]) {
    if (this.isActualGlobalUser) {
      this.globalUserViewRoleNames = roles;
      if (roles && !this.isOnlyGlobalUserRoleSelected(roles)) {
        localStorage.setItem(REMEMBER_GLOBAL_USER_VIEW_ROLES_LOCAL_STORAGE_KEY, roles.join(','));
      } else {
        localStorage.removeItem(REMEMBER_GLOBAL_USER_VIEW_ROLES_LOCAL_STORAGE_KEY);
      }
    }
  }

  @computed
  get globalUserViewRoles() {
    if (!this.globalUserViewRoleNames) {
      return undefined;
    }
    const roles = this.globalUserViewRoleNames.filter(roleName => roleName !== GLOBAL_USER_ROLE);
    return roles.length ? roles : undefined;
  }

  @action
  updateCache(data: User) {
    Object.assign(this, data);
  }

  @action
  updateLastMessagesReadAt(formattedLastMessageCreatedAt) {
    this.messages_last_read_at = formattedLastMessageCreatedAt;
  }

  getRolesForOrg(orgId: number): OrgGroupRole[] {
    const orgMembership = this.org_memberships.find(membership => membership.org.id === orgId);
    return orgMembership ? orgMembership.roles.map(roleName => OrgGroupRoles[roleName]) : [];
  }

  getRoleNamesForOrg(orgId: number): string[] {
    return this.getRolesForOrg(orgId).map(role => role.name);
  }

  getVocEnabledForOrg(orgId: number): boolean {
    const orgMembership = this.org_memberships.find(membership => membership.org.id === orgId);
    return Boolean(orgMembership && orgMembership.receive_voc_email);
  }

  isOnlyGlobalUserRoleSelected = (roles: string[]) => {
    return roles.length === 1 && roles[0] === GLOBAL_USER_ROLE;
  };

  async destroy() {
    return userService.deleteUser(this.id);
  }

  async assignOrgGroupRole(org_group_id: number, org_group_role: string) {
    return userService.assignOrgGroupRoleToUser(this.id, org_group_role, org_group_id);
  }

  async updateUserVoCOrgs(orgIds: number[]) {
    return userService.updateUserProfileVoCOrgs(orgIds);
  }

  async changeViewerPassword(old_password, new_password) {
    return userService.changeViewerPassword(old_password, new_password);
  }
}
