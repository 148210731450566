import { Button, Form, FormInstance, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { IWorkflow } from '../../store/ResolveUI/Workflow';
import { typedInject } from '../../store/rootStore';
import { WorkflowStore } from '../../store/workflowStore';

interface IProps {
  workflow: IWorkflow;
  onCancel: () => void;
  onSuccess?: () => void;
  workflowStore: WorkflowStore;
}

interface IState {
  publishing?: boolean;
}

@observer
class PublishWorkflowModal extends Component<IProps, IState> {
  state: IState = {};
  formRef = React.createRef<FormInstance>();

  @action.bound
  async publishWorkflow({ publishedComments }) {
    const { workflowStore, workflow, onSuccess } = this.props;
    this.setState({ publishing: true });
    await workflowStore.publishWorkflow(workflow, publishedComments);
    if (onSuccess) {
      onSuccess();
    }
  }

  render() {
    const { onCancel } = this.props;
    const { publishing } = this.state;

    return (
      <Modal
        visible={true}
        title={null}
        onCancel={onCancel}
        width={600}
        footer={
          <div>
            <div className="buttons">
              <Button type="default" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={publishing}
                onClick={() => this.formRef.current?.submit()}
              >
                Publish
              </Button>
            </div>
          </div>
        }
      >
        <h3>Publish Changes</h3>

        <p>Publishing changes will overwrite the currently published workflow.</p>

        <Form ref={this.formRef} layout="vertical" onFinish={this.publishWorkflow}>
          <Form.Item
            name="publishedComments"
            required={false}
            label="Add a short description of what has changed (Required)"
            rules={[
              {
                required: true,
                message: 'A short description is required.'
              }
            ]}
          >
            <TextArea rows={3} autoFocus={true} />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default typedInject('workflowStore')(PublishWorkflowModal);
