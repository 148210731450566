import { OrgPermission } from '@solvvy/util/lib/authorization';
import { bind } from 'bind-decorator';
import _ from 'lodash';
import { action, computed, observable, runInAction } from 'mobx';
import { asyncAction } from 'mobx-utils';
import moment, { Moment } from 'moment-timezone';
import qs from 'qs';
import { IIntent, IntentService } from 'src/services/intentService';
import asyncComputed from '../shared/util/asyncComputed';
import { NotificationManager } from '../shared/util/NotificationManager';
import { recordErrors } from '../shared/util/recordErrors';
import { IExploreService } from './../services/exploreService';
import { OrgStore } from './orgStore';
import { RouterStore } from './routerStore';

const SEARCH_LIMIT = 3;
const TOP_LAUNCH_URLS_LIMIT = 100;
const DEFAULT_TIMEZONE = 'America/Los_Angeles';

const DEFAULT_FIRST_FETCH_LIMIT = 20;

/** Underscore is just to make 100% sure it does not collide with actual intent names */
export const NO_INTENT = '_No Intent_';

export interface ISolution {
  id: string;
  content: string;
  metadata: {
    title: string;
    subtitle?: string;
    url: string;
  };
  resource: {
    id: string;
  };
}

export interface IAnnotation {
  id?: string;
  comment: string;
  labels?: string[];
}

export interface IQuery {
  // common fields for session and ticket queries.
  id: string;
  /** Pick either `submitted_at` or `started_at` */
  timestamp: string;
  content: string;
  solutionTags?: string[];

  // to distinguish between session and ticket query.
  isSessionQuery: boolean;

  // session query fields
  questionId: string;

  queryId: string;
  sessionId?: string;
  launchUrl?: string;
  intents?: string[];
  solutionIds?: string[];
  isAssistedSupport?: boolean;
  isDropoff?: boolean;
  isInstantResolution?: boolean;
  isSupportContacted?: boolean;
  isSuggestion?: boolean;
  supportOption?: 'ticket' | 'chat' | 'phone' | 'facebook' | 'other';
  supportOptionRaw?: string;
  status: IQueryResolution;

  // ticket query fields
  isUserQuery: boolean;
  isTicketQuery: boolean;
  ticketResourceId?: string;

  annotation: IAnnotation;
}

export enum QueryResolutionType {
  DROP_OFF,
  SELF_SERVICED,
  SUPPORT_CONTACTED,
  ASSISTED_SUPPORT
}

export enum QueryMode {
  BY_RESOLUTION_TYPE,
  BY_CONVERSIONS,
  BY_TICKET_QUERIES
}

interface IQueryResolution {
  type: QueryResolutionType;
  text?: string;
}
interface IFetchQueryParam {
  limit: number;
  queryMode: QueryMode;
  startAt: string;
  endAt: string;
}

export interface ITriggeredWorkflow {
  id: string;
  name: string;
  skipIntentConfirmation: boolean;
  intents: ITriggeredIntent[];
}

interface ITriggeredIntent {
  label: string;
  id: string;
  score: number;
}

export interface ISearchResult {
  workflows?: ITriggeredWorkflow[];
  orgId?: number;
  solutions?: ISolution[];
}

export default class ExploreStore {
  fetchOffset: number = 0;

  incrementalFetchOffset: number = 0;

  @observable
  incrementalFetchComplete: boolean = false;

  @observable
  fetchLimit: number = 1000;

  @observable
  queryLaunchUrls?: string[];

  @observable
  queryLocalFilters: string[][] = [];

  @observable
  queryTags: string[] = [];

  @observable
  queryIntentFilters: string[] = [];

  defaultQueryResolutionTypes = [
    QueryResolutionType.DROP_OFF,
    QueryResolutionType.SELF_SERVICED,
    QueryResolutionType.SUPPORT_CONTACTED,
    QueryResolutionType.ASSISTED_SUPPORT
  ];

  @observable
  queryResolutionTypes: QueryResolutionType[] = _.clone(this.defaultQueryResolutionTypes);

  @observable
  totalQuerySearchResults?: number;

  @observable
  searching: boolean;

  @observable
  query: string = '';

  @observable
  queries: IQuery[] = [];

  @observable
  originalQuery: string = '';

  @observable
  searchTags: string = '';

  @observable
  searchResult: ISearchResult = {};

  @observable
  searchQueryIndex: number = 0;

  @observable
  customStartDate?: Moment;

  @observable
  customEndDate?: Moment;

  @observable
  currentQueryMode: QueryMode = QueryMode.BY_RESOLUTION_TYPE;

  @observable
  isSmartSuggestionEnabled: boolean = true;

  @observable
  isAnnotationFilterEnabled: boolean = false;

  topLaunchUrls = asyncComputed(() => this.loadTopLaunchUrls());

  asyncQueries = asyncComputed(() => this.loadQueries());

  asyncQueriesCount = asyncComputed(() => this.loadQueriesCount());

  annotationLabels = asyncComputed(() => this.loadAnnotationLabels());

  asyncIntents = asyncComputed(() => this.fetchIntents());

  cachedSessionQueriesCount: number;
  optionsForCachedSessionQueriesCount: any;

  cachedConversionQueriesCount: number;
  optionsForCachedConversionQueriesCount: any;

  constructor(
    private orgStore: OrgStore,
    private routerStore: RouterStore,
    private notificationManager: NotificationManager,
    private exploreService: IExploreService,
    private intentService: IntentService
  ) {
    const queryParams = this.routerStore.activeQueryParams;

    if (!_.isEmpty(queryParams)) {
      this.updateStoreFromQueryParams(queryParams);
    }
  }

  @computed
  get timezone() {
    return this.orgStore.orgSettings.timezone || DEFAULT_TIMEZONE;
  }

  @computed
  get isEngageEnabled() {
    return this.orgStore.orgSettings.engage;
  }

  @computed
  get isTicketQueriesModeEnabled() {
    return this.orgStore.orgSettings.enable_ticketqueries_explore;
  }

  @computed
  get isGlobalUser() {
    return this.orgStore.currentUserContext.isGlobalUser;
  }

  @computed
  get canCreateAnnotation(): boolean {
    return this.orgStore.hasPermission(OrgPermission.exploreCreate);
  }

  @computed
  get canUpdateAnnotation(): boolean {
    return this.orgStore.hasPermission(OrgPermission.exploreUpdate);
  }

  @computed
  get canDeleteAnnotation(): boolean {
    return this.orgStore.hasPermission(OrgPermission.exploreDelete);
  }

  @action.bound
  displayServerError(e: any) {
    const serverError = _.get(e, 'response.data.message');
    this.notificationManager.error({
      title: 'Oops something went wrong',
      message: `${e}${serverError ? '\n' + serverError : ''}`
    });
  }

  @bind
  @action
  reset() {
    this.query = '';
    this.searchResult = {};
    this.searchQueryIndex = 0;
    this.fetchOffset = 0;
    this.searchTags = '';
    this.queryTags = [];
    this.queryIntentFilters = [];
    this.queryLaunchUrls = undefined;
    this.totalQuerySearchResults = undefined;
  }

  @computed
  get defaultDateRange(): [Moment, Moment] {
    const fetchPrevDays = this.orgStore.orgSettings.explore_default_previous_days;
    return [moment().subtract(fetchPrevDays, 'days'), moment().endOf('day')];
  }

  @computed
  get customOrDefaultStartDate(): Moment {
    return this.customStartDate || this.defaultDateRange[0];
  }

  @computed
  get customOrDefaultEndDate(): Moment {
    return this.customEndDate || this.defaultDateRange[1];
  }

  @computed
  get startAt(): string {
    return this.customOrDefaultStartDate.toISOString();
  }

  @computed
  get endAt(): string {
    return this.customOrDefaultEndDate.toISOString();
  }

  @computed
  get isLocalFilterEmpty(): boolean {
    return (
      _.isEmpty(this.queryLocalFilters) &&
      this.queryResolutionTypes.length === this.defaultQueryResolutionTypes.length &&
      this.isSmartSuggestionEnabled === true
    );
  }

  @computed
  get isAPIFilterEmpty(): boolean {
    return (
      _.isEmpty(this.queryLaunchUrls) &&
      _.isEmpty(this.queryTags) &&
      _.isEmpty(this.queryIntentFilters) &&
      this.isAnnotationFilterEnabled === false &&
      this.currentQueryMode === QueryMode.BY_RESOLUTION_TYPE
    );
  }

  @computed
  get isFilterEmpty(): boolean {
    return this.isLocalFilterEmpty && this.isAPIFilterEmpty;
  }

  @computed
  get filteredQueries(): IQuery[] {
    let filteredQueries: IQuery[] = [];
    if (this.asyncQueries.fulfilled) {
      filteredQueries = this.queries;
      if (this.queryLocalFilters && this.queryLocalFilters.length > 0) {
        filteredQueries = filteredQueries.filter(query => {
          return this.queryLocalFilters.some(filter => {
            if (query.content) {
              const andFilters: string[] = filter;
              return andFilters.every(andFilter =>
                query.content.toLowerCase().includes(andFilter.toLowerCase().trim())
              );
            }
            return false;
          });
        });
      }
      filteredQueries = filteredQueries.filter((query: IQuery) => {
        let matchesResolutionType = true;
        if (
          this.currentQueryMode === QueryMode.BY_RESOLUTION_TYPE ||
          this.currentQueryMode === QueryMode.BY_CONVERSIONS
        ) {
          matchesResolutionType = query.status ? this.queryResolutionTypes.includes(query.status.type) : true;
        }

        let matchesSmartSuggestion = true;
        if (this.isSmartSuggestionEnabled !== true && query.isSuggestion === true) {
          matchesSmartSuggestion = false;
        }

        return matchesResolutionType && matchesSmartSuggestion;
      });
    }
    return filteredQueries;
  }

  @computed
  get filterQueryString() {
    const queryParams = {
      startDate: this.customOrDefaultStartDate.format('x'),
      endDate: this.customOrDefaultEndDate.format('x'),
      ...(this.queryIntentFilters.length ? { intents: this.queryIntentFilters.join(',') } : {})
    };
    return qs.stringify(queryParams);
  }

  @action
  updateStoreFromQueryParams(queryParams) {
    const startDate = _.get(queryParams, 'startDate', null);
    const endDate = _.get(queryParams, 'endDate', null);
    if (startDate && endDate) {
      this.customStartDate = moment(_.parseInt(startDate));
      this.customEndDate = moment(_.parseInt(endDate));
    }
    const intent = _.get(queryParams, 'intents', null);
    intent && (this.queryIntentFilters = intent.split(','));
  }
  @action
  searchQuery(query: IQuery, index: number) {
    this.query = query.content;
    this.originalQuery = query.content;
    this.searchTags = (query.solutionTags || []).join(', ');
    this.searchQueryIndex = index;
    this.search();
  }

  @bind
  @asyncAction
  *search() {
    this.searching = true;

    const solutionTags = (this.searchTags || '')
      .split(/\s*,\s*/)
      .map(tag => tag.trim())
      .filter(tag => Boolean(tag));

    const searchOptions = {
      create_query: false,
      limit: SEARCH_LIMIT,
      query: this.query,
      solution_tags: solutionTags.length > 0 ? solutionTags : undefined,
      include_workflows: true
    };

    try {
      if (this.query) {
        const { solutions, workflows } = yield this.exploreService.search({
          org_id: this.orgStore.selectedOrgId,
          ...searchOptions
        });

        this.searchResult = { solutions, workflows, orgId: this.orgStore.selectedOrgId };
      }
      this.searching = false;
    } catch (e) {
      this.searching = false;
      this.displayServerError(e);
      recordErrors(e);
    }
  }

  @bind
  async createOrEditSessionAnnotation(annotation: IAnnotation, query: IQuery) {
    const annotationData = {
      id: annotation.id,
      event_id: query.sessionId,
      org_id: this.orgStore.selectedOrgId,
      comment: annotation.comment,
      labels: annotation.labels,
      created_by: this.orgStore.currentUserContext.id
    };
    try {
      let response;
      if (annotation.id) {
        await this.exploreService.editSessionAnnotation(annotationData);
        return annotationData;
      } else {
        response = await this.exploreService.createSessionAnnotation(annotationData);
      }
      return response;
    } catch (e) {
      this.displayServerError(e);
      recordErrors(e);
      throw e;
    }
  }

  @bind
  async deleteSessionAnnotation(annotation: IAnnotation) {
    try {
      await this.exploreService.deleteSessionAnnotation(annotation);
    } catch (e) {
      this.displayServerError(e);
      recordErrors(e);
      throw e;
    }
  }

  @action
  clearLocalFilters() {
    // clear local filters
    this.queryLocalFilters = [];
    this.queryResolutionTypes = this.defaultQueryResolutionTypes;
    this.isSmartSuggestionEnabled = true;
    this.totalQuerySearchResults = undefined;
    this.currentQueryMode = QueryMode.BY_RESOLUTION_TYPE;
  }

  @action
  clearAPIFilters() {
    // clear API filters
    this.queryLaunchUrls = undefined;
    this.queryTags = [];
    this.queryIntentFilters = [];
    this.fetchOffset = 0;
    this.isAnnotationFilterEnabled = false;
  }

  @action
  clearQuerySearchResults() {
    this.fetchOffset = 0;
    this.incrementalFetchOffset = 0;
    this.queries = [];
  }

  @bind
  async loadQueries() {
    const newQueries = await this.fetchQueries();
    runInAction(() => (this.queries = newQueries));
  }

  @bind
  async fetchMoreQueries(): Promise<IQuery[]> {
    this.fetchOffset += this.fetchLimit;
    return this.fetchQueries();
  }

  async fetchQueries() {
    const queryModes = {
      [QueryMode.BY_CONVERSIONS]: QueryMode.BY_CONVERSIONS,
      [QueryMode.BY_TICKET_QUERIES]: QueryMode.BY_TICKET_QUERIES
    };
    const queryMode = queryModes[this.currentQueryMode] || QueryMode.BY_RESOLUTION_TYPE;

    let limit = DEFAULT_FIRST_FETCH_LIMIT;
    // get first set of DEFAULT_FIRST_FETCH_LIMIT number of queries, to resolve this function at the earliest.
    const queries = await this.loadQueriesIncremental(limit, queryMode);
    // and fetch remaining queries iteratively.

    // prepare the offset and limit for the next iteration.
    this.incrementalFetchOffset = this.incrementalFetchOffset + limit;
    limit = this.getIncrementedLimit(limit);

    this.fetchQueriesIteratively({ limit, queryMode, startAt: this.startAt, endAt: this.endAt });
    return queries;
  }

  getQueriesFromSessions(sessions): IQuery[] {
    const supportedSupportOptions = ['ticket', 'chat', 'phone', 'facebook'];
    return sessions.map((question: any) => {
      let status;
      if (question.dropoff) {
        status = { type: QueryResolutionType.DROP_OFF, text: 'Drop Off' };
      } else if (question.instant_resolution) {
        status = { type: QueryResolutionType.SELF_SERVICED, text: 'Instant Resolution' };
      } else if (question.assisted_support) {
        status = { type: QueryResolutionType.ASSISTED_SUPPORT, text: 'Assisted Support' };
      } else if (question.support_contacted) {
        if (supportedSupportOptions.includes(question.selected_support_option)) {
          status = {
            type: QueryResolutionType.SUPPORT_CONTACTED,
            text: `Support Request (${question.selected_support_option})`
          };
        } else {
          status = { type: QueryResolutionType.SUPPORT_CONTACTED, text: 'Support Request (other)' };
        }
      }

      return {
        id: question.query_id + question.id + (question.submitted_at?.value || question.started_at?.value),
        queryId: question.query_id,
        questionId: question.question_id,
        timestamp: question.submitted_at?.value || question.started_at?.value,
        content: question.question,
        solutionTags: question.solution_tags,
        sessionId: question.id,
        launchUrl: question.launch_url,
        intents: question.intents,
        isSuggestion: question.suggestion,
        solutionIds: question.solution_ids,
        isDropoff: question.dropoff,
        isAssistedSupport: question.assisted_support,
        isInstantResolution: question.instant_resolution,
        isSupportContacted: question.support_contacted,
        supportOptionRaw: question.selected_support_option,
        ...(question.annotation_id
          ? {
              annotation: {
                id: question.annotation_id,
                comment: question.comment,
                labels: question.labels
              }
            }
          : {}),
        ...(question.support_contacted
          ? {
              supportOption: supportedSupportOptions.includes(question.selected_support_option)
                ? question.selected_support_option
                : 'other'
            }
          : {}),
        status,
        isSessionQuery: true
      };
    });
  }

  async getSessionAnnotations() {
    try {
      const { data: annotations } = await this.exploreService.getSessionAnnotations({
        org_id: this.orgStore.selectedOrgId,
        start_date: this.customOrDefaultStartDate.format('YYYY-MM-DD'),
        end_date: this.customOrDefaultEndDate.format('YYYY-MM-DD')
      });
      return { annotations, orgName: this.orgStore.currentOrgName };
    } catch (e) {
      this.displayServerError(e);
      recordErrors(e);
      throw e;
    }
  }

  private async loadTopLaunchUrls(): Promise<string[]> {
    const orgId = this.orgStore.selectedOrgId;

    const params = {
      org_id: orgId,
      start_day: moment(this.startAt).format('YYYY-MM-DD'),
      end_day: moment(this.endAt).format('YYYY-MM-DD'),
      limit: TOP_LAUNCH_URLS_LIMIT
    };
    const results = await this.exploreService.getTopLaunchUrls(params);
    return results.map(result => result.url);
  }

  private async loadConversionQueriesCount(): Promise<number> {
    const orgId = this.orgStore.selectedOrgId;

    let launchUrlRegexp;
    if (this.queryLaunchUrls && this.queryLaunchUrls.length > 0) {
      launchUrlRegexp = this.queryLaunchUrls.map(urlRegex => `(${urlRegex})`).join('|');
    }
    const params = {
      org_id: orgId,
      start_date: this.startAt,
      end_date: this.endAt,
      ...(launchUrlRegexp ? { launch_url_regexp: launchUrlRegexp } : {}),
      ...(this.queryTags ? { solution_search_tags: this.queryTags.map(t => t.trim()) } : {})
    };

    // using cache for the params to avoid hitting the api for the same params.
    if (this.cachedConversionQueriesCount && _.isEqual(params, this.optionsForCachedConversionQueriesCount)) {
      return this.cachedConversionQueriesCount;
    }
    const sessionCount = await this.exploreService.getConversionQuestionsCount(params);

    this.cachedConversionQueriesCount = _.toNumber(sessionCount.count);
    this.optionsForCachedConversionQueriesCount = params;

    return _.toNumber(sessionCount.count);
  }

  private async fetchIntents(): Promise<IIntent[]> {
    try {
      const intentCollection = await this.intentService.getIntentCollections(this.orgStore.selectedOrgId);
      // Remove duplicate intents and sort alphabetically by name
      const allIntents: Record<string, IIntent> = {};
      intentCollection.forEach(({ intents }) => intents.forEach(intent => (allIntents[intent.id] = intent)));
      return Object.values(allIntents).sort((a, b) => a.name.localeCompare(b.name));
    } catch (err) {
      recordErrors(err);
      this.displayServerError(err);
      return Promise.reject();
    }
  }

  private getIncrementedLimit(currentLimit) {
    if (currentLimit === 0) {
      return DEFAULT_FIRST_FETCH_LIMIT;
    }
    // for kind-of an exponential growth, e.g. 0, 20, 40, 160, 2560. Also make sure that the multiplier is always greater than 1.
    const newLimit = currentLimit * (currentLimit / 10 > 1 ? currentLimit / 10 : 2);
    const offsetInCurrentIteration = this.incrementalFetchOffset % this.fetchLimit;
    // this is so that when the limit is greater than what is needed to fill the fetchLimit-number-of-queries for that page,
    // it is brought down to that sufficient limit.
    if (newLimit > this.fetchLimit - offsetInCurrentIteration) {
      return this.fetchLimit - offsetInCurrentIteration;
    }
    return newLimit;
  }

  @action
  private async loadQueriesIncremental(limit, queryMode: QueryMode): Promise<IQuery[]> {
    if (this.currentQueryMode !== queryMode) {
      return [];
    }
    const params: any = {
      org_id: this.orgStore.selectedOrgId,
      limit,
      offset: this.incrementalFetchOffset
    };
    if (queryMode === QueryMode.BY_CONVERSIONS || queryMode === QueryMode.BY_RESOLUTION_TYPE) {
      Object.assign(params, this.getParamsForSessionQuestionsAPI());
    } else {
      Object.assign(params, this.getParamsForMLDataAPI());
    }

    // fetch the sessions and normalize the response to IQuery[]
    let queries: IQuery[] = [];
    if (queryMode === QueryMode.BY_RESOLUTION_TYPE) {
      const sessions = await this.exploreService.getSessionQuestions(params);
      queries = this.getQueriesFromSessions(sessions);
    } else if (queryMode === QueryMode.BY_CONVERSIONS) {
      const sessions = await this.exploreService.getConversionQuestions(params);
      queries = this.getQueriesFromSessions(sessions);
    } else if (queryMode === QueryMode.BY_TICKET_QUERIES) {
      const sessions = await this.exploreService.getMLDataQueries(params); // no filter
      queries = this.getMlDataQueriesFromSessions(sessions);
    }
    runInAction(() => this.currentQueryMode === queryMode && this.queries.push(...queries));
    return queries;
  }

  private getParamsForSessionQuestionsAPI() {
    let launchUrlRegexp;
    if (this.queryLaunchUrls && this.queryLaunchUrls.length > 0) {
      launchUrlRegexp = this.queryLaunchUrls.map(urlRegex => `(${urlRegex})`).join('|');
    }
    const params: any = {
      start_date: this.startAt,
      end_date: this.endAt,
      order_by: 'start_time desc',
      ...(launchUrlRegexp ? { launch_url_regexp: launchUrlRegexp } : {}),
      ...(this.queryTags ? { solution_search_tags: this.queryTags.map(t => t.trim()) } : {}),
      ...(this.isAnnotationFilterEnabled ? { only_annotation_queries: this.isAnnotationFilterEnabled } : {})
    };
    if (this.queryIntentFilters?.length) {
      params.intents = this.queryIntentFilters[0] === NO_INTENT ? '' : this.queryIntentFilters.join(',');
    }
    return params;
  }

  private getParamsForMLDataAPI = () => {
    return {
      updated_at$gt: this.startAt,
      updated_at$lt: this.endAt,
      order_by: 'updated_at desc',
      count: false
    };
  };

  @action
  private async fetchQueriesIteratively({ limit, queryMode, startAt, endAt }: IFetchQueryParam) {
    this.incrementalFetchComplete = false;
    while (this.incrementalFetchOffset < this.fetchLimit + this.fetchOffset) {
      const queries = await this.loadQueriesIncremental(limit, queryMode);
      const isExecutionForLatestParams = () => {
        // this check guarantees that the operation to be performed are not being done for an older params that was changed, after this async event was triggered.
        return this.currentQueryMode === queryMode && this.startAt === startAt && this.endAt === endAt;
      };
      const isResultSetNonEmptyAndSameSizeAsRequested = () => queries && queries.length > 0 && queries.length === limit;
      if (isResultSetNonEmptyAndSameSizeAsRequested() && isExecutionForLatestParams()) {
        // prepare the offset and limit for the next iteration.
        this.incrementalFetchOffset = this.incrementalFetchOffset + limit;
        limit = this.getIncrementedLimit(limit);
      } else {
        this.incrementalFetchOffset = this.incrementalFetchOffset + queries.length;
        break;
      }
    }
    this.incrementalFetchComplete = true;
  }

  private getMlDataQueriesFromSessions = mlDataQueries => {
    return mlDataQueries.map((mlDataQuery: any) => {
      const isUserQuery = mlDataQuery.sources.some(source => source.type === 'user');
      const ticketSource = mlDataQuery.sources.find(source => source.type === 'ticket');
      let isTicketQuery = false;
      let ticketResourceId;
      if (ticketSource) {
        isTicketQuery = true;
        ticketResourceId = ticketSource.source_id;
      }
      return {
        id: mlDataQuery.id,
        timestamp: mlDataQuery.updated_at,
        content: mlDataQuery.content,
        solutionTags: mlDataQuery.solution_tags,
        isSessionQuery: false,
        isUserQuery,
        isTicketQuery,
        ticketResourceId
      } as IQuery;
    });
  };

  private async loadSessionQueriesCount(): Promise<number> {
    const orgId = this.orgStore.selectedOrgId;

    let launchUrlRegexp;
    if (this.queryLaunchUrls && this.queryLaunchUrls.length > 0) {
      launchUrlRegexp = this.queryLaunchUrls.map(urlRegex => `(${urlRegex})`).join('|');
    }
    const params = {
      org_id: orgId,
      start_date: this.startAt,
      end_date: this.endAt,
      ...(launchUrlRegexp ? { launch_url_regexp: launchUrlRegexp } : {}),
      ...(this.queryTags ? { solution_search_tags: this.queryTags.map(t => t.trim()) } : {}),
      ...(this.isAnnotationFilterEnabled ? { only_annotation_queries: this.isAnnotationFilterEnabled } : {}),
      ...(this.queryIntentFilters?.length ? { intents: this.queryIntentFilters.join(',') } : {})
    };

    // using cache for the params to avoid hitting the api for the same params.
    if (this.cachedSessionQueriesCount && _.isEqual(params, this.optionsForCachedSessionQueriesCount)) {
      return this.cachedSessionQueriesCount;
    }
    const [sessionCount] = await this.exploreService.getSessionQuestionsCount(params);

    this.cachedSessionQueriesCount = _.toNumber(sessionCount.count);
    this.optionsForCachedSessionQueriesCount = params;

    return _.toNumber(sessionCount.count);
  }

  private async loadQueriesCount(): Promise<number> {
    let count = 0;
    if (this.currentQueryMode === QueryMode.BY_CONVERSIONS) {
      count = await this.loadConversionQueriesCount();
    } else {
      count = await this.loadSessionQueriesCount();
    }
    return count;
  }

  private async loadAnnotationLabels(): Promise<string[]> {
    const params = {
      org_id: this.orgStore.selectedOrgId
    };
    const labelsData = await this.exploreService.getAnnotationLabels(params);
    return labelsData.map(data => data.labels);
  }
}
