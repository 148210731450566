import { MergeWithCustomizer } from 'lodash';
import { TEXT_OVERRIDE_TYPES } from './constants';
import { IConfigurationPath, ITextGroup } from './types';

export const SUPPORT_OPTION_TYPES = {
  LINK: 'link',
  PHONE: 'phone',
  CHAT: 'chat',
  SMS: 'sms',
  EMAIL: 'email',
  TICKET: 'ticket',
  SINGLE_FEED_CHAT: 'singlefeedchat',
  BOT_AGENT_AVAILABLITY: 'bot:chat'
};

export const inferTypeFromPluginName = pluginName => {
  const types = [
    SUPPORT_OPTION_TYPES.LINK,
    SUPPORT_OPTION_TYPES.PHONE,
    SUPPORT_OPTION_TYPES.CHAT,
    SUPPORT_OPTION_TYPES.SMS,
    SUPPORT_OPTION_TYPES.EMAIL,
    SUPPORT_OPTION_TYPES.TICKET,
    SUPPORT_OPTION_TYPES.SINGLE_FEED_CHAT,
    SUPPORT_OPTION_TYPES.BOT_AGENT_AVAILABLITY
  ];

  let type: string = pluginName;
  types.forEach(t => {
    if (pluginName.toLowerCase().indexOf(t) > -1) {
      type = t;
    }
  });

  // handle special cases
  const chatPluginNames = ['zopim', 'snapengage', 'webviewnativebridge', 'intercom'];
  chatPluginNames.forEach(ct => {
    if (type.toLowerCase().indexOf(ct) > -1) {
      type = SUPPORT_OPTION_TYPES.CHAT;
    }
  });

  const emailPluginNames = ['droponform'];
  emailPluginNames.forEach(ct => {
    if (type.toLowerCase().indexOf(ct) > -1) {
      type = SUPPORT_OPTION_TYPES.EMAIL;
    }
  });

  const linkPluginNames = ['suggestions', 'community', 'droponform', 'samsung'];
  linkPluginNames.forEach(ct => {
    if (type.toLowerCase().indexOf(ct) > -1) {
      type = SUPPORT_OPTION_TYPES.LINK;
    }
  });
  return type;
};

export const inferIconFromType = type => {
  const types = {
    phone: 'call',
    chat: 'chat',
    email: 'email',
    sms: 'chat',
    link: 'email'
  };

  return types[type] || types.email;
};

export const getTextOverridePaths = (textOverrideGroups: ITextGroup[], prefix: string = ''): IConfigurationPath[] => {
  // get all the potential text override keys for the specified groups
  const potentialTextOverridePaths: IConfigurationPath[] = textOverrideGroups.reduce((acc, group) => {
    const paths: IConfigurationPath[] = group.configTexts.map(configText => ({
      path: prefix + (group.type === TEXT_OVERRIDE_TYPES.ROOT ? configText.key : configText.fullKey),
      treatValueAsNull: configText.defaultText as string
    }));
    return acc.concat(paths);
  }, [] as IConfigurationPath[]);

  return potentialTextOverridePaths;
};

/**
 * Returns the current suggestion instead of merging
 *
 * lodash `merge([1,2], [2])` gives `[2,2]`, but on merging the raw/published suggestion with current suggestion we need `[2]`
 */
export const customMergeSuggestions: MergeWithCustomizer = (obj, src, key) => {
  if (key === 'suggestions') {
    // To convert the mobx proxy to plain JSON object.
    return JSON.parse(JSON.stringify(src || null));
  }
};
