import * as colors from './ColorsInTheme';
const theme = colors.ColorsInTheme;

export const Colors = {
  white: theme.white,
  SolvvyDarkPurple: '#350352',
  SolvvyDarkPurple_2: '#5d0e8b',
  SolvvyDarkPurple_3: '#320B4A',
  solvvyMediumPurple: '#bd10e0',
  solvvyMediumPurple_2: '#9155aa',
  solvvyLightPurple: '#bdb2f2',
  solvvyLightPurple_2: '#efe8f4',
  solvvyLightPurple_3: '#D1BBDE',
  solvvyLightPurple_4: '#EFE8F4',
  solvvyLightPurple_5: '#D499E0',
  solvvyLightPurple_6: '#D8E7FE',
  solvvyDarkGray: '#4A4A4A',
  solvvyOffWhite: '#F7F7F7',
  solvvyOffWhite_2: '#F7F7F3',
  solvvyPink: '#fa3777',
  solvvyPink_2: '#BD10E0',
  solvvyRed: '#D0021B',
  errorRed: theme.errorColor,
  error: '#FF4D4F',
  solvvyOrange: '#f57c00',
  sunsetOrange: '#D46B08',
  solvvyMediumBlue: '#008CC9',
  solvvyBlue: '#0087fe',
  primaryBlue: theme.primaryBlue,
  primaryHover: '#E6F7FF',
  primaryDisabled: '#00000040',
  primarySuccess: theme.primarySuccess,
  solvvyLightBlue: '#DAECFF',
  solvvyDarkBlue: '#00447B',
  blue: '#69C0FF',
  solvvyTeal: theme.solvvyTeal,
  solvvyLightTeal: '#1ee6d699',
  SolvvyGreenPrimary: '#60BB09',
  SolvvyLightGreen: '#60BB09',
  greenPrimaryBackground: '#c5ffecad',
  SolvvyOlive: '#B5CC18',
  solvvyBlack: theme.solvvyBlack,
  black_2: '#4a4a4a',
  grey_1: '#f4f4ed',
  grey_2: '#9b9b97',
  grey_3: '#bfbbbb',
  grey_4: '#f7f7f3',
  grey_5: '#C4C2C5',
  grey_6: '#CACAC5',
  grey_7: '#CBCBCB',
  grey_8: '#979797',
  grey_9: '#E7E7E3',
  grey_10: '#858581',
  grey_11: '#D6D6D6',
  grey_12: '#A6A7A6',
  grey_13: '#676765',
  grey_14: '#F3F3F3',
  grey_15: '#6C6C6C',
  grey_16: '#fafafa',
  grey_17: theme.grey_17,
  grey_18: '#E7E7E7',
  grey_19: '#EAEAEA',
  textPrimary: theme.textPrimary,
  textSecondary: theme.textSecondary,
  textSecondaryWhite: 'rgba(255, 255, 255, 0.45)',
  neutral: '#F5F5F5',
  neutral_2: '#F0F0F0',
  neutral_3: '#D9D9D9',
  neutral_5: theme.grey_17,
  pageBackground: '#F0F2F5',
  wfReportingBlue: '#1890FF'
};
