import { initialize, LDClient, LDFlagSet } from 'ldclient-js';
import { UserInfo } from './userService';

export const FEATURE_FLAGS = {
  advanced_bot_workflows: 'advanced_bot_workflows',
  survey_workflows: 'survey_workflows',
  workflow_carousel_step: 'workflow_carousel_step'
} as const;

/**
 * The flags in this service does not dynamically change.
 * If the flags needs to be dynamically updated/changed add the flag in `FeaturesStore`.
 * Initialization of launchdarkly client happens here. Updating the user attributes dynamically happens in `FeaturesStore`.
 */
export default class FeatureFlagService {
  static ldClient: LDClient;
  static flags: LDFlagSet = {};

  constructor(currentUser: UserInfo, currentUserContext) {
    if (!FeatureFlagService.ldClient) {
      const payload = {
        key: currentUser.id,
        name: currentUser.first_name + ' ' + currentUser.last_name,
        email: currentUser.email,
        custom: {
          isGlobalUser: currentUserContext.isGlobalUser,
          orgId: currentUser.org_id || ''
        }
      };
      FeatureFlagService.ldClient = initialize(process.env.REACT_APP_LD_CLIENT_ID || '', payload);
      FeatureFlagService.ldClient.on('ready', this.initializeFlags);
    }
  }
  initializeFlags() {
    const flags = FeatureFlagService.ldClient.allFlags();
    FeatureFlagService.flags = flags;
  }
}
