import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import { Api } from './api';

type IUnboundServiceMethod = (api: Api, ...args: any[]) => Promise<any>;

interface IService {
  [methodName: string]: IUnboundServiceMethod;
}

export const usingApi = (api: Api, func: IUnboundServiceMethod) => {
  return (...args) => {
    return func(api, ...args);
  };
};

export const allUsingApi = <ConcreteService extends IService>(
  api: Api,
  service: ConcreteService
): { [Method in keyof ConcreteService]: (...args: any[]) => ReturnType<ConcreteService[Method]> } => {
  return mapValues(service, func => {
    return usingApi(api, func);
  }) as any;
};

export function excludePaths(config, paths: string[] = []) {
  const newConfig: any = omit(config, paths);

  // Exclude NORAD properties from being stored in the dashboard state
  if (newConfig.instances) {
    newConfig.instances = newConfig.instances.map(instance => omit(instance, ['dialogType']));
  }
  return newConfig;
}
