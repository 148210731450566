import { isNil } from 'lodash';
import { observable } from 'mobx';

export default class PhoneNumber {
  @observable
  number: string;

  @observable
  title?: string;

  constructor({ number: phone, title }: { number: string; title? }) {
    if (!isNil(title)) {
      this.title = title;
    }

    this.number = phone;
  }
}
