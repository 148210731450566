import { JSONSchema7 } from 'json-schema';
import { isPlainObject } from 'lodash';
import { action, autorun, computed, observable, reaction, runInAction } from 'mobx';
import { orgPageRoute, pluginSettingsRoute } from 'src/routes/routes';
import { PluginService } from '../services/pluginService';
import { NotificationManager } from '../shared/util/NotificationManager';
import { OrgStore } from './orgStore';
import { ConversationalUiInstance } from './ResolveUI/ConversationalUiInstance';
import { IConfigurationPath } from './ResolveUI/types';
import { UiConfiguration, UiConfigurationState } from './ResolveUI/UiConfiguration';
import { UiInstance } from './ResolveUI/UiInstance';
import { ResolveUiStore } from './resolveUiStore';
import { RouterStore } from './routerStore';

export interface IPlugin {
  id: string;
  options: any;
  type: string;
  publishedAt?: string;
}

export interface IPluginManifest {
  name: string;
  description: string;
  options: JSONSchema7;
  uiSchema: any;
}

export interface IPluginInstance {
  uiConfig: UiConfiguration;
  instance: UiInstance;
  plugin: IPlugin;
  publishedPlugin: IPlugin;
  pluginManifest: IPluginManifest;
  instanceIndex: number;
  pluginIndex: number;
}

export class PluginStore {
  readonly GITHUB_URL = 'https://github.com/solvvy/ui-solvvy-util/tree/main/packages/plugins/src/custom/';

  // used to disable messages by tests since they are interfering
  disableMessages: boolean = false;

  pendingAutoSave: boolean;

  instancesWithCurrentPlugin: Array<{ id: string; name: string; instanceIndex: number }> = [];

  @observable
  fetchingPluginManifest = false;

  @observable
  pluginsManifest: Record<string, IPluginManifest>;

  @observable
  autoSaving: boolean;

  @observable
  lastAutoSavedAt?: string;

  constructor(
    private _routerStore: RouterStore,
    private _orgStore: OrgStore,
    public notificationManager: NotificationManager,
    public resolveUiConfigurationService: any,
    private _pluginService: PluginService,
    private _resolveUiStore: ResolveUiStore
  ) {
    autorun(() => {
      if (this._resolveUiStore.resolveUiConfigurations?.fulfilled) {
        this.getPluginsManifest(this._resolveUiStore.resolveUiConfigurations.value);
      }
    });
  }

  async getPluginsManifest(resolveUiConfigurations: UiConfiguration[]) {
    runInAction(() => (this.fetchingPluginManifest = true));
    const allPlugins = new Set<string>();

    for (const config of resolveUiConfigurations) {
      for (const instance of config?.instances) {
        for (const plugin of instance?.plugins || []) {
          allPlugins.add(plugin.type);
        }
      }
    }

    const pluginTypes = [...allPlugins.values()];

    const manifests = await this._pluginService?.getPluginsManifest(pluginTypes, this._orgStore.selectedOrgId);

    Object.values(manifests || {}).forEach(({ options }) => {
      if (!options.type && options.properties) {
        // if `type: 'object'` is not included, description will not be rendered properly by `@rjsf/antd`
        options.type = 'object';
      }
      this.setArrayDefaults(options);
    });

    runInAction(() => {
      this.pluginsManifest = manifests;
      this.fetchingPluginManifest = false;
    });
  }

  /* Set default value for new array item as `null`. By default `@rjsf` will set value of new item as `undefined`.
   * undefined value creates TypeError in when showing the diff using jsondiffpatch's html formatter in `UnpublishedChangesModal.ts`
   */
  setArrayDefaults(jsonSchema: any) {
    if (isPlainObject(jsonSchema)) {
      const allKeys = Object.entries(jsonSchema);
      if (allKeys.some(([key, value]) => key === 'type' && value === 'array')) {
        if (jsonSchema.items) {
          if (!('default' in jsonSchema.items)) {
            jsonSchema.items.default = null;
          }
        }
      } else {
        allKeys.forEach(([key, value]) => {
          if (isPlainObject(value)) {
            this.setArrayDefaults(value);
          }
        });
      }
    }
  }

  @action
  async saveUiConfiguration(): Promise<void> {
    const configuration = this.uiConfigurationToEdit;

    // if an autosave or update is already in progress then do nothing but queue an autosave to occur after
    if (this.pendingAutoSave || configuration.submittingPreview) {
      this.pendingAutoSave = true;
      return;
    }

    // perform save without big corner notification
    this.autoSaving = true;
    try {
      await configuration.update(UiConfigurationState.PREVIEW, true);
      runInAction(() => (this.lastAutoSavedAt = new Date().toLocaleString()));
    } finally {
      runInAction(() => (this.autoSaving = false));
    }

    // if some other event triggered an autosave while it was autosaving something previous, then trigger another final autosave
    if (this.pendingAutoSave) {
      this.pendingAutoSave = false;
      // can always skip undo on the pending save because it would have already been done
      await this.saveUiConfiguration();
    }
  }

  @action
  async publish(): Promise<void> {
    await this.waitForPendingSaveToComplete();

    const configuration = this.uiConfigurationToEdit;
    const lastPublishedAt = this.pluginToEdit.publishedAt;
    runInAction(() => (this.pluginToEdit.publishedAt = new Date().toISOString()));

    const configPaths = this.getUiConfigurationPathsForPlugins();
    const isSuccess = await configuration.partiallyPublish(configPaths, { disableNotification: this.disableMessages });
    if (isSuccess) {
      // don't refresh unless successfully saved (to prevent losing changes)
      this._resolveUiStore.resolveUiConfigurations.refresh();
      runInAction(() => (this.lastAutoSavedAt = ''));
    } else {
      runInAction(() => (this.pluginToEdit.publishedAt = lastPublishedAt));
    }
  }

  @action
  async revertUnpublished(): Promise<void> {
    await this.waitForPendingSaveToComplete();

    const configuration = this.uiConfigurationToEdit;
    const configPaths = this.getUiConfigurationPathsForPlugins();
    await configuration.partiallyRevertUnpublishedChanges(configPaths, {
      disableNotification: this.disableMessages
    });
    runInAction(() => (this.lastAutoSavedAt = ''));
  }

  /** publish or revert publish modal will block changes to the editor so new save operations will not occur. */
  waitForPendingSaveToComplete(): Promise<void> {
    if (this.autoSaving || this.pendingAutoSave) {
      return new Promise(resolve => {
        const dispose = reaction(
          () => [this.autoSaving, this.pendingAutoSave],
          () => {
            if (!this.autoSaving && !this.pendingAutoSave) {
              resolve();
              dispose();
            }
          }
        );
      });
    }
    return Promise.resolve();
  }

  getUiConfigurationPathsForPlugins(): IConfigurationPath[] {
    const instanceIndex = this._routerStore.activeResolveUiInstanceIndex;
    const pluginIndex = this.uiInstanceToEdit.plugins.findIndex(({ id }) => id === this.pluginToEdit.id);
    return [{ path: `instances[${instanceIndex}].plugins[${pluginIndex}].options` }];
  }

  navigateToPluginSettings({
    pluginId,
    resolveUiConfigurationName,
    instanceIndex
  }: {
    pluginId: string;
    instanceIndex: number;
    resolveUiConfigurationName: string;
  }) {
    this._routerStore.history.push(
      pluginSettingsRoute.stringify({
        ...this._routerStore.activeRouteParams,
        resolveUiConfigurationName,
        instanceIndex,
        pluginId
      })
    );
  }

  @computed
  get uiInstanceToEdit(): UiInstance | ConversationalUiInstance {
    return this._resolveUiStore.solvvyUiInstanceToEdit!;
  }

  @computed
  get resolveUiConfigurations() {
    return this._resolveUiStore.resolveUiConfigurations;
  }

  @computed
  get uiConfigurationToEdit(): UiConfiguration {
    const uiConfig = this._resolveUiStore.uiConfigurationToEdit;
    if (uiConfig) {
      return uiConfig;
    }
    throw new Error('uiConfig not found');
  }

  @computed
  get pluginToEdit(): IPlugin {
    const plugin = this.uiInstanceToEdit.plugins.find(({ id }) => id === this._routerStore.activePluginId);

    if (plugin) {
      return plugin;
    }

    this._routerStore.history.push(
      orgPageRoute.stringify({
        ...this._routerStore.activeRouteParams,
        orgPage: 'addons'
      })
    );
    this.notificationManager.error({
      title: 'Not found',
      message: 'Could not find the plugin or instance'
    });
    throw new Error('plugin not found');
  }

  @computed
  get pluginInstances() {
    if (!this.resolveUiConfigurations.fulfilled || this.fetchingPluginManifest) {
      return [];
    }

    this.instancesWithCurrentPlugin = [];
    const pluginInstances: any[] = [];

    this.resolveUiConfigurations.value.forEach(config => {
      config.instances.forEach((instance, instanceIndex) => {
        const plugins = instance.plugins?.filter(({ type }) => this.pluginsManifest[type]) || [];

        if (plugins.length > 0) {
          this.instancesWithCurrentPlugin.push({ id: instance.id, name: instance.name || '', instanceIndex });
        }

        plugins.forEach(plugin => {
          pluginInstances.push({
            instanceIndex,
            uiConfig: config,
            instance,
            plugin,
            pluginManifest: this.pluginsManifest[plugin.type]
          });
        });

        return plugins;
      });
    });

    return pluginInstances;
  }

  @computed
  get publishedPluginOptions() {
    return (
      this.uiConfigurationToEdit?.publishedUiConfiguration.instances[
        this._routerStore.activeResolveUiInstanceIndex
      ].plugins.find(({ id }) => id === this.pluginToEdit.id).options || {}
    );
  }
}
