import { allUsingApi } from './util';

export const getArticlePerformance = (api, reportParams) => {
  return api.get(`/v1/data-analytics/articles-driving-support-volume`, ...reportParams);
};

export const getSolutionsForArticle = (api, reportParams) => {
  return api.get(`/v1/data-analytics/solutions-driving-support-volume`, ...reportParams);
};

export const getArticlePerformanceCount = (api, params) => {
  return api.get(`/v1/data-analytics/articles-driving-support-volume-count`, ...params);
};

export const getWorkflowButtonData = (api, params) => {
  return api.get(`/v1/data-analytics/workflow-button`, ...params);
};

export const getWorkflowScreenSourceData = (api, params) => {
  return api.get(`/v1/data-analytics/screen-source`, ...params);
};

export const getWorkflowScreenActionsData = (api, params) => {
  return api.get(`/v1/data-analytics/screen-action`, ...params);
};

const dataAnalyticsService = api => {
  return allUsingApi(api, {
    getSolutionsForArticle,
    getArticlePerformance,
    getArticlePerformanceCount,
    getWorkflowButtonData,
    getWorkflowScreenSourceData,
    getWorkflowScreenActionsData
  });
};

export default dataAnalyticsService;
