import { get, isEmpty, mergeWith } from 'lodash';
import { action, computed, observable } from 'mobx';
import { makeBoolean } from 'src/shared/util/format';
import LauncherConfiguration from 'src/store/models/LauncherConfiguration';
import ModalInstanceColors from '../models/ModalInstanceColors';
import { IPlugin } from '../pluginStore';
import { BaseUiInstance } from './BaseUiInstance';
import { DialogType, UiMajorVersion } from './types';
import { customMergeSuggestions } from './Util';

export class BaseSolvvyUiInstance extends BaseUiInstance {
  static createRaw(instance: BaseSolvvyUiInstance) {
    const myRaw = instance.raw;
    const baseInstanceRaw = BaseUiInstance.createRaw(instance);

    return mergeWith(
      {},
      myRaw,
      baseInstanceRaw,
      {
        uiMajorVersion: instance.uiMajorVersion,
        position: instance.position,
        colors: {
          primary: instance.colors.primary,
          secondary: instance.colors.secondary,
          link: instance.colors.link,
          confirmation: instance.colors.confirmation,
          button: instance.colors.button || undefined
        },
        plugins: instance.plugins
      },
      {
        ...(instance.uiMajorVersion === UiMajorVersion.V5 && {
          dialogType: instance.dialogType,
          widget: {
            backgroundClosed: instance.widget.backgroundClosed,
            backgroundOpen: instance.widget.backgroundOpen,
            iconColor: instance.widget.iconColor,
            borderRadius: instance.widget.borderRadius,
            icon: instance.widget.icon,
            ...(instance.dialogType === DialogType.Conversational && {
              backgroundClosedHover: instance.widget.backgroundClosedHover,
              iconSvg: instance.widget.iconSvg,
              textLabel: instance.widget.textLabel,
              size: instance.widget.size,
              mobileSize: instance.widget.mobileSize
            })
          }
        })
      },
      customMergeSuggestions
    );
  }

  @observable
  colors: ModalInstanceColors;

  @observable
  connectorIdForTicketCreation?: string;

  @observable
  css?: string;

  @observable
  hasCustomCss: boolean = false;

  @observable
  hasSupportOptions: boolean = false;

  @observable
  plugins: IPlugin[] = [];

  @observable
  position: string = 'bottom-right';

  @observable
  uiMajorVersion: UiMajorVersion;

  @observable
  widget: LauncherConfiguration;

  @observable
  dialogType: DialogType;

  constructor(public raw: any, private _orgSettings: any, public configuration) {
    super(raw, configuration);
  }

  @action
  applyFromRaw(raw: any) {
    super.applyFromRaw(raw);

    this.connectorIdForTicketCreation = raw.connectorIdForTicketCreation;
    this.dialogType = raw.dialogType || DialogType.Professional;
    this.css = raw.css;
    this.uiMajorVersion = raw.uiMajorVersion || UiMajorVersion.V4;
    this.position = raw.position;
    this.plugins = raw.plugins;

    this.colors = new ModalInstanceColors();
    if (raw.colors) {
      this.colors.background = raw.colors.background;
      this.colors.primary = raw.colors.primary;
      this.colors.secondary = raw.colors.secondary;
      this.colors.confirmation = raw.colors.confirmation;
      this.colors.link = raw.colors.link;
      this.colors.button = raw.colors.button;
    }

    // widget colors
    this.widget = new LauncherConfiguration(this.colors);
    if (raw.widget) {
      const widget = raw.widget;
      this.widget.backgroundClosed = widget.backgroundClosed;
      this.widget.backgroundClosedHover = widget.backgroundClosedHover;
      this.widget.backgroundOpen = widget.backgroundOpen;
      this.widget.iconColor = widget.iconColor;
      this.widget.borderRadius = widget.borderRadius;
      this.widget.icon = widget.icon;
      this.widget.iconSvg = widget.iconSvg;
      this.widget.textLabel = widget.textLabel;
      this.widget.size = widget.size;
      this.widget.mobileSize = widget.mobileSize;
    }

    if (!isEmpty(raw.css)) {
      this.hasCustomCss = true;
    }
  }

  @computed
  get enableSupportOptionOrgSetting() {
    return makeBoolean(get(this._orgSettings, 'enable_support_option_texts', true));
  }

  @computed
  get currentInstanceIsV4Version() {
    return this.uiMajorVersion === UiMajorVersion.V4;
  }

  @computed
  get currentInstanceIsV5Version() {
    return this.uiMajorVersion === UiMajorVersion.V5;
  }

  @computed
  get isDialogTypeProfessional() {
    return (
      this.dialogType === DialogType.Professional ||
      this.dialogType === DialogType.ConversationalLegacy ||
      this.dialogType !== DialogType.Conversational
    );
  }

  @computed
  get isSolvvyUiInstance() {
    return true;
  }
}
