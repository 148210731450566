import { ExportOutlined, LinkOutlined, RightCircleFilled, WarningOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { DataSourcesStore } from 'src/store/dataSourcesStore';
import { sanitizeHTML } from '../../shared/util/misc';
import {
  IComponent,
  IScreenActionOptions,
  IStep,
  ISupportActionOptions,
  IUrlActionOptions,
  IWorkflowError
} from '../../store/ResolveUI/Workflow';
import { typedInject } from '../../store/rootStore';
import { DEFAULT_HEADER_TEXT, WorkflowStore } from '../../store/workflowStore';
import { StyledCard, StyledCardButton, StyledCardLayout, StyledCardParagraph } from './workflow.styles';

interface IProps {
  step: IStep;
  showEntryIcon: boolean;
  selectedStepId: string;
  className?: any;
  workflowStore: WorkflowStore;
}

@observer
class WorkflowCard extends Component<IProps, {}> {
  renderComponent(
    stepId: string,
    component: IComponent,
    componentIndexes: number[],
    componentErrors: IWorkflowError[]
  ) {
    const { type, text, childComponents, options } = component || {};
    const {
      workflowStore: { _dataSourcesStore }
    } = this.props;
    const hasError = componentErrors.length > 0;

    if (type === 'header') {
      return <h4>{text}</h4>;
    } else if (type === 'paragraph') {
      const textWithFormattedTemplateExpressions = DataSourcesStore.replaceTemplateExpressionsToVariableNames(
        text,
        _dataSourcesStore.dataSources
      );
      return (
        <StyledCardParagraph
          className="step_content_paragraph"
          dangerouslySetInnerHTML={{ __html: sanitizeHTML(textWithFormattedTemplateExpressions, undefined, true) }}
        />
      );
    } else if (type === 'button') {
      return (
        <>
          <StyledCardButton
            className={classNames('action_button', `action_button_${componentIndexes.join('_')}`, { error: hasError })}
            actionAvailable={isNil(options.action)}
          >
            {hasError && <WarningOutlined />}
            {text}
            {!isNil(options.action) &&
              options.action.type === 'screen' &&
              !isNil((options.action.options as IScreenActionOptions).screenId) && (
                <div className="card_step_connector">{(options.action.options as IScreenActionOptions).screenId}</div>
              )}
            {!isNil(options.action) && options.action.type === 'url' && (
              <div className="card_step_connector">
                <LinkOutlined />
              </div>
            )}
            {!isNil(options.action) && options.action.type === 'exit' && (
              <div className="card_step_exit_connector">
                <RightCircleFilled />
              </div>
            )}
          </StyledCardButton>

          {options.action &&
            (options.action.type === 'exit' ||
              options.action.type === 'ticket-form' ||
              options.action.type === 'support' ||
              (options.action.type === 'url' &&
                (options.action.options as IUrlActionOptions | ISupportActionOptions).exit)) && (
              <div className="mini_card card_exit">
                <ExportOutlined />
              </div>
            )}
        </>
      );
    } else if (type === 'repeatingGroup') {
      return (
        <div className="repeating-group-child-components">
          {(childComponents || []).map((childComponent, childIndex) => {
            const subComponentErrors = WorkflowStore.getComponentErrors(componentErrors, childIndex);
            return (
              <div key={childIndex}>
                {this.renderComponent(stepId, childComponent, componentIndexes.concat(childIndex), subComponentErrors)}
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  }

  render() {
    const { workflowStore, step } = this.props;

    const stepTitle = step.header || DEFAULT_HEADER_TEXT;

    return (
      <StyledCardLayout className={`card_layout ${this.props.className}`}>
        <div className="mini_card card_step">{step.id}</div>
        {this.props.showEntryIcon && (
          <div className="mini_card card_entry">
            <ExportOutlined />
          </div>
        )}
        <StyledCard
          hoverable={true}
          title={stepTitle}
          className={classNames('card', { card_active: step.id === this.props.selectedStepId })}
        >
          {step.components.map((component, index) => {
            const stepErrors = workflowStore.getStepErrors(step.id);
            const componentErrors = WorkflowStore.getComponentErrors(stepErrors, index);
            return (
              <div className="step_content" key={index}>
                {this.renderComponent(step.id, component, [index], componentErrors)}
              </div>
            );
          })}
        </StyledCard>
      </StyledCardLayout>
    );
  }
}

export default typedInject('workflowStore')(WorkflowCard);
