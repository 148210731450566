export enum AnalyticsReportId {
  ArticlePerformance = '8',
  Overview = '21',
  ViewedArticles = '22',
  QuerySources = '23',
  Announcements = '24',
  Workflows = '25',
  Suggestions = '26',
  SolutionTags = '27',
  Engage = '28'
}

export const CombinedOrgOverviewReport: IReport = {
  reportId: '',
  label: '',
  modeId: '125',
  visibility: 'ALL'
};

export interface IReport {
  reportId: AnalyticsReportId | '';
  label: string;
  visibility: string;
  modeId: string;
}

export enum ReportVisibility {
  All = 'ALL',
  Global = 'GLOBAL',
  Off = 'OFF'
}
