import bind from 'bind-decorator';
import { action, computed, observable, runInAction } from 'mobx';
import { asyncAction } from 'mobx-utils';
import { NotificationManager } from '../shared/util/NotificationManager';
import { OrgStore } from './orgStore';

interface IPage {
  name: string;
  access_token: string;
}

export class SocialIntegrationStore {
  @observable
  userName = '';

  @observable
  userAccessToken = '';

  @observable
  pages: IPage[];

  @observable
  selectedPage: IPage;

  @observable
  isComplete = false;

  @observable
  isLoading = false;

  @observable
  isConnectorAvailable = false;

  @observable
  connector;

  constructor(
    private orgStore: OrgStore,
    private liveChatService: any,
    private connectorService: any,
    private notificationManager: NotificationManager
  ) {}

  async init() {
    await this.getSunShineConnector();
  }

  async getSunShineConnector() {
    const data = await this.connectorService.fetchSunshineConnectors(this.orgStore.selectedOrgId);
    if (data.length === 0) {
      runInAction(() => {
        this.isConnectorAvailable = false;
        this.connector = {};
      });
      return;
    }
    const integrations = data[0].sunshine?.integrations;
    runInAction(() => {
      this.connector = data[0];
      this.isConnectorAvailable = true;
      if (integrations) {
        this.isComplete = !!integrations[0]?.integration_id;
        this.userName = integrations[0]?.facebook_user_name;
        this.selectedPage = {
          name: integrations[0]?.page_name,
          access_token: ''
        };
      }
    });
  }

  @asyncAction
  @bind
  async sunshineIntegration() {
    try {
      const page = this.selectedPage;

      await this.liveChatService.sunshineIntegration({
        userName: this.userName,
        pageToken: page.access_token,
        pageName: page.name,
        sunshineId: this.connector.id
      });

      this.notificationManager.success({
        title: 'Integration Complete',
        message: 'Integration has been completed.',
        config: {
          duration: 5
        }
      });

      this.updateComplete();
    } catch (e) {
      this.notificationManager.error({
        title: 'Integration failed',
        message: 'Request could not be completed.',
        config: {
          duration: 5
        }
      });
    }
  }

  @action
  updateComplete() {
    this.isComplete = true;
  }

  get facebookInstance() {
    return (window as any).FB;
  }

  @computed
  get isFacebookInitiated() {
    return this.orgStore.isFacebookInitiated;
  }

  getPages = async userAccessToken => {
    const response = await this.liveChatService.facebookPages(this.orgStore.selectedOrgId, userAccessToken);
    return response.data;
  };

  async facebookLogin() {
    if (!this.facebookInstance) {
      return;
    }
    const { token, name } = await login(this.facebookInstance);
    if (token && name) {
      this.secondStep(token, name);
    }
  }

  async secondStep(token, name) {
    const data = await this.getPages(token);
    runInAction(() => {
      this.pages = data;
      this.userAccessToken = token;
      this.userName = name;
    });
  }
}

const login = (fb: any): Promise<{ token: string; name: string }> => {
  return new Promise((resolve, reject) => {
    fb.login(
      response => {
        if (response.authResponse) {
          fb.api('/me', nameResponse => {
            resolve({ token: response.authResponse.accessToken, name: nameResponse.name });
          });
        }
      },
      { scope: 'pages_manage_metadata,pages_messaging,pages_show_list' }
    );
  });
};
