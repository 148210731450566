import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Empty, Modal, Steps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { observer } from 'mobx-react';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Cell, Legend, Pie, PieChart, Sector } from 'recharts';
import ErrorPlaceHolder from 'src/shared/components/ErrorPlaceHolder';
import Loader from 'src/shared/components/Loader';
import { Colors } from 'src/shared/util/Colors';
import { typedInject } from '../../../store/rootStore';
import { colors, COLORSPIE, WorkflowStore } from '../../../store/workflowStore';
import DownloadReports from '../../Analytics/components/DownloadReports';
import { INodeData, IStepPieData } from './IWorkflowReporting';
import { getActionType, mapButtonClickedData, mapWftoWfData, truncateString } from './utils';
import {
  EmptyStateContainer,
  InfiniteScrollContainer,
  PieContainer,
  QueriesContainer,
  StepContainer,
  StepsContainer,
  StyledTable,
  StyledTableContainer,
  VolumeHeader
} from './WorkflowReporting.styles';
interface IColumns {
  screen: ColumnsType;
  workflow_to_workflow: ColumnsType;
  suggestion: ColumnsType;
  url: ColumnsType;
  button: ColumnsType;
  other: ColumnsType;
}
interface IProps {
  workflowStore: WorkflowStore;
  stepData: INodeData | null;
  onStepChange: (stepNumber) => void;
}

const renderVolume = (text: string, count: number) => {
  return (
    <div>
      {text}%{' '}
      <span
        style={{
          color: 'gray'
        }}
      >
        {count ? `- ${count}` : null}
      </span>
    </div>
  );
};

const renderActiveStep = props => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
  return (
    <Sector
      cx={cx}
      cy={cy}
      innerRadius={innerRadius}
      outerRadius={outerRadius + 10}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
  );
};

const renderCustomizedLabel = (props, isSinglePie: boolean, activeIndex: number) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, outerRadius, fill, percent, innerRadius, index } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);

  const sx = cx + outerRadius * cos;
  const sy = cy + outerRadius * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  const tx = cx + (outerRadius + 60) * cos;
  const ty = cy + (outerRadius + 60) * sin;
  const ey = ty - 12;

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <>
      {percent * 100 < 5 ? (
        <g>
          <path d={`M${sx},${sy}L${mx},${my}L`} stroke={fill} fill="none" />
          <text x={tx + (cos >= 0 ? -2 : 2) * 12} y={ey - 5} dy={6} textAnchor={textAnchor} fill="#999">
            {`${(percent * 100).toFixed(1)}%`}
          </text>
        </g>
      ) : (
        <text
          x={isSinglePie ? cx : x}
          y={y}
          fill="white"
          textAnchor={'middle'}
          dominantBaseline="central"
          filter={index === activeIndex ? '' : 'brightness(0.75)'}
        >
          {`${(percent * 100).toFixed(1)}%`}
        </text>
      )}
    </>
  );
};

const StepAnalytics = observer(({ workflowStore, stepData, onStepChange }: IProps) => {
  const {
    toggleStepAnalyticsModal,
    screenSourcesBreakdown,
    screenActionsBreakdown,
    screenActionsData,
    queryActionsData,
    singleCategoryData,
    isSingleCategory,
    screenSourcesData,
    screenSourcesCount,
    screenActionsCount,
    emptyStep,
    emptySourcesState,
    emptyActionsState,
    querySourcesData,
    resetStep,
    sourcesQueries,
    actionsQueries,
    dataSankey,
    workflowIdToEdit
  } = workflowStore;
  const { querySourcesBreakdown, hasMoreSourcesQueries, fetchMoreSourcesQueries } = sourcesQueries;
  const { queryActionsBreakdown, hasMoreActionsQueries, fetchMoreActionsQueries } = actionsQueries;
  const [current, setCurrent] = React.useState<number>(0);
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const [sourcesRef, setSourcesRef] = React.useState<HTMLDivElement | null>(null);

  const columns: IColumns = {
    screen: [
      {
        title: 'Step Arrived From',
        dataIndex: 'step_name',
        key: 'step_name',
        width: '70%',
        render: (text, { step_number }) => renderOtherStep(text, step_number)
      },
      {
        title: 'Volume',
        dataIndex: 'percentage',
        key: 'percentage',
        align: 'left',
        render: (text, { count }) => renderVolume(text, count)
      }
    ],
    workflow_to_workflow: [
      {
        title: 'Workflow Name',
        dataIndex: 'workflow_name',
        key: 'workflow_name',
        width: '70%',
        render: text => <div>{text ? text : 'Unknown'}</div>
      },
      {
        title: 'Volume',
        dataIndex: 'workflow_percentage',
        key: 'workflow_percentage',
        align: 'left',
        render: (text, { count }) => renderVolume(text, count)
      }
    ],
    suggestion: [
      {
        title: 'Suggestion',
        dataIndex: 'suggestion_text',
        key: 'suggestion_text',
        width: '70%',
        render: text => <div>{text ? text : 'Unknown'}</div>
      },
      {
        title: 'Volume',
        dataIndex: 'percentage',
        key: 'percentage',
        align: 'right',
        render: (text, { count }) => renderVolume(text, count)
      }
    ],
    url: [
      {
        title: 'Arrived From',
        dataIndex: 'unknownUrl',
        key: 'unknownUrl',
        width: '70%',
        render: text => <div>{text}</div>
      },
      {
        title: 'Volume',
        dataIndex: 'volume',
        key: 'volume',
        align: 'left',
        render: (text, { count }) => renderVolume(text, count)
      }
    ],
    button: [
      {
        title: 'Button Title',
        dataIndex: 'button_title',
        key: 'button_title',
        width: '70%',
        render: (text, data) => renderButtonStep(text, data)
      },
      {
        title: 'Volume',
        dataIndex: 'button_percentage',
        key: 'button_percentage',
        align: 'left',
        render: (text, { button_count }) => renderVolume(text, button_count)
      }
    ],
    other: [
      {
        title: 'Other Categories',
        dataIndex: 'sessionEnded',
        key: 'sessionEnded',
        width: '70%',
        render: text => <div>{text}</div>
      },
      {
        title: 'Volume',
        dataIndex: 'volume',
        key: 'volume',
        align: 'left',
        render: (text, { count }) => renderVolume(text, count)
      }
    ]
  };

  const onSourcesRef = React.useCallback(node => {
    if (node !== null) {
      setSourcesRef(node);
    }
  }, []);

  const onPieEnter = React.useCallback(
    (slice, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const onLegendClick = (slice, index) => {
    setActiveIndex(index);
  };

  const onCancel = () => {
    toggleStepAnalyticsModal();
    setCurrent(0);
    resetStep();
  };

  const onTabChange = (value: number) => {
    setCurrent(value);
    setActiveIndex(0);
    // reset ref
    setSourcesRef(null);
  };

  const renderOtherStep = (text, stepNumberSkip) => {
    return (
      <div>
        {text ? text : null}
        <span
          style={{
            color: Colors.wfReportingBlue,
            cursor: 'pointer'
          }}
          onClick={() => {
            toggleStepAnalyticsModal();
            setCurrent(current ? 0 : 1);
            onStepChange(stepNumberSkip);
          }}
        >
          {text && stepNumberSkip ? ' -' : ''}
          {stepNumberSkip ? ` Open Step` : null}
        </span>
      </div>
    );
  };

  const renderButtonStep = (text, data) => {
    const getString = () => {
      if (data.isMain) {
        return text;
      } else if (data.target_workflow_id && data.target_workflow_id === workflowIdToEdit) {
        const curr = dataSankey.nodes.find(currStep => currStep.stepNumber === data.target_step_number.toString());
        return curr ? curr.id : `Step ${data.target_step_number}`;
      } else if (data.target_workflow_id && data.target_workflow_id !== workflowIdToEdit) {
        return truncateString(`${data.button_title} - (Links to workflow *)`, 45);
      } else if (!text) {
        return `${getActionType(data.button_action_type)} *`;
      } else {
        return text;
      }
    };

    return <div>{getString()}</div>;
  };

  const renderTable = () => {
    return (
      <StyledTableContainer>
        <StyledTable
          columns={checkTableColumnData(current ? screenActionsData : screenSourcesData)}
          dataSource={checkTableRowData(current ? screenActionsData : screenSourcesData)}
          rowKey={(record, i) => i}
          pagination={false}
          bordered={false}
          scroll={{ y: 250 }}
          size="middle"
        />
      </StyledTableContainer>
    );
  };

  const renderErrorMessage = () => {
    return (
      <EmptyStateContainer>
        <InfoCircleOutlined style={{ fontSize: '60px', color: Colors.wfReportingBlue }} />
        <br />
        <h3>Activity data not found</h3>
        <p>This step was unused, or not enabled, during the date range.</p>
        <Button
          type="primary"
          style={{
            background: Colors.wfReportingBlue
          }}
          onClick={onCancel}
        >
          Close
        </Button>
      </EmptyStateContainer>
    );
  };

  const renderSteps = () => {
    return (
      <StepsContainer>
        <Steps
          type="navigation"
          current={current}
          onChange={onTabChange}
          size="small"
          style={{ borderBottom: '1px solid #d6d6d6' }}
        >
          <Steps.Step status={!current ? 'process' : 'wait'} title="How Users Got Here" />
          <Steps.Step status={current ? 'process' : 'wait'} title="What Users Did On This Step" />
        </Steps>
      </StepsContainer>
    );
  };

  const renderChart = (data: IStepPieData[], colorScheme: object, isSingleCategoryPie: boolean = false) => {
    const isSinglePie = data.length === 1;
    return (
      <PieContainer>
        <PieChart width={500} height={300} key={data.length}>
          <Pie
            activeIndex={activeIndex}
            activeShape={isSingleCategoryPie ? null : renderActiveStep}
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={e => renderCustomizedLabel(e, isSinglePie, activeIndex)}
            outerRadius={110}
            fill="#8884d8"
            isAnimationActive={false}
            dataKey="value"
            onClick={isSingleCategoryPie ? null : onPieEnter}
          >
            {data.map((entry, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={isSingleCategoryPie ? COLORSPIE[index % COLORSPIE.length] : colorScheme[entry.key]}
                  filter="brightness(0.75)"
                />
              );
            })}
          </Pie>
          <Legend
            height={25}
            wrapperStyle={{
              overflow: 'scroll',
              bottom: '-25px',
              cursor: 'pointer'
            }}
            iconType="circle"
            onClick={isSingleCategoryPie ? null : onLegendClick}
          />
        </PieChart>
      </PieContainer>
    );
  };

  const checkTableColumnData = data => {
    if (columns[data[activeIndex].key]) {
      columns[data[activeIndex].key][1].title = (
        <div style={{ margin: 0 }}>
          Volume{' '}
          <span
            style={{
              fontWeight: 'normal',
              color: 'gray'
            }}
          >
            - {data[activeIndex].count ? data[activeIndex].count : '0'}
          </span>
        </div>
      );
      return columns[data[activeIndex].key];
    }
  };

  const checkTableRowData = data => {
    if (data[activeIndex]?.key === 'button') {
      return mapButtonClickedData(data[activeIndex]);
    }
    if (data[activeIndex]?.key === 'workflow_to_workflow') {
      return mapWftoWfData(data[activeIndex]);
    }
    if (data[activeIndex]?.key === 'other') {
      return [
        {
          sessionEnded: 'Workflow Exited',
          count: data[activeIndex].value,
          volume: '100'
        }
      ];
    }
    if (data[activeIndex]?.key === 'url') {
      return [
        {
          unknownUrl: 'URL',
          count: data[activeIndex].value,
          volume: '100'
        }
      ];
    } else {
      return data[activeIndex]?.details;
    }
  };

  const renderQueries = (queriesData, fetchMoreFunc, hasQueries, ref, refFunc) => {
    const [showAllChars, setShowAllChars] = React.useState<number[]>([]);
    const [showAllIntents, setShowAllIntents] = React.useState<number[]>([]);

    const getStr = (str, index, isIntent = false) => {
      if (!str) {
        return 'Unknown';
      }
      const length = isIntent ? 20 : 45;
      if (str.length > length) {
        return (
          <>
            {`${str.substring(0, length)}...`}{' '}
            <span
              style={{
                color: Colors.wfReportingBlue
              }}
              onClick={() => {
                if (isIntent) {
                  setShowAllIntents(prevState => {
                    return [...prevState, index];
                  });
                } else {
                  setShowAllChars(prevState => {
                    return [...prevState, index];
                  });
                }
              }}
            >
              See More
            </span>
          </>
        );
      } else {
        return str;
      }
    };

    if (!queriesData.length) {
      return <Empty style={{ marginTop: '30px' }} />;
    }

    return (
      <InfiniteScrollContainer ref={refFunc}>
        {ref && (
          <InfiniteScroll
            dataLength={queriesData.length}
            next={fetchMoreFunc}
            hasMore={hasQueries}
            loader={<Loader size="small" onlySpinner={true} />}
            scrollableTarget={ref}
          >
            {queriesData.map((item: any, index) => {
              const str = item.intent_label ? item.intent_label : item.intent;
              return (
                <div
                  style={{ padding: '12px 8px', display: 'flex', borderBottom: '1px solid #f0f0f0' }}
                  key={item.message}
                >
                  <div style={{ width: '70%', paddingRight: '10px' }}>
                    {showAllChars.includes(index) ? item.message : getStr(item.message, index)}
                  </div>
                  <div style={{ width: '30%' }}> {showAllIntents.includes(index) ? str : getStr(str, index, true)}</div>
                </div>
              );
            })}
          </InfiniteScroll>
        )}
      </InfiniteScrollContainer>
    );
  };

  const renderQueriesTable = (isSources: boolean = true) => {
    const queries = isSources ? querySourcesData : queryActionsData;
    const callbackFunc = data => {
      return isSources
        ? renderQueries(data, fetchMoreSourcesQueries, hasMoreSourcesQueries, sourcesRef, onSourcesRef)
        : renderQueries(data, fetchMoreActionsQueries, hasMoreActionsQueries, sourcesRef, onSourcesRef);
    };
    return (
      <>
        <StyledTableContainer>
          <StyledTable
            columns={[
              {
                title: 'Queries From User',
                dataIndex: 'message',
                width: '80%'
              },
              {
                title: () => (
                  <QueriesContainer>
                    Intent
                    {queries.length ? (
                      <DownloadReports
                        isStepAnalyticsQueriesDownload={true}
                        data={isSources ? { isQuerySources: true } : { isQueryActions: true }}
                      />
                    ) : null}
                  </QueriesContainer>
                ),
                width: '30%',
                dataIndex: 'intent_label',
                align: 'left'
              }
            ]}
            dataSource={queries}
            components={{
              body: data => callbackFunc(data)
            }}
            rowKey={(record, i) => i}
            pagination={false}
            bordered={false}
            scroll={{ y: 250 }}
            size="middle"
          />
        </StyledTableContainer>
      </>
    );
  };

  const renderModal = () => {
    if (emptySourcesState && !current) {
      return renderErrorMessage();
    }
    if (emptyActionsState && current) {
      return renderErrorMessage();
    } else {
      return (
        <StepContainer>
          {!current ? (
            <>
              {screenSourcesBreakdown.rejected ? (
                <ErrorPlaceHolder errorMsg="Oops something went wrong!!!. Try again later." />
              ) : null}
              {screenSourcesBreakdown.pending ? <Loader thingToWaitFor="Step Analytics" /> : null}
              {screenSourcesBreakdown.fulfilled && screenSourcesData.length ? (
                <>
                  {screenSourcesData.length ? (
                    <VolumeHeader>Step Volume: {screenSourcesCount.toLocaleString()}</VolumeHeader>
                  ) : null}
                  {isSingleCategory
                    ? renderChart(singleCategoryData, COLORSPIE, true)
                    : renderChart(screenSourcesData, colors)}
                  {current ? null : screenSourcesData[activeIndex]?.key === 'query' ? (
                    querySourcesBreakdown.pending ? (
                      <Loader thingToWaitFor="Queries" />
                    ) : (
                      renderQueriesTable()
                    )
                  ) : (
                    renderTable()
                  )}
                </>
              ) : null}
            </>
          ) : null}
          {current ? (
            <>
              {screenActionsBreakdown.rejected ? (
                <ErrorPlaceHolder errorMsg="Oops something went wrong!!!. Try again later." />
              ) : null}
              {screenActionsBreakdown.pending ? <Loader thingToWaitFor="Step Analytics" /> : null}
              {screenActionsBreakdown.fulfilled && screenActionsData.length ? (
                <>
                  {screenActionsData.length ? (
                    <VolumeHeader>Step Volume: {screenActionsCount.toLocaleString()}</VolumeHeader>
                  ) : null}
                  {renderChart(screenActionsData, colors)}
                  {screenActionsData[activeIndex]?.key === 'chat' ? (
                    queryActionsBreakdown.pending ? (
                      <Loader thingToWaitFor="Queries" />
                    ) : (
                      renderQueriesTable(false)
                    )
                  ) : (
                    renderTable()
                  )}
                </>
              ) : null}
            </>
          ) : null}
        </StepContainer>
      );
    }
  };

  return (
    <>
      <Modal
        title={`Analytics for '${stepData?.id ? stepData?.id : stepData?.name}'`}
        centered={true}
        visible={workflowStore.isStepAnalyticsModal}
        onCancel={onCancel}
        footer={false}
        width={700}
        bodyStyle={{
          minHeight: 700
        }}
      >
        {emptyStep ? (
          renderErrorMessage()
        ) : (
          <>
            {renderSteps()}
            {renderModal()}
          </>
        )}
      </Modal>
    </>
  );
});

export default typedInject('workflowStore')(StepAnalytics);
