import get from 'lodash/get';
import { action, computed, observable, runInAction } from 'mobx';
import moment, { Moment } from 'moment-timezone';

import { AnalyticsReportId, IReport, ReportVisibility } from 'src/routes/Analytics/AnalyticsReportIdTypes';
import { analyticsRoute } from 'src/routes/routes';
import { dataAnalyticsService, eventsService, reportsService } from 'src/services';
import asyncComputed from 'src/shared/util/asyncComputed';
import { parseAndDownloadCsv } from 'src/shared/util/csvUtil';
import { RangeType } from 'src/shared/util/DateRangeTypes';
import { NotificationManager } from 'src/shared/util/NotificationManager';
import { recordErrors } from 'src/shared/util/recordErrors';
import { ticketDownloadLinkCreator } from 'src/shared/util/ticketDownload';
import { AuthStore } from '../authStore';
import ArticlePerformance from '../models/ArticlePerformance';
import Question from '../models/Question';
import SolutionBySupportVolume from '../models/SolutionBySupportVolume';
import { OrgStore } from '../orgStore';
import { RouterStore } from '../routerStore';
import { WorkflowStore } from '../workflowStore';
import { DATE_ONLY_FORMAT, DAYS_AGO_TO_RANGE } from './constants';
import { listenToAnalyticsFrameEvents } from './events';
import { IArticlePerformancePagination } from './types';
import { parseStartAndEndDateFromLookerRange, sortReports } from './utils';

interface IAnalyticsFilters {
  /* `In looker format */
  'Date Range': string;
  'Aggregate by'?: string;
  'Workflow Name'?: string;
}

const REPORT_FILTERS = 'reportFilters';

export class AnalyticsStore {
  isDateFilterUpdatedByUser: boolean;

  analyticsFilters: IAnalyticsFilters;
  // This is used when changes in article performance date filter needs to be notified. Actual value is not observed as the update notification is not required everywhere.
  @observable
  articlePerformanceUpdateObserver = new Date();

  @observable
  selectedDeviceType = 'all devices';
  @observable
  reports: IReport[];
  @observable
  selectedReportInfo: IReport;

  totalArticlesSupportVolumeCount = asyncComputed(() => this.getArticlePerformanceCount());

  get currentRangeType() {
    const [startDate, endDate] = this.selectedDateRange;
    const dateDiff = Math.ceil(endDate.diff(startDate, 'days', true)) + 1;

    if (endDate.diff(moment(), 'days') === 0) {
      if (startDate.isSame(moment().startOf('year'))) {
        return RangeType.CURRENT_YEAR;
      } else if (DAYS_AGO_TO_RANGE[dateDiff]) {
        return DAYS_AGO_TO_RANGE[dateDiff];
      } else {
        return RangeType.CUSTOM;
      }
    }
    return RangeType.CUSTOM;
  }

  get selectedDateRange(): Moment[] {
    const [start, end] = parseStartAndEndDateFromLookerRange(this.analyticsFilters['Date Range']);
    const effectiveStartDate = moment.tz(start, this.timezone);
    const effectiveEndDate = moment.tz(end, this.timezone);
    return [effectiveStartDate, effectiveEndDate];
  }

  @computed
  get timezone() {
    return this._orgStore.orgSettings.timezone;
  }

  @computed
  get canShowReportAvailabilityInfo() {
    return (
      this.selectedReportInfo?.reportId === AnalyticsReportId.Announcements ||
      this.selectedReportInfo?.reportId === AnalyticsReportId.Suggestions
    );
  }

  @computed
  get isArticlePerformanceReportSelected() {
    return this.selectedReportInfo?.reportId === AnalyticsReportId.ArticlePerformance;
  }

  @computed
  get isLookerReport() {
    return Number(this.selectedReportInfo?.reportId) > 20;
  }

  getAnalyticsReportUrl = asyncComputed(async () => {
    const { first_name, last_name } = this._orgStore.currentUserContext;
    if (!this.selectedReportInfo || !this.analyticsFilters) {
      return new Promise(() => undefined);
    }

    const lookerData = await eventsService.getLookerReportUrl({
      org_id: this._orgStore.selectedOrgId,
      report_id: this.selectedReportInfo.modeId,
      user_id: this._orgStore.currentUserContext.id,
      filterParams: JSON.stringify(this.analyticsFilters),
      first_name,
      last_name
    });
    return lookerData.data;
  });

  constructor(
    private _orgStore: OrgStore,
    private _authStore: AuthStore,
    private _workflowStore: WorkflowStore,
    private _routerStore: RouterStore,
    private _notificationManager: NotificationManager
  ) {
    // looker sends events to the iframe which is used to
    // reload the embed whenever a filter changes
    listenToAnalyticsFrameEvents(this._workflowStore, this);
  }

  getSolutionsForArticle = async (articleUrl: string) => {
    const { data } = await dataAnalyticsService.getSolutionsForArticle({
      ...this.commonReportFilters,
      ...{ url: articleUrl, limit: 0, offset: 0 }
    });

    const supportVolumeRequests: [SolutionBySupportVolume] = data.map(supportVolumeData => {
      const supportVolume = new SolutionBySupportVolume(supportVolumeData);
      return supportVolume;
    });
    return supportVolumeRequests;
  };

  getArticlePerformance = async (params: IArticlePerformancePagination) => {
    const { data } = await dataAnalyticsService.getArticlePerformance({
      ...this.commonReportFilters,
      ...params
    });

    const articlesBySupportVolume: [ArticlePerformance] = data.map(articlesData => {
      const articleBySupportVolume = new ArticlePerformance(articlesData);
      return articleBySupportVolume;
    });
    return articlesBySupportVolume;
  };

  getQuestionsForSolutions = async (solutionId, params?: IArticlePerformancePagination): Promise<[Question]> => {
    const { org_id, start_day: start_date, end_day: end_date } = this.commonReportFilters;
    const { data } = await eventsService.getQuestionsForSolution({
      org_id,
      start_date,
      end_date,
      ...params,
      ...(solutionId ? { solution_id: solutionId } : {})
    });

    const questionsForSolutions: [Question] = data.map(questionData => {
      const question = new Question(questionData);
      return question;
    });
    return questionsForSolutions;
  };

  async getInlineWorkflowBuilderReport(params) {
    const { data } = await eventsService.getLookerReportUrl(params);
    return data;
  }

  @action
  setActiveReportTab(reportInfo: IReport, firstLoad: boolean = false) {
    const defaultDateRange = [AnalyticsReportId.ViewedArticles, AnalyticsReportId.ArticlePerformance].includes(
      reportInfo.reportId as AnalyticsReportId
    )
      ? '14 day'
      : '7 day';

    // Default filter values per report
    const reportFilters: IAnalyticsFilters = {
      'Date Range': defaultDateRange,
      ...(reportInfo.reportId === AnalyticsReportId.ViewedArticles && { 'Aggregate by': 'Week' }),
      ...(reportInfo.reportId === AnalyticsReportId.Workflows && { 'Workflow Name': '' })
    };

    try {
      Object.assign(reportFilters, JSON.parse(this._routerStore.activeReportFilters as string));
    } catch (e) {}

    if (!(this.isDateFilterUpdatedByUser || firstLoad)) {
      reportFilters['Date Range'] = defaultDateRange;
    }

    const pathname = analyticsRoute.stringify({
      ...this._routerStore.activeRouteParams,
      reportId: reportInfo.reportId
    });
    const url = new URL(window.location.origin);
    url.searchParams.set(REPORT_FILTERS, JSON.stringify(reportFilters));
    this._routerStore.history.push(pathname + url.search);

    this.analyticsFilters = reportFilters;
    this.selectedReportInfo = reportInfo;

    if (this.isLookerReport) {
      this.getAnalyticsReportUrl.refresh();
    }
  }

  @action
  updateFilters(filters: IAnalyticsFilters, updateArticlePerformance = false) {
    this.isDateFilterUpdatedByUser = true;
    const url = new URL(window.location.origin);
    let reportFilters = {};

    try {
      reportFilters = JSON.parse(this._routerStore.activeReportFilters as string);
    } catch (e) {}

    this.analyticsFilters = { ...reportFilters, ...filters };
    url.searchParams.set(REPORT_FILTERS, JSON.stringify(this.analyticsFilters));
    this._routerStore.history.push(window.location.pathname + url.search);

    if (updateArticlePerformance) {
      // MobX triggers update when new value is set on observer. So setting current time which will be uniq.
      this.articlePerformanceUpdateObserver = new Date();
    }
  }

  async downloadArticlePerformance() {
    const DOWNLOAD_NOTIFICATION_KEY = 'DownloadNotificationKey';
    try {
      this._notificationManager.info({
        message: `Fetching Article Performance report...`,
        config: { key: DOWNLOAD_NOTIFICATION_KEY, duration: 0 }
      });

      const { org_id, start_day: start_date, end_day: end_date } = this.commonReportFilters;
      const { data } = await eventsService.getArticlePerformanceDownload({
        org_id,
        start_date,
        end_date
      });
      this._notificationManager.close(DOWNLOAD_NOTIFICATION_KEY);

      if (!data?.length) {
        return this._notificationManager.warn({ message: 'No items found for the request', config: { duration: 10 } });
      }

      this._notificationManager.info({ message: 'Generating CSV for download...' });

      const [start, end] = this.selectedDateRange;
      const fileName = `ArticlePerformance_${this._orgStore.currentOrgName}_(${start.format('YYYY.MM.DD')}-${end.format(
        'YYYY.MM.DD'
      )})`;
      parseAndDownloadCsv(data, fileName, {
        fields: [
          { value: 'question', label: 'Query' },
          { value: 'support_contacted', label: 'Support Requests' },
          { value: 'dropoffs', label: 'Drop Offs' },
          { value: 'instant_resolutions', label: 'Instant Resolutions' },
          { value: 'total', label: 'Total Views' },
          { value: 'ssr_percent', label: '%SSR' },
          { value: 'solution_id', label: 'Solution ID' },
          { value: 'solution_title', label: 'Solution Title' },
          { value: 'solution_content', label: 'Solution Content' },
          { value: 'article_title', label: 'Article Title' },
          { value: 'url', label: 'URL' }
        ]
      });
    } catch (e) {
      this._notificationManager.close(DOWNLOAD_NOTIFICATION_KEY);
      this._notificationManager.error({
        title: 'Oops something went wrong',
        message: `Download request failed. Please try after some time.`
      });
      recordErrors(e);
    }
  }

  @computed
  get isGlobalUser() {
    return this._orgStore.currentUserContext.isGlobalUser;
  }

  @computed
  get isEngageEnabledOrg() {
    return this._orgStore.orgSettings.engage;
  }

  get canDownloadUserQueries(): boolean {
    const downloadDateLimit = get(this._orgStore.orgSettings, 'default_dashboard_queries_csv_export_limit', 14);
    const [start, end] = this.selectedDateRange;
    const days = end.diff(start, 'days');
    return days <= parseInt(downloadDateLimit, 10);
  }

  get userQueryDownloadLink() {
    if (this.canDownloadUserQueries) {
      const [start, end] = this.selectedDateRange;
      return ticketDownloadLinkCreator(this._authStore, this._orgStore)(start, end);
    }
    return '#';
  }

  get userQueryDownloadText(): string {
    if (this.canDownloadUserQueries) {
      return 'Download as CSVs';
    } else {
      return 'Currently not available';
    }
  }

  get commonReportFilters() {
    const [start, end] = this.selectedDateRange;
    return {
      org_id: this._orgStore.selectedOrFirstOrgId,
      start_day: start.format(DATE_ONLY_FORMAT),
      end_day: end.format(DATE_ONLY_FORMAT)
    };
  }

  async initAnalyticsPage() {
    // admin users will not be shown GLOBAL reports
    const reportsFilter = (report: IReport) => {
      return this.isGlobalUser
        ? report.visibility !== ReportVisibility.Off
        : report.visibility === ReportVisibility.All;
    };

    try {
      const unorderedReports = await reportsService.getReportsForOrg(this._orgStore.selectedOrgId, false);
      const reportOrder = get(this._orgStore.orgSettings, 'default_mode_reports').split(',');

      runInAction(() => {
        this.reports =
          reportOrder.length > 0 ? sortReports(unorderedReports, reportOrder).filter(reportsFilter) : unorderedReports;
      });

      const activeReportId = this._routerStore.activeReportId || this.reports[0]?.reportId;
      const activeReportInfo = this.reports.find(report => report.reportId === activeReportId) || this.reports[0];

      this.setActiveReportTab(activeReportInfo, true);
    } catch (e) {
      recordErrors(e);
    }
  }

  getArticlePerformanceCount = async (search_text?: string): Promise<number> => {
    const {
      data: { count }
    }: { data: { count: number } } = await dataAnalyticsService.getArticlePerformanceCount({
      ...this.commonReportFilters,
      ...(search_text && { search_text })
    });

    return count;
  };
}
