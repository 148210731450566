import * as d3 from 'd3';

export function appendArrows(selection, arrowLength, gapLength, arrowHeadSize) {
  const totalDashArrayLength = arrowLength + gapLength;

  const arrows = selection
    .append('path')
    .attr('d', d => {
      return d.path;
    })
    .style('stroke-width', 1)
    .style('stroke', 'black')
    .style('stroke-dasharray', arrowLength + ',' + gapLength);
  // tslint:disable-next-line: only-arrow-functions
  arrows.each(function(this) {
    // TODO: find other way to target current node without using "this"
    const thisPath = d3.select(this).node();
    // TODO: find other way to target current node without using "this"
    const parentG = d3.select(this.parentNode);
    const pathLength = thisPath.getTotalLength();
    let numberOfArrows = Math.ceil(pathLength / totalDashArrayLength);

    // remove the last arrow head if it will overlap the target node
    if ((numberOfArrows - 1) * totalDashArrayLength + (arrowLength + (arrowHeadSize + 1)) > pathLength) {
      numberOfArrows = numberOfArrows - 1;
    }

    const arrowHeadData = d3.range(numberOfArrows).map((d, i) => {
      const length = i * totalDashArrayLength + arrowLength;

      const point = thisPath.getPointAtLength(length);
      const previousPoint = thisPath.getPointAtLength(length - 2);

      let rotation = 0;

      if (point.y === previousPoint.y) {
        rotation = point.x < previousPoint.x ? 180 : 0;
      } else if (point.x === previousPoint.x) {
        rotation = point.y < previousPoint.y ? -90 : 90;
      } else {
        const adj = Math.abs(point.x - previousPoint.x);
        const opp = Math.abs(point.y - previousPoint.y);
        let angle = Math.atan(opp / adj) * (180 / Math.PI);
        if (point.x < previousPoint.x) {
          angle = angle + (90 - angle) * 2;
        }
        if (point.y < previousPoint.y) {
          rotation = -angle;
        } else {
          rotation = angle;
        }
      }

      return { x: point.x, y: point.y, rotation };
    });

    parentG
      .selectAll('.arrow-heads')
      .data(arrowHeadData)
      .enter()
      .append('path')
      .attr('d', d => {
        return (
          'M' +
          d.x +
          ',' +
          (d.y - arrowHeadSize / 2) +
          ' ' +
          'L' +
          (d.x + arrowHeadSize) +
          ',' +
          d.y +
          ' ' +
          'L' +
          d.x +
          ',' +
          (d.y + arrowHeadSize / 2)
        );
      })
      .attr('class', 'arrow-head')
      .attr('transform', d => {
        return 'rotate(' + d.rotation + ',' + d.x + ',' + d.y + ')';
      })
      .style('fill', 'black');
  });
}

export const calculateDimensions = (depth, initialSankeyData, height, width) => {
  const nodeWidth = 80;
  const nodePadding = 50;
  let nodePaddingRatio = 0.5;
  let screenWidth = width;
  let screenHeight = height;
  if (depth[1] > 5 && depth[1] < 10) {
    nodePaddingRatio = 1;
  } else if (depth[1] >= 10) {
    screenWidth += screenWidth * 0.5;
    screenHeight += 200;
    nodePaddingRatio = 1;
  } else if (depth[1] >= 20) {
    screenWidth += screenWidth * 1;
    screenHeight += 200;
    nodePaddingRatio = 1;
  } else if (depth[1] < 2 && initialSankeyData.nodes.length === 2 && initialSankeyData.links.length === 1) {
    nodePaddingRatio = 1;
    screenHeight = 300;
    screenWidth -= 150;
  } else if (initialSankeyData.nodes.length === 2) {
    nodePaddingRatio = 1;
    screenHeight = 300;
    screenWidth -= 150;
  } else {
    screenWidth -= 150;
    screenHeight -= 250;
  }
  return {
    nodeWidth,
    nodePadding,
    nodePaddingRatio,
    screenWidth,
    screenHeight
  };
};

export const highlightNodes = (selectedNode, name) => {
  let opacity = 0.3;

  if (selectedNode.name === name) {
    opacity = 1;
  }
  selectedNode.sourceLinks.forEach(link => {
    if (link.target.name === name) {
      opacity = 1;
    }
  });
  selectedNode.targetLinks.forEach(link => {
    if (link.source.name === name) {
      opacity = 1;
    }
  });

  return opacity;
};

export const truncateString = (str: string, limit: number = 17) => {
  if (str.length > limit) {
    return str.substring(0, limit) + '...';
  } else {
    return str;
  }
};

export const getActionType = (data: string) => {
  let actionStr = '';
  if (data === 'ticket-form') {
    return (actionStr = 'Support Contact');
  } else if (data === 'url') {
    return (actionStr = 'URL');
  } else if (data === 'screen') {
    return (actionStr = 'Step');
  } else if (data === 'exit') {
    return (actionStr = 'Clean Exit');
  } else if (data === 'workflow') {
    return (actionStr = 'Workflow');
  }
  return actionStr;
};

export function mapButtonClickedData(data) {
  const dataRes = data.button_titles;
  const result = Array.from(new Set(dataRes.map(s => s.button_title))).map(item => {
    const button = dataRes.filter(s => s.button_title === item).map(edition => edition)[0];
    return {
      button_title: item,
      button_percentage: button.button_percentage,
      button_count: button.button_count,
      target_step_number: button.target_step_number,
      target_workflow_id: button.target_workflow_id,
      isMain: true,
      children: dataRes
        .filter(s => s.button_title === item)
        .map(edition => {
          return {
            target_workflow_id: edition.target_workflow_id,
            button_title: edition.target_workflow_name,
            button_count: edition.workflow_count,
            button_percentage: edition.workflow_percentage,
            target_step_number: edition.target_step_number,
            button_action_type: button.button_action_type
          };
        })
    };
  });
  result.map(item => {
    if (item.children.length <= 1) {
      item.children.map(child => {
        child.button_percentage = '100.0';
      });
    }
  });
  return result;
}

export function mapWftoWfData(data) {
  const result = Array.from(new Set(data.details.map(s => s.workflow_name))).map(item => {
    const button = data.details.filter(s => s.workflow_name === item).map(edition => edition)[0];
    return {
      workflow_name: item,
      workflow_percentage: button.workflow_percentage,
      count: button.workflow_number,
      workflow_id: button.workflow_id,
      children: data.details
        .filter(s => s.workflow_name === item)
        .map(edition => {
          return {
            workflow_name: edition.step_name,
            count: edition.count,
            step_number: edition.step_number,
            workflow_percentage: edition.step_percentage
          };
        })
    };
  });
  return result;
}
