import { WorkflowStore } from '../workflowStore';
import { AnalyticsStore } from './';

const lookerSolvvyUrl = 'https://looker-external.solvvy.com';
export const listenToAnalyticsFrameEvents = (workflowStore: WorkflowStore, analyticsStore: AnalyticsStore): void => {
  const onAnalyticsEvent = (event: MessageEvent) => {
    const analyticsFrame = document.getElementById('analytics-frame');
    if (isIFrameValidWithEvent(analyticsFrame, event)) {
      let lookerEvent;
      try {
        lookerEvent = JSON.parse(event.data);
      } catch (e) {
        return;
      }

      if (lookerEvent.type === 'dashboard:filters:changed') {
        onChangeLookerFilter(lookerEvent, analyticsFrame);
      }
    }

    const breakdownAnalyticsFrame = document.getElementById('breakdown-analytics-frame');
    if (isIFrameValidWithEvent(breakdownAnalyticsFrame, event)) {
      const lookerEvent = JSON.parse(event.data);
      if (lookerEvent.type === 'page:changed') {
        updateButtonBreakdownReport(breakdownAnalyticsFrame);
      }
    }
  };

  function onChangeLookerFilter(lookerEvent, analyticsIFrame: IValidHTMLIFrameElement) {
    analyticsStore.updateFilters(lookerEvent.dashboard?.dashboard_filters);

    // Auto refresh dashboard
    const dashboardRunRequest = JSON.stringify({ type: 'dashboard:run' });
    analyticsIFrame.contentWindow.postMessage(dashboardRunRequest, lookerSolvvyUrl);
  }

  const updateButtonBreakdownReport = (frame: IValidHTMLIFrameElement) => {
    const updateFilterRequest = JSON.stringify({
      type: 'dashboard:filters:update',
      filters: {
        workflow_id: workflowStore.reportParams.workflow_id,
        screen_id: workflowStore.reportParams.screen_id
      }
    });
    const dashboardRunRequest = JSON.stringify({
      type: 'dashboard:run'
    });

    frame.contentWindow.postMessage(updateFilterRequest, lookerSolvvyUrl);
    frame.contentWindow.postMessage(dashboardRunRequest, lookerSolvvyUrl);
  };

  window.addEventListener('message', onAnalyticsEvent);
};

interface IValidHTMLIFrameElement extends HTMLIFrameElement {
  contentWindow: WindowProxy;
}

const isIFrameValidWithEvent = (iFrame: HTMLElement | null, event: MessageEvent): iFrame is IValidHTMLIFrameElement =>
  iFrame?.tagName === 'IFRAME' &&
  (iFrame as HTMLIFrameElement).contentWindow !== null &&
  (iFrame as HTMLIFrameElement).contentWindow === event?.source &&
  lookerSolvvyUrl === event?.origin;
