import { SolutionSource } from './models/Solution';

export const ALL_TIKA_USERS = [
  { value: '1978', label: 'Tika User 01: 1978' },
  { value: '1979', label: 'Tika User 02: 1979' },
  { value: '1980', label: 'Tika User 03: 1980' },
  { value: '1981', label: 'Tika User 04: 1981' },
  { value: '1982', label: 'Tika User 05: 1982' },
  { value: '2007', label: 'Tika User 06: 2007' },
  { value: '2008', label: 'Tika User 07: 2008' },
  { value: '2009', label: 'Tika User 08: 2009' },
  { value: '2010', label: 'Tika User 09: 2010' },
  { value: '2011', label: 'Tika User 10: 2011' },
  { value: '2012', label: 'Tika User 11: 2012' },
  { value: '2013', label: 'Tika User 12: 2013' },
  { value: '2014', label: 'Tika Manager 01: 2014' },
  { value: '2015', label: 'Tika Manager 02: 2015' },
  { value: '2776', label: 'Tika Manager 03: 2776' },
  { value: '2777', label: 'Tika Manager 04: 2777' },
  { value: '2778', label: 'Tika Manager 05: 2778' },
  { value: '2779', label: 'Tika Manager 06: 2779' },
  { value: '2780', label: 'Tika Manager 07: 2780' }
];

export const ALL_COACH_USERS = [
  {
    value: 'all_tika_users',
    label: 'All Tika Users',
    children: ALL_TIKA_USERS
  },
  { value: 'my_feedback', label: 'My Feedback' }
];

export const JUDGEMENT_FILTER: Record<Judgment, string> = {
  not_deflectable: 'Exclude question from training set',
  addressed: 'Absolutely Perfect Answer',
  junk: 'This query does not follow coaching best practices',
  innately_non_deflectable: 'Customer Support Required - Recommend create new Customer Support experience workflow',
  content_gap: 'A new knowledge base article must be created',
  support_recommended: 'Article recommends contacting support',
  answer_loop: 'Article contains a link to Solvvy, resulting in an “answer loop”',
  accuracy_issue: 'Article did not surface in the top 3 recommendations'
};

export const NOT_DEFLECTABLE = 'not_deflectable';

export type Judgment =
  | typeof NOT_DEFLECTABLE
  | 'innately_non_deflectable'
  | 'content_gap'
  | 'junk'
  | 'addressed'
  | 'support_recommended'
  | 'answer_loop'
  | 'accuracy_issue';

export const QUESTION_JUDGMENTS: Judgment[] = [NOT_DEFLECTABLE, 'content_gap', 'innately_non_deflectable', 'junk'];

export const ANSWER_JUDGMENTS: Judgment[] = ['addressed', 'support_recommended', 'answer_loop', 'accuracy_issue'];

export interface ICoachStats {
  total_addressed: string;
  total_not_self_serviceable: string;
  user_addressed: string;
  user_not_self_serviceable: string;
  total_remaining: string; // recommendations with status that is not addressed|not_deflectable|junk
}

export interface IRecommendation {
  id: string;
  query: {
    description: string;
    id: string;
    created_at: string;
  };
  query_id: string;
  org_id: number;
  analysis_id: string;
}

export interface ISearchedQuestion {
  score: number;
  id: string;
  org_id: number;
  created_at: string;
  searchable: string;
}

export interface IQuestion {
  /** questionId */
  id: string;
  text: string;
  askedAt: string;
  /** Will not be available when user picks a question by search */
  orgId: number | undefined;
  /** Will not be available when user picks a question by search */
  recommendationId?: string;
  /** Will be `true` when the user picks a question by search */
  searchedQuestion?: boolean;
  /** Will be present only for question in a voted recommendation */
  voteUpdatedAt?: string;
}

export interface IVotedQuery {
  /** Question Id */
  id: string;
  org_id: number;
  content: string;
  created_at: string;
  status: Judgment;
  recommendationId: string;
  voteUpdatedAt: string;
}

export interface IVote {
  solution_id: string;
  relevance: number;
  solution_resource_type: string;
  source: SolutionSource;
  source_type: string;
  source_id: string;
  was_positive: boolean;
}

export enum SolutionRelevance {
  RELEVANT = '3',
  NOT_RELEVANT = '0'
}

export const TARA_MODE = true; // tara mode will always be true after coach-voteaudit refactor
