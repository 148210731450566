import gql from 'graphql-tag';
import assign from 'lodash/assign';
import includes from 'lodash/includes';
import sourceTypes from '../shared/util/sourceTypes';
import { EXTEND_SESSION_HEADER } from '../store/authStore';
import { Api } from './api';

const orgJobStatusQuery = gql`
  query($orgId: Int!, $jobNames: [String]!, $statuses: [String]!) {
    allJobsPerOrgStatus(org_id: $orgId, jobNames: $jobNames, statuses: $statuses) {
      id
      org_id
      flow_id
      job_name
      status
      requested_at
      user_id
      updated_at
    }
  }
`;

const isKbRecrawlInProgressQuery = gql`
  query($orgId: Int!) {
    isKbRecrawlInProgress(org_id: $orgId)
  }
`;

const requestForRecrawlMutation = gql`
  mutation($orgId: Int!, $jobName: String!) {
    requestToStartJob(org_id: $orgId, job_name: $jobName) {
      id
      org_id
      flow_id
      job_name
      status
      requested_at
      user_id
      updated_at
    }
  }
`;

export class ConnectorService {
  constructor(private api: Api) {}

  async fetchConnectors(orgId) {
    const supportedTypes = sourceTypes().map(source => source.key);
    return this.api.get('/v1/connectors', { org_id: orgId, limit: 100 }).then(response => {
      return response.data.filter(connector => {
        return includes([...supportedTypes, 'external_api'], connector.source.type) && connector.enabled;
      });
    });
  }

  async createConnector(orgId, sourceData) {
    const completeSourceData = assign(
      {
        credentials: null
      },
      sourceData,
      {
        org_id: orgId,
        enabled: true
      }
    );

    return this.api.post('/v1/connectors', completeSourceData);
  }

  async updateConnector(sourceData) {
    return this.api.put(`/v1/connectors/${sourceData.id}`, sourceData);
  }

  async deleteConnector(id) {
    return this.api.del(`/v1/connectors/${id}`);
  }

  async getAuthUrl(id) {
    return this.api.get(`/v1/connectors/${id}/authenticate?redirect=false`);
  }

  async getRecrawlJobStatus({ org_id, jobNames, status }, periodicRefreshing): Promise<any[]> {
    const headers = {};

    // if doing a periodic a refresh, disable extending the session
    if (periodicRefreshing) {
      headers[EXTEND_SESSION_HEADER] = 'false';
    }

    const { data } = await this.api.gqlQuery({
      query: orgJobStatusQuery,
      variables: {
        orgId: org_id,
        jobNames,
        statuses: status
      },
      fetchPolicy: 'network-only',
      context: { headers }
    });

    return data.allJobsPerOrgStatus;
  }

  async isKbRecrawlInProgress(orgId, periodicRefreshing): Promise<boolean> {
    const headers = {};

    // if doing a periodic a refresh, disable extending the session
    if (periodicRefreshing) {
      headers[EXTEND_SESSION_HEADER] = 'false';
    }

    const { data } = await this.api.gqlQuery({
      query: isKbRecrawlInProgressQuery,
      variables: { orgId },
      fetchPolicy: 'network-only',
      context: { headers }
    });

    return data.isKbRecrawlInProgress;
  }

  async requestForRecrawl({ org_id, job_name }) {
    return this.api.gqlMutate({
      mutation: requestForRecrawlMutation,
      variables: {
        orgId: org_id,
        jobName: job_name
      }
    });
  }

  async getTicketFormConfig(connectorId, uiConfigurationName, uiConfigurationInstanceId) {
    const { data } = await this.api.get(`/v1/connectors/${connectorId}/ticket-form-config`, {
      uiConfigurationName,
      uiConfigurationState: 'published',
      uiConfigurationInstanceId
    });
    return data;
  }

  async fetchSunshineConnectors(orgId) {
    const { data } = await this.api.get('/v1/connectors', {
      ['source.type']: 'sunshine',
      org_id: orgId,
      enabled: true
    });
    return data;
  }
}

export const connectorService = api => {
  return new ConnectorService(api);
};

export default connectorService;
