import * as FullStory from '@fullstory/browser';
import { OrgPermission } from '@solvvy/util/lib/authorization';
import { OrgGroupRole, OrgGroupRoles } from '@solvvy/util/lib/authorization/RoleModel';
import bind from 'bind-decorator';
import * as cronParser from 'cron-parser';
import cronstrue from 'cronstrue';
import { clone } from 'lodash';
import capitalize from 'lodash/capitalize';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import { action, computed, observable, reaction, runInAction } from 'mobx';
import { asyncAction } from 'mobx-utils';
import moment from 'moment-timezone';
import qs from 'qs';
import FeatureFlagService, { FEATURE_FLAGS } from 'src/services/features';
import { Maybe } from 'tsmonad';
import {
  combinedOrgPageRoute,
  globalUserPageRoute,
  loginPageRoute,
  onboardingPageRoute,
  orgPageRoute,
  passwordResetRoute,
  ssoCallbackRoute,
  twoFactorAuthPageRoute
} from '../routes/routes';
import { api, userService, whatsNewMessageService } from '../services';
import asyncComputed from '../shared/util/asyncComputed';
import { formatEmail, getNodesFromConnection, isNumeric } from '../shared/util/format';
import { isLocalhost, isStaging } from '../shared/util/misc';
import { NotificationManager } from '../shared/util/NotificationManager';
import * as OnboardingSteps from '../shared/util/onboarding_steps';
import { recordErrors } from '../shared/util/recordErrors';
import { AuthStore } from './authStore';
import { ConnectorStore } from './connectorStore';
import FeaturesStore, { FLAGS } from './featuresStore';
import Org from './models/Org';
import OrgMessage from './models/OrgMessage';
import { ISSOConnection, User } from './models/User';
import OnboardingStore from './onboardingStore';
import { RouterStore } from './routerStore';
const LogRocket = require('logrocket');

const CURRENT_DATE_TIME = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

const DATETIME_FORMAT = 'llll';

export enum ScheduleType {
  WEEKLY = 'Weekly',
  DAILY = 'Daily',
  CUSTOM = 'Custom'
}

export interface ICronSchedule {
  type: ScheduleType;
  hour?: number;
  days?: number[];
  schedule: string;
  last_run?: string;
  timezone: string;
  disabled?: boolean;
}

export interface IOrgDetails {
  id?;
  name?;
  selectedForOverViewReport?;
  settings?;
}

function extractLoginErrorMessage(error) {
  let message = 'An unknown error occurred while logging in. Please try again later.';
  if (error.response?.data?.name === 'TooManyRequestsError') {
    return (message = "You've made too many login attempts. Please try again in a few minutes or contact support.");
  }
  if (!error) {
    message = 'There was a network error. Please check your internet connection.';
  } else if (error.response && error.response.status === 401) {
    message = 'We could not find that email/password combination. Please try typing in your login information again.';
  } else if (
    error instanceof Error &&
    (error.name === 'GlobalUserError' ||
      error.name === 'SessionExpiredError' ||
      error.name === 'AccessDeniedError' ||
      error.name === 'AuthenticationError')
  ) {
    message = error.message;
  } else {
    recordErrors(error);
  }
  return message;
}

const DEFAULT_ORG_SETTINGS = {
  timezone: 'America/Los_Angeles',
  cost_per_ticket: '',
  org_display_name: '',
  average_order_value: '',
  carting_url: 'never match',
  conversion_url: 'never match',
  engage: false,
  enable_coach_app: false,
  enable_insights_app: false,
  enable_url_suggestions: false,
  enable_ticketqueries_explore: false,
  explore_default_previous_days: 30,
  enable_workflow_builder: false,
  enable_survey_workflows: false,
  enable_wfb_spatial_editor: false,
  enable_analytics_api: false,
  enable_org_roles: false,
  enable_social_channel_integration: false,
  intent_prediction_rules: '',
  enable_data_source_setup: false,
  enable_addon_marketplace: false,
  display_url_with_article_title: false,
  enable_intents_layout: false,
  coach_search_all_solutions_elasticsearch: false,
  enable_launcher_widget_controls: false,
  enable_workflow_insights: false
};

export class OrgStore {
  // TODO: Fix to not initialize to an empty object.. its not an actual instance of User and hides some errors. It should be initialized as null or undefined
  @observable
  currentUserContext: User = {} as User;
  @observable
  userOrg = {
    id: '',
    name: null,
    org_group_id: 0,
    org_group: {
      orgs: [],
      api_clients: []
    }
  };
  @observable
  orgSettings = clone(DEFAULT_ORG_SETTINGS);
  @observable
  sauron_cron_schedule = {
    reingest_webpages_if_safe: [
      {
        schedule: '--',
        timezone: '--',
        disabled: false
      }
    ]
  };
  @observable
  userAssignedOrgId;
  @observable
  force_change_password = false;
  @observable
  onBoarding = false;
  @observable
  resetEmailSuccess = false;
  @observable
  resetEmailLinkExpired = false;
  @observable
  resetUserName = '';
  @observable
  errorName: null;

  @observable
  loadingUser = false;
  @observable
  loginError = '';

  @observable
  loadingOrgDetails = false;
  @observable
  fetchedOrgSettingsOfSelectedOrg = false;

  @observable
  tempUserEmail: string | null = null;
  @observable
  tempUserPassword: string | null = null;

  @observable
  twoFactorAuthEnabled = false;
  @observable
  enablingTwoFactor = false;
  @observable
  twoFactorQrCode: string | null = null;
  @observable
  verifyingUser = false;
  @observable
  twoFactorError = '';

  @observable
  showWhatsNew = false;

  @observable
  allOrgMessageNodes;

  @observable
  newOrgMessages;

  @observable
  isFacebookInitiated = false;

  @observable
  ssoConnections: ISSOConnection[] | null;

  features: FeatureFlagService;

  // This variable is used for analytics tool to keep in sync with logged in user and org Id;
  @observable
  sessionStoreUserInfo = {};

  allOrgs = asyncComputed(async () => {
    const {
      data: { allOrgs }
    } = await userService.getAllOrgs();
    const orgNodes = getNodesFromConnection(allOrgs);
    return orgNodes.map(orgNode => new Org(orgNode.id, orgNode.name, orgNode.settings.is_active));
  });

  constructor(
    private _authStore: AuthStore,
    private _routerStore: RouterStore,
    private _connectorStore: ConnectorStore,
    private _onboardingStore: OnboardingStore,
    private _featuresStore: FeaturesStore,
    private _notificationManager: NotificationManager
  ) {
    _routerStore._orgStore = this;
    api &&
      api.setOnLogout(() => {
        this.clearLoginInfo();
      });
    reaction(
      () => this.selectedOrgId,
      () => {
        if (!isNaN(this.selectedOrgId)) {
          this._featuresStore.updateCustomAttributes({ orgId: this.selectedOrgId });
          return this.fetchOrgDetails(this.selectedOrgId);
        }
        return null;
      },
      {
        fireImmediately: true
      }
    );
    reaction(
      () => this.orgStoreDataToPersist,
      data => this.persistOrgStoreData(data)
    );

    reaction(
      () => this.currentUserContext.isGlobalUser,
      isGlobalUser => this._featuresStore.updateCustomAttributes({ isGlobalUser })
    );
  }

  @asyncAction
  *login(username: string, password: string) {
    this.loadingUser = true;
    this.loginError = '';
    try {
      const currentQueryParams = this._routerStore.activeQueryParams;
      const { for_zendesk: loginForZendesk, return_to: zendeskReturnToUrl } = currentQueryParams;

      this.clearLoginInfo();
      LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
        email: formatEmail(username)
      });
      /*
        If user has two factor auth enabled the access_token would be returned as null and user would be routed to two factor auth page.a
        If no two factor is enabled then login service will return access_token and rest of the flow continues.
       */

      const { is_two_factor_authentication_enabled, access_token, zendesk_redirect_url } = yield userService.login(
        formatEmail(username),
        password.trim(),
        loginForZendesk ? Boolean(loginForZendesk) : false,
        zendeskReturnToUrl
      );
      this.twoFactorAuthEnabled = Boolean(is_two_factor_authentication_enabled);
      if (is_two_factor_authentication_enabled) {
        // save the username and password which will be used for verifying the token.
        this.tempUserEmail = formatEmail(username);
        this.tempUserPassword = password.trim();
        this.routeForTwoFactorAuthPage(currentQueryParams);
      } else {
        this.initializeAPI(access_token);

        if (zendesk_redirect_url) {
          window.location.href = zendesk_redirect_url;
          return;
        }
        yield this.fetchUserContext();
        this._routerStore.redirectFromPreservedUrl();
      }
    } catch (e) {
      recordErrors(e);
      this.loadingUser = false;
      this.loginError = extractLoginErrorMessage(e);
    }
  }

  @bind
  @asyncAction
  *loginWithGoogleIdToken(googleAuthResult) {
    if (googleAuthResult.error) {
      this.loginError = googleAuthResult.details;
    } else {
      try {
        const loginForZendesk = this._routerStore.activeQueryParams?.for_zendesk;
        const zendeskReturnToUrl = this._routerStore.activeQueryParams?.return_to;

        this.clearLoginInfo();
        const { access_token, zendesk_redirect_url } = yield userService.loginWithGoogleIdToken(
          googleAuthResult.tokenId,
          loginForZendesk,
          zendeskReturnToUrl
        );
        this.initializeAPI(access_token);
        if (zendesk_redirect_url) {
          window.location.href = zendesk_redirect_url;
          return;
        }
        yield this.fetchUserContext();

        this._routerStore.redirectFromPreservedUrl();
      } catch (e) {
        this.loadingUser = false;
        this.loginError = extractLoginErrorMessage(e);
        recordErrors(e);
      }
    }
  }

  @asyncAction
  *loginWithSSO(accessToken) {
    try {
      this.clearLoginInfo();
      this.initializeAPI(accessToken);
      const { zendeskRedirectUrl } = this._routerStore.activeQueryParams;
      if (zendeskRedirectUrl) {
        window.location.href = zendeskRedirectUrl as string;
      }
      this.fetchUserContext();
      this._routerStore.redirectFromPreservedUrl();
    } catch (e) {
      this.loadingUser = false;
      this.loginError = extractLoginErrorMessage(e);
      recordErrors(e);
    }
  }

  @action
  showSSOLoginError(e) {
    let error = e;
    try {
      const rawError = JSON.parse(e);
      error = new Error(rawError.message);
      error.name = rawError.name;

      this.clearLoginInfo();
      this.loadingUser = false;
      this.loginError = extractLoginErrorMessage(error);
    } finally {
      recordErrors(error);
    }
  }

  @action
  updateSSODetails(ssoConnections) {
    this.ssoConnections = ssoConnections;
  }

  redirectToSSOLogin = (emailId: string, workOSConnectionId?: string) => {
    const currentQueryParams = this._routerStore.activeQueryParams;
    userService.redirectToSSOLogin(emailId, currentQueryParams, workOSConnectionId).then(response => {
      try {
        if (typeof response !== 'string') {
          const { workos_details: ssoConnections } = response;
          this.updateSSODetails(ssoConnections);
        }
      } catch (e) {
        this.clearLoginInfo();
        recordErrors(e);
      }
    });
  };

  @asyncAction
  *verifyUser(token) {
    try {
      this.verifyingUser = true;
      this.twoFactorError = '';
      const currentQueryParams = this._routerStore.activeQueryParams;
      const { for_zendesk: loginForZendesk, return_to: zendeskReturnToUrl } = currentQueryParams;
      const { access_token, is_verified, zendesk_redirect_url } = yield userService.verifyAuthToken(
        this.tempUserEmail,
        this.tempUserPassword,
        token,
        loginForZendesk,
        zendeskReturnToUrl
      );
      if (is_verified) {
        this.initializeAPI(access_token);

        if (zendesk_redirect_url) {
          window.location.href = zendesk_redirect_url;
          return;
        }
        yield this.fetchUserContext();
        this._routerStore.redirectFromPreservedUrl();
      } else {
        this.verifyingUser = false;
        this.twoFactorError = 'Invalid token. Please try again.';
      }
    } catch (e) {
      this.verifyingUser = false;
      recordErrors(e);
      this.loadingUser = false;
      yield this.logout();
      this.loginError = 'Oops something went wrong!!!. Please try again.';
    }
  }

  @bind
  @asyncAction
  *enableTwoFactorAuth() {
    try {
      const {
        data: {
          updateViewerTwoFactorAuth: { is_two_factor_authentication_enabled, qrCode }
        }
      } = yield userService.updateTwoFactorAuth(true);
      this.twoFactorAuthEnabled = is_two_factor_authentication_enabled;
      this.twoFactorQrCode = qrCode;
      this._notificationManager.success({
        message: `Use an application on your phone to scan the QR code to get two-factor authentication codes when prompted during login.`,
        title: 'Two factor authentication enabled!!!'
      });
    } catch (e) {
      this._notificationManager.error({
        message: 'Update failed. Please try after some time.',
        title: 'Oops something went wrong!!!'
      });
      recordErrors(e);
    }
  }

  @asyncAction
  *disableTwoFactorAuth(twoFactorAuthFlag) {
    try {
      yield userService.updateTwoFactorAuth(twoFactorAuthFlag);
      this.twoFactorAuthEnabled = twoFactorAuthFlag;
      this.twoFactorQrCode = null;
      this._notificationManager.success({
        message: `Two factor authentication disabled`,
        title: 'Two factor authentication disabled!!!'
      });
    } catch (e) {
      this._notificationManager.error({
        message: 'Update failed. Please try after some time.',
        title: 'Oops something went wrong!!!'
      });
      recordErrors(e);
    }
  }

  @asyncAction
  *changePassword(oldPassword?, newPassword?) {
    try {
      yield userService.changeViewerPassword(oldPassword.trim(), newPassword.trim());
      this.onBoarding = false;
      this.force_change_password = false;
      this._notificationManager.success({
        message: `Your password is successfully updated`,
        title: 'Password Updated'
      });
    } catch (e) {
      this._notificationManager.error({
        message: e.message,
        title: 'Password Update'
      });
      recordErrors(e);
    }
  }

  @asyncAction
  *forgotPasswordChange(newPassword, passwordToken) {
    try {
      this.resetEmailSuccess = false;
      yield userService.forgotPasswordChange(newPassword.trim(), passwordToken);
      this._notificationManager.success({
        title: 'Password Updated',
        message: `Your password is successfully updated. Please login again.`
      });
    } catch (e) {
      this._notificationManager.error({
        title: 'Password Update',
        message: e.message
      });
      recordErrors(e);
    }
  }

  @asyncAction
  *verifyPasswordResetCode(resetCode) {
    try {
      this.resetEmailLinkExpired = false;
      const { full_name } = yield userService.verifyPasswordResetCode(resetCode);
      this.resetUserName = capitalize(full_name);
    } catch (e) {
      this.resetEmailLinkExpired = true;
      recordErrors(e);
    }
  }

  @asyncAction
  *sendForgotPasswordEmailLink(email) {
    try {
      this.resetEmailSuccess = false;
      yield userService.sendForgotPasswordEmail(email.trim());
      this.resetEmailSuccess = true;
    } catch (e) {
      this.resetEmailSuccess = false;
      this._notificationManager.error({
        title: 'Oops something went wrong',
        message: `The entered email address is not in our system. Please contact Solvvy Support.`
      });
      LogRocket.captureException(e);
    }
  }

  async getOrgSetting(name: string, bypassCache?: boolean): Promise<string | null | undefined> {
    return userService.getOrgSetting(this.selectedOrgId, name, bypassCache);
  }

  @asyncAction
  *updateOrgSetting(name, value, disableNotification = false) {
    try {
      yield userService.updateOrgSettingValue(this.selectedOrgId, name, value);
      this.orgSettings[name] = value;
      if (!disableNotification) {
        this._notificationManager.success({
          title: 'Updated the setting value',
          message: `Setting value is updated.`
        });
      }
    } catch (e) {
      this._notificationManager.error({
        title: 'Update the setting value',
        message: `We encountered some issues please try again after some time.`
      });
      recordErrors(e);
    }
  }

  @asyncAction
  *fetchUserInfo(org_id = this.selectedOrgId) {
    try {
      yield userService.getUserInfo(org_id);
    } catch (e) {
      if (e.name === 'SessionExpiredError') {
        this.clearLoginInfo();
      }
      this.loginError = extractLoginErrorMessage(e);
    }
  }

  @asyncAction
  *fetchUserContext(org_id = this.selectedOrgId) {
    try {
      this.loadingUser = true;
      this.twoFactorQrCode = null;
      const currentUser = yield userService.getUserInfo(org_id);

      this.sessionStoreUserInfo = {
        user_id: currentUser.id,
        user_email: currentUser.email,
        org_id: currentUser.org_id
      };
      LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
        email: formatEmail(currentUser.email)
      });
      this.userAssignedOrgId = currentUser.org_id;
      this.twoFactorAuthEnabled = currentUser.is_two_factor_authentication_enabled;
      this.force_change_password = currentUser.force_change_password;
      this.currentUserContext = new User(currentUser, true);

      this._connectorStore.updateUserAssignedOrgId(this.userAssignedOrgId);

      if (!isLocalhost) {
        FullStory.identify(currentUser.id, {
          dashboard: true,
          orgId: this.currentUserContext.isGlobalUser ? null : currentUser.org_id,
          globalUser: this.currentUserContext.isGlobalUser
        });
      }

      this.features = new FeatureFlagService(currentUser, this.currentUserContext);
      this._featuresStore.init();

      this.initChameleon(currentUser);

      if (!isLocalhost) {
        this.initFacebook();
      }

      // check for zendesk parameter, and if present, redirect to zendesk in logged in state.
      const currentQueryParams = this._routerStore.activeQueryParams;
      if (currentQueryParams.for_zendesk) {
        yield userService.getZendeskRedirectUrlAndPerformRedirect(currentQueryParams);
        return;
      }

      yield Promise.all([
        this.getOrgDetailsAndRoute(this.userAssignedOrgId),
        userService.updateUserLastLogin(CURRENT_DATE_TIME)
      ]);
      this.verifyingUser = false;
      this.loadingUser = false;
    } catch (e) {
      // Set error information to pass to subsequent routes
      if (e.name === 'GlobalUserError' && this._routerStore.history.location.pathname !== '/404') {
        const currentQueryParams = this._routerStore.activeQueryParams;
        if (currentQueryParams.for_zendesk) {
          yield userService.getZendeskRedirectUrlAndPerformRedirect(currentQueryParams);
          return;
        }

        // Note that this only occurs if this isn't a 404 page
        this._routerStore.history.push('/global'); // Redirect for a global user
      } else {
        this.clearLoginInfo();
      }
      this.loadingUser = false;
      this.verifyingUser = false;
      this.loginError = extractLoginErrorMessage(e);
    }
  }

  canAccessPage(orgPage) {
    switch (orgPage) {
      case 'addons':
        return this.canAccessAddonMarketplace;
      case 'analytics':
        return this.canAccessAnalytics;
      case 'categories':
        return this.canAccessInsights;
      case 'explore':
        return this.canAccessExplore;
      case 'dataDownload':
        return this.canAccessDataDownload;
      case 'coach':
        return this.canAccessCoachApp;
      case 'coachv2':
        return this.canAccessUnifiedCoach;
      case 'intents':
        return this.canAccessIntents;
      case 'interfaceSetup':
        return this.canAccessInterfaceSetup;
      case 'workflows':
        return this.canAccessWorkflowBuilder;
      case 'suggestions':
        return this.canAccessSuggestionsEditor;
      case 'recrawlHub':
        return this.canAccessRecrawlHub;
      case 'userManagement':
        return this.canAccessUserManagement;
      case 'coachBeta':
      case 'voteAudit':
      case 'voteAuditBeta':
        return this.currentUserContext.isGlobalUser;
      case 'social-integrations':
        return this.canAccessSocialIntegrations;
      case 'profileSettings':
      case 'settings':
        return this.canAccessSettings;
      case 'onboarding':
        return this.canAccessOnboarding;
      case 'passwordReset':
        return true;
      case undefined: // for non org pages. i.e, pages like combinedAnalytics, 404, login, etc.
        return true;
      default:
        return false;
    }
  }

  @action
  async getOrgDetailsAndRoute(org_id = this.selectedOrgId) {
    try {
      await this.fetchOrgDetails(org_id);
      this.goToNextRoute();
    } catch (e) {
      recordErrors(e);
      // Redirects if the org doesn't exist
      // Behavior:
      //  - User is non-global: return to user's org
      //  - User is global: return to global user page
      // This should get converted to a 401
      this._routerStore.history.push('/404');
    }
  }

  initChameleon(currentUser) {
    if (isStaging || currentUser.org_id !== 1165) {
      return;
    }
    const chmln = (window as any).chmln;
    chmln.identify(currentUser.id, {
      email: currentUser.email,
      created: currentUser.created_at,
      name: [currentUser.first_name, currentUser.last_name].filter(Boolean).join(' '),
      role: this.currentOrgRoles.map(role => role.name).join(',')
    });
  }

  initFacebook() {
    try {
      const fb = (window as any).FB;
      fb.getLoginStatus(response => {
        runInAction(() => {
          this.isFacebookInitiated = true;
        });
      });
    } catch (e) {
      recordErrors(e);
    }
  }

  applyQueryParamOrgSettingsOverride() {
    const queryParams = qs.parse(window.location.search.replace(/^\?/, ''));
    Object.keys(queryParams).forEach(key => {
      // only allow overrides of fetched org settings
      if (this.orgSettings[key] !== undefined) {
        let value: any = queryParams[key];
        if (value === 'true') {
          value = true;
        } else if (value === 'false') {
          value = false;
        } else if (isNumeric(value)) {
          value = Number(value);
        }
        this.orgSettings[key] = value;
      }
    });
  }

  @asyncAction
  *fetchOrgDetails(orgId = this.selectedOrgId) {
    try {
      this.loadingOrgDetails = true;
      this.fetchedOrgSettingsOfSelectedOrg = false;
      const [
        {
          data: { allOrgMessages }
        },
        {
          data: { Org: userOrgData }
        }
      ] = yield Promise.all([
        // Cap at five calls
        whatsNewMessageService.getAllOrgMessages(),
        userService.fetchOrgInfoWithSettings(orgId)
      ]);

      if (this.hasPermissionForOrg(orgId, OrgPermission.connectorsRead)) {
        yield this._connectorStore.retrieveConnectors(orgId);
      }

      this.allOrgMessageNodes = getNodesFromConnection(allOrgMessages);

      this.userOrg = pick(userOrgData, ['id', 'name', 'org_group_id', 'org_group', 'settings']);
      this.orgSettings = userOrgData.settings;
      this.sauron_cron_schedule = JSON.parse(userOrgData.settings.sauron_cron_schedule);
      this._connectorStore.updateOrgTimeZone(this.orgSettings.timezone);
      // allow global users to override org settings with query params
      if (this.currentUserContext.isActualGlobalUser) {
        this.applyQueryParamOrgSettingsOverride();
      }

      // checking if the org_id is same as the orgId in the route, so that
      // the orgSettings of the relevant org can be checked for authorization
      if (orgId === this._routerStore.activeOrgId) {
        this.fetchedOrgSettingsOfSelectedOrg = true;
      }
    } catch (e) {
      recordErrors(e);
    } finally {
      this.loadingOrgDetails = false;
    }
  }

  // Redirects to a specific org
  // Used for global user org selection
  @action
  loadOrg(org_id) {
    if (includes(this._routerStore.activeRoutes, onboardingPageRoute)) {
      return;
    }

    this._routerStore.history.push(
      orgPageRoute.stringify({
        ...this._routerStore.activeRouteParams,
        orgId: org_id,
        orgPage: 'analytics'
      })
    );
  }

  @asyncAction
  *logout() {
    try {
      yield userService.logOutUser();
    } catch (e) {
      recordErrors(e);
    } finally {
      this.clearLoginInfo();
    }
  }

  @action
  clearLoginInfo() {
    this.resetUserInfo();
    this.deleteOrgStoreData();
    this._authStore.setAccessToken('', 0);
    this._authStore.removeToken();
    this._authStore.cancelSessionExpirationTimeouts();

    this._routerStore.preserveCurrentUrl();
    this.goToLogin();
  }

  @action
  resetUserInfo() {
    this.currentUserContext = {} as User;
    this.sessionStoreUserInfo = '';
    this.userOrg = {
      id: '',
      name: null,
      org_group_id: 0,
      org_group: {
        orgs: [],
        api_clients: []
      }
    };
    this.orgSettings = clone(DEFAULT_ORG_SETTINGS);
    this.loadingUser = false;
    this.loginError = '';
    this.fetchedOrgSettingsOfSelectedOrg = false;
  }

  @action
  async updateCurrentUserPassword(oldPassword, newPassword) {
    try {
      await this.currentUserContext.changeViewerPassword(oldPassword, newPassword);
      this._notificationManager.success({
        title: 'Password Updated',
        message: `Your password is successfully updated`
      });
    } catch (e) {
      this._notificationManager.error({
        title: 'Password Update',
        message: e.message
      });
      recordErrors(e);
    }
  }

  @action
  goToNextRoute() {
    if (this.force_change_password) {
      this.onBoarding = true;
      this.routeForPasswordResetPage();
    } else if (
      !(this._connectorStore.hasKnowledgeBaseConnectors || this._connectorStore.hasTicketConnectors) &&
      this.canAccessOnboarding &&
      this._connectorStore.isConnectorsLoaded
    ) {
      // Only admins needs to set up the KB connectors and re authenticate them
      this.onBoarding = true;
      this._onboardingStore.updateCurrentStep(OnboardingSteps.KNOWLEDGE_BASES);
      this.routeForOnboardingStep();
    } else if (
      !this._connectorStore.hasTicketConnectors &&
      this.canAccessOnboarding &&
      this._connectorStore.isConnectorsLoaded
    ) {
      // Only admins needs to set up the ticket connectors and re authenticate them
      this.onBoarding = true;
      this._onboardingStore.updateCurrentStep(OnboardingSteps.SERVICE_PROVIDER);
      this.routeForOnboardingStep();
    } else {
      this.onBoarding = false;
      if (
        includes(this._routerStore.activeRoutes, onboardingPageRoute) ||
        includes(this._routerStore.activeRoutes, loginPageRoute) ||
        includes(this._routerStore.activeRoutes, globalUserPageRoute) ||
        includes(this._routerStore.activeRoutes, passwordResetRoute) ||
        includes(this._routerStore.activeRoutes, twoFactorAuthPageRoute) ||
        includes(this._routerStore.activeRoutes, ssoCallbackRoute)
      ) {
        this.routeForMainPage();
      } else {
        this._routerStore.history.push(this._routerStore.history.location);
      }
    }
  }

  @bind
  @action
  closeWhatsNewBanner() {
    this.showWhatsNew = false;
  }

  /**
   * When user toggles the what's new banner for first time update user record with current date as messages_last_read_at.
   * current_date with org timezone is used.
   * Toggle the view for whats new drawer
   */
  @bind
  @asyncAction
  *toggleWhatsNewBanner() {
    if (!isNil(this.latestMessageCreatedAt)) {
      const formattedLastMessageCreatedAt = this.latestMessageCreatedAt;
      this.showWhatsNew = !this.showWhatsNew;
      if (this.currentUserContext.messages_last_read_at !== formattedLastMessageCreatedAt) {
        try {
          yield userService.updateUserLastMessagesRead(this.latestMessageCreatedAt);
          this.currentUserContext.updateLastMessagesReadAt(formattedLastMessageCreatedAt);
        } catch (e) {
          recordErrors(e);
        }
      }
    }
  }

  @action
  initializeAPI(access_token) {
    this.tempUserEmail = null;
    this.tempUserPassword = null;
    this._authStore.setAccessToken(access_token, 86400);
  }

  @action
  setLoginError(loginError) {
    this.loginError = loginError;
  }

  routeForTwoFactorAuthPage = currentQueryParams => {
    let routeString = twoFactorAuthPageRoute.stringify({
      ...this._routerStore.activeRouteParams
    });
    routeString += currentQueryParams ? `?${qs.stringify(currentQueryParams)}` : '';
    this._routerStore.history.push(routeString);
  };

  routeForPasswordResetPage = () => {
    this._routerStore.history.push(
      passwordResetRoute.stringify({
        ...this._routerStore.activeRouteParams,
        orgId: this.userAssignedOrgId,
        orgPage: 'passwordReset'
      })
    );
  };

  routeForMainPage = () => {
    if (this.hasAssociatedOrgs) {
      this._routerStore.history.push(
        combinedOrgPageRoute.stringify({
          ...this._routerStore.activeRouteParams
        })
      );
    } else {
      this._routerStore.history.push(
        orgPageRoute.stringify({
          ...this._routerStore.activeRouteParams,
          orgId: this.userAssignedOrgId,
          orgPage: 'analytics'
        })
      );
    }
  };

  routeForOnboardingStep = () => {
    this._routerStore.history.push(
      onboardingPageRoute.stringify({
        ...this._routerStore.activeRouteParams,
        orgId: this.userAssignedOrgId,
        orgPage: 'onboarding'
      })
    );
  };

  goToLogin() {
    const currentQueryParams = this._routerStore.activeQueryParams;
    let loginRoute = loginPageRoute.stringify({
      ...this._routerStore.activeRouteParams,
      orgPage: 'login'
    });
    loginRoute += currentQueryParams ? `?${qs.stringify(currentQueryParams)}` : '';
    this._routerStore.history.push(loginRoute);
  }

  parseCronExpression(cronExpression: string): ICronSchedule {
    const cronSchedule: ICronSchedule = { schedule: cronExpression, type: ScheduleType.CUSTOM, timezone: 'UTC' };
    const [min, hour, dayOfMonth, month, dayOfWeek] = cronExpression.split(' ');
    if (dayOfMonth === '*' && month === '*' && dayOfWeek === '*' && min === '0') {
      cronSchedule.type = ScheduleType.DAILY;
      cronSchedule.hour = Number(hour);
    } else if (dayOfMonth === '*' && month === '*' && dayOfWeek !== '*' && min === '0') {
      cronSchedule.type = ScheduleType.WEEKLY;
      cronSchedule.hour = Number(hour);
      cronSchedule.days = dayOfWeek.split(',').map(Number);
    }
    return cronSchedule;
  }

  persistOrgStoreData(data: OrgStore['orgStoreDataToPersist']) {
    localStorage.setItem('orgStore', JSON.stringify(data));
  }

  deleteOrgStoreData() {
    localStorage.removeItem('orgStore');
  }

  @bind
  hasPermissionForOrg(orgId: number, orgPermission: OrgPermission): boolean {
    if (this.currentUserContext.isGlobalUser) {
      return true;
    }

    const { authorizationInfo, globalUserViewRoles, isActualGlobalUser } = this.currentUserContext;
    if (!authorizationInfo) {
      return false;
    }

    if (isActualGlobalUser && globalUserViewRoles) {
      let allPermissions: OrgPermission[] = [];
      for (const roleName of globalUserViewRoles) {
        allPermissions = allPermissions.concat(OrgGroupRoles[roleName].permissions.orgs);
      }
      return allPermissions.includes(orgPermission);
    } else {
      return (
        authorizationInfo.permissions.orgs[orgPermission] &&
        authorizationInfo.permissions.orgs[orgPermission].includes(orgId)
      );
    }
  }

  /**
   * Return true if user has any of the specified permissions for the current org
   */
  @bind
  hasAnyPermission(...orgPermissions: OrgPermission[]): boolean {
    return this.hasAnyPermissionForOrg(this.selectedOrFirstOrgId, ...orgPermissions);
  }

  /**
   * Return true if user has any of the specified permissions for the current org
   */
  @bind
  hasAnyPermissionForOrg(orgId: number, ...orgPermissions: OrgPermission[]): boolean {
    return orgPermissions.some(orgPermission => this.hasPermissionForOrg(orgId, orgPermission));
  }

  @bind
  hasAllPermissions(...orgPermissions: OrgPermission[]): boolean {
    return orgPermissions.every(orgPermission => this.hasPermissionForOrg(this.selectedOrFirstOrgId, orgPermission));
  }

  /**
   * Return true if user has the specified permissions for the current org
   */
  @bind
  hasPermission(orgPermission: OrgPermission): boolean {
    return this.hasAnyPermission(orgPermission);
  }

  isOrgActive(org: IOrgDetails): boolean {
    return org.settings && org.settings.is_active && !org.settings.shadow_for_org;
  }

  isOrgAccessible(org: IOrgDetails): boolean {
    return (
      this.isOrgActive(org) &&
      // only include orgs in picker if user has at least 1 permission for it
      this.hasAnyPermissionForOrg(
        org.id,
        OrgPermission.analyticsRead,
        OrgPermission.categoryInsightsRead,
        OrgPermission.connectorsRead,
        OrgPermission.dataDownloadRead,
        OrgPermission.exploreRead,
        OrgPermission.interfaceRead,
        OrgPermission.recommendationsRead,
        OrgPermission.recrawlHubRead,
        OrgPermission.settingsRead,
        OrgPermission.suggestionsRead,
        OrgPermission.usersRead,
        OrgPermission.votesRead,
        OrgPermission.workflowRead
      )
    );
  }

  @computed
  get loadingUserOrOrg() {
    return this.loadingUser || this.loadingOrgDetails;
  }

  @computed
  get orgStoreDataToPersist() {
    // we intentionally explicitly pick only a few selected properties from userOrg only, since we don't want to persist the api_clients
    // stored also under `userOrg` for security reasons.
    const { id, name, org_group_id } = this.userOrg;
    return {
      sessionStoreUserInfo: this.sessionStoreUserInfo,
      userOrg: { id, name, org_group_id }
    };
  }

  @computed
  get recrawlJobSchedule() {
    try {
      const recrawlSchedule = cronstrue.toString(this.sauron_cron_schedule.reingest_webpages_if_safe[0].schedule);
      const formattedRecrawlSchedule = recrawlSchedule.split(',');
      const displaySchedule = { time: formattedRecrawlSchedule[0], day: formattedRecrawlSchedule[1] };
      return displaySchedule;
    } catch (e) {
      return { time: '--', day: '--' };
    }
  }

  @computed
  get recrawlJobScheduleTimeZone() {
    try {
      return this.sauron_cron_schedule.reingest_webpages_if_safe[0].timezone;
    } catch (e) {
      return '--';
    }
  }

  @computed
  get nextRun() {
    try {
      const nextRun = cronParser
        .parseExpression(this.sauron_cron_schedule.reingest_webpages_if_safe[0].schedule, {
          tz: this.sauron_cron_schedule.reingest_webpages_if_safe[0].timezone
        })
        .next();
      if (nextRun && !this.sauron_cron_schedule.reingest_webpages_if_safe[0].disabled) {
        return moment.tz(nextRun.toISOString(), this.orgTimeZone).format(DATETIME_FORMAT);
      }
      return '--';
    } catch (e) {
      return '--';
    }
  }

  @computed
  get recrawlScheduleType() {
    try {
      return this.parseCronExpression(this.sauron_cron_schedule.reingest_webpages_if_safe[0].schedule).type;
    } catch (e) {
      return '--';
    }
  }

  @computed
  get orgTimeZone() {
    return this.orgSettings.timezone;
  }

  @computed
  get selectedOrgId() {
    return this._routerStore.activeOrgId;
  }

  @computed
  get selectedOrFirstOrgId() {
    return this.selectedOrgId || this.userAssignedOrgId;
  }

  @computed
  get hasAssociatedOrgs() {
    const filteredOrgGroups = this.userOrg.org_group.orgs.filter(org => this.isOrgAccessible(org));
    return filteredOrgGroups.length > 1;
  }

  @computed
  get allAccessibleActiveOrgsInGroup() {
    const filteredOrgGroups = this.userOrg.org_group.orgs.filter(org => this.isOrgAccessible(org));
    const orgs: any = [] as IOrgDetails;
    let filteredOrg: IOrgDetails;
    for (filteredOrg of filteredOrgGroups) {
      orgs.push({
        id: filteredOrg.id,
        name: filteredOrg.settings.org_display_name || filteredOrg.name
      });
    }
    return orgs;
  }

  @computed
  get allActiveOrgsInGroup(): IOrgDetails[] {
    const filteredOrgGroups = this.userOrg.org_group.orgs.filter(org => this.isOrgActive(org));
    const orgs: any = [] as IOrgDetails;
    let filteredOrg: IOrgDetails;
    for (filteredOrg of filteredOrgGroups) {
      orgs.push({
        ...filteredOrg,
        name: filteredOrg.settings.org_display_name || filteredOrg.name
      });
    }
    return orgs;
  }

  @computed
  get associatedOrgs() {
    const orgs: any = [] as IOrgDetails;
    if (!isEmpty(this.userOrg.org_group)) {
      let org: IOrgDetails;
      for (org of this.userOrg.org_group.orgs) {
        if (org.id !== this.selectedOrgId && this.isOrgAccessible(org)) {
          orgs.push({
            ...org,
            name: org.settings.org_display_name || org.name,
            selectedForOverViewReport: true
          });
        }
      }

      if (orgs.length >= 1) {
        orgs.push({
          id: 0,
          name: 'Combined Analytics',
          selectedForOverViewReport: false
        });
      }
    }
    return orgs;
  }

  @computed
  get currentOrgName() {
    if (this.userOrg.org_group && !isEmpty(this.userOrg.org_group.orgs) && this.selectedOrgId) {
      const selectedOrg: any = this.userOrg.org_group.orgs.find((org: any) => org.id === this.selectedOrgId);
      return selectedOrg ? selectedOrg.settings.org_display_name || selectedOrg.name : this.userOrg.name;
    }
    return this.userOrg.name;
  }

  @computed
  get modalApiClient() {
    const api_Client = this.userOrg.org_group.api_clients.find((client: any) => client.name === 'Modal');
    return api_Client ? Maybe.just(api_Client) : Maybe.nothing();
  }

  @computed
  get analyticsApiClient() {
    const api_Client = this.userOrg.org_group.api_clients.find((client: any) => client.name === 'Analytics API');
    return api_Client ? Maybe.just(api_Client) : Maybe.nothing();
  }

  @computed
  get modalApiClientKey() {
    const modalClient = this.modalApiClient.valueOr({}) as any;
    return modalClient.as_api_key;
  }

  @computed
  get currentOrgGroupId(): number {
    return this.userOrg.org_group_id;
  }

  @computed
  get canAccessDataDownload(): boolean {
    return this.hasPermission(OrgPermission.dataDownloadRead) || this.currentUserContext.isGlobalUser;
  }
  @computed
  get shouldShowArticleUrlWithTitle(): boolean {
    return this.orgSettings.display_url_with_article_title;
  }

  @computed
  get canAccessInsights(): boolean {
    return (
      (this.orgSettings.enable_insights_app && this.hasPermission(OrgPermission.categoryInsightsRead)) ||
      this.currentUserContext.isGlobalUser
    );
  }

  @computed
  get canAccessExplore(): boolean {
    return this.hasPermission(OrgPermission.exploreRead) || this.currentUserContext.isGlobalUser;
  }

  @computed
  get canAccessCoachApp() {
    return (
      this.orgSettings.enable_coach_app &&
      this.hasAllPermissions(
        OrgPermission.recommendationsRead,
        OrgPermission.recommendationsUpdate,
        OrgPermission.votesCreate
      ) &&
      !this._featuresStore.flags[FLAGS.enable_unified_coach]
    );
  }

  @computed
  get canAccessUnifiedCoach(): boolean {
    return (
      this.orgSettings.enable_coach_app &&
      this.hasAllPermissions(
        OrgPermission.recommendationsRead,
        OrgPermission.recommendationsUpdate,
        OrgPermission.votesCreate
      ) &&
      this._featuresStore.flags[FLAGS.enable_unified_coach]
    );
  }

  @computed
  get canAccessInterfaceSetup() {
    return this.hasPermission(OrgPermission.interfaceRead) || this.currentUserContext.isGlobalUser;
  }

  @computed
  get canAccessWorkflowBuilder(): boolean {
    return (
      (this.orgSettings.enable_workflow_builder && this.hasPermission(OrgPermission.workflowRead)) ||
      this.currentUserContext.isGlobalUser
    );
  }

  @computed
  get canAccessSurveyWorkflows() {
    return (
      this.canAccessWorkflowBuilder &&
      FeatureFlagService.flags[FEATURE_FLAGS.survey_workflows] &&
      this.orgSettings.enable_survey_workflows
    );
  }

  @computed
  get canAccessLauncherWidgetControls() {
    return this.orgSettings.enable_launcher_widget_controls;
  }

  @computed
  get canAccessSuggestionsEditor(): boolean {
    return this.hasPermission(OrgPermission.suggestionsRead) || this.currentUserContext.isGlobalUser;
  }

  @computed
  get canAccessWorkflowSpatialEditor(): boolean {
    return (
      (this.orgSettings.enable_wfb_spatial_editor && this.canAccessWorkflowBuilder) ||
      this.currentUserContext.isGlobalUser
    );
  }

  @computed
  get canUpdateWorkflow(): boolean {
    return this.hasPermission(OrgPermission.workflowUpdate);
  }

  @computed
  get canEditDataSourceSetup(): boolean {
    return (
      (this.orgSettings.enable_data_source_setup && this.hasPermission(OrgPermission.connectorsUpdate)) ||
      this.currentUserContext.isGlobalUser
    );
  }

  @computed
  get canEditUrlSuggestions(): boolean {
    return (
      (this.orgSettings.enable_url_suggestions &&
        this.hasAnyPermission(OrgPermission.suggestionsCreate, OrgPermission.suggestionsUpdate)) ||
      this.currentUserContext.isGlobalUser
    );
  }

  @computed
  get canAccessAnalyticsApi(): boolean {
    return (
      (this.orgSettings.enable_analytics_api && this.hasPermission(OrgPermission.analyticsRead)) ||
      this.currentUserContext.isGlobalUser
    );
  }

  @computed
  get canChangeBuilderWorkflowType(): boolean {
    return this.canAccessWorkflowBuilder && this.orgSettings.engage;
  }

  @computed
  get canAccessRecrawlHub(): boolean {
    return this.hasPermission(OrgPermission.recrawlHubRead);
  }

  @computed
  get canTriggerRecrawl() {
    return this.hasPermission(OrgPermission.recrawlHubUpdate) || this.currentUserContext.isGlobalUser;
  }

  @computed
  get canAccessSettings(): boolean {
    return this.hasAnyPermission(OrgPermission.settingsRead, OrgPermission.analyticsRead, OrgPermission.connectorsRead);
  }

  @computed
  get canAccessAnalytics(): boolean {
    return this.hasAnyPermission(OrgPermission.analyticsRead);
  }

  @computed
  get canAccessOnboarding(): boolean {
    return this.hasAllPermissions(
      OrgPermission.connectorsRead,
      OrgPermission.connectorsCreate,
      OrgPermission.connectorsUpdate
    );
  }

  @computed
  get canAccessSocialIntegrations() {
    return (
      (this.orgSettings.enable_social_channel_integration && this.hasPermission(OrgPermission.connectorsUpdate)) ||
      this.currentUserContext.isGlobalUser
    );
  }

  @computed
  get canAccessUserManagement(): boolean {
    return this.hasAnyPermission(OrgPermission.usersRead);
  }

  @computed
  get canAccessOrgRoles(): boolean {
    return this.orgSettings.enable_org_roles;
  }

  @computed
  get canAccessWfReporting(): boolean {
    return this.orgSettings.enable_workflow_insights;
  }

  @computed
  get canAccessAddonMarketplace(): boolean {
    return (
      (this.orgSettings.enable_addon_marketplace && this.hasPermission(OrgPermission.interfaceRead)) ||
      this.currentUserContext?.isGlobalUser
    );
  }

  @computed
  get canAccessIntents(): boolean {
    return (
      (this.orgSettings.enable_intents_layout && this.hasPermission(OrgPermission.admin)) ||
      this.currentUserContext?.isGlobalUser
    );
  }

  @computed
  get orgWhatsNewMessages() {
    if (this.allOrgMessageNodes) {
      return this.allOrgMessageNodes.map(
        messageNode =>
          new OrgMessage(
            messageNode.id,
            messageNode.subject,
            messageNode.is_active,
            messageNode.message_body,
            messageNode.created_at
          )
      );
    }
    return [];
  }

  @computed
  get latestMessageCreatedAt() {
    if (this.orgWhatsNewMessages && this.orgWhatsNewMessages.length > 0) {
      return this.orgWhatsNewMessages[0].created_at;
    }
    return null;
  }

  @computed
  get aNewMessageIsAvailable() {
    let lastReadAt = this.currentUserContext.messages_last_read_at;
    if (isNil(this.latestMessageCreatedAt)) {
      return false;
    }

    // explicity checking for null, instead of undefined, because null has meaning here.
    if (lastReadAt === null) {
      lastReadAt = new Date(0);
    }

    // compare latest message to last read to find if
    // new message is added
    return moment(this.latestMessageCreatedAt).isAfter(lastReadAt);
  }

  @computed
  get isUserLoggedIn() {
    return !isEmpty(this.currentUserContext);
  }

  @computed
  get currentOrgRoles(): OrgGroupRole[] {
    return isEmpty(this.currentUserContext) ? [] : this.currentUserContext.getRolesForOrg(this.selectedOrgId);
  }

  @computed
  get currentOrgRoleNames(): string[] {
    return this.currentOrgRoles.map(role => role.name);
  }
}
