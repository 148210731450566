import Icon from '@ant-design/icons';
import React from 'react';

const EllipsisSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 20 20">
    <title> ellipsis </title> <circle cx="10" cy="10" r="2" /> <circle cx="3" cy="10" r="2" />{' '}
    <circle cx="17" cy="10" r="2" />{' '}
  </svg>
);

const ShareSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 50 50">
    <path d="M 5 44.996094 C 4.984375 44.996094 4.972656 44.996094 4.957031 44.996094 C 4.417969 44.972656 3.992188 44.527344 4 43.988281 C 4.003906 43.742188 4.578125 19.679688 28 19.015625 L 28 12 C 28 11.613281 28.222656 11.261719 28.570313 11.097656 C 28.914063 10.929688 29.332031 10.980469 29.628906 11.222656 L 45.628906 24.222656 C 45.863281 24.414063 46 24.699219 46 25 C 46 25.300781 45.863281 25.585938 45.632813 25.777344 L 29.632813 38.777344 C 29.332031 39.019531 28.917969 39.066406 28.570313 38.902344 C 28.222656 38.738281 28 38.386719 28 38 L 28 31.007813 C 7.160156 31.386719 6.035156 43.542969 5.996094 44.066406 C 5.960938 44.59375 5.523438 44.996094 5 44.996094 Z" />
  </svg>
);

const ExternalLinkSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 20 20">
    <path d="M13 9.5h-.1c-.4 0-.7.3-.7.7v5.2H2.7V5.9h4.7c.4 0 .7-.3.7-.7v-.1c0-.4-.3-.7-.7-.7H1.9c-.2 0-.4.1-.5.2s-.2.3-.2.5v11.1c0 .4.3.7.7.7H13c.4 0 .7-.3.7-.7v-6c0-.4-.3-.7-.7-.7z" />
    <path d="M16.4 1.1h-5.8c-.4 0-.7.3-.7.7v.1c0 .4.3.7.7.7h4L6 11.1c-.3.3-.3.7 0 1l.1.1c.3.3.7.3 1 0l8.5-8.5v4c0 .4.3.7.7.7h.1c.4 0 .7-.3.7-.7V1.8c0-.4-.3-.7-.7-.7z" />
  </svg>
);

const BottomLeftSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
    <path d="M1 15H0.5V15.5H1V15ZM0.5 1V1.5H1.5V1H0.5ZM1.5 4V3.5H0.5V4H1.5ZM0.5 6V6.5H1.5V6H0.5ZM1.5 9V8.5H0.5V9H1.5ZM0.5 11V11.5H1.5V11H0.5ZM1.5 14V13.5H0.5V14H1.5ZM2 15.5H2.5V14.5H2V15.5ZM5 14.5H4.5V15.5H5V14.5ZM7 15.5H7.5V14.5H7V15.5ZM10 14.5H9.5V15.5H10V14.5ZM12 15.5H12.5V14.5H12V15.5ZM15 14.5H14.5V15.5H15V14.5ZM0.5 0V1H1.5V0H0.5ZM0.5 4V6H1.5V4H0.5ZM0.5 9V11H1.5V9H0.5ZM0.5 14V15H1.5V14H0.5ZM1 15.5H2V14.5H1V15.5ZM5 15.5H7V14.5H5V15.5ZM10 15.5H12V14.5H10V15.5ZM15 15.5H16V14.5H15V15.5Z" />
    <rect x="5" y="3" width="8" height="8" />
  </svg>
);

const BottomRightSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
    <path d="M15 15H15.5V15.5H15V15ZM15.5 1V1.5H14.5V1H15.5ZM14.5 4V3.5H15.5V4H14.5ZM15.5 6V6.5H14.5V6H15.5ZM14.5 9V8.5H15.5V9H14.5ZM15.5 11V11.5H14.5V11H15.5ZM14.5 14V13.5H15.5V14H14.5ZM14 15.5H13.5V14.5H14V15.5ZM11 14.5H11.5V15.5H11V14.5ZM9 15.5H8.5V14.5H9V15.5ZM6 14.5H6.5V15.5H6V14.5ZM4 15.5H3.5V14.5H4V15.5ZM1 14.5H1.5V15.5H1V14.5ZM15.5 0V1H14.5V0H15.5ZM15.5 4V6H14.5V4H15.5ZM15.5 9V11H14.5V9H15.5ZM15.5 14V15H14.5V14H15.5ZM15 15.5H14V14.5H15V15.5ZM11 15.5H9V14.5H11V15.5ZM6 15.5H4V14.5H6V15.5ZM1 15.5H0V14.5H1V15.5Z" />
    <rect width="8" height="8" transform="matrix(-1 0 0 1 11 3)" />
  </svg>
);

export const EllipsisIcon = props => <Icon component={EllipsisSvg} {...props} />;
export const ShareIcon = props => <Icon component={ShareSvg} {...props} />;
export const ExternalLinkIcon = props => <Icon component={ExternalLinkSvg} {...props} />;
export const BottomLeftIcon = props => <Icon component={BottomLeftSvg} {...props} />;
export const BottomRightIcon = props => <Icon component={BottomRightSvg} {...props} />;
