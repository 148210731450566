import { mergeWith } from 'lodash';
import { action, computed, observable } from 'mobx';
import { customMergeSuggestions } from './Util';

export class BaseUiInstance {
  static createRaw(instance: BaseUiInstance) {
    const myRaw = instance.raw;

    return mergeWith(
      {},
      myRaw,
      {
        name: instance.name,
        enabled: instance.enabled,
        active: instance.active
      },
      customMergeSuggestions
    );
  }

  @observable
  active: boolean;

  @observable
  enabled: boolean;

  @observable
  id: string;

  @observable
  name: string | undefined;

  constructor(public raw: any, public configuration) {}

  @action
  applyFromRaw(raw: any) {
    this.id = raw.id;
    this.raw = raw;
    this.enabled = Boolean(raw.enabled);
    this.active = Boolean(raw.active);
    this.name = raw.name;
  }

  @computed
  get displayName() {
    if (this.name) {
      return this.name.charAt(0).toUpperCase() + this.name.slice(1);
    }

    return 'Default';
  }

  @computed
  get orgId(): number {
    return this.raw.orgId;
  }

  // overridden by BaseSolvvyUiInstance to return true
  @computed
  get isSolvvyUiInstance() {
    return false;
  }
}
