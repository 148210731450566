import BOT_MESSAGING_DEFAULT_TEXT from '@solvvy/locales/en/bot-messaging';
import V5_PLUGINS_DEFAULT from '@solvvy/locales/en/plugins';
import V4_UI_OPTION_DEFAULTS from '@solvvy/locales/en/v4-ui/v4-ui';
import V5_RESOLVE_FEED_DEFAULTS from '@solvvy/locales/en/v5-ui/ResolveFeed';
import V5_UI_OPTION_DEFAULTS from '@solvvy/locales/en/v5-ui/v5-ui';
import { OrgPermission } from '@solvvy/util/lib/authorization';
import { message } from 'antd';
import flat from 'flat';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import set from 'lodash/set';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import { action, computed, observable, reaction, runInAction, toJS } from 'mobx';
import { FULFILLED } from 'mobx-utils';
import { InstanceTab } from 'src/routes/InterfaceSetup/constants';
import { WORKFLOW_PURPOSE } from 'src/routes/Workflows/constants';
import { resolveUiConfigurationEditRoute } from '../routes/routes';
import asyncComputed from '../shared/util/asyncComputed';
import { NotificationManager } from '../shared/util/NotificationManager';
import { OrgStore } from './orgStore';
import { BaseUiInstance } from './ResolveUI/BaseUiInstance';
import { TEXT_OVERRIDE_TYPES } from './ResolveUI/constants';
import { ConversationalUiInstance } from './ResolveUI/ConversationalUiInstance';
import { ISuggestion } from './ResolveUI/Suggestion';
import { SunshineUiInstance } from './ResolveUI/SunshineUiInstance';
import { DialogType, IConfigurationPath, IPane, ITextGroup, UiMajorVersion } from './ResolveUI/types';
import { IUnpublishedChanges, UiConfiguration, UiConfigurationState } from './ResolveUI/UiConfiguration';
import { UiInstance } from './ResolveUI/UiInstance';
import { getTextOverridePaths } from './ResolveUI/Util';
import { IWorkflow } from './ResolveUI/Workflow';
import routerStore from './routerStore';
import { SuggestionsStore } from './suggestionsStore';
import { WorkflowStore } from './workflowStore';

export const MAX_UNDO_HISTORY = 25;

interface IUndoHistory {
  lastSaveSource?: ISaveSource;
  history: UiConfiguration[];
  undoIndex?: number;
}

export enum ISaveSource {
  CONFIG_MANAGER = 'config-manager',
  WORKFLOWS = 'workflows'
}

export enum SlideoutOverlayContentType {
  Announcement = 'announcement',
  Solution = 'solution'
}

export interface ISaveOptions {
  skipUndo?: boolean;
  customWorkflowToSave?: IWorkflow;
  customConfigurationToSave?: UiConfiguration;
}

const TEXT_GROUPS_TO_EXCLUDE = ['Greeting Card'];

export class ResolveUiStore {
  @observable
  resolveUiConfigurations = asyncComputed(() => {
    const orgGroupId = this._orgStore.currentOrgGroupId;
    if (!orgGroupId) {
      return Promise.resolve([]);
    }
    return this.loadResolveUiConfigurationsForGroup(orgGroupId);
  });

  autoSaveConfigManager = debounce(() => runInAction(() => this.saveConfigManager()), 500);
  pendingAutoSave: boolean;

  @observable
  autoSaving: boolean;

  @observable
  lastAutoSavedAt?: string;

  @observable
  undoHistories: { [name: string]: IUndoHistory } = {};

  @observable
  previewSlideoutOverlay: { show: boolean; contentType?: SlideoutOverlayContentType } = { show: false };

  // used to disable messages by tests since they are interferring
  disableMessages: boolean = false;

  _workflowStore: WorkflowStore;

  _suggestionsStore: SuggestionsStore;

  constructor(
    private _routerStore = routerStore,
    private _orgStore: OrgStore,
    public notificationManager: NotificationManager,
    public resolveUiConfigurationService: any
  ) {
    reaction(
      () => this._orgStore.selectedOrgId,
      () => {
        this.resolveUiConfigurations.refresh();
      }
    );
  }

  retrieveConfigValue(text) {
    const value = this.configurableGreetingForm[0].configTexts.find(configText => configText.key === text);
    return value?.overrideText || value?.defaultText;
  }

  retrieveSupportOptionConfigValue(text) {
    const value = this.configurableSupportOption[0].configTexts.find(configText => configText.key === text);
    return value.overrideText || value.defaultText;
  }

  retrieveChannelOptionConfigValue(text) {
    const value = this.configurableChannelOptionsMenuCard[0].configTexts.find(configText => configText.key === text);
    return value?.overrideText || value?.defaultText;
  }

  @action
  editResolveUiConfiguration(resolveUiConfigurationName: string | undefined) {
    if (resolveUiConfigurationName) {
      this._routerStore.history.push(
        resolveUiConfigurationEditRoute.stringify({
          ...this._routerStore.activeRouteParams,
          resolveUiConfigurationName
        })
      );
    }
  }

  getConfigManagerUiConfigurationPaths(
    customUiInstance?: UiInstance | ConversationalUiInstance | SunshineUiInstance,
    customInstanceIndex?: string | number
  ): IConfigurationPath[] {
    const uiInstance = customUiInstance || this.uiInstanceToEdit;
    const instanceIndex = customInstanceIndex !== undefined ? customInstanceIndex : this.uiInstanceIndex;
    const instancePrefix = `instances[${instanceIndex}]`;

    if (!uiInstance) {
      throw new Error('no instance');
    }

    if (uiInstance instanceof SunshineUiInstance) {
      return this.getConfigManagerUiConfigurationPathsForSunshineUiInstance(uiInstance, instancePrefix);
    }

    return this.getConfigManagerUiConfigurationPathsForSolvvyUiInstance(uiInstance!, instancePrefix);
  }

  getConfigManagerUiConfigurationPathsForSunshineUiInstance(
    uiInstance: SunshineUiInstance,
    instancePrefix: string
  ): IConfigurationPath[] {
    const textPaths = getTextOverridePaths(this.sunshineInstanceTextGroups);

    return [
      { path: `${instancePrefix}.socialChannelOptions.botDisplayName` },
      { path: `${instancePrefix}.socialChannelOptions.botAvatarUrl` },
      { path: `${instancePrefix}.feedOptions.channelOptions` },
      { path: `${instancePrefix}.feedOptions.channelOptionSchedules` },

      ...textPaths.map(pathObj => ({
        ...pathObj,
        path: `${instancePrefix}.socialChannelOptions.text.${pathObj.path}`
      }))
    ];
  }

  getConfigManagerUiConfigurationPathsForSolvvyUiInstance(
    uiInstance: UiInstance | ConversationalUiInstance,
    instancePrefix: string
  ): IConfigurationPath[] {
    const commonConfigPaths = [
      { path: `${instancePrefix}.colors` },
      { path: `${instancePrefix}.announcement` },
      ...(uiInstance.plugins?.map((_, index) => ({ path: `${instancePrefix}.plugins[${index}].options.text` })) || [])
    ];

    const v4Paths = [
      ...commonConfigPaths,
      { path: `${instancePrefix}.supportOptions` },
      { path: `${instancePrefix}.supportOptionSchedules` },
      ...UiInstance.getV4TextOverridePaths(
        uiInstance as UiInstance,
        [...this.configurableGreetingForm, ...this.configurableSupportOption],
        `${instancePrefix}.ui.`
      )
    ];
    const v5Paths = [
      ...commonConfigPaths,
      { path: `${instancePrefix}.feedOptions.channelOptions` },
      { path: `${instancePrefix}.feedOptions.channelOptionSchedules` },
      { path: `${instancePrefix}.widget` },
      { path: `${instancePrefix}.position` },
      { path: `${instancePrefix}.feedOptions.greetings` },
      { path: `${instancePrefix}.avatar` },
      { path: `${instancePrefix}.solutionsSettings` },
      { path: `${instancePrefix}.surveySettings` },
      ...getTextOverridePaths(this.configurableTextGroupsV5, `${instancePrefix}.text.`),
      ...getTextOverridePaths(this.configurableResolveFeedGroupsV5, `${instancePrefix}.feedOptions.text.`)
    ];

    // partially publish specified resolve UI configuration _.get paths (of raw JSON)
    return uiInstance.currentInstanceIsV4Version ? v4Paths : v5Paths;
  }

  getConfigManagerUnpublishedChanges(uiInstance?: UiInstance, instanceIndex?: string | number): IUnpublishedChanges[] {
    const configuration = this.uiConfigurationToEdit;
    if (!configuration) {
      throw new Error('no active configuration');
    }
    const paths = this.getConfigManagerUiConfigurationPaths(uiInstance, instanceIndex);
    return configuration.getPartiallyUnpublishedChanges(paths);
  }

  getConfigManagerHasUnpublishedChanges(uiInstance?: UiInstance, instanceIndex?: string | number): boolean {
    return (
      this.getConfigManagerUnpublishedChanges(uiInstance, instanceIndex).filter(change => change.changed).length > 0
    );
  }

  currentUserCanEditUiConfiguration() {
    return this._orgStore.hasAnyPermission(
      OrgPermission.interfaceUpdate,
      OrgPermission.suggestionsCreate,
      OrgPermission.suggestionsUpdate,
      OrgPermission.suggestionsDelete,
      OrgPermission.workflowCreate,
      OrgPermission.workflowUpdate,
      OrgPermission.workflowDelete
    );
  }

  @action
  async revertConfigManagerUnpublished(uiInstance?: UiInstance, instanceIndex?: string | number): Promise<void> {
    if (!this.currentUserCanEditUiConfiguration()) {
      return;
    }

    const configuration = this.uiConfigurationToEdit;
    if (!configuration) {
      throw new Error('no active configuration');
    }

    const configPaths = this.getConfigManagerUiConfigurationPaths(uiInstance, instanceIndex);
    const isSuccess = await configuration.partiallyRevertUnpublishedChanges(configPaths, {
      disableNotification: this.disableMessages
    });
    // don't refresh unless successfully saved (to prevent losing changes)
    if (isSuccess) {
      this.resolveUiConfigurations.refresh();
    }
  }

  @action
  async saveUiConfiguration(saveSource: ISaveSource, options: ISaveOptions = {}): Promise<void> {
    if (!this.currentUserCanEditUiConfiguration()) {
      return;
    }

    const configuration = options.customConfigurationToSave || this.uiConfigurationToEdit;
    if (!configuration) {
      return;
    }

    if (!options.skipUndo && this.uiConfigurationToEdit) {
      this.addToUndoHistory(saveSource);
    }

    // if an autosave or update is already in progress then do nothing but queue an autosave to occur after
    if (this.pendingAutoSave || configuration.submittingPreview) {
      this.pendingAutoSave = true;
      return;
    }

    // perform save without big corner notification
    this.autoSaving = true;
    try {
      await configuration.update(UiConfigurationState.PREVIEW, true);
      runInAction(() => (this.lastAutoSavedAt = new Date().toLocaleString()));
    } finally {
      runInAction(() => (this.autoSaving = false));
    }

    // if some other event triggered an autosave while it was autosaving something previous, then trigger another final autosave
    if (this.pendingAutoSave) {
      this.pendingAutoSave = false;
      // can always skip undo on the pending save because it would have already been done
      await this.saveUiConfiguration(saveSource, { ...options, skipUndo: true });
    }
  }

  // add change to undo history
  @action
  addToUndoHistory(saveSource: ISaveSource): void {
    if (!this.uiConfigurationToEdit) {
      throw new Error('no current configuration');
    }

    const { name } = this.uiConfigurationToEdit;

    let undoHistory = this.undoHistories[name];
    if (!undoHistory) {
      undoHistory = { history: [] };
      this.undoHistories[name] = undoHistory;
    }

    // if last saved source is different, delete all but most recent history
    // (e.g. we don't want undo inside one area like workflows to affect another area like suggestions)
    if (undoHistory.lastSaveSource && undoHistory.lastSaveSource !== saveSource) {
      undoHistory.history.splice(0, undoHistory.history.length - 1);
    }
    undoHistory.lastSaveSource = saveSource;

    // clone config and parse+stringify to remove mobx wrappers
    undoHistory.history.push(JSON.parse(JSON.stringify(this.uiConfigurationToEdit.toRaw())));
    if (undoHistory.history.length > MAX_UNDO_HISTORY) {
      undoHistory.history.shift();
    }

    undoHistory.undoIndex = undoHistory.history.length - 2;
  }

  @action
  async undoLastSave(saveSource: ISaveSource): Promise<void> {
    if (!this.uiConfigurationToEdit) {
      throw new Error('no current configuration');
    }
    const { name } = this.uiConfigurationToEdit;
    const undoHistory = this.undoHistories[name];
    if (!undoHistory || undoHistory.undoIndex === undefined) {
      throw new Error('nothing to undo');
    }

    // read from history and parse+stringify to remove mobx wrappers
    const raw = JSON.parse(JSON.stringify(undoHistory.history[undoHistory.undoIndex!]));
    const origLockVersion = this.uiConfigurationToEdit.lock_version;
    this.uiConfigurationToEdit.setFieldsFromRaw(raw);
    this.uiConfigurationToEdit.lock_version = origLockVersion;

    undoHistory.undoIndex = undoHistory.undoIndex! - 1;
    if (!this.disableMessages) {
      message.success('Undo succeeded');
    }

    await this.saveUiConfiguration(saveSource, { skipUndo: true });
  }

  @action
  async redoLastUndo(saveSource: ISaveSource): Promise<void> {
    if (!this.uiConfigurationToEdit) {
      throw new Error('no current configuration');
    }
    const { name } = this.uiConfigurationToEdit;
    const undoHistory = this.undoHistories[name];
    if (
      !undoHistory ||
      undoHistory.undoIndex === undefined ||
      undoHistory.undoIndex >= undoHistory.history.length - 1
    ) {
      throw new Error('nothing to redo');
    }

    // read from history and parse+stringify to remove mobx wrappers
    const raw = JSON.parse(JSON.stringify(undoHistory.history[undoHistory.undoIndex + 2]));
    const origLockVersion = this.uiConfigurationToEdit.lock_version;
    this.uiConfigurationToEdit.setFieldsFromRaw(raw);
    this.uiConfigurationToEdit.lock_version = origLockVersion;

    undoHistory!.undoIndex = undoHistory!.undoIndex! + 1;
    if (!this.disableMessages) {
      message.success('Redo succeeded');
    }

    await this.saveUiConfiguration(saveSource, { skipUndo: true });
  }

  getCanUndo(saveSource: ISaveSource) {
    if (!this.uiConfigurationToEdit) {
      throw new Error('no current configuration');
    }
    const undoHistory = this.undoHistories[this.uiConfigurationToEdit.name];
    return (
      undoHistory &&
      undoHistory.history.length > 1 &&
      undoHistory.undoIndex !== undefined &&
      undoHistory.undoIndex >= 0 &&
      (undoHistory.lastSaveSource === undefined || undoHistory.lastSaveSource === saveSource)
    );
  }

  getCanRedo(saveSource: ISaveSource) {
    if (!this.uiConfigurationToEdit) {
      throw new Error('no current configuration');
    }
    const undoHistory = this.undoHistories[this.uiConfigurationToEdit.name];
    return (
      undoHistory &&
      undoHistory.undoIndex !== undefined &&
      undoHistory.undoIndex + 2 < undoHistory.history.length &&
      (undoHistory.lastSaveSource === undefined || undoHistory.lastSaveSource === saveSource)
    );
  }

  @action
  async saveConfigManager(): Promise<void> {
    if (!this.uiInstanceToEdit) {
      throw new Error('no current instance');
    }

    set(this.uiInstanceToEdit, 'options.configManagerLastModifiedAt', new Date());

    await this.saveUiConfiguration(ISaveSource.CONFIG_MANAGER);
  }

  @action
  async publishConfigManager(): Promise<boolean> {
    if (!this.currentUserCanEditUiConfiguration()) {
      return false;
    }

    if (!this.uiConfigurationToEdit || !this.uiInstanceToEdit) {
      throw new Error('no current instance');
    }

    set(this.uiInstanceToEdit, 'options.configManagerLastModifiedAt', new Date());

    // partially publish specified resolve UI configuration _.get paths (of raw JSON)
    const configManagerPaths = this.getConfigManagerUiConfigurationPaths(this.uiInstanceToEdit, this.uiInstanceIndex);
    return this.uiConfigurationToEdit.partiallyPublish(configManagerPaths, {
      disableNotification: this.disableMessages
    });
  }

  @action
  async undoLastSaveConfigManager() {
    this.autoSaveConfigManager.flush();
    if (this.canUndoConfigManager) {
      await this.undoLastSave(ISaveSource.CONFIG_MANAGER);
    }
  }

  @action
  async redoLastUndoConfigManager() {
    this.autoSaveConfigManager.flush();
    if (this.canRedoConfigManager) {
      await this.redoLastUndo(ISaveSource.CONFIG_MANAGER);
    }
  }

  @action
  showSlideoutOverlay = (show: boolean, contentType?: SlideoutOverlayContentType) => {
    this.previewSlideoutOverlay = { show, contentType };
  };

  getUiConfigurationByName(name: string): UiConfiguration | null {
    if (this.resolveUiConfigurations.state === FULFILLED) {
      const resolveUiConfiguration = this.resolveUiConfigurations.value.find(uiConfig => uiConfig.name === name);
      return resolveUiConfiguration || null;
    }
    return null;
  }

  getUiInstanceByConfigurationNameAndIndex(
    name: string,
    instanceIndex?: string | number
  ): UiInstance | ConversationalUiInstance | SunshineUiInstance | null {
    const uiConfiguration = this.getUiConfigurationByName(name);
    if (uiConfiguration && uiConfiguration.instances && instanceIndex !== undefined) {
      return uiConfiguration.instances[Number(instanceIndex)];
    }
    return null;
  }

  getUiInstanceById(id: string): UiInstance | undefined {
    if (this.resolveUiConfigurations.state === FULFILLED) {
      for (const configuration of this.resolveUiConfigurations.value) {
        const instance = configuration.instances.find(i => i.raw.id === id);
        if (instance) {
          return instance;
        }
      }
    }
    return undefined;
  }

  getOtherOrgUiInstanceById(id: string): any | undefined {
    if (this.resolveUiConfigurations.state === FULFILLED) {
      for (const configuration of this.resolveUiConfigurations.value) {
        const instance = configuration.restOfTheOrgInstances.find(i => i.id === id);
        if (instance) {
          return instance;
        }
      }
    }
    return undefined;
  }

  getUiMajorVersionById(id: string): UiMajorVersion | undefined {
    if (this.resolveUiConfigurations.state === FULFILLED) {
      for (const configuration of this.resolveUiConfigurations.value) {
        const allInstances = [...configuration.instances, ...configuration.restOfTheOrgInstances];
        const instance = allInstances.find(i => i.id === id);
        if (instance) {
          return instance.uiMajorVersion;
        }
      }
    }
    return undefined;
  }

  getOrgIdByUiInstanceId(id: string): number | undefined {
    if (this.resolveUiConfigurations.state === FULFILLED) {
      for (const configuration of this.resolveUiConfigurations.value) {
        const allInstances = [...configuration.instances, ...configuration.restOfTheOrgInstances];
        const instance = allInstances.find(i => i.id === id);
        if (instance) {
          return instance.orgId;
        }
      }
    }
    return undefined;
  }

  getUiInstanceIndexById(id: string): number | undefined {
    if (this.resolveUiConfigurations.state === FULFILLED) {
      for (const configuration of this.resolveUiConfigurations.value) {
        const index = configuration.instances.findIndex(i => i.raw.id === id);
        if (index > -1) {
          return index;
        }
      }
    }
    return undefined;
  }

  getUiConfigurationByInstanceId(instanceId: string): UiConfiguration | undefined {
    if (this.resolveUiConfigurations.state === FULFILLED) {
      for (const configuration of this.resolveUiConfigurations.value) {
        const instance = configuration.instances.find(i => i.raw.id === instanceId);
        if (instance) {
          return configuration;
        }
      }
    }
    return undefined;
  }

  getUiInstanceIds(): string[] {
    let instanceIds: string[] = [];
    if (this.resolveUiConfigurations.state === FULFILLED) {
      for (const configuration of this.resolveUiConfigurations.value) {
        instanceIds = instanceIds.concat(configuration.instances.map(instance => instance.raw.id));
      }
    }
    return instanceIds;
  }

  getConfigurationNamePrefix(uiConfiguration: UiConfiguration): string {
    if (this.resolveUiConfigurations.fulfilled && this.resolveUiConfigurations.value.length > 1) {
      return `${uiConfiguration.name} - `;
    }

    return '';
  }

  getUiInstanceDisplayName(
    uiConfiguration: UiConfiguration | undefined,
    uiInstance: BaseUiInstance | undefined
  ): string {
    if (!uiConfiguration || !uiInstance) {
      return '';
    }

    const configurationPrefix = this.getConfigurationNamePrefix(uiConfiguration);
    let instanceDisplayName = configurationPrefix + (uiInstance.name || 'Default');
    instanceDisplayName = uiInstance.active ? instanceDisplayName : `${instanceDisplayName} (inactive)`;
    return instanceDisplayName;
  }

  getResolveFeedOverrides(uiInstanceId?: string) {
    const resolveFeedOverrides = cloneDeep(this.resolvFeedDefaults);
    const instance = uiInstanceId ? this.getUiInstanceById(uiInstanceId) : null;
    const overrides = {};
    if (instance) {
      merge(overrides, {
        ...(instance.textOverrides ? toJS(instance.textOverrides) : {}),
        ...(instance.resolveFeedTextOverrides ? toJS(instance.resolveFeedTextOverrides) : {})
      });
      if (!isEmpty(overrides)) {
        Object.entries(flat(this.resolvFeedDefaults)).map(([key, value]) => {
          const overriddenValue = get(overrides, key, value);
          set(resolveFeedOverrides, key, overriddenValue);
        });
      }
    }
    return resolveFeedOverrides;
  }

  getOverrideTextBasedOnType(type, item, pluginId?: string) {
    const uiInstance = this.uiInstanceToEdit;

    if (TEXT_OVERRIDE_TYPES.TEXT === type) {
      return uiInstance?.textOverrides.get(item.fullKey);
    } else if (TEXT_OVERRIDE_TYPES.RESOLVE_FEED === type) {
      return this.solvvyUiInstanceToEdit?.resolveFeedTextOverrides.get(item.fullKey);
    } else if (TEXT_OVERRIDE_TYPES.ROOT === type) {
      return uiInstance?.textOverrides.get(item.key);
    } else if (TEXT_OVERRIDE_TYPES.PLUGINS === type) {
      const matchedPlugin = this.plugins.find(
        plugin => plugin.id === pluginId && plugin.options && plugin.options.text
      );
      return matchedPlugin?.options.text[item.key];
    } else if (TEXT_OVERRIDE_TYPES.RESOLVE_FEED_CH_OPTIONS === type) {
      const matchedOption = this.solvvyUiInstanceToEdit?.supportOptions.find(plugin => plugin.id === pluginId);
      return get(matchedOption?.getRawText(), item.key);
    }
  }

  getInstanceDefaultTabId(name, instanceIndex) {
    const panes = this.getInstanceTabs(name, instanceIndex);
    return panes[0].id;
  }

  getInstanceTabs(configurationName, instanceIndex): IPane[] {
    const { hasPermission } = this._orgStore;

    const uiInstance = this.getUiInstanceByConfigurationNameAndIndex(configurationName, instanceIndex)!;

    if (uiInstance instanceof SunshineUiInstance) {
      const sunshineInstancePanes: IPane[] = [
        {
          id: 'bot-setup',
          menuItem: 'Bot Setup'
        }
      ];

      if (this._orgStore.currentUserContext.isGlobalUser) {
        sunshineInstancePanes.push({
          id: 'interface-text',
          menuItem: 'Interface Text'
        });
      }

      uiInstance.hasSupportOptions &&
        this.showSupportOptionTexts &&
        sunshineInstancePanes.push({
          menuItem: 'Support Options',
          id: InstanceTab.SUPPORT_OPTIONS
        });

      return sunshineInstancePanes;
    }

    const isDialogTypeConversational = uiInstance.dialogType === DialogType.Conversational;
    let panes: IPane[] = [
      {
        menuItem: uiInstance.isDialogTypeProfessional ? 'Welcome Text' : 'Greeting',
        id: InstanceTab.GREETING,
        uiVersions: [UiMajorVersion.V4, UiMajorVersion.V5]
      }
    ];

    panes.push(
      isDialogTypeConversational
        ? {
            menuItem: 'Welcome Panel',
            id: InstanceTab.WELCOME_PANEL,
            uiVersions: [UiMajorVersion.V5]
          }
        : {
            menuItem: 'Announcement',
            id: InstanceTab.ANNOUNCEMENT,
            uiVersions: [UiMajorVersion.V4, UiMajorVersion.V5]
          }
    );

    (hasPermission(OrgPermission.interfaceUpdate) || hasPermission(OrgPermission.interfaceRead)) &&
      isDialogTypeConversational &&
      panes.splice(0, 0, {
        menuItem: 'Look & Feel',
        id: InstanceTab.LOOK_AND_FEEL,
        uiVersions: [UiMajorVersion.V5]
      });

    uiInstance.isDialogTypeProfessional &&
      panes.splice(1, 0, {
        menuItem: 'Colors',
        id: InstanceTab.COLORS,
        uiVersions: [UiMajorVersion.V4, UiMajorVersion.V5]
      });

    this._orgStore.currentUserContext.isGlobalUser &&
      (!isDialogTypeConversational || !this._orgStore.canAccessLauncherWidgetControls) &&
      (!uiInstance.raw.hasOwnProperty('showWidget') || uiInstance.raw.showWidget) &&
      panes.splice(2, 0, {
        menuItem: 'Launcher',
        id: InstanceTab.LAUNCHER,
        uiVersions: [UiMajorVersion.V5]
      });

    uiInstance.hasSupportOptions &&
      this.showSupportOptionTexts &&
      panes.splice(3, 0, {
        menuItem: 'Support Options',
        id: InstanceTab.SUPPORT_OPTIONS,
        uiVersions: [UiMajorVersion.V4, UiMajorVersion.V5]
      });

    if (this._orgStore.currentUserContext.isGlobalUser) {
      panes = panes.concat(
        {
          menuItem: 'Exclusions',
          id: InstanceTab.EXCLUSIONS,
          uiVersions: [UiMajorVersion.V5]
        },
        {
          menuItem: 'Interface Text',
          id: InstanceTab.INTERFACE_TEXT,
          uiVersions: [UiMajorVersion.V5]
        }
      );
    }

    if (isDialogTypeConversational) {
      panes.push({
        menuItem: 'Solutions Settings',
        id: InstanceTab.SOLUTIONS_SETTINGS,
        uiVersions: [UiMajorVersion.V5]
      });

      this._orgStore.canAccessSurveyWorkflows &&
        panes.push({
          menuItem: 'Survey Settings',
          id: InstanceTab.SURVEY_SETTINGS,
          uiVersions: [UiMajorVersion.V5]
        });
    }

    uiInstance.hasCustomCss &&
      panes.push({
        menuItem: 'Custom CSS',
        id: InstanceTab.CUSTOM_CSS,
        uiVersions: [UiMajorVersion.V4, UiMajorVersion.V5]
      });

    panes = panes.filter(pane => !pane.uiVersions || pane.uiVersions.includes(uiInstance.uiMajorVersion));
    return panes;
  }

  getWorkflows(): IWorkflow[] {
    if (this.uiInstanceToEdit && this._workflowStore.workflows.fulfilled) {
      const workflowsForInstance = this._workflowStore.workflows.value.filter(workflow =>
        workflow.workingVersion.uiInstanceIds.includes(this.uiInstanceToEdit!.id)
      );
      return sortBy(workflowsForInstance, 'name');
    }

    return [];
  }

  getSurveyWorkflows() {
    return this.getWorkflows().filter(workflow => workflow.purpose === WORKFLOW_PURPOSE.SURVEY);
  }

  private async loadResolveUiConfigurationsForGroup(groupId: number): Promise<UiConfiguration[]> {
    const orgConfigs = await this.resolveUiConfigurationService.loadResolveUiConfiguration(groupId);

    /**
     * For each org configs filter out instances based on selected org id or enabled status
     */
    for (const config of orgConfigs) {
      const filter = instance => instance.orgId === this._orgStore.selectedOrgId && instance.enabled;
      const filteredInstances = config.instances.filter(filter);
      // get rest of the org instances which will be saved to database
      const nonFilteredInstances = config.instances.filter(instance => !filter(instance));
      /**
       * If we dont find org specific instance then look if the configurations has personas. If we match persona org to
       * the selected org then we show the same instance.
       */
      if (isEmpty(filteredInstances)) {
        for (const instance of config.instances) {
          if (instance.personas && instance.personas.find(persona => persona.orgId === this._orgStore.selectedOrgId)) {
            filteredInstances.push(instance);
            break;
          }
        }
      }
      config.instances = filteredInstances;
      config.restOfTheOrgInstances = nonFilteredInstances;
    }

    // remove empty instance as we dont want to show them in UI.
    const filteredOrgConfigs = orgConfigs.filter(configs => configs.instances.length > 0);
    // group by customization name
    const groupedConfigurations = groupBy(filteredOrgConfigs, 'name');

    // use preview instead of published when both exist
    return Object.entries(groupedConfigurations).map(([, configurations]) => {
      const getByState = state => configurations.find(configuration => configuration.state === state);
      const preview = getByState('dashboard-preview');
      const published = getByState('dashboard-published');
      const resolveUiConfiguration = new UiConfiguration(
        this.notificationManager,
        this.resolveUiConfigurationService,
        preview || cloneDeep(published),
        this.effectiveTimezone,
        this._orgStore.orgSettings
      );

      const { name } = resolveUiConfiguration;
      this.undoHistories[name] = { history: [cloneDeep(resolveUiConfiguration.toRaw())] };

      resolveUiConfiguration.publishedUiConfiguration = new UiConfiguration(
        this.notificationManager,
        this.resolveUiConfigurationService,
        published || cloneDeep(preview),
        this.effectiveTimezone,
        this._orgStore.orgSettings
      );
      return resolveUiConfiguration;
    });
  }

  @computed
  get uiConfigurationNameToEdit() {
    return this._routerStore.activeResolveUiConfigurationName;
  }

  @computed
  get uiInstanceIndex() {
    return this._routerStore.activeResolveUiInstanceIndex;
  }

  @computed
  get uiConfigurationToEdit() {
    return this.getUiConfigurationByName(this.uiConfigurationNameToEdit);
  }

  @computed
  get uiInstanceToEdit(): UiInstance | ConversationalUiInstance | SunshineUiInstance | null {
    let uiInstance = this.getUiInstanceByConfigurationNameAndIndex(
      this.uiConfigurationNameToEdit,
      this.uiInstanceIndex
    );

    // see if workflow is being edited (E.g. to get text overrides in preview)
    if (!uiInstance && this._workflowStore.workflowIdToEdit && this._workflowStore.currentWorkflowFirstUiInstance) {
      uiInstance = this._workflowStore.currentWorkflowFirstUiInstance;
    }

    return uiInstance;
  }

  @computed
  get solvvyUiInstanceToEdit(): UiInstance | ConversationalUiInstance | null {
    const uiInstance: any = this.uiInstanceToEdit;
    if (uiInstance && !(uiInstance instanceof UiInstance) && !(uiInstance instanceof ConversationalUiInstance)) {
      throw new Error('instance is not a UiInstance or ConversationalUiInstance');
    }
    return uiInstance;
  }

  @computed
  get uiInstanceToEditIsSunshine(): boolean {
    return this.uiInstanceToEdit instanceof SunshineUiInstance;
  }

  @computed
  get uiInstanceToEditName(): string | null {
    if (!this.uiConfigurationToEdit || !this.uiInstanceToEdit) {
      return null;
    }

    return this.getUiInstanceDisplayName(this.uiConfigurationToEdit, this.uiInstanceToEdit);
  }

  @computed
  get configTexts() {
    return (groupKey: string, groupText: any, type: string = TEXT_OVERRIDE_TYPES.TEXT, pluginId?: string) => {
      return Object.entries(flat(groupText))
        .filter(([key, defaultText]) => typeof defaultText === 'string')
        .map(([key, defaultText]) => {
          const fullKey = `${groupKey}.${key}`;
          const overrideText = this.getOverrideTextBasedOnType(type, { fullKey, key }, pluginId);
          return { fullKey, key, defaultText, overrideText };
        });
    };
  }

  @computed
  get uiOptionDefaults(): any {
    if (this.solvvyUiInstanceToEdit?.currentInstanceIsV5Version) {
      // remove greetingCard.prompts path from V5_UI_OPTION_DEFAULT
      // since it is used only for conversational type
      return omit(V5_UI_OPTION_DEFAULTS, 'greetingCard.prompts');
    }

    return V4_UI_OPTION_DEFAULTS;
  }

  @computed
  get resolvFeedDefaults(): any {
    return V5_RESOLVE_FEED_DEFAULTS;
  }

  @computed
  get pluginDefaults(): any {
    return V5_PLUGINS_DEFAULT;
  }

  @computed
  get configurablePluginsGroupV5(): ITextGroup[] {
    if (!this.plugins) {
      return [];
    }
    const groups: any = [];
    const getTexts = this.configTexts;
    this.plugins.map(plugin => {
      if (this.pluginDefaults[plugin.type]) {
        groups.push({
          name: plugin.type,
          pluginId: plugin.id,
          configTexts: getTexts(
            plugin.type,
            this.pluginDefaults[plugin.type].text,
            TEXT_OVERRIDE_TYPES.PLUGINS,
            plugin.id
          ),
          type: TEXT_OVERRIDE_TYPES.PLUGINS
        });
      }
    });
    return groups;
  }

  @computed
  get configurableTextGroups(): any[] {
    const groups: Array<Partial<ITextGroup>> = [];
    const getTexts = this.configTexts;

    for (const [key, value] of Object.entries(this.uiOptionDefaults)) {
      if (isObject(value) && (value as any).text) {
        groups.push({
          name: startCase(key),
          configTexts: getTexts(key, (value as any).text)
        });
      }
    }
    return groups;
  }

  @computed
  get configurableTextGroupsV5(): ITextGroup[] {
    const groups: ITextGroup[] = [];
    const rootGroups: ITextGroup[] = [];
    const getTexts = this.configTexts;

    for (const [key, value] of Object.entries(this.uiOptionDefaults)) {
      if (isObject(value) && (value as any)) {
        groups.push({
          name: startCase(key),
          configTexts: getTexts(key, value as any, TEXT_OVERRIDE_TYPES.TEXT),
          type: TEXT_OVERRIDE_TYPES.TEXT
        });
      } else if (isString(value)) {
        // for strings which are in root
        rootGroups.push({
          name: startCase('root'),
          configTexts: getTexts(key, { [key]: value as any }, TEXT_OVERRIDE_TYPES.ROOT),
          type: TEXT_OVERRIDE_TYPES.ROOT
        });
      }
    }
    return [...rootGroups, ...groups];
  }

  @computed
  get filteredConfigurableTextGroupsV5(): ITextGroup[] {
    return this.configurableTextGroupsV5.filter(groups => !TEXT_GROUPS_TO_EXCLUDE.includes(groups.name));
  }

  @computed
  get sunshineInstanceTextGroups(): ITextGroup[] {
    const groups: ITextGroup[] = [];
    const getTexts = this.configTexts;

    for (const [key, value] of Object.entries(BOT_MESSAGING_DEFAULT_TEXT)) {
      groups.push({
        name: startCase(key),
        configTexts: getTexts(key, value as any, TEXT_OVERRIDE_TYPES.TEXT),
        type: TEXT_OVERRIDE_TYPES.TEXT
      });
    }

    return groups;
  }

  @computed
  get configurableResolveFeedGroupsV5(): ITextGroup[] {
    const groups: any = [];
    const getTexts = this.configTexts;
    for (const [key, value] of Object.entries(this.resolvFeedDefaults)) {
      if (isObject(value) && (value as any)) {
        groups.push({
          name: startCase(key),
          configTexts: getTexts(key, value as any, TEXT_OVERRIDE_TYPES.RESOLVE_FEED),
          type: TEXT_OVERRIDE_TYPES.RESOLVE_FEED
        });
      }
    }

    return groups;
  }

  @computed
  get configurableChanelOptionGroupV5(): ITextGroup[] {
    if (!this.solvvyUiInstanceToEdit?.supportOptions) {
      return [];
    }
    const groups: any = [];
    const getTexts = this.configTexts;

    this.solvvyUiInstanceToEdit?.supportOptions.forEach(supportOption => {
      if (this.pluginDefaults[supportOption.normalizedPluginName]) {
        const pluginTexts = this.pluginDefaults[supportOption.normalizedPluginName].text;

        groups.push({
          name: startCase(supportOption.type),
          pluginId: supportOption.id,
          configTexts: getTexts(
            supportOption.type,
            pluginTexts,
            TEXT_OVERRIDE_TYPES.RESOLVE_FEED_CH_OPTIONS,
            supportOption.id
          ),
          type: TEXT_OVERRIDE_TYPES.RESOLVE_FEED_CH_OPTIONS
        });
      }
    });
    return groups;
  }

  @computed
  get configurableGreetingForm(): ITextGroup[] {
    return this.solvvyUiInstanceToEdit?.currentInstanceIsV5Version
      ? this.configurableTextGroupsV5.filter(groups => groups.name === 'Greeting Card')
      : this.configurableTextGroups.filter(groups => groups.name === 'Question Form');
  }

  @computed
  get configurableSupportOption() {
    return this.configurableTextGroups.filter(groups => groups.name === 'Support Options');
  }

  @computed
  get configurableChannelOptionsMenuCard() {
    const isConversational = this.solvvyUiInstanceToEdit?.dialogType === DialogType.Conversational;
    const channelOptionsMenuCard = this.configurableResolveFeedGroupsV5.filter(
      group => group.name === 'Channel Options Menu Card'
    );
    if (!isConversational) {
      return channelOptionsMenuCard;
    }

    /**
     * For conversational type, the "Instructions" field is only applicable so
     * the rest of the fields "Title" and "Title When Shown Automatically" are excluded
     * from the array.
     */
    return channelOptionsMenuCard.map(group => {
      group.configTexts = group.configTexts.filter(configText => configText.key === 'instructions');
      return group;
    });
  }

  @computed
  get greetingText() {
    return this.retrieveConfigValue('greeting');
  }

  @computed
  get greetingTextV5(): string {
    const value = this.configurableGreetingForm[0].configTexts.find(configText => configText.key === 'greeting');
    return value?.overrideText || value?.defaultText;
  }

  @computed
  get instructionsText() {
    return this.retrieveConfigValue('instructions');
  }

  @computed
  get placeholderText() {
    return this.retrieveConfigValue('placeholder');
  }

  @computed
  get supportOptionsInstructionsText() {
    return this.retrieveSupportOptionConfigValue('instructions');
  }

  @computed
  get noSupportOptionText() {
    return this.retrieveSupportOptionConfigValue('noSupportOptionsAvailable');
  }

  @computed
  get supportOptionMainText() {
    return this.retrieveSupportOptionConfigValue('title');
  }

  @computed
  get supportOptionMainDescriptionText() {
    return this.retrieveSupportOptionConfigValue('description');
  }

  @computed
  get supportOptions() {
    return this.solvvyUiInstanceToEdit!.supportOptions;
  }

  @computed
  get plugins() {
    return this.solvvyUiInstanceToEdit!.plugins;
  }

  @computed
  get continueButtonText() {
    return this.retrieveConfigValue('continue');
  }

  @computed
  get showSupportOptionTexts() {
    return get(this._orgStore.orgSettings, 'enable_support_option_texts', true);
  }

  @computed
  get effectiveTimezone() {
    return this._orgStore.orgSettings.timezone;
  }

  @computed
  get channelOptionsMenuCardTitle() {
    return this.retrieveChannelOptionConfigValue('title');
  }

  @computed
  get channelOptionsMenuCardInstructions() {
    return this.retrieveChannelOptionConfigValue('instructions');
  }

  @computed
  get hasSingleUiInstance() {
    return (
      this.resolveUiConfigurations.fulfilled &&
      this.resolveUiConfigurations.value.length === 1 &&
      this.resolveUiConfigurations.value[0].instances.length === 1
    );
  }

  @computed
  get canUndoConfigManager() {
    return this.getCanUndo(ISaveSource.CONFIG_MANAGER);
  }

  @computed
  get canRedoConfigManager() {
    return this.getCanRedo(ISaveSource.CONFIG_MANAGER);
  }

  @computed
  get isGlobalUser() {
    return this._orgStore.currentUserContext.isGlobalUser;
  }

  @computed
  get isCurrentSolvvyInstanceConversational() {
    return this.solvvyUiInstanceToEdit?.dialogType === DialogType.Conversational;
  }

  @computed
  get isCurrentSolvvyInstanceProfessional() {
    return (
      this.solvvyUiInstanceToEdit?.dialogType === DialogType.Professional ||
      this.solvvyUiInstanceToEdit?.dialogType !== DialogType.Conversational
    );
  }

  @computed
  get activeSuggestions(): ISuggestion[] {
    const suggestionListsAC = this._suggestionsStore.suggestionLists;
    if (suggestionListsAC.fulfilled) {
      const suggestionList = suggestionListsAC.value.find(sl => sl.uiInstanceId === this.uiInstanceToEdit?.id);
      if (suggestionList) {
        return suggestionList.workingVersion.suggestions.filter(s => s.enabled);
      }
    }

    return [];
  }

  @computed
  get instanceActiveTabKey() {
    return this._routerStore.activeInstanceTabId;
  }

  @computed
  get isWorkflowsLoading() {
    return this._workflowStore.workflows.pending;
  }
}
