import { EyeFilled, SearchOutlined } from '@ant-design/icons';
import { Popover, Tooltip } from 'antd';
import Input from 'antd/lib/input';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { reaction, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { EllipsisIcon } from '../../shared/components/icons';
import { OrgStore } from '../../store/orgStore';
import { IStep } from '../../store/ResolveUI/Workflow';
import { ResolveUiStore } from '../../store/resolveUiStore';
import { typedInject } from '../../store/rootStore';
import { WorkflowStore } from '../../store/workflowStore';
import StepActions from './StepActions';
import { StyledStepSearchDrawer } from './workflow.styles';

interface IProps {
  workflowStore: WorkflowStore;
  resolveUiStore: ResolveUiStore;
  orgStore: OrgStore;
  step?: IStep;
}

interface IState {
  searchText: string | undefined;
  selectedSteps: IStep[];
  isStepActionsPopoverVisible: boolean;
}

interface IStepDisplay extends IStep {
  isSelected?: boolean;
}

const StepRow = ({
  step,
  onClick,
  onClickScrollToStep
}: {
  step: IStepDisplay;
  onClick?: any;
  onClickScrollToStep?: any;
}) => {
  return (
    <Tooltip title={step.name} mouseEnterDelay={0.5}>
      <div className={classNames('step_container', { selected: step.isSelected })} onClick={onClick}>
        <div className="step_name">{step.name}</div>
        <div className="step_action">
          <EyeFilled onClick={onClickScrollToStep} />
        </div>
      </div>
    </Tooltip>
  );
};

@observer
class StepSearchDrawer extends Component<IProps, IState> {
  state: IState = {
    searchText: undefined,
    selectedSteps: [],
    isStepActionsPopoverVisible: false
  };
  selectedStepsReactionDisposer: any;
  constructor(props) {
    super(props);

    this.selectedStepsReactionDisposer = reaction(
      () => this.props.workflowStore.selectedSteps,
      selectedSteps => {
        this.setState({ selectedSteps });
      }
    );
  }

  componentWillUnmount() {
    if (this.selectedStepsReactionDisposer) {
      this.selectedStepsReactionDisposer();
    }
  }

  handleSearch = event => {
    this.setState({ searchText: event.target.value });
  };

  highlightSteps = steps => {
    // unhighlight all steps and links before highlighting.
    this.props.workflowStore.unhighlightAllSteps();

    steps.forEach(step => {
      const workflowStep = this.props.workflowStore.jointJsModelByStepId[step.id];
      const cellView = this.props.workflowStore.paper.findViewByModel(workflowStep);
      this.props.workflowStore.highlightStepAndLinks(cellView);
    });
  };

  filteredSteps = (steps: IStep[]) => {
    const { searchText }: { searchText: string | undefined } = this.state;
    let filteredSteps = steps;
    if (!isEmpty(searchText)) {
      const isMatchingStep = (text, step: IStep) => {
        const textLowerCase = text.toLowerCase().trim();
        return (
          step.name?.toLowerCase().includes(textLowerCase) ||
          step.header?.toLowerCase().includes(textLowerCase) ||
          step.tags?.some(tag => tag.toLowerCase().includes(textLowerCase)) ||
          step.notes?.toLowerCase().includes(textLowerCase)
        );
      };
      filteredSteps = steps.filter(step => isMatchingStep(searchText, step));
    }
    return filteredSteps.sort((stepA, stepB) => {
      return new Intl.Collator('en', { numeric: true }).compare(stepA.name || '', stepB.name || '');
    });
  };

  handleStepClick = (step, e) => {
    const { workflowStore } = this.props;
    let newSelectedSteps: IStep[] = [];
    const { selectedSteps } = this.state;
    if (e.shiftKey) {
      // step was clicked with Shift key

      // get index of current selected step.
      const { workflowToEdit } = workflowStore;

      const allStepsInView = this.filteredSteps(workflowToEdit.workingVersion.steps);
      if (selectedSteps && selectedSteps.length > 0) {
        const firstSelectedStep = selectedSteps[0];
        const firstIndex = allStepsInView.indexOf(firstSelectedStep);

        const secondIndex = allStepsInView.indexOf(step);
        const from = secondIndex - firstIndex > 0 ? firstIndex : secondIndex;
        const to = secondIndex - firstIndex > 0 ? secondIndex : firstIndex;

        for (let i = from; i <= to; i++) {
          newSelectedSteps.push(allStepsInView[i]);
        }
      }
    } else if (e.ctrlKey || e.metaKey) {
      // step was clicked with CMD(mac) or CTRL key
      newSelectedSteps = selectedSteps.concat(step);
    } else {
      newSelectedSteps = [step];
    }

    this.setState({ selectedSteps: newSelectedSteps });

    runInAction(() => {
      workflowStore.selectedSteps = newSelectedSteps;
    });

    // to select and open the step properties.
    if (!workflowStore.showWorkflowProperties || workflowStore.selectedStepId === step.id) {
      workflowStore.toggleWorkflowProperties();
    }
    workflowStore.updateSelectedStepId(workflowStore.showWorkflowProperties ? step.id : '');

    this.highlightSteps(newSelectedSteps);
  };

  render() {
    const { showStepSearch, scrollStepIntoView, workflowToEdit } = this.props.workflowStore;
    const { isStepActionsPopoverVisible, selectedSteps } = this.state;
    const filteredSteps = this.filteredSteps(workflowToEdit.workingVersion.steps);
    return (
      <StyledStepSearchDrawer
        data-testid="step-search"
        mask={false}
        maskClosable={false}
        visible={showStepSearch}
        placement="left"
        getContainer="#workflow_step_area"
        style={{ position: 'absolute' }}
        closable={false}
        width={240}
      >
        <div className="step_search_header">
          <div className="search_input">
            <Input
              placeholder="Search Steps"
              onChange={this.handleSearch}
              allowClear={true}
              prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </div>
          <div className="step_list_header">
            {filteredSteps.length < workflowToEdit.workingVersion.steps.length
              ? `Matching Steps(${filteredSteps.length})`
              : 'All Steps'}
            <div className="card_actions">
              {selectedSteps.length > 0 ? (
                <Popover
                  placement="top"
                  visible={isStepActionsPopoverVisible}
                  trigger="click"
                  onVisibleChange={visible => {
                    this.setState({ isStepActionsPopoverVisible: visible });
                  }}
                  content={
                    <StepActions
                      onActionClicked={() => this.setState({ isStepActionsPopoverVisible: false })}
                      step={selectedSteps[0]}
                    />
                  }
                >
                  <EllipsisIcon data-testid="card-actions-button" className="ellipsis_action_button" />
                </Popover>
              ) : (
                <EllipsisIcon
                  data-testid="card-actions-button"
                  disabled={selectedSteps.length === 0}
                  className="ellipsis_action_button"
                />
              )}
            </div>
          </div>
        </div>
        <div className="steps_container">
          {filteredSteps.map(step => (
            <StepRow
              key={step.id}
              step={{ ...step, isSelected: selectedSteps.find(s => s.id === step.id) !== undefined }}
              onClick={e => {
                this.handleStepClick(step, e);
              }}
              onClickScrollToStep={e => {
                e.stopPropagation();
                scrollStepIntoView(step);
              }}
            />
          ))}
        </div>
      </StyledStepSearchDrawer>
    );
  }
}

export default typedInject('resolveUiStore', 'workflowStore', 'orgStore')(StepSearchDrawer);
