import { observable } from 'mobx';

export default class AnnouncementConfiguration {
  @observable
  title: string = '';
  @observable
  message: string = '';
  @observable
  url: string = '';
  @observable
  linkText: string = '';
  @observable
  autoShow: boolean = false;
  @observable
  styleLinkAsButton: boolean = false;
  @observable
  countClickAsInstantResolution: boolean = false;
  @observable
  imageUrl?: string = '';
  @observable
  showImage?: boolean = false;
}
