import { observable } from 'mobx';
import { ContactSupportPriority, SolutionsSnippetLength } from '../ResolveUI/types';

export default class SolutionsSettings {
  static createRaw(solutionsSettings: SolutionsSettings) {
    return {
      snippetLength: solutionsSettings.snippetLength,
      displayLimit: solutionsSettings.displayLimit,
      showRelatedArticles: solutionsSettings.showRelatedArticles,
      contactSupportPriority: solutionsSettings.contactSupportPriority
    };
  }

  @observable
  snippetLength: SolutionsSnippetLength;

  @observable
  displayLimit: number;

  @observable
  showRelatedArticles: boolean;

  @observable
  contactSupportPriority: ContactSupportPriority;

  constructor({ snippetLength, displayLimit, showRelatedArticles, contactSupportPriority }: SolutionsSettings) {
    this.snippetLength = snippetLength;
    this.displayLimit = displayLimit;
    this.showRelatedArticles = showRelatedArticles;
    this.contactSupportPriority = contactSupportPriority;
  }
}
