/* tslint:disable:object-literal-sort-keys */

// This file uses .js extension and is used as a common js module because it is used by craco.config.js which is a common js module.

// Complete list of Overrides
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

// ant theme variables which modifies the color are overridden from here.
// Other variables are overridden from src/themes/theme.less

/** Color codes that are re-used */
const Color = {
  white: '#ffffff',
  black: '#000000',
  textPrimary: 'rgba(0, 0, 0, 0.85)',
  textSecondary: 'rgba(0, 0, 0, 0.45)',
  primaryBlue: '#1890ff',
  primarySuccess: '#52c41a',
  errorColor: '#f5222d',
  solvvyTeal: '#00a99b',
  solvvyBlack: '#4d4d4d',
  solvvyLightBlue: '#DAECFF',
  grey_11: '#f6f6f6',
  grey_17: '#d9d9d9',
  deepBlue: '#292d33',
  grey: '#a0a0a0'
};

/** Color codes that are used in both ant-theme and inside component styles */
module.exports.ColorsInTheme = {
  white: Color.white,
  grey_17: Color.grey_17,
  solvvyTeal: Color.solvvyTeal,
  solvvyBlack: Color.solvvyBlack,
  errorColor: Color.errorColor,
  primarySuccess: Color.primarySuccess,
  primaryBlue: Color.primaryBlue,
  textPrimary: Color.textPrimary,
  textSecondary: Color.textSecondary,
  solvvyLightBlue: Color.solvvyLightBlue
};

/** Color codes that are used as ant-theme overrides */
module.exports.ThemeColors = {
  '@solvvyHighlightColor': Color.solvvyLightBlue,
  '@solvvyTextColor': '#320b4a',
  '@solvvyDarkPurple': '#5d0e8b',
  '@solvvyMediumPurple': '#9155aa',
  '@solvvyLightPurple': '#bdb2f2',
  '@solvvyPink': '#fa3777',
  '@solvvyMediumBlue': '#008cc9',
  '@solvvyDarkBlue': '#00447b',
  '@solvvyTeal': '#00a99b',
  '@solvvyBlack': Color.solvvyBlack,
  '@secondary-color': Color.grey,
  '@red': '#b03060',
  '@orange': '#fe9a76',
  '@yellow': '#ffd700',
  '@olive': '#32cd32',
  '@green': '#016936',
  '@teal': '#008080',
  '@blue': '#0e6eb8',
  '@violet': '#ee82ee',
  '@purple': '#b413ec',
  '@pink': '#ff1493',
  '@brown': '#a52a2a',
  '@grey': Color.grey,
  '@grey_1': '#f4f4ed',
  '@grey_2': '#9b9b97',
  '@grey_3': '#bfbbbb',
  '@grey_4': '#f7f7f3',
  '@grey_5': '#c4c2c5',
  '@grey_6': '#cacac5',
  '@grey_7': '#cbcbcb',
  '@grey_8': '#979797',
  '@grey_9': '#e7e7e3',
  '@grey_10': '#858581',
  '@grey_11': Color.grey_11,
  '@black': Color.black,

  // @primary-color: @solvvyDarkPurple; // primary color for all components
  '@link-color': Color.primaryBlue, // link color
  '@success-color': Color.primarySuccess, // success state color
  '@warning-color': '#faad14', // warning state color
  '@error-color': Color.errorColor, // error state color
  '@heading-color': Color.textPrimary, // heading text color
  '@text-color': Color.solvvyBlack, // major text color
  '@text-color-secondary': Color.textSecondary, // secondary text color
  '@disabled-color': Color.grey, // disable state color
  '@border-color-base': Color.grey_17, // major border color: ;

  '@layout-sider-background': Color.deepBlue,
  '@layout-trigger-background': Color.deepBlue,
  '@layout-body-background': Color.grey_11,
  '@menu-dark-bg': Color.deepBlue,
  '@menu-highlight-color': Color.deepBlue,
  '@menu-item-active-bg': Color.white,
  '@menu-dark-item-active-bg': Color.black,

  '@item-hover-bg': '#e6f7ff',

  //Button
  '@btn-primary-bg': Color.solvvyTeal,
  '@btn-default-border': Color.solvvyTeal,
  '@btn-default-color': Color.solvvyTeal,

  //Tabs
  '@tabs-hover-color': Color.solvvyTeal,
  '@tabs-highlight-color': Color.solvvyTeal,
  '@tabs-active-color': Color.solvvyTeal,
  '@tabs-ink-bar-color': Color.solvvyTeal,

  // Progress
  '@progress-default-color': Color.solvvyTeal
};
