import React, { PureComponent } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import S from './chartStyles';
import { IButtonBreakdown } from './IButtonBreakdown';
const COLORS = ['#ED9DCA', '#9E7863', '#E17B29', '#29A962', '#EFA62A', '#9580A3', '#6BD4BB', '#EE5066'];

interface IProps {
  screen: IButtonBreakdown;
}
const renderCustomizedLabel = props => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, outerRadius, fill, percent } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);

  const sx = cx + outerRadius * cos;
  const sy = cy + outerRadius * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  const tx = cx + (outerRadius + 60) * cos;
  const ty = cy + (outerRadius + 60) * sin;
  const ey = sin >= 0 ? ty + 2 : ty - 12;

  return (
    <g>
      <path d={`M${sx},${sy}L${mx},${my}L`} stroke={fill} fill="none" />

      <text x={tx + (cos >= 0 ? -2 : 2) * 12} y={ey} dy={6} textAnchor={textAnchor} fill="#999">
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

const getChartHeight = btnCount => {
  if (btnCount > 12) {
    return 0.7;
  }

  if (btnCount > 9) {
    return 0.75;
  }

  if (btnCount >= 6) {
    return 0.8;
  }

  return 1;
};

export default class BreakdownChart extends PureComponent<IProps> {
  render() {
    const chartData = this.props.screen.buttons.map(button => ({ name: button.text, value: button.user_actions }));

    return (
      <S.Container>
        <ReportHeader text={this.props.screen.step_name} step={this.props.screen.step_number} />

        <ResponsiveContainer width="100%" aspect={getChartHeight(this.props.screen.buttons.length)}>
          <PieChart>
            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              labelLine={true}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Legend
              wrapperStyle={{
                '&::after': {
                  content: 'Test'
                }
              }}
              verticalAlign="bottom"
              align="left"
              iconType="circle"
            />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </S.Container>
    );
  }
}

const ReportHeader = ({ step, text }) => (
  <S.Header>
    <span className="id">{step}: </span>
    <span className="title">{text}</span>
  </S.Header>
);
