export const UNDO = ['command+z', 'ctrl+z'];
export const REDO = ['command+shift+z', 'ctrl+shift+z'];
export const ZOOM_IN = ['command++', 'command+=', 'ctrl++', 'ctrl+='];
export const ZOOM_OUT = ['command+-', 'ctrl+-'];
export const ZOOM_TO_FIT = ['command+0', 'ctrl+0'];
export const ZOOM_RESET = ['command+1', 'ctrl+1'];

export const MOD_KEY = navigator.userAgent.indexOf('Mac OS X') !== -1 ? '⌘' : 'ctrl+';

export default {
  UNDO,
  REDO,
  ZOOM_IN,
  ZOOM_OUT,
  ZOOM_TO_FIT,
  ZOOM_RESET
};
