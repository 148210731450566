import gql from 'graphql-tag';
import { Api } from './api';
import { allUsingApi } from './util';

const OrgReportsQuery = gql`
  query($orgId: Int!) {
    allReportsForOrg(org_id: $orgId) {
      label
      org_id
      report_id
      mode_id
      tag_mode_id
      visibility
    }
  }
`;

const getReportsForOrg = async (api, orgId) => {
  const {
    data: { allReportsForOrg }
  } = await api.gqlQuery({ query: OrgReportsQuery, variables: { orgId }, fetchPolicy: 'network-only' }).catch(e => {
    throw e;
  });

  const reports = allReportsForOrg.map(report => ({
    modeId: report.mode_id,
    reportId: report.report_id,
    visibility: report.visibility,
    label: report.label
  }));
  return reports;
};

export const reportsService = (api: Api) => {
  return allUsingApi(api, {
    getReportsForOrg
  });
};

export default reportsService;
