import { message, Modal } from 'antd';
import { isEqual } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import { action, computed, observable, runInAction } from 'mobx';
import { WORKFLOW_PURPOSE } from 'src/routes/Workflows/constants';
import { v4 as uuidv4 } from 'uuid';
import { UiConfigService } from '../services/uiConfigService';
import asyncComputed from '../shared/util/asyncComputed';
import { NotificationManager } from '../shared/util/NotificationManager';
import { recordErrors } from '../shared/util/recordErrors';
import { ISolution } from './exploreStore';
import { OrgStore } from './orgStore';
import { ConversationalUiInstance } from './ResolveUI/ConversationalUiInstance';
import {
  INewSuggestion,
  IRevertSuggestionListVersion,
  ISuggestion,
  ISuggestionList,
  ISuggestionListVersion,
  SuggestionType
} from './ResolveUI/Suggestion';
import { SunshineUiInstance } from './ResolveUI/SunshineUiInstance';
import { DialogType, IConfigurationPath } from './ResolveUI/types';
import { IUnpublishedChanges, UiConfiguration } from './ResolveUI/UiConfiguration';
import { UiInstance } from './ResolveUI/UiInstance';
import { IWorkflow } from './ResolveUI/Workflow';
import { ResolveUiStore } from './resolveUiStore';
import { RouterStore } from './routerStore';
import { WorkflowStore } from './workflowStore';

export const MAX_UNDO_HISTORY = 25;

interface IUndoItem {
  workingVersion?: ISuggestionListVersion;
}
interface IUndoHistory {
  history: IUndoItem[];
  undoIndex?: number;
}

export class SuggestionsStore {
  static getSuggestionType(suggestion: ISuggestion): SuggestionType {
    return suggestion.workflowId ? SuggestionType.WORKFLOW : SuggestionType.QUERY;
  }

  @observable
  saving: boolean = false;

  @observable
  loadingSolutions: boolean = false;

  @observable
  loadingSuggestionListVersionsForRevert: boolean = false;

  @observable
  solutions?: ISolution[];

  @observable
  lastModifiedAt?: string;

  @observable
  lastUndoOrRedoOrRevertAt?: string;

  @observable
  undoHistories: { [uiInstanceId: string]: IUndoHistory } = {};

  @observable
  showPreview = false;

  @observable
  showCreateForm = false;

  @observable
  suggestionLists = asyncComputed(() => this.loadSuggestionLists(this.orgStore.selectedOrgId));

  @observable
  suggestionListToEditAsyncComputed = asyncComputed(() => this.loadSuggestionList(this.routerStore.uiInstanceId));

  @observable
  suggestionListToEdit: ISuggestionList | undefined;

  // used to disable messages by tests since they are interferring
  disableMessages: boolean = false;

  @observable
  showingErrorModal: boolean = false;

  constructor(
    private orgStore: OrgStore,
    private resolveUiStore: ResolveUiStore,
    private workflowStore: WorkflowStore,
    public routerStore: RouterStore,
    private notificationManager: NotificationManager,
    private exploreService: any,
    public uiConfigService: UiConfigService
  ) {}

  async loadSuggestionLists(orgId: number): Promise<ISuggestionList[]> {
    if (!orgId) {
      return [];
    }

    const suggestionLists: ISuggestionList[] = await this.uiConfigService.getSuggestionLists(orgId);
    return suggestionLists;
  }

  async loadSuggestionList(uiInstanceId: string): Promise<ISuggestionList | undefined> {
    const suggestionList = await this.uiConfigService.getSuggestionList(uiInstanceId);
    this.suggestionListToEdit = suggestionList;

    runInAction(() => {
      this.undoHistories[uiInstanceId] = {
        history: []
      };
      (window as any).undoHistories = this.undoHistories;
    });

    return this.suggestionListToEdit;
  }

  @action
  async loadSuggestionListVersionsForRevert(suggestionList: ISuggestionList): Promise<IRevertSuggestionListVersion[]> {
    try {
      this.loadingSuggestionListVersionsForRevert = true;
      return await this.uiConfigService.getSuggestionListVersionsForRevert(suggestionList.id);
    } catch (e) {
      recordErrors(e);
      message.error(
        'There was an unexpected error loading the suggestions versions. Please refresh the page or try again later.'
      );
      throw e;
    } finally {
      runInAction(() => (this.loadingSuggestionListVersionsForRevert = false));
    }
  }

  getSuggestionListForUiInstance(uiInstanceId: string): ISuggestionList | undefined {
    if (this.suggestionLists.fulfilled) {
      return this.suggestionLists.value.find(sl => sl.uiInstanceId === uiInstanceId);
    }
    return undefined;
  }

  getUiConfigurationPaths(configurationName: string, instanceIndex?: string | number): IConfigurationPath[] {
    const uiInstance = this.resolveUiStore.getUiInstanceByConfigurationNameAndIndex(configurationName, instanceIndex);
    if (!uiInstance) {
      throw new Error(`No UI instance found at name ${configurationName} and index ${instanceIndex}`);
    }
    return [
      {
        path: `instances[${instanceIndex}].suggestions`,
        exclude: ['lastModifiedAt']
      },
      {
        path: `instances[${instanceIndex}].suggestionsLastPublishedAt`
      }
    ];
  }

  @action
  setMissingPositions() {
    if (!this.suggestionListToEdit) {
      throw new Error('no current suggestion list');
    }

    const { workingVersion } = this.suggestionListToEdit;
    // set positions in case they don't exist yet
    let highestRecordedPosition = 0;
    const suggestions = this.suggestionsInPositionOrder.map(suggestion => {
      if (suggestion.position) {
        highestRecordedPosition = Math.max(highestRecordedPosition, suggestion.position);
      } else if (suggestion.enabled) {
        suggestion.position = highestRecordedPosition + 1;
        highestRecordedPosition = suggestion.position;
      }
      return suggestion;
    });
    // set positions with potentially new ordering
    workingVersion.suggestions = suggestions;
  }

  @action
  async publish(suggestionList: ISuggestionList, publishedComments?: string): Promise<void> {
    try {
      this.saving = true;

      const updatedSuggestionList = await this.uiConfigService.publishSuggestionList(
        suggestionList.id,
        suggestionList.workingVersion.id,
        publishedComments
      );
      runInAction(() => {
        suggestionList.workingVersion = updatedSuggestionList.workingVersion;
        suggestionList.publishedVersion = updatedSuggestionList.publishedVersion;
        this.lastModifiedAt = new Date().toISOString();
      });
      if (!this.disableMessages) {
        message.success('Changes have been published');
      }
    } catch (err) {
      this.handleServerError(
        err,
        'suggestions',
        'There was an unexpected error publishing the suggestions. Please try again later.'
      );
      throw err;
    } finally {
      runInAction(() => (this.saving = false));
    }
  }

  @action
  async revertUnpublished(suggestionList: ISuggestionList, srcSuggestionListVersionId?: string): Promise<void> {
    try {
      this.saving = true;

      const updatedSuggestionListVersion = await this.uiConfigService.revertSuggestionListWorkingVersion(
        suggestionList.workingVersion.id,
        srcSuggestionListVersionId || suggestionList.publishedVersion.id
      );
      runInAction(() => {
        suggestionList.workingVersion = updatedSuggestionListVersion;
        this.lastUndoOrRedoOrRevertAt = new Date().toISOString();
        this.lastModifiedAt = new Date().toISOString();
      });
      if (!this.disableMessages) {
        message.success('Suggestions have been successfully reverted, but not published');
      }
    } catch (e) {
      this.handleServerError(
        e,
        'suggestions',
        'There was an unexpected error reverting to the specified suggestions. Please try again later.'
      );
      return undefined;
    } finally {
      runInAction(() => (this.saving = false));
    }
  }

  @action
  async deleteSuggestion(suggestionToDelete: ISuggestion) {
    if (!this.suggestionListToEdit) {
      throw new Error('no current suggestion list');
    }

    this.addToUndoHistory();

    this.saving = true;

    // need to set missing positions before to make sure to preserve original order
    this.setMissingPositions();

    const { workingVersion } = this.suggestionListToEdit;
    const origSuggestions = cloneDeep(workingVersion.suggestions);

    try {
      if (suggestionToDelete.placeholder) {
        // if deleting a placeholder.. then just decrement all the subsequent positions
        workingVersion.suggestions = workingVersion.suggestions.map(suggestion => {
          if (suggestion.position! > suggestionToDelete.position!) {
            suggestion.position = suggestion.position! - 1;
          }
          return suggestion;
        });
        await this.uiConfigService.updateSuggestionListVersion(workingVersion);
      } else {
        runInAction(
          () => (workingVersion.suggestions = workingVersion.suggestions.filter(s => s.id !== suggestionToDelete.id))
        );
        await this.uiConfigService.deleteSuggestion(workingVersion.id, suggestionToDelete.id);
      }

      if (!this.disableMessages) {
        message.success('The suggestion has been deleted');
      }
    } catch (err) {
      runInAction(() => (workingVersion.suggestions = origSuggestions));
      this.handleServerError(
        err,
        'suggestion',
        'There was an unexpected error deleting the suggestion. Please try again later.'
      );
      throw err;
    } finally {
      runInAction(() => (this.saving = false));
    }
  }

  @action
  async duplicateSuggestion(suggestion: ISuggestion) {
    if (!this.suggestionListToEdit) {
      throw new Error('no current suggestion list');
    }

    this.addToUndoHistory();

    this.saving = true;

    // need to set missing positions before to make sure to preserve original order
    this.setMissingPositions();

    const workingVersion = this.suggestionListToEdit.workingVersion;
    const newSuggestion: INewSuggestion = {
      ...cloneDeep(suggestion),
      id: uuidv4(),
      enabled: false,
      name: `Copy of ${suggestion.name || suggestion.text}`,
      updatedAt: new Date().toISOString()
    };

    const origSuggestions = cloneDeep(workingVersion.suggestions);
    try {
      workingVersion.suggestions.push(newSuggestion as ISuggestion);
      await this.uiConfigService.createSuggestion(workingVersion.id, newSuggestion);

      if (!this.disableMessages) {
        message.success('The suggestion has been duplicated');
      }
    } catch (err) {
      runInAction(() => (workingVersion.suggestions = origSuggestions));

      this.handleServerError(
        err,
        'suggestion',
        'There was an unexpected error duplicated the suggestion. Please try again later.'
      );
      throw err;
    } finally {
      runInAction(() => (this.saving = false));
    }
  }

  @action
  async replaceActiveSuggestion(newPosition: number, suggestionToReplace: ISuggestion, newSuggestion: ISuggestion) {
    if (!this.suggestionListToEdit) {
      throw new Error('no current suggestion list');
    }

    this.addToUndoHistory();

    this.saving = true;

    // need to set missing positions before replacing to make sure to preserve original order
    this.setMissingPositions();

    const initialEnabledStatus = newSuggestion.enabled;

    const suggestionsToBatchUpdate: ISuggestion[] = [];
    // can't use suggestionToReplace.position because it might not exist (e.g. newly migrated suggestions)
    newSuggestion.position = newPosition;
    newSuggestion.enabled = true;
    suggestionsToBatchUpdate.push(newSuggestion);

    suggestionToReplace.enabled = false;
    suggestionToReplace.position = undefined;
    if (!suggestionToReplace.placeholder) {
      suggestionsToBatchUpdate.push(suggestionToReplace);
    }

    try {
      await this.uiConfigService.batchUpdateSuggestions(
        this.suggestionListToEdit.workingVersion.id,
        suggestionsToBatchUpdate
      );
      if (!this.disableMessages) {
        if (initialEnabledStatus) {
          message.success('The suggestion has been made moved');
        } else {
          message.success('The suggestion has been made active');
        }
      }
    } catch (err) {
      this.handleServerError(
        err,
        'suggestion',
        'There was an unexpected error updating the suggestion. Please try again later.'
      );
      await this.suggestionListToEditAsyncComputed.refresh();
      throw err;
    } finally {
      runInAction(() => (this.saving = false));
    }
  }

  @action
  async moveToActive(suggestion: ISuggestion) {
    this.saving = true;

    this.addToUndoHistory();

    // need to set missing positions before replacing to make sure to preserve original order
    this.setMissingPositions();

    const position = this.activeSuggestionsWithEmptySlots.length + 1;
    suggestion.position = position;
    suggestion.enabled = true;

    try {
      await this.uiConfigService.updateSuggestion(suggestion);
      if (!this.disableMessages) {
        message.success('The suggestion has been made active');
      }
    } catch (err) {
      this.handleServerError(
        err,
        'suggestion',
        'There was an unexpected error updating the suggestion. Please try again later.'
      );
      await this.suggestionListToEditAsyncComputed.refresh();
      throw err;
    } finally {
      runInAction(() => (this.saving = false));
    }
  }

  @action
  async disableSuggestion(suggestionToDisable: ISuggestion) {
    this.saving = true;

    this.addToUndoHistory();

    // need to set missing positions before replacing to make sure to preserve original order
    this.setMissingPositions();

    suggestionToDisable.enabled = false;
    suggestionToDisable.position = undefined;

    try {
      await this.uiConfigService.updateSuggestion(suggestionToDisable);
      if (!this.disableMessages) {
        message.success('The suggestion has been made inactive');
      }
    } catch (err) {
      this.handleServerError(
        err,
        'suggestion',
        'There was an unexpected error updating the suggestion. Please try again later.'
      );
      await this.suggestionListToEditAsyncComputed.refresh();
      throw err;
    } finally {
      runInAction(() => (this.saving = false));
    }
  }

  @action
  async createSuggestion(newSuggestion: INewSuggestion): Promise<void> {
    if (!this.routerStore.uiInstanceId) {
      throw new Error('no selected UI instance');
    }

    this.saving = true;

    this.addToUndoHistory();

    try {
      if (!this.suggestionListToEdit) {
        const suggestionList = await this.uiConfigService.createSuggestionList(
          this.orgStore.currentOrgGroupId,
          this.routerStore.uiInstanceId,
          newSuggestion
        );
        runInAction(() => (this.suggestionListToEdit = suggestionList));
      } else {
        const workingVersion = this.suggestionListToEdit.workingVersion;
        const suggestion = await this.uiConfigService.createSuggestion(workingVersion.id, newSuggestion);
        runInAction(() => workingVersion.suggestions.push(suggestion));
      }

      if (!this.disableMessages) {
        message.success('The suggestion has been created');
      }
    } catch (err) {
      this.handleServerError(
        err,
        'suggestion',
        'There was an unexpected error creating the suggestion. Please try again later.'
      );
      throw err;
    } finally {
      runInAction(() => (this.saving = false));
    }
  }

  @action
  async updateSuggestion(suggestion: ISuggestion): Promise<void> {
    if (!this.routerStore.uiInstanceId) {
      throw new Error('no selected UI instance');
    }

    this.saving = true;

    this.addToUndoHistory();

    try {
      await this.uiConfigService.updateSuggestion(suggestion);
      if (!this.disableMessages) {
        message.success('The suggestion has been updated');
      }
    } catch (err) {
      this.handleServerError(
        err,
        'suggestion',
        'There was an unexpected error updating the suggestion. Please try again later.'
      );
      throw err;
    } finally {
      runInAction(() => (this.saving = false));
    }
  }

  @action
  async searchQuery(query: string) {
    try {
      this.loadingSolutions = true;
      const solutions = await this.exploreService.search({
        org_id: this.orgStore.selectedOrgId,
        create_query: false,
        limit: 3,
        query
      });
      runInAction(() => (this.solutions = solutions));
    } catch (e) {
      const serverError = get(e, 'response.data.message');
      this.notificationManager.error({
        title: 'Oops something went wrong',
        message: `${e}${serverError ? '\n' + serverError : ''}`
      });
    } finally {
      runInAction(() => (this.loadingSolutions = false));
    }
  }

  @action
  resetSolutions() {
    this.solutions = undefined;
    this.loadingSolutions = false;
  }

  @action.bound
  togglePreview() {
    this.showPreview = !this.showPreview;
  }

  @action.bound
  setShowCreateForm(showCreateForm) {
    this.showCreateForm = showCreateForm;
  }

  // add change to undo history
  @action
  addToUndoHistory(): void {
    let undoHistory = this.undoHistories[this.routerStore.uiInstanceId];
    if (!undoHistory) {
      undoHistory = { history: [] };
      this.undoHistories[this.routerStore.uiInstanceId] = undoHistory;
    }

    // clone config and parse+stringify to remove mobx wrappers
    const undoItem: IUndoItem = JSON.parse(
      JSON.stringify({ workingVersion: this.suggestionListToEdit?.workingVersion })
    );
    undoHistory.history.push(undoItem);

    if (undoHistory.history.length > MAX_UNDO_HISTORY) {
      undoHistory.history.shift();
    }

    undoHistory.undoIndex = undoHistory.history.length - 1;
  }

  @action
  async undoLastSave(): Promise<void> {
    if (!this.canUndo) {
      return;
    }

    const undoHistory = this.undoHistories[this.routerStore.uiInstanceId];
    if (!undoHistory || undoHistory.undoIndex === undefined) {
      // nothing to undo
      return;
    }

    if (undoHistory.undoIndex === undoHistory.history.length - 1) {
      this.addToUndoHistory();
      undoHistory.undoIndex -= 1;
    }

    // read from history and parse+stringify to remove mobx wrappers
    const raw: IUndoItem = JSON.parse(JSON.stringify(undoHistory.history[undoHistory.undoIndex!]));
    const suggestionList = this.suggestionListToEdit!;
    if (raw.workingVersion) {
      suggestionList.workingVersion = raw.workingVersion;
    } else {
      suggestionList.workingVersion.suggestions = [];
    }

    this.saving = true;
    try {
      const savePromise = this.uiConfigService.updateSuggestionListVersion(suggestionList.workingVersion);
      if (!this.disableMessages) {
        message.success('Undo succeeded');
      }
      undoHistory.undoIndex = undoHistory.undoIndex! - 1;
      this.lastUndoOrRedoOrRevertAt = new Date().toISOString();
      await savePromise;
    } catch (err) {
      this.handleServerError(
        err,
        'suggestion',
        'There was an unexpected error undoing the changes. Please try again later.'
      );
      throw err;
    } finally {
      runInAction(() => (this.saving = false));
    }
  }

  @action
  async redoLastUndo(): Promise<void> {
    if (!this.canRedo) {
      return;
    }

    const undoHistory = this.undoHistories[this.routerStore.uiInstanceId];
    if (
      !undoHistory ||
      undoHistory.undoIndex === undefined ||
      undoHistory.undoIndex >= undoHistory.history.length - 1
    ) {
      // nothing to redo
      return;
    }
    // read from history and parse+stringify to remove mobx wrappers
    const raw: IUndoItem = JSON.parse(JSON.stringify(undoHistory.history[undoHistory.undoIndex + 2]));
    const suggestionList = this.suggestionListToEdit!;
    suggestionList.workingVersion = raw.workingVersion!;

    this.saving = true;
    try {
      const savePromise = this.uiConfigService.updateSuggestionListVersion(suggestionList.workingVersion);

      undoHistory!.undoIndex = undoHistory!.undoIndex! + 1;
      if (!this.disableMessages) {
        message.success('Redo succeeded');
      }
      this.lastUndoOrRedoOrRevertAt = new Date().toISOString();
      await savePromise;
    } catch (err) {
      this.handleServerError(
        err,
        'suggestion',
        'There was an unexpected error redoing the changes. Please try again later.'
      );
      throw err;
    } finally {
      runInAction(() => (this.saving = false));
    }
  }

  getUiConfigurationToEdit(): UiConfiguration {
    const { uiConfigurationToEdit } = this.resolveUiStore;
    if (!uiConfigurationToEdit) {
      throw new Error('no current configuration');
    }

    return uiConfigurationToEdit;
  }

  getWorkflows(): IWorkflow[] {
    if (this.workflowStore.workflows.fulfilled && this.uiInstance) {
      const workflowsForInstance = this.workflowStore.workflows.value.filter(
        workflow =>
          workflow.workingVersion.uiInstanceIds.includes(this.uiInstance!.id) &&
          workflow.purpose !== WORKFLOW_PURPOSE.SURVEY
      );
      return sortBy(workflowsForInstance, 'name');
    }
    return [];
  }

  @computed
  get workflowsLoading(): boolean {
    return this.workflowStore.workflows.pending;
  }

  @computed
  get numActiveSlots() {
    return 3;
  }

  // visible suggestions are active suggestions that are linked to query or enabled workflow
  @computed
  get visibleSuggestions(): ISuggestion[] {
    const workflows = this.getWorkflows();

    return (
      this.activeSuggestionsWithEmptySlots
        // remove placeholders
        .filter(s => !s.placeholder)
        // visible suggestions must be enabled
        .filter(suggestion => suggestion.enabled)
        // visible suggestions must either link to a query or an enabled workflow
        .filter(suggestion => {
          if (suggestion.workflowId) {
            const selectedWorkflow = workflows.find(w => (w.legacyId || w.id) === suggestion.workflowId);
            if (selectedWorkflow && !selectedWorkflow.enabled) {
              return false;
            }
          }
          return true;
        })
    );
  }

  @computed
  get haveAnyUrlSuggstions() {
    return (this.allSuggestions || []).some(s => s.urlRegex);
  }

  @computed
  get suggestionsInPositionOrder(): ISuggestion[] {
    // start with suggestions which have a recorded position
    const suggestionsWithPosition: ISuggestion[] = (this.allSuggestions || [])
      .filter(s => !isNil(s.position))
      .sort((a, b) => a.position! - b.position!);

    const suggestionsWithoutPosition: ISuggestion[] = (this.allSuggestions || []).filter(s => isNil(s.position));

    return [...suggestionsWithPosition, ...suggestionsWithoutPosition];
  }

  @computed
  get activeSuggestionsWithEmptySlots(): ISuggestion[] {
    const allSuggestions = this.suggestionsInPositionOrder;
    const activeSuggestions = allSuggestions.filter(s => s.enabled);
    const inactiveSuggestions = allSuggestions.filter(s => !s.enabled);

    const suggestions: ISuggestion[] = [];

    // slot in active suggestions
    activeSuggestions.forEach(suggestion => {
      if (!isNil(suggestion.position)) {
        suggestions[suggestion.position - 1] = suggestion;
      } else {
        suggestions.push(suggestion);
      }
    });

    let maxSuggestions = Math.max(this.numActiveSlots, suggestions.length);

    // when URL suggestions exist, make sure to show a blank slot at the end if there are any
    // inactive solutions so that they can potentially be made active
    const allSlotsFilled = suggestions.length === suggestions.filter(Boolean).length;
    if (
      this.haveAnyUrlSuggstions &&
      inactiveSuggestions.length > 0 &&
      suggestions.length >= this.numActiveSlots &&
      allSlotsFilled
    ) {
      maxSuggestions += 1;
    }

    // fill in blank spots with placeholders
    for (let i = 0; i < maxSuggestions; i++) {
      if (!suggestions[i]) {
        suggestions[i] = {
          id: `placeholder-${i}`,
          lockVersion: 1,
          placeholder: true,
          name: '',
          updatedAt: new Date().toISOString(),
          text: '',
          enabled: false,
          position: i + 1
        };
      }
    }

    return suggestions;
  }

  @computed
  get canUndo() {
    const undoHistory = this.undoHistories[this.routerStore.uiInstanceId];
    return (
      undoHistory && undoHistory.history.length > 0 && undoHistory.undoIndex !== undefined && undoHistory.undoIndex >= 0
    );
  }

  @computed
  get canRedo() {
    const undoHistory = this.undoHistories[this.routerStore.uiInstanceId];
    return undoHistory && undoHistory.undoIndex !== undefined && undoHistory.undoIndex + 2 < undoHistory.history.length;
  }

  prepForUnpublishedChangesCompare(suggestionListVersion: Partial<ISuggestionListVersion>): void {
    // delete properties that are always different
    delete suggestionListVersion.id;
    const commonExcludeFields = [
      'createdAt',
      'updatedAt',
      'overallUpdatedAt',
      'overallUpdatedByUserId',
      'overallUpdatedByUser',
      'updatedByUser',
      'updatedByUserId',
      'publishedAt',
      'publishedComments',
      'publishedVersion',
      'lockVersion',
      'suggestionListVersionId',
      '__typename'
    ];
    const removeCommon = (inputObj: any) => {
      const objs = Array.isArray(inputObj) ? inputObj : [inputObj];
      for (const obj of objs || []) {
        if (obj && typeof obj === 'object') {
          for (const field of commonExcludeFields) {
            delete obj[field];
          }
          removeCommon(Object.values(obj));
        }
      }
    };
    removeCommon(suggestionListVersion);

    // put suggestions into maps by ID instead of arrays for easier visual diffing
    const makeMapByIds = (objs: Array<{ id: string }>) =>
      objs.reduce((acc, obj) => {
        if (obj) {
          acc[obj.id] = obj;
        }
        return acc;
      }, {});
    (suggestionListVersion as any).suggestions = makeMapByIds(suggestionListVersion.suggestions || []);
  }

  getUnpublishedChanges(suggestionList?: ISuggestionList): IUnpublishedChanges[] {
    if (!suggestionList) {
      return [];
    }

    const changes: IUnpublishedChanges[] = [];

    const workingVersion = cloneDeep(suggestionList.workingVersion);
    const publishedVersion = cloneDeep(suggestionList.publishedVersion);

    this.prepForUnpublishedChangesCompare(workingVersion);
    this.prepForUnpublishedChangesCompare(publishedVersion);

    // compare working and published
    const changed = !isEqual(workingVersion, publishedVersion);

    changes.push({
      changed,
      path: { path: `suggestion-list-${suggestionList.id}` },
      published: publishedVersion,
      preview: workingVersion
    });

    return changes;
  }

  getHasUnpublishedChanges(suggestionList?: ISuggestionList): boolean {
    return suggestionList
      ? this.getUnpublishedChanges(suggestionList).filter(change => change.changed).length > 0
      : false;
  }

  @computed
  get uiConfiguration(): UiConfiguration | undefined {
    return this.resolveUiStore.getUiConfigurationByInstanceId(this.routerStore.uiInstanceId);
  }

  @computed
  get uiInstance(): UiInstance | ConversationalUiInstance | SunshineUiInstance | undefined {
    return this.resolveUiStore.getUiInstanceById(this.routerStore.uiInstanceId);
  }

  @computed
  get solvvyUiInstance(): UiInstance | ConversationalUiInstance | undefined {
    const uiInstance = this.resolveUiStore.getUiInstanceById(this.routerStore.uiInstanceId);
    if (uiInstance?.isSolvvyUiInstance) {
      return uiInstance;
    }

    return undefined;
  }

  @computed
  get isBotInstance(): boolean {
    if (!this.uiInstance) {
      return false;
    }
    return this.uiInstance instanceof SunshineUiInstance;
  }

  @computed
  get isUiInstanceConversational(): boolean {
    return this.solvvyUiInstance?.dialogType === DialogType.Conversational || this.isBotInstance;
  }

  @computed
  get isUiInstanceProfessional(): boolean {
    return (
      this.solvvyUiInstance?.dialogType === DialogType.Professional ||
      this.solvvyUiInstance?.dialogType === DialogType.ConversationalLegacy
    );
  }

  @computed
  get uiInstanceName(): string {
    return this.resolveUiStore.getUiInstanceDisplayName(this.uiConfiguration, this.uiInstance);
  }

  @computed
  get allSuggestions(): ISuggestion[] {
    return this.suggestionListToEdit?.workingVersion?.suggestions || [];
  }

  @computed
  get activeSuggestions(): ISuggestion[] {
    return (this.allSuggestions || []).filter(s => s.enabled);
  }

  @computed
  get inactiveSuggestions(): ISuggestion[] {
    return (this.allSuggestions || []).filter(s => !s.enabled);
  }

  handleServerError(err: Error, objectName: string, defaultMessageText: string) {
    recordErrors(err);
    let messageText = defaultMessageText;
    if (err.message.match(/Record .*in DB has a newer version than expected/)) {
      messageText = `Someone else has already modified this ${objectName}. Please refresh the page, reapply your changes and then save again.`;
      if (!this.showingErrorModal) {
        this.showingErrorModal = true;
        Modal.error({
          title: 'Oops something went wrong',
          content: messageText,
          okText: 'Refresh',
          keyboard: false,
          width: 600,
          onOk: () => {
            window.location.reload();
          }
        });
      }
    } else {
      message.error(defaultMessageText, 5);
    }
  }
}
