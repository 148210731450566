import { allUsingApi } from './util';

const getRecommendation = (api, orgId, userId, offset) => {
  return api.get('/v1/recommendations/include-viewed', {
    org_id: orgId,
    user_id: userId || 0,
    offset,
    embed: 'query'
  });
};

const getVotedQueries = (api, orgId, userId, status, start_at, end_at, offset, tara_mode) => {
  return api.get('/v1/recommendations/status-by-query-not-audited', {
    org_id: orgId,
    user_id: userId || 0,
    offset,
    status,
    start_at,
    end_at,
    tara_mode
  });
};

const getCoachStats = (api, orgId, userId) => {
  return api.get('/v1/recommendations/stats-by-org-and-user', {
    org_id: orgId,
    user_id: userId
  });
};

// Function: markAsViewed
//
// Updates a given recommendation status using the recommendation id.
// Arguments:
//  - id: The recommendation id. This is not the query id.
//  - status: The status to update to.
// Returns: a promise that resolves to a recommendation object.
const updateRecommendationStatus = (
  api,
  { id, status, user_id, org_id, audited = false, audited_by = null, tara_mode }
) => {
  return api.put(`/v1/recommendations/${id}/recommendationStatus`, {
    status,
    user_id,
    org_id,
    audited,
    audited_by,
    ...(tara_mode && { tara_mode })
  });
};

// Function: checkExists
//
// Gets a given recommendation using the query id.
// Arguments:
//  - query_id: The query id. This is not the recommendation id.
// Returns: a promise that resolves to a recommendation object.
const checkIfQueriesExists = async (api, query_ids, org_id, status = ['addressed', 'not_deflectable']) => {
  return await api.get(`/v1/recommendations/by-query-status`, {
    org_id,
    query_ids,
    status
  });
};

// Function: insertAsViewed
//
// Inserts a given question into api_tutor as a new recommendation.
// Arguments:
//  - org_id: The org id the user is signed in as.
//  - query_id: The query id of the question.
//  - analysis_id: Re Use the same analysis id of the current training set.
//  - status: The status to set the recommendation as.
const insertAsViewed = (
  api,
  { org_id, query_id, analysis_id, status, user_id, audited = false, audited_by = null, tara_mode = false }
) => {
  return api.post(`/v1/recommendations`, {
    org_id,
    query_id,
    analysis_id,
    status,
    quality: 0.5, // Adding arbitrary quality for now
    quality_a: 0.5, // Adding arbitrary quality for now
    scope: 'public_test',
    user_id,
    audited,
    audited_by,
    tara_mode
  });
};

export const recommendationService = api => {
  return allUsingApi(api, {
    getRecommendation,
    getCoachStats,
    insertAsViewed,
    checkIfQueriesExists,
    updateRecommendationStatus,
    getVotedQueries
  });
};

export default recommendationService;
