import gql from 'graphql-tag';

import { allUsingApi } from './util';

const CategoryFieldsFragment = gql`
  fragment categoryFields on Category {
    id
    name
    mostRepresentativeKeywords(orgId: $orgId, period: { start: $start, end: $end }, first: $count) {
      edges {
        node {
          keyword {
            text
          }
          fraction
          representativeness
        }
      }
    }
    mostRepresentativeQueries(orgId: $orgId, period: { start: $start, end: $end }, first: $count) {
      edges {
        node {
          query {
            text
            launchURL
          }
          representativeness
        }
      }
    }
    topLaunchURLs(orgId: $orgId, period: { start: $start, end: $end }, first: $launchCount) {
      edges {
        node {
          launchURL
          count
        }
      }
    }
  }
`;

const CategoryTimelineFragment = gql`
  fragment categoryTimeline on Category {
    id
    name
    queryStatisticsTrend(orgId: $orgId, period: { start: $start, end: $end }, bins: $bins) {
      period {
        start
        end
      }
      statistics {
        category {
          id
          name
        }
        count
        fraction
        ssr
      }
    }
  }
`;

const CategoryInfoQuery = gql`
  query($id: ID!, $orgId: Int!, $start: DateTime!, $end: DateTime!, $count: Int!, $launchCount: Int!) {
    node(id: $id) {
      ... on Category {
        ...categoryFields
      }
    }
  }
  ${CategoryFieldsFragment}
`;

const CategoryTimelineInfoQuery = gql`
  query($id: ID!, $orgId: Int!, $start: DateTime!, $end: DateTime!, $bins: Int!) {
    node(id: $id) {
      ... on Category {
        ...categoryTimeline
      }
    }
  }
  ${CategoryTimelineFragment}
`;

const queryCategoriesWithMostQueries = (
  api,
  { org_id, sort_by, count = 5, sort_order = 'DESC', start_time, end_time }
) => {
  return api.gqlQuery({
    query: gql`
      query{
        categoryQueryStatistics(orgId: ${org_id}, period: {start: "${start_time}", end: "${end_time}"}, 
          first: ${count}, orderBy : { field: ${sort_by} direction: ${sort_order} }) {
            edges {
              node {
                category {
                  id
                  name
                }
                count
                ssr
                supportContactedCount
              }
            }
          }
      }
    `
  });
};

const topMovingCategories = (api, { org_id, count = 5, start_time, end_time }) => {
  return api.gqlQuery({
    query: gql`
      query($orgId: Int!, $start: DateTime!, $end: DateTime!, $count: Int!) {
        biggestCategoryMovements(orgId: $orgId, targetPeriod: { start: $start, end: $end }, first: $count) {
          edges {
            node {
              category {
                id
                name
                queryStatistics(orgId: $orgId, period: { start: $start, end: $end }) {
                  count
                  ssr
                  supportContactedCount
                }
              }
              movement
              reference {
                fraction
              }
              target {
                fraction
              }
            }
          }
        }
      }
    `,
    variables: {
      orgId: org_id,
      start: start_time,
      end: end_time,
      count
    }
  });
};

const retrieveSingleCategoryDetails = (api, { org_id, id, start_time, end_time }) => {
  return api.gqlQuery({
    query: CategoryInfoQuery,
    variables: {
      id,
      orgId: org_id,
      start: start_time,
      end: end_time,
      count: 10,
      launchCount: 5
    }
  });
};

const retrieveCategoryTimeline = (api, { org_id, id, start_time, end_time, bins = 14 }) => {
  return api.gqlQuery({
    query: CategoryTimelineInfoQuery,
    variables: {
      id,
      orgId: org_id,
      start: start_time,
      end: end_time,
      bins
    },
    fetchPolicy: 'network-only'
  });
};

const retrieveTotalQueries = (api, { org_id, start_time, end_time }) => {
  return api.gqlQuery({
    query: gql`
      query {
        queryStatistics(orgId: ${org_id}, period: {start: "${start_time}", end: "${end_time}"}) {
          total
        }
      }
    `
  });
};

const insightsService = api => {
  return allUsingApi(api, {
    topMovingCategories,
    queryCategoriesWithMostQueries,
    retrieveTotalQueries,
    retrieveSingleCategoryDetails,
    retrieveCategoryTimeline
  });
};

export default insightsService;
