import createApi from './api';
import createConnectorService from './connectorService';
import createDataAnalyticsService from './dataAnalyticsService';
import createEventsService from './eventsService';
import createExploreService from './exploreService';
import createExportQueriesService from './exportQueriesService';
import createFeedbackService from './feedbackService';
import createInsightsService from './insightsService';
import IntentService from './intentService';
import createLiveChatService from './liveChatService';
import createMessagesService from './messagesService';
import PluginService from './pluginService';
import createRecommendationService from './recommendationService';
import createReportsService from './reportsService';
import createResolveUiConfigurationService from './resolveUIConfigurationService';
import createResourcesService from './resourcesService';
import createSearchService from './searchService';
import createUiConfigService from './uiConfigService';
import createUserService from './userService';

export const api = createApi();
export const userService = createUserService(api);
export const connectorService = createConnectorService(api);
export const recommendationService = createRecommendationService(api);
export const feedbackService = createFeedbackService(api);
export const searchService = createSearchService(api);
export const resolveUiConfigurationService = createResolveUiConfigurationService(api);
export const eventsService = createEventsService(api);
export const exportQueriesService = createExportQueriesService(api);
export const whatsNewMessageService = createMessagesService(api);
export const insightsService = createInsightsService(api);
export const exploreService = createExploreService(api);
export const resourcesService = createResourcesService(api);
export const dataAnalyticsService = createDataAnalyticsService(api);
export const reportsService = createReportsService(api);
export const liveChatService = createLiveChatService(api);
export const uiConfigService = createUiConfigService(api);
export const pluginService = PluginService(api);
export const intentService = IntentService(api);
