import { JSONSchema4 } from 'json-schema';
import truncate from 'lodash/truncate';
import { IDataSource } from '../data-source-types';
import { WorkflowStore } from '../workflowStore';
import { IComponent, IStep } from './Workflow';

export const STEP_BUTTONS_DATA_SOURCE_ID = 'StepButtonsDataSource';
export class StepButtonsDataSource implements IDataSource {
  id: string = STEP_BUTTONS_DATA_SOURCE_ID;
  name: string = 'Previously Clicked Button';
  variablesJsonSchema: JSONSchema4 = { properties: {} };

  constructor(steps: IStep[]) {
    for (const step of steps) {
      const buttons = this.extractButtons(step.components);
      if (buttons.length > 0 && this.variablesJsonSchema.properties) {
        this.variablesJsonSchema.properties[step.id] = {
          templateExpression: `{{workflow.step.${step.id}.lastButtonClicked.buttonId}}`,
          title: `from ${truncate(WorkflowStore.getStepOptionHeading(step), { length: 50 })}`,
          type: 'string',
          oneOf: buttons.map((button, index) => ({
            const: button.id,
            title: button.text || ''
          }))
        };
      }
    }
  }

  extractButtons(components: IComponent[]): IComponent[] {
    let buttons: IComponent[] = [];
    for (const component of components) {
      // only include buttons with an id and text
      if (component && component.type === 'button' && component.id && component.text) {
        buttons.push(component as IComponent);
      } else if (component && component.childComponents) {
        buttons = buttons.concat(this.extractButtons(component.childComponents));
      }
    }

    return buttons;
  }
}
