import { message, Modal } from 'antd';
import bind from 'bind-decorator';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import set from 'lodash/set';
import { action, computed, observable, runInAction } from 'mobx';
import { NotificationManager } from '../../shared/util/NotificationManager';
import { recordErrors } from '../../shared/util/recordErrors';
import { ConversationalUiInstance } from './ConversationalUiInstance';
import { createInstance, unescape } from './instanceUtils';
import { SunshineUiInstance } from './SunshineUiInstance';
import { IConfigurationPath } from './types';
import { UiInstance } from './UiInstance';

export enum PendingOperation {
  save = 'save',
  delete = 'delete',
  read = 'read'
}

export enum UiConfigurationState {
  PUBLISHED = 'dashboard-published',
  PREVIEW = 'dashboard-preview'
}

interface ISavePreviewAndPublishOptions {
  disableLoadingFlag?: boolean;
  disablePreviewSave?: boolean;
  disableNotification?: boolean;
}

export interface IUnpublishedChanges {
  path: IConfigurationPath;
  published: any;
  preview: any;
  changed: boolean;
}

export class UiConfiguration {
  static setPublishedFromPreviewForPaths(configPaths, previewRaw, publishedRaw) {
    configPaths.forEach(uiConfigurationPath => {
      const path = typeof uiConfigurationPath === 'object' ? uiConfigurationPath.path : uiConfigurationPath;
      const partialToPublish = get(previewRaw, path);
      set(publishedRaw, path, partialToPublish);
    });
  }

  static deletePathsAtIndex(arrayPath, indexToDelete, previewRaw, publishedRaw) {
    const previewArray = get(previewRaw, arrayPath);
    if (!previewArray || indexToDelete >= previewArray.length) {
      throw Error(`Cannot delete item in preview array ${arrayPath} at index ${indexToDelete}`);
    }

    const publishedArray = get(publishedRaw, arrayPath);
    if (!publishedArray || indexToDelete >= publishedArray.length) {
      throw Error(`Cannot delete item in published array ${arrayPath} at index ${indexToDelete}`);
    }

    // delete items in arrays at specified indexes
    previewArray.splice(indexToDelete, 1);
    publishedArray.splice(indexToDelete, 1);
  }

  id: string;
  lock_version: number;
  name: string;
  org_group_id: number;
  @observable
  modal_version: string;
  @observable
  ui_versions: { [style: string]: string };
  created_at: string;
  updated_at: string;
  @observable
  instances: UiInstance[];
  @observable
  restOfTheOrgInstances: UiInstance[];
  state: UiConfigurationState;
  @observable
  submittingPublished = false;
  @observable
  submittingPreview = false;
  @observable
  revertingUnpublished = false;
  include_builder_workflow?: boolean;

  // optional reference to published version of a preview configuration
  publishedUiConfiguration: UiConfiguration;

  @observable
  pendingOperation: PendingOperation | null = null;

  showingErrorModal: boolean = false;

  constructor(
    public notificationManager: NotificationManager,
    public resolveUiConfigurationService: any,
    public raw: any,
    private _orgTimeZone: string,
    private _orgSettings: any
  ) {
    this.setFieldsFromRaw(raw);
  }

  @action
  setFieldsFromRaw(raw) {
    this.id = raw.id;
    this.lock_version = raw.lock_version;
    this.name = raw.name;
    this.org_group_id = raw.org_group_id;
    this.state = raw.state;
    this.modal_version = raw.modal_version;
    this.ui_versions = raw.ui_versions;
    if (!this.ui_versions && raw.modal_version) {
      this.ui_versions = {};
    }
    this.created_at = raw.created_at;
    this.updated_at = raw.updated_at;

    this.instances = raw.instances.map((instance: any) => {
      return createInstance(instance, this._orgTimeZone, this._orgSettings, this);
    });
    this.restOfTheOrgInstances = raw.restOfTheOrgInstances;
    this.include_builder_workflow = raw.include_builder_workflow;
  }

  toRaw(): any {
    if (!this.raw) {
      return {};
    }

    const restOfTheOrgInstances = this.restOfTheOrgInstances || [];
    const rawInstances = this.instances.map((instance: UiInstance | ConversationalUiInstance | SunshineUiInstance) => {
      if (instance instanceof UiInstance) {
        return UiInstance.createRaw(instance);
      } else if (instance instanceof ConversationalUiInstance) {
        return ConversationalUiInstance.createRaw(instance);
      } else if (instance instanceof SunshineUiInstance) {
        return SunshineUiInstance.createRaw(instance);
      } else {
        throw new Error(
          `unknown UI instance type for ${(instance as any).name} ${(instance as any).id} (config ${this.name})`
        );
      }
    });
    return {
      ...omit(this.raw, 'restOfTheOrgInstances'),
      name: this.name,
      instances: [...rawInstances, ...restOfTheOrgInstances],
      state: this.state,
      include_builder_workflow: this.include_builder_workflow,
      lock_version: undefined // will be set explicitly before saving
    };
  }

  /**
   * Return true if there are unpublished changes within the scope of the specified UI configuration paths
   */
  getPartiallyHaveUnpublishedChanges(configurationPaths: string[] | IConfigurationPath[]): boolean {
    return this.getPartiallyUnpublishedChanges(configurationPaths).filter(change => change.changed).length > 0;
  }

  /**
   * Recursively null out excluded paths in an arbitrary number of nested arrays
   *
   * Example excludePath values:
   *   options.flow.foo
   *   options.flow.screens[].position
   *   options.flow.screens[].components[].options.foo
   */
  nullOutExcludePaths(obj: object, excludePath: string) {
    const nestedArrayDelim = '[].';
    const excludePathParts: string[] = excludePath.split(nestedArrayDelim);

    if (!excludePathParts || excludePathParts.length === 0) {
      return;
    }

    if (excludePathParts.length === 1) {
      set(obj, excludePathParts[0], null);
    }

    const arrayObj = get(obj, excludePathParts[0]);
    if (arrayObj && Array.isArray(arrayObj)) {
      for (const subObj of arrayObj) {
        this.nullOutExcludePaths(subObj, excludePathParts.slice(1).join(nestedArrayDelim));
      }
    }
  }

  getPartiallyUnpublishedChanges(configurationPaths: Array<string | IConfigurationPath>): IUnpublishedChanges[] {
    const previewRaw = this.toRaw();
    const publishedRaw = this.publishedUiConfiguration.toRaw();

    return configurationPaths.map((pathOrObj: string | IConfigurationPath) => {
      const pathObj: IConfigurationPath = typeof pathOrObj === 'object' ? pathOrObj : { path: pathOrObj };

      let preview = cloneDeep(get(previewRaw, pathObj.path));
      let published = cloneDeep(get(publishedRaw, pathObj.path));

      // handle any exclude sub-paths
      if (pathObj.exclude) {
        for (const excludePath of pathObj.exclude) {
          if (Array.isArray(preview)) {
            for (const previewItem of preview) {
              this.nullOutExcludePaths(previewItem, excludePath);
            }
          } else {
            this.nullOutExcludePaths(preview, excludePath);
          }
          if (Array.isArray(published)) {
            for (const publishedItem of published) {
              this.nullOutExcludePaths(publishedItem, excludePath);
            }
          } else {
            this.nullOutExcludePaths(published, excludePath);
          }
        }
      }

      // handle treatValueAsNull (text overrides)
      if (pathObj.treatValueAsNull) {
        if (unescape(preview) === pathObj.treatValueAsNull) {
          preview = null;
        }
        if (unescape(published) === pathObj.treatValueAsNull) {
          published = null;
        }
      }

      // normalize undefined and null
      preview = isNil(preview) ? null : preview;
      published = isNil(published) ? null : published;

      return {
        path: pathObj,
        published,
        preview,
        changed: !isEqual(preview, published)
      };
    });
  }

  handleSaveOrPublishError(e) {
    let messageTxt = 'Update failed. Please try after some time.';
    const serverErrorName = get(e, 'response.data.name');
    if (serverErrorName === 'OptimisticLockError') {
      messageTxt =
        'Someone else has already modified this configuration. Please refresh the page, reapply your changes and then save again.';
    }
    recordErrors(e);
    if (!this.showingErrorModal) {
      this.showingErrorModal = true;
      Modal.error({
        title: 'Oops something went wrong',
        content: messageTxt,
        okText: 'Refresh',
        keyboard: false,
        width: 600,
        onOk: () => {
          window.location.reload();
        }
      });
    }
  }

  /**
   * Partially revert unpublish changes to a resolve UI configuration.
   *
   * Certain parts of the preview configuration (specified by _.get paths) are reverted by copying values from the
   * published configuration and then updating the preview configuration. The preview configuration is *not* deleted
   * after.
   *
   * @param configurationPaths array of specific lodash _.get paths to revert (on the resolve UI configuration raw JSON)
   */
  @bind
  @action
  async partiallyRevertUnpublishedChanges(
    configurationPaths: string[] | IConfigurationPath[],
    { disableNotification }: { disableNotification?: boolean } = {}
  ): Promise<boolean> {
    this.revertingUnpublished = true;

    try {
      // copy the partial config from published on top of preview
      const newPreviewRaw = this.toRaw();
      const publishedRaw = this.publishedUiConfiguration.toRaw();
      configurationPaths.forEach(uiConfigurationPath => {
        const path = typeof uiConfigurationPath === 'object' ? uiConfigurationPath.path : uiConfigurationPath;
        const partialToRevert = get(publishedRaw, path);
        set(newPreviewRaw, path, partialToRevert);
      });

      // update preview
      await this.resolveUiConfigurationService.updateById(this.id, {
        ...newPreviewRaw,
        lock_version: this.lock_version // the lock version from preview
      });

      this.raw = newPreviewRaw;
      this.raw.lock_version = this.lock_version + 1;
      this.setFieldsFromRaw(this.raw);

      if (!disableNotification) {
        message.success('Changes have been reverted');
      }

      return true;
    } catch (e) {
      this.handleSaveOrPublishError(e);
      return false;
    } finally {
      runInAction(() => (this.revertingUnpublished = true));
    }
  }

  @bind
  @action
  async savePreviewAndPublish(
    newPreviewRaw: any,
    newPublishedRaw: any,
    { disableLoadingFlag, disablePreviewSave, disableNotification }: ISavePreviewAndPublishOptions = {}
  ): Promise<boolean> {
    if (!disableLoadingFlag) {
      this.setSubmittingState(UiConfigurationState.PUBLISHED, true);
    }

    try {
      // get IDs of preview and published configurations
      const previewId = this.id;
      let publishedId = this.publishedUiConfiguration.id;

      // first update full preview copy to make sure there is no optimize lock error
      // unless preview save is disabled (e.g. happened already by caller)
      if (!disablePreviewSave) {
        const newConfig = {
          ...newPreviewRaw,
          id: previewId,
          state: UiConfigurationState.PREVIEW,
          lock_version: this.lock_version // the lock version from preview
        };

        // if creating preview for the first time
        if (previewId === publishedId) {
          const { data } = await this.resolveUiConfigurationService.postNewConfig(
            omit(newConfig, ['id', 'created_at', 'updated_at', 'lock_version'])
          );
          this.id = data.id;
          if (data.newPublishedId) {
            this.publishedUiConfiguration.id = data.newPublishedId;
            publishedId = data.newPublishedId;
          }
          this.lock_version = data.lock_version;
        } else {
          await this.resolveUiConfigurationService.updateById(newConfig.id, newConfig);
          this.lock_version += 1;
        }
      }

      // finally update the published copy with just the requested partial config from preview
      await this.resolveUiConfigurationService.updateById(publishedId, {
        ...newPublishedRaw,
        id: publishedId,
        state: UiConfigurationState.PUBLISHED,
        lock_version: this.publishedUiConfiguration.lock_version // the lock version from published
      });
      this.publishedUiConfiguration.lock_version += 1;

      this.raw = newPreviewRaw;
      this.raw.lock_version = this.lock_version;
      this.setFieldsFromRaw(this.raw);

      if (!disableNotification) {
        message.success('Changes have been published');
      }

      return true;
    } catch (e) {
      this.handleSaveOrPublishError(e);
      return false;
    } finally {
      if (!disableLoadingFlag) {
        this.setSubmittingState(UiConfigurationState.PUBLISHED, false);
      }
    }
  }

  /**
   * Partially publish a resolve UI configuration by copying certain from preview.
   *
   * Certain parts of the preview configuration (specified by _.get paths) are copied onto the top of the existing
   * published configuration and then the published configuration is updated. This also first updates the preview
   * configuration to force any potenial optimistic lock exceptions on it. The preview configuration is *not* deleted
   * after.
   *
   * @param configurationPaths array of specific lodash _.get paths to publish (on the resolve UI configuration raw JSON)
   */
  @bind
  @action
  async partiallyPublish(
    configurationPaths: string[] | IConfigurationPath[],
    options?: ISavePreviewAndPublishOptions
  ): Promise<boolean> {
    // copy the partial config from preview on top of current published
    const newPublishedRaw = this.publishedUiConfiguration.toRaw();
    const previewRaw = this.toRaw();

    UiConfiguration.setPublishedFromPreviewForPaths(configurationPaths, previewRaw, newPublishedRaw);

    return await this.savePreviewAndPublish(previewRaw, newPublishedRaw, options);
  }

  /**
   * Partially publish a resolve UI configuration by deleting some array elements.
   *
   * Certain parts of the preview configuration (specified by _.get paths) are copied onto the top of the existing
   * published configuration and then the published configuration is updated. This also first updates the preview
   * configuration to force any potenial optimistic lock exceptions on it. The preview configuration is *not* deleted
   * after.
   *
   * @param configurationPaths array of specific lodash _.get paths to publish (on the resolve UI configuration raw JSON)
   */
  @bind
  @action
  async partiallyPublishDeleteArrayIndex(
    arrayPath: string,
    indexToDelete: number,
    options?: ISavePreviewAndPublishOptions
  ): Promise<boolean> {
    const newPublishedRaw = this.publishedUiConfiguration.toRaw();
    const newPreviewRaw = this.toRaw();

    UiConfiguration.deletePathsAtIndex(arrayPath, indexToDelete, newPreviewRaw, newPublishedRaw);

    return this.savePreviewAndPublish(newPreviewRaw, newPublishedRaw, options);
  }

  @bind
  @action
  async update(newState: UiConfigurationState, disableNotification?: boolean): Promise<boolean> {
    this.setSubmittingState(newState, true);
    const oldState = this.state;

    this.state = newState;

    const newConfig: any = this.toRaw();

    if (newState !== UiConfigurationState.PREVIEW) {
      throw new Error('this function can only be used to update preview');
    }

    try {
      newConfig.lock_version = this.lock_version;

      if (oldState === UiConfigurationState.PUBLISHED) {
        const { data } = await this.resolveUiConfigurationService.postNewConfig(newConfig);
        if (data.newPublishedId) {
          this.publishedUiConfiguration.id = data.newPublishedId;
          delete data.newPublishedId;
        }
        this.id = data.id;
        this.lock_version = data.lock_version;
        this.raw.id = data.id;
      } else {
        await this.resolveUiConfigurationService.updateById(newConfig.id, newConfig);
        this.lock_version += 1;
      }

      if (!disableNotification) {
        message.success('Changes have been saved');
      }

      return true;
    } catch (e) {
      this.handleSaveOrPublishError(e);
      return false;
    } finally {
      this.setSubmittingState(newState, false);
    }
  }

  @action.bound
  setSubmittingState(state: UiConfigurationState, submitting: boolean) {
    if (state === UiConfigurationState.PREVIEW) {
      this.submittingPreview = submitting;
    } else if (state === UiConfigurationState.PUBLISHED) {
      this.submittingPublished = submitting;
    }
  }

  @computed
  get updated_at_long() {
    const date = new Date(this.updated_at);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  @computed
  get hasInstances() {
    return this.instances.length > 0;
  }
}
