import { Component, ReactNode } from 'react';

interface IProps {
  startSeconds: number;
  message: (value: number) => ReactNode;
}

interface IState {
  value: number;
}

export default class CountdownMessage extends Component<IProps, IState> {
  countdownInterval;

  constructor(props) {
    super(props);
    this.state = {
      value: this.props.startSeconds
    };
  }

  componentDidMount() {
    this.countdownInterval = setInterval(() => {
      if (this.state.value <= 0) {
        clearInterval(this.countdownInterval);
      } else {
        this.setState(({ value }) => ({ value: value - 1 }));
      }
    }, 1000);
  }

  componentWillUnmount() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

  render() {
    return this.props.message(this.state.value);
  }
}
