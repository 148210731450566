import {
  CaretRightOutlined,
  DownOutlined,
  InfoCircleTwoTone,
  LayoutOutlined,
  SearchOutlined,
  SettingOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { OrgPermission } from '@solvvy/util/lib/authorization';
import { Button, Col, Dropdown, Input, Menu, Modal, Popover, Switch, Tooltip } from 'antd';
import bind from 'bind-decorator';
import classNames from 'classnames';
import * as dagre from 'dagre';
import * as graphlib from 'graphlib';
import * as joint from 'jointjs/dist/joint';
import debounce from 'lodash/debounce';
import set from 'lodash/set';
import { action, reaction, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, RefObject } from 'react';
import { GlobalHotKeys } from 'react-hotkeys';
import FeatureFlagService, { FEATURE_FLAGS } from 'src/services/features';
import '../../../node_modules/jointjs/dist/joint.css';
import Loader from '../../shared/components/Loader';
import { Colors } from '../../shared/util/Colors';
import keyMap, { MOD_KEY } from '../../shared/util/keymap';
import { OrgStore } from '../../store/orgStore';
import { IComponent, INewStandaloneStep, IPosition, IStep } from '../../store/ResolveUI/Workflow';
import { typedInject } from '../../store/rootStore';
import {
  CANVAS_PADDING,
  CONDITION_LINK_COLOR,
  CONDITION_LINK_HIGHLIGHT_COLOR,
  LINK_COLOR,
  LINK_HIGHLIGHT_COLOR,
  LINK_WIDTH,
  PRINT_CANVAS_PADDING,
  VIEWPORT_PADDING,
  WorkflowStore
} from '../../store/workflowStore';
import { showConfirmToggleEnableModal } from './actionConfirmModals';
import ButtonBreakdown from './ButonBreakdown';
import { STEP_DISPLAY_TYPE } from './constants';
import NotesAndTags from './NotesAndTags';
import StepSearchDrawer from './StepSearchDrawer';
import { getComponentByIndexesStr, getConditionalStartStepIds } from './Util';
import { WorkflowCardArea, WorkflowEditAreaRow, WorkflowStatusArea } from './workflow.styles';
import WorkflowCard from './WorkflowCard';
import WorkflowEditHeader from './WorkflowEditHeader';

interface IProps {
  workflowStore: WorkflowStore;
  orgStore: OrgStore;
}

interface IState {
  zoomScale: number;
  showZoomMenu?: boolean;
  snapLinks: boolean;
  perpendicularLinks: boolean;
  spatialEditor: boolean;
  defaultRouter: string;
}

export const JOINTJS_CONTAINER_CLASS = 'jointjs-container';
const ZOOM_SCALE_INCREMENT = 0.125;
const ZOOM_SCALE_INCREMENT_MOUSE = 0.035;
const ZOOM_SCALE_MIN = 0.1;
const ZOOM_SCALE_MAX = 2;

window.joint = joint;
const CustomTargetArrowhead = joint.linkTools.TargetArrowhead.extend({
  attributes: {
    d: 'M -10 -5 0 0 -10 5 Z',
    fill: Colors.solvvyBlue,
    stroke: Colors.solvvyBlue,
    'stroke-width': 1,
    cursor: 'crosshair',
    class: 'target-arrowhead'
  }
});

const LS_SPATIAL_EDITOR = 'solvvy.wfb.spatialEditorOverride';
const LS_PERPENDICULAR = 'solvvy.wfb.perpendicularLinks';
const LS_ROUTER = 'solvvy.wfb.router';

const WORKFLOW_REFRESH_INTERVAL = 30000; // in milliseconds
@observer
class WorkflowEdit extends Component<IProps, IState> {
  state: IState = {
    zoomScale: 1.0,
    showZoomMenu: false,
    snapLinks: false,
    perpendicularLinks: Boolean(JSON.parse(window.localStorage.getItem(LS_PERPENDICULAR) || 'false')),
    spatialEditor: false,
    defaultRouter: window.localStorage.getItem(LS_ROUTER) || 'manhattan'
  };

  // TODO: can remove with legacy / fixed view / non-spatial mode
  cardRefs: { [id: string]: RefObject<HTMLDivElement> };
  scrollNewCardIntoView?: string;

  jointJsElementRef: RefObject<HTMLDivElement> = React.createRef();
  destroyOnStepChangeHandler;
  workflowRefreshTimer;
  scrollIntoViewAfterRender?: joint.dia.CellView;
  undoRedoReaction;
  refreshJointJsReaction;
  dialogTypeChangeReaction;
  optimizingLayout: boolean = false;
  optimizingLayoutTimeout: any = undefined;

  // stuff for printing
  printing: boolean = false;
  fixedViewport?: { width: number; height: number };
  afterNextRender?: () => void;
  origZoom?: number;
  origScrollLeft?: number;
  origScrollTop?: number;

  constructor(props: IProps) {
    super(props);

    let spatialEditor = props.orgStore.canAccessWorkflowSpatialEditor;
    const spatialEditorLocalStorageOverride = window.localStorage.getItem(LS_SPATIAL_EDITOR);
    if (spatialEditorLocalStorageOverride) {
      spatialEditor = Boolean(JSON.parse(spatialEditorLocalStorageOverride));
    }
    this.state.spatialEditor = spatialEditor;

    this.cardRefs = props.workflowStore.workflowToEdit.workingVersion.steps.reduce((acc, value) => {
      acc[value.id] = React.createRef<HTMLDivElement>();
      return acc;
    }, {});

    // re-render entire paper if undo/redo occurs
    this.undoRedoReaction = reaction(
      () => props.workflowStore.lastUndoOrRedoOrRevertAt,
      () => this.initJointJSPaper()
    );
    this.initWorkflowAutoRefresh();

    // re-render entire paper if workflow instance dialog type has changed
    this.dialogTypeChangeReaction = reaction(
      () => props.workflowStore.workflowIdToEdit && props.workflowStore.currentWorkflowInstanceDialogType,
      () => this.initJointJSPaper()
    );
  }

  initWorkflowAutoRefresh() {
    this.workflowRefreshTimer = setInterval(() => this.autoRefreshWorkflow(), WORKFLOW_REFRESH_INTERVAL);
  }

  autoRefreshWorkflow() {
    if (this.props.workflowStore.saving) {
      // When there is a pending auto-save API, stop auto-refresh. Check the auto-save API status until completed. After a small delay, start auto refresh again.
      // To avoid issues when there are continuous auto save API calls, auto-refresh is prevented from starting immediately after the current auto-save API call.
      clearInterval(this.workflowRefreshTimer);
      this.workflowRefreshTimer = undefined;
      setTimeout(() => this.autoRefreshWorkflow(), 3000);
    } else {
      this.props.workflowStore.workflowToEditAsyncComputed.refresh();

      if (!this.workflowRefreshTimer) {
        this.initWorkflowAutoRefresh();
      }
    }
  }

  getCanvasPadding() {
    return this.printing ? PRINT_CANVAS_PADDING : CANVAS_PADDING;
  }

  getViewportDimensions() {
    const element = this.jointJsElementRef.current;

    if (this.fixedViewport) {
      return this.fixedViewport;
    }

    // when printing, and it triggers a resize (e.g. the paper orientation is changed), it can't get the correct new width and
    // height from the container for some reason, so we can use the window width/height
    if (this.printing) {
      return { width: window.innerWidth, height: window.innerHeight };
    }

    if (element && element.parentElement) {
      return { width: element.parentElement.offsetWidth, height: element.parentElement.offsetHeight };
    }

    return { width: 1000, height: 1000 };
  }

  componentDidMount() {
    const { workflowStore } = this.props;
    const { workflowToEdit: workflow } = workflowStore;

    const { zoomScale: defaultZoomScale } = workflow.workingVersion.dashboardState;
    const { zoomScale: userZoomScale } = workflowStore.getUserSpecificWorkflowState(workflow.id);
    const zoomScale = userZoomScale || defaultZoomScale;

    this.setState({ zoomScale: zoomScale || 1 }, this.initJointJSPaper);

    // add window resize listener to grow or shrink canvas size as necessary
    window.addEventListener('resize', () => {
      runInAction(() => this.setCanvasSize());
      if (this.printing) {
        this.afterNextRender = () => this.zoomToFit(true);
      }
    });

    window.addEventListener('beforeprint', () => this.beforePrint());
    window.addEventListener('afterprint', () => this.afterPrint());

    // listen for step changes from the workflow store
    this.destroyOnStepChangeHandler = this.props.workflowStore.onStepChange(this.onStepChange);

    reaction(
      () => this.props.workflowStore.addNewStepOptions.addNewStepClicked,
      addNewStepClicked => {
        if (addNewStepClicked) {
          this.onClickAddStep(undefined, this.props.workflowStore.addNewStepOptions.stepName);
          this.props.workflowStore.addNewStepOptions.addNewStepClicked = false;
          this.props.workflowStore.addNewStepOptions.callBack?.();
        }
      }
    );

    // When a step is duplicated, update the canvas size and focus the duplicated step
    reaction(
      () => this.props.workflowStore.lastDuplicatedStep,
      lastDuplicatedStep => {
        this.setCanvasSize();
        this.props.workflowStore.scrollStepIntoView(lastDuplicatedStep);
      }
    );
  }

  componentWillUnmount() {
    if (this.destroyOnStepChangeHandler) {
      this.destroyOnStepChangeHandler();
    }
    if (this.workflowRefreshTimer) {
      clearInterval(this.workflowRefreshTimer);
      this.workflowRefreshTimer = undefined;
    }
    if (this.refreshJointJsReaction) {
      this.refreshJointJsReaction();
    }
    if (this.dialogTypeChangeReaction) {
      this.dialogTypeChangeReaction();
    }
  }

  componentDidUpdate() {
    // TODO: can remove when legacy / fixed view / non-spatial mode retired
    if (this.scrollNewCardIntoView) {
      const ref = this.cardRefs[this.scrollNewCardIntoView];
      if (ref && ref.current && ref.current.scrollIntoView) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
        this.scrollNewCardIntoView = undefined;
      }
    }
  }

  addLinksForStep(step: IStep) {
    for (let componentIndex = 0; componentIndex < step.components.length; componentIndex++) {
      const component = step.components[componentIndex];
      this.addLinksForComponent(step.id, step.dashboardState.position, [componentIndex], component);
    }
  }

  getActionOptionsStepIds(buttonAction): string[] {
    if (!buttonAction) {
      return [];
    }
    const { options } = buttonAction;

    if (buttonAction.type === 'screen') {
      return [options.screenId];
    } else if (buttonAction.type === 'condition') {
      let stepIds: string[] = [];
      for (const condition of options.conditions || []) {
        stepIds = stepIds.concat(this.getActionOptionsStepIds(condition.action));
      }
      stepIds = stepIds.concat(this.getActionOptionsStepIds(options.defaultAction));
      return stepIds;
    } else if (buttonAction.type === 'external_api') {
      let stepIds: string[] = [];
      stepIds = stepIds.concat(this.getActionOptionsStepIds(options.onSuccessAction));
      stepIds = stepIds.concat(this.getActionOptionsStepIds(options.onErrorAction));
      for (const errorAction of options.onErrorActions || []) {
        stepIds = stepIds.concat(this.getActionOptionsStepIds(errorAction));
      }
      return stepIds;
    }

    return [];
  }

  addLinksForComponent(
    srcStepId: string,
    position: IPosition | undefined,
    componentIndexes: number[],
    component: IComponent
  ) {
    const { workflowStore } = this.props;
    const srcStep = workflowStore.jointJsModelByStepId[srcStepId];
    const { type, options } = component;
    const { action: buttonAction } = options;
    if (type === 'button' && buttonAction && ['screen', 'condition'].includes(buttonAction.type)) {
      const dstStepIds = this.getActionOptionsStepIds(buttonAction);
      for (const dstStepId of dstStepIds) {
        const dstStep = workflowStore.jointJsModelByStepId[dstStepId];

        if (dstStep) {
          const color = buttonAction.type === 'condition' ? CONDITION_LINK_COLOR : LINK_COLOR;

          const link = new joint.shapes.standard.Link({ attrs: { line: { stroke: color, strokeWidth: LINK_WIDTH } } });
          link.prop('condition', buttonAction.type === 'condition');

          link.source({ id: srcStep.id, port: componentIndexes.join('-') });
          link.target(dstStep);
          link.addTo(workflowStore.graph);
          if (position) {
            link.set('z', position.z);
          }
        }
      }
    } else if (type === 'repeatingGroup') {
      const childComponents = component.childComponents || [];
      for (let childIndex = 0; childIndex < childComponents.length; childIndex++) {
        const childComponent = childComponents[childIndex];
        this.addLinksForComponent(srcStepId, position, componentIndexes.concat(childIndex), childComponent);
      }
    }
  }

  /**
   * When anything on the step/step changes from the step properties drawer,
   * remove the old JointJS element and create a new one in its place
   */
  @bind
  onStepChange(step: IStep) {
    if (!this.state.spatialEditor) {
      return;
    }

    const { workflowStore } = this.props;
    const { workflowToEdit: workflow } = this.props.workflowStore;
    const { graph, paper } = workflowStore;
    const { startStepId, options } = workflow.workingVersion;
    const { onStartAction, onStartOrContinueAction } = options || {};
    const anyStartAction = onStartAction || onStartOrContinueAction;

    // create a new JointJS WorkflowStep element
    const isEntryStep = step.id === startStepId;
    const isConditionalEntryStep = getConditionalStartStepIds(workflow).includes(step.id);
    const stepErrors = workflowStore.getStepErrors(step.id);

    const workflowStep = joint.shapes.solvvy.WorkflowStep.createFromStep(
      step,
      stepErrors,
      workflow,
      {
        isEntryStep: isEntryStep && !anyStartAction,
        isConditionalEntryStep,
        fullyEditable: workflowStore.workflowToEditIsFullyEditable,
        dialogType: workflowStore.currentWorkflowInstanceDialogType
      },
      workflowStore._dataSourcesStore.dataSources
    );
    const oldWorkflowStep = workflowStore.jointJsModelByStepId[step.id];
    workflowStore.jointJsModelByStepId[step.id] = workflowStep;

    let position = step.dashboardState.position;
    if (!position) {
      position = workflowStore.getNewStepPosition();
    }
    workflowStep.position(position.x, position.y);
    workflowStep.set('z', position.z);

    // handle when the step is moved
    workflowStep.on('change:position', debounce(this.onStepPositionChange, 500));

    // TODO: is there a better way to do this???
    // add new step to graph
    paper.dumpViews(); // necessary when using async rendering mode
    graph.addCell(workflowStep);

    if (oldWorkflowStep) {
      // move inbound links from old to new
      for (const link of graph.getConnectedLinks(oldWorkflowStep, { inbound: true })) {
        const { id: targetId, port: targetPort } = link.get('target');
        if (targetId === oldWorkflowStep.id) {
          link.set('target', { id: workflowStep.id, port: targetPort });
        }
      }

      // remove old step
      oldWorkflowStep.remove();
    }

    // TODO: is there a better way to do this???
    paper.dumpViews(); // necessary when using async rendering mode
    // paper.updateViews();
    // paper.requireView(workflowStep);

    this.addLinksForStep(step);

    // highlight step and links if necessary
    const cellView = paper.findViewByModel(workflowStep);
    if (cellView) {
      if (workflowStore.selectedStepId === step.id) {
        workflowStore.highlightStepAndLinks(cellView);
      }
    }
  }

  saveBeforePrintState() {
    // save zoom and scroll for restoration after print
    if (!this.origZoom) {
      this.origZoom = this.state.zoomScale;
      const element = this.jointJsElementRef.current;
      const container = element ? element.parentElement : undefined;
      if (container) {
        this.origScrollLeft = container.scrollLeft;
        this.origScrollTop = container.scrollTop;
      }
    }
  }

  @action.bound
  beforePrint(onPrintReady?: () => void) {
    this.saveBeforePrintState();
    // auto zoom to fit before printing
    this.printing = true;
    this.zoomToFit(true);
    if (onPrintReady) {
      onPrintReady();
    }
  }

  @bind
  afterPrint() {
    this.printing = false;
    const element = this.jointJsElementRef.current;
    const container = element ? element.parentElement : undefined;

    // restore orig zoom scale
    this.setZoomScale(this.origZoom);

    // restore scroll
    if (container) {
      container.scrollLeft = this.origScrollLeft!;
      container.scrollTop = this.origScrollTop!;
    }
    this.origZoom = undefined;
    this.origScrollLeft = undefined;
    this.origScrollTop = undefined;
  }

  @bind
  onPrint() {
    // when the window.print() is used, it pauses the JS engine and doesn't trigger any resize events (e.g. when paper orientation is changed)
    // so we need to force a reasonable width/height value to use for the viewport dimensions
    this.fixedViewport = { width: 1236, height: 1600 };
    this.saveBeforePrintState();
    this.beforePrint(() => window.print());
    this.fixedViewport = undefined;
  }

  @action.bound
  zoomIn(closeMenu: boolean, e?) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    this.setZoomScale(this.state.zoomScale + ZOOM_SCALE_INCREMENT, closeMenu);
  }

  @action.bound
  zoomOut(closeMenu: boolean, e?) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    this.setZoomScale(this.state.zoomScale - ZOOM_SCALE_INCREMENT, closeMenu);
  }

  @action.bound
  zoomToFit(closeMenu: boolean, e?) {
    const { workflowStore } = this.props;

    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const container = this.jointJsElementRef.current?.parentElement;
    if (!container || !workflowStore.paper) {
      return;
    }

    const { width: viewportWidth, height: viewportHeight } = this.getViewportDimensions();

    const {
      x: contentX,
      y: contentY,
      width: contentWidth,
      height: contentHeight
    } = workflowStore.paper.getContentArea();

    const newZoomScaleX = viewportWidth / (contentWidth + 2 * VIEWPORT_PADDING);
    const newZoomScaleY = viewportHeight / (contentHeight + 2 * VIEWPORT_PADDING);
    const newScale = Math.min(newZoomScaleX, newZoomScaleY, 1);
    this.setZoomScale(newScale, closeMenu);

    const { tx: translateX, ty: translateY } = workflowStore.paper.translate();
    container.scrollLeft = contentX * newScale - VIEWPORT_PADDING + translateX;
    container.scrollTop = contentY * newScale - VIEWPORT_PADDING + translateY;
  }

  @action.bound
  setZoomScale(zoomScale, closeMenu?: boolean, origin?: { x: number; y: number }) {
    const { workflowStore } = this.props;
    const { workflowToEdit: workflow } = this.props.workflowStore;

    const { paper } = workflowStore;

    const oldScale = this.state.zoomScale;
    const newScale = Math.min(Math.max(zoomScale, ZOOM_SCALE_MIN), ZOOM_SCALE_MAX);

    const container = this.jointJsElementRef.current?.parentElement;
    if (!container || !paper) {
      return;
    }

    const { width: viewportWidth } = this.getViewportDimensions();
    const { tx: translateX, ty: translateY } = paper.translate();

    if (origin) {
      // adjust origin in real cordinates to view cordinates
      origin.x = (origin.x + translateX) * oldScale - container.scrollLeft;
      origin.y = (origin.y + translateY) * oldScale - container.scrollTop;
    } else {
      // middle of viewport
      origin = {
        x: viewportWidth / 2,
        y: viewportWidth / 2
      };
    }
    const originX = origin.x;
    const originY = origin.y;

    const zoomPointX = (container.scrollLeft + origin.x) / oldScale;
    const zoomPointY = (container.scrollTop + origin.y) / oldScale;

    const zoomPointNewX = zoomPointX * newScale;
    const zoomPointNewY = zoomPointY * newScale;

    paper.scale(newScale, newScale);

    this.setCanvasSize(newScale);
    container.scrollLeft = zoomPointNewX - originX;
    container.scrollTop = zoomPointNewY - originY;
    workflowStore.setUserSpecificWorkflowState(workflow.id, { zoomScale: newScale });

    this.setState({
      zoomScale: newScale,
      ...(closeMenu ? { showZoomMenu: false } : {})
    });
  }

  @action.bound
  toggleZoomMenu() {
    this.setState(({ showZoomMenu }) => ({ showZoomMenu: !showZoomMenu }));
  }

  /**
   * Grow or shrink canvas based on bounding box of content
   */
  setCanvasSize(newZoomScale?: number) {
    const { workflowStore } = this.props;

    const paperElement = this.jointJsElementRef.current;
    if (!paperElement || !workflowStore.workflowIdToEdit) {
      return;
    }

    const { workflowToEdit: workflow } = workflowStore;
    const { paper } = workflowStore;

    const { width: viewportWidth, height: viewportHeight } = this.getViewportDimensions();
    const zoomScale = newZoomScale || this.state.zoomScale;

    const content = paper.getContentArea();

    const { tx: prevTranslateX, ty: prevTranslateY } = paper.translate();
    const translateX = (-content.x + this.getCanvasPadding() + VIEWPORT_PADDING) * zoomScale;
    const translateY = (-content.y + this.getCanvasPadding() + VIEWPORT_PADDING) * zoomScale;
    paper.translate(translateX, translateY);
    workflow.workingVersion.dashboardState.canvasTranslate = { x: translateX, y: translateY };

    const contentWidth = (content.x + content.width + this.getCanvasPadding()) * zoomScale + translateX;
    const contentHeight = (content.y + content.height + this.getCanvasPadding()) * zoomScale + translateY;
    const canvasWidth = Math.max(viewportWidth, contentWidth);
    const canvasHeight = Math.max(viewportHeight, contentHeight);
    paper.setDimensions(canvasWidth - 2, canvasHeight - 2);

    const container = paperElement.parentElement;
    if (container) {
      container.scrollBy(translateX - prevTranslateX, translateY - prevTranslateY);
    }
  }

  @action.bound
  initJointJSPaper() {
    const { workflowStore } = this.props;

    if (!this.state.spatialEditor || !workflowStore.workflowIdToEdit) {
      return;
    }

    const { workflowToEdit: workflow } = this.props.workflowStore;

    const paperElement = this.jointJsElementRef.current;
    if (!paperElement) {
      return;
    }
    paperElement.innerHTML = '';

    const graph = new joint.dia.Graph();
    workflowStore.graph = graph;
    const paper = new joint.dia.Paper({
      el: paperElement,
      model: graph,
      async: true,
      width: '100%',
      height: '100%',
      gridSize: 10,
      drawGrid: true,
      defaultLink: new joint.shapes.standard.Link({
        attrs: { line: { stroke: LINK_HIGHLIGHT_COLOR, strokeWidth: LINK_WIDTH } }
      }),
      linkPinning: true,
      perpendicularLinks: this.state.perpendicularLinks,
      snapLinks: this.state.snapLinks,
      multiLinks: false,
      defaultConnector: { name: 'rounded' },
      defaultRouter: {
        name: this.state.defaultRouter,
        args: {
          ...(this.state.defaultRouter === 'manhattan' || this.state.defaultRouter === 'metro'
            ? {
                // startDirections doesn't appear to work when using a port as the source?
                startDirections: ['right'],
                step: 10, // supposedly works best if this matches gridSize
                padding: 30,
                perpendicular: true,
                maxAllowedDirectionChange: 180
              }
            : {})
        }
      },
      highlighting: {
        default: {
          name: 'addClass',
          options: {
            className: 'highlight'
          }
        }
      },
      validateConnection: (cellViewS, magnetS, cellViewT, magnetT, end, linkView) => {
        // preventing a step's action to reference itself.
        if (cellViewS === cellViewT) {
          return false;
        }
        return true;
      },
      defaultConnectionPoint: { name: 'boundary' },
      interactive: this.props.orgStore.hasPermission(OrgPermission.workflowUpdate)
    } as any);
    workflowStore.paper = paper;

    paper.scale(this.state.zoomScale, this.state.zoomScale);

    const { canvasTranslate } = workflow.workingVersion.dashboardState;
    if (canvasTranslate) {
      const { x, y } = canvasTranslate;
      paper.translate(x, y);
    }

    // save scroll position when it changes
    const handleScrollChange = () => {
      const element = this.jointJsElementRef.current;
      const container = element ? element.parentElement : undefined;
      if (container) {
        workflowStore.setUserSpecificWorkflowState(workflow.id, {
          canvasScroll: {
            left: container.scrollLeft,
            top: container.scrollTop
          }
        });
      }
    };
    const debouncedHandleScrollChange = debounce(handleScrollChange, 500);

    // when the user drags on the blank canvas, scroll the container
    // this will need to be removed if we ever add a multi-step "selection" feature
    let blankPrevX;
    let blankPrevY;
    paper.on('blank:pointerdown', event => {
      blankPrevX = event.pageX;
      blankPrevY = event.pageY;
    });
    paper.on('blank:pointermove', event => {
      let dragX = 0;
      let dragY = 0;
      // skip the drag when the x position was not changed
      if (event?.pageX && event.pageX - blankPrevX !== 0) {
        dragX = blankPrevX - event?.pageX;
        blankPrevX = event.pageX;
      }
      // skip the drag when the y position was not changed
      if (event?.pageY && event.pageY - blankPrevY !== 0) {
        dragY = blankPrevY - event?.pageY;
        blankPrevY = event.pageY;
      }
      // scrollBy x and y
      if (dragX !== 0 || dragY !== 0) {
        paperElement.parentElement!.scrollBy(dragX, dragY);
        debouncedHandleScrollChange();
      }
    });

    // handle zoom using mousewheel when meta key also pressed
    const handleMouseWheel = (x, y, delta) => {
      const { zoomScale } = this.state;
      this.setZoomScale(zoomScale + delta * ZOOM_SCALE_INCREMENT_MOUSE * zoomScale, false, { x, y });
    };
    paper.on('blank:mousewheel', (event, x, y, delta) => {
      debouncedHandleScrollChange();
      if (event.metaKey) {
        event.preventDefault();
        handleMouseWheel(x, y, delta);
      }
    });
    paper.on('cell:mousewheel', (cellView, event, x, y, delta) => {
      debouncedHandleScrollChange();
      if (event.metaKey) {
        event.preventDefault();
        handleMouseWheel(x, y, delta);
      }
    });

    let elementPrevX;
    let elementPrevY;
    paper.on('element:pointerdown', (cellView: any, event) => {
      // store click coordinates so we can detect if a real move is happening or if should be a click
      elementPrevX = event.pageX;
      elementPrevY = event.pageY;

      // raise card when its moved
      cellView.model.toFront();

      const stepId = cellView.model.prop('stepId');
      if (stepId) {
        runInAction(() => {
          // update z position of step
          const step = workflowStore.getStepById(stepId);
          if (step) {
            const { x, y } = cellView.model.position();
            step!.dashboardState.position = { x, y, z: cellView.model.get('z') };
            const links = graph.getConnectedLinks(cellView.model);
            for (const link of links) {
              link.toFront();
            }
          }
        });
      }
    });

    const doElementClick = cellView => {
      const stepId = cellView.model.prop('stepId');
      if (stepId) {
        cellView.highlight();
        this.onClickStep(stepId);
      }
    };
    const debouncedDoElementClick = debounce(doElementClick, 500);

    paper.on('element:pointermove', (cellView, event) => {
      workflowStore.highlightStepAndLinks(cellView);

      const deltaX = (event?.pageX || 0) - elementPrevX;
      const deltaY = (event?.pageY || 0) - elementPrevY;
      // if move is so small that the X and Y havn't changed , then treat as regular click
      if (Math.abs(deltaX) <= 2 && Math.abs(deltaY) <= 2) {
        debouncedDoElementClick(cellView);
      } else {
        debouncedDoElementClick.cancel();
      }
    });

    // on step click or double click, unlight other steps and show properties
    paper.on('element:pointerclick', cellView => doElementClick(cellView));
    paper.on('element:pointerdblclick', cellView => doElementClick(cellView));

    // TODO: on step right click, show step actions
    // paper.on('element:contextmenu', cellView => { });

    // highlight step on mouseenter
    paper.on('element:mouseenter', cellView => {
      workflowStore.highlightStepAndLinks(cellView);
    });

    // unhighlight on mouseleave (unless step is selected)
    paper.on('element:mouseleave', (cellView: any) => {
      const stepId = cellView.model.prop('stepId');

      const isStepSelected = id => {
        if (workflowStore.selectedSteps.length > 1) {
          return workflowStore.selectedSteps.find(s => s.id === id) ? true : false;
        } else {
          return workflowStore.selectedStepId === id;
        }
      };
      if (!isStepSelected(stepId)) {
        cellView.unhighlight();

        // unhighlight connecting lines (unless to or from selected step)
        const links = graph.getConnectedLinks(cellView.model);
        for (const link of links) {
          this.unhighlightLink(link);
        }
      }
    });

    // enable dragging target arrow to change or remove step link
    paper.on('link:mouseenter', (linkView: any) => {
      if (!linkView.model.prop('condition')) {
        const tools = new joint.dia.ToolsView({
          tools: [
            // new joint.linkTools.Vertices(),
            // new joint.linkTools.TargetAnchor(),
            // new CustomTargetArrowhead({ focusOpacity: 0.5 })
            new CustomTargetArrowhead()
            // new joint.linkTools.Segments()
            // new joint.linkTools.Boundary()
          ]
        });
        linkView.addTools(tools);
      }

      // highlight link
      linkView.model.attr(
        'line/stroke',
        linkView.model.prop('condition') ? CONDITION_LINK_HIGHLIGHT_COLOR : LINK_HIGHLIGHT_COLOR
      );
    });
    paper.on('link:mouseleave', (linkView: any) => {
      linkView.removeTools();
      this.unhighlightLink(linkView.model);
    });

    // handle link connection to set target step
    paper.on('link:connect', (linkView: any, event, elementViewConnected: any) => {
      runInAction(() => {
        const { id: srcModelId } = linkView.model.get('source');
        const srcStepModel = paper.getModelById(srcModelId);

        // get component ID from source port ID
        const srcComponentIndexes = linkView.model.get('source').port;
        const srcStepId = srcStepModel.prop('stepId');
        const dstStepId = elementViewConnected.model.prop('stepId');

        // remove any existing links from same src button
        const outLinks = workflowStore.graph.getConnectedLinks(srcStepModel, { outbound: true });
        for (const link of outLinks) {
          if (link.id !== linkView.model.id && link.get('source').port === linkView.model.get('source').port) {
            link.remove();
          }
        }

        const srcStep = workflowStore.getStepById(srcStepId);
        if (srcStep && srcComponentIndexes) {
          const srcComponent = getComponentByIndexesStr(srcStep, srcComponentIndexes);

          if (srcComponent) {
            set(srcComponent, 'options.action.options.screenId', dstStepId);
            // run onStepChange on next tick to allow rest of mouse events to occur (e.g. element:mouseleave) before
            // the view is replaced (in order to property unlighlight links when mouse no longer hovers over step
            setTimeout(() => this.onStepChange(srcStep), 0);
            workflowStore.updateComponent(srcComponent);
          }
        }
      });
    });

    // handle dragging link target arrow onto blank space in order to disconnect
    paper.on('link:disconnect', (linkView: any, event, elementViewDisconnected: any) => {
      const dstStep = elementViewDisconnected.model;
      if (dstStep) {
        const srcStepModel = linkView.model.getSourceElement();
        // get component index from source port ID
        const srcComponentIndexes = linkView.model.get('source').port;
        const srcStepId = srcStepModel.prop('stepId');
        linkView.model.remove();

        runInAction(() => {
          const srcStep = workflowStore.getStepById(srcStepId);
          if (srcStep && srcComponentIndexes) {
            const srcComponent = getComponentByIndexesStr(srcStep, srcComponentIndexes);
            if (srcComponent) {
              set(srcComponent, 'options.action.options.screenId', null);
              workflowStore.updateComponent(srcComponent);
            }
          }
        });
      }
    });

    // don't let link point to nothing on the canvas
    paper.on('link:pointerup', (cellView: any, event, x, y) => {
      const { id: targetModelId } = cellView.model.get('target');
      if (!targetModelId) {
        cellView.model.remove();

        // update src model
        const { id: srcModelId } = cellView.model.get('source');
        const srcStepModel = workflowStore.paper.getModelById(srcModelId);
        const srcStep = srcStepModel ? workflowStore.getStepById(srcStepModel.prop('stepId')) : null;
        if (srcStep) {
          // run onStepChange on next tick to allow rest of mouse events to occur (e.g. element:mouseleave) before
          // the view is replaced (in order to property unlighlight links when mouse no longer hovers over step
          setTimeout(() => this.onStepChange(srcStep), 0);
        }
      }
    });

    // only necessary if routing turned on
    if (this.state.defaultRouter !== 'normal') {
      graph.on('change:position', cell => {
        const links = graph.getLinks();
        for (const link of links) {
          (link.findView(paper) as joint.dia.LinkView).requestConnectionUpdate();
        }
      });
    }

    const { startStepId } = workflow.workingVersion;
    const { onStartAction, onStartOrContinueAction } = workflow.workingVersion.options || {};
    const anyStartAction = onStartAction || onStartOrContinueAction;
    const dialogType = workflowStore.currentWorkflowInstanceDialogType;
    const steps = workflow.workingVersion.steps;
    for (let stepIndex = 0; stepIndex < steps.length; stepIndex++) {
      const step = workflow.workingVersion.steps[stepIndex];

      const isEntryStep = startStepId === step.id;
      const isConditionalEntryStep = getConditionalStartStepIds(workflow).includes(step.id);
      const stepErrors = workflowStore.getStepErrors(step.id);
      const workflowStep = joint.shapes.solvvy.WorkflowStep.createFromStep(
        step,
        stepErrors,
        workflow,
        {
          isEntryStep: isEntryStep && !anyStartAction,
          isConditionalEntryStep,
          fullyEditable: workflowStore.workflowToEditIsFullyEditable,
          dialogType
        },
        workflowStore._dataSourcesStore.dataSources
      );
      workflowStore.jointJsModelByStepId[step.id] = workflowStep;

      workflowStep.addTo(graph);

      const { position } = step.dashboardState;
      if (position) {
        workflowStep.position(position.x, position.y);
        workflowStep.set('z', position.z);

        if (!position.z) {
          workflowStep.toFront();
          position.z = workflowStep.get('z');
        }
      }

      // when step is moved, update and save position
      workflowStep.on('change:position', debounce(this.onStepPositionChange, 500));
    }

    // add links
    for (const step of workflow.workingVersion.steps) {
      this.addLinksForStep(step);
    }

    let firstTime = true;
    paper.on('render:done', () => {
      // perform first time render setup
      if (firstTime) {
        firstTime = false;
        runInAction(() => {
          // if there are no step positions yet, then rearrange automatically
          if (
            steps.length > 0 &&
            !steps[0].dashboardState.position &&
            !steps[steps.length - 1].dashboardState.position
          ) {
            this.optimizeLayoutVertical(true);
            // this.optimizeLayoutHorizontal(true);
          }

          this.setCanvasSize();

          // set initial scroll
          const { canvasScroll: defaultCanvasScroll } = workflow.workingVersion.dashboardState;
          const { canvasScroll: userCanvasScroll } = workflowStore.getUserSpecificWorkflowState(workflow.id);
          const canvasScroll = userCanvasScroll || defaultCanvasScroll;

          const container = paperElement.parentElement;
          if (canvasScroll && container) {
            container.scrollLeft = canvasScroll.left;
            container.scrollTop = canvasScroll.top;
          } else {
            this.setDefaultScroll();
          }
        });
      }

      // execute special generic callback after render if requested (e.g. print preparation)
      if (this.afterNextRender) {
        const callback = this.afterNextRender;
        this.afterNextRender = undefined;
        callback();
      }

      // scroll element into view if requested (e.g. new step added)
      if (this.scrollIntoViewAfterRender) {
        (this.scrollIntoViewAfterRender as any).el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
        this.scrollIntoViewAfterRender = undefined;
      }
    });
  }

  @action.bound
  onChangeEnabled() {
    const { workflowStore } = this.props;
    const disabled = !workflowStore.workflowToEdit.enabled;
    const unpublishedChanges = workflowStore.getWorkflowHasUnpublishedChanges(workflowStore.workflowToEdit);
    showConfirmToggleEnableModal(
      disabled,
      unpublishedChanges,
      () =>
        runInAction(() => {
          workflowStore.workflowToEdit.enabled = !workflowStore.workflowToEdit.enabled;
          this.props.workflowStore.updateWorkflow(workflowStore.workflowToEdit);
        }),
      workflowStore.getWorkflowDependencies()
    );
  }

  onSubmitTagsModal = async () => {
    const { addTagsToSelectedSteps, tagsToAddToSteps, toggleTagsModalVisibility } = this.props.workflowStore;
    await addTagsToSelectedSteps(tagsToAddToSteps);
    toggleTagsModalVisibility();
  };

  @action.bound
  onChangeTagsInTagsModal(tags) {
    this.props.workflowStore.tagsToAddToSteps = tags;
  }

  @bind
  handlePropertiesClick() {
    this.props.workflowStore.updateSelectedStepId('main');
    this.props.workflowStore.toggleWorkflowProperties();
  }

  @bind
  handleSearchClick() {
    this.props.workflowStore.toggleWorkflowStepSearch();
  }

  @bind
  handlePreviewClick() {
    this.props.workflowStore.togglePreview();
  }

  @bind
  handleShowIssuesClick() {
    this.props.workflowStore.showIssues();
  }

  @bind
  handleMainPropertiesClick() {
    const { workflowStore } = this.props;

    const { showMainWorkflowProperties } = workflowStore;
    if (showMainWorkflowProperties) {
      workflowStore.toggleWorkflowProperties();
    } else {
      workflowStore.updateSelectedStepId('main');
      workflowStore.setShowWorkflowProperties(true);
    }
  }

  @action.bound
  onStepPositionChange(workflowStep: joint.shapes.solvvy.WorkflowStep) {
    const { workflowStore } = this.props;
    const newPosition = workflowStep.get('position');

    const step = workflowStore.getStepById(workflowStep.prop('stepId'));
    if (step) {
      step.dashboardState.position = { ...step.dashboardState.position, ...newPosition } as any; // preserve z

      if (this.optimizingLayout) {
        // wait for step position change events to stop coming in, and then batch update all components in 1 API call
        if (this.optimizingLayoutTimeout) {
          clearTimeout(this.optimizingLayoutTimeout);
        }
        this.optimizingLayoutTimeout = setTimeout(() => {
          runInAction(() => {
            this.setCanvasSize();
            this.optimizingLayoutTimeout = undefined;
            this.optimizingLayout = false;
            workflowStore.updateWorkflowVersionLayout(workflowStore.workingVersionToEdit);
          });
        }, 500);
      } else {
        this.setCanvasSize();
        workflowStore.updateStep(step);
      }
    }
  }

  @action.bound
  async onClickAddStep(newStep?: INewStandaloneStep, stepName?: string) {
    const { workflowStore } = this.props;
    const { workflowToEdit: workflow } = workflowStore;

    const newDisplayId = workflowStore.getNextDisplayStepId();

    // TODO: remove with legacy / fixed view / non-spatial mode
    this.cardRefs[newDisplayId] = React.createRef<HTMLDivElement>();
    this.scrollNewCardIntoView = newDisplayId;

    const step =
      newStep ||
      this.props.workflowStore.createEmptyStepObject(newDisplayId, {
        dialogType: workflowStore.currentWorkflowInstanceDialogType
      });

    // Creating step with searched text as step name by default.
    if (stepName) {
      step.name = stepName;
    }

    if (this.state.spatialEditor) {
      const stepErrors = workflowStore.getStepErrors(step.id);
      const workflowStep = joint.shapes.solvvy.WorkflowStep.createFromStep(
        step,
        stepErrors,
        workflow,
        {
          fullyEditable: workflowStore.workflowToEditIsFullyEditable,
          dialogType: workflowStore.currentWorkflowInstanceDialogType
        },
        workflowStore._dataSourcesStore.dataSources
      );
      this.props.workflowStore.jointJsModelByStepId[step.id] = workflowStep;
      if (step.dashboardState.position) {
        workflowStep.position(step.dashboardState.position.x, step.dashboardState.position.y);
      }
      workflowStep.addTo(this.props.workflowStore.graph);
      runInAction(() => this.setCanvasSize());

      // if there were no cards previously, then zoom to 100% or else new card is temporarily "hidden"
      if (workflow.workingVersion.steps.length === 0) {
        setTimeout(() => {
          this.setZoomScale(1);
        });
      }

      // scroll new step into view
      const cellView = workflowStep.findView(this.props.workflowStore.paper);
      this.scrollIntoViewAfterRender = cellView;

      // when step is moved, update and save position
      workflowStep.on('change:position', debounce(this.onStepPositionChange, 500));
    }

    await this.props.workflowStore.createStep(step);
  }

  @action.bound
  onClickAddCommentsOverlayStep() {
    const { workflowStore } = this.props;
    const newStep = workflowStore.createCommentsOverlayStep();
    this.onClickAddStep(newStep, 'Comments Form (Component)');
  }

  @action.bound
  async onClickAddCarouselStep() {
    const { workflowStore } = this.props;
    const newStep = workflowStore.createCarouselStep();
    await this.onClickAddStep(newStep, 'Carousel (Component)');
    workflowStore.addDefaultComponentsToCarouselStepRepeatingGroup(workflowStore.getStepById(newStep.id));
  }

  @action.bound
  onClickStep(stepId: string) {
    this.props.workflowStore.onClickStep(stepId);
  }

  setDefaultScroll() {
    const element = this.jointJsElementRef.current;
    const container = element ? element.parentElement : undefined;
    if (!container) {
      return;
    }

    const { tx: translateX, ty: translateY } = this.props.workflowStore.paper.translate();
    // don't need to use zoom scale since we explicitly just set to scale 1.0
    container.scrollLeft = (this.getCanvasPadding() - VIEWPORT_PADDING + translateX) * this.state.zoomScale;
    container.scrollTop = (this.getCanvasPadding() - VIEWPORT_PADDING + translateY) * this.state.zoomScale;
  }

  unhighlightLink(link) {
    const { workflowStore } = this.props;
    const { paper } = workflowStore;
    const { id: srcModelId } = link.get('source');
    const srcStep = paper.getModelById(srcModelId);
    const { id: targetModelId } = link.get('target');
    const targetStep = paper.getModelById(targetModelId);
    const srcStepId = srcStep ? srcStep.prop('stepId') : null;
    const targetStepId = targetStep ? targetStep.prop('stepId') : null;
    // only unhighlight if not src or target step is not selected
    if (srcStepId !== workflowStore.selectedStepId && targetStepId !== workflowStore.selectedStepId) {
      link.attr('line/stroke', link.prop('condition') ? CONDITION_LINK_COLOR : LINK_COLOR);
    }
  }

  // @action.bound
  // rearrangeAsGrid() {
  //   const { workflowStore, workflow } = this.props;

  //   const { width: viewportWidth } = this.getViewportDimensions();

  //   const startX = this.getCanvasPadding() + VIEWPORT_PADDING;
  //   const startY = this.getCanvasPadding() + VIEWPORT_PADDING;
  //   let currentX = startX;
  //   let currentY = startY;
  //   let maxRowHeight = 0;

  //   for (const step of workflow.options.flow.steps) {
  //     const workflowStep = workflowStore.jointJsModelByStepId[step.id];

  //     const stepWidth = workflowStep.get('size').width;
  //     const stepHeight = workflowStep.get('size').height;

  //     const position = step.position || ({} as IPosition);
  //     if (stepHeight > maxRowHeight) {
  //       maxRowHeight = stepHeight;
  //     }
  //     position.x = currentX;
  //     position.y = currentY;
  //     currentX += stepWidth + PADDING_BETWEEN_STEPS;

  //     // see if next step would go off the right of viewport
  //     if (currentX + stepWidth + VIEWPORT_PADDING > startX + viewportWidth) {
  //       currentX = startX;
  //       currentY += maxRowHeight + PADDING_BETWEEN_STEPS;
  //       maxRowHeight = 0;
  //     }

  //     workflowStep.position(position.x, position.y);
  //     workflowStep.set('z', position.z);
  //   }

  //   this.scrollToStartStep();
  //   this.afterNextRender = () => {
  //     runInAction(() => {
  //       this.setCanvasSize();
  //       this.props.workflowStore.autoSaveWorkflow();
  //     });
  //   };
  // }

  scrollToStartStep() {
    // const flow = workflowStore.workflowFlowToEdit;
    const { workflowStore } = this.props;
    const { workingVersion } = workflowStore.workflowToEdit;
    const { steps } = workingVersion;
    if (steps.length > 0) {
      const startStepId = workingVersion.startStepId || steps[0].id;
      const workflowStep = workflowStore.jointJsModelByStepId[startStepId];
      const cellView = workflowStep.findView(workflowStore.paper);
      this.scrollIntoViewAfterRender = cellView;
    }
  }

  @action.bound
  optimizeLayoutVertical(initialLoad = false) {
    this.optimizeLayout({ rankDir: 'TB' }, initialLoad);
  }

  @action.bound
  optimizeLayoutHorizontal(initialLoad = false) {
    this.optimizeLayout({ rankDir: 'LR' }, initialLoad);
  }

  @action.bound
  optimizeLayout(layoutOptions, initialLoad) {
    const { workflowStore } = this.props;

    this.optimizingLayout = true;
    joint.layout.DirectedGraph.layout(workflowStore.graph, {
      dagre,
      graphlib,
      nodeSep: 60,
      edgeSep: 80,
      ...layoutOptions
    });

    if (!initialLoad) {
      this.afterNextRender = () => this.scrollToStartStep();
    }
  }

  renderZoomMenu() {
    const { zoomScale } = this.state;

    return (
      <Menu data-testid="workflow-zoom-menu">
        <Menu.Item disabled={zoomScale <= ZOOM_SCALE_MIN} onClick={e => this.zoomOut(false, e)}>
          Zoom Out <span className="menu-shortcut">{`${MOD_KEY}-`}</span>
        </Menu.Item>
        <Menu.Item disabled={zoomScale >= ZOOM_SCALE_MAX} onClick={e => this.zoomIn(false, e)}>
          Zoom In <span className="menu-shortcut">{`${MOD_KEY}+`}</span>
        </Menu.Item>
        <Menu.Item onClick={e => this.zoomToFit(true, e)}>
          Zoom to Fit <span className="menu-shortcut">{`${MOD_KEY}0`}</span>
        </Menu.Item>
        <Menu.Item onClick={() => this.setZoomScale(1, true)}>
          100% <span className="menu-shortcut">{`${MOD_KEY}1`}</span>
        </Menu.Item>
        <Menu.Item onClick={() => this.setZoomScale(0.75, true)}>75%</Menu.Item>
        <Menu.Item onClick={() => this.setZoomScale(0.5, true)}>50%</Menu.Item>
        <Menu.Item onClick={() => this.setZoomScale(0.25, true)}>25%</Menu.Item>
      </Menu>
    );
  }

  renderAddStep() {
    const {
      workflowStore: { workingVersionToEdit, workflowToEditIsFullyEditable, isSurveyWorkflow, isCarouselStepEnabled }
    } = this.props;
    const showDropdownButton = isSurveyWorkflow || isCarouselStepEnabled;
    const disableAddCommentsOverlay =
      isSurveyWorkflow &&
      Boolean(workingVersionToEdit.steps.find(step => step.displayType === STEP_DISPLAY_TYPE.OVERLAY));

    return (
      <div className="add_card_button_wrapper">
        {workflowToEditIsFullyEditable ? (
          showDropdownButton ? (
            <Dropdown.Button
              data-testid="add-step-dropdown-button"
              icon={<DownOutlined />}
              placement="bottomCenter"
              overlay={
                <Menu data-testid="add-step-menu">
                  {isSurveyWorkflow && (
                    <Menu.Item
                      disabled={disableAddCommentsOverlay}
                      onClick={() => this.onClickAddCommentsOverlayStep()}
                    >
                      Add Component - Comments Form
                    </Menu.Item>
                  )}
                  {!isSurveyWorkflow && isCarouselStepEnabled && (
                    <Menu.Item onClick={() => this.onClickAddCarouselStep()}>Add Component - Carousel</Menu.Item>
                  )}
                </Menu>
              }
              onClick={() => this.onClickAddStep()}
            >
              Add Step
            </Dropdown.Button>
          ) : (
            <Button onClick={() => this.onClickAddStep()}>Add Step</Button>
          )
        ) : (
          <Popover
            content="This workflow is not fully editable. Please contact Solvvy to make certain changes."
            trigger="click"
          >
            <InfoCircleTwoTone />
          </Popover>
        )}
      </div>
    );
  }

  render() {
    const { zoomScale, spatialEditor } = this.state;
    const { workflowStore, orgStore } = this.props;
    const {
      workflowToEdit: workflow,
      loadedWorkflowToEdit,
      isShowingTagsInputModal,
      tagsToAddToSteps,
      toggleTagsModalVisibility
    } = this.props.workflowStore;
    const errors = workflowStore.getWorkflowErrors();
    const { hasPermission } = orgStore;
    const haveErrors = errors.length > 0;

    const botInstanceSupport = FeatureFlagService.flags[FEATURE_FLAGS.advanced_bot_workflows];
    const isInstanceBot = workflowStore.currentWorkflowFirstUiInstanceIsSunshine;
    const previewEnabled = workflowStore.workflowToEditIsFullyEditable && (botInstanceSupport || !isInstanceBot);

    const { startStepId } = workflow.workingVersion;
    return (
      <>
        <GlobalHotKeys
          keyMap={keyMap}
          handlers={{
            ZOOM_IN: e => this.zoomIn(false, e),
            ZOOM_OUT: e => this.zoomOut(false, e),
            ZOOM_TO_FIT: e => this.zoomToFit(false, e),
            ZOOM_RESET: e => {
              if (e) {
                e.preventDefault();
              }
              this.setZoomScale(1);
            }
          }}
        />

        {!loadedWorkflowToEdit ? (
          <Loader thingToWaitFor="Workflow" />
        ) : (
          <>
            <WorkflowEditHeader
              workflow={workflow}
              spatialEditor={spatialEditor}
              optimizeLayoutHorizontal={() => this.optimizeLayoutHorizontal()}
              optimizeLayoutVertical={() => this.optimizeLayoutVertical()}
              onImport={this.initJointJSPaper}
              print={this.onPrint}
            />

            {this.props.workflowStore.isBreakdownOpen && <ButtonBreakdown />}

            <WorkflowEditAreaRow>
              <WorkflowStatusArea className="workflow_status_area" type="flex" align="middle" justify="space-between">
                <Col span={9}>
                  {hasPermission(OrgPermission.workflowUpdate) && this.renderAddStep()}
                  {this.state.spatialEditor && (
                    <Dropdown
                      overlay={this.renderZoomMenu()}
                      trigger={['click']}
                      visible={this.state.showZoomMenu}
                      onVisibleChange={showZoomMenu => this.setState({ showZoomMenu })}
                    >
                      <Input
                        data-testid="workflow-zoom-button"
                        addonBefore={
                          <span style={{ cursor: 'pointer' }} onClick={this.toggleZoomMenu}>
                            Zoom
                          </span>
                        }
                        suffix={
                          <DownOutlined style={{ fontSize: 12, cursor: 'pointer' }} onClick={this.toggleZoomMenu} />
                        }
                        readOnly={true}
                        value={`${Math.round(zoomScale * 100)}%`}
                        className="current-zoom"
                        onClick={this.toggleZoomMenu}
                      />
                    </Dropdown>
                  )}

                  {workflowStore.showStepSearch ? (
                    <Tooltip title="Hide Search" mouseEnterDelay={0.3}>
                      <Button
                        icon={<SearchOutlined />}
                        shape="circle"
                        type="primary"
                        className="toggle_button active"
                        onClick={this.handleSearchClick}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Search Steps" mouseEnterDelay={0.3}>
                      <SearchOutlined className="toggle_button inactive" onClick={this.handleSearchClick} />
                    </Tooltip>
                  )}

                  {workflowStore.showWorkflowProperties ? (
                    <Tooltip title="Hide Properties" mouseEnterDelay={0.3}>
                      <Button
                        icon={<LayoutOutlined />}
                        shape="circle"
                        type="primary"
                        className="toggle_button active"
                        onClick={this.handlePropertiesClick}
                        data-testid="hide-properties-button"
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Show Properties" mouseEnterDelay={0.3}>
                      <LayoutOutlined
                        className="toggle_button inactive"
                        data-testid="show-properties-button"
                        onClick={this.handlePropertiesClick}
                      />
                    </Tooltip>
                  )}

                  {previewEnabled && (
                    <>
                      {workflowStore.showPreview ? (
                        <Tooltip title="Hide Preview" mouseEnterDelay={0.3}>
                          <Button
                            icon={<CaretRightOutlined />}
                            shape="circle"
                            type="primary"
                            className="toggle_button active"
                            onClick={this.handlePreviewClick}
                            data-testid="hide-preview-button"
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Show Preview" mouseEnterDelay={0.3}>
                          <CaretRightOutlined
                            className="toggle_button inactive"
                            onClick={this.handlePreviewClick}
                            data-testid="show-preview-icon"
                          />
                        </Tooltip>
                      )}
                    </>
                  )}

                  {haveErrors && (
                    <Tooltip title="Show Issues" mouseEnterDelay={0.3}>
                      <WarningOutlined
                        className="show_issues_button"
                        data-testid="show-issues-button"
                        onClick={this.handleShowIssuesClick}
                      />
                    </Tooltip>
                  )}
                </Col>
                <Col span={3}>
                  <div className="status_container">
                    Status:
                    <Switch
                      disabled={!hasPermission(OrgPermission.workflowUpdate)}
                      checked={workflow.enabled}
                      onChange={this.onChangeEnabled}
                    />
                    Enabled
                  </div>
                </Col>
              </WorkflowStatusArea>

              <WorkflowCardArea id="workflow_step_area" className="workflow_step_area">
                <StepSearchDrawer />
                <Tooltip title="Workflow Settings" mouseEnterDelay={0.3}>
                  <Button
                    icon={<SettingOutlined />}
                    shape="circle"
                    size="large"
                    data-testid="workflow-settings-button"
                    className={classNames('workflow_settings', {
                      active: workflowStore.showMainWorkflowProperties,
                      drawer_open: workflowStore.showStepSearch
                    })}
                    onClick={this.handleMainPropertiesClick}
                  />
                </Tooltip>

                {/*  TODO: remove the old display code or make configurable? */}
                {!this.state.spatialEditor && (
                  <div className="grid_cards">
                    {workflow.workingVersion.steps.map(step => (
                      <div
                        key={step.id}
                        ref={this.cardRefs[step.id]}
                        className="card_wrapper"
                        data-testid={`card-${step.id}`}
                        onClick={() => this.onClickStep(step.id)}
                      >
                        <WorkflowCard
                          step={step}
                          showEntryIcon={step.id === startStepId}
                          selectedStepId={workflowStore.selectedStepId}
                          className={step.id}
                        />
                      </div>
                    ))}
                  </div>
                )}

                {this.state.spatialEditor && (
                  <div className={JOINTJS_CONTAINER_CLASS}>
                    <div ref={this.jointJsElementRef} className="jointjs-element" />
                  </div>
                )}
              </WorkflowCardArea>
              <Modal
                title="Tags"
                className="tags-modal-input"
                visible={isShowingTagsInputModal}
                onOk={this.onSubmitTagsModal}
                onCancel={toggleTagsModalVisibility}
              >
                <NotesAndTags
                  tagsStandaloneMode={true}
                  tagsExternal={tagsToAddToSteps}
                  onChange={({ value }) => {
                    this.onChangeTagsInTagsModal(value);
                  }}
                />
              </Modal>
            </WorkflowEditAreaRow>
          </>
        )}
      </>
    );
  }
}

export default typedInject('workflowStore', 'orgStore')(WorkflowEdit);
