import styled from 'styled-components';

const S: { Container?: any; Header?: any } = {};

S.Container = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 0 1rem 1rem 0;
  border-radius: 4px;

  .recharts-wrapper {
    height: auto !important;
  }

  .recharts-legend-wrapper {
    padding: 12px;
    font-size: 14px;
    ::before {
      font-size: 16px;
      content: 'Button Name';
    }
  }

  .recharts-default-legend {
    margin-top: 4px !important;
  }
`;

S.Header = styled.div`
  font-size: 16px;
  font-weight: 600;
  display: flex;
  padding: 8px 12px;

  .id {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .title {
    margin-left: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default S;
