import get from 'lodash/get';
import { observable } from 'mobx';
import { UiMajorVersion } from '../ResolveUI/types';
import { GenericSupportOption } from './GenericSupportOption';

export default class LinkSupportOption extends GenericSupportOption {
  @observable
  url: string;

  constructor(rawSupportOption: any, uiMajorVersion: UiMajorVersion) {
    super(rawSupportOption, uiMajorVersion);
    const { options } = rawSupportOption;
    this.url = get(options, 'link', '');
  }
}
