import { WarningOutlined } from '@ant-design/icons';
import { CascaderOptionType } from 'antd/lib/cascader';
import { JSONSchema4 } from 'json-schema';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import set from 'lodash/set';
import startCase from 'lodash/startCase';
import React from 'react';
import { DEFAULT_DELAY_IN_MS } from 'src/store/ResolveUI/constants';
import { DialogType } from 'src/store/ResolveUI/types';
import { v4 as uuidv4 } from 'uuid';
import { IDataSource, IDataSourceAction } from '../../store/data-source-types';
import { IUnpublishedChanges } from '../../store/ResolveUI/UiConfiguration';
import {
  IAction,
  IComponent,
  ICondition,
  IConditionActionOptions,
  IConditionDataSourcePropertyArgument,
  IExternalApiActionOptions,
  IFieldErrors,
  IFlow,
  IFlowComponent,
  IFlowScreen,
  ISetVariableActionOptions,
  IStep,
  IWorkflow,
  IWorkflowVersion
} from '../../store/ResolveUI/Workflow';
import { NESTED_CONDITION_OPS } from '../../store/workflowStore';
import { ADD_COMPONENT_BUTTON_LABEL, COMPONENT_LABEL, PANEL_TITLE } from './constants';

export const fieldErrorsToFormItemProps = (fieldErrors: IFieldErrors, field: string): any => {
  if (fieldErrors[field]) {
    return {
      validateStatus: 'error',
      help: (
        <span>
          <WarningOutlined /> {fieldErrors[field]}
        </span>
      )
    };
  }

  return {};
};

const getDataSourceProperty = (
  dataSources: IDataSource[],
  conditionArg: IConditionDataSourcePropertyArgument
): JSONSchema4 | null => {
  const dataSource = (dataSources || []).find(ds => ds.id === conditionArg.dataSourceId);
  if (!dataSource) {
    return null;
  }

  return (
    (conditionArg.dataSourcePropertyId &&
      (dataSource.variablesJsonSchema?.properties || {})[conditionArg.dataSourcePropertyId]) ||
    null
  );
};

export const getDataSourceAction = (
  dataSources: IDataSource[],
  dataSourceId: string,
  actionId: string
): IDataSourceAction | null => {
  const dataSource = (dataSources || []).find(ds => ds.id === dataSourceId);
  if (!dataSource) {
    return null;
  }

  return (dataSource.actions || []).find(a => a.id === actionId) || null;
};

export const getDataSourceRepeatingGroupSource = (
  dataSources: IDataSource[],
  dataSourceId: string,
  repeatingGroupSourceId: string
): JSONSchema4 | null => {
  const dataSource = (dataSources || []).find(ds => ds.id === dataSourceId);
  if (!dataSource) {
    return null;
  }

  for (const [propertyName, schema] of Object.entries(dataSource.variablesJsonSchema?.properties || {})) {
    if (propertyName === repeatingGroupSourceId && schema.type === 'array') {
      return schema;
    }
  }

  return null;
};

const updateConditionFromDataSources = (dataSources: IDataSource[], condition: ICondition): void => {
  const keys = Object.keys(condition);
  const op = keys.length > 0 ? keys[0] : null;
  if (!op) {
    return;
  }
  const args = condition[op];

  // handle nested operators
  if (NESTED_CONDITION_OPS.includes(op)) {
    for (const arg of args) {
      updateConditionFromDataSources(dataSources, arg as ICondition);
    }
  } else {
    // handle non-nested operators
    for (const arg of args) {
      // if the argument is a data source property reference
      if (typeof arg === 'object' && arg && arg.expression !== undefined) {
        // update the template expression in case it changed
        const prop = getDataSourceProperty(dataSources, arg as IConditionDataSourcePropertyArgument);
        if (prop) {
          arg.expression = prop.templateExpression;
        }
      }
    }
  }
};

const forEachActionNested = (action: IAction | undefined, callback: (action: IAction) => void): void => {
  if (!action) {
    return;
  }

  const { type, options } = action;

  callback(action);

  // handle actions with nested actions
  switch (type) {
    case 'external_api':
      const { onSuccessAction, onErrorAction, onErrorActions } = options as IExternalApiActionOptions;
      forEachActionNested(onSuccessAction, callback);
      forEachActionNested(onErrorAction, callback);
      if (onErrorActions) {
        for (const errorAction of onErrorActions) {
          forEachActionNested(errorAction, callback);
        }
      }
      break;

    case 'condition':
      const { conditions, defaultAction } = options as IConditionActionOptions;
      if (conditions) {
        for (const condition of conditions) {
          forEachActionNested(condition.action, callback);
        }
      }
      forEachActionNested(defaultAction, callback);
      break;

    case 'set-variable':
      const { nextAction } = options as ISetVariableActionOptions;
      forEachActionNested(nextAction, callback);
      break;

    default:
      return;
  }
};

const forEachComponentNested = (components: IComponent[], callback: (action: IAction) => void) => {
  for (const component of components) {
    const { type, options, childComponents } = component;
    if (type === 'button') {
      forEachActionNested(options.action, callback);
    } else if (type === 'repeatingGroup' && childComponents) {
      forEachComponentNested(childComponents, callback);
    }
  }
};

export const hasEmailOtpAuthAction = (workflow: IWorkflow) => {
  let workflowHasEmailOtpAuthAction = false;

  const nestedActionHasEmailOtpAuth = nestedAction => {
    if (nestedAction.type === 'authentication' && nestedAction.options) {
      if (nestedAction.options.type === 'email-otp') {
        workflowHasEmailOtpAuthAction = true;
      }
    }
  };

  if (workflow.workingVersion) {
    forEachActionNested(workflow.workingVersion.options?.onStartAction, nestedActionHasEmailOtpAuth);
    forEachActionNested(workflow.workingVersion.options?.onContinueAction, nestedActionHasEmailOtpAuth);
    forEachActionNested(workflow.workingVersion.options?.onStartOrContinueAction, nestedActionHasEmailOtpAuth);

    for (const screen of workflow.workingVersion.steps) {
      forEachComponentNested(screen.components, nestedActionHasEmailOtpAuth);
    }
  }
  return workflowHasEmailOtpAuthAction;
};

export const getAllWorkflowActionTargetWorkflowIds = (workflow: IWorkflow): string[] => {
  const workflowIds: string[] = [];

  const extractWorkflowIdFromAction = nestedAction => {
    if (nestedAction.type === 'workflow' && nestedAction.options) {
      workflowIds.push(nestedAction.options.workflowId);
    }
  };

  if (workflow.workingVersion) {
    forEachActionNested(workflow.workingVersion.options?.onStartAction, extractWorkflowIdFromAction);
    forEachActionNested(workflow.workingVersion.options?.onContinueAction, extractWorkflowIdFromAction);
    forEachActionNested(workflow.workingVersion.options?.onStartOrContinueAction, extractWorkflowIdFromAction);

    for (const screen of workflow.workingVersion.steps) {
      forEachComponentNested(screen.components, extractWorkflowIdFromAction);
    }
  }

  return workflowIds;
};

const updateActionFromDataSources = (dataSources: IDataSource[], action: IAction | undefined): void => {
  forEachActionNested(action, nestedAction => {
    const { type, dataSourceId, dataSourceActionId } = nestedAction;

    // if the action came from a data source, then update in case it changed
    if (dataSourceId && dataSourceActionId) {
      const dsAction = getDataSourceAction(dataSources, dataSourceId, dataSourceActionId);
      if (dsAction) {
        // start with copy of options from data source, then add back in input values
        const oldOptions = cloneDeep(nestedAction.options);
        nestedAction.type = dsAction.action.type;
        nestedAction.options = cloneDeep(dsAction.action.options);
        // restore input options
        for (const [, property] of Object.entries(dsAction.inputsJsonSchema?.properties || {})) {
          const { targetPath } = property;
          const value = get(oldOptions, targetPath);
          if (value !== undefined) {
            set(nestedAction.options, targetPath, value);
          }
        }
      }
    }

    if (type === 'condition') {
      const { conditions } = nestedAction.options as IConditionActionOptions;
      if (conditions) {
        for (const condition of conditions) {
          updateConditionFromDataSources(dataSources, condition.condition);
        }
      }
    }
  });
};

const updateComponentsFromDataSources = (dataSources: IDataSource[], components: IComponent[]) => {
  for (const component of components) {
    const { type, options, childComponents } = component;
    if (type === 'button') {
      updateActionFromDataSources(dataSources, options.action);
    } else if (type === 'repeatingGroup' && childComponents) {
      const { dataSourceId, dataSourceRepeatingGroupSourceId } = options;

      // if the source came from a data source, then update in case it changed
      if (dataSourceId && dataSourceRepeatingGroupSourceId) {
        const rgSource = getDataSourceRepeatingGroupSource(dataSources, dataSourceId, dataSourceRepeatingGroupSourceId);
        if (rgSource) {
          options.source = rgSource.templateExpression;
        }
      }

      updateComponentsFromDataSources(dataSources, childComponents);
    }
  }
};

/**
 * Update workflow actions and conditions that came from a Data Source in case the definition has changed
 * @param dataSources
 * @param workflows
 */
export const updateWorkflowsFromDataSources = (workflow: IWorkflow, dataSources: IDataSource[]) => {
  if (!workflow || !workflow.workingVersion) {
    return;
  }

  updateActionFromDataSources(dataSources, workflow.workingVersion.options?.onStartAction);
  updateActionFromDataSources(dataSources, workflow.workingVersion.options?.onContinueAction);
  updateActionFromDataSources(dataSources, workflow.workingVersion.options?.onStartOrContinueAction);
  for (const step of workflow.workingVersion.steps) {
    updateComponentsFromDataSources(dataSources, step.components);
  }
};

export const getConditionalStartStepIds = (workflow: IWorkflow): string[] => {
  const { options } = workflow.workingVersion;

  const stepIds: string[] = [];

  const callback = nestedAction => {
    if (nestedAction.type === 'screen' && nestedAction.options && nestedAction.options.screenId) {
      stepIds.push(nestedAction.options.screenId);
    }
  };

  forEachActionNested(options?.onStartAction, callback);
  forEachActionNested(options?.onContinueAction, callback);
  forEachActionNested(options?.onStartOrContinueAction, callback);

  return stepIds;
};

const generateMissingIds = (components: IComponent[]) => {
  for (const component of components) {
    const { id, type, childComponents } = component;
    if (!id) {
      component.id = uuidv4();
    }
    if (type === 'repeatingGroup' && childComponents) {
      generateMissingIds(childComponents);
    }
  }
};

// TODO: can eventually remove this once all workflows have been re-published and there are no more missing component IDs
export const generateMissingComponentIds = (workflow: IWorkflow): void => {
  for (const step of workflow.workingVersion.steps) {
    generateMissingIds(step.components);
  }
};

// credit: https://stackoverflow.com/a/54077142
export const prefixCssSelectors = (rules, className) => {
  const classLen = className.length;
  let nextChar;
  let isAt;
  let isIn;

  // makes sure the className will not concatenate the selector
  className += ' ';

  // removes comments
  rules = rules.replace(/\/\*(?:(?!\*\/)[\s\S])*\*\/|[\r\n\t]+/g, '');

  // makes sure nextChar will not target a space
  rules = rules.replace(/}(\s*)@/g, '}@');
  rules = rules.replace(/}(\s*)}/g, '}}');

  for (let i = 0; i < rules.length - 2; i++) {
    const char = rules[i];
    nextChar = rules[i + 1];

    if (char === '@' && nextChar !== 'f') {
      isAt = true;
    }
    if (!isAt && char === '{') {
      isIn = true;
    }
    if (isIn && char === '}') {
      isIn = false;
    }

    if (
      !isIn &&
      nextChar !== '@' &&
      nextChar !== '}' &&
      (char === '}' || char === ',' || ((char === '{' || char === ';') && isAt))
    ) {
      rules = rules.slice(0, i + 1) + className + rules.slice(i + 1);
      i += classLen;
      isAt = false;
    }
  }

  // prefix the first select if it is not `@media` and if it is not yet prefixed
  if (rules.indexOf(className) !== 0 && rules.indexOf('@') !== 0) {
    rules = className + rules;
  }

  return rules;
};

export const convertDbWorkflowToPluginWorkflow = (
  workflowVersion: IWorkflowVersion,
  dialogType = DialogType.Professional
): IFlow => {
  const isConversational = dialogType === DialogType.Conversational;

  const convertComponent = (component?: IComponent): IFlowComponent => {
    if (!component) {
      return undefined as any;
    }
    return {
      id: component.id,
      type: component.type,
      options: {
        ...component.options,
        ...(isConversational && isNil(component.options.delay) && { delay: DEFAULT_DELAY_IN_MS }),
        text: component.text,
        class: component.cssClass ? component.cssClass : undefined,
        components:
          component.childComponents && component.childComponents.length > 0
            ? component.childComponents.map(convertComponent)
            : undefined
      }
    };
  };

  const screens: IFlowScreen[] = workflowVersion.steps.map((step: IStep) => {
    const headerComponents: IFlowComponent[] = [];
    if (!isConversational && step.header) {
      headerComponents.push({
        type: 'header',
        options: {
          text: step.header,
          class: step.headerCssClass ? step.headerCssClass : undefined,
          ...step.headerOptions
        }
      });
    }
    const screen: IFlowScreen = {
      id: step.id,
      name: step.name,
      tags: step.tags,
      notes: step.notes,
      ...(step.displayType && { displayType: step.displayType }),
      displayId: step.displayId,
      components: [...headerComponents, ...step.components.map(convertComponent)],
      position: step.dashboardState.position
    };
    return screen;
  });

  const flow: IFlow = {
    screens,
    text: {
      ...workflowVersion.text,
      confirmationText: workflowVersion.confirmationText
    },
    autoSendEngagedEvent: workflowVersion.options?.autoSendEngagedEvent,
    startScreenId: workflowVersion.startStepId,
    extraUrlParams: workflowVersion.extraUrlParams,
    captureOptions: {
      nameAsTag: workflowVersion.captureNameAsTag,
      journey: workflowVersion.captureJourney,
      trigger: workflowVersion.captureTrigger,
      greeting: workflowVersion.captureGreeting
    },
    onStartAction: workflowVersion.options?.onStartAction,
    onContinueAction: workflowVersion.options?.onContinueAction,
    onStartOrContinueAction: workflowVersion.options?.onStartOrContinueAction,
    initialTemplateContext: workflowVersion.options?.initialTemplateContext,
    intents: workflowVersion.intents
  };

  return flow;
};

const prepForUnpublishedChangesCompare = (workflowVersion: Partial<IWorkflowVersion>): void => {
  // delete properties that are always different
  delete workflowVersion.id;
  delete workflowVersion.numSteps;
  const commonExcludeFields = [
    'createdAt',
    'updatedAt',
    'overallUpdatedAt',
    'overallUpdatedByUser',
    'overallUpdatedByUserId',
    'updatedByUser',
    'updatedByUserId',
    'publishedAt',
    'publishedComments',
    'publishedVersion',
    'dashboardState',
    'lockVersion',
    'workflowVersionId',
    '__typename'
  ];
  const removeCommon = (inputObj: any) => {
    const objs = Array.isArray(inputObj) ? inputObj : [inputObj];
    for (const obj of objs || []) {
      if (obj && typeof obj === 'object') {
        for (const field of commonExcludeFields) {
          delete obj[field];
        }
        removeCommon(Object.values(obj));
      }
    }
  };
  removeCommon(workflowVersion);

  // put components and steps into maps by ID instead of arrays for easier visual diffing
  const makeMapByIds = (objs: Array<{ id: string }>) =>
    objs.reduce((acc, obj) => {
      if (obj) {
        acc[obj.id] = obj;
      }
      return acc;
    }, {});
  (workflowVersion.steps || []).forEach(step => ((step as any).components = makeMapByIds(step.components)));
  (workflowVersion as any).steps = makeMapByIds(workflowVersion.steps || []);
};

export const getUnpublishedChanges = (workflow: IWorkflow): IUnpublishedChanges[] => {
  const changes: IUnpublishedChanges[] = [];

  const workingVersion = cloneDeep(workflow.workingVersion);
  const publishedVersion = cloneDeep(workflow.publishedVersion);

  prepForUnpublishedChangesCompare(workingVersion);
  prepForUnpublishedChangesCompare(publishedVersion);

  // compare working and published
  const changed = !isEqual(workingVersion, publishedVersion);

  changes.push({
    changed,
    path: { path: `workflow-${workflow.id}` },
    published: publishedVersion,
    preview: workingVersion
  });

  return changes;
};

export const getComponentByIndexes = (step: IStep, indexes: number[]): IComponent | null => {
  let component;
  let containerComponents = step.components;
  for (const index of indexes) {
    component = containerComponents[index];
    if (component && component.childComponents) {
      containerComponents = component.childComponents;
    }
  }
  return component;
};

export const getComponentByIndexesStr = (step: IStep, indexesStr: string): IComponent | null => {
  return getComponentByIndexes(step, indexesStr.split('-').map(Number));
};

export const variablesSchemaToCascaderOptions = (
  variablesSchema: JSONSchema4 | undefined,
  filter: (property: JSONSchema4) => boolean = () => true
): CascaderOptionType[] => {
  const cascaderOptions: CascaderOptionType[] = [];

  if (variablesSchema && variablesSchema.properties) {
    for (const [propertyId, schema] of Object.entries(variablesSchema.properties)) {
      const cascaderOption: CascaderOptionType = {
        value: propertyId,
        label: schema.title || propertyId
      };
      if (schema.properties) {
        cascaderOption.children = variablesSchemaToCascaderOptions(schema, filter);
      }
      if (
        // have children
        (cascaderOption.children && cascaderOption.children.length > 0) ||
        // or matches filter
        filter(schema)
      ) {
        cascaderOptions.push(cascaderOption);
      }
    }
  }

  return cascaderOptions;
};

/**
 * To find the deep path of a specific value in a nested object. e.g find the path of a component with an id: 'xyz', would return path like workingVersion.steps.0.components.id
 * @param a The value to search for
 * @param obj The actual object in which to search
 * @returns The path of the field which holds the matching value.
 */
export const findPath = (a, obj) => {
  for (const key in obj) {
    // for each key in the object obj
    if (obj.hasOwnProperty(key)) {
      // if it's an owned key
      if (a === obj[key]) {
        return key;
      }
      // if the item beign searched is at this key then return this key as the path
      else if (obj[key] && typeof obj[key] === 'object') {
        // otherwise if the item at this key is also an object
        const path = findPath(a, obj[key]); // search for the item a in that object
        if (path) {
          return key + '.' + path;
        } // if found then the path is this key followed by the result of the search
      }
    }
  }
};

// get display name based on whether current user is a client admin
// if the current user is a client admin, and if the user's email is a solvvy email, then show 'Solvvy User'
// else show the user's name.
export const getMaskedDisplayName = (
  user: { name: string; email: string } | undefined,
  isGlobalUser: boolean
): string => {
  if (!user) {
    return '';
  }
  if (isGlobalUser) {
    return user.name;
  } else {
    const solvvyEmail = 'solvvy.com';
    const solvvyUser = user.email.includes(solvvyEmail);
    return solvvyUser ? 'Solvvy User' : user.name;
  }
};

export function getPanelTitle(dialogType: DialogType, componentType: string) {
  const panelTitle =
    PANEL_TITLE[componentType] && PANEL_TITLE[componentType][dialogType]
      ? PANEL_TITLE[componentType][dialogType]
      : componentType;
  return startCase(panelTitle);
}

export function getComponentLabel(dialogType: DialogType, componentType: string) {
  const label =
    COMPONENT_LABEL[componentType] && COMPONENT_LABEL[componentType][dialogType]
      ? COMPONENT_LABEL[componentType][dialogType]
      : componentType;
  return startCase(label);
}

export function getAddComponentButtonLabel(dialogType: DialogType, componentType: string) {
  const label =
    ADD_COMPONENT_BUTTON_LABEL[componentType] && ADD_COMPONENT_BUTTON_LABEL[componentType][dialogType]
      ? ADD_COMPONENT_BUTTON_LABEL[componentType][dialogType]
      : componentType;
  return startCase(label);
}
