import { message } from 'antd';
import { debounce } from 'lodash';
import { observable, reaction, runInAction } from 'mobx';
import moment from 'moment';
import { orgPageRoute } from 'src/routes/routes';
import {
  IIntent,
  IIntentCollection,
  ILabellingQuery,
  IntentService,
  IPredictionRule
} from 'src/services/intentService';
import asyncComputed from 'src/shared/util/asyncComputed';
import NotificationManager from 'src/shared/util/NotificationManager';
import { recordErrors } from 'src/shared/util/recordErrors';
import { OrgStore } from './orgStore';
import routerStore, { RouterStore } from './routerStore';

export interface IIntentWithCollection extends IIntent {
  collection: IIntentCollection;
}

export const NEW_INTENT_ID = 'new';
const CATEGORY_PREFIX = 'Category:';

const LAST_7DAYS = {
  start_date: moment().subtract(7, 'd'),
  end_date: moment()
};

export class IntentStore {
  @observable
  intentCollection = asyncComputed(() => this.getIntents());

  @observable
  intentStats = asyncComputed(() => this.getIntentLabelledStats());

  @observable
  intentQueryStats = asyncComputed(() => this.getIntentQueryStats());

  @observable
  intentExtras = asyncComputed(() => this.getIntentExtras());

  @observable
  activeIntentName: string;

  @observable
  currentIntentActivatorsCount: number = 0;

  @observable
  countLabelledQueries: number = 0;

  updatedIntent: IIntent;

  /** When an intent is updated in create or edit flow, trigger update for intents list page */
  intentsUpdated: boolean;

  autoSaveIntents = debounce((intent: IIntent) => {
    this.updatedIntent = intent;
    this.updateIntent();
  }, 500);

  pendingAutoSave: boolean;

  @observable
  autoSaving: boolean;

  @observable
  lastAutoSavedAt?: string;

  intentLabelledStatsUpdated: boolean;

  @observable
  dateRange = LAST_7DAYS;

  constructor(private _orgStore: OrgStore, private _routerStore: RouterStore, private _intentService: IntentService) {
    reaction(
      () => [this._orgStore.selectedOrgId],
      () => this.intentCollection.refresh()
    );
  }

  stripAndGetId(id: string) {
    return atob(id).replace(CATEGORY_PREFIX, '');
  }

  addLabelForId(id: string) {
    return btoa(CATEGORY_PREFIX + id);
  }

  async getIntents(): Promise<{ intents: IIntentWithCollection[]; collections: IIntentCollection[] }> {
    if (!this._orgStore.selectedOrgId) {
      return { intents: [], collections: [] };
    }
    try {
      const collections = await this._intentService.getIntentCollections(this._orgStore.selectedOrgId);
      collections.forEach(collection => {
        collection.intents.sort((intentA, intentB) => {
          return new Intl.Collator('en', { numeric: true }).compare(intentA.name, intentB.name);
        });
      });

      const intentsWithCollection = collections
        .sort((collectionA, collectionB) => {
          return new Intl.Collator('en', { numeric: true }).compare(collectionA.name, collectionB.name);
        })
        .reduce<IIntentWithCollection[]>((intents, collection) => {
          return intents.concat(
            collection.intents.map(intent => {
              return { ...intent, collection };
            })
          );
        }, [])
        .flat();

      return { intents: intentsWithCollection, collections };
    } catch (e) {
      recordErrors(e);
      message.error('There was an unexpected error loading the intents. Please refresh the page or try again later.');
      throw e;
    }
  }

  async getIntentLabelledStats(): Promise<any> {
    if (!this._orgStore.selectedOrgId) {
      return {};
    }

    if (!this.intentCollection.fulfilled) {
      return {};
    }

    const intentCollection = this.intentCollection.value;
    const intentIds = intentCollection.intents.filter(({ clientEditable }) => clientEditable).map(({ id }) => id);

    try {
      const intentStats = await Promise.all(
        intentIds.map(id => {
          return this._intentService.getIntentLabelledStats(id, this._orgStore.selectedOrgId);
        })
      );
      return intentStats.reduce((a, c) => {
        a[c.id] = c;
        return a;
      }, {});
    } catch (e) {
      recordErrors(e);
      message.error(
        'There was an unexpected error loading the intent stats. Please refresh the page or try again later.'
      );
      throw e;
    }
  }

  async getIntentQueryStats(): Promise<any> {
    if (!this._orgStore.selectedOrgId) {
      return {};
    }
    try {
      const data = await this._intentService.getIntentStatistics({
        ...this.dateRange,
        org_id: this._orgStore.selectedOrgId
      });
      return data.reduce((a, c) => {
        a[this.addLabelForId(c.intent_id)] = c;
        return a;
      }, {});
    } catch (e) {
      recordErrors(e);
      message.error(
        'There was an unexpected error loading intent query stats. Please refresh the page or try again later.'
      );
      throw e;
    }
  }

  async getIntentExtras(): Promise<any> {
    if (!this._orgStore.selectedOrgId || !routerStore.activeIntentId) {
      return {};
    }
    try {
      return await this._intentService.getIntentExtras(
        this.addLabelForId(routerStore.activeIntentId),
        this._orgStore.selectedOrgId
      );
    } catch (e) {
      recordErrors(e);
      message.error(
        'There was an unexpected error loading additional intent details. Please refresh the page or try again later.'
      );
      throw e;
    }
  }

  async getOrgPredictionRules(): Promise<any> {
    if (!this._orgStore.selectedOrgId) {
      return {};
    }
    try {
      const data = await this._intentService.getOrgPredictionRules(this._orgStore.selectedOrgId);
      return data.reduce((a, c) => {
        a[c.id] = c;
        return a;
      }, {});
    } catch (e) {
      recordErrors(e);
      message.error(
        'There was an unexpected error loading Org Prediction Rules. Please refresh the page or try again later.'
      );
      throw e;
    }
  }

  getIntent(intentId: string, intents: IIntentWithCollection[]) {
    return intents.find(({ id }) => this.stripAndGetId(id) === intentId);
  }

  createIntent(name: string, intentCollectionId: string, description: string) {
    return this._intentService.createIntent(
      this._orgStore.selectedOrgId,
      this._orgStore.currentUserContext.full_name,
      name,
      intentCollectionId,
      description
    );
  }

  async updateIntent() {
    // if an autosave or update is already in progress then do nothing but queue an autosave to occur after
    if (this.autoSaving) {
      this.pendingAutoSave = true;
      return;
    }

    // perform save without big corner notification
    runInAction(() => (this.autoSaving = true));
    try {
      await this._intentService.updateIntent(this.updatedIntent, this._orgStore.selectedOrgId);
      this.intentsUpdated = true;
      const now = moment();
      runInAction(
        () => (this.lastAutoSavedAt = 'Last autosaved at ' + now.format('h:mma ') + ' on ' + now.format('MMMM D, YYYY'))
      );
    } catch (err) {
      recordErrors(err);
      NotificationManager.error({
        message: err?.graphQLErrors?.some(error =>
          error.message.includes('duplicate key value violates unique constraint "api_intent_categories_name_uq"')
        )
          ? 'Intent name already exists'
          : 'Unable to save intent details'
      });
    } finally {
      runInAction(() => (this.autoSaving = false));
    }

    // if some other event triggered an autosave while it was autosaving something previous, then trigger another final autosave
    if (this.pendingAutoSave) {
      this.pendingAutoSave = false;
      await this.updateIntent();
    }
  }

  setExamples(intentId: string, exampleInputs: string[]) {
    return this._intentService.setExamples({
      user: this._orgStore.currentUserContext.full_name,
      orgId: this._orgStore.selectedOrgId,
      intentId,
      exampleInputs: exampleInputs.map(content => ({ content, indicator: true }))
    });
  }

  createRules(orgId: number, categoryId: string, newRules: IPredictionRule[]) {
    return this._intentService.createRules(orgId, categoryId, newRules);
  }

  updateRules(orgId: number, updatedBy: string, updatedRules: IPredictionRule[]) {
    return this._intentService.updateRules(orgId, updatedBy, updatedRules);
  }

  moveIntent(orgId: number, initialRuleIds: string[], newRuleIds: string[]) {
    return this._intentService.moveIntent(orgId, initialRuleIds, newRuleIds);
  }

  deleteRules(orgId: number, deletedRuleIds: string[]) {
    return this._intentService.deleteRules(orgId, deletedRuleIds);
  }

  labelActiveLearningQuery(query: ILabellingQuery) {
    return this._intentService.labelActiveLearningQueries(query, this._orgStore.selectedOrgId);
  }

  notifyTrainingCompleted(intentId: string, intentName: string) {
    const userId = this._orgStore.currentUserContext.id;
    return this._intentService.notifyTrainingCompleted({
      intentId,
      intentName,
      userId,
      orgId: this._orgStore.selectedOrgId
    });
  }

  throwError(e: Error, navigateBack: boolean = false) {
    recordErrors(e);
    if (navigateBack) {
      setTimeout(() =>
        this._routerStore.history.push(
          orgPageRoute.stringify({ ...this._routerStore.activeRouteParams, orgPage: 'intents' })
        )
      );
    }
    throw e;
  }
}
